import { MCAVS010Request } from "types/MCAVS010/MCAVS010Request"
import { PassFailObjDto, SelectionFlowStepPassFailObj, SelectionStepObjDto } from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'
import { undecided, judgment, participate, pass, notParticipate, fail, other } from 'componentsHsc/AdvancedSearch/Config/SelectionFlowStepPassFailModalConfig'

export interface MCAVS010CountInfoOutDto {
  // 年度対応 start
  [key: string]: string | Number | string[] | boolean
  // 年度対応 end
  entryId: Number	//	エントリーID
  jobSeekerId: Number	//	求職者ID
  selectionManagementIdList:string[]  // 選考管理ID
  newApplicantCount: string	//	新規応募者数
  unreadResumeCount: string	//	履歴書未読数
  unreadCount:	string	//	未読数
  interviewFixedWaitingCount:	string	//	確定待ち数
  interviewSchedulePresentedCount: string	//	提示済み数
  // 年度対応 start
  exclamationMark1VisibleFlag: boolean // エクスクラメーションマークフラグ（新規応募）
  exclamationMark2VisibleFlag: boolean // エクスクラメーションマークフラグ（未読メッセージ）
  // 年度対応 end
}
export interface MCAVS010InterviewPlannerInfoOutDto {
  [key: string]: string
  interviewStartTime:	string	//	面接開始日時
  familyName:	string	//	姓
  name:	string	//	名
  displayTime:	string	//	表示時間
  interviewLocationName:	string	//	面接場所名
  entryId:	string	//	エントリーID
  selectionManagementId:	string	//	選考管理ID
  jobSeekerId:	string	//	求職者ID
  interviewPlanner:	string	//	面接予定者
}
// 年度対応 start
export interface MCAVS010DisplayYearYieldTitleOutDto {
  [key: string]: any
  targetYear:string   // 対象年度
  defaultChecked:boolean // 開閉状態
}

export interface MCAVS010DisplayYearYieldInfoOutDto {
  [key: string]: any
  targetYear: string   // 対象年度
  defaultChecked:boolean // 開閉状態
  displayYearYieldInfoByYear: MCAVS010SelectionInformationOutDto[] // 年度ごとに歩留まり情報
}
// 年度対応 end

// #58811 2022/05/24 start
export interface MCAVS010WeekProgressInfoOutDto {
  contractMedia: MCAVS010ContractMediaInfoOutDto // 契約媒体情報
  entryApplicant:MCAVS010EntryApplicantOutDto // 応募者数情報
  entryApplication:MCAVS010EntryApplicationOutDto // 応募件数情報
}

export interface MCAVS010ContractMediaInfoOutDto {
  contractMediaId:string // 契約媒体ID
  contractMediaName:string // 契約媒体名
}

export interface MCAVS010EntryApplicantOutDto {
  entryApplicantCount:MCAVS010EntryApplicantCountOutDto[] // 応募者数詳細情報
  totalEntryApplicantCount:string //合計エントリー応募者数
}

export interface MCAVS010EntryApplicationOutDto{
  entryApplicationCount:MCAVS010EntryApplicationCountOutDto[] // 応募件数詳細情報
  totalEntryApplicationCount:string // 合計エントリー応募件数
}

export interface MCAVS010EntryApplicantCountOutDto {
  entryDate:string // エントリー日
  entryApplicantCount:string // エントリー日で応募者数
}

export interface MCAVS010EntryApplicationCountOutDto {
  entryDate:string // エントリー日
  entryApplicationCount:string // エントリー日で応募件数
}

// #58811 2022/05/24 end

export interface MCAVS010SelectionInformationOutDto {
  [key: string]: any
  selectionClassification:	string	// 選考分類
  selectionInformation:	string	//	選考情報
  selectionPeriod:string   // 選考期間
  recruitmentTarget:string // 採用目標
  yieldMediaNameOutDto:MCAVS010YieldMediaNameOutDto[]//応募経路名OutDto
  // 年度対応 start
  displayYearYieldTitleList: MCAVS010DisplayYearYieldTitleOutDto[]//年度歩留まり表示タイトルOutDto
  // 年度対応 end
}
export interface MCAVS010YieldMediaNameOutDto {
  [key: string]: any
  selectionFlowSettingId:	string	//	選考フロー設定ID
  mediaSettingId:	string	//	応募経路設定ID
  contractMediaId:	string	//	契約応募経路ID
  mediaName:	string	//	応募経路名
  progressOutDto:	MCAVS010ProgressOutDto[]	//	進捗名
}
export interface MCAVS010ProgressOutDto {
  progressStatusSettingId:string //選考ステップ設定ID
  progressName:	string	//	進捗名
  progressCount:	string	//	進捗件数
  progressPercentage:	string	//	進捗割合
  // 25KH #75311 start
  progressPassCount: string // 進捗件数（合格/参加/承諾件数）
  progressPassPercentage: string // 進捗割合（合格/参加/承諾率）
  decisionDivisionType: string // 判定区分種別
  progressType: string // 進捗種別
  // 25KH #75311 end
}
export interface ApplicantsInfo {
  jobSeekerId: string //求職者ID
  entryId: string //エントリーID
  selectionManagementId: string //選考管理ID
}

export interface MCAVS010BriefingSessionOutDto {
  [key: string]: any
  contractMediaId:	string	//	契約応募経路ID
  contractMediaName:	string	//	契約応募経路名
  seminarInfoOutDto:MCAVS010SeminarInfoOutDto[]//セミナー情報
}
export interface MCAVS010SeminarInfoOutDto {
  [key: string]: any
  seminarId:	string	//	セミナーID
  coordinationFormerSeminarId:	string	//	連携元セミナーID
  seminarName:	string	//	セミナー名
  briefingSessionName:	string	//	説明会・面接名
  seminarScheduleId: string  // セミナー日程ID
  seminarInfo:MCAVS010SeminarOutDto[]//セミナー情報
}
export interface MCAVS010SeminarOutDto {
  invisibleFlag:	string	//	非表示フラグ
  seminarNumber:	string	//	セミナーNo.
  eventDate:	string	//	開催日
  startTime:	string	//	開始時間
  endTime:	string	//	終了時間
  seminarStartDateTime:	string	//	開催日時
  venueLocationName:	string	//	開催場所名
  seminarCapacity:	string	//	セミナー定員
  seminarReservedCount:	string	//	予約
  seminarCanceledCount:	string	//	キャンセル
  seminarScheduleId: string  // セミナー日程ID  
}
export interface MCAVS010ContractMediaOutDto {
  [key: string]: any
  contractMediaId:	string	//	契約応募経路ID
  contractMediaName:	string	//	契約応募経路名
  eventTypeName:MCAVS010EventTypeNameOutDto[]//イベント情報
}
export interface MCAVS010EventTypeNameOutDto {
  [key: string]: any
  eventTypeName:	string	//	イベント種別名
  eventInfo:MCAVS010EventOutDto[]//イベント情報
}
export interface MCAVS010EventOutDto {
  eventId:	string	// イベントID
  eventName:	string	//	イベント名
  eventNameEdi:	string	//	イベント名編集
  prefecturesName:	string	//	都道府県名
  // フェーズ2改修 start
  eventKubunName:  string  //イベント区分名
  // フェーズ2改修 end
  eventParticipantCount:	string	//	参加人数
}
export interface MCAVS010NewsInfoOutDto {
  [key: string]: string
  newsId:	string	//	お知らせID
  category:	string	//	分類
  newsClassification:	string	//	お知らせ分類
  subject:	string	//	件名
  publicStartDateGamen:	string	//	公開開始日
  newsTitle:	string	//	お知らせタイトル
}
export interface MCAVS010GuideOutDto {
  [key: string]: string
  newPawnGuidePhoneNumber:	string	//新卒電話番号
  newPawnGuideTime:	string	//新卒案内文
  halfwayGuidePhoneNumber:	string	//中途電話番号
  halfwayGuideTime:	string	//中途案内文
  newPawnMailAddress:	string	//新卒メールアドレス
  halfwayMailAddress:	string	//中途メールアドレス
  //WEB面接対応 start
  webInterviewGuidePhoneNumber: string  //WEB面接機能電話番号
  webInterviewGuideTime: string  //WEB面接機能案内文
  webInterviewInquiryMailAddress: string  //FacePeer社様の問い合わせメールアドレス
  //WEB面接対応 end
}

// #58811 2022/05/24 start
const contractMediaInit = {
  contractMediaId:'', // 契約媒体ID
  contractMediaName:'', // 契約媒体名
}

const entryApplicantInit = {
  entryApplicantCount:[], // エントリー日で応募者数
  totalEntryApplicantCount:'' // 合計エントリー応募者数
}
const entryApplicationInit = {
  entryApplicationCount:[], // エントリー日で応募件数
  totalEntryApplicationCount:'' // 合計エントリー応募件数
}
const weekProgressInfoOutDtoInit = {
  contractMedia: contractMediaInit, // 契約媒体情報
  entryApplicant: entryApplicantInit, // 応募者数情報
  entryApplication: entryApplicationInit, // 応募件数情報
}
// #58811 2022/05/24 end

const initialValuesRecruitmentStatusReport = {
  countInfoOutDto: {
    entryId: 0, //エントリーID
    jobSeekerId: 0, //求職者ID
    selectionManagementIdList:[], // 選考管理ID
    newApplicantCount: '',//新規応募者数
    unreadResumeCount:'',//履歴書未読数
    unreadCount:'',//未読数
    interviewFixedWaitingCount:'',//確定待ち数
    // 年度対応 start
    interviewSchedulePresentedCount:'',//提示済み数
    exclamationMark1VisibleFlag: false, // エクスクラメーションマークフラグ（新規応募）
    exclamationMark2VisibleFlag: false // エクスクラメーションマークフラグ（未読メッセージ）
    // 年度対応 end
  },//件数情報
  interviewPlannerInfoOutDto:[],//面接予定者の情報
  yieldInfoOutDto:[],//歩留まり情報
  briefingSessionInfoOutDto:[],//説明会・面接情報
  contractMediaOutDto:[],//イベントの参加状況情報
  newsInfoOutDto:[],//お知らせの情報
  guideOutDto: {
  newPawnGuidePhoneNumber:'',//新卒電話番号
  newPawnGuideTime:'',//新卒案内文
  halfwayGuidePhoneNumber:'',//中途電話番号
  halfwayGuideTime:'',//中途案内文
  newPawnMailAddress:'',//新卒メールアドレス
  halfwayMailAddress:'',//中途メールアドレス
  //WEB面接対応 start
  webInterviewGuidePhoneNumber:'',//WEB面接機能電話番号
  webInterviewGuideTime:'',//WEB面接機能案内文
  webInterviewInquiryMailAddress:'',//FacePeer社様の問い合わせメールアドレス
  //WEB面接対応 end
  },
  // 年度対応 start
  displayYearYieldInfo:[],//年度ごとに歩留まり情報
  // 年度対応 end
  // #58811 2022/05/24 start
  weekProgressInfoOutDto:weekProgressInfoOutDtoInit, //今週の推移エリアの情報
  // #58811 2022/05/24 end
  // MCB ロット３　start
  mcbRequestInfoOutDto:[],//My CareerBox 提出状況情報
  // MCB ロット３　end
}
const prim: MCAVS010Request = {
  recruitmentManagementDivision: '', //採用管理区分
  lastLogoutTime: '', // 最終ログアウト日時
  preLoginTime:'',// 前回ログイン日時
  lastLoginTime: '', // 最終ログイン日時
}

// WEB面接対応 start
const interviewWebInfoOutDto = {
  code: '',
  statusCode: '',
  interviewWebBorderNumber: '',
  interviewWebExprirationDate: '',
}
// WEB面接対応 end
// 年度対応 start
const initialValuesDisplayYearYieldInfo = {
  targetYear: '',   // 対象年度
  defaultChecked: false, // 開閉状態
  displayYearYieldInfoByYear: [], // 年度ごとに歩留まり情報
}
// 年度対応 end

export interface seminaSearch {
  [key: string]: any
  seminarId: string,// セミナーID
  seminarScheduleId: string,// セミナー日程ID
  senniID: string,// 遷移元ボタン
}

// MCB ロット３　start
export interface MCAVS010McbRequestInfoOutDto {
  [key: string]: string
  submissionContentName:	string	//	提出物内容名
  submission:	string	//	提出リクエスト人数
  submissionRequestCount:	string	//	提出人数
  unsubmitted:	string	//	未提出人数
  submissionContentId:	string	//	提出物内容ID
}
// MCB ロット３　end

export { 
  initialValuesRecruitmentStatusReport,
  interviewWebInfoOutDto, // WEB面接対応
  // 年度対応 start
  initialValuesDisplayYearYieldInfo,
  // 年度対応 end
  prim
}
// 25KH #75311 start
// [選考フロー×選考ステップ×合否]のObjectを作成
export const getSelectionFlowStepPassFailObj = (i: any, vue: any, data: any, linkFlag: string, afterInfixList: any[]) => {
  let selectionStepObj: SelectionStepObjDto[] = []
  const passFailObjDto: PassFailObjDto[] = []
  if (linkFlag === 'progressCount') {
    // 累計件数をクリック
    if (['0', '2'].includes(data.decisionDivisionType)) {
      // 合否型選考ステップの合否判定
      [undecided, judgment, pass, fail].forEach(item => {
        passFailObjDto.push({ passFailCode: item.value, passFailName: item.label })
      })
    } else if (['1'].includes(data.decisionDivisionType)) {
      // 参加型選考ステップの合否判定
      [undecided, participate, notParticipate, other].forEach(item => {
        passFailObjDto.push({ passFailCode: item.value, passFailName: item.label })
      })
    } else {
      // 応募、内定、インターンシップの選考ステップの合否判定
      passFailObjDto.push({ passFailCode: '0', passFailName: data.progressName.toString() })
    }
    // 選考ステップObjを作成
    selectionStepObj.push({
      selectionStepCode: data.progressStatusSettingId.toString(),
      selectionStepName: data.progressName.toString(),
      passFailObj: passFailObjDto,
    })
    if (data.progressType === '2') {
      // [内定]選考ステップの場合、内定承諾、入社の選考ステップObjを[内定]選考ステップObjの後に追加する
      afterInfixList.forEach(item => {
        selectionStepObj.push({
          selectionStepCode: item.progressStatusSettingId.toString(),
          selectionStepName: item.progressName.toString(),
          passFailObj: [{ passFailCode: '0', passFailName: item.progressName.toString() }]
        })
      })
    }
  } else {
    // 参加/合格/承諾件数をクリック
    if (data.progressType === '2') {
      // [内定]選考ステップの場合、内定承諾、入社だけの選考ステップObjを追加する
      afterInfixList.forEach(item => {
        selectionStepObj.push({
          selectionStepCode: item.progressStatusSettingId.toString(),
          selectionStepName: item.progressName.toString(),
          passFailObj: [{ passFailCode: '0', passFailName: item.progressName.toString() }]
        })
      })
    } else {
      // [内定]選考ステップ以外の場合
      // 合格/参加/承諾件数をクリック
      if (['0', '2'].includes(data.decisionDivisionType)) {
        // 合否型選考ステップの合格判定
        passFailObjDto.push({ passFailCode: pass.value, passFailName: pass.label })
      } else if (['1'].includes(data.decisionDivisionType)) {
        // 参加型選考ステップの参加判定
        passFailObjDto.push({ passFailCode: participate.value, passFailName: participate.label })
      } else {
        // 応募、インターンシップの選考ステップの合否判定
        passFailObjDto.push({ passFailCode: '0', passFailName: data.progressName.toString() })
      }
      // 選考ステップObjを作成
      selectionStepObj.push({
        selectionStepCode: data.progressStatusSettingId.toString(),
        selectionStepName: data.progressName.toString(),
        passFailObj: passFailObjDto,
      })
    }
  }
  // 採用状況レポート画面の選考フロー名にの【インターンシップ選考フロー】【採用選考フロー】を消す
  let selectionFlowName = ''
  if (i.selectionInformation.substring(0, '【インターンシップ選考フロー】 '.length) === '【インターンシップ選考フロー】 ') {
    selectionFlowName = i.selectionInformation.substring('【インターンシップ選考フロー】 '.length, i.selectionInformation.length)
  } else if (i.selectionInformation.substring(0, '【採用選考フロー】 '.length) === '【採用選考フロー】 ') {
    selectionFlowName = i.selectionInformation.substring('【採用選考フロー】 '.length, i.selectionInformation.length)
  } else {
    selectionFlowName = i.selectionInformation
  }

  // [選考フロー×選考ステップ×合否]のObjectを作成
  const selectionFlowStepPassFailObj: SelectionFlowStepPassFailObj = {
    searchConditionFlag: "0",
    searchConditionName: "全てと一致",
    selectionFlowObj: [
      {
        selectionFlowCode: vue.selectionFlowSettingId.toString(),
        selectionFlowName: selectionFlowName,
        selectionPhaseJudgmentFlag: '2',
        selectionPhaseJudgmentName: '過去の履歴を含む選考段階 選考変更前を含まない',
        selectionFlowSelectAllFlag: '0',
        selectionFlowSelectAllName: '',
        selectionStepObj: selectionStepObj,
        exceptCode: '0',
        exceptName: '',
      }
    ]
  }
  return selectionFlowStepPassFailObj
}
// 25KH #75311 end
