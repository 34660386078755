const initialValues = {
  loginId: '',
  temporaryLoginId: '',
  password: '',
  newPasswordConfirm: '',
  versionNumber:0,
}

const initVersionNumberAndCompanyAccountValues={
  versionNumber:'',
  companyAccountId:'',
}
export {initialValues,initVersionNumberAndCompanyAccountValues}