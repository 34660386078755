import request from 'utils/request'
import { MCBHS090Request } from 'types/MCBHS090/MCBHS090Request'
import { MCBHS090DestinationDownloadRequest } from 'types/MCBHS090/MCBHS090DestinationDownloadRequest'
import { MCBHS090DraftRequest } from 'types/MCBHS090/MCBHS090DraftRequest'
import { MCBHS090InitRequest } from 'types/MCBHS090/MCBHS090InitRequest'
import { MCBHS090PreviewRequest } from 'types/MCBHS090/MCBHS090PreviewRequest'


export const initRequest = (apiData: MCBHS090InitRequest) => {
  return request({
    url: '/MCBHS090/init',
    method: 'post',
    data: apiData,
  })
}

export const draftRequest = (apiData: MCBHS090DraftRequest) => {
  return request({
    url: '/MCBHS090/draft',
    method: 'post',
    data: apiData,
  })
}

export const previewRequest = (apiData: MCBHS090PreviewRequest) => {
  return request({
    url: '/MCBHS090/previewDeadlineChange',
    method: 'post',
    data: apiData,
  })
}

export const confirmRequest = (apiData: MCBHS090Request) => {
  return request({
    url: '/MCBHS090/confirm',
    method: 'post',
    data: apiData,
  })
}

export const destinationDownloadRequest = (apiData: MCBHS090DestinationDownloadRequest) => {
  return request({
    url: '/MCBHS090/destinationDownload',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })
}
