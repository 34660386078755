import axios from 'axios'
import { getToken, getTokenCopy } from './auth'
import { redirect } from 'common/businessUtil'
import { getMessage } from 'common/messageUtil'
import history from './history'
import { routeList } from 'routes/routes'
import { setErrorFromStorage, removeErrorFromStorage } from 'utils/error';
import { yearCheck, tokenCheckOfYearChanged, getTargetYearFromToken } from './yearCheckUtil'
import store from 'store'
import { setErrorMessageForOKControl, setTargetServiceErrorFlag, setTargetYearErrorFlag } from 'reducers/globalMenuReducer'
import { getMarchModeFromToken, marchModeCheck, tokenCheckOfModeChanged } from './marchModeGetUtil'
import Cookies from 'js-cookie'
import { magiContants } from './contants'

// 年度対応 start
// リクエストキャンセル用
const CancelToken = axios.CancelToken
const source = CancelToken.source()
// 年度対応 end

const service = axios.create({
  baseURL: '/api',
  timeout: 864000000
})

// 環境変数を使用する場合
const USE_API_DUMMY = process.env.REACT_APP_USE_API_DUMMY === 'USE';
// 環境変数を使用しない場合は直接書き換えてください
// const USE_API_DUMMY = true;

service.interceptors.request.use(
  config => {
    document.body.classList.add('loading-indicator')

    // 年度対応 start
    // トークンから対象年度取得
    let token = getTargetYearFromToken()
    // 転職March #75621 START
    // cookieにある採用管理区分を取得
    const rcmd = Cookies.get("recruitmentManagementDivision")
    if (getToken() && (getToken() !== getTokenCopy())) {
      if ((rcmd == '1' && !tokenCheckOfYearChanged(token)) || (rcmd == '2' && !tokenCheckOfModeChanged(token))) {
        window.location.href = routeList.interruption
      }
    }
    // 転職March #75621 END
    if (getToken()) {
      config.headers['Authorization'] = getToken()
    }
    // 年度切替感知チェック
    if (getToken() && token.year && yearCheck(token)) {
      // エラーの場合、リクエストをキャンセル、エラーメッセージを表示
      config.cancelToken = source.token
      source.cancel()
      store.dispatch(setTargetYearErrorFlag(true))
    }
    // 年度対応 end
    // 転職March #75621 START
    if (getToken() && token.march && marchModeCheck(token)) {
      // エラーの場合、リクエストをキャンセル、エラーメッセージを表示
      config.cancelToken = source.token
      source.cancel()
      // 転職March #9626 START
      // エントリー一覧強制切替時一時エラー出ないため
      if (store.getState().globalMenu.marchModeCheckExecuteFlg) store.dispatch(setTargetServiceErrorFlag(true))
      // 転職March #9626 END
    }
    // Marchモードの場合
    if (getMarchModeFromToken() == '1') {
      // 特殊リクエスト以外にヘッダにMarchサービスフラグ追加
      if (exceptedRequestList.findIndex(i =>
        config.url && config.url.indexOf(i) >= 0
      ) < 0) {
        config.headers['marchModeDecidedFlg'] = 'true'
      }
    }
    // 転職March #75621 END
    
    // ローカル環境スタブルート
    if (USE_API_DUMMY) {
      config.baseURL = '/api_dummy/'
      config.url += `/${(config.method || 'get').toLowerCase()}.json`
      config.method = 'GET'
    }
    return config
  },
  error => {
    document.body.classList.remove('loading-indicator')
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    document.body.classList.remove('loading-indicator')

    const { code, message, data } = response.data
    if (code === undefined) {
      if (200 === response.status) {
        return response
      }
    }
    if (response.config.url == "/error/networkError") {
      return response
    }
    if (code !== 200) {
      if (code === 301) {
        redirect(data)
        return Promise.reject({})
      }
      if (code === 404) {
        history.push(routeList.notFound)
      }
      if (code === 401) {
        if (response.config.url == "/MCACS010/siteKey") {
          return response;
        }
        if (window.location.href.indexOf(routeList.readying) === 0) {
          history.push(routeList.home)
        }
        let message = getMessage("401")
        return Promise.reject({ message })
      }

      if (code === 500) {
        try {
          const param = JSON.parse(message)
          if (param.errorReportId) {
            const { errorReportId, companyAccountId } = param
            history.push(
              `${routeList.errorReport}?errorReportId=${errorReportId}&companyAccountId=${companyAccountId}`
            );
          } else {
            history.push(routeList.error)
          }
        } catch(e) {
          history.push(routeList.error)
        }
        return Promise.reject({})
      }
      // 転職March #75621 START
      if (message == magiContants.MESSAGECODE_MCAAS010_004) {
        store.dispatch(setErrorMessageForOKControl(magiContants.MESSAGECODE_MCAAS010_004))
        return Promise.reject({})
      }
      // 転職March #75621 END

      return Promise.reject({ message })
    } else {
      return data
    }
  },
  error => {
    document.body.classList.remove('loading-indicator')
    // 年度対応 start
    // キャンセルされたリクエスト
    if (axios.isCancel(error)) {
      return Promise.reject({})
    }
    // 年度対応 end
    if (error.message === "Network Error") {
      return Promise.reject({})
    }
    if (error.message.includes("504")) {
      error.message = "504"
      return Promise.reject(error)
    }
    if (error.message.includes("503")) {
      window.location.href = '/sorry/maintenance';
      return Promise.reject({})
    }
    if (error.code === "ECONNABORTED") {
      return Promise.reject({})
    }
    if (error.response.config.url == "/error/networkError"
        || error.response.config.url == "/error/report") {
      removeErrorFromStorage()
      return error.response
    }
    setErrorFromStorage({
      errorMessage: 'network_error',
      errorStack: JSON.stringify(error.response) + '\n' + error.response.data
    })
    history.push(routeList.networkError, {
      body: error.response.data || ''
    })
    return Promise.reject({})
  }
)

// 転職March #75621 START
const exceptedRequestList: string[] = [
  'MCAAS010/serviceChange', // サービス切替
  'MCAUS010', // エラーレポート
]
// 転職March #75621 END

export default service
