import store from 'store'
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode'
import { getTokenCopy } from './auth';
import { isEqual } from 'lodash'

const TOKEN_HEAD = 'Bearer '

// 年度切り替えた感知チェック
export const yearCheck = (token: any) => {
    if (store.getState().globalMenu.targetYear) {
        if (token.year != store.getState().globalMenu.targetYear) {
            return true
        }
    }
    return false
}

// トークンに年度だけ変更チェック
export const tokenCheckOfYearChanged = (token: any) => {
    let tokenCopyString = getTokenCopy()
    if (tokenCopyString) {
        let index = tokenCopyString.lastIndexOf('.')
        let jwtStringOfCopy = tokenCopyString.substring(TOKEN_HEAD.length, index + 1)
        let tokenCopy: any = jwt_decode(jwtStringOfCopy)
        if (tokenCopy.year) {
            return isEqual({ ...token, year: '', exp: '' }, { ...tokenCopy, year: '', exp: '' })
        }
    }
    return false
}

// トークンからJWT対象取得
export const getTargetYearFromToken = () => {
    let tokenString = Cookies.get('client-jwt')
    if (tokenString) {
        let index = tokenString.lastIndexOf('.')
        let jwtString = tokenString.substring(TOKEN_HEAD.length, index + 1)
        const token: any = jwt_decode(jwtString)
        return token
    }
    return ''
}