import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS561QueryRequest } from 'types/MCAXS561/MCAXS561QueryRequest'
import {
  ItemSpecs,
  questionaireItemSpecs,
  QuestionnaireObj as QuestionnaireObjJobChange,
  QuestionnaireCondition as QuestionnaireConditionJobChange,
  ManagementItemObj as ManagementItemObjJobChange,
  ManagementItemCondition as ManagementItemConditionJobChange,
} from 'componentsHsc/AdvancedSearchJobChange/Config/itemConfig'
import {
  ItemSpecsEntryReply,
  ManagementItemObj,
  ManagementItemCondition,
  QuestionnaireObjNested,
  QuestionnaireCondition,
  QuestionnaireConditionNested,
  EntryReplyModalItem,
  EntryReplyModalItems,
} from 'componentsHsc/AdvancedSearch/Config/itemConfig'
import { QuestionnaireResponseObj } from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'

export const initEntryReplyModalState: EntryReplyModalStateTest = {
  typeCode: '0',
  typeName: 'AND',
  conditionList: [] as QuestionnaireConditionNested[],
}

export const initEntryReplyModalStateJobChange: EntryReplyModalStateJobChange = {
  typeCode: '0',
  typeName: 'AND',
  conditionList: [] as QuestionnaireConditionJobChange[],
}

export const initManagementModalState: ManagementModalState = {
  typeCode: '0',
  typeName: 'AND',
  conditionList: [] as ManagementItemCondition[],
}

export const initManagementModalStateJobChange: ManagementModalStateJobChange = {
  typeCode: '0',
  typeName: 'AND',
  conditionList: [] as ManagementItemConditionJobChange[],
}

export interface EntryReplyModalState {
  typeCode: string | null
  typeName: string | null
  conditionList: QuestionnaireCondition[] | null
}

export interface EntryReplyModalStateTest {
  typeCode: string | null
  typeName: string | null
  conditionList: QuestionnaireConditionNested[] | null
}

export interface EntryReplyModalStateTest {
  typeCode: string | null
  typeName: string | null
  conditionList: QuestionnaireConditionNested[] | null
}

export interface EntryReplyModalStateJobChange {
  typeCode: string | null
  typeName: string | null
  conditionList: QuestionnaireConditionJobChange[] | null
}

export interface ManagementModalState {
  typeCode: string | null
  typeName: string | null
  conditionList: ManagementItemCondition[] | null
}
export interface ManagementModalStateJobChange {
  typeCode: string | null
  typeName: string | null
  conditionList: ManagementItemConditionJobChange[] | null
}

const initialState = {
  management: [] as ItemSpecs[],
  invisibleRecruitmentManagementFlagSettingIdList: [] as string[],
  questionaire: [] as questionaireItemSpecs[],
  itemSpecsEntryReply: [] as ItemSpecsEntryReply[],
  entryReplyModalState: initEntryReplyModalState,
  entryReplyModalStateJobChange: initEntryReplyModalStateJobChange,
  managementModalState: initManagementModalState,
  managementModalStateJobChange: initManagementModalStateJobChange,
  entryReplyModalItems: [] as EntryReplyModalItems[],
}

const itemSpecificationSlice = createSlice({
  name: 'itemSpecification',
  initialState,
  reducers: {
    // 管理項目情報を取得
    getMcaxs501Init(
      state,
      action: PayloadAction<{
        isInitManagementItemObj: boolean
        isJobChange: boolean
        toggle: () => void
      }>
    ) {
      return state
    },
    // アンケート回答内容情報を取得
    getMcaxs561Init(
      state,
      action: PayloadAction<{
        request: MCAXS561QueryRequest
        qro: QuestionnaireResponseObj | null
        toggle: () => void
      }>
    ) {
      return state
    },
    // アンケート回答内容情報を取得
    getMcaxs561InitJobChange(
      state,
      action: PayloadAction<{
        request: MCAXS561QueryRequest
        isInitSelectedQuestionnaireObj: boolean
        toggle: () => void
      }>
    ) {
      return state
    },
    // 管理項目情報結果を設定
    setMcaxs501Init(
      state,
      action: PayloadAction<{
        result: ItemSpecs[]
        isInitManagementItemObj: boolean
        test?: QuestionnaireResponseObj
        isJobChange: boolean
        invisibleRecruitmentManagementFlagSettingIdList: string[]
      }>
    ) {
      const result = action.payload.result
      state.management = result
      state.invisibleRecruitmentManagementFlagSettingIdList =
        action.payload.invisibleRecruitmentManagementFlagSettingIdList

      if (action.payload.isInitManagementItemObj) {
        if (action.payload.isJobChange) {
          const managementItemConditionArray: ManagementItemConditionJobChange[] = []
          result.forEach(i => {
            let itemTypeName: string = ''
            switch (i.type) {
              case '10':
                itemTypeName = '文字列'
                break
              case '20':
                itemTypeName = '数値'
                break
              case '30':
                itemTypeName = '選択肢'
                break
              default:
                break
            }
            managementItemConditionArray.push({
              recruitmentManagementFlagSettingId: i.id ? i.id : '',
              recruitmentManagementFlagName: i.title,
              managementItemPresenceFlag: '',
              managementItemPresenceName: '',
              managementItemTypeCode: i.type,
              managementItemTypeName: itemTypeName,
              managementItemTagText: '',
              managementItemTagFrom: '',
              managementItemFlagTo: '',
              managementItemFlagCheckBoxObj: [],
            } as any)
          })

          state.managementModalStateJobChange.conditionList = managementItemConditionArray
          state.managementModalStateJobChange.typeCode = '0'
          state.managementModalStateJobChange.typeName = 'AND'
        } else {
          const managementItemConditionArray: ManagementItemCondition[] = []
          result.forEach(i => {
            let itemTypeName: string = ''
            switch (i.type) {
              case '10':
                itemTypeName = '文字列'
                break
              case '20':
                itemTypeName = '数値'
                break
              case '30':
                itemTypeName = '選択肢'
                break
              default:
                break
            }
            managementItemConditionArray.push({
              recruitmentManagementFlagSettingId: i.id ? i.id : '',
              recruitmentManagementFlagName: i.title,
              managementItemPresenceFlag: '',
              managementItemPresenceName: '',
              managementItemTypeCode: i.type,
              managementItemTypeName: itemTypeName,
              managementItemFlagText: '',
              managementItemFlagFrom: '',
              managementItemFlagTo: '',
              managementItemFlagCheckBoxObj: [],
            } as any)
          })

          state.managementModalState.conditionList = managementItemConditionArray
          state.managementModalState.typeCode = '0'
          state.managementModalState.typeName = 'AND'
        }
      }

      return state
    },
    // アンケート回答内容情報結果(就職)を設定
    setMcaxs561Init(
      state,
      action: PayloadAction<{
        result: ItemSpecsEntryReply[]
        qro: QuestionnaireResponseObj | null
      }>
    ) {
      const result = action.payload.result
      state.itemSpecsEntryReply = result

      const itemList = result.reduce(
        (result, current, index) => [
          ...result,
          ...current.questionInfoList.map((e, index2) => ({
            ...e,
            entryBoxInfo: {
              index: index,
              applicationRouteName: current.applicationRouteName,
              coordinationFormerEntryBoxId:
                current.coordinationFormerEntryBoxId,
              entryBoxName: current.entryBoxName,
            },
            index: index2,
          })),
        ],
        [] as EntryReplyModalItem[]
      )
      
      const particleSize = 10
      let entryReplyModalItems = [] as EntryReplyModalItems[]
      let temp = [] as EntryReplyModalItem[]
      entryReplyModalItems.push({ item: temp })
      itemList.forEach((item, index) => {
        temp.push(item)
        if (index % particleSize === particleSize - 1) {
          temp = new Array()
          entryReplyModalItems.push({ item: temp })
        }
      })

      if(entryReplyModalItems[entryReplyModalItems.length -1].item.length === 0){
        entryReplyModalItems = entryReplyModalItems.slice(0, entryReplyModalItems.length -1)
      }
      state.entryReplyModalItems = entryReplyModalItems

      //サーバから取得した条件を元にアンケート回答内容モーダルの画面表示情報を設定
      let questionnaireConditionArray: QuestionnaireConditionNested[] = []
      questionnaireConditionArray = result.map(i => ({
        questionnaireResponseApplicationRouteId: i.applicationRouteId,
        questionnaireResponseApplicationRouteName: i.applicationRouteName,
        questionnaireResponsecoordinationFormerEntryBoxId:
          i.coordinationFormerEntryBoxId,
        questionnaireResponseEntryBoxId: i.entryBoxId,
        questionnaireResponseEntryBoxName: i.entryBoxName,
        questionInfoList: i.questionInfoList.map(j => ({
          questionnaireResponseQuestionNumber: j.questionNumber,
          questionnaireResponseQuestionTitle: j.questionTitle,
          questionnaireResponseQuestionAnswerConditionFlag: '',
          questionnaireResponseQuestionAnswerConditionName: '',
          questionnaireResponseQuestionTypeCode: j.questionTypeCode,
          questionnaireResponseQuestionTypeName: getQuestionTypeName(
            j.questionTypeCode
          ),
          questionnaireResponseAnswerText: '',
          questionnaireResponseAnswerCheckBoxObj: [],
        })),
      }))

      state.entryReplyModalState.conditionList = questionnaireConditionArray
      state.entryReplyModalState.typeCode = '0'
      state.entryReplyModalState.typeName = 'AND'

      if (action.payload.qro) {
        //条件を保持している場合、
        //アンケート回答内容モーダルの画面表示情報に対して、検索条件JSONに設定済みの条件分情報を上書きする
        let newEntryReplyModalState = state.entryReplyModalState
        const questionnaireResponseObj = action.payload.qro
        newEntryReplyModalState.typeCode = questionnaireResponseObj.typeCode
        newEntryReplyModalState.typeName = questionnaireResponseObj.typeName
        let newCond: QuestionnaireConditionNested[] = []
        if (newEntryReplyModalState.conditionList) {
          newCond = newEntryReplyModalState.conditionList.map(i => ({
            ...i,
            questionInfoList: i.questionInfoList.map(j => {
              const findResult = questionnaireResponseObj.conditionList.find(
                itemObjCondition =>
                  itemObjCondition.questionnaireResponseQuestionNumber ===
                    j.questionnaireResponseQuestionNumber &&
                  itemObjCondition.questionnaireResponseEntryBoxId ===
                    i.questionnaireResponseEntryBoxId
              )
              if (findResult) {
                return {
                  ...j,
                  questionnaireResponseQuestionAnswerConditionFlag:
                    findResult.questionnaireResponseQuestionAnswerConditionFlag,
                  questionnaireResponseQuestionAnswerConditionName:
                    findResult.questionnaireResponseQuestionAnswerConditionName,
                  questionnaireResponseAnswerText:
                    findResult.questionnaireResponseAnswerText,
                  questionnaireResponseAnswerCheckBoxObj:
                    findResult.questionnaireResponseAnswerCheckBoxObj,
                }
              } else {
                return { ...j }
              }
            }),
          }))
        }

        newEntryReplyModalState.conditionList = newCond
        state.entryReplyModalState = newEntryReplyModalState
      }

      return state
    },
    // アンケート回答内容情報結果(転職)を設定
    setMcaxs561InitJobChange(
      state,
      action: PayloadAction<{
        result: questionaireItemSpecs[]
        isInitSelectedQuestionnaireObj: boolean
      }>
    ) {
      const result = action.payload.result
      state.questionaire = result

      if (action.payload.isInitSelectedQuestionnaireObj) {
        const questionnaireConditionArray: QuestionnaireConditionJobChange[] = []
        result.forEach((i, index) => {
          let questionTypeName: string = ''
          switch (i.type) {
            case '0':
              questionTypeName = 'なし'
              break
            case '1':
              questionTypeName = '自由記述'
              break
            case '2':
              questionTypeName = '単一選択'
              break
            case '3':
              questionTypeName = '複数選択'
              break
            default:
              break
          }
          const questionnaireCondition: QuestionnaireConditionJobChange = {
            questionNumber: i.id ? i.id : '',
            questionOption: i.questionOption ? i.questionOption : '',
            questionTitle: i.title,
            answerConditionFlag: '',
            answerConditionName: '',
            questionTypeCode: i.type,
            questionTypeName: questionTypeName,
            answerText: '',
            answerCheckBoxObj: [],
          }
          questionnaireConditionArray.push(questionnaireCondition)
        })

        state.entryReplyModalStateJobChange.conditionList = questionnaireConditionArray
        state.entryReplyModalStateJobChange.typeCode = '0'
        state.entryReplyModalStateJobChange.typeName = 'AND'
      }

      return state
    },
    setEntryReplyModalState(
      state,
      action: PayloadAction<QuestionnaireObjNested>
    ) {
      state.entryReplyModalState = action.payload
      return state
    },
    setEntryReplyModalStateJobChange(
      state,
      action: PayloadAction<QuestionnaireObjJobChange>
    ) {
      state.entryReplyModalStateJobChange = action.payload
      return state
    },
    setManagementModalState(state, action: PayloadAction<ManagementItemObj>) {
      state.managementModalState = action.payload
      return state
    },
    setManagementModalStateJobChange(
      state,
      action: PayloadAction<ManagementItemObjJobChange>
    ) {
      state.managementModalStateJobChange = action.payload
      return state
    },
  },
})

export const {
  getMcaxs501Init,
  getMcaxs561Init,
  getMcaxs561InitJobChange,
  setMcaxs501Init,
  setMcaxs561Init,
  setMcaxs561InitJobChange,
  setEntryReplyModalState,
  setEntryReplyModalStateJobChange,
  setManagementModalState,
  setManagementModalStateJobChange,
} = itemSpecificationSlice.actions
export default itemSpecificationSlice.reducer

const getQuestionTypeName = (questionTypeCode: string) => {
  let questionTypeName: string = ''
  switch (questionTypeCode) {
    case '0':
      questionTypeName = 'なし'
      break
    case '1':
      questionTypeName = '自由記述'
      break
    case '2':
      questionTypeName = '単一選択'
      break
    case '3':
      questionTypeName = '複数選択'
      break
    default:
      break
  }
  return questionTypeName
}
