/** 初期表示リクエスト */
export interface ApplicantDetailsRequest {
  [key: string]: any
  applicantsListInfo: ApplicantsInfo[]
  directionId: string
  displayTargetId: string
  listIndex: number
}
export interface ApplicantsInfo {
  [key: string]: any
  jobSeekerId: number //求職者ID
  entryId: number //エントリーID
  selectionManagementId: number //選考管理ID
}

export interface CurrentApplicantsListInfo {
  [key: string]: any
  applicantsListInfo: ApplicantsInfo[]
  listIndex: number
}

export interface ApplicantDetailsRequestInfo {
  [key: string]: any
  applicantDetailsRequest: ApplicantDetailsRequest
  currentApplicantsListInfo: CurrentApplicantsListInfo
}

/** サーバレスポンスからもらう情報 */
/** 初期表示 (初期表示リクエストに対するレスポンス内容) */
export interface InitResult {
  [key: string]: any
  commonHeaderInfo: CommonHeaderInfo
  applicantDetailsTargetInfo: ApplicantDetailsTargetInfo
  applicantDataHeaderInfo: ApplicantDataHeaderInfo
  displayTargetListInfoList: DisplayTargetListInfoList[]
  profileInfo: ProfileInfo
  entryHistoryInfoList: EntryHistoryInfoList[]
  // [phase2] start by zhangxp
  mcbLinkHistoryInfoList: McbLinkHistoryInfoList[]
  // [phase2] end by zhangxp
  // [phase2] start by zhangxp
  scoutInfoList: ScoutInfoList[]
  // [phase2] end by zhangxp
  questionnaireInfoList: QuestionnaireInfoList[]
  aptitudeTestIMAGESInfo: AptitudeTestIMAGESInfo
  aptitudeTestGABInfo: AptitudeTestGABInfo
  aptitudeTestOPQInfo: AptitudeTestOPQInfo
  aptitudeTestCABInfo: AptitudeTestCABInfo
  messageInfoList: MessageInfoList[]
  selectionFlow: SelectionFlow
  managementItemList: ManagementItem[]
  // phase2 start by 考
  encodeCompanyId: string
  encodeJobSeekerCompanyId: string
  // phase2 end by 考
  mcbSubmissionRequestInfo: McbSubmissionRequestInfo[]
  mcbMessageInfoList: McbMessageInfo[]
}

/** 共通ヘッダ情報 */
export interface CommonHeaderInfo {
  [key: string]: any
  companyName: string
  fullName: string
}

/** 応募者詳細表示対象情報 */
export interface ApplicantDetailsTargetInfo {
  [key: string]: any
  displayTargetId: string
  applicantsListInfo: ApplicantsInfo[]
  listIndex: number
  directionId: string
}

/** 応募者データヘッダ情報 */
export interface ApplicantDataHeaderInfo {
  [key: string]: any
  familyName: string
  name: string
  familyNameKana: string
  nameKana: string
  jobSeekerIdForDisplay: string
  unsubscribeTime: string
}

/** 表示権限情報 */
export interface DisplayTargetListInfoList {
  [key: string]: any
  jobSeekerId: number
  entryId: number
  selectionManagementId: number
  sysVersionNumber: string
}

/** 面接日程情報 */
export interface MCAXS040InterviewScheduleInfoDto {
  [key: string]: any
  interviewScheduleAdjustmentStatus: string
  selectionManagementId: number
  sendTime: string
  receivingTime: string
  interviewStartTime: string
  interviewLocationName: string
}

/** 応募経路情報 */
export interface MCAXS040ApplicationRouteInfoDto {
  [key: string]: any
  selectionManagementId: number
  mediaId: string
  routeName: string
}

/** 選考フローエリア */
export interface SelectionFlow {
  selectionFlowColumnList: SelectionFlowColumnList[]
  selectionFlowTagsDisplay: string
}

/** 選考フロー情報 */
export interface SelectionFlowColumnList {
  selectionManagementId: number
  selectionFlowSettingId: number
  selectionFlowInterviewSchedule: string
  selectionFlowMediumBadgeList: string[]
  applicationRouteInfoList: ApplicationRouteInfo[]
  selectionFlowName: string
  selectionFlowGPBadge: boolean
  selectionFlowSelectionStepBadge: boolean
  selectionFlowApplicationDateTime: string
  selectionFlowExclude: string
  selectionFlowUnExclude: string
  selectionFlowProgressBarList: SelectionFlowProgressBarList[]
  selectionFlowEvaluation: string
  selectionFlowComment: string
  sysVersionNumber: number
  entryId: number
  defaultChecked: boolean
}
/** 応募経路情報 */
export interface ApplicationRouteInfo {
  selectionManagementId: number
  mediaId: string
  routeName: string
  routeId: number
  sysVersionNumber: number
}
/** 選考フロー進捗バー */
export interface SelectionFlowProgressBarList {
  [key: string]: any
  selectionFlowJudgeBadge: null
  selectionFlowCurrentProgress: false
  selectionFlowProgressBarColor: null
  selectionFlowProgressName: string
  selectionFlowProgressUpdateDateTime: string
}
/** プロフィール情報 */
export interface ProfileInfo {
  [key: string]: any
  facePhotoManagementId: string
  scanVirusFlag: boolean
  familyName: string
  name: string
  familyNameKana: string
  nameKana: string
  postalCode: string
  prefecturesName: string
  addressAfterCity: string
  birthdate: string
  sex: string
  schoolName: string
  facultyName: string
  subjectName: string
  graduationPlanTimeName: string
  tag3: string
  phoneNumber3: string
  phoneNumber1: string
  mailAddress2: string
  mailAddress1: string
  tag4: string
  // [phase2] start by zhangxp
  zeminar: string
  circle: string
  majorTheme: string
  // [phase2] end by zhangxp
  schoolTypeName: string
  schoolGroupName: string
  humanitiesAndSciencesDivisionName: string
  extracurricularActivities: string 
}

/** エントリー履歴情報 */
export interface EntryHistoryInfoList {
  [key: string]: any
  manualRegistrationFlag: string
  manualRegistrationFlag2: string
  entryType: string
  addingOpportunity: string
  entryBoxName: string
  eventName: string
  mediaRouteName: string
  entryDate: string
  seminarType: string
  eventDate: string
  startTime: string
  venueLocationName: string
  // [phase2] start by zhangxp
  venueAreaName: string
  // [phase2] end by zhangxp
  // フェーズ2改修　start
  eventDateText:string
   // フェーズ2改修　end
  watchingStatus: string
  webInterviewParticipatingStatusId: string
  mediaSettingId: string
  cancelFlag: string
  cancelTime: string
  cancelType: string
  entryId: number
}

// [phase2] start by zhangxp
/**  MCB連携履歴情報 */
export interface McbLinkHistoryInfoList {
  [key: string]: any
  mcbCategory: string // MCBカテゴリー
  coordinationCompanyId: string // 連携元企業識別キー
  linkDatetime: Date // 連携日時
  searchConditionName: string // 検索条件名 
}
// [phase2] end by zhangxp

export interface McbSubmissionRequestInfo{
  [key: string]: any
  jobSeekerByCompanyId : number
  submissionRequestId : number
  mcbUseStatus : string
  submissionContentName : string
  submissionStatus : string
  forceStopFlag : string
  resubmitReceptionFlag : string
  sendingResult : string
  readFlag : string
  mcbMemberId : number
  submissionEntrySheetFlag : string
  submissionResumeFlag : string
  submissionResearchSummaryFlag : string
  submissionOriginalQuestionFlag : string
  submissionFileSubmissionFlag : string
  sysRegistrationTime : string
  msrSubmissionDeadlineKind : string
  msrSubmissionDeadlineDay : string
  msrSubmissionDeadlineHour : number
  sendTime : string
  deadlineChangeFlag : string
  mssSubmissionDeadlineKind : string
  mssSubmissionDeadlineDay : string
  mssSubmissionDeadlineHour : number
  sendPlanTime : string
  requestStatus : string
  sendAndReceiveDivision : string
  messageType : string
  status : string
  convertingBatchCompleteFlag : string
  submissionDatetime : string
  submissionTimes : number
  messageSendId : number
  mcbSubmissionRequestId : number
}

// [phase2] start by jxp
/**  スカウト履歴情報 */
export interface ScoutInfoList {
  [key: string]: any
  scoutSendTime: string // 送信日時
  scoutMedia: string // サービス
  scoutCharacter: string // 選考特徴アイコン
  scoutMessageStatus: string // 未読／既読 
}
// [phase2] end by jxp
/** アンケート情報 */
export interface QuestionnaireInfoList {
  [key: string]: any
  entryId: number
  entryType: string
  contractMediaName: string
  entryBoxName: string
  entryReceptionTime: string
  questionnaireList: QuestionnaireList[]
}
export interface QuestionnaireList {
  [key: string]: any
  questionNumber: number
  question: string
  invisibleFlag: string
  requiredFlag: string
  answer: string
}

/** 適性テスト（イメジス）情報 */
export interface AptitudeTestIMAGESInfo {
  [key: string]: any
  imagesDataId: string
  comprehensiveDeviationValue: number
  countingDeviationValue: number
  languageDeviationValue: number
  englishDeviationValue: number
  countingStandardPoint: number
  languageStandardPoint: number
  englishStandardPoint: number
  irealityImagination: number
  mflexibleMethodical: number
  aselfPacedEffortIntention: number
  gintrovertedOpened: number
  erelaxWorrier: number
  scalmSympathy: number
  evigor: number
  hantipersonnel: number
  mfixation: number
  birthdate: string
  updateRoute: number
  updateTime: string
  // Phase2 Start
  dstaticDynamic: number 
  wthinkingKindness: number
  shlCompanyId: number  // SHL企業ID
  mwMemberId: string  // 受験番号
  // Phase2 End
}

/** 適性テスト（GAB）情報 */
export interface AptitudeTestGABInfo {
  [key: string]: any
  webGabDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  personalId: string
  kanjiFamilyName: string
  kanjiName: string
  kanaFamilyName: string
  kanaName: string
  eMail: string
  examLanguage: string
  languageExamDate: string
  countingExamDate: string
  opqExamDate: string
  languageStandardPoint: number
  countingStandardPoint: number
  comprehensiveStandardPoint: number
  languageDeviationValue: number
  countingDeviationValue: number
  comprehensiveDeviationValue: number
  languageRawScore: number
  countingRawScore: number
  languageCorrectAnswerRate: number
  countingCorrectAnswerRate: number
  languageDlTimes: number
  countingDlTimes: number
  managementCapacity: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  specialistGeneralistCompetence: number
  salesI: number
  salesIi: number
  staff: number
  generalOffice: number
  engineer: number
  researchDevelopment: number
  serviceOverTheCounterSalesCallCenter: number
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
}

/** 適性テスト（OPQ）情報 */
export interface AptitudeTestOPQInfo {
  [key: string]: any
  webOpqDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  test: string
  version: string
  examDate: string
  examNumber: string
  eMail: string
  kanaFullName: string
  kanjiFullName: string
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
  managementCapacity: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  salesI: number
  salesIi: number
  staff: number
  generalOffice: number
  engineer: number
  researchDevelopment: number
  serviceOverTheCounterSalesCallCenter: number
}

/** 適性テスト（CAB）情報 */
export interface AptitudeTestCABInfo {
  [key: string]: any
  webCabDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  personalId: string
  kanjiFamilyName: string
  kanjiName: string
  kanaFamilyName: string
  kanaName: string
  mailAddress: string
  birthdate: string
  examLanguage: string
  cab1ExamDate: string
  cab2ExamDate: string
  cab3ExamDate: string
  cab4ExamDate: string
  opqExamDate: string
  cab1StandardPoint: number
  cab2StandardPoint: number
  cab3StandardPoint: number
  cab4StandardPoint: number
  comprehensiveStandardPoint: number
  cab1DeviationValue: number
  cab2DeviationValue: number
  cab3DeviationValue: number
  cab4DeviationValue: number
  comprehensiveDeviationValue: number
  cab1RawScore: number
  cab2RawScore: number
  cab3RawScore: number
  cab4RawScore: number
  cab1CorrectAnswerRate: number
  cab2CorrectAnswerRate: number
  cab3CorrectAnswerRate: number
  cab4CorrectAnswerRate: number
  cab1dlTimes: number
  cab2dlTimes: number
  cab3dlTimes: number
  cab4dlTimes: number
  pgCompetence: number
  seCompetence: number
  ceCompetence: number
  pmCompetence: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
}

/** 管理項目エリア */
export interface ManagementItem {
  managementItemSelectionFlowName: string
  managementItemColumnList: ManagementItemColumn[]
  selectionManagementId: number
  entryId: number
  sysVersionNumber: number
  defaultChecked: boolean
}

export interface ManagementItemColumn {
  managementItemNo: number
  managementItemName: string
  managementItemValue: string
  managementItemUpdateDate: string
}

/** メッセージ情報 */
export interface MessageInfoList {
  [key: string]: any
  messageId: string
  messageSendId: string
  selectionName: string
  sendAndReceiveDivision: string
  status: string
  // phase2 start
  sendTimeFlag:String
  messageRepliesNecessary: string
  messageRepliesLimit: string
  messageScoutCharacter: string
  sendingResult:string
  // phase2 end
  messageType: string
  readFlag: string
  sendPlanTime: string
  sendTime: string
  receivingTime: string
  subject: string
  maxSequence: string
  messageSendType: string
  repliedFlag: string
  senderName: string
  sysVersionNumber: number
  convertingBatchCompleteFlag: string
}

/** MCBメッセージ情報 */
export interface McbMessageInfo {
  [key: string]: any
  jobSeekerByCompanyId : number
  submissionRequestId : number
  mcbMessageId : number
  sendAndReceiveDivision : string
  messageType : string
  status : string
  convertingBatchCompleteFlag : string
  sendTimeFlag : string
  sendingResult : string
  readFlag : string
  subject : string
  subjectForSending : string
  maxAttachmentSequence : number
  senderName : string
  sendTime : string
  sendPlanTime : string
  messageSendId : number
}
/** 初期表示リクエスト */
export const applicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  directionId: '',
  displayTargetId: '',
  listIndex: 0,
}

/** 共通ヘッダ情報 */
export const commonHeaderInfo: CommonHeaderInfo = {
  companyName: '',
  fullName: '',
}

/** 応募者詳細表示対象情報 */
export const applicantDetailsTargetInfo: ApplicantDetailsTargetInfo = {
  displayTargetId: '',
  applicantsListInfo: [],
  listIndex: 0,
  directionId: '',
}

/** 応募者データヘッダ情報 */
export const applicantDataHeaderInfo: ApplicantDataHeaderInfo = {
  familyName: '',
  name: '',
  familyNameKana: '',
  nameKana: '',
  jobSeekerIdForDisplay: '',
  unsubscribeTime: '',
}

/** プロフィール情報 */
export const profileInfo: ProfileInfo = {
  facePhotoManagementId: '',
  scanVirusFlag: false,
  familyName: '',
  name: '',
  familyNameKana: '',
  nameKana: '',
  postalCode: '',
  prefecturesName: '',
  addressAfterCity: '',
  birthdate: '',
  sex: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  graduationPlanTimeName: '',
  tag3: '',
  phoneNumber3: '',
  phoneNumber1: '',
  mailAddress2: '',
  mailAddress1: '',
  tag4: '',
  // [phase2] start by zhangxp
  zeminar: '',
  circle: '',
  majorTheme: '',
  // [phase2] end by zhangxp
  schoolTypeName: '',
  schoolGroupName: '',
  humanitiesAndSciencesDivisionName: '',
  extracurricularActivities: '',
}

/** 選考フローエリア */
export const selectionFlow: SelectionFlow = {
  selectionFlowColumnList: [],
  selectionFlowTagsDisplay: '',
}
/** 選考フロー情報 */
export const selectionFlowColumnList: SelectionFlowColumnList = {
  selectionManagementId: 0,
  selectionFlowSettingId: 0,
  selectionFlowInterviewSchedule: '',
  selectionFlowMediumBadgeList: [],
  applicationRouteInfoList: [],
  selectionFlowName: '',
  selectionFlowGPBadge: false,
  selectionFlowSelectionStepBadge: false,
  selectionFlowApplicationDateTime: '',
  selectionFlowExclude: '',
  selectionFlowUnExclude: '',
  selectionFlowProgressBarList: [],
  selectionFlowEvaluation: '',
  selectionFlowComment: '',
  sysVersionNumber: 0,
  entryId: 0,
  defaultChecked: false,
}
/** 応募経路情報 */
export const applicationRouteInfo: ApplicationRouteInfo = {
  selectionManagementId: 0,
  mediaId: '',
  routeName: '',
  routeId: 0,
  sysVersionNumber: 0,
}

/** IMAGES情報 */
export const aptitudeTestIMAGESInfo: AptitudeTestIMAGESInfo = {
  imagesDataId: '',
  comprehensiveDeviationValue: 0,
  countingDeviationValue: 0,
  languageDeviationValue: 0,
  englishDeviationValue: 0,
  countingStandardPoint: 0,
  languageStandardPoint: 0,
  englishStandardPoint: 0,
  irealityImagination: 0,
  mflexibleMethodical: 0,
  aselfPacedEffortIntention: 0,
  gintrovertedOpened: 0,
  erelaxWorrier: 0,
  scalmSympathy: 0,
  evigor: 0,
  hantipersonnel: 0,
  mfixation: 0,
  birthdate: '',
  updateRoute: 0,
  updateTime: '',
  // Phase2 Start
  dstaticDynamic: 0, 
  wthinkingKindness: 0,
  shlCompanyId: 0,
  mwMemberId: "",
  // Phase2 End

}

/** GAB情報 */
export const aptitudeTestGABInfo: AptitudeTestGABInfo = {
  webGabDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  personalId: '',
  kanjiFamilyName: '',
  kanjiName: '',
  kanaFamilyName: '',
  kanaName: '',
  eMail: '',
  examLanguage: '',
  languageExamDate: '',
  countingExamDate: '',
  opqExamDate: '',
  languageStandardPoint: 0,
  countingStandardPoint: 0,
  comprehensiveStandardPoint: 0,
  languageDeviationValue: 0,
  countingDeviationValue: 0,
  comprehensiveDeviationValue: 0,
  languageRawScore: 0,
  countingRawScore: 0,
  languageCorrectAnswerRate: 0,
  countingCorrectAnswerRate: 0,
  languageDlTimes: 0,
  countingDlTimes: 0,
  managementCapacity: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  specialistGeneralistCompetence: 0,
  salesI: 0,
  salesIi: 0,
  staff: 0,
  generalOffice: 0,
  engineer: 0,
  researchDevelopment: 0,
  serviceOverTheCounterSalesCallCenter: 0,
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
}

/** OPQ情報 */
export const aptitudeTestOPQInfo: AptitudeTestOPQInfo = {
  webOpqDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  test: '',
  version: '',
  examDate: '',
  examNumber: '',
  eMail: '',
  kanaFullName: '',
  kanjiFullName: '',
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
  managementCapacity: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  salesI: 0,
  salesIi: 0,
  staff: 0,
  generalOffice: 0,
  engineer: 0,
  researchDevelopment: 0,
  serviceOverTheCounterSalesCallCenter: 0,
}

/** CAB情報 */
export const aptitudeTestCABInfo: AptitudeTestCABInfo = {
  webCabDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  personalId: '',
  kanjiFamilyName: '',
  kanjiName: '',
  kanaFamilyName: '',
  kanaName: '',
  mailAddress: '',
  birthdate: '',
  examLanguage: '',
  cab1ExamDate: '',
  cab2ExamDate: '',
  cab3ExamDate: '',
  cab4ExamDate: '',
  opqExamDate: '',
  cab1StandardPoint: 0,
  cab2StandardPoint: 0,
  cab3StandardPoint: 0,
  cab4StandardPoint: 0,
  comprehensiveStandardPoint: 0,
  cab1DeviationValue: 0,
  cab2DeviationValue: 0,
  cab3DeviationValue: 0,
  cab4DeviationValue: 0,
  comprehensiveDeviationValue: 0,
  cab1RawScore: 0,
  cab2RawScore: 0,
  cab3RawScore: 0,
  cab4RawScore: 0,
  cab1CorrectAnswerRate: 0,
  cab2CorrectAnswerRate: 0,
  cab3CorrectAnswerRate: 0,
  cab4CorrectAnswerRate: 0,
  cab1dlTimes: 0,
  cab2dlTimes: 0,
  cab3dlTimes: 0,
  cab4dlTimes: 0,
  pgCompetence: 0,
  seCompetence: 0,
  ceCompetence: 0,
  pmCompetence: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
}

export const mcbSubmissionRequestInfo: McbSubmissionRequestInfo = {
  jobSeekerByCompanyId : 0,
  submissionRequestId : 0,
  mcbUseStatus : '',
  submissionContentName : '',
  submissionStatus : '',
  forceStopFlag : '',
  resubmitReceptionFlag : '',
  sendingResult : '',
  readFlag : '',
  mcbMemberId : 0,
  submissionEntrySheetFlag : '',
  submissionResumeFlag : '',
  submissionResearchSummaryFlag : '',
  submissionOriginalQuestionFlag : '',
  submissionFileSubmissionFlag : '',
  sysRegistrationTime : '',
  msrSubmissionDeadlineKind : '',
  msrSubmissionDeadlineDay : '',
  msrSubmissionDeadlineHour : 0,
  sendTime : '',
  deadlineChangeFlag : '',
  mssSubmissionDeadlineKind : '',
  mssSubmissionDeadlineDay : '',
  mssSubmissionDeadlineHour : 0,
  sendPlanTime : '',
  requestStatus : '',
  sendAndReceiveDivision : '',
  messageType : '',
  status : '',
  convertingBatchCompleteFlag : '',
  submissionDatetime : '',
  submissionTimes : 0,
  messageSendId : 0,
  mcbSubmissionRequestId : 0,
}

/** 画面遷移リクエスト */
export interface ScreenTransitionRequest {
  screenId: string
  jobSeekerId: string
  jobSeekerIdForDisplay: string
  entryData: EntryData[]
}
export interface EntryData {
  entryId: string
  selectionManagementId: string
}

/** 応募経路追加画面遷移リクエスト */
export interface RouteScreenTransitionRequest {
  screenId: string
  functionType: string
  jobSeekerId: string
  entryId: string
  selectionManagementId: string
  jobSeekerIdForDisplay: string
}

/** 画面遷移リクエスト(PDF) */
export interface PDFScreenTransitionRequest {
  jobSeekerId: string[]
  entryId: string[]
  pdfType: string
  prevScreenId: string
}

export interface SelectionInfo {
  managementId:number[]
  jobSeekerId:number[]
  entryId:number[]
  screenRepresentationID:number[]
}

export interface SelectionFlowChange {
  targetSelectionManagementId: string[],
  targetEntryId: string[],
  filterdSelectionFlowSettingId: string,
}

export interface MCAXS230InitRequest {
  initFlag: string,
  prevDisplayId: string,
  jobSeekerId: string,
  entryId: string,
  selectionManagementId: string,
  recruitmentManagementDivision: string,
}

export interface MCAXS170InitRequest {
  screenId: string
  jobSeekerId: string[]
  selectionManagementId: string[]
  entryId: string[]
}