import { Height } from '@material-ui/icons'
import React from 'react'


interface Props {
  checked: boolean
  onChange: (query: object) => void
}

const CheckBoxCoulumn: React.FC<Props> = ({
  checked,
  onChange,
}) => {
  return (
    <>  
      <label className="Checkbox" onClick={onChange}>
        <input
          type='checkbox'
          checked={checked}
          onClick={event => event.stopPropagation()}
          onChange={onChange}
        />
        <mark　style={{width : '16px' , height : '16px'}}
          onClick={event => event.stopPropagation()}
        ></mark>
      </label>
    </>
  )
}

export default CheckBoxCoulumn
