import React from 'react';
import { FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

interface Option {
  value: string;
  label: string;
}

interface Props extends FieldProps {
  optionList: Option[];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  defaultValue?: boolean;
}

export default ({ className, optionList, placeholder, field, form, disabled, onChange, defaultValue }: Props) => {
  const classes = useStyles();
  const { name, value } = field;
  React.useEffect(() => {

    if(defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, "");
    }
  }, [defaultValue])



  const handleChange = (e: any) => {
    if (onChange !== undefined) {
    onChange(e)
    }
    form.setFieldValue(name, e.target.value);
  };

  return (
    <div className={className}>
      <select disabled={disabled} className={classes.root} value={value} onChange={handleChange}>
        {placeholder ? <option value="">{placeholder}</option> : null}
        {optionList.map(i => (
          <option key={i.value} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
    </div>
  );
};
