import moment from 'moment'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { now, stringToDateTime } from 'common/generalUtil'
import { isEmpty } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import {
  getInit,
  setInit,
  newTemplete,
  orverWrite,
  chkNotAdoptedNotificationType,
  setMessage,
} from 'reducers/messageSendCompReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import {
  initRequest,
  newRequest,
  orverWriteRequest,
  chkNotAdoptedNotificationTypeRequest,
} from 'apis/MCAZS101Api'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const serverResponse = yield call(initRequest, action.payload)
    yield put(setInit(serverResponse))
    //yield put(setScreenDisplayItems())
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* newSaga(action: ReturnType<typeof newTemplete>) {
  try {
    console.log('new saga')
    const formData = action.payload.formData
    yield call(newRequest, formData)
    yield put(openSnackbar('メッセージテンプレートを新規保存しました。'));
  } catch (error) {
    console.log('new saga error')
    yield put(openModal(error.message))
  }
}

export function* orverWriteSaga(action: ReturnType<typeof orverWrite>) {
  try {
    console.log('orverWrite saga')
    const formData = action.payload.formData
    yield call(orverWriteRequest, formData)
    yield put(openSnackbar('メッセージテンプレートを上書き保存しました。'));
  } catch (error) {
    console.log('orverWrite saga error')
    yield put(openModal(error.message))
  }
}

export function* chkNotAdoptedNotificationTypeSaga(action: ReturnType<typeof chkNotAdoptedNotificationType>) {
  try {
    console.log('chkNotAdoptedNotificationType saga')
    const message = yield call(chkNotAdoptedNotificationTypeRequest, action.payload)
    yield put(setMessage(message))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* messageInputSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(newTemplete, newSaga),
    takeLatest(orverWrite, orverWriteSaga),
    takeLatest(chkNotAdoptedNotificationType, chkNotAdoptedNotificationTypeSaga),
  ])
}
