import request from 'utils/request'
import { MCAXS320InitRequest } from 'types/MCAXS320/MCAXS320InitRequest'
import { MCAXS320CancelRequest } from 'types/MCAXS320/MCAXS320CancelRequest'
import { MCAXS330AptitudeTestIdCreateRequest } from 'types/MCAXS330/MCAXS330AptitudeTestIdCreateRequest'

// export const initRequest = (params: MCAXS320InitRequest) =>
//   request({
//     url: `MCAXS320/init`,
//     method: 'post',
//     data: params,
//   })
export const setSelectionCompanyContractWebTestRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAXS330/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const register = (data: MCAXS330AptitudeTestIdCreateRequest) =>
  request({
    url: `/MCAXS330/register`,
    method: 'post',
    data
  })