import request from 'utils/request'
import { MCAPS040InitRequest } from 'types/MCAPS040/MCAPS040InitRequest'

// 次期開発12月 #73504 start
// 初期表示
export const initEntryUploadAutoMergeComfrimApi = (param: MCAPS040InitRequest) =>
  request({
    url: '/MCAPS040/init',
    method: 'post',
    data: param
  })
// 次期開発12月 #73504 end
