import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  myChange: Function
  formName?: string
  target?: string
  targetIndex?: number
  targetIndex2?: number
  selectOptionList?: Option[]
}

const UncheckableToggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  myChange,
  formName,
  target,
  targetIndex,
  targetIndex2,
  selectOptionList,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const selectOptionName = name.split('.')[0] + '.managementItemFlagCheckBoxObj'

  const handleChange = (e: Option) => {
    if (e.value !== undefined) {
      switch (target) {
        case 'scoutObj':
          const optionA: Option = { value: '0', label: 'AND' }
          form.setFieldValue('scoutCharacterConditionJudgment', optionA)
          form.setFieldValue('scoutPreSiteObj', [])
          form.setFieldValue('scoutProSiteObj', [])
        case 'welcomeObj':
        case 'residenceObj.residenceCondition':
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(target, null)
          } else {
            const option: Option = {
              value: e.value,
              label: e.label,
            }
            form.setFieldValue(target, option)
          }
          break
        case 'answerCondition':
        case 'managementItemPresence':
          if (handleRadioCheck(e.value)) {
            form.setFieldValue(name, '')
            if (undefined != selectOptionList && e.value === '0') {
              form.setFieldValue(selectOptionName, [])
            }
          } else {
            form.setFieldValue(name, e.value)
            if (undefined != selectOptionList && e.value === '0') {
              form.setFieldValue(selectOptionName, selectOptionList)
            }
          }
          break
        default:
          form.setFieldValue(name, e.value)
          break
      }
    }
  }

  const handleRadioCheck = (value: string) => {
    if (value !== undefined) {
      let result = undefined
      switch (target) {
        case 'scoutObj':
          const scoutObj = form.values.scoutObj
          const scoutObjValue = ((scoutObj === null || scoutObj === undefined) ? undefined : scoutObj.value)
          result = scoutObjValue === value
          break
        case 'welcomeObj':
          const welcomeObj = form.values.welcomeObj
          const welcomeObjValue = ((welcomeObj === null || welcomeObj === undefined) ? undefined : welcomeObj.value)
          result = welcomeObjValue === value
          break
        case 'answerCondition':
          if (targetIndex !== undefined && targetIndex2 !== undefined) {
            result =
              form.values['conditionList'][targetIndex]['questionInfoList'][
                targetIndex2
              ].questionnaireResponseQuestionAnswerConditionFlag === value
            return result
          }
          if (targetIndex !== undefined) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex]
                .questionnaireResponseQuestionAnswerConditionFlag === value
          }
          break
        case 'managementItemPresence':
          if (targetIndex !== undefined) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex]
                .managementItemPresenceFlag === value
          }
          break
        default:
          break
      }
      return result
    }
  } 

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value}>
        {optionList.map(i => (
          <div onClick={() => handleChange(i)} style={{ cursor: 'pointer', marginRight: '20px' }}>
            <input type="radio" value={i.value} disabled={disabled} name={`${targetIndex}_${targetIndex2}`} checked={handleRadioCheck(i.value)} />&nbsp;{i.label}
          </div>
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default UncheckableToggle
