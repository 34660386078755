import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAES020UpdateRequest } from 'types/MCAES020/MCAES020UpdateRequest'
import { MCAES020CreateRequest } from 'types/MCAES020/MCAES020CreateRequest'
import { MCAES020DeleteRequest } from 'types/MCAES020/MCAES020DeleteRequest'
import { initialFormValues, recruitmentGroupInfoInitialValues } from '../pages/MCAES020/formConfig'

export interface Permission {
  [key: string]: string | null
  id: string
  name: string
  sortOrder: string | null
  status: string | null
}

export interface RecruitmentGroup {
  recruitmentGroup: string
  selection: string
  recruitmentGroupType: string
  affiliationCount: string
  updateTime: string
  updater: string
  recruitmentGroupSettingId: string
}

// MCAES020
export interface UpdateFormValues {
  recruitmentGroupSettingId: string //採用グループ設定ID
  reqruitGroupName: string //採用グループ名
  recruitmentType: string //採用グループ種別
  sysVersionNumber: number //sysバージョン番号
  celectionId: string[]
  AffiliationId: string[]
  selectionTable: SelectionFlowPreference[]
  affiliationTable: Affiliation[]
}

export interface RecruitmentGroupInfo {
  [key: string]: string | any
  recruitmentGroupSettingId: string //採用グループ設定ID
  recruitmentGroupName: string //採用グループ名
  recruitmentType: string //採用グループ種別
  sysVersionNumber: number //sysバージョン番号
}

export interface SelectionFlowPreference {
  [key: string]: string | any
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
  selectionType: string //選考種別
  selectionClassification: string //選考分類
  selectionClassificationName: string //選考分類名
  selectionStartDate: Date //選考開始日
  selectionEndDate: Date //選考終了日
  selectionEndFlag: string //選考終了フラグ
}

export interface Affiliation {
  [key: string]: string | any
  companyAccountId: string //企業アカウントID
  loginId: string //ログインID
  fullName: string //氏名
  departmentName: string //部署
  accountType: string //アカウント種別
  accountTypeName: string //アカウント種別名
  validFlag: string //有効フラグ
  validFlagName: string //有効フラグ名
}

const initialState: {
  recruitmentGroupTableData: RecruitmentGroup[]
  open: boolean
  updateFormValues: UpdateFormValues
  recruitmentGroupInfo: RecruitmentGroupInfo
  selectionFlowPreferenceList: SelectionFlowPreference[]
  affiliationList: Affiliation[]
  recruitmentGroupCopyFlag:boolean
  copyFlag:boolean
} = {
  recruitmentGroupTableData: [],
  open: false,
  updateFormValues: initialFormValues,
  recruitmentGroupInfo: recruitmentGroupInfoInitialValues,
  selectionFlowPreferenceList: [],
  affiliationList: [],
  recruitmentGroupCopyFlag:false,
  copyFlag:false,
}

const recruitmentGroupReducer = createSlice({
  name: 'recruitmentGroup',
  initialState,
  reducers: {
    getRecruitmentGroupTableData(state) {
      return state
    },
    setRecruitmentGroupTableData(
      state,
      action: PayloadAction<RecruitmentGroup[]>
    ) {
      state.recruitmentGroupTableData = action.payload
      return state
    },
    getRecruitmentGroupCount(state,
      action: PayloadAction<string>) {
      return state
    },
    
    // MCAES020
    getSelectionFlowPreferenceList(state, action: PayloadAction<string>) {
      state.recruitmentGroupCopyFlag =false
      return state
    },
    getRecruitmentGroupCopyFlag(state, action: PayloadAction<boolean>) {
      state.recruitmentGroupCopyFlag = action.payload
      return state
    },
    setRecruitmentGroupInfo(state, action: PayloadAction<UpdateFormValues>){
      state.updateFormValues = action.payload
      if(state.recruitmentGroupCopyFlag==true){
        state.updateFormValues.reqruitGroupName=''
        state.recruitmentGroupCopyFlag = false
      }
      return state
    },
    setSelectionTableData(state, action: PayloadAction<SelectionFlowPreference[]>){
      state.updateFormValues.selectionTable = action.payload
      return state
    },
    setAffiliationTableData(state, action: PayloadAction<Affiliation[]>){
      state.updateFormValues.affiliationTable = action.payload
      return state
    },
    setSelectionOptionList(state, action: PayloadAction<SelectionFlowPreference[]>){
      state.selectionFlowPreferenceList = action.payload
      return state
    },
    setAffiliationOptionList(state, action: PayloadAction<Affiliation[]>){
      state.affiliationList = action.payload
      return state
    },

    createAffiliationRecruitment(state, action: PayloadAction<MCAES020CreateRequest>) {
      return state
    },
    updateAffiliationRecruitment(state, action: PayloadAction<MCAES020UpdateRequest>) {
      return state
    },
    deleteAffiliationRecruitment(state, action: PayloadAction<MCAES020DeleteRequest>) {
      return state
    },
    setCopyFlag(state,action:PayloadAction<boolean>){
      state.copyFlag = action.payload
      return state
    },
    setDialogOpen(
      state,
      action: PayloadAction<boolean>
    ) {
      state.open = action.payload
      return state
    },
  },
})

export const {
  getRecruitmentGroupTableData,
  setRecruitmentGroupTableData,
  getRecruitmentGroupCount,
  getSelectionFlowPreferenceList,
  getRecruitmentGroupCopyFlag,
  setRecruitmentGroupInfo,
  setSelectionTableData,
  setAffiliationTableData,
  setSelectionOptionList,
  setAffiliationOptionList,
  createAffiliationRecruitment,
  updateAffiliationRecruitment,
  deleteAffiliationRecruitment,
  setDialogOpen,
  setCopyFlag,
} = recruitmentGroupReducer.actions
export default recruitmentGroupReducer.reducer
