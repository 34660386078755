import React, { useEffect, useState, useRef } from 'react'
import { FieldProps } from 'formik'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { getYear, getMonth } from 'date-fns'
import { ja } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'
import { makeStyles } from '@material-ui/core/styles'
import { useErrorStyle } from 'componentsHsc'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .react-datepicker-wrapper': {
      flex: '1 0 0',
      marginRight: '16px'
    },
    '& .react-datepicker__navigation': {
      top: '13px'
    }
  },
  inputField: {
    width: '100%',
    padding: '0 20px 0 8px',
    background: 'none #fff',
    border: '1px solid #8593a6',
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: '32px',
    lineHeight: '30px',
  },
}))

interface Props extends FieldProps, ReactDatePickerProps {
  className?: string
  validateTimeUnit?: boolean
  labelName?: string
  timeIntervals?: number
  showPlaceholder?: boolean
}

export default ({ 
  className, 
  form, 
  field, 
  onBlur,
  onChange, 
  validateTimeUnit,
  labelName,
  timeIntervals,
  showPlaceholder = true,
  ...otherProps 
}: Props) => {
  const classes = useStyles()
  const errorClasses = useErrorStyle()
  
  const { name, value } = field
  const { errors, touched } = form
  const inputRef = useRef<DatePicker>(null)

  const [timeValue, setTimeValue] = useState<Date | null>(value)
  useEffect(() => {
    setTimeValue(value)
  }, [value])

  const handleBlur= (e: any) => {
    if (onBlur) {
      onBlur(e);
    }
  }
  const handleChange = (date: Date | null) => {
    form.setFieldValue(name, date)
  }

  const handleRawDateChange = (e: any) => {
    const newDate = new Date(e.target.value)
    if (!isNaN(newDate.getTime())) {
      form.setFieldValue(name, newDate)
    }
  }
  
  const handleKeyDown = (ref: React.RefObject<DatePicker>) => (e: any) => {
    // detect the Enter key
    if (e.keyCode && e.keyCode === 13) {
      if (ref && ref.current) {
        ref.current.setOpen(false)
        if (onBlur) {
          onBlur(e);
        }
      }
    }
  }

  const handleIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.setFocus()
    }
  }

  let optionProps = {}

  if (otherProps.selectsStart) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
    }
  }

  if (otherProps.selectsEnd) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
      minDate: form.values.startDate,
    }
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <DatePicker
        className={classes.inputField}
        showTimeSelect
        timeFormat='HH:mm'
        timeIntervals={timeIntervals ? timeIntervals : 10}
        dateFormat='yyyy/MM/dd HH:mm'
        locale={ja}
        ref={inputRef}
        placeholderText={showPlaceholder ? '日付を選択' : ''}
        selected={timeValue}
        onBlur = {handleBlur}
        onClickOutside={handleBlur}
        onChange={handleChange}
        onChangeRaw={handleRawDateChange}
        onKeyDown={handleKeyDown(inputRef)}
        timeCaption="時間"
        renderCustomHeader={({ 
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div>
            <button 
              type="button" 
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth} 
              disabled={prevMonthButtonDisabled}>
            </button>
            <div className="react-datepicker__current-month">
              {`${getYear(date)}年${getMonth(date) + 1}月`}
            </div>
            <button 
              type="button" 
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}>
            </button>
          </div>
        )}
        {...otherProps}
        {...optionProps}
      />
      <button
        type='button'
        className='btn only-icon icon-input_calender'
        onClick={handleIconClick}
      />
      {validateTimeUnit && errors[name] ? (
        <p className={errorClasses.helperText}>
          {labelName}{errors[name]}
        </p>
      ) : null}
    </div>
  )
}
