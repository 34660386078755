import { MCBCS040DownloadCsvRequest } from 'types/MCBCS040/MCBCS040DownloadCsvRequest'

const MCBCS040downloadValues: MCBCS040DownloadCsvRequest = {
  requestId: '',
  mcbCategory: '',
  targetCount: 0,
  coordinationFormerCompanyIdentificationKey :''
}

export {MCBCS040downloadValues }
