import request from 'utils/request'
import { MCBHS110InitRequest } from 'types/MCBHS110/MCBHS110InitRequest'

export const initRequest = (apiData: MCBHS110InitRequest) => {
  return request({
    url: '/MCBHS110/init',
    method: 'post',
    data: apiData,
  })
}
