import { magiContants } from 'utils/contants'

export interface Row {
  [key: string]: string | Date | number
  mediaName: string
  updateTime: Date
  updater: string
  displayOrder: number
  contractMediaId: number
  mediaSettingId: string
  mediaType: string
  systemMediaMasterId: number
  recruitmentManagementDivision: string
}
export const initialValues: Row[] = []
interface MediaList {
  mediaListResults: Row[]
  updateMediaListResults: Row[]
  mediaListCount: number
}
const mediaListInitialValues: MediaList = {
  mediaListResults: initialValues,
  updateMediaListResults: initialValues,
  mediaListCount: 0,
}
const maxcount: number = magiContants.CHECK_MACIS010_MAXCOUNT
const msg: string = magiContants.CHECK_MACIS010_MAXCOUNT_MSG

const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}
export interface MediaListAndCount {
  mediaList: Row[]
  count: number
}
export { mediaListInitialValues, maxcount, msg, textMap }
