import { initRequest, redistRequest } from 'apis/MCBHS100Api'
import { isEmpty } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import moment from 'moment-timezone'
import {
  getInit,
  redistMessage,
  setBody,
  setBusinessCheckResult,
  setCode,
  setInit,
  setSubject,
} from 'reducers/submissionRequestForcedStopConfirmReducer'
import { openModal } from 'reducers/messageReducer'
import {
  changeConfirmState,
  closeConfirmModal,
} from 'reducers/submissionRequestForcedStopReducer'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
moment.tz.setDefault('Asia/Tokyo')

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const initVal = action.payload.initVal
    const serverResponse = yield call(initRequest, initVal)
    yield put(setInit(serverResponse))
    const result = action.payload.setReplace(
      serverResponse.previewDataList,
      initVal.requestSubject,
      // #MCB1.5次開発 #72395 START
      serverResponse.changedBody,
      serverResponse.senderMailAddressFix
      // #MCB1.5次開発 #72395 END
    )
    yield put(setSubject(result.subject))
    yield put(
      setBody(
        result.body
      )
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* redistMessageSaga(action: ReturnType<typeof redistMessage>) {
  try {
    const formData = action.payload.formData
    const sendTargetConfirmation = action.payload.sendTargetConfirmation
    const forbiddenWords = action.payload.forbiddenWords
    const sendReplyImpossibleTime = action.payload.sendReplyImpossibleTime

    // No.6 送信先チェック
    if (sendTargetConfirmation) {
      if (!sendTargetConfirmation.some(item => !isEmpty(item.mailAddress))) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS100-006')))
        return
      }
    }

    // No.8 不正文字チェック
    if (
      formData.requestSubject.indexOf('●●') >= 0 ||
      formData.requestText.indexOf('●●') >= 0
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS100-008')))
      return
    }

    // No.9 No.10 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.requestSubject.indexOf(item) >= 0) {
          isErrorSubject = true
          return
        }
        if (formData.requestText.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS100-009')))
        return
      }
      if (isErrorBody) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS100-010')))
        return
      }
    }

    // No.11 メンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()

      sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr)
          ? item.startDateStr.trim()
          : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(
            `${startDateStr.substring(0, 4)}-${startDateStr.substring(
              4,
              6
            )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
              8,
              10
            )}:${startDateStr.substring(10, 12)}`
          )
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(
            `${endDateStr.substring(0, 4)}-${endDateStr.substring(
              4,
              6
            )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
          )
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if (
            (sendTime.isAfter(startDate) ||
              sendTime.diff(startDate, 'minute') === 0) &&
            (sendTime.isBefore(endDate) ||
              sendTime.diff(endDate, 'minute') === 0)
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
              4,
              6
            )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (
            sendTime.isAfter(startDate) ||
            sendTime.diff(startDate, 'minute') === 0
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          // #MCB1.5次開発 #8986 END
          if (
            sendTime.isBefore(endDate) ||
            sendTime.diff(endDate, 'minute') === 0
          ) {
            errorPeriod = `～${endDateStr.substring(
              0,
              4
            )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
              6,
              8
            )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessageEx('MCBHS100-011', errorPeriod)))
        return
      }
    }

    yield call(redistRequest, formData)
    yield put(setCode(200))
    yield put(setBusinessCheckResult(true))
  } catch (error) {
    yield put(changeConfirmState(false))
    yield put(closeConfirmModal())
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export default function* mcbMessageConfirmSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(redistMessage, redistMessageSaga),
  ])
}
