/**
 * イメジス情報指定モーダル
 */
export const imagesTitles = [
  { label: '知的能力で検索', value: 'searchByAbility' },
  { label: 'パーソナリティで検索', value: 'searchByPersonality' },
]

export const imagesObject = {
  searchByAbility: [
    { label: '総合偏差値', value: 'comprehensive_deviation_value' },
    { label: '計数偏差値', value: 'counting_deviation_value' },
    { label: '言語偏差値', value: 'language_deviation_value' },
    { label: '英語偏差値', value: 'english_deviation_value' },
    { label: '計数標準点', value: 'counting_standard_point' },
    { label: '言語標準点', value: 'language_standard_point' },
    { label: '英語標準点', value: 'english_standard_point' },
  ],
  searchByPersonality: [
    { label: 'Ｉ （実現－想像）', value: 'i_reality_imagination' },
    { label: 'Ｍ （柔軟－几帳面）', value: 'm_flexible_methodical' },
    {
      label: 'Ａ （マイペース－努力思考）',
      value: 'a_self_paced_effort_intention',
    },
    { label: 'Ｇ （内向的－開放的）', value: 'g_introverted_opened' },
    { label: 'Ｅ （リラックス－心配性）', value: 'e_relax_worrier' },
    { label: 'Ｓ （冷静－共感）', value: 's_calm_sympathy' },
    { label: 'Ｅ （活力）', value: 'e_vigor' },
    { label: 'Ｈ （対人）', value: 'h_antipersonnel' },
    { label: 'Ｍ （定着）', value: 'm_fixation' },
    { label: 'D （静的-動的）', value: 'd_static_dynamic' },
    { label: 'W （思考-親身）', value: 'w_thinking_kindness' },
  ],
}

/**
 * GAB情報指定モーダル
 */
export const gabTitles = [
  { label: '受検言語で検索', value: 'searchByLanguage' },
  { label: '受検日で検索', value: 'searchByExamDate' },
  { label: '知的能力で検索', value: 'searchByIntellectualAbility' },
  { label: 'マネジメント資質で検索', value: 'searchByManagementQualities' },
  { label: '能力特性で検索', value: 'searchByAbilityCharacteristics' },
  { label: '職務適性結果で検索', value: 'searchByJobDutyResult' },
  { label: 'OPQ結果で検索', value: 'searchByOpqResult' },
]

export const gabObject = {
  searchByLanguage: [{ label: '受検言語', value: 'exam_Language' }],
  searchByExamDate: [
    { label: '言語受検日', value: 'language_Exam_Date' },
    { label: '計数受検日', value: 'counting_Exam_Date' },
    { label: 'OPQ受検日', value: 'opq_Exam_Date' },
  ],
  searchByIntellectualAbility: [
    { label: '言語標準点', value: 'language_standard_point' },
    { label: '計数標準点', value: 'counting_standard_point' },
    { label: '総合標準点', value: 'comprehensive_standard_point' },
    { label: '言語偏差値', value: 'language_deviation_value' },
    { label: '計数偏差値', value: 'counting_deviation_value' },
    { label: '総合偏差値', value: 'comprehensive_deviation_value' },
  ],
  searchByManagementQualities: [
    { label: 'マネジメント資質', value: 'management_capacity' },
  ],
  searchByAbilityCharacteristics: [
    { label: 'ヴァイタリティ', value: 'vitality' },
    { label: '人あたり', value: 'per_person' },
    { label: 'チームワーク', value: 'teamwork' },
    { label: '創造的思考力', value: 'creative_thinking_power' },
    { label: '問題解決力', value: 'problem_solving_power' },
    { label: '状況適応力', value: 'status_adaptation_power' },
    { label: 'プレッシャーへの耐力', value: 'proof_stress_to_pressure' },
    { label: 'オーガナイズ能力', value: 'organize_ability' },
    { label: '統率力', value: 'command_power' },
  ],
  searchByJobDutyResult: [
    { label: '営業１', value: 'sales_i' },
    { label: '営業２', value: 'sales_ii' },
    { label: 'スタッフ', value: 'staff' },
    { label: '一般事務', value: 'general_office' },
    { label: 'エンジニア', value: 'engineer' },
    { label: '研究開発', value: 'research_development' },
    {
      label: '接客／店頭販売／コールセンター',
      value: 'service_over_the_counter_sales_call_center',
    },
  ],
  searchByOpqResult: [
    { label: '説得力', value: 'persuasion_power' },
    { label: '指導力', value: 'coaching_power' },
    { label: '独自性', value: 'uniqueness' },
    { label: '外向性', value: 'extroversion' },
    { label: '友好性', value: 'friendship' },
    { label: '社会性', value: 'sociality' },
    { label: '謙虚さ', value: 'humility' },
    { label: '協議性', value: 'consultation' },
    { label: '面倒み', value: 'to_care' },
    { label: '具体的事物', value: 'concrete_thing' },
    { label: 'データ', value: 'data' },
    { label: '美的価値', value: 'aesthetic_value' },
    { label: '人間', value: 'human' },
    { label: 'オーソドックス', value: 'orthodox' },
    { label: '変化志向', value: 'change_intention' },
    { label: '概念性', value: 'conceptuality' },
    { label: '創造的', value: 'creative' },
    { label: '計画性', value: 'planning' },
    { label: '緻密', value: 'accurate' },
    { label: '几帳面', value: 'methodical' },
    { label: '余裕', value: 'allowance' },
    { label: '心配性', value: 'worrier' },
    { label: 'タフ', value: 'tough' },
    { label: '抑制', value: 'suppression' },
    { label: '楽観的', value: 'optimistic' },
    { label: '批判的', value: 'criticism' },
    { label: '行動力', value: 'activity_power' },
    { label: '競争性', value: 'competitiveness' },
    { label: '上昇志向', value: 'upward_intention' },
    { label: '決断力', value: 'decision_power' },
    { label: '一貫性', value: 'consistency' },
  ],
}

/**
 * OPQ情報指定モーダル
 */
export const opqTitles = [
  { label: '受検日で検索', value: 'searchByExamDate' },
  { label: 'マネジメント資質で検索', value: 'searchByManagementQualities' },
  { label: '能力特性で検索', value: 'searchByAbilityCharacteristics' },
  { label: '職務適性結果で検索', value: 'searchByJobDutyResult' },
  { label: 'OPQ結果で検索', value: 'searchByOpqResult' },
]

export const opqObject = {
  searchByExamDate: [{ label: '受検日', value: 'exam_date' }],
  searchByManagementQualities: [
    { label: 'マネジメント資質', value: 'management_capacity' },
  ],
  searchByAbilityCharacteristics: [
    { label: 'ヴァイタリティ', value: 'vitality' },
    { label: '人あたり', value: 'per_person' },
    { label: 'チームワーク', value: 'teamwork' },
    { label: '創造的思考力', value: 'creative_thinking_power' },
    { label: '問題解決力', value: 'problem_solving_power' },
    { label: '状況適応力', value: 'status_adaptation_power' },
    { label: 'プレッシャーへの耐力', value: 'proof_stress_to_pressure' },
    { label: 'オーガナイズ能力', value: 'organize_ability' },
    { label: '統率力', value: 'command_power' },
  ],
  searchByJobDutyResult: [
    { label: '営業１', value: 'sales_i' },
    { label: '営業２', value: 'sales_ii' },
    { label: 'スタッフ', value: 'staff' },
    { label: '一般事務', value: 'general_office' },
    { label: 'エンジニア', value: 'engineer' },
    { label: '研究開発', value: 'research_development' },
    {
      label: '接客／店頭販売／コールセンター',
      value: 'service_over_the_counter_sales_call_center',
    },
  ],
  searchByOpqResult: [
    { label: '説得力', value: 'persuasion_power' },
    { label: '指導力', value: 'coaching_power' },
    { label: '独自性', value: 'uniqueness' },
    { label: '外向性', value: 'extroversion' },
    { label: '友好性', value: 'friendship' },
    { label: '社会性', value: 'sociality' },
    { label: '謙虚さ', value: 'humility' },
    { label: '協議性', value: 'consultation' },
    { label: '面倒み', value: 'to_care' },
    { label: '具体的事物', value: 'concrete_thing' },
    { label: 'データ', value: 'data' },
    { label: '美的価値', value: 'aesthetic_value' },
    { label: '人間', value: 'human' },
    { label: 'オーソドックス', value: 'orthodox' },
    { label: '変化志向', value: 'change_intention' },
    { label: '概念性', value: 'conceptuality' },
    { label: '創造的', value: 'creative' },
    { label: '計画性', value: 'planning' },
    { label: '緻密', value: 'accurate' },
    { label: '几帳面', value: 'methodical' },
    { label: '余裕', value: 'allowance' },
    { label: '心配性', value: 'worrier' },
    { label: 'タフ', value: 'tough' },
    { label: '抑制', value: 'suppression' },
    { label: '楽観的', value: 'optimistic' },
    { label: '批判的', value: 'criticism' },
    { label: '行動力', value: 'activity_power' },
    { label: '競争性', value: 'competitiveness' },
    { label: '上昇志向', value: 'upward_intention' },
    { label: '決断力', value: 'decision_power' },
    { label: '一貫性', value: 'consistency' },
  ],
}

/**
 * CAB情報指定モーダル
 */
export const cabTitles = [
  { label: '受検言語で検索', value: 'searchByLanguage' },
  { label: '受検日で検索', value: 'searchByExamDate' },
  { label: '知的能力で検索', value: 'searchByIntellectualAbility' },
  {
    label: 'コンピュータ関係職適性で検索',
    value: 'searchByAptitudeRelatedToComputer',
  },
  { label: '能力特性で検索', value: 'searchByAbilityCharacteristics' },
  { label: 'OPQ結果で検索', value: 'searchByOpqResult' },
]

export const cabObject = {
  searchByLanguage: [{ label: '受検言語', value: 'exam_language' }],
  searchByExamDate: [
    { label: 'CAB1受検日', value: 'cab1_exam_date' },
    { label: 'CAB2受検日', value: 'cab2_exam_date' },
    { label: 'CAB3受検日', value: 'cab3_exam_date' },
    { label: 'CAB4受検日', value: 'cab4_exam_date' },
    { label: 'OPQ受検日', value: 'opq_exam_date' },
  ],
  searchByIntellectualAbility: [
    { label: '総合偏差値', value: 'comprehensive_deviation_value' },
    { label: 'CAB1偏差値', value: 'cab1_deviation_value' },
    { label: 'CAB2偏差値', value: 'cab2_deviation_value' },
    { label: 'CAB3偏差値', value: 'cab3_deviation_value' },
    { label: 'CAB4偏差値', value: 'cab4_deviation_value' },
    { label: '総合標準点', value: 'comprehensive_standard_point' },
    { label: 'CAB1標準点', value: 'cab1_standard_point' },
    { label: 'CAB2標準点', value: 'cab2_standard_point' },
    { label: 'CAB3標準点', value: 'cab3_standard_point' },
    { label: 'CAB4標準点', value: 'cab4_standard_point' },
  ],
  searchByAptitudeRelatedToComputer: [
    { label: 'プログラマー（PG）', value: 'pg_competence' },
    { label: 'システムエンジニア（SE）', value: 'se_competence' },
    { label: 'カスタマーエンジニア（CE）', value: 'ce_competence' },
    { label: 'プロジェクトマネージャー（PM）', value: 'pm_competence' },
  ],
  searchByAbilityCharacteristics: [
    { label: 'ヴァイタリティ', value: 'vitality' },
    { label: '人あたり', value: 'per_person' },
    { label: 'チームワーク', value: 'teamwork' },
    { label: '創造的思考力', value: 'creative_thinking_power' },
    { label: '問題解決力', value: 'problem_solving_power' },
    { label: '状況適応力', value: 'status_adaptation_power' },
    { label: 'プレッシャーへの耐力', value: 'proof_stress_to_pressure' },
    { label: 'オーガナイズ能力', value: 'organize_ability' },
    { label: '統率力', value: 'command_power' },
  ],
  searchByOpqResult: [
    { label: '説得力', value: 'persuasion_power' },
    { label: '指導力', value: 'coaching_power' },
    { label: '独自性', value: 'uniqueness' },
    { label: '外向性', value: 'extroversion' },
    { label: '友好性', value: 'friendship' },
    { label: '社会性', value: 'sociality' },
    { label: '謙虚さ', value: 'humility' },
    { label: '協議性', value: 'consultation' },
    { label: '面倒み', value: 'to_care' },
    { label: '具体的事物', value: 'concrete_thing' },
    { label: 'データ', value: 'data' },
    { label: '美的価値', value: 'aesthetic_value' },
    { label: '人間', value: 'human' },
    { label: 'オーソドックス', value: 'orthodox' },
    { label: '変化志向', value: 'change_intention' },
    { label: '概念性', value: 'conceptuality' },
    { label: '創造的', value: 'creative' },
    { label: '計画性', value: 'planning' },
    { label: '緻密', value: 'accurate' },
    { label: '几帳面', value: 'methodical' },
    { label: '余裕', value: 'allowance' },
    { label: '心配性', value: 'worrier' },
    { label: 'タフ', value: 'tough' },
    { label: '抑制', value: 'suppression' },
    { label: '楽観的', value: 'optimistic' },
    { label: '批判的', value: 'criticism' },
    { label: '行動力', value: 'activity_power' },
    { label: '競争性', value: 'competitiveness' },
    { label: '上昇志向', value: 'upward_intention' },
    { label: '決断力', value: 'decision_power' },
    { label: '一貫性', value: 'consistency' },
  ],
}
