import request from 'utils/request'

export const getMCBHS161InitRequest = () =>
  request({
    url: '/MCBHS161/init',
    method: 'post',
  })

  
export const getMcbhs161SubmissionClickRequestApi = () =>
  request({
    url: '/MCBHS161/submissionOriginalQuestionBtnClick',
    method: 'post',
  })