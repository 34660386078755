import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Tooltip as MuiTooptip, IconButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    display: 'flex',
    alignItems: ({ fullHeight }: any) => 
      fullHeight ? 'stretch' : 'flex-start',
    margin: ({ fullHeight }: any) => 
      fullHeight ? theme.spacing(0, 10) : theme.spacing(2, 10),
    '& > :first-child': {
      flex: 3,
    },
    '& > :nth-child(2)': {
      flex: 6,
    },
    '& > :last-child': {
      flex: 1,
    },
  },
  button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const Tooltip = withStyles({
  tooltip: {
    fontSize: '1em',
  },
  popper: {
    whiteSpace: 'pre-line'
  }
})(MuiTooptip)

interface Props {
  className?: string
  helperText?: string
  fullHeight?: boolean
}

const FormField: React.FC<Props> = ({ 
  children, 
  className, 
  helperText,
  fullHeight
}) => {
  const classes = useStyles({ fullHeight })
  return (
    <div className={`${className} ${classes.root}`}>
      {children}
      {helperText ? (
        <Tooltip title={helperText} placement='top'>
          <IconButton className={classes.button}>
            <HelpOutlineIcon fontSize='large' />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}
    </div>
  )
}

export default FormField
