import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '120px',
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  onClick: () => void
}

const Button = ({ onClick }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.modal}>
      <button
        type='button'
        className={`btn high ${classes.button}`}
        onClick={onClick}>
        指定する
      </button>
    </div>
  )
}

export default Button
