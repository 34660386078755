import { call, put, all, takeEvery, select, takeLeading } from 'redux-saga/effects'
import { updateRequest, getMediaListRequest, checkMediaCountRequest } from 'apis/MCAIS010Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import {
  setMediaList, updateMediaSortOrder, setMediaFlagCreate,
  setMediaEdit, setUpdateDialogOpen, editMediaSuccess,
  updateMediaAction, deleteMedia, getMediaList, setCreateDialogOpen, checkMediaCount
} from 'reducers/mediaReducer'
import { mediaNameIns } from 'apis/MCAIS020Api'
import { setMediaEditRequest, updateMediaRequest, deleteMediaRequest } from 'apis/MCAIS030Api'
import { magiContants } from 'utils/contants'

// -----------------MCAIS0I0_応募経路一覧_START------------------------//
export function* getMediaListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(getMediaListRequest, recruitmentManagementDivision);
    yield put(setMediaList(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* checkMediaCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield call(checkMediaCountRequest, recruitmentManagementDivision)
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateSaga(action: ReturnType<typeof updateMediaSortOrder>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.map((item) => (
      item.recruitmentManagementDivision = recruitmentManagementDivision
    ))
    yield call(updateRequest, action.payload);
    yield getMediaListSaga();
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAIS0I0_応募経路一覧_END------------------------//

// -----------------MCAIS020_応募経路登録_START------------------------//
function* setMediaNameSaga(action: ReturnType<typeof setMediaFlagCreate>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision;
    const message = yield call(mediaNameIns, action.payload);
    yield getMediaListSaga();
    yield put(setCreateDialogOpen(false))
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// -----------------MCAIS020_応募経路登録_END------------------------//

// -----------------MCAIS030_応募経路編集_START------------------------//
function* setMediaEditSaga(action: ReturnType<typeof setMediaEdit>) {
  try {
    const data = yield call(setMediaEditRequest, action.payload);
    yield put(setUpdateDialogOpen(true));
    yield put(editMediaSuccess(data));
  } catch (error) {
    if (error.message == magiContants.MESSAGECODE_RESULT_NULL) {
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    } else {
      yield put(openModal(error.message))
    }
  }
}

function* updateActionSaga(action: ReturnType<typeof updateMediaAction>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision;
    const message = yield call(updateMediaRequest, action.payload);
    yield getMediaListSaga();
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* deleteActionSaga(action: ReturnType<typeof deleteMedia>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    const message = yield call(deleteMediaRequest, action.payload);
    const data = yield call(getMediaListRequest, recruitmentManagementDivision);
    yield put(setMediaList(data));
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAIS030_応募経路編集_END------------------------//

export default function* mediaListSaga() {
  yield all([
    // -----------------MCAIS0I0_応募経路一覧_START------------------------//
    takeEvery(getMediaList, getMediaListSaga),
    takeEvery(checkMediaCount, checkMediaCountSaga),
    takeEvery(updateMediaSortOrder, updateSaga),
    // -----------------MCAIS0I0_応募経路一覧_END------------------------//

    // -----------------MCAIS020_応募経路登録_START------------------------//
    takeLeading(setMediaFlagCreate, setMediaNameSaga),
    // -----------------MCAIS020_応募経路登録_END------------------------//

    // -----------------MCAIS030_応募経路編集_START------------------------//
    takeEvery(setMediaEdit, setMediaEditSaga),
    takeLeading(updateMediaAction, updateActionSaga),
    takeLeading(deleteMedia, deleteActionSaga),
    // -----------------MCAIS030_応募経路編集_END------------------------//
  ])
}
