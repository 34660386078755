import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialMCBCS010InitDto, resetModal, initNextPageParam } from 'pages/MCBCS010/formConfig'
import MCBCS010OutputCsvRequest from 'types/MCBCS010/MCBCS010OutputCsvRequest'
import MCBCS010CooperationRequest from 'types/MCBCS010/MCBCS010CooperationRequest'
import MCBCS010DuplicateDataRequest from 'types/MCBCS010/MCBCS010DuplicateDataRequest'
import { MCBCS030initialValues } from 'pages/MCBCS030/formConfig'
import { MCBCS040downloadValues } from 'pages/MCBCS040/formConfig'
import { getMessage } from 'common/messageUtil'

export interface MCBCS010InitDto{
    // 選択全件数
    cooperativeNumber:number;
    // 重複件数
    numberOfLinkedRecords:number;
    // MCBカテゴリーリスト
    mcbCategoryList: string[];
    // 企業IDリスト
    companyIdList:number[];
    // 選考管理IDリスト
    selectionManagementIdList:number[];
    // 選考フロー設定IDリスト
    selectionFlowIdList:number[];
}

export interface openModalData {
    openCheckModal:boolean,
    modalMessage:string,
    modalMessageId:string,
}

export interface MCBCS040Param {
    [key: string]: string | any
    requestId: string
    targetCount: number
    mcbCategory: string
    coordinationFormerCompanyIdentificationKey : string
}

export interface NextPageParam {
    selectionManagementIdList: number[]
    count: number
    selectionFlowSettingIdList: number[]
    requestId: string
    mcbCategory: string
    coordinationFormerCompanyIdentificationKey:string
}

export interface MyCareerBoxInfo{
    // 初期表示用オブジェクト
    mCBCS010InitDto:MCBCS010InitDto,
    // モーダルオブジェクト
    openModalData:openModalData,
    // MCBCS030
    mCBCS030initialValues: InitRequest,
    nextPageParam: NextPageParam,
    mCBCS040Param:MCBCS040Param,
    requestId: string 
}

const initialState:MyCareerBoxInfo={
    // 初期表示用オブジェクト
    mCBCS010InitDto:initialMCBCS010InitDto,
    // モーダルオブジェクト
    openModalData:resetModal,
    //MCBCS030
    mCBCS030initialValues: MCBCS030initialValues,
    nextPageParam: initNextPageParam,
    mCBCS040Param:MCBCS040downloadValues,
    requestId: '',
}

// MCBCS030 start
export interface InitRequest{
    selectionManagementIdList: string[];
    selectionFlowSettingId: string;
  }
  export interface InitParam{
    InitRequest: InitRequest
    targetCount: string
  }
  
  export interface MonitorParam{
    requestId: string    
    selectionManagementIdList: number[];
    selectionFlowSettingIdList: number[];
    targetCount: number
    mcbCategory: string
    coordinationFormerCompanyIdentificationKey:string
  }

const myCareerBoxReducersSlice = createSlice({
    name: 'myCareerBoxInfo',
    initialState,
    reducers: {
        // MCBCS010 start
        getMyCareerBoxInfo(state,action:PayloadAction<MCBCS010DuplicateDataRequest>){
            return state
        },
        initMyCareerBoxInfo(state,action:PayloadAction<MCBCS010DuplicateDataRequest>){
            return state
        },
        setMyCareerBoxInfo(state,action:PayloadAction<MCBCS010InitDto>){
            state.mCBCS010InitDto = action.payload
            return state
        },
        outputCsvDownload(state,action:PayloadAction<{mCBCS010OutputCsvRequest:MCBCS010OutputCsvRequest,nextPageParam:NextPageParam}>){
            return state
        },
        exectionCooperation(state,action:PayloadAction<MCBCS010CooperationRequest>){
            return state
        },
        closeModel(state){
            state.openModalData.openCheckModal = false
            return state;
        },
        openModalMessage(state,action:PayloadAction<string>){
            state.openModalData.modalMessageId = action.payload
            state.openModalData.modalMessage = getMessage(action.payload)
            state.openModalData.openCheckModal = true
        },
        // MCBCS010 end
        // MCBCS020 start
        openMCBCS020(state){
            return state
        },
        // MCBCS020 end

        // MCBCS030 start
        monitor(state, action: PayloadAction<MonitorParam>) {
            state.requestId = action.payload.requestId            
            state.mCBCS040Param.targetCount = action.payload.targetCount
            state.mCBCS040Param.mcbCategory = action.payload.mcbCategory
            state.mCBCS040Param.coordinationFormerCompanyIdentificationKey = action.payload.coordinationFormerCompanyIdentificationKey
            return state
        },
        // MCBCS030 end
    }
})

export const{
    getMyCareerBoxInfo,
    initMyCareerBoxInfo,
    setMyCareerBoxInfo,
    outputCsvDownload,
    exectionCooperation,
    openModalMessage,
    closeModel,
    openMCBCS020,    
    monitor,
} = myCareerBoxReducersSlice.actions
export default myCareerBoxReducersSlice.reducer