import UrlMappingRequest from 'types/Url/UrlMappingRequest'
import request from 'utils/request'

export const urlApi = (apiData: UrlMappingRequest) =>
  request({
    url: `/url/getUrl`,
    method: 'post',
    data: apiData,
  })

