/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { McbSendTarget, SendTarget} from 'pages/MCBHS060/viewConig'
import { SendReplyImpossibleTime } from 'pages/MCBHS060/apiConfig'
import { MCBHS070InitRequest } from 'types/MCBHS070/MCBHS070InitRequest'
import { MCBHS070InitResult } from 'types/MCBHS070/MCBHS070InitResult'
import { MCBHS070RegisterRequest } from 'types/MCBHS070/MCBHS070RegisterRequest'
import { MCBHS070RegisterResult } from 'types/MCBHS070/MCBHS070RegisterResult'

const messageRegisterRequest: MCBHS070RegisterRequest = {
  destinationId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  // 9174 start
  searchCriteriaJson: '',
  // 9174 end
  submissionsSelectId: '',
  limitDateDetermineFlag: 0,
  dateTimeDetermineDate: '',
  requestDayTimeLaterDay: 0,
  requestDayTimeToTime: 0,
  anytimeReceptionEndDate: '',
  reRequestReceptFlag: 0,
  // #MCB1.5���J�� #72395 START
  senderCompanyName: '',
  senderMailAddress: '',
  // #MCB1.5���J�� #72395 END
  deadlineChangeSubject: '',
  deadlineChangeText: '',
  sendTimerFlag: 0,
  draftMessageSendID: '',
  messageUtilMcbMessagesDetailDtoList: [],
}

const initRequest: MCBHS070InitRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  submissionsId: '',
  limitDateDetermineFlag: 0,
  dateTimeDetermineDate: '',
  requestDayTimeLaterDay: 0,
  requestDayTimeToTime: 0,
  anytimeReceptionEndDate: '',
  reRequestReceptFlag: 0,
  // #MCB1.5���J�� #72395 START
  senderCompanyName: '',
  senderMailAddress: '',
  // #MCB1.5���J�� #72395 END
  requestSubject: '',
  requestText: '',
  sendTimer: 0,
  messageUtilMcbMessagesDetailDtoList: [],
}

const initResult: MCBHS070InitResult = {
  entryId: 0,
  familyName: '',
  name: '',
  jobSeekerIdForDisplay: 0,
  submissionRequestUrl: '',
}

const registerResult: MCBHS070RegisterResult = {
  sendCount: 0,
  emailFailureCount: 0,
}

const initialState: {
  messageComfInitRequest: MCBHS070InitRequest
  messageComInitResult: MCBHS070InitResult[]
  messageComfRegisterResult: MCBHS070RegisterResult
  draftRequest: MCBHS070RegisterRequest
  businessCheckResult: boolean
  alertMessageIdList: string[]
  requestSubject: string
  requestText: string
  successfulCountMessage: number
  sendCount: number
  emailFailedCount: number
  mcbSendTarget: McbSendTarget[]
  isSending: boolean
  // #MCB1.5���J�� #72395 START
  senderMailAddressFix: string
  // #MCB1.5���J�� #72395 END
} = {
  messageComfInitRequest: initRequest,
  messageComInitResult: [],
  messageComfRegisterResult: registerResult,
  draftRequest: messageRegisterRequest,
  businessCheckResult: false,
  alertMessageIdList: [],
  requestSubject: '',
  requestText: '',
  successfulCountMessage: 0,
  sendCount: 0,
  emailFailedCount: 0,
  mcbSendTarget: [],
  isSending: false,
  // #MCB1.5���J�� #72395 START
  senderMailAddressFix: '',
  // #MCB1.5���J�� #72395 END
}

const mcbDeadlineChangeConfirmSlice = createSlice({
  name: 'mcbDeadlineChangeConfirm',
  initialState,
  reducers: {
    getInit(
      state,
      action: PayloadAction<{
        initVal: MCBHS070InitRequest
        setReplace: (
          serverResponse: MCBHS070InitResult[],
          subject: string,
          body: string
        ) => { subject: string; body: string }
      }>
    ) {
      return state
    },
    setInit(state, action: PayloadAction<MCBHS070InitResult[]>) {
      state.messageComInitResult = action.payload
      return state
    },
    setSubject(state, action: PayloadAction<string>) {
      state.requestSubject = action.payload
      return state
    },
    setBody(state, action: PayloadAction<string>) {
      state.requestText = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = false
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    redistMessage(
      state,
      action: PayloadAction<{
        formData: MCBHS070RegisterRequest
        sendTargetConfirmation: SendTarget[]
        forbiddenWords: string[]
        sendReplyImpossibleTime: SendReplyImpossibleTime[]
      }>
    ) {
      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
    // #MCB1.5���J�� #72395 START
    setSenderMailAddressFix(state, action: PayloadAction<string>) {
      state.senderMailAddressFix = action.payload
      return state
    },
    // #MCB1.5���J�� #72395 END
  },
})

export const {
  getInit,
  setInit,
  setSubject,
  setBody,
  setCode,
  setBusinessCheckResult,
  redistMessage,
  // #MCB1.5���J�� #72395 START
  setSenderMailAddressFix,
  // #MCB1.5���J�� #72395 END
} = mcbDeadlineChangeConfirmSlice.actions
export default mcbDeadlineChangeConfirmSlice.reducer
