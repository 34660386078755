import { all, fork } from 'redux-saga/effects';
import userSaga from './userSaga';
import accountSaga from './accountSaga';
import errorSaga from './errorSaga';
import selectionStatusUpdateSaga from './selectionStatusUpdateSaga';
import extraAccountSaga from './extraAccountSaga';
import mediaSaga from './mediaSaga';
import signSaga from './signSaga';
import recruitmentManagementFlagSaga from './recruitmentManagementFlagSaga';
import selectionFlowSaga from './selectionFlowSaga'
import recruitmentGroupSaga from './recruitmentGroupSaga';
import interviewSaga from './interviewSaga';
import globalMenuSaga from './globalMenuSaga';
import companyLoginSaga from './companyLoginSaga';
import affiliationRecruitmentSaga from './affiliationRecruitmentSaga'
import advancedSearchSaga from './advancedSearchSaga'
import advancedSearchModalSaga from './advancedSearchModalSaga'
import entryDetailSaga from './entryDetailSaga'
import entryListSaga from './entryListSaga'
import advancedSearchJobChangeSaga from './advancedSearchJobChangeSaga'
import itemSpecificationSaga from './itemSpecificationSaga'
import messageSendSaga from './messageSendSaga'
import messageSendConfirmSaga from './messageSendConfirmSaga'
import mcbMessageSendConfirmSaga from './mcbMessageSendConfirmSaga'
import messageSendCompSaga from './messageSendCompSaga'
import mcbMessageSendCompSaga from './mcbMessageSendCompSaga'
import applicantDetailForEmplymentSaga from './applicantDetailForEmplymentSaga'
import commentUpdateSaga from './commentUpdateSaga';
import starEvaluationUpdateSaga from './starEvaluationUpdateSaga'
import notAdoptedNotificationSaga from './notAdoptedNotificationSaga'
import errorReportSaga from './errorReportSaga'
import spamActReportSaga from './spamActReportSaga'
import alertPreferenceSettingSaga from './alertPreferenceSettingSaga'
import attachmentListSaga from './attachmentListSaga'
import actionPreSaga from './actionPreSaga'
import csvTemplateSaga from './csvTemplateSaga'
import commentHistorySaga from './commentHistorySaga'
import progressStatusHistorySaga from './progressStatusHistorySaga'
// 転職March #75621 START
import progressStatusChangeSaga from './progressStatusChangeSaga'
// 転職March #75621 END
import entryHistorySaga from './entryHistorySaga'
import seminarSaga from './seminarSaga'
import messageTemplateSaga from './messageTemplateSaga'
// MCBリプレース MCBJS020 START
import submissionReqTemplateSaga from './submissionReqTemplateSaga'
// MCBリプレース MCBJS020 END
// MCBリプレース MCBHS010 START
import submissionRequestSendSaga from './submissionRequestSendSaga'
// MCBリプレース MCBHS010 END
import changeOthersSaga from './changeOthersSaga'
import MCAXS340Saga from './recruitmentManagementFlagChangeSaga'
import nameCheckListSaga from "./nameCheckListSaga"
import csvOutputSaga from './csvOutputSaga'
import entryRouteSaga from './entryRouteSaga'
import aptitudeTestIDIssue from "./aptitudeTestIDIssueSaga";
import entryUploadSaga from './entryUploadSaga';
import applicantListSaga from './applicantListSaga'
import tagManagementSaga from './tagManagementSaga'
import messageListSaga from './messageListSaga';
// MCBリプレース MCAZS170 start
import mcbMessageListSaga from './mcbMessageListSaga';  
// MCBリプレース MCAZS170 end
import selectCriteriaSaga from './selectCriteriaSaga'
import selectCriteriaMcbSaga from './selectCriteriaMcbSaga'
import messageDetailSaga from './messageDetailSaga'
// import newsSaga from './newsSaga'
import recruitmentStatusReportSaga from './recruitmentStatusReportSaga'
import progressStagentAlertSaga from './progressStagentAlertSaga'
import pdfGeneratingSaga from './pdfGeneratingSaga'
import interviewScheduleSaga from './interviewScheduleSaga'
import jobSeekerInfoSaga from './jobSeekerInfoSaga'
import duplicateIdentificationSaga from './duplicateIdentificationSaga'
import entrySaga from './entrySaga'
import redirectSaga from './redirectSaga'
import facePhotoSaga from './facePhotoSaga'
import myCareerBoxDataLinkSaga from './myCareerBoxDataLinkSaga'
import endSaga from './endSaga';
import urlSaga from './urlSaga'
import submissionRequestListSaga from './submissionRequestListSaga'
import submissionRequestDetailedSearchModalSaga from './submissionRequestDetailedSearchModalSaga'
import mcbMessageSendSaga from './mcbMessageSendSaga';
import bulkOperationSaga from './bulkOperationSaga';
import commonSettingsMenuInitializeSaga from './commonSettingsMenuSage';
// MCBリプレース MCBLS030 MCBLS020 START
import mcbPdfGeneratingSaga from './mcbPdfGeneratingSaga'
// MCBリプレース MCBLS030 MCBLS020 END
import mcbPdfDownloadSaga from './mcbPdfDownloadSaga';
// MCBHS110 START
import mcbMessageForcedStopSaga from './mcbMessageForcedStopSaga';
// MCBHS100 START
import submissionRequestForcedStopConfirmSaga from './submissionRequestForcedStopConfirmSaga';
// MCBHS090 START
import submissionRequestForcedStopSaga from './submissionRequestForcedStopSaga';
// MCBHS080 START
import mcbMessageDeadlineCompSaga from './mcbMessageDeadlineCompSaga';
import submissionRequestSendConfirmSaga from './submissionRequestSendConfirmSaga';
// MCBHS060 START
import deadlineChangeRequestSaga from './deadlineChangeRequestSaga';
// MCBHS070 START
import mcbDeadlineChangeConfirmSaga from './mcbDeadlineChangeConfirmSaga';
// MCAZS210 START
import mcbMessageDetailSaga from './mcbMessageDetailSaga';
// MCAZS210 END
// MCBHS050 START
import submisssionRequestMessageDetailSaga from './submissionRequestMessageDetailSaga'
// MCBHS050 END
// MCBHS030 START
import submissionRequestSendCompSaga from './submissionRequestSendCompSaga'
// MCBHS030 END
import tableUpdateSaga from './tableUpdateSaga';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(accountSaga),
    fork(errorSaga),
    fork(selectionStatusUpdateSaga),
    fork(extraAccountSaga),
    fork(mediaSaga),
    fork(signSaga),
    fork(recruitmentManagementFlagSaga),
    fork(selectionFlowSaga),
    fork(recruitmentGroupSaga),
    fork(interviewSaga),
    fork(globalMenuSaga),
    fork(companyLoginSaga),
    fork(affiliationRecruitmentSaga),
    fork(advancedSearchSaga),
    fork(advancedSearchModalSaga),
    fork(entryDetailSaga),
    fork(entryListSaga),
    fork(advancedSearchJobChangeSaga),
    fork(itemSpecificationSaga),
    fork(messageSendSaga),
    fork(mcbMessageSendSaga),
    fork(messageSendConfirmSaga),
    fork(messageSendCompSaga),
    fork(mcbMessageSendCompSaga),
    fork(applicantDetailForEmplymentSaga),
    fork(commentUpdateSaga),
    fork(starEvaluationUpdateSaga),
    fork(notAdoptedNotificationSaga),
    fork(errorReportSaga),
    fork(spamActReportSaga),
    fork(alertPreferenceSettingSaga),
    fork(attachmentListSaga),
    fork(actionPreSaga),
    fork(csvTemplateSaga),
    fork(tagManagementSaga),
    fork(messageListSaga),
    //MCBリプレース MCAZS170 start
    fork(mcbMessageListSaga),  
    //MCBリプレース MCAZS170 end
    fork(selectCriteriaSaga),
    // MCBリプレース MCBIS010 START
    fork(selectCriteriaMcbSaga),
    // MCBリプレース MCBIS010 END
    fork(commentHistorySaga),
    fork(progressStatusHistorySaga),
    // 転職March #75621 START
    fork(progressStatusChangeSaga),
    // 転職March #75621 END
    fork(entryHistorySaga),
    fork(seminarSaga),
    fork(messageTemplateSaga),
    // MCBリプレース MCBJS020 START
    fork(submissionReqTemplateSaga),
    // MCBリプレース MCBJS020 END
    fork(changeOthersSaga),
    fork(MCAXS340Saga),
    fork(applicantListSaga),
    fork(nameCheckListSaga),
    fork(csvOutputSaga),
    fork(entryRouteSaga),
    fork(aptitudeTestIDIssue),
    fork(entryUploadSaga),
    fork(messageDetailSaga),
    // fork(newsSaga),
    fork(recruitmentStatusReportSaga),
    fork(progressStagentAlertSaga),
    fork(pdfGeneratingSaga),
    fork(interviewScheduleSaga),
    fork(jobSeekerInfoSaga),
    fork(duplicateIdentificationSaga),
    fork(entrySaga),
    fork(redirectSaga),
    fork(facePhotoSaga),
    fork(myCareerBoxDataLinkSaga),
    fork(endSaga),
    fork(urlSaga),
    // MCBリプレース MCBHS040 START
    fork(submissionRequestListSaga),
    // MCBリプレース MCBHS040 END
    // MCBリプレース MCBHS171 Start
    fork(submissionRequestDetailedSearchModalSaga),
    // MCBリプレース MCBHS171 End
    // MCBリプレース MCAZS230 Start
    fork(mcbMessageSendConfirmSaga),
    // MCBリプレース MCAZS230 End
       // MCBリプレース MCBHS180 Start
    fork(bulkOperationSaga),
       // MCBリプレース MCBHS180 End
    // MCBリプレース MCABS010 Start
    fork(commonSettingsMenuInitializeSaga),
    // MCBリプレース MCABS010 End
    fork(mcbPdfDownloadSaga),
       
    // MCBリプレース MCBLS030 MCBLS020 START
    fork(mcbPdfGeneratingSaga),
    // MCBリプレース MCBLS030 MCBLS020 END
    // MCBリプレース MCBHS090 Start
    fork(submissionRequestForcedStopSaga),
    // MCBリプレース MCBHS090 End
    // MCBリプレース MCBHS100 Start
    fork(submissionRequestForcedStopConfirmSaga),
    // MCBリプレース MCBHS100 End
    // MCBリプレース MCBHS110 Start
    fork(mcbMessageForcedStopSaga),
    // MCBリプレース MCBHS110 End
    // MCBリプレース MCBHS080 Start
    fork(mcbMessageDeadlineCompSaga),
    // MCBリプレース MCBHS010 START
    fork(submissionRequestSendSaga),
    // MCBリプレース MCBHS010 END
    // MCBリプレース MCBHS020 START
    fork(submissionRequestSendConfirmSaga),
    // MCBリプレース MCBHS020 END
    // MCBリプレース MCBHS060 Start
    fork(deadlineChangeRequestSaga),
    // MCBリプレース MCBHS060 End
    // MCBリプレース MCBHS070 Start
    fork(mcbDeadlineChangeConfirmSaga),
    // MCBリプレース MCBHS070 End
    // MCBリプレース MCAZS210 START
    fork(mcbMessageDetailSaga),
    // MCBリプレース MCAZS210 END
    // MCBリプレース MCBHS050 START
    fork(submisssionRequestMessageDetailSaga),
    // MCBリプレース MCBHS050 END
    // MCBリプレース MCBHS030 START
    fork(submissionRequestSendCompSaga),
    // MCBリプレース MCBHS030 END
    fork(tableUpdateSaga),
  ])
}
