
import request from 'utils/request'

import { MCARS020InitRequest } from 'types/MCARS020/MCARS020InitRequest'

export const selectCriteriaRequest = (apiData: MCARS020InitRequest) =>
  request({
    url: '/MCARS020/init',
    method: 'post',
    data: apiData,
})
