import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'providers/routerProvider'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

interface Props {
  title: string;
  noPadding?: boolean
}

const Page: React.FC<Props> = ({ 
  title, 
  noPadding = false, 
  children 
}) => {
  const classes = useStyles();
  const { location } = useRouter();

  useEffect(() => {
    document.title = `${title} - MIWS`
  }, [location.pathname])

  return (
    <div className={noPadding ? '' : classes.root}>
      {children}
    </div>
  );
};

export default Page;
