/**
 * MCAZS190MessageQueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 検索対象
  // -
  searchTargetList: yup
    .string(),

  // 検索文字
  // -
  searchWordText: yup
    .string()
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

  // 更新日（FROM）
  // -
  updateFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 更新日（TO）
  // -
  updateToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 添付_あり
  // 添付_なし
  attachment: yup
    .object()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value.attachmentExist))
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value.attachmentNot)),

  // 一括送信のみ
  // -
  onlyBulkSend: yup
    .string()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
    
  // ページ数
  countPage: yup
    .number()
    .min(1, validationMessageSet.minValue)
});