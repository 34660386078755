import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  colors,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  FormLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormField, SubTitle, FieldGroup, Title } from 'componentsHsc'
import React, { useState, useEffect } from 'react'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector } from 'react-redux'
import { magiContants as contants } from 'utils/contants'
import {
  EntryPresenceObj,
  EntryPresenceConditionObj,
} from '../Config/searchConditionConfig'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important',
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      margin: theme.spacing(0, 1),
    },
  },
  subtitle: {
    color: 'mediumaquamarine',
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: 'lightgrey',
  },
  labelTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
}))

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const optionList = [
  { label: 'エントリーあり', value: '0' },
  { label: 'エントリーなし', value: '1' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

const EntryExitModal = ({ open, onClose, form }: Props) => {
  const classes = useStyles()

  const { entryBoxInfoList, externalEntryInfoList } = useSelector(
    (state: RootState) => state.advancedSearchModal.entryExitModalInfo
  )

  const [confirm, setConfirm] = useState(false)
  const [searchType, setSearchType] = useState('0')
  const [checkedAll, setCheckedAll] = useState('')

  //エントリーボックスのラジオボタン状態
  const [radioStateEntryBox, setRadioStateEntryBox] = useState([] as string[])
  //外部エントリーのラジオボタン状態
  const [radioStateExEntry, setRadioStateExEntry] = useState([] as string[])

  useEffect(() => {
    if (open) {
      if (form.values.entryPresenceObj) {
        if (form.values.entryPresenceObj.entryPresenceConditionJudgmentFlag) {
          setSearchType(form.values.entryPresenceObj.entryPresenceConditionJudgmentFlag.toString())
        } else {
          setSearchType('0')
        }
        if (form.values.entryPresenceObj.entryPresenceConditionObj) {
          //エントリーボックス再現
          let flglst2: string[] = []
          for (var i = 0; i < entryBoxInfoList.length; i++) {
            let flglst2val: string = ''
            for (
              var j = 0;
              j < form.values.entryPresenceObj.entryPresenceConditionObj.length;
              j++
            ) {
              const fromObj =
                form.values.entryPresenceObj.entryPresenceConditionObj[j]
              if (
                entryBoxInfoList[i].classificationFlag ===
                  fromObj.entryPresenceClassificationFlag &&
                entryBoxInfoList[i].contractMediaId.toString() ===
                  fromObj.entryPresenceApplicationRouteId &&
                entryBoxInfoList[i].entryBoxId.toString() ===
                  fromObj.entryPresenceEntryBoxId
              ) {
                flglst2val =
                  fromObj.entryPresenceFlag !== null
                    ? fromObj.entryPresenceFlag.toString()
                    : ''
                break
              }
            }
            flglst2.push(flglst2val)
          }
          setRadioStateEntryBox(flglst2)

          //外部エントリー再現
          let flglst3: string[] = []
          for (var i = 0; i < externalEntryInfoList.length; i++) {
            let flglst3val: string = ''
            for (
              var j = 0;
              j < form.values.entryPresenceObj.entryPresenceConditionObj.length;
              j++
            ) {
              const fromObj =
                form.values.entryPresenceObj.entryPresenceConditionObj[j]
              if (
                externalEntryInfoList[i].classificationFlag ===
                  fromObj.entryPresenceClassificationFlag &&
                externalEntryInfoList[i].mediaSettingId.toString() ===
                  fromObj.entryPresenceApplicationRouteId
              ) {
                flglst3val =
                  fromObj.entryPresenceFlag !== null
                    ? fromObj.entryPresenceFlag.toString()
                    : ''
                break
              }
            }
            flglst3.push(flglst3val)
          }
          setRadioStateExEntry(flglst3)
          if (!flglst2.includes('1') && !flglst2.includes('') && !flglst3.includes('1') && !flglst3.includes('')) {
            setCheckedAll('0')
          } else if (!flglst2.includes('0') && !flglst2.includes('') && !flglst3.includes('0') && !flglst3.includes('')) {
            setCheckedAll('1')
          } else {
            setCheckedAll('')
          }
        }
      } else {
        setSearchType('0')
        setRadioStateEntryBox(entryBoxInfoList.map(i => ''))
        setRadioStateExEntry(externalEntryInfoList.map(i => ''))
        setCheckedAll('')
      }
    }
  }, [open])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const handleChangeSearchType = (e: any) => {
    setSearchType(e.target.value)
  }

  /**
   * エントリーボックスのラジオボタンクリック時のイベント
   *
   * @param event
   * @param index
   */
  const handleClickEntryBoxRadio = (event: any, index: number) => {
    const newRadioStateEntry = [...radioStateEntryBox]
    const newRadioStateEx = [...radioStateExEntry]
    if (event.target.value === radioStateEntryBox[index]) {
      //ラジオボタンを非選択状態にする
      //Todo:他のモーダルと挙動を合わせるため、暫定でラジオボタンを非選択にできないようにする。
      newRadioStateEntry.splice(index, 1, '')
      setRadioStateEntryBox(newRadioStateEntry)
    } else {
      //ラジオボタンの選択状態を変更
      newRadioStateEntry.splice(index, 1, event.target.value)
      setRadioStateEntryBox(newRadioStateEntry)
    }
    if (((newRadioStateEntry.includes('0') && newRadioStateEntry.includes('1')) || newRadioStateEntry.includes('')) || ((newRadioStateEx.includes('0') && newRadioStateEx.includes('1')) || newRadioStateEx.includes(''))) {
      setCheckedAll('')
    } else {
      if (!newRadioStateEntry.includes('1') && !newRadioStateEntry.includes('') && !newRadioStateEx.includes('1') && !newRadioStateEx.includes('')) {
        setCheckedAll('0')
      }
      if (!newRadioStateEntry.includes('0') && !newRadioStateEntry.includes('') && !newRadioStateEx.includes('0') && !newRadioStateEx.includes('')) {
        setCheckedAll('1')
      }
    }
  }

  /**
   * 外部エントリーのラジオボタンクリック時のイベント
   *
   * @param event
   * @param index
   */
  const handleClickExEntryRadio = (event: any, index: number) => {
    const newRadioStateEx = [...radioStateExEntry]
    const newRadioStateEntry = [...radioStateEntryBox]
    if (event.target.value === radioStateExEntry[index]) {
      //ラジオボタンを非選択状態にする
      //Todo:他のモーダルと挙動を合わせるため、暫定でラジオボタンを非選択にできないようにする。
      newRadioStateEx.splice(index, 1, '')
      setRadioStateExEntry(newRadioStateEx)
    } else {
      //ラジオボタンの選択状態を変更
      newRadioStateEx.splice(index, 1, event.target.value)
      setRadioStateExEntry(newRadioStateEx)
    }
    if (((newRadioStateEntry.includes('0') && newRadioStateEntry.includes('1')) || newRadioStateEntry.includes('')) || ((newRadioStateEx.includes('0') && newRadioStateEx.includes('1')) || newRadioStateEx.includes(''))) {
      setCheckedAll('')
    } else {
      if (!newRadioStateEntry.includes('1') && !newRadioStateEntry.includes('') && !newRadioStateEx.includes('1') && !newRadioStateEx.includes('')) {
        setCheckedAll('0')
      }
      if (!newRadioStateEntry.includes('0') && !newRadioStateEntry.includes('') && !newRadioStateEx.includes('0') && !newRadioStateEx.includes('')) {
        setCheckedAll('1')
      }
    }
  }

  const handleSubmit = () => {
    let entryPresenceObj: EntryPresenceObj = {
      entryPresenceConditionJudgmentFlag: 1,
      entryPresenceConditionJudgmentName: '',
      entryPresenceConditionObj: [],
    }

    var searchValue = searchType
    if (searchValue) {
      if (searchValue === '0') {
        entryPresenceObj.entryPresenceConditionJudgmentFlag = 0
        entryPresenceObj.entryPresenceConditionJudgmentName = 'AND'
      } else if (searchValue === '1') {
        entryPresenceObj.entryPresenceConditionJudgmentFlag = 1
        entryPresenceObj.entryPresenceConditionJudgmentName = 'OR'
      }
    }
    if (entryBoxInfoList) {
      if (entryBoxInfoList.length > 0) {
        for (var i = 0; i < entryBoxInfoList.length; i++) {
          var fromValue: any = document.getElementsByName(
            `entryBoxInfoList[${i}]`
          )
          if (fromValue) {
            let entryPresenceConditionObj: EntryPresenceConditionObj = {
              entryPresenceClassificationFlag:
                entryBoxInfoList[i].classificationFlag,
              entryPresenceClassificationName:
                entryBoxInfoList[i].classificationFlag === '0'
                  ? 'エントリーボックス'
                  : '外部エントリー',
              entryPresenceApplicationRouteId: entryBoxInfoList[
                i
              ].contractMediaId.toString(),
              entryPresenceApplicationRouteName:
                entryBoxInfoList[i].contractMediaName,
              entryPresencecoordinationFormerEntryBoxId: entryBoxInfoList[
                i
              ].coordinationFormerEntryBoxId.toString(),
              entryPresenceEntryBoxId: entryBoxInfoList[
                i
              ].entryBoxId.toString(),
              entryPresenceEntryBoxName: entryBoxInfoList[i].entryBoxName,
              entryPresenceFlag: 0,
              entryPresenceName: '',
            }
            if (fromValue[0].checked) {
              entryPresenceConditionObj.entryPresenceFlag = 0
              entryPresenceConditionObj.entryPresenceName = 'エントリーあり'
              entryPresenceObj.entryPresenceConditionObj.push(
                entryPresenceConditionObj
              )
            } else if (fromValue[1].checked) {
              entryPresenceConditionObj.entryPresenceFlag = 1
              entryPresenceConditionObj.entryPresenceName = 'エントリーなし'
              entryPresenceObj.entryPresenceConditionObj.push(
                entryPresenceConditionObj
              )
            }
          }
        }
      }
    }
    if (externalEntryInfoList) {
      if (externalEntryInfoList.length > 0) {
        for (var i = 0; i < externalEntryInfoList.length; i++) {
          var fromValue: any = document.getElementsByName(
            `exEntryBoxInfoList[${i}]`
          )
          if (fromValue) {
            let entryPresenceConditionObj: EntryPresenceConditionObj = {
              entryPresenceClassificationFlag:
                externalEntryInfoList[i].classificationFlag,
              entryPresenceClassificationName:
                externalEntryInfoList[i].classificationFlag === '0'
                  ? 'エントリーボックス'
                  : '外部エントリー',
              entryPresenceApplicationRouteId: externalEntryInfoList[
                i
              ].mediaSettingId.toString(),
              entryPresenceApplicationRouteName:
                externalEntryInfoList[i].mediaRouteName,
              entryPresencecoordinationFormerEntryBoxId: null,
              entryPresenceEntryBoxId: null,
              entryPresenceEntryBoxName: null,
              entryPresenceFlag: 0,
              entryPresenceName: '',
            }
            if (fromValue[0].checked) {
              entryPresenceConditionObj.entryPresenceFlag = 0
              entryPresenceConditionObj.entryPresenceName = 'エントリーあり'
              entryPresenceObj.entryPresenceConditionObj.push(
                entryPresenceConditionObj
              )
            } else if (fromValue[1].checked) {
              entryPresenceConditionObj.entryPresenceFlag = 1
              entryPresenceConditionObj.entryPresenceName = 'エントリーなし'
              entryPresenceObj.entryPresenceConditionObj.push(
                entryPresenceConditionObj
              )
            }
          }
        }
      }
    }
    if (entryPresenceObj.entryPresenceConditionObj.length > 0) {
      form.setFieldValue('entryPresenceObj', entryPresenceObj)
    } else {
      form.setFieldValue('entryPresenceObj', null)
    }
    handleClose()
    // TODO: formik
  }

    const handleSelectAll = (e: any) => {
        if (checkedAll === e.target.value) {
            setRadioStateEntryBox(entryBoxInfoList.map(i => ''))
            setRadioStateExEntry(externalEntryInfoList.map(i => ''))
            setCheckedAll('')
        } else {
            let tmpval1: string[] = []
            for (var i = 0; i < entryBoxInfoList.length; i++) {
                tmpval1.push(e.target.value)
            }
            setRadioStateEntryBox(tmpval1)
    
            let tmpval2: string[] = []
            for (var i = 0; i < externalEntryInfoList.length; i++) {
                tmpval2.push(e.target.value)
            }
            setRadioStateExEntry(tmpval2)
            setCheckedAll(e.target.value)
        }
    }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>エントリー有無を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className={`ps-container ps ${classes.root}`}>
            <RadioGroup onChange={handleChangeSearchType} row style={{display: 'inline-block'}}>
              {searchOptionList
                ? searchOptionList.map((item) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={searchType === item.value}
                            value={item.value}
                          />
                        }
                        label={item.label}
                      />
                    </>
                  ))
                : null}
            </RadioGroup>
            <RadioGroup row style={{display: 'inline-block', marginLeft: '380px'}}>
                <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                <FormControlLabel
                    control={
                        <Radio color='primary' value='0' name='selectSearch4' onClick={handleSelectAll} checked={checkedAll === '0'} />
                    }
                    label='エントリーあり'
                />
                <FormControlLabel
                    control={
                        <Radio color='primary' value='1' name='selectSearch4' onClick={handleSelectAll} checked={checkedAll === '1'} />
                    }
                    label='エントリーなし'
                />
            </RadioGroup>
            {entryBoxInfoList && entryBoxInfoList.length > 0 ? (
              <>
                <FieldGroup>
                  <SubTitle
                    variant='h5'
                    className={classes.subtitle}
                    noUnderline>
                    エントリーボックス
                  </SubTitle>
                  {entryBoxInfoList.map((entryBoxInfo, index) => (
                    <FormField>
                      <Title
                        title={`${entryBoxInfo.contractMediaName} ${entryBoxInfo.coordinationFormerEntryBoxId} ${entryBoxInfo.entryBoxName}`}
                      />
                      <div className={classes.dateRange}>
                        <RadioGroup row>
                          {optionList.map(option => (
                            <FormControlLabel
                              control={
                                <Radio
                                  color='primary'
                                  onClick={event =>
                                    handleClickEntryBoxRadio(event, index)
                                  }
                                  checked={
                                    radioStateEntryBox[index] === option.value
                                  }
                                  name={`entryBoxInfoList[${index}]`}
                                  value={option.value}
                                />
                              }
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    </FormField>
                  ))}
                </FieldGroup>
                <Divider className={classes.divider} />
              </>
            ) : null}
            {externalEntryInfoList && externalEntryInfoList.length > 0 ? (
              <>
                <FieldGroup>
                  <SubTitle
                    variant='h5'
                    className={classes.subtitle}
                    noUnderline>
                    外部取込
                  </SubTitle>
                  {externalEntryInfoList.map((externalEntryInfo, index) => (
                    <FormField>
                      <Title title={externalEntryInfo.mediaRouteName} />
                      <div className={classes.dateRange}>
                        <RadioGroup row>
                          {optionList.map(option => (
                            <FormControlLabel
                              control={
                                <Radio
                                  color='primary'
                                  onClick={event =>
                                    handleClickExEntryRadio(event, index)
                                  }
                                  checked={
                                    radioStateExEntry[index] === option.value
                                  }
                                  name={`exEntryBoxInfoList[${index}]`}
                                  value={option.value}
                                />
                              }
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    </FormField>
                  ))}
                </FieldGroup>
                <Divider className={classes.divider} />
              </>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS511_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EntryExitModal
