import {
  colors,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { TabPanel } from 'componentsHsc'
import { Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setOpen,
  setEntryReplyModalOpen,
  setManagementModalOpen,
} from 'reducers/advancedSearchJobChangeReducer'
import { useInViewport } from 'ahooks'
import SearchRequestValidation from 'validations/MCAXS401/SearchRequestValidation'
import Applicant from './Applicant'
import Basic from './Basic'
import {
  initSearchCondition,
  SearchCondition as EntryListSearchCondition,
} from 'pages/MCAXS020/searchConditionConfig'
import {
  fromEntryList,
  toEntryList,
  SearchCondition,
} from './Config/searchConditionConfig'
import RecruitmentManagement from './RecruitmentManagement'
import { initeQueryRequest } from './Config/formConfig'
import { magiContants as contants, magiContants, validationMessageSet } from '../../utils/contants'
import { getMessage } from 'common/messageUtil'
import { RootState } from 'reducers'
import { openModal } from 'reducers/messageReducer'
import TabItem from 'componentsHsc/AdvancedSearch/TabItem'
import {
  setDialogOpen,
  getSelectCriteriaList,
} from 'reducers/selectCriteriaManagementReducer'
import { setDisplaySearchCriteriaTitleOpenFlag } from 'reducers/entryListReducer'
import { MCAXS020SearchRequest } from 'types/MCAXS020/MCAXS020SearchRequest'
import { search, setSearchCondition } from 'reducers/entryListReducer'
import MCARS030 from 'pages/MCARS030'
import ManagementModal from './Modal/ManagementModal'
import EntryReplyModal from './Modal/EntryReplyModal'
import OverlaySearch from 'componentsHsc/OverlaySearch'
import { isOverlayShow } from 'componentsHsc/AdvancedSearch'
import { miscRegexSet } from 'utils/regex'
import { SEARCH_BUTTON_ID, TABLE_CONTAINER_ID } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
  },
  tab: {
    backgroundColor: '#e6e6e6',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
      opacity: 1,
    },
  },
  title: {
    minWidth: '218px',
    '&::before': {
      backgroundSize: '100% auto !important',
      fontSize: '2.4rem !important',
    },
  },
  disablePointerEvents: {
    pointerEvents: 'none',
  },
}))

const ExpansionPanelDetails = withStyles({
  root: {
    display: 'block',
    margin: 25,
  },
})(AccordionDetails)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: colors.blue[600],
    height: 8,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    flexGrow: 0,
  },
  expanded: {},
})(AccordionSummary)

export type FormControl = FormikProps<SearchCondition>

interface Props {
  initialValues?: EntryListSearchCondition
}

const AdvancedSearchJobChange = ({
  initialValues = initSearchCondition,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [disableTouch, setDisableTouch] = useState(false)

  const tableInViewport = useInViewport(
    () => document.querySelector(`#${TABLE_CONTAINER_ID}`)
  )

  const buttonInViewport = useInViewport(
    () => document.querySelector(`#${SEARCH_BUTTON_ID}`)
  )

  const orderObjArray = useSelector((state: RootState) => state.advancedSearchJobChange.orderObjArray)

  // 初期表示データ設定
  const { open, entryReplyModalOpen, managementModalOpen } = useSelector(
    (state: RootState) => state.advancedSearchJobChange
  )

  // ExpansionPanel
  const handleClick = () => {
    dispatch(setOpen(!open))
  }

  // Tabs
  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (_: any, newValue: number) => {
    setCurrentTab(newValue)
  }

  const searchRequest = useSelector(
    (state: RootState) => state.entryList.searchRequest
  )

  const searchResult = useSelector(
    (state: RootState) => state.entryList.searchResult
  )

  const mySearch = useSelector((state: RootState) => state.entryList.mySearch)

  const searchCondition = useSelector(
    (state: RootState) => state.entryList.searchCondition
  )

  const sortCondition = useSelector(
    (state: RootState) => state.entryList.sortCondition
  )

  const globalMenu = useSelector((state: RootState) => state.globalMenu)

  //検索ボタン押下,My検索ボタン押下どちらがされたのかのstate保持
  const [stateClickType, setStateClickType] = useState('')

  // Formikのsubmitボタン押下時のonClick処理
  const handleSubmitButtonClick = (type: string) => {
    setStateClickType(type)
  }

  //Formikのsubmitボタン押下時のsubmit処理
  const handleSubmit = (data: any) => {
    businessCheck(data, stateClickType)
  }

  //検索条件クリア
  const handleReset = () => {
    setCurrentTab(0)
    //※年齢、メールアドレス項目のみ、初期値を空文字としないと、
    //初期化されないため、下記の処理を入れています。
    const newInit: EntryListSearchCondition = {
      ...initSearchCondition,
      ageFrom: '',
      ageTo: '',
      mailAddress: '',
    }
    dispatch(setSearchCondition(newInit))
  }

  //ページトップへスクロール
  const scrollToAnchor = () => {
    let anchorElement = document.getElementById('top')
    if (anchorElement) {
      anchorElement.scrollIntoView()
    }
  }

  // 業務チェック
  const businessCheck = (data: any, type: string) => {
    if (
      data.applicantId &&
      contants.MESSAGECODE_MCAXS401_001_MAXCOUNT <
        (data.applicantId as string).split(/\r\n|\n/g).filter(i => i !== '')
          .length
    ) {
      // 応募者管理ID 件数チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS401_001)))
    } else if (
      data.name &&
      contants.MESSAGECODE_MCAXS401_002_MAXCOUNT <
        (data.name as string).split(/\r\n|\n/g).filter(i => i !== '').length
    ) {
      // 氏名 件数チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS401_002)))
    } else if (type === 'search') {
      // 検索データ設定
      const newSearchRequest: MCAXS020SearchRequest = Object.assign(
        {},
        searchRequest
      )
      newSearchRequest.sourceFunctionId = 'MCAXS401'
      let newData = {
        ...data,
        name: data.name ? (data.name as string).replace(/[ 　\t]/g, '') : data.name,
      }
      newSearchRequest.searchCondition = JSON.stringify(toEntryList(newData, orderObjArray))
      newSearchRequest.searchCount = 1
      newSearchRequest.sortKey = sortCondition.order

      dispatch(
        search({
          request: newSearchRequest,
          isInitFilter: false,
          onSearch: () => {
            dispatch(setOpen(false))
            dispatch(setDisplaySearchCriteriaTitleOpenFlag(false))
            scrollToAnchor()
          },
        })
      )
      dispatch(setSearchCondition(toEntryList(data, orderObjArray)))
    } else if (type === 'save') {
      // MY検索に保存
      dispatch(
        setSearchCondition(
          toEntryList(data, orderObjArray)
        )
      )
      dispatch(getSelectCriteriaList())
      dispatch(setDialogOpen(true))
    }
  }

  const chkDate = (datestr: string) => {
    var iYear = Number(datestr.substr(0, 4)) - 0
    var iMonth = Number(datestr.substr(5, 2)) - 0
    var iDay = Number(datestr.substr(8, 2)) - 0
    if (iYear > 9999) {
      return false;
    }
    if ((iMonth >= 1) && (iMonth <= 12)) {
      var iMaxDay = 31;
      switch (iMonth) {
        case 4:
        case 6:
        case 9:
        case 11:
          iMaxDay = 30;
          break;
        case 2:
          if ((iYear % 4) == 0) {
            if ((iYear % 100) == 0) {
              if ((iYear % 400) == 0) { iMaxDay = 29; }
              else { iMaxDay = 28; }
            }
            else { iMaxDay = 29; }
          }
          else { iMaxDay = 28; }
          break;
        default:
          break;
      }
      if ((iDay >= 1) && (iDay <= iMaxDay)) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Paper className={classes.root}>
        <Accordion
          expanded={open}
          TransitionProps={{
            onEnter: () => {
              setDisableTouch(true)
            },
            onEntered: () => {
              setDisableTouch(false)
            },
            onExit: () => {
              setDisableTouch(true)
            },
            onExited: () => {
              setDisableTouch(false)
            },
            timeout: 500,
          }}
          className={`${disableTouch ? classes.disablePointerEvents : ''}`}>
          <ExpansionPanelSummary>
            <Typography
              className={`btn high on-icon icon-search detailed-search ${classes.title}`}
              onClick={handleClick}>
              詳細な検索条件を{open === true ? '閉じる' : '指定する'}
            </Typography>
          </ExpansionPanelSummary>
          <Formik
            onSubmit={handleSubmit}
            onReset={handleReset}
            initialValues={fromEntryList(initialValues)}
            enableReinitialize
            validationSchema={SearchRequestValidation}
            // 相関チェック
            validate={values => {
              const errors = {
                ageTo: '',
                applicationTo: '',
                postingEndTime: '',
                priorityTo: '',
                errorflag: '',
                educationalBackground: '',
              }
              if (values.educationalBackground) {
                values.educationalBackground.map((i: any) =>{
                  if(i.trim().length === 0) {
                  errors.educationalBackground = validationMessageSet.required()
                  errors.errorflag = '1'
                  }
                })
              }

              if (values.ageTo && values.ageTo.match(miscRegexSet.halfWidthNumberIndention)) {
                if (
                  values.ageFrom && parseInt(values.ageFrom) && 
                  values.ageTo && parseInt(values.ageTo) &&
                  parseInt(values.ageFrom) > parseInt(values.ageTo)
                ) {
                  errors.ageTo = getMessage(magiContants.Correlation_Item_Check_Error_Message)
                  errors.errorflag = '1'
                }
              }

              const regDate = /^(\d{4}\/\d{1,2}\/\d{1,2}){0,1}$/u
              if (values.applicationTo) {
                var newDate = values.applicationTo.substring(0, 10)
                if (!newDate.match(regDate) || chkDate(newDate) == false) {
                  errors.applicationTo = validationMessageSet.date()
                  errors.errorflag = '1'
                } else {
                  if (
                    values.applicationFrom &&
                    values.applicationTo &&
                    values.applicationFrom > values.applicationTo
                  ) {
                    errors.applicationTo = '終了日が開始日よりも前です'
                    errors.errorflag = '1'
                  }
                }
              } 

              if (values.postingEndTime) {
                var newDate = values.postingEndTime.substring(0, 10)
                if (!newDate.match(regDate) || chkDate(newDate) == false) {
                  errors.postingEndTime = validationMessageSet.date()
                  errors.errorflag = '1'
                } else {
                  if (
                    values.postingStartTime &&
                    values.postingEndTime &&
                    values.postingStartTime > values.postingEndTime
                  ) {
                    errors.postingEndTime = '掲載終了日が掲載開始日よりも前です'
                    errors.errorflag = '1'
                  }
                }
              } 

              if (values.priorityTo && values.priorityTo.match(miscRegexSet.halfWidthNumberIndention)) {
                if (
                  values.priorityFrom && parseInt(values.priorityFrom) &&
                  values.priorityTo && parseInt(values.priorityTo) &&
                  parseInt(values.priorityFrom) > parseInt(values.priorityTo)
                ) {
                  errors.priorityTo = getMessage(magiContants.Correlation_Item_Check_Error_Message)
                  errors.errorflag = '1'
                }
              }
              if (errors.errorflag === '1'){
                return errors
              }
            }}
            render={formik => {
              return (
                <ExpansionPanelDetails>
                  <Tabs value={currentTab} onChange={handleChange} centered>
                    <Tab label='基本情報' className={classes.tab} />
                    <Tab label='応募情報' className={classes.tab} />
                    <Tab label='採用管理情報' className={classes.tab} />
                  </Tabs>
                  <TabItem
                    onSubmitButtonClick={handleSubmitButtonClick}
                    serviceMode={globalMenu.serviceMode}>
                    <TabPanel value={currentTab} index={0}>
                      {/* 基本情報 */}
                      <Basic form={formik} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                      {/* 応募情報 */}
                      <Applicant form={formik} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                      {/* 採用管理情報 */}
                      <RecruitmentManagement form={formik} />
                    </TabPanel>
                    <OverlaySearch
                      show={isOverlayShow({
                        open, 
                        tableInViewport,
                        buttonInViewport
                      })}
                      onClick={handleSubmitButtonClick}
                      serviceMode={globalMenu.serviceMode}
                    />
                  </TabItem>
                </ExpansionPanelDetails>
              )
            }}
          />
        </Accordion>
      </Paper>

      <MCARS030
        escsId={mySearch.entrySearchCriteriaSettingId}
        scName={mySearch.searchCriteriaName}
        sCriteria={searchCondition}
      />

      <EntryReplyModal
        open={entryReplyModalOpen}
        onClose={() => {
          dispatch(setEntryReplyModalOpen(false))
        }}
        form={fromEntryList(initialValues)}
      />

      <ManagementModal
        open={managementModalOpen}
        onClose={() => {
          dispatch(setManagementModalOpen(false))
        }}
        form={fromEntryList(initialValues)}
      />
    </>
  )
}

export default AdvancedSearchJobChange
