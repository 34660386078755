/**
 * MCAZS170MessageQueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
  // 検索文字
  searchWordText: yup
    .string()
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
      miscRegexSet.halfWidth.test(value)
    ),

  // 送信期間（FROM）
  sentFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),

  // 送信期間（TO）
  sentToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),

  // 提出締切日（FROM）
  sentFromDateDeadline: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),

  // 提出締切日（TO）
  sentToDateDeadline: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) =>
      miscRegexSet.date.test(value)
    ),

  // 添付
  attachment: yup.object().shape({
    // 添付_あり
    attachmentExist: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) =>
        miscRegexSet.flag.test(value)
      ),

    // 添付_なし
    attachmentNot: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) =>
        miscRegexSet.flag.test(value)
      ),
  }),

  // 一括送信のみ
  onlyBulkSend: yup
    .string()
    .test('isFlag', validationMessageSet.flag, (value: any) =>
      miscRegexSet.flag.test(value)
    ),

  // ページ数
  countPage: yup.number().min(1, validationMessageSet.minValue),
})
