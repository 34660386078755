import moment from 'moment'

export const redirect = (url: string) => (window.location.href = url)

// 適性テスト結果表示URL取得
// 適性テストの結果を表示するためのURLを取得する。
export const getWebTestResultUrl = (
  jobSeekerId: string,
  aptitudeTestId: string
) => {
  return 'https://web1.e-exams.jp/0000/wia/fb/auth1.asp'
}

// 年齢を算出する
// 生年月日(yyyy/MM/dd形式)を基に年齢を算出する
export const getAge = (birthday: string | null) => {
  if (birthday && moment(birthday, 'YYYY/MM/DD').isValid()) {
    const birth = birthday.split('/')
    const _birth = parseInt('' + birth[0] + birth[1] + birth[2])
    const today = new Date()
    const _today = parseInt(
      '' +
        today.getFullYear() +
        affixZero(today.getMonth() + 1) +
        affixZero(today.getDate())
    )
    return parseInt('' + (_today - _birth) / 10000)
  } else {
    return ''
  }
}
const affixZero = (num: number) => {
  if (num < 10) return '0' + num
  return '' + num
}

// 画面から設定された添付ファイルが存在するかチェックする。
// true：ファイルあり　false: ファイルなし
// yield call で呼び出すこと
export const isFileExist = (file: File) => {
  return new Promise((resolve) => {
    const reader  = new FileReader();
    reader.onload = () => resolve(true)
    reader.onerror = () => resolve(false)
    reader.readAsArrayBuffer(file)
  })
}
