import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Status, PropsInterface,PropsSend } from './interface'
import './style_progress.css'

const useStyles = makeStyles({
  line: {
    margin: '10px 26px !important'
  }
})

export default function ProgressStatus(props: PropsInterface) {
  const classes = useStyles()

  // 左進捗の選考ステップ
  const leftProgress: Status[] = props.leftProgress
  // 左進捗の選考ステップ
  const rightProgress: Status[] = props.rightProgress
  // 旗の位置
  const [flag, setFlag] = React.useState(props.flag)
  // 左進捗の選択位置
  const [leftSelect, setLeftSelect] = React.useState(props.leftSelect)
  // 右進捗の選択位置
  const [rightSelect, setRightSelect] = React.useState(props.rightSelect)
  // 左進捗のグレー表示の標識
  const [leftGrey, setLeftGrey] = React.useState(props.leftGrey)
  // 右進捗のグレー表示の標識
  const [rightGrey, setRightGrey] = React.useState(props.rightGrey)
  // 対象の標識
  const [target, setTarget] = React.useState(props.target)
  // リセットの場合、状態がリセット
  React.useEffect(() => {
    if (flag != props.flag ||
      leftSelect != props.leftSelect ||
      rightSelect != props.rightSelect ||
      leftGrey != props.leftGrey ||
      rightGrey != props.rightGrey ||
      target != props.target
    )
    {
      setFlag(props.flag)
      setLeftSelect(props.leftSelect)
      setRightSelect(props.rightSelect)
      setLeftGrey(props.leftGrey)
      setRightGrey(props.rightGrey)
      setTarget(props.target)}
  }, [props.flag, props.leftSelect,props.rightSelect,props.leftGrey, props.rightGrey,props.target])

  // 左進捗のクリックイベント
  const leftClick = (event: any) => {
    if (!props.clickFlag){
      return
    }
    // クリックした選考ステップに旗を移動する
    setFlag(event.target.id)
    // 左進捗の選択位置にクリックした選考ステップを設定
    setLeftSelect(event.target.id)
    // 右進捗の選択位置をクリア
    setRightSelect(0)
    // 左進捗がグレー表示をしません
    setLeftGrey(false)
    const propsNew : PropsSend = {
      flag: event.target.id, // 旗の位置
      leftSelect: event.target.id, // 左進捗の選択位置
      rightSelect: 0, // 右進捗の選択位置
      leftGrey: false, // 左進捗のグレー表示の標識
      rightGrey: rightGrey, // 右進捗のグレー表示の標識
      eventTarget: event.target, // クリック対象
    }
    // 選考ステップが変更後実行したメソッド
    props.handlerClick(propsNew)
  }

  // 右進捗のクリックイベント
  const rightClick = (event: any) => {
    if (!props.clickFlag){
      return
    }
    // クリックした選考ステップに旗を移動する
    setFlag(event.target.id)
    // 右進捗の選択位置にクリックした選考ステップを設定
    setRightSelect(event.target.id)
    // 右進捗がグレー表示をしません
    setRightGrey(false)
    // 左進捗がグレー表示をします
    setLeftGrey(true)
    const propsNew : PropsSend = {
      flag: event.target.id, // 旗の位置
      leftSelect: leftSelect, // 左進捗の選択位置
      rightSelect: event.target.id, // 右進捗の選択位置
      leftGrey: true, // 左進捗のグレー表示の標識
      rightGrey: false, // 右進捗のグレー表示の標識
      eventTarget: event.target, // クリック対象
    }
    // 選考ステップが変更後実行したメソッド
    props.handlerClick(propsNew)
  }

  // 左進捗のスタイル名の取得イベント
  const getLeftclassName = (statusId: number) => {
    // 左進捗の一番左の選考ステップのID
    let leftId = leftProgress[0].id
    // 左進捗の一番右の捗ステータスのID
    let rightId = leftProgress[leftProgress.length - 1].id
    // スタイル名
    let className = 'style-progress-status-text-color '

    // 旗のスタイル名
    if (statusId == flag) {
      className = className + 'current '
    }

    // グレー表示の以外の場合、デフォルトスタイルを使用します
    if (!leftGrey) {
      return className
    }

    if (leftSelect == -1) {
      if (statusId == leftId) {
        className = className + 'progress-status-together-left'
      } else if (statusId == rightId) {
        className = className + 'progress-status-together-right'
      } else {
        className = className + 'progress-status-together-whole'
      }
    } else {
      if (statusId > leftId && statusId < leftSelect) {
        // 選考ステップの左と右がグレー
        className = className + 'progress-status-grey-whole'
      } else if (statusId == leftId && statusId < leftSelect) {
        // 選考ステップの右がグレー
        className = className + 'progress-status-grey-right'
      } else if (statusId == leftSelect && statusId == rightId) {
        // 選考ステップの左がグレー
        className = className + 'progress-status-grey-left'
      } else if (statusId == leftSelect && statusId != leftId) {
        // 選考ステップの左がグレー、右が白
        className = className + 'progress-status-grey-left-white'
      } else if (statusId == leftSelect && statusId == leftId) {
        // 選考ステップの右が白
        className = className + 'progress-status-together-left'
      }
    }

    return className
  }

  // 右進捗のスタイル名の取得イベント
  const getRightclassName = (statusId: number) => {
    // スタイル名
    let className = 'style-progress-status-text-color '

    // 旗のスタイル名
    if (statusId == flag) {
      className = className + 'current '
    }

    // グレー表示の以外の場合、デフォルトスタイルを使用します
    if (!rightGrey) {
      return className
    }

    if (statusId == rightSelect) {
      // 右選考ステップのグレースタイル
      className = className + 'progress-status-grey-middle'
    }

    return className
  }

  // 対象外バッジの位置の取得イベント
  const targetWidth = (): string => {
    let divWidth = (leftProgress.length - 1) * 53 + 70
    return divWidth + 'px'
  }

  return (
    <div className='progress-status style-progress-status' tabIndex={-1}>
      {target ? null : (
        <div className='style-target' style={{ width: targetWidth() }}>
          <label className='style-target-label'>対象外</label>
        </div>
      )}
      {rightProgress.map(status => {
        let radioProps = null
        let labelProps = null

        labelProps = (
          <label
            htmlFor={status.id.toString()}
            className={getRightclassName(status.id)}
            tabIndex={flag == status.id ? 0 : -1}>
            {status.name}
          </label>
        )
        radioProps = (
          <input
            name='status'
            id={status.id.toString()}
            value={status.settingId}
            type='radio'
            disabled={!target}
            checked={status.id == rightSelect ? true : false}
            onClick={rightClick}></input>
        )

        return (
          <React.Fragment>
            {radioProps}
            {labelProps}
          </React.Fragment>
        )
      })}
      <hr className={classes.line} />
      {leftProgress.map(status => {
        let radioProps = null
        let labelProps = null

        labelProps = (
          <label
            style = { leftProgress.length <= 1 ? { backgroundSize : '20px 20px,24px 24px,28px 28px,100% 0px' }: undefined}
            htmlFor={status.id.toString()}
            className={getLeftclassName(status.id)}
            tabIndex={flag == status.id ? 0 : -1}>
            {status.name}
          </label>
        )
        radioProps = (
          <input
            name='progress'
            id={status.id.toString()}
            value={status.settingId}
            type='radio'
            disabled={!target}
            checked={status.id == leftSelect ? true : false}
            onClick={leftClick}></input>
        )

        return (
          <React.Fragment>
            {radioProps}
            {labelProps}
          </React.Fragment>
        )
      })}
    </div>
  )
}
