import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Row, mediaListInitialValues, MediaListAndCount } from 'pages/MCAIS010/formConfig'
import { MCAIS020CreateRequest } from 'types/MCAIS020/MCAIS020CreateRequest'
import UpdateRequestData from 'types/MCAIS030/MCAIS030UpdateRequest'
import { mediaCreateInitialValues } from 'pages/MCAIS020/formConfig'
import { mediaUpdateInitialValues } from 'pages/MCAIS030/formConfig'
import DeleteData from 'types/MCAIS030/MCAIS030DeleteRequest'

export interface MediaList {
  mediaListResults: Row[]
  updateMediaListResults: Row[]
  mediaListCount: number
}
export interface MediaCreate {
  mediaName: string; //応募経路（経路）名
}
export interface MediaUpdate {
  mediaSettingId: string
  mediaName: string
  sysVersionNumber: number
}
interface Media {
  MediaList: MediaList
  MediaUpdate: MediaUpdate
  MediaCreate: MediaCreate
  updateDialogOpen: boolean
  createDialogOpen: boolean
  defFlag: number
}
const initialState: Media = {
  MediaList: mediaListInitialValues,
  MediaCreate: mediaCreateInitialValues,
  MediaUpdate: mediaUpdateInitialValues,
  updateDialogOpen: false,
  createDialogOpen: false,
  defFlag: 0
}

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // -----------------MCAIS0I0_応募経路一覧_START------------------------//
    // 画面初期表示時用
    getMediaList(state) {
      return state
    },
    // DBから一覧データ取得
    setMediaList(state, action: PayloadAction<MediaListAndCount>) {
      state.MediaList.mediaListResults = action.payload.mediaList
      state.MediaList.mediaListCount = action.payload.count
    },
    // 表示順変更時用
    updateMediaSortOrder(state, action: PayloadAction<Row[]>) {
      state.MediaList.mediaListResults = action.payload
      return state
    },
    checkMediaCount(state) {
      return state;
    },
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    // -----------------MCAIS0I0_応募経路一覧_END------------------------//

    // -----------------MCAIS020_応募経路登録_START------------------------//
    setMediaFlagCreate(state, action: PayloadAction<MCAIS020CreateRequest>) {
      state.MediaCreate = action.payload
      return state;
    },
    // -----------------MCAIS020_応募経路登録_END------------------------//

    // -----------------MCAIS030_応募経路編集_START------------------------//
    setMediaEdit(state, action: PayloadAction<string>) {
      return state
    },
    editMediaSuccess: (state, action: PayloadAction<UpdateRequestData>) => {
      state.MediaUpdate = action.payload
      return state
    },
    updateMediaAction(state, action: PayloadAction<UpdateRequestData>) {
      return state
    },
    deleteMedia(state: any, action: PayloadAction<DeleteData>) {
      return state
    },
    setUpdateDialogOpen(state, action: PayloadAction<boolean>) {
      state.updateDialogOpen = action.payload
      return state
    },
    // -----------------MCAIS030_応募経路編集_END------------------------//

    setDefFlag(state, action: PayloadAction<number>){
      state.defFlag = action.payload
      return state
    },
  },
})

export const {
  getMediaList,
  setMediaList,
  updateMediaSortOrder,
  checkMediaCount,
  setCreateDialogOpen,
  setMediaFlagCreate,
  updateMediaAction,
  setMediaEdit,
  editMediaSuccess,
  deleteMedia,
  setUpdateDialogOpen,
  setDefFlag,
} = mediaSlice.actions
export default mediaSlice.reducer
