
/** MCAZS101呼び出し用 */
export interface MCAZS091JobSeekerSelectionInfoDto {
  progressStatusSettingId: number[]
  selectionFlowSettingId: number
  
}

export interface MessageConfVal {
  messageTemplateSettingId: string|null
  entryId: number[]
  selectionManagementId: string[]
  progressStatusSettingId: number[]
  selectionFlowSettingId: number[]
  sendTimeFlag: string
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  messageTemplateSttingId: string|null
  templateName: string
  notAdoptedNotificationType: string|null
}

export interface MCAZS101selectionStepDto {
  selectionName: string
  progressStatusSettingId: string
  progressType: string
  displayOrder: number
  progressName: string
  decisionDivisionType: string
  selectedId: string
}

export interface MCAZS101selectionManagementDto {
  selectionManagementId: string
  sysVersionNumber: number
}

export interface MCAZS101progressStatusManagementDto {
  selectionManagementId: number
  progressStatusSettingId: number
  sysVersionNumber: number 
}

export interface InitResult {
  selectionStepDtos: MCAZS101selectionStepDto[]
  selectionManagementDtos: MCAZS101selectionManagementDto[]
  progressStatusManagementDtos: MCAZS101progressStatusManagementDto[]
}

export interface NewRequest {
  messageTemplateName: string | null
  destination: number
  // [phase2] start
  sendToNonMember: number
  // [phase2] end
  senderCompanyName: string
  senderMailAddress: string
  allowReply: number
  subject: string
  text: string
  attachmentName: string[]
  fileManagementId: string[]
  entryBoxId: number[]
  seminarId: number[]
  homepageTitle1: string
  homepageTitle2: string
  homepageTitle3: string
  homepageUrl1: string
  homepageUrl2: string
  homepageUrl3: string
  barcodeDisplay: number
  replyNecessaryFlag: string
  replyLimitTime: string|null
}

export interface OrverWriteRequest {
  messageTemplateId: string | null
  destination: number
  // [phase2] start
  sendToNonMember: number
  // [phase2] end
  senderCompanyName: string
  senderMailAddress: string
  allowReply: number
  subject: string
  text: string
  attachmentName: string[]
  fileManagementId: string[]
  entryBoxId: number[]
  seminarId: number[]
  homepageTitle1: string
  homepageTitle2: string
  homepageTitle3: string
  homepageUrl1: string
  homepageUrl2: string
  homepageUrl3: string
  barcodeDisplay: number
  replyNecessaryFlag: string
  replyLimitTime: string|null
  sysVersionNumber: string | null
}

const mCAZS091JobSeekerSelectionInfoDto: MCAZS091JobSeekerSelectionInfoDto = {
  progressStatusSettingId: [],
  selectionFlowSettingId: 0,
}

const messageConfVal: MessageConfVal = {
  messageTemplateSettingId: "",
  entryId: [],
  selectionManagementId: [],
  progressStatusSettingId: [],
  selectionFlowSettingId: [],
  sendTimeFlag: "",
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  messageTemplateSttingId: "",
  templateName: "",
  notAdoptedNotificationType: null,
}

const mCAZS101selectionStepDto: MCAZS101selectionStepDto = {
  selectionName: "",
  progressStatusSettingId: "",
  progressType: "",
  displayOrder: 0,
  progressName: "",
  decisionDivisionType: "",
  selectedId: "",
}

const mCAZS101selectionManagementDto: MCAZS101selectionManagementDto = {
  selectionManagementId: "",
  sysVersionNumber: 0,
}

const mCAZS101progressStatusManagementDto: MCAZS101progressStatusManagementDto = {
  selectionManagementId: 0,
  progressStatusSettingId: 0,
  sysVersionNumber: 0,
}

const initResult: InitResult = {
  selectionStepDtos: [],
  selectionManagementDtos: [],
  progressStatusManagementDtos: [],
}

const newRequest: NewRequest = {
  messageTemplateName: "",
  destination: 0,
  // [phase2] start
  sendToNonMember: 0,
  // [phase2] end
  senderCompanyName: "",
  senderMailAddress: "",
  allowReply: 0,
  subject: "",
  text: "",
  attachmentName: [],
  fileManagementId: [],
  entryBoxId: [],
  seminarId: [],
  homepageTitle1: "",
  homepageTitle2: "",
  homepageTitle3: "",
  homepageUrl1: "",
  homepageUrl2: "",
  homepageUrl3: "",
  barcodeDisplay: 0,
  replyNecessaryFlag: "",
  replyLimitTime: "",
}

const orverWriteRequest: OrverWriteRequest = {
  messageTemplateId: "",
  destination: 0,
  // [phase2] start
  sendToNonMember: 0,
  // [phase2] end
  senderCompanyName: "",
  senderMailAddress: "",
  allowReply: 0,
  subject: "",
  text: "",
  attachmentName: [],
  fileManagementId: [],
  entryBoxId: [],
  seminarId: [],
  homepageTitle1: "",
  homepageTitle2: "",
  homepageTitle3: "",
  homepageUrl1: "",
  homepageUrl2: "",
  homepageUrl3: "",
  barcodeDisplay: 0,
  replyNecessaryFlag: "",
  replyLimitTime: "",
  sysVersionNumber: "",
}

export {
  mCAZS091JobSeekerSelectionInfoDto,
  messageConfVal,
  initResult,
  newRequest,
  orverWriteRequest,
}

