import Cookies from 'js-cookie';

const TOKEN_KEY = 'client-jwt';

let tokenCopy: string | undefined;

export const getToken = () => {
    if (tokenCopy === undefined) {
        tokenCopy = Cookies.get(TOKEN_KEY);
    }
    return Cookies.get(TOKEN_KEY);
}
export const getTokenCopy = () => tokenCopy;
export const setTokenCopy = (token: string) => tokenCopy = token;
export const setToken = (token: string) => {
    var a = window.location.protocol;
    switch(a){
    case 'https:':
      Cookies.set(TOKEN_KEY, token, { secure: true });
      break;
    default: 
      Cookies.set(TOKEN_KEY, token);
    }
    tokenCopy = token;
}
export const removeToken = () => {
    Cookies.remove(TOKEN_KEY);
    tokenCopy = undefined;
}
