import React, { useState } from 'react'
import { Paginate, BulkChange, OtherOperation } from 'componentsHsc'
import ClickTooltip from './ClickTooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  empty: {
    display: 'flex',
    height: '80vh',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '100px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      backgroundColor: 'transparent',
      height: '48px',
      marginTop: '14px',
      width: '48px',
    },
  },
  iconChangeProgress: {
    paddingBottom: "12px!important",
  },
})

const mockData = [
  {
    deleted: false,
    id: '1004077462',
    name: '毎日 ナビ太',
    age: '28',
    address: '東京都',
    sex: '男性',
    evaluation: '4',
    unsubscribe: true,
    datetime: '2019/01/18 10:45',
    description: '未経験歓迎！ノルマ無し！内勤営業★月の残業時間10時間未満',
    replicate: true,
    education:
      '国立大学法人北陸先端科学技術大学院大学/マテリアルサイエンス研究科/ゲーム･エンタテインメント領域(大学)/システム設計・分析系',
    current: '法人営業 正社員 在職',
    experience: [
      'プログラマー（WEB・オープン・モバイル系） 2年以上',
      'プロジェクトマネジャー・リーダー(WEB・オープン・モバイル系） 3年以上',
    ],
    badgeList: [
      'マッチング済',
      '〇〇〇〇〇1',
      '〇〇〇〇〇2',
      '〇〇〇〇〇3',
      '〇〇〇〇〇4',
      '〇〇〇〇〇5',
    ],
    campus: '2020＜営業職＞',
    progess: '1',
    result: '判定中',
    notApplicable: false,
    tagList: [
      '競合出身',
      '優先対応',
      '○○○○3級',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
    ],
    message: {
      type: '送信',
      title: 'RE:日程候補日のご連絡',
      datetime: '2019/01/20 08:45',
    },
    unread: false,
  },
  {
    deleted: false,
    id: '1004077463',
    name:
      '毎日 森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森森',
    age: '28',
    address: '東京都',
    sex: '男性',
    evaluation: '3',
    unsubscribe: false,
    datetime: '2019/01/18 10:45',
    description: '未経験歓迎！ノルマ無し！内勤営業★月の残業時間10時間未満',
    replicate: false,
    education:
      '国立大学法人北陸先端科学技術大学院大学/マテリアルサイエンス研究科/ゲーム･エンタテインメント領域(大学)/システム設計・分析系',
    current: '法人営業 正社員 在職',
    experience: [
      'プログラマー（WEB・オープン・モバイル系） 2年以上',
      'プロジェクトマネジャー・リーダー(WEB・オープン・モバイル系） 3年以上',
    ],
    badgeList: ['マッチング済'],
    campus: '2020＜営業職＞',
    progess: '1',
    result: '判定中',
    notApplicable: false,
    tagList: [
      '競合出身',
      '優先対応',
      '○○○○3級',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
    ],
    message: {
      type: '受信',
      title: 'RE:日程候補日のご連絡',
      datetime: '2019/01/20 08:45',
    },
    unread: true,
  },
  {
    deleted: false,
    id: '1004077465',
    name: '毎日 ナビ太',
    age: '28',
    address: '東京都',
    sex: '男性',
    evaluation: '4',
    unsubscribe: false,
    datetime: '2019/01/18 10:45',
    description: '未経験歓迎！ノルマ無し！内勤営業★月の残業時間10時間未満',
    replicate: false,
    education:
      '国立大学法人北陸先端科学技術大学院大学/マテリアルサイエンス研究科/ゲーム･エンタテインメント領域(大学)/システム設計・分析系',
    current: '法人営業 正社員 在職',
    experience: [
      'プログラマー（WEB・オープン・モバイル系） 2年以上',
      'プロジェクトマネジャー・リーダー(WEB・オープン・モバイル系） 3年以上',
    ],
    badgeList: ['マッチング済'],
    campus: '2020＜営業職＞',
    progess: '1',
    result: '判定中',
    notApplicable: true,
    tagList: [
      '競合出身',
      '優先対応',
      '○○○○3級',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
    ],
    message: {
      type: '受信',
      title: 'RE:日程候補日のご連絡',
      datetime: '2019/01/20 08:45',
    },
    unread: false,
  },
  {
    deleted: true,
    id: '1004077466',
    name: '毎日 ナビ太',
    age: '28',
    address: '東京都',
    sex: '男性',
    evaluation: '4',
    unsubscribe: true,
    datetime: '2019/01/18 10:45',
    description: '未経験歓迎！ノルマ無し！内勤営業★月の残業時間10時間未満',
    replicate: true,
    education:
      '国立大学法人北陸先端科学技術大学院大学/マテリアルサイエンス研究科/ゲーム･エンタテインメント領域(大学)/システム設計・分析系',
    current: '法人営業 正社員 在職',
    experience: [
      'プログラマー（WEB・オープン・モバイル系） 2年以上',
      'プロジェクトマネジャー・リーダー(WEB・オープン・モバイル系） 3年以上',
    ],
    badgeList: [
      'マッチング済',
      '〇〇〇〇〇',
      '〇〇〇〇〇',
      '〇〇〇〇〇',
      '〇〇〇〇〇',
      '〇〇〇〇〇',
    ],
    campus: '2020＜営業職＞',
    progess: '1',
    result: '判定中',
    notApplicable: false,
    tagList: [
      '競合出身',
      '優先対応',
      '○○○○3級',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
      '○○○○○○○',
    ],
    message: {
      type: '送信',
      title: 'RE:日程候補日のご連絡',
      datetime: '2019/01/20 08:45',
    },
    unread: false,
  },
]

const rowsPerPage = 10

export default ({ data = mockData }) => {
  const classes = useStyles()
  const [selectedAccounts, setSelectedAccounts] = useState([] as string[])
  const [page, setPage] = useState(0)
  const startIndex = page * rowsPerPage
  const endIndex =
    startIndex + rowsPerPage > data.length
      ? data.length
      : startIndex + rowsPerPage

  const handleSelectAll = () => {
    setSelectedAccounts(() =>
      selectedAccounts.length === data.length ? [] : data.map(i => i.id)
    )
  }

  const handleSelectOne = (id: string) => () => {
    selectedAccounts.includes(id)
      ? setSelectedAccounts(selectedAccounts.filter(i => i !== id))
      : setSelectedAccounts([...selectedAccounts, id])
  }

  const handleChangePage = (data: any) => {
    setPage(data.selected)
  }

  const [bulkChangeState, setBulkChangeState] = useState(false)
  const handleBulkChangeState = () => {
    setBulkChangeState(!bulkChangeState)
  }

  const [otherOperationState, setOtherOperationState] = useState(false)
  const handleOtherOperationState = () => {
    setOtherOperationState(!otherOperationState)
  }

  const [statusList, setStatusList] = useState(
    data.reduce((result, current) => {
      result[current.id as any] = {
        status: current.result,
        open: false,
      }
      return result
    }, {} as any)
  )

  const handleCloseTooltip = (id: string, status: string) => () => {
    setStatusList({
      ...statusList,
      [id]: {
        status,
        open: false,
      },
    })
  }

  const handleChangeStatus = (id: string, status: string) => () => {
    setStatusList({
      ...statusList,
      [id]: {
        status,
        open: true,
      },
    })
  }

  const handleClick = (id: string) => () => {
    window.open(`https://example.com/${id}`)
  }

  return (
    <>
      <section>
        <h1>
          件数：<strong>{data.length}</strong>件
        </h1>
        <div className='selection-table entry'>
          <div className='other-operation'>
            <button
              type='button'
              disabled={selectedAccounts.length ? false : true}
              onClick={handleOtherOperationState}
              className={`btn most circular icon-changeProgress ${classes.iconChangeProgress}`}
              data-action='check-open-change-progress'
              data-balloon='changeProgress'>
              合否・選考
              <br />
              ステップを
              <br />
              一括変更
            </button>
            <button
              type='button'
              disabled={selectedAccounts.length ? false : true}
              onClick={handleBulkChangeState}
              className='btn most circular icon-otherOperation'
              data-action='open-balloon-otherOperations'
              data-balloon='otherOperation'>
              その他の
              <br />
              一括操作
            </button>
          </div>
          {data.length === 0 ? (
            <div className={classes.empty}>
              条件に一致する検索結果がありませんでした。
            </div>
          ) : (
            <>
              <label>
                <input type='checkbox' onClick={handleSelectAll} />
                <mark></mark>全て選択
              </label>
              <label
                className={
                  selectedAccounts.length === data.length ? 'btn show' : 'btn'
                }
                data-balloon='selectAll'>
                <input type='checkbox' />
                <mark>検索結果の全件を選択</mark>
              </label>
              <hr />
              <label>
                <select>
                  <option>ID順（昇順）</option>
                </select>
                でソート
              </label>
              <Paginate
                pageCount={Math.ceil(data.length / rowsPerPage)}
                forcePage={page}
                onPageChange={handleChangePage}
              />
              {data.slice(startIndex, endIndex).map(item => {
                const classNameDuplicate = item.notApplicable ? 'duplicate' : ''
                const classNameUnread = item.unread ? 'unread' : ''

                return item.deleted ? (
                  <article
                    onClick={handleClick(item.id)}
                    className='duplicate'
                    key={item.id}>
                    <header>
                      <h1>
                        <a href='#'>このデータは削除されました。 ({item.id})</a>
                      </h1>
                    </header>
                    <label>
                      <input
                        type='checkbox'
                        checked={selectedAccounts.includes(item.id)}
                        onChange={handleSelectOne(item.id)}
                      />
                      <mark></mark>
                    </label>
                  </article>
                ) : (
                  <article
                    onClick={handleClick(item.id)}
                    key={item.id}
                    className={`${classNameDuplicate} ${classNameUnread}`}>
                    <header>
                      <h1>
                        <a href='#'>
                          {item.name} ({item.id})
                        </a>
                        <span>
                          {item.age}歳　{item.address}　{item.sex}
                          <input
                            type='number'
                            defaultValue={item.evaluation}
                            className='evaluation-meter'
                          />
                          {item.unsubscribe && (
                            <i className='badge ptn2'>退会済み</i>
                          )}
                        </span>
                      </h1>
                      <time>応募日時:{item.datetime}</time>
                    </header>
                    <h2>
                      <i className='badge ptn7'>マイナビ転職</i>
                      <i className='badge ptn3'>応募種別</i>
                      <span>{item.description}</span>
                      {item.replicate && <i className='badge ptn6'>重複</i>}
                    </h2>
                    <dl className='lst-icon-head'>
                      <dt className='only-icon icon-lst-hd-education'>学歴</dt>
                      <dd>{item.education}</dd>
                      <dt className='only-icon icon-lst-hd-experiencedHistory'>
                        経験
                      </dt>
                      <dd>
                        {item.experience.length}社
                        <br />
                        {item.current}
                        {item.experience.map(i => (
                          <React.Fragment key={i}>
                            <br />
                            {i}
                          </React.Fragment>
                        ))}
                      </dd>
                    </dl>
                    <ul>
                      {item.badgeList.map(i => (
                        <li className='badge ptn3' key={i}>
                          {i}
                        </li>
                      ))}
                    </ul>
                    <div>
                      <span>{item.campus}</span>
                      <em>{item.progess}次面接</em>
                      <span className='group-horizontal'>
                        <ClickTooltip
                          open={
                            statusList[item.id].open &&
                            statusList[item.id].status === '判定中'
                          }
                          onClose={handleCloseTooltip(item.id, '判定中')}
                          title='判定中'
                          to='/'>
                          <label>
                            <input
                              type='radio'
                              name={`select-article-${item.id}`}
                              checked={statusList[item.id].status === '判定中'}
                              onChange={handleChangeStatus(item.id, '判定中')}
                            />
                            <mark>判定中</mark>
                          </label>
                        </ClickTooltip>
                        <ClickTooltip
                          open={
                            statusList[item.id].open &&
                            statusList[item.id].status === '合格'
                          }
                          onClose={handleCloseTooltip(item.id, '合格')}
                          title='合格'
                          to='/'>
                          <label>
                            <input
                              type='radio'
                              name={`select-article-${item.id}`}
                              checked={statusList[item.id].status === '合格'}
                              onChange={handleChangeStatus(item.id, '合格')}
                            />
                            <mark>合格</mark>
                          </label>
                        </ClickTooltip>
                        <ClickTooltip
                          open={
                            statusList[item.id].open &&
                            statusList[item.id].status === '不合格'
                          }
                          onClose={handleCloseTooltip(item.id, '不合格')}
                          title='不合格'
                          to='/'>
                          <label>
                            <input
                              type='radio'
                              name={`select-article-${item.id}`}
                              checked={statusList[item.id].status === '不合格'}
                              onChange={handleChangeStatus(item.id, '不合格')}
                            />
                            <mark>不合格</mark>
                          </label>
                        </ClickTooltip>
                      </span>
                      <div className={classes.alignRight}>
                        {item.notApplicable ? (
                          <button
                            type='button'
                            className='btn only-icon icon-btn_include'
                            title='このエントリーを対象外となる前の進捗に戻します'>
                            対象
                          </button>
                        ) : (
                          <button
                            type='button'
                            className='btn only-icon icon-notApplicable'
                            title='このエントリーを対象外にします'>
                            対象外
                          </button>
                        )}
                      </div>
                    </div>
                    <dl className='lst-icon-head'>
                      <dt className='only-icon icon-lst-hd-tag'>タグ</dt>
                      <dd>
                        {item.tagList.map((tag, tagIndex) => (
                          <React.Fragment key={tagIndex}>
                            <a href='#'>{tag}</a>
                            {tagIndex + 1 === item.tagList.length ? '' : ','}
                          </React.Fragment>
                        ))}
                      </dd>
                    </dl>
                    <a href='#' className='read'>
                      <i
                        className={
                          item.message.type === '受信'
                            ? 'badge ptn1'
                            : 'badge ptn8'
                        }>
                        {item.message.type}
                      </i>
                      <b>{item.message.title}</b>
                      <time>{item.message.datetime}</time>
                    </a>
                    <label>
                      <input
                        type='checkbox'
                        checked={selectedAccounts.includes(item.id)}
                        onChange={handleSelectOne(item.id)}
                      />
                      <mark></mark>
                    </label>
                  </article>
                )
              })}
            </>
          )}
        </div>
      </section>
      <BulkChange type='startJob' source='selection' show={bulkChangeState} />
      <OtherOperation
        show={otherOperationState}
        onClose={handleOtherOperationState}
      />
    </>
  )
}
