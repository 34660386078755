import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldGroup, FormField, SubTitle } from 'componentsHsc'
import Toggle from './Toggle'
import TextFieldError from './TextFieldError'
import TextFieldForMessageError from './TextFieldForMessageError'
import GroupOptions from '../GroupOptions'
import { FastField, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import { getMessage } from 'common/messageUtil'
import { ManagementItemCondition } from '../Config/itemConfig'
import UncheckableToggle from '../UncheckableToggle'
import { setSearchCondition } from 'reducers/entryListReducer'
import { SearchCondition, toEntryList, innerSortFunc } from '../Config/searchConditionConfig'
import * as yup from 'yup'
import { onKeyDown } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  selectListItem:{
    minWidth: '265px',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
    '& > :last-child': {
      flex: 2,
    },
    '& span': {
      color: colors.grey[600],
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: '#1950a6',
      textDecoration: 'underline',
      '& span': {
          color: '#1950a6',
      },
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  dateRange: {
    display: 'flex',
    minWidth:'200px',
    maxWidth:'600px',
    justifyContent: 'space-between',
    '& > div': {
      margin: theme.spacing(0, 1),
      flexBasis: '206px',
    },
  },
  container: {
    paddingLeft: '50px',
    minWidth:'900px',
  },
  dialog: {
    '& .MuiDialog-scrollPaper':{
      alignItems: window.navigator.userAgent
      .toLocaleLowerCase()
      .match(/(trident)\/([\d.]+)/) ? 'stretch' : 'center',
    },
    '& .MuiDialog-paperScrollPaper':{
      overflow: 'auto',
      overflowX: 'hidden',
    },
  },
}))

const optionList1 = [
  { label: '値がある', value: '0' },
  { label: '値がない', value: '1' },
]

const optionList2 = [
  { label: '値がある', value: '0' },
  { label: '値がない', value: '1' },
  { label: 'を除く', value: '2' },
]

const typeList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: SearchCondition
}

const ManagementModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [selectedSub, setSelectedSub] = useState(-1)
  const handleSubClick = (index: number) => () => {
    setSelectedSub(index)
  }
  interface ManagementItemError {
    managementItemTagText: string
    managementItemTagFrom: string
    managementItemFlagTo: string
    errorsMessage: any
    manageIndex: number
    manageName: string
  }
  const { management, managementModalStateJobChange } = useSelector(
    (state: RootState) => state.itemSpecification
  )

  const handleSave = (data: any) => {
    const newForm = { ...form }
    const newConditionList = (data.conditionList as ManagementItemCondition[])
      .filter(i => i.managementItemPresenceFlag !== '')
      .map(i => ({
        ...i,
        managementItemFlagCheckBoxObj:
          i.managementItemPresenceFlag === '1'
            ? []
            : [...i.managementItemFlagCheckBoxObj].sort((a, b) => {
              //選択肢の並びを画面順にソート
              const orderObjArray = management.find(j => j.id === i.recruitmentManagementFlagSettingId)
              if (orderObjArray) {
                return innerSortFunc(
                  { managementItemFlagCheckBoxId: a.value },
                  { managementItemFlagCheckBoxId: b.value },
                  orderObjArray.optionList.map(j => ({ managementItemFlagCheckBoxId: j.value }))
                )
              } else {
                return 0
              }
            }),
        managementItemTagText:
          i.managementItemPresenceFlag === '1'
            ? ''
            : i.managementItemTagText,
        managementItemTagFrom:
          i.managementItemPresenceFlag === '1'
            ? ''
            : i.managementItemTagFrom,
        managementItemFlagTo:
          i.managementItemPresenceFlag === '1'
            ? ''
            : i.managementItemFlagTo,
      }))
    if (newConditionList.length > 0) {
      const newData = { ...data }
      newData.conditionList = newConditionList
      newForm.managementItemObj = newData
    } else {
      newForm.managementItemObj = null
    }
    dispatch(setSearchCondition(toEntryList(newForm)))
    onClose()
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    toggle(false)()
    onClose()
  }

  const FieldCheck = (num: number, values: any) => {
    if (values.conditionList !== null) {
      return values.conditionList[num]
        ? (values.conditionList[num].managementItemPresenceFlag == '' ? false : values.conditionList[num].managementItemPresenceFlag === '1')
        : true
    }
  }

  const managementItemTagTextYup = yup.object().shape({
    managementItemTagText: yup.string(),
  })

  useEffect(() => {
    setSelectedSub(-1)
  }, [open])

  let mySubmitForm: ((arg0: any) => void) | null = null;

  const bindSubmit = (submitForm: any) => {
    mySubmitForm = submitForm
  }

  const handleSubmitMyForm = (e: any) => {
    if (mySubmitForm) {
        mySubmitForm(e);
    }
  };

  return (
    <>
      {/* 転職March #90253 START */}
      <Dialog open={open} maxWidth='lg' fullWidth className={classes.dialog + ' mainDialog'}>
      {/* 転職March #90253 END */}
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>管理項目を指定</label>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={managementModalStateJobChange}
            enableReinitialize
            onSubmit={handleSave}
            validate={values => {
              let errorsflg = true
              const errors = { conditionList: [] as ManagementItemError[] }
              if (values.conditionList) {
                values.conditionList.forEach(
                  (item: ManagementItemError, index: number) => {
                    if (
                      item.managementItemTagText &&
                      item.managementItemTagText !== ''
                    ) {
                      try {
                        yup.object().shape({
                          [`conditionList[${index}].managementItemTagText`]: yup.string()
                        }).validateSync({
                          [`conditionList[${index}].managementItemTagText`]: item.managementItemTagText,
                        })
                      } catch (err) {
                        errors.conditionList.push({
                          managementItemTagText: '',
                          managementItemTagFrom: '',
                          managementItemFlagTo: '',
                          errorsMessage: err.message.props.children,
                          manageIndex: index,
                          manageName: 'managementItemTagText',
                        })
                        errorsflg = false
                      }
                    }
                    if ((item.managementItemTagFrom).length > magiContants.MCAXS501_MAXLENGTH_POSITVE_NUMBER &&
		                    parseInt(item.managementItemTagFrom) > magiContants.MCAXS501_NUMBERR_0){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                      })
                      errorsflg = false
                    }
                    if ((item.managementItemTagFrom).length > magiContants.MCAXS501_MAXLENGTH_NEGATIVE_NUMBER &&
		                    parseInt(item.managementItemTagFrom) < magiContants.MCAXS501_NUMBERR_0){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                      })
                      errorsflg = false
                    }
                    if (parseInt(item.managementItemTagFrom) > magiContants.MCAXS501_MAXVALUE) {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                      })
                      errorsflg = false
                    }
                    if (parseInt(item.managementItemTagFrom) < magiContants.MCAXS501_MINVALUE){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                      })
                      errorsflg = false
                    }
                    if (!/^[-+]?(0|[1-9]\d*)(\.\d+)?$/u.test(item.managementItemTagFrom) && item.managementItemTagFrom !== '') {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                       })
                       errorsflg = false
                    }
                    else if (!/^[-+]?(0|[1-9]\d*)(\.\d{1,2})?$/u.test(item.managementItemTagFrom) && item.managementItemTagFrom !== '') {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemTagFrom',
                       })
                       errorsflg = false
                    }
                    if ((item.managementItemFlagTo).length > magiContants.MCAXS501_MAXLENGTH_POSITVE_NUMBER &&
		                    parseInt(item.managementItemFlagTo) > magiContants.MCAXS501_NUMBERR_0){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                    }
                    if ((item.managementItemFlagTo).length > magiContants.MCAXS501_MAXLENGTH_NEGATIVE_NUMBER &&
		                    parseInt(item.managementItemFlagTo) < magiContants.MCAXS501_NUMBERR_0){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                    }
		                if (parseInt(item.managementItemFlagTo) > magiContants.MCAXS501_MAXVALUE) {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                    }
                    if (parseInt(item.managementItemFlagTo) < magiContants.MCAXS501_MINVALUE){
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                    }
                    if (!/^[-+]?(0|[1-9]\d*)(\.\d+)?$/u.test(item.managementItemFlagTo) && item.managementItemFlagTo !== '') {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                     } 
                    else if (!/^[-+]?(0|[1-9]\d*)(\.\d{1,2})?$/u.test(item.managementItemFlagTo) && item.managementItemFlagTo !== '') {
                        errors.conditionList.push({
                        managementItemTagText: '',
                        managementItemTagFrom: '',
                        managementItemFlagTo: '',
                        errorsMessage: '',
                        manageIndex: index,
                        manageName: 'managementItemFlagTo',
                       })
                       errorsflg = false
                    }
                    if (
                      item.managementItemTagFrom &&
                      item.managementItemFlagTo
                    ) {
                      if (
                        parseInt(item.managementItemTagFrom) >
                        parseInt(item.managementItemFlagTo)
                      ) {
                        errors.conditionList.push({
                          managementItemTagText: '',
                          managementItemTagFrom: '',
                          managementItemFlagTo: '',
                          errorsMessage:
                            getMessage(magiContants.Correlation_Item_Check_Error_Message),
                          manageIndex: index,
                          manageName: 'managementItemTagFrom',
                        })
                        errorsflg = false
                      } else {
                        errors.conditionList.push({
                          managementItemTagText: '',
                          managementItemTagFrom: '',
                          managementItemFlagTo: '',
                          errorsMessage: '',
                          manageIndex: index,
                          manageName: 'managementItemTagFrom',
                        })
                      }
                    }
                  }
                )
                if (errors.conditionList.length > 0) {
                  let returnflg = false
                  errors.conditionList.forEach(
                    (item: ManagementItemError, index: number) => {
                      if (errorsflg == false) {
                        returnflg = true
                      }
                    }
                  )
                  if (returnflg) {
                    return errors
                  }
                }
              }
            }}
            render={formikProps => {
              const { values } = formikProps
              bindSubmit(formikProps.submitForm)
              return (
                <>
                <Form onKeyDown={onKeyDown}>
                  {/* AND/OR */}
                  <FastField
                    name='typeCode'
                    component={Toggle}
                    optionList={typeList}
                    row
                    target='managementCondition'
                  />
                  <div className={classes.select}>
                    <div className={classes.selectListItem}>
                      <List>
                        {management.map((item, index) =>
                          item.invisibleFlag ? (
                            undefined
                          ) : (
                            <ListItem
                              button
                              component='a'
                              key={item.id}
                              selected={selectedSub === index}
                              onClick={handleSubClick(index)}
                              href={`#${item.id}`}>
                              <ListItemText primary={item.title} />
                            </ListItem>
                          )
                        )}
                      </List>
                      </div>
                    <div className={`ps-container ps ${classes.container}`}>
                      {management.map((item, index) =>
                        item.invisibleFlag ? (
                          undefined
                        ) : (
                          <FieldGroup key={item.title} id={item.id}>
                            <SubTitle variant='h5'>{item.title}</SubTitle>
                            {/* 値あり/なし/を除く */}
                            <FormField>
                              <Field
                                name={`conditionList[${index}].managementItemPresenceFlag`}
                                optionList={item.type === '10' || item.type === '20' ? optionList1 : optionList2}
                                selectOptionList={item.optionList}
                                row
                                component={UncheckableToggle}
                                target='managementItemPresence'
                                targetIndex={index}
                              />
                            </FormField>
                            {/* テキスト入力 */}
                            <FormField>
                              {item.type === '10' ? (
                                <Field
                                  name={`conditionList[${index}].managementItemTagText`}
                                  // [phase2] start
                                  multiline
                                  component={TextFieldForMessageError}
                                  // [phase2] end
                                  errorsItemIndex={index}
                                  placeholder='キーワードを入力'
                                  disabled={FieldCheck(index, values)}
                                  relatedName={`conditionList[${index}].managementItemPresenceFlag`}
                                />
                              ) : item.type === '20' ? (
                                <div className={classes.dateRange}>
                                  <Field
                                    name={`conditionList[${index}].managementItemTagFrom`}
                                    component={TextFieldError}
                                    errorsItemIndex={index}
                                    placeholder='半角数字'
                                    disabled={FieldCheck(index, values)}
                                    relatedName={`conditionList[${index}].managementItemPresenceFlag`}
                                  />
                                  ~
                                  <Field
                                    name={`conditionList[${index}].managementItemFlagTo`}
                                    component={TextFieldError}
                                    errorsItemIndex={index}
                                    placeholder='半角数字'
                                    disabled={FieldCheck(index, values)}
                                    relatedName={`conditionList[${index}].managementItemPresenceFlag`}
                                  />
                                </div>
                              ) : (
                                <Field
                                  name={`conditionList[${index}].managementItemFlagCheckBoxObj`}
                                  component={GroupOptions}
                                  optionList={item.optionList}
                                  row
                                  disabled={FieldCheck(index, values)}
                                  relatedName={`conditionList[${index}].managementItemPresenceFlag`}
                                  index={index}
                                />
                              )}
                            </FormField>
                          </FieldGroup>
                        )
                      )}
                    </div>
                  </div>
                </Form>
                </>
              )
            }}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={toggle(true)}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmitMyForm}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={show} onClose={toggle(false)}>
        <DialogTitle>{getMessage('MCAXS501-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManagementModal
