import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import {
    alertPreferenceInit,
    setAlertPreferenceSettingInfoResults,
    interFixWaitAlertOn,
    interFixWaitAlertOff,
    passStsContAlertOn,
    passStsContAlertOff,
    progsResdenceAlertOn,
    progsResdenceAlertOff,
    interCancelAlertOn,
    interCancelAlertOff,
    contUnanswrAlertOn,
    contUnanswrAlertOff,
    interCurtDayAlertOn,
    interCurtDayAlertOff,
    validAlertMailLinkFlag,
} from 'reducers/alertPreferenceSettingReducer'
import {
    selectAlertMail,
    updateAlertMail,
    setAlertMail,
    setNotificationMailDialogOpen,
    setLinkFlag,
} from 'reducers/alertMailPreferenceReducer'
import { 
    initRequest,
    interFixWaitAlertOnRequest,
    interFixWaitAlertOffRequest,
    passStsContAlertOnRequest,
    passStsContAlertOffRequest,
    progsResdenceAlertOnRequest,
    progsResdenceAlertOffRequest,
    interCancelAlertOnRequest,
    interCancelAlertOffRequest,
    contUnanswrAlertOnRequest,
    contUnanswrAlertOffRequest,
    interCurtDayAlertOnRequest,
    interCurtDayAlertOffRequest,
    mailSettingLinkRequest,
} from 'apis/MCAFS010Api'
import { 
    initAlertMailDisplayRequest,
    updateAlertMailRequest,
} from 'apis/MCAFS020Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { getDynamicMessage } from 'common/messageUtil'

function* alertPreferenceInitSaga(action: ReturnType<typeof alertPreferenceInit>) {
    try {
        const data = yield call(initRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interFixWaitAlertOnSaga(action: ReturnType<typeof interFixWaitAlertOn>) {
    try {
        const data = yield call(interFixWaitAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interFixWaitAlertOffSaga(action: ReturnType<typeof interFixWaitAlertOff>) {
    try {
        const data = yield call(interFixWaitAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* passStsContAlertOnSaga(action: ReturnType<typeof passStsContAlertOn>) {
    try {
        const data = yield call(passStsContAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* passStsContAlertOffSaga(action: ReturnType<typeof passStsContAlertOff>) {
    try {
        const data = yield call(passStsContAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* progsResdenceAlertOnSaga(action: ReturnType<typeof progsResdenceAlertOn>) {
    try {
        const data = yield call(progsResdenceAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* progsResdenceAlertOffSaga(action: ReturnType<typeof progsResdenceAlertOff>) {
    try {
        const data = yield call(progsResdenceAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interCancelAlertOnSaga(action: ReturnType<typeof interCancelAlertOn>) {
    try {
        const data = yield call(interCancelAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interCancelAlertOffSaga(action: ReturnType<typeof interCancelAlertOff>) {
    try {
        const data = yield call(interCancelAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* contUnanswrAlertOnSaga(action: ReturnType<typeof contUnanswrAlertOn>) {
    try {
        const data = yield call(contUnanswrAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* contUnanswrAlertOffSaga(action: ReturnType<typeof contUnanswrAlertOff>) {
    try {
        const data = yield call(contUnanswrAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interCurtDayAlertOnSaga(action: ReturnType<typeof interCurtDayAlertOn>) {
    try {
        const data = yield call(interCurtDayAlertOnRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

function* interCurtDayAlertOffSaga(action: ReturnType<typeof interCurtDayAlertOff>) {
    try {
        const data = yield call(interCurtDayAlertOffRequest, action.payload);
        yield put(setAlertPreferenceSettingInfoResults(data));
        yield put(openSnackbar(getDynamicMessage({
            messageID: data.message,
            title: data.title
        })))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

//次期9月対応 #63073 start
// MCAFS010 遷移リンクフラグ押下
function* validAlertMailLinkFlagSaga(action: ReturnType<typeof validAlertMailLinkFlag>) {
    try {
        const data = yield call(mailSettingLinkRequest, action.payload);
        if (data === "Successed") {
            yield put(selectAlertMail({linkFlag: action.payload.linkFlag}))
        }
    } catch (error) {
      yield put(openModal(error.message))
    }
}
// MCAFS020 画面初期表示
function* selectAlertMailSaga(action: ReturnType<typeof selectAlertMail>) {
    try {
      yield put(setLinkFlag(action.payload.linkFlag))
      const data = yield call(initAlertMailDisplayRequest, action.payload);
      yield put(setAlertMail(data))
      yield put(setNotificationMailDialogOpen(true))
    } catch (error) {
      yield put(openModal(error.message))
    }
}
// MCAFS020「更新する」ボタンを押下
function* updateAlertMailSaga(action: ReturnType<typeof updateAlertMail>) {
    try {
      // 通知メール更新
      const data = yield call(updateAlertMailRequest, action.payload.updateAlertMailRequest);
      yield put(setNotificationMailDialogOpen(false));
      yield put(openSnackbar(getDynamicMessage({
        messageID: data.message,
        title: data.title
      })))
      yield put(alertPreferenceInit(action.payload.recruitmentManagementDivision));
    } catch (error) {
      yield put(openModal(error.message))
    }
}
// 次期9月対応 #63073 end
export default function* alertPreferenceSettingSaga() {
    yield all([
        takeEvery(alertPreferenceInit, alertPreferenceInitSaga),
        takeEvery(interFixWaitAlertOn, interFixWaitAlertOnSaga),
        takeEvery(interFixWaitAlertOff, interFixWaitAlertOffSaga),
        takeEvery(passStsContAlertOn, passStsContAlertOnSaga),
        takeEvery(passStsContAlertOff, passStsContAlertOffSaga),
        takeEvery(progsResdenceAlertOn, progsResdenceAlertOnSaga),
        takeEvery(progsResdenceAlertOff, progsResdenceAlertOffSaga),
        takeEvery(interCancelAlertOn, interCancelAlertOnSaga),
        takeEvery(interCancelAlertOff, interCancelAlertOffSaga),
        takeEvery(contUnanswrAlertOn, contUnanswrAlertOnSaga),
        takeEvery(contUnanswrAlertOff, contUnanswrAlertOffSaga),
        takeEvery(interCurtDayAlertOn, interCurtDayAlertOnSaga),
        takeEvery(interCurtDayAlertOff, interCurtDayAlertOffSaga),
        // 次期9月対応 #63073 
        takeEvery(selectAlertMail, selectAlertMailSaga),
        takeEvery(validAlertMailLinkFlag, validAlertMailLinkFlagSaga),
        takeEvery(updateAlertMail, updateAlertMailSaga),
        // 次期9月対応 #63073 end
    ])
}