
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBHS180OutputPdfRequest } from 'types/MCBHS180/MCBHS180OutputPdfRequest'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'
import { MCBHS180InitResultDto, MCBHS180InitResultValue } from 'pages/MCBHS180/formConfig'
import { MCBHS180OutputCsvRequest } from 'types/MCBHS180/MCBHS180OutputCsvRequest'
import { MCBHS180ChangeRequestDeadlineRequest } from 'types/MCBHS180/MCBHS180ChangeRequestDeadlineRequest'
import { MCBHS180ForcedStopRequest } from 'types/MCBHS180/MCBHS180ForcedStopRequest'
import { MCBHS180ToJobSeekManageRequest } from 'types/MCBHS180/MCBHS180ToJobSeekManageRequest'
export interface snackBarForCheckData {
    openCheckModal: boolean,
    modalMessage: string
}
const resetModal: snackBarForCheckData = {
    openCheckModal: false,
    modalMessage: ""
}
interface initType {
    [x: string]: any
    validTestFlag: string,
    initResultData: MCBHS180InitResultDto,
    snackBarForCheckData: snackBarForCheckData
    mCAXS111Open: boolean
    mCAXS340Open: boolean
    mCBHS190Open: boolean
    openDialogData: snackBarForCheckData
}
const initialState: initType = {
    validTestFlag: "",
    initResultData: MCBHS180InitResultValue,
    snackBarForCheckData: {
        openCheckModal: false,
        modalMessage: ""
    },
    mCAXS111Open: false,
    mCAXS340Open: false,
    mCBHS190Open: false,
    openDialogData: resetModal,
}

const bulkOperationSlice = createSlice({
    name: 'bulkOperation',
    initialState,
    reducers: {
        init(state) {
          return state;
      },
        setState(state, action: PayloadAction<MCBHS180InitResultDto>) {
            state.initResultData = action.payload;
            return state;
        },
        outputPdfdata(state, action: PayloadAction<MCBHS180OutputPdfRequest>) {
            return state;
        },
        sendMessagedata(state, acton: PayloadAction<any>) { 
          return state;
            
        },
        changerequestdeadlinedata(state, acton: PayloadAction<MCBHS180ChangeRequestDeadlineRequest>) {
          return state;     
        },
        toJobSeekManagedata(state, acton: PayloadAction<MCBHS180ToJobSeekManageRequest>)
        { 
          return state;  
        },

        forcedStopdata(state, acton: PayloadAction<MCBHS180ForcedStopRequest>) {  
          return state; 
        },
        
        outputCsvdata(state, action: PayloadAction<MCBHS180OutputCsvRequest>) {
            return state
        },
        setOpenMCAXS111(state, action: PayloadAction<boolean>) {
            state.mCAXS111Open = action.payload
            return state
        },
        openModel(state, action: PayloadAction<string>) {
            state.snackBarForCheckData.modalMessage = action.payload;
            state.snackBarForCheckData.openCheckModal = true
        },
        openDialog(state, action: PayloadAction<snackBarForCheckData>) {
            state.openDialogData = action.payload
            return state
        },
        closeModel(state) {
            state.snackBarForCheckData = resetModal
            return state;
        },
        setMCAXS340Open(state, action: PayloadAction<boolean>) {
            state.mCAXS340Open = action.payload
            return state
        },
        setMCBHS190Open(state, action: PayloadAction<boolean>) {
            state.mCBHS190Open = action.payload
            return state
        },
    }
})
export const {
    init,
    setState,
    outputPdfdata,
    sendMessagedata,
    forcedStopdata,
    toJobSeekManagedata,
    changerequestdeadlinedata,
    outputCsvdata,
    openModel,
    closeModel,
    openDialog,
    setOpenMCAXS111,
    setMCAXS340Open,
    setMCBHS190Open,
    
} = bulkOperationSlice.actions
export default bulkOperationSlice.reducer
