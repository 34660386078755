import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import {
  setPdfManagementListSearchCondition,
  setPdfDownloadShow,
  selectPdfSubmissionList,
  setPdfSubmissionList,
  setPdfSearchCondition,
  deletePdfManagement,
  deletePdfManagementCheck,
  deletePdfManagementFlg,
  setErrorDataList,
  setErrorconfirmationClick,
  getinitPdf,
  setinitPdf,
  setLinkClickSubmission,
  setMCBLS040InitInfo,
  setMCBLS040OpenFlg,
  setMCBHS190OpenFlg,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
  pdfCount,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
} from 'reducers/mcbPdfDownloadReducer'
import { clickErrorRequest, clickNumberRequest, deleteRequest, mCBLS010InitRequest, searchRequest } from 'apis/MCBLS010Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  initMCBHS190Api,
  clickLookDetailApi,
  createApi,
} from 'apis/MCBHS190Api'
import {initMCBLS040Api,} from 'apis/MCBLS040Api'
import {  selectInitInfo, selectInitResult,clickLookDetailListInfo,creatListInfo,setMCBLS040CloseInfo } from 'reducers/mcbPdfDownloadReducer'
import { magiContants } from 'utils/contants'
import { getPdfManagementSearchCondition } from 'selectors'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { setMCBHS190Open } from 'reducers/bulkOperationReducer'
import { getMessage } from 'common/messageUtil'

//MCBLS010初期画面
function* MCBLS010InitSaga(action: ReturnType<typeof getinitPdf>) {
  try {
     // 初期化
    yield put(setPdfSubmissionList([]))
    const data = yield call(mCBLS010InitRequest,action.payload);
    yield* selectPdfSubmissionListSaga(action);
    yield put(setinitPdf(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
//イベント発生条件:"検索する"ボタン押下時、初期画面
function* selectPdfSubmissionListSaga(
  action: ReturnType<typeof selectPdfSubmissionList>
) {
  try {
    // 初期化
    yield put(setPdfSubmissionList([]))
    // 検索処理
    const data = yield call(searchRequest, action.payload)
    // 検索結果取得
    yield put(setPdfSubmissionList(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:「エラー確認」ボタン押下
function* selectErrorDataListSaga(
  action: ReturnType<typeof setErrorconfirmationClick>
) {
  try {
    const data = yield call(clickErrorRequest, action.payload)
    yield put(setPdfDownloadShow(true))
    // 結果
    yield put(setErrorDataList(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}


//イベント発生条件:「提出件数」ボタン押下
function* selectSubmissionNumberDataSaga(
  action: ReturnType<typeof setLinkClickSubmission>
) {
  try {
    const data = yield call(clickNumberRequest, action.payload) 
    
    const applicantManagement = {
      pathname: routeList.submissionRequestManagement,
      state: {
        request: {
          requestList:data && data.map((i: any) => (
            {
              jobSeekerByCompanyId: i.jobSeekerByCompanyId,
              requestId: i.submissionRequestId
            }
          )),
          screenId: 'MCBLS010'
        }
      },
    }
    history.push(applicantManagement)
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:"削除"ボタン押下
function* deleteSaga(action: ReturnType<typeof deletePdfManagement>) {
  try {
    // 削除処理
    const deleteFlg = yield call(deleteRequest, action.payload)
    yield put(deletePdfManagementFlg(deleteFlg))
    if (deleteFlg === magiContants.DELET_FLAG_0) {
      // チェック正常、データ削除成功
      yield put(deletePdfManagementCheck(magiContants.OUT_FLG_NG))
      // 再検索条件取得
      const searchCondition: ReturnType<typeof getPdfManagementSearchCondition> = yield select(
        getPdfManagementSearchCondition
      )
      // 初期化
      yield put(setPdfSubmissionList([]))
      // 検索処理
      const resultsDataList = yield call(searchRequest, searchCondition)
      yield put(setPdfSubmissionList(resultsDataList))
      // 削除成功メッセージ出力
      yield put(openSnackbar(magiContants.MESSAGECODE_MCBLS010_006))
    } else if (deleteFlg === magiContants.DELET_FLAG_1) {
      yield put(deletePdfManagementCheck(magiContants.OUT_FLG_OK))
    }
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

// 検索条件を保持
function* setPdfManagementDate(
  action: ReturnType<typeof setPdfSearchCondition>
) {
  yield put(setPdfManagementListSearchCondition(action.payload))
}

// MCBリプレース MCBHS190 start
//イベント発生条件:初期画面
function* selectSubmissionListSaga(
  action: ReturnType<typeof selectInitInfo>
) {
  try {
    // 検索処理
    const data = yield call(initMCBHS190Api, action.payload)
    // 検索結果取得
    yield put(selectInitResult(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

//イベント発生条件:もっと見る
function* clickLookDetailListSaga(
  action: ReturnType<typeof clickLookDetailListInfo>
) {
  try {
    // 検索処理
    const data = yield call(clickLookDetailApi, action.payload)
    // 検索結果取得
    if (data == '0') {
      action.payload.lookDetail(true)
    }
    // yield put(clickLookDetailListResult(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}


//イベント発生条件:ファイルを作成する
function* createPdfSaga(action: ReturnType<typeof creatListInfo>) {
  try {
    // 検索処理
    const data = yield call(createApi, action.payload)
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
    if (data) {
      yield put(setMCBHS190Open(false))
      yield put(setMCBHS190OpenFlg(false))
      yield put(setMCBLS040OpenFlg(true))
      yield put(pdfCount(data))
    }
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
  } catch (error) {
    try {
      const messagejson = JSON.parse(error.message)
      const countMax = messagejson.count.toLocaleString()
      // 異常
      yield put(openModal(getMessage(messagejson.messageId,[countMax,countMax])))
    } catch {
      yield put(openModal(error.message))
    }
  }
}
// MCBリプレース MCBHS190 end

// MCBリプレース MCBLS040 start
function* MCBLS040InitSaga(
  action: ReturnType<typeof setMCBLS040InitInfo>
) {
  try {
    // 検索処理
    const data : string = yield call(initMCBLS040Api, action.payload)
    // 検索結果取得
    if (data == '0') {
      yield put(setMCBLS040OpenFlg(true))
    }
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

function* MCBLS040CloseSaga(
) {
  try {
    yield put(setMCBLS040OpenFlg(false))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}

// MCBリプレース MCBLS040 start

export default function* mcbPdfDownloadSaga() {
  yield all([
    takeLatest(selectPdfSubmissionList, selectPdfSubmissionListSaga),
    takeLatest(setErrorconfirmationClick, selectErrorDataListSaga),
    takeEvery(deletePdfManagement, deleteSaga),
    takeEvery(setPdfSearchCondition, setPdfManagementDate),
    takeEvery(getinitPdf, MCBLS010InitSaga),
    takeEvery(setLinkClickSubmission, selectSubmissionNumberDataSaga),
    // MCBリプレース MCBHS190 start
    takeLatest(selectInitInfo, selectSubmissionListSaga),
    takeEvery(clickLookDetailListInfo, clickLookDetailListSaga),
    takeEvery(creatListInfo, createPdfSaga),
    // MCBリプレース MCBHS190 end
    takeLatest(setMCBLS040InitInfo, MCBLS040InitSaga),
    takeEvery(setMCBLS040CloseInfo,MCBLS040CloseSaga)
  ])
}
