import request from 'utils/request'
import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'
import { MCAXS010UpdateJudgmentRequest } from 'types/MCAXS010/MCAXS010UpdateJudgmentRequest'
import { MCAXS010GetMySearchRequest } from 'types/MCAXS010/MCAXS010GetMySearchRequest'
import { MCAXS010GetContractMediaInfoRequest } from 'types/MCAXS010/MCAXS010GetContractMediaInfoRequest'
import { MCAXS010SearchAcynchronousRequest } from 'types/MCAXS010/MCAXS010SearchAcynchronousRequest'

export const searchRequest = (apiData: MCAXS010SearchRequest) =>
  request({
    url: '/MCAXS010/init',
    method: 'post',
    data: apiData,
  })

export const updateJudgmentRequest = (apiData: MCAXS010UpdateJudgmentRequest) =>
  request({
    url: '/MCAXS010/pushJudgmentListItem',
    method: 'post',
    data: apiData,
  })

export const getMySearchRequest = (apiData: MCAXS010GetMySearchRequest) =>
  request({
    url: '/MCAXS010/pushCallSearchCondition',
    method: 'post',
    data: apiData,
  })

export const getContractMediaInfoRequest = (apiData: MCAXS010GetContractMediaInfoRequest) =>
  request({
    url: '/MCAXS010/getContractMediaInfo',
    method: 'post',
    data: apiData,
  })

  export const getMultiMySearchResultRequest = (
    apiData: MCAXS010SearchAcynchronousRequest
  ) =>
    request({
      url: '/MCAXS010/getMultiMySearchResult',
      method: 'post',
      data: apiData,
    })
