import { all, call, put, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'

import { getMessage } from 'common/messageUtil'

import {
  getInit,
  setInit,
  createTemplate,
  orverWrite,
} from 'reducers/submissionRequestSendCompReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'

import {
  initRequest,
  createTemplateRequest,
  overwriteTemplateRequest,
} from 'apis/MCBHS030Api'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const data = yield call(initRequest, action.payload)
    yield put(setInit(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* createTemplateSaga(action: ReturnType<typeof createTemplate>) {
  try {
    const formData = action.payload.formData
    yield call(createTemplateRequest, formData)
    yield put(openSnackbar(getMessage('MCBHS030-009')));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* orverWriteSaga(action: ReturnType<typeof orverWrite>) {
  try {
    const formData = action.payload.formData
    yield call(overwriteTemplateRequest, formData)
    yield put(openSnackbar(getMessage('MCBHS030-010')));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* submissionRequestSendCompSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLeading(createTemplate, createTemplateSaga),
    takeLeading(orverWrite, orverWriteSaga),
  ])
}
