import request from 'utils/request'
import { MCAZS220MessageConfirmRequest } from 'types/MCAZS220/MCAZS220MessageConfirmRequest'
import { MCAZS220MessageCsvDownloadRequest } from 'types/MCAZS220/MCAZS220MessageCsvDownloadRequest'
import { MCAZS220MessageDraftRequest } from 'types/MCAZS220/MCAZS220MessageDraftRequest'
import { MCAZS220MessageInitRequest } from 'types/MCAZS220/MCAZS220MessageInitRequest'
import { MCAZS220MessagePreviewRequest } from 'types/MCAZS220/MCAZS220MessagePreviewRequest'


export const initRequest = (apiData: MCAZS220MessageInitRequest) => {
  return request({
    url: '/MCAZS220/init',
    method: 'post',
    data: apiData,
  })
}

export const draftRequest = (apiData: MCAZS220MessageDraftRequest, files: any[]) => {
  const data = new FormData()
  data.append('draftRequest', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  if (attachmentList) {
    attachmentList.map(attachment => {
      if (attachment) {
        data.append('attachment', attachment)
      }
    })  
  }

  return request({
    url: '/MCAZS220/draft',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const previewRequest = (apiData: MCAZS220MessagePreviewRequest) => {
  return request({
    url: '/MCAZS220/preview',
    method: 'post',
    data: apiData,
  })
}

export const confirmRequest = (apiData: MCAZS220MessageConfirmRequest, files: any[]) => {
  const data = new FormData()
  data.append('confirmRequest', encodeURIComponent(JSON.stringify(apiData)))
  const attachmentList = files
  attachmentList.map(attachment => {
    if (attachment) {
      data.append('attachment', attachment)
    }
  })

  return request({
    url: '/MCAZS220/confirm',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const csvDownloadRequest = (apiData: MCAZS220MessageCsvDownloadRequest) => {
  return request({
    url: '/MCAZS220/csvDownload',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })
}
