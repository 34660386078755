import request from 'utils/request'
import { MCAXS391RecruitmentInfoInitRequest } from 'types/MCAXS391/MCAXS391RecruitmentInfoInitRequest'
import { MCAXS391ManagementItemRequest } from 'types/MCAXS391/MCAXS391ManagementItemRequest'
import { MCAXS391ImagesRequest } from 'types/MCAXS391/MCAXS391ImagesRequest'
import { MCAXS391GABRequest } from 'types/MCAXS391/MCAXS391GABRequest'
import { MCAXS391OPQRequest } from 'types/MCAXS391/MCAXS391OPQRequest'
import { MCAXS391CABRequest } from 'types/MCAXS391/MCAXS391CABRequest'

export const getMCAXS391InitRequest = (
  interview: MCAXS391RecruitmentInfoInitRequest
) =>
  request({
    url: '/MCAXS391/init',
    method: 'post',
    data: interview,
  })

export const SearchRequest = (interview: MCAXS391ManagementItemRequest) =>
  request({
    url: '/MCAXS391/ManagementItemBtnClick',
    method: 'post',
    data: interview,
  })

export const ImagesRequest = (interview: MCAXS391ImagesRequest) =>
  request({
    url: '/MCAXS391/ImagesBtnClick',
    method: 'post',
    data: interview,
  })

export const GABRequest = (interview: MCAXS391GABRequest) =>
  request({
    url: '/MCAXS391/GABBtnClick',
    method: 'post',
    data: interview,
  })

export const PQRequest = (interview: MCAXS391OPQRequest) =>
  request({
    url: '/MCAXS391/OPQBtnClick',
    method: 'post',
    data: interview,
  })

export const CABRequest = (interview: MCAXS391CABRequest) =>
  request({
    url: '/MCAXS391/CABBtnClick',
    method: 'post',
    data: interview,
  })
