import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import { MCAXS660sendMessageRequest } from 'types/MCAXS660/MCAXS660sendMessageRequest'
const endSlice = createSlice({
    name: 'end',
    initialState:{},
    reducers: {
        init(state){
        }
    }
})
export const{
    init
}=endSlice.actions
export default endSlice.reducer