import request from 'utils/request'
import { McbMessageReplaceBodyRequest } from 'types/McbMessageReplaceBodyRequest'

export const mcbPreviewRequest = (params: McbMessageReplaceBodyRequest) =>
  request({
    url: '/McbMessageReplaceBody/mcbPreview',
    method: 'post',
    data: params,
  })

export const mcbMessageTemplatePreviewRequest = (params: McbMessageReplaceBodyRequest) =>
  request({
    url: '/McbMessageReplaceBody/mcbMessageTemplatePreview',
    method: 'post',
    data: params,
  })
