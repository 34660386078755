import request from '../utils/request'
import {MCADS050ProcessRequest} from "../types/MCADS050/MCADS050ProcessRequest";

export const toIUDSubmitRequest = (editList: MCADS050ProcessRequest) =>
  request({
    url: '/MCADS050/decide',
    method: 'post',
    data: editList,
  })


