import React, { useEffect } from 'react'
import { TextField as MuiTextField, PropTypes } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'

const useStyles = makeStyles({
  input: {
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
  },
  multiLineTextArea: {
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
    minHeight: '58px',
  },
})

interface Props extends FieldProps {
  type?: string
  margin?: PropTypes.Margin
  placeholder?: string
  defaultValue?: string
  multiline?: boolean
  rows?: number
  multiLineTextArea?: boolean
}

// 名前入力欄要タブ許容テキストフォーム
const TextFIeldForMessageTabAllowed = ({
  type = 'text',
  margin = 'dense',
  placeholder,
  field,
  form,
  multiline,
  rows,
  multiLineTextArea = false,
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { name } = field
  const value = field.value ? field.value : ""

  return (
    <MuiTextField
      variant='outlined'
      margin={margin}
      fullWidth
      multiline={multiline}
      rows={rows}
      type={type}
      inputProps={{ spellCheck: false }}
      InputProps={{
        classes: {
          input: multiLineTextArea ? classes.multiLineTextArea : classes.input,
        },
      }}
      error={Boolean(errors[name] && touched[name])}
      helperText={errors[name] && touched[name] ? errors[name] : null}
      placeholder={placeholder}
      {...field}
      value={value}
      onChange={(event)=>{
        form.setFieldValue(name, event.target.value.replace(/\t/g,' '))
      }}
    />
  )
}

export default TextFIeldForMessageTabAllowed
