import request from 'utils/request'
import { MCAJS020CreateRequest } from 'types/MCAJS020/MCAJS020CreateRequest'
import { MCAJS020IdRequest } from 'types/MCAJS020/MCAJS020IdRequest'
import { MCAJS020CustomizeProgressRequest } from 'types/MCAJS020/MCAJS020CustomizeProgressRequest'
import { MCAJS020UpdateCustomizeProgressRequest } from 'types/MCAJS020/MCAJS020UpdateCustomizeProgressRequest'
import { MCAJS020ProgressIdRequest } from 'types/MCAJS020/MCAJS020ProgressIdRequest'

export const createInitSelectionFlow = (apidata: MCAJS020IdRequest) =>
  request({
    url: '/MCAJS020/init',
    method: 'post',
    data: apidata,
  })

export const createRequest = (apidata: MCAJS020CreateRequest) =>
  request({
    url: '/MCAJS020/register',
    method: 'post',
    data: apidata,
  })

export const createCustomizeProgressRequest = (
  apidata: MCAJS020CustomizeProgressRequest
) =>
  request({
    url: '/MCAJS020/createCustomizeProgress',
    method: 'post',
    data: apidata,
  })

export const updateCustomizeProgressRequest = (
  apidata: MCAJS020UpdateCustomizeProgressRequest
) =>
  request({
    url: '/MCAJS020/updateCustomizeProgress',
    method: 'post',
    data: apidata,
  })

export const deleteProgressRequest = (apidata: MCAJS020ProgressIdRequest) =>
  request({
    url: '/MCAJS020/deleteProgress',
    method: 'post',
    data: apidata,
  })
