import { initValues,initialSelectFacePhotoData } from 'pages/MCAXS300/formConfig'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MCAXS300InitRequest from 'types/MCAXS300/MCAXS300InitRequest'
import MCAXS300UploadImageRequest from 'types/MCAXS300/MCAXS300UploadImageRequest'


export interface MCAXS300SelectFacePhotoDto {
  // 顔写真管理ID
  facePhotoManagementId: string;
  // sysバージョン番号
  sysVersionNumber: number;
}

interface FacePhotoInfo {
  mCAXS300SelectFacePhotoDto: MCAXS300SelectFacePhotoDto,
  mCAXS300initRequest:MCAXS300InitRequest,
  pageDialogOpen:boolean
}

const initialState: FacePhotoInfo = {
  mCAXS300SelectFacePhotoDto: initialSelectFacePhotoData,
  mCAXS300initRequest:initValues,
  pageDialogOpen:false,
}

const entryRouteSlice = createSlice({
  name: 'facePhotoInfo',
  initialState,
  reducers: {
    getInitInfo(state, action: PayloadAction<MCAXS300InitRequest>) {
      return state;
    },
    setInitInfo(state, action: PayloadAction<MCAXS300SelectFacePhotoDto>) {
      state.mCAXS300SelectFacePhotoDto = action.payload
      return state;
    },
    uploadFileRegister(state, action: PayloadAction<{
      uploadImageRequest: MCAXS300UploadImageRequest
      onOk: () => void
    }>) {
      return state;
    },
    setPageDialogOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen = action.payload
      return state
    },
  },
})

export const {
  getInitInfo,
  setInitInfo,
  uploadFileRegister,
  setPageDialogOpen,
} = entryRouteSlice.actions
export default entryRouteSlice.reducer
