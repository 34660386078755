// 初期画面初期化
const initialValues = {
  mediaName: '' ,//応募経路
  entryDate: '', //エントリー日
  applicationJobTitle: '',//応募職種名
  publishedStartDate: '', //掲載開始日
  publishedEndDate: '', //掲載終了日
  surname: '', //姓
  firstName: '', //名
  surnameKana: '', //姓カナ
  firstNameKana: '', //名カナ
  birthday: '', //生年月日
  sex: 0, //性別
  mailAddressPc: '', //メールアドレス（PC)
  mailAddressMobile: '', //メールアドレス（携帯）
  phoneNumberMobile: '', //電話番号（携帯）
  phoneNumberHome: '', //電話番号（自宅）
  postalCode: '', //郵便番号
  prefectures: '', //都道府県
  addressAfterMunicipalities: '', //市区町村以降の住所
  sysVersionNumberEntry:'',//バージョン番号（エントリー）
  sysVersionNumberJobSeeker:'',//バージョン番号（求職者）
  sysVersionNumberSeeInfor:'',//バージョン番号（企業別求職者）
  prefecturesCode:'',//都道府県コード
  entryId:'',//エントリーID
  jobSeekerByCompanyId:'',//企業別求職者ID
  jobSeekerId:'',//求職者ID
  manualRegistrationFlag:'',//手動登録フラグ
}

const initial = {
  jobSeekerId: '',
  entryId: ''
}

export interface MCAXS240LayoutGroupOutDto {
  [key: string]: string
  value: string
  label: string
}

const optionListSex = [
  { label: '男性　　　　　　', value: 1 },
  { label: '女性　　　　　　', value: 2 },
  { label: '未選択　　　　　', value: 0 },
]
export { initialValues, initial,optionListSex }
