import request from 'utils/request'
import { MCAQS030InitMergingMethodEntry } from 'types/MCAQS030/MCAQS030InitMergingMethodEntry'
import { MCAQS030JobSeekerDisplay } from 'types/MCAQS030/MCAQS030JobSeekerDisplay'
import { MCAQS030ConfirmationRequest } from 'types/MCAQS030/MCAQS030ConfirmationRequest'

export const mCAQS030InitRequest = (apiData: MCAQS030InitMergingMethodEntry) =>
  request({
    url: '/MCAQS030/init',
    method: 'post',
    data: apiData,
  })

export const mCAQS030SelectRequest = (apiData: MCAQS030JobSeekerDisplay) =>
  request({
    url: '/MCAQS030/select',
    method: 'post',
    data: apiData,
  })

export const mCAQS030ConfirmRequest = (apiData: MCAQS030ConfirmationRequest) =>
request({
  url: '/MCAQS030/confirm',
  method: 'post',
  data: apiData,
})
