import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InterviewListInitialValues, Row, InterviewListAndCount } from 'pages/MCAOS010/formConfig'
import { InterviewLocationListInitialValues} from 'pages/MCAZS130/formConfig'
import { initialCreateValues } from 'pages/MCAOS020/formConfig'
import { initialUpdateValues } from 'pages/MCAOS030/formConfig'
import { MCAOS020CreateRequest } from 'types/MCAOS020/MCAOS020CreateRequest'
import { MCAOS030UpdateRequest } from 'types/MCAOS030/MCAOS030UpdateRequest'
import { MCAOS030DeleteRequest } from 'types/MCAOS030/MCAOS030DeleteRequest'
import { MCAZS130InitDisplayRequest } from 'types/MCAZS130/MCAZS130InitDisplayRequest'

export interface interviewList {
  interviewListResults: Row[]
  updateInterviewListResults: Row[]
  interviewListCount: number
}
export interface interviewLocationList {
  interviewListResults: Row[]
  updateInterviewListResults: Row[]
  interviewLocationListCount: number
}
export interface interviewCreate {
  interviewLocationName: string
  streetAddress: string
  map: string
}

export interface interviewUpdate {
  interviewLocationId: string;
  interviewLocationName: string;
  streetAddress: string;
  mapURL: string;
  sysVersionNumber: number;
}
  
//test change front page content  start
export interface testTpe{
  interviewId:number,
  interviewLocationId: string;
  interviewLocationName: string;
}
//test change front page content  end

interface Interview {
  interviewLocationList: any
  interviewList: interviewList
  interviewCreate: interviewCreate
  interviewUpdate: interviewUpdate
  updateDialogOpen: boolean
  createDialogOpen: boolean
  interviewLocationResultList:interviewLocationResult[]
//test change front page content  start
  testArray:testTpe[]
//test change front page content  end
}
//面接場所指定
export interface interviewLocationResult{
 interviewLocationSettingId:number
 interviewLocationType:number;
 interviewLocationName:string;
 interviewLocationAddress:string;
 mapUrl:string;
 displayOrder:number;
}
const initialState: Interview = {
  interviewList: InterviewListInitialValues,
  interviewCreate: initialCreateValues,
  interviewUpdate: initialUpdateValues,
  updateDialogOpen: false,
  createDialogOpen: false,
  interviewLocationList:InterviewLocationListInitialValues,
  interviewLocationResultList:[],
  //test change front page content  start
  testArray:[{
    interviewId:1,
    interviewLocationId:"35",
    interviewLocationName:"hebei"
  },
  {
    interviewId:2,
    interviewLocationId:"36",
    interviewLocationName:"shandong"
  },
  {
    interviewId:3,
    interviewLocationId:"37",
    interviewLocationName:"tianjin"
  }]
  //test change front page content  end
}

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    // -----------------MCAOS0I0_面接場所一覧_START------------------------//
    // 画面初期表示時用
    getInterviewList(state) {
      return state
    },
    // -----------------MCAZS130_面接場所指定_START------------------------//
     // 画面初期表示時用
     getinterviewLocationResultList(state, action: PayloadAction<MCAZS130InitDisplayRequest>) {
      return state
    },
    checkInterviewLocation(state,action:PayloadAction<MCAZS130InitDisplayRequest>){
      return state
    },
    // -----------------MCAZS130_面接場所指定_END------------------------//
    // -----------------MCAOS0I0_面接場所一覧_START------------------------//
   
    // DBから一覧データ取得
    setInterviewList: (state, action: PayloadAction<InterviewListAndCount>) => {
      state.interviewList.interviewListResults = action.payload.interviewList
      state.interviewList.interviewListCount = action.payload.count
    },
    setInterviewLocationList: (state, action: PayloadAction<interviewLocationResult[]>) => {
      state.interviewLocationResultList = action.payload
    },
    // 表示順変更時用
    updateInterviewSortOrder(state, action: PayloadAction<Row[]>) {
      state.interviewList.interviewListResults = action.payload
      return state
    },
    checkInterviewCount(state) {
      return state;
    },
    checkInterviewLocationCount(state) {
      return state;
    },
 
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    // -----------------MCAOS0I0_面接場所一覧_END------------------------//

    // -----------------MCAOS020_面接場所登録_START------------------------//
    setInterviewCreate(state, action: PayloadAction<{param:MCAOS020CreateRequest,screenId:string}>) {
      state.interviewCreate = action.payload.param
      return state
    },
    // -----------------MCAOS020_面接場所登録_END------------------------//

    // -----------------MCAOS030_面接場所編集_START------------------------//
    getInterviewPlaceById(state, action: PayloadAction<string>) {
      return state;
    },
    setInterviewPlace(state, action: PayloadAction<interviewUpdate>) {
      state.interviewUpdate = action.payload
      return state;
    },
    updateInterviewPlace(state, action: PayloadAction<MCAOS030UpdateRequest>) {
      return state;
    },
    deleteInterviewPlace(state, action: PayloadAction<MCAOS030DeleteRequest>) {
      return state;
    },
    setUpdateDialogOpen(state, action: PayloadAction<boolean>) {
      state.updateDialogOpen = action.payload
      return state
    },
    // -----------------MCAOS030_面接場所編集_END------------------------//

      //test change front page content  start
      setTestData(state,action:PayloadAction<{index:number,id:string,name:string}>){
        state.testArray[action.payload.index].interviewLocationId=action.payload.id;
        state.testArray[action.payload.index].interviewLocationName=action.payload.name;
        return state;
    }
    //test change front page content  end

  },
})

export const {
  // -----------------MCAOS0I0_面接場所一覧_START------------------------//
  getinterviewLocationResultList,
  getInterviewList,
  setInterviewList,
  updateInterviewSortOrder,
  checkInterviewCount,
  setCreateDialogOpen,
 
  // -----------------MCAOS0I0_面接場所一覧_END------------------------//

  // -----------------MCAOS020_面接場所登録_START------------------------//
  setInterviewCreate,
  // -----------------MCAOS020_面接場所登録_END------------------------//

  // -----------------MCAOS030_面接場所編集_START------------------------//
  getInterviewPlaceById,
  setInterviewPlace,
  updateInterviewPlace,
  deleteInterviewPlace,
  setUpdateDialogOpen,
  // -----------------MCAOS030_面接場所編集_END------------------------//

    // -----------------MCAZS130_面接場所指定_START------------------------//
  setInterviewLocationList,
  checkInterviewLocationCount,
  checkInterviewLocation,
    // -----------------MCAZS130_面接場所指定_END------------------------//

    setTestData
} = interviewSlice.actions
export default interviewSlice.reducer
