import { EntryRoutInitialValues } from 'pages/MCAXS670/formConfig'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS670initRequest } from 'types/MCAXS670/MCAXS670initRequest'
import { MCAXS670mediaAdditionalRequest } from 'types/MCAXS670/MCAXS670mediaAdditionalRequest'
import { MCAXS670mediaChangeRequest } from 'types/MCAXS670/MCAXS670mediaChangeRequest'




export interface AddAndAfterEntryRouteInfoList {
  // 応募経路設定ID
　 mediaSettingId: number;
  // 応募経路名
    mediaRouteName: string;
}
export interface BeforeEntryRouteInfoList {
  // 応募経路設定ID
　 mediaSettingId: number;
  // 応募経路名
    mediaRouteName: string;
}
export interface SystVersionNumberList {
  // 選考管理ID
    selectionManagementId: number;

  // 応募経路設定ID
    mediaSettingId: number;

  //sysバージョン番号
    sysVersionNumber: number;
}

export interface EntryRout {
  addAndAfterEntryRouteInfoList: AddAndAfterEntryRouteInfoList[];
  beforeEntryRouteInfoList: BeforeEntryRouteInfoList[];
  systVersionNumberList: SystVersionNumberList[];
  recruitmentManagementDivision: string;
  entryDate: string;
}

interface Entry {
  updateDialogOpen: boolean
  EntryRout:EntryRout
}

const initialState: Entry = {
  updateDialogOpen: false,
  EntryRout:EntryRoutInitialValues,
}

const entryRouteSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {

    // -----------------MCAXS670_応募経路追加_START------------------------//
    getInitInfo(state, action: PayloadAction<{request: MCAXS670initRequest, onError?: () => void} >) {
      return state;
    },
    setEntryRout(state, action: PayloadAction<EntryRout>) {
      state.EntryRout = action.payload
      return state;
    },
    addMediaAdditional(state, action: PayloadAction<{request: MCAXS670mediaAdditionalRequest, onclose?: () => void}>) {
      return state;
    },
    updatemediaChange(state, action: PayloadAction<{request: MCAXS670mediaChangeRequest, onclose?: () => void}>) {
      return state;
    },

    setEntryRouteDialogOpen(state, action: PayloadAction<boolean>) {
      state.updateDialogOpen = action.payload
      return state
    },




    // -----------------MCAXS670_応募経路追加_END------------------------//
  },
})

export const {
  getInitInfo,
  setEntryRout,
  addMediaAdditional,
  updatemediaChange,
  setEntryRouteDialogOpen,
  
} = entryRouteSlice.actions
export default entryRouteSlice.reducer
