import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBHS080InitRequest } from 'types/MCBHS080/MCBHS080InitRequest'

const messageInitRequest: MCBHS080InitRequest = {
  sendCount: 0,
  emailFailedCount: 0,
}

const initialState: {
  messageInitRequest: MCBHS080InitRequest
} = {
  messageInitRequest: messageInitRequest,
}

const mcbMessageDeadlineCompSlice = createSlice({
  name: 'mcbMessageDeadlineComp',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCBHS080InitRequest>) {
      console.log('Reducer getInit1')
      const initRequest = action.payload
      return state
    },
  },
})

export const { getInit } = mcbMessageDeadlineCompSlice.actions
export default mcbMessageDeadlineCompSlice.reducer
