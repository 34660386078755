import { initPopupRequest, initAreaContactPassRequest } from 'apis/MCAWS010Api'
import {
    getInitPopup,
    setInitPopup,
    getInitAreaContactPass,
    setInitAreaContactPass,
    setProgressAlertDialogOpen,
} from 'reducers/progressStagentAlertReducer'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { openModal } from 'reducers/messageReducer'

// MCAWS010 画面初期表示「進捗停滞アラート（ポップアップ）」
function* getInitPopupSaga(action: ReturnType<typeof getInitPopup>) {
    try {
        // 初期データ取得
        const data= yield call(initPopupRequest, action.payload)
        // 初期表示_アラートエリア（ポップアップ）
        yield put(setInitPopup(data))
        if (data.interviewCancelMessage !== '' || data.contactUnansweredMessage !== '' || data.interviewCurrentMessage!==''){
            yield put(setProgressAlertDialogOpen(true))
        }else{
            yield put(setProgressAlertDialogOpen(false))
        }
    } catch (error) {
        yield put(openModal(error.message))
    }
}
// MCAWS010 画面初期表示「進捗停滞アラート（アラートエリア）」
function* getInitAreaContactPassSaga(action: ReturnType<typeof getInitAreaContactPass>) {
    try {
        // 初期データ取得
        const data = yield call(initAreaContactPassRequest, action.payload)
        // 初期表示_アラートエリア
        yield put(setInitAreaContactPass(data))
    } catch (error) {
        yield put(openModal(error.message))
    }
}

export default function* selectCriteriaSaga() {
    yield all([
        takeEvery(getInitPopup, getInitPopupSaga),
        takeEvery(getInitAreaContactPass, getInitAreaContactPassSaga),
    ])
}