import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useErrorStyle } from 'components'
import { FieldProps } from 'formik'

interface Props extends FieldProps {
    label: string
    disabled: boolean
    optionList: {
        label: string
        value: string | number
    }[]
    clearFieldValue?: boolean
}

const TypeToggle = ({ field, form, label, optionList, disabled, clearFieldValue, ...others }: Props) => {
    const classes = useErrorStyle()
    const { name, value } = field
    const { errors, touched } = form
    const handleChange = (e: any) => {
        form.setFieldValue(name, e.target.value)
        if (clearFieldValue) {
            form.setFieldValue("collegeCode", "")
            form.setFieldValue("facultyCode", "")
            form.setFieldValue("subjectCode", "")
            form.setFieldValue("schoolName", "")
            form.setFieldValue("vocationalSchoolMajor", "")
            form.setFieldValue("overseasSchoolMajor", "")
            form.setFieldValue("humanitiesAndSciencesDivision", "2")
            form.setFieldValue("overseasSchoolDegree", "5")
            form.setFieldValue("overseasSchoolCountryRegion", "")
            form.setFieldValue("overseasSchoolState", "")
            form.setFieldValue("inputSchoolName", "")
            form.setFieldValue("inputSubject", "")
            form.setFieldValue("inputFaculty", "")
            form.setFieldValue("facultyName", "")
            form.setFieldValue("subjectName", "")
            form.setFieldValue("overseasSchoolName", "")
            form.setFieldValue("overseasSchoolFacultyDepartment", "")
            form.setFieldValue("overseasSchoolSubjectDepartment", "")
        }
    }
    return (
        <FormControl error={Boolean(errors[name] && touched[name])}>
            <RadioGroup row value={value} onChange={handleChange} {...others}>
                {optionList.map(i => (
                    <FormControlLabel
                        control={<Radio color='primary' value={i.value} />}
                        label={i.label}
                        key={i.value}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
            {errors[name] && touched[name] ? (
                <p className={classes.helperText}>{errors[name]}</p>
            ) : null}
        </FormControl>
    )
}

export default TypeToggle
