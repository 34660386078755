import request from 'utils/request'

import { MCAZS030MessageQueryRequest } from 'types/MCAZS030/MCAZS030MessageQueryRequest'
import { MCAZS030MessageSelectedRequest } from 'types/MCAZS030/MCAZS030MessageSelectedRequest'

export const selectMessageReservationRequest = (apiData: MCAZS030MessageQueryRequest) =>
  request({
    url: '/MCAZS030/find',
    method: 'post',
    data: apiData,
  })

  export const initReservationRequest = (apiData: MCAZS030MessageQueryRequest) =>
  request({
    url: '/MCAZS030/init',
    method: 'post',
    data: apiData,
  })  

  export const deleteMessageReservationRequest = (apiData: MCAZS030MessageSelectedRequest) =>
  request({
    url: '/MCAZS030/moveDraftMessage',
    method: 'post',
    data: apiData,
  }) 

  export const sendMessageReservationRequest = (apiData: MCAZS030MessageSelectedRequest) =>
  request({
    url: '/MCAZS030/sendMessage',
    method: 'post',
    data: apiData,
  }) 

  // [phase2] start by zhangxp
  export const getFailSelectionManagementApiMCAZS030 = (messageSendId: number) =>
    request({
    url:`/MCAZS030/getFailSelectionManagementIdBulkList/${messageSendId}`,
    method: 'post',
  })
  // [phase2] end by zhangxp

  // #5654 #59450 start
  export const getSelectionManagementApiMCAZS030 = (apiData: any) =>
    request({
    url:`/MCAZS030/getSelectionManagementIdBulkList`,
    method: 'post',
    data: apiData,
  })
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  export const editMessageMCAZS030Request = (apiData: any) =>
    request({
      url:`/MCAZS030/editMessageMCAZS030`,
      method: `post`,
      data: apiData,
    })
  // 次期開発5月向#58931 end