import {
  transform,
  transformApplicationRoute,
  innerSortFunc,
  transformNumberVal,
} from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { OriginalQuestionObj, SearchCondition as EntryListSearchCondition } from 'pages/MCBHS040/searchConditionConfig'
import { dateToString } from 'common/generalUtil'

const managementAnd = '全てと一致'
const managementOr = 'いずれかに一致'

export interface Option {
  [key: string]: any
  label: string
  value: string
}
// MCBリプレイス #8947 START
export interface OptionNumVal {
  [key: string]: any
  label: string
  value: number
}
// MCBリプレイス #8947 END
export interface SexObj {
  sexCode: string
  sexName: string
}

export interface McbUsageObj {
  mcbUsageCode: string
  mcbUsageName: string
}

export interface SubmissionDeadlineOptionObj {
  submissionDeadlineOptionCode: string
  submissionDeadlineOptionName: string
}

export interface SubmissionStatusConditionsObj {
  submissionStatusCode: string
  submissionStatusName: string
}

export interface SubmissionRequestConfirmationStatusConditionsObj {
  submissionRequestConfirmationStatusConditionsCode: string
  submissionRequestConfirmationStatusConditionsName: string
}

export interface SubmissionFileConditionObj {
  fileUploadStatusFlag: number | null
  fileUploadStatusName: string | null
  uploadFileId: number | null
  uploadFileName: string | null
}

export interface SubmissionFileObj {
  submissionFileConditionJudgmentFlag: number
  submissionFileConditionJudgmentName: string
  submissionFileConditionObj: SubmissionFileConditionObj[]
}

export interface OrderObjArray {
  sexCodeList: { sexCode: number }[]
}

export interface SubmissionsExceptForcedStopedObj {
  submissionsExceptForcedStopedId: string
  submissionsExceptForcedStopedName: string
}

export interface submissionsObj {
  submissionsId: string
  submissionsName: string
}

export interface affiliationSeminarLaboratoryResearchContentObj {
  divisionCode: number
  detailsCode: number
  learningKeywordCode: string
  detailsContent: string
}

export interface SearchCondition {
  //ApplicantBasic
  applicantId: string | null
  name: string | null
  residenceObj: {
    current: Option[]
    vacation: Option[]
    residenceCondition: string | null
  } | null
  mcbUsageObj: Option | null
  schoolName: string | null
  facultyGraduateName: string | null

  // SubmissionRequest
  // MCBリプレイス #8947 START
  submissionsObj: OptionNumVal[] | null
  // MCBリプレイス #8947 END
  submissionConditionsObj: Option | null
  submissionsExceptForcedStopedObj: Option[] | null
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  submissionDeadlineConditionsObj: Option | null
  submissionDeadlineFrom: string | null
  submissionDeadlineTo: string | null
  submissionStatusConditionsObj: Option | null
  filingDateFrom: string | null
  filingDateTo: string | null
  submissionRequestConfirmationStatusConditionsObj: Option[] | null

  // //EntrySheet
  sexObj: Option[] | null
  highSchoolName: string | null
  languageSkillsObj: Option[] | null
  languageSkillsConditionsObj: Option | null
  // MCBリプレイス #8947 START
  eligibilityObj: OptionNumVal[] | null
  // MCBリプレイス #8947 END
  eligibilityConditionsObj: Option | null
  // MCBリプレイス #8947 START
  focusedAcademicWorkObj: OptionNumVal[] | null
  // MCBリプレイス #8947 END
  affiliationSeminarLaboratoryResearchContentObj: affiliationSeminarLaboratoryResearchContentObj[] | null
  minToeic: number | null
  maxToeic: number | null
  minToeflIbt: number | null
  maxToeflIbt: number | null

  // QuestionAndFile
  // MCBHS161 オリジナル設問
  originalQuestionObj: OriginalQuestionObj | null
  // MCBHS161 提出物ファイル
  submissionFileObj: SubmissionFileObj | null
}

export const initSearchCondition: SearchCondition = {
  // ApplicantBasic
  applicantId: null,
  name: null,
  residenceObj: null,
  mcbUsageObj: null,
  schoolName: null,
  facultyGraduateName: null,
  // SubmissionRequest
  submissionsObj: null,
  submissionConditionsObj: null,
  submissionsExceptForcedStopedObj: null,
  deliveryDateFrom: null,
  deliveryDateTo: null,
  submissionDeadlineConditionsObj: null,
  submissionDeadlineFrom: null,
  submissionDeadlineTo: null,
  submissionStatusConditionsObj: null,
  filingDateFrom: null,
  filingDateTo: null,
  submissionRequestConfirmationStatusConditionsObj: null,
  //EntrySheet
  sexObj: null,
  highSchoolName: null,
  eligibilityObj: null,
  focusedAcademicWorkObj: null,
  affiliationSeminarLaboratoryResearchContentObj: null,
  minToeic: null,
  maxToeic: null,
  minToeflIbt: null,
  maxToeflIbt: null,
  originalQuestionObj: null,
  submissionFileObj: null,
  languageSkillsObj: null,
  languageSkillsConditionsObj: null,
  eligibilityConditionsObj: null,
}

export const toEntryList = (
  input: SearchCondition,
  orderObjArray?: OrderObjArray
): EntryListSearchCondition =>
({
  applicantId:
    input.applicantId && input.applicantId.length > 0
      ? input.applicantId.split(/\r\n|\n/g).filter(i => i !== '')
      : null,
  name:
    input.name && input.name.length > 0
      ? input.name.split(/\r\n|\n/g).filter(i => i !== '')
      : null,
  residenceObj: input.residenceObj
    ? {
      residenceCurrentObj: input.residenceObj.current.map(i => ({
        residenceCurrentPrefecturesCode: i.value,
        residenceCurrentPrefecturesName: i.label,
      })),
      residenceVacationObj: input.residenceObj.vacation.map(i => ({
        residenceVacationPrefecturesCode: i.value,
        residenceVacationPrefecturesName: i.label,
      })),
      residenceConnectorFlag: input.residenceObj.residenceCondition
      // MCBリプレイス #8976 START
        ? input.residenceObj.residenceCondition
      // MCBリプレイス #8976 END
        : null,
      residenceConnectorName: input.residenceObj.residenceCondition
        ? input.residenceObj.residenceCondition === '0'
          ? 'かつ'
          : 'または'
        : null,
    }
    : null,
  schoolName: input.schoolName,
  facultyGraduateName: input.facultyGraduateName,
  mcbUsageObj: input.mcbUsageObj
    ? {
      mcbUsageFlag: input.mcbUsageObj.value,
      mcbUsageName: input.mcbUsageObj.label,
    }
    : null,
  submissionsObj: input.submissionsObj
    ? input.submissionsObj
      .map(i => ({ submissionsId: i.value, submissionsName: i.label })) : null,
  submissionConditionsObj: input.submissionConditionsObj
  ? {
    submissionConditionsFlag: input.submissionConditionsObj.value,
    submissionConditionsName: input.submissionConditionsObj.label,
  }
  : null,
  submissionsExceptForcedStopedObj: input.submissionsExceptForcedStopedObj && input.submissionsExceptForcedStopedObj.length != 0
  ? {
    submissionsExceptForcedStopedId: input.submissionsExceptForcedStopedObj[0].value,
    submissionsExceptForcedStopedName: input.submissionsExceptForcedStopedObj[0].label,
  }
  : null,
  deliveryDateFrom: input.deliveryDateFrom,
  deliveryDateTo: input.deliveryDateTo,
  submissionStatusConditionsObj: input.submissionStatusConditionsObj
  ? {
    submissionStatusConditionsFlag: input.submissionStatusConditionsObj.value,
    submissionStatusConditionsName: input.submissionStatusConditionsObj.label,
  }
  : null,
  filingDateFrom: input.filingDateFrom,
  filingDateTo: input.filingDateTo,
  submissionDeadlineFrom: input.submissionDeadlineFrom,
  submissionDeadlineTo: input.submissionDeadlineTo,
  submissionDeadlineConditionsObj: input.submissionDeadlineConditionsObj
  ? {
    submissionDeadlineConditionsFlag: input.submissionDeadlineConditionsObj.value,
    submissionDeadlineConditionsName: input.submissionDeadlineConditionsObj.label,
  }
  : null,
  submissionRequestConfirmationStatusConditionsObj: input.submissionRequestConfirmationStatusConditionsObj
    ? input.submissionRequestConfirmationStatusConditionsObj
      .map(i => ({ submissionRequestConfirmationStatusConditionsCode: i.value, submissionRequestConfirmationStatusConditionsName: i.label }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { sexCode: Number(a.submissionRequestConfirmationStatusConditionsCode) },
            { sexCode: Number(b.submissionRequestConfirmationStatusConditionsCode) },
            orderObjArray.sexCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  sexObj: input.sexObj
    ? input.sexObj
      .map(i => ({ sexCode: i.value, sexName: i.label }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { sexCode: Number(a.sexCode) },
            { sexCode: Number(b.sexCode) },
            orderObjArray.sexCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  highSchoolName:
    input.highSchoolName && input.highSchoolName.length > 0
      ? input.highSchoolName.split(/\r\n|\n/g).filter(i => i !== '')
      : null,
  eligibilityObj: input.eligibilityObj
    ? input.eligibilityObj
      .map(i => ({ eligibilityId: i.value, eligibilityName: i.label })) : null,
  eligibilityConditionsObj: input.eligibilityConditionsObj
    ? {
      eligibilityConditionsFlag: input.eligibilityConditionsObj.value,
      eligibilityConditionsName: input.eligibilityConditionsObj.label,
    }
    : null,
  languageSkillsObj: input.languageSkillsObj
    ? input.languageSkillsObj
      .map(i => ({ languageSkillsId: i.value, languageSkillsName: i.label })) : null,
  languageSkillsConditionsObj: input.languageSkillsConditionsObj
    ? {
      languageSkillsConditionsFlag: input.languageSkillsConditionsObj.value,
      languageSkillsConditionsName: input.languageSkillsConditionsObj.label,
    }
    : null,
  focusedAcademicWorkObj: input.focusedAcademicWorkObj
    ? input.focusedAcademicWorkObj
      .map(i => ({ focusedAcademicWorkId: i.value, focusedAcademicWorkName: i.label })) : null,
  affiliationSeminarLaboratoryResearchContentObj: input.affiliationSeminarLaboratoryResearchContentObj
    ? input.affiliationSeminarLaboratoryResearchContentObj.map(i => ({
      divisionCode: i.divisionCode,
      detailsCode: i.detailsCode,
      learningKeywordCode: i.learningKeywordCode,
      detailsContent: i.detailsContent,
    }))
    : null,
  minToeic: input.minToeic,
  maxToeic: input.maxToeic,
  minToeflIbt: input.minToeflIbt,
  maxToeflIbt: input.maxToeflIbt,
  // MCBHS161　オリジナル設問 条件保持用
  originalQuestionObj: input.originalQuestionObj
    ? {
      SearchConditionType: input.originalQuestionObj.SearchConditionType,
      SearchConditionList: input.originalQuestionObj.SearchConditionList.map(
        i => ({
          originalQuestionId: i.originalQuestionId,
          questionTitle: i.questionTitle,
          questionBody: i.questionBody,
          questionType: i.questionType,
          answerSelect: i.answerSelect,
          answerSelectionList: i.answerSelectionList ? i.answerSelectionList.map(i2 => ({
            ...i2
          })) : null,
          uploadSelect: i.uploadSelect,
        })
      )
    }
    : null,
  // MCBHS161　提出物ファイル 条件保持用
  submissionFileObj: input.submissionFileObj
    ? {
      submissionFileConditionJudgmentFlag:
        input.submissionFileObj.submissionFileConditionJudgmentFlag
      ,
      submissionFileConditionJudgmentName: String(
        input.submissionFileObj.submissionFileConditionJudgmentFlag) === '0'
        ? "OR"
        : "AND",
      submissionFileConditionObj: input.submissionFileObj.submissionFileConditionObj.map(
        i => ({
          ...i,
          fileUploadStatusFlag: i.fileUploadStatusFlag,
          fileUploadStatusName: i.fileUploadStatusName,
          uploadFileName: i.uploadFileName,
        })
      ),
    }
    : null,
} as any)

export const fromEntryList = (
  input: EntryListSearchCondition
): SearchCondition => ({
  ...input,
  applicantId: input.applicantId ? input.applicantId.join('\r\n') : null,
  name: input.name ? input.name.join('\r\n') : null,
  residenceObj: input.residenceObj
    ? {
      current: input.residenceObj.residenceCurrentObj
      ? input.residenceObj.residenceCurrentObj.map(transform)
      : [],
    vacation: input.residenceObj.residenceVacationObj
      ? input.residenceObj.residenceVacationObj.map(transform)
      : [],
    residenceCondition: input.residenceObj.residenceConnectorFlag
    // MCBリプレイス #8976 START
      ? input.residenceObj.residenceConnectorFlag
    // MCBリプレイス #8976 END
      : null,
  }
  : null,
  schoolName: input.schoolName,
  facultyGraduateName: input.facultyGraduateName,
  mcbUsageObj: input.mcbUsageObj
    ? {
      value: input.mcbUsageObj.mcbUsageFlag,
      label: input.mcbUsageObj.mcbUsageName,
    }
  : null,
  // MCBリプレイス #8947 START
  submissionsObj: input.submissionsObj ? input.submissionsObj.map(transformNumberVal) : null,
  // MCBリプレイス #8947 END
  submissionConditionsObj: input.submissionConditionsObj
  ? {
    value: input.submissionConditionsObj.submissionConditionsFlag,
    label: input.submissionConditionsObj.submissionConditionsName,
  }
  : null,
  submissionsExceptForcedStopedObj: input.submissionsExceptForcedStopedObj 
  ? [{
    value: input.submissionsExceptForcedStopedObj.submissionsExceptForcedStopedId,
    label: input.submissionsExceptForcedStopedObj.submissionsExceptForcedStopedName,
    }]
  : null,
  deliveryDateFrom: input.deliveryDateFrom,
  deliveryDateTo: input.deliveryDateTo,
  submissionDeadlineConditionsObj: input.submissionDeadlineConditionsObj
  ? {
      value: input.submissionDeadlineConditionsObj.submissionDeadlineConditionsFlag,
      label: input.submissionDeadlineConditionsObj.submissionDeadlineConditionsName,
    }
  : null,
  submissionDeadlineFrom: input.submissionDeadlineFrom,
  submissionDeadlineTo: input.submissionDeadlineTo,
  submissionStatusConditionsObj: input.submissionStatusConditionsObj
    ? {
      value: input.submissionStatusConditionsObj.submissionStatusConditionsFlag,
      label: input.submissionStatusConditionsObj.submissionStatusConditionsName,
    }
  : null,
  filingDateFrom: input.filingDateFrom,
  filingDateTo: input.filingDateTo,
  submissionRequestConfirmationStatusConditionsObj: input.submissionRequestConfirmationStatusConditionsObj ? input.submissionRequestConfirmationStatusConditionsObj.map(transform) : null,
  sexObj: input.sexObj ? input.sexObj.map(transform) : null,
  highSchoolName: input.highSchoolName ? input.highSchoolName.join('\r\n') : null,
  // MCBリプレイス #8947 START
  eligibilityObj: input.eligibilityObj ? input.eligibilityObj.map(transformNumberVal) : null,
  // MCBリプレイス #8947 END
  eligibilityConditionsObj: input.eligibilityConditionsObj
  ? {
    value: input.eligibilityConditionsObj.eligibilityConditionsFlag,
    label: input.eligibilityConditionsObj.eligibilityConditionsName,
  }
  : null,
  languageSkillsObj: input.languageSkillsObj ? input.languageSkillsObj.map(transform) : null,
  languageSkillsConditionsObj: input.languageSkillsConditionsObj
    ? {
      value: input.languageSkillsConditionsObj.languageSkillsConditionsFlag,
      label: input.languageSkillsConditionsObj.languageSkillsConditionsName,
    }
    : null,
  // MCBリプレイス #8947 START
  focusedAcademicWorkObj: input.focusedAcademicWorkObj ? input.focusedAcademicWorkObj.map(transformNumberVal) : null,
  // MCBリプレイス #8947 END
  affiliationSeminarLaboratoryResearchContentObj: input.affiliationSeminarLaboratoryResearchContentObj
  ? input.affiliationSeminarLaboratoryResearchContentObj.map(i => ({
    // MCBリプレイス #8947 START
    divisionCode: Number(i.divisionCode),
    detailsCode: Number(i.detailsCode),
    // MCBリプレイス #8947 END
    learningKeywordCode: i.learningKeywordCode,
    detailsContent: i.detailsContent,
  }))
  : null,
  minToeic: input.minToeic,
  maxToeic: input.maxToeic,
  minToeflIbt: input.minToeflIbt,
  maxToeflIbt: input.maxToeflIbt,
  // MCBHS161　オリジナル設問 条件保持用
  originalQuestionObj: input.originalQuestionObj
    ? {
      SearchConditionType: input.originalQuestionObj.SearchConditionType,
      SearchConditionList: input.originalQuestionObj.SearchConditionList.map(
        i => ({
          originalQuestionId: i.originalQuestionId,
          questionTitle: i.questionTitle,
          questionBody: i.questionBody,
          questionType: i.questionType,
          answerSelect: i.answerSelect,
          answerSelectionList: i.answerSelectionList ? i.answerSelectionList.map(i2 => ({
            ...i2
          })) : null,
          uploadSelect: i.uploadSelect,
        })
      )
    }
    : null,
  // MCBHS161　提出物ファイル 条件保持用
  submissionFileObj: input.submissionFileObj
    ? {
      submissionFileConditionJudgmentFlag: input.submissionFileObj.submissionFileConditionJudgmentFlag,
      submissionFileConditionJudgmentName:
        String(input.submissionFileObj.submissionFileConditionJudgmentFlag) === '0'
          ? 'OR'
          : 'AND',
      submissionFileConditionObj: input.submissionFileObj.submissionFileConditionObj.map(
        i =>
        ({
          ...i,
          fileUploadStatusFlag: i.fileUploadStatusFlag,
          fileUploadStatusName: i.fileUploadStatusName,
          uploadFileName: i.uploadFileName,
        } as any)
      ),
    }
    : null,
})
