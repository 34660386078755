import { useState } from 'react';

const useModal = (initialValue = false) => {
  const [show, setShow] = useState(initialValue);
  const toggle = (newValue: boolean) => () => setShow(newValue);

  /**
   * TypeScript const assertions
   * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
   */
  return [show, toggle] as const;
};

export default useModal;
