import { MCBLS020InitRequest } from 'types/MCBLS020/MCBLS020InitRequest'
import { MCBLS020OutputPdfRequest } from 'types/MCBLS020/MCBLS020OutputPdfRequest'
import request from 'utils/request'

export const initRequest = (apidata: MCBLS020InitRequest) =>
  request({
    url: `/MCBLS020/init`,
    method: 'post',
    data: apidata,
  })

export const downloadApi = (apidata: any) =>
  request({
    url: apidata,
    method: 'get',
    responseType: 'blob',
  })
