import request from 'utils/request'
import { MCACS030UpdateRequest } from 'types/MCACS030/MCACS030UpdateRequest'

export const agreeInitRequset = (companyAccountId: string) =>
  request({
    url: `/MCACS030/init/${companyAccountId}`,
    method: 'post',
})
export const agreeUpdateRequest = (apiData: MCACS030UpdateRequest) =>
  request({
    url: '/MCACS030/agree',
    method: 'post',
    data: apiData,
})
