/** 画面表示項目 */
export interface ScreenDisplayItems {
  recruitmentManagementDivision: string
  title: Title
  submissionRequestInput: SubmissionRequestInput
  thanksMessageInput: ThanksMessageInput
  personalDataInput: PersonalDataInput
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  recruitmentManagementFlag: RecruitmentManagementFlag[]
  signature: Signature[]
  hiddenItems: HiddenItems
  senderCompanyName:string
  senderMailAddress: string
  submissionContentList: MCBHS010SubmissionsContentList[] //提出物内容リスト
  thanksMailTemplateContentList:MCBHS010ThanksMailTemplateList[] // サンクスメールテンプレート内容リスト
  contactContentList: MCBHS010ContactList[] // お問い合わせ先内容リスト
  privacyPolicyContentList: MCBHS010MyCareerBoxPrivacyPolicyList[] // 個人情報の取り扱い内容リスト
  submissionsLink: string // 提出物リンク
  requestExampleLink: string // 提出リクエスト文例リンク
  thanksMailTemplateLink: string// サンクスメールテンプレートリンク
  thanksMailExampleLink: string // サンクスメール文例リンク
  referenceLink: string// 問い合わせ先リンク
  personalDataHandlingLink: string // 個人情報の取り扱いリンク
  authToken: string // MCB認証トークン
}

export interface NotDisplayItem {
  submissionsContentModified: string
  contactModified: string
  myCareerBoxPrivacyPolicyModified: string
}

export interface MCBHS010SubmissionsContentList {
  submissionsContentId: number // 提出物内容ID
  submissionsNameForCompany: string // 提出物内容名（企業管理用）
  submissionsNameForStudent: string // 提出物内容名（学生）
  entrySheetResumeSubmissionFlg: boolean // エントリーシート/履歴書提出フラグ
  entrySheetResumeSubmissionCategory: number //エントリーシート/履歴書選択区分
  researchOverviewsSubmissionFlg: boolean // 研究概要書提出フラグ
  originalQuestionSubmissionFlg: boolean // オリジナル設問提出フラグ
  fileSubmissionFlg: boolean // ファイル提出フラグ
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string  // 更新日時
}
export interface MCBHS010ThanksMailTemplateList {
  thanksMailTemplateId: number  // サンクスメールテンプレートID
  thanksMailTemplateRegisteredName: string //サンクスメールテンプレート件名
  thanksMailTemplateSubject: string //サンクスメールテンプレート件名
  thanksMailTemplateBody: string //サンクスメールテンプレート本文
  modified: string //更新日時
}
export interface MCBHS010ContactList {
  inquiryId: number // お問い合わせ先ID
  inquiryRegisteredName: string// お問い合わせ先名
  companyName: string// 企業名
  departmentNameAndManagerName: string// 部署名／担当者氏名
  telNumber: string// 電話番号
  emailAddress: string// メールアドレス
  hiddenFlg: boolean// 非表示フラグ
  modifiedDate: string// 更新日時
}
export interface MCBHS010MyCareerBoxPrivacyPolicyList {
  myCareerBoxPrivacyPolicyId: number // My CareerBoxに関する個人情報の取り扱いID
  privacyPolicyRegisterdName: string // My CareerBoxに関する個人情報の取り扱い名
  hiddenFlg: boolean // 非表示フラグ
  modifiedDate: string// 更新日時
}
/** タイトルエリア */
export interface Title {
  [key: string]: any
  selectedTemplateSettingId: string
  submissionRequestTemplate: SubmissionRequestTemplate[]
}

export interface SubmissionRequestTemplate {
  [key: string]: any
  submissionRequestTemplateSettingId: string
  templateName: string
  templateType: string
  destinationDivision: string
  submissionContentId: number
  submissionContentName: string
  submissionContentNameStudent: string
  submissionEntrySheetFlag: string
  submissionResumeFlag: string
  submissionResearchSummaryFlag: string
  submissionOriginalQuestionFlag: string
  submissionFileSubmissionFlag: string
  submissionDeadlineKind: string
  submissionDeadlineDay: string
  submissionDeadlineHour: string
  resubmitReceptionFlag: string
  homePageTitle1: string
  homePageUrl1: string
  homePageTitle2: string
  homePageUrl2: string
  homePageTitle3: string
  homePageUrl3: string
  thanksMailSettingFlag: string
  thanksMailTemplateId: number
  thanksMailTemplateName: string
  thanksSubject: string
  thanksBody: string
  contactId: number
  contactName: string
  contactCompanyName: string
  contactDepartmentNameAndManagerName: string
  contactTelephoneNumber: string
  contactMailAddress: string
  privacyPolicyId: number
  privacyPolicyName: string
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  requestText: string
  sequence: number
  sysVersionNumber: string // sysバージョン番号
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  [key: string]: any
  destinationSelection: string
  sendTarget: SendTarget[]
  mcbMessageDestination: McbSendTarget[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: SearchCriteriaInfo
  filterCondition: string
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}

/** 送信対象 */
export interface SendTarget {
  [key: string]: any
  entryId: string,
  jobSeekerByCompanyId: string,
  fullName: string,
  familyName: string,
  name: string,
  mailAddress: string,
  // #MCB1.5次開発 #9168 START
  jobSeekerIdForDisplay: string,
  // #MCB1.5次開発 #9168 END
  sendTargetJobSeekerId: string,
  mynaviMemberInfo: string,
  schoolName: string,
  facultyName: string,
  subjectName: string,
  applicationDate: string,
  mcbUseStatusName: string,
  coFormerJobSeekerId: string,
  viewEndTime: Date //閲覧終了日時
  selectionManagementId: string
  // #MCB1.5次開発 #8997 START
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}

/** 送信対象 */
export interface McbSendTarget {
  entryId: string
  selectionManagementId: string
  jobSeekerId: string
  mycareercd: string
  mcbMemberId: string
  mcbSubmissionRequestId: string
  jobSeekerByCompanyId: string
  submissionRequestId: string
  coordinationFormerJobSeekerIdentificationKey: string
  notSend: boolean
}

/** 提出リクエスト入力エリア */
export interface SubmissionRequestInput {
  [key: string]: any
  submissionsSelectId: number// 提出物内容ID
  submissionsNameForCompany:string// 提出物内容名
  submissionsNameForStudent: string// 提出物内容名（学生画面表示用）
  submissionsDetails: string
  submissionEntrySheetFlag:number
  submissionResumeFlag:number
  submissionResearchSummaryFlag:number
  submissionOriginalQuestionFlag:number
  submissionFileSubmissionFlag:number
  limitDateDetermineFlag: string// 提出締切_種別	
  determineDateTime: string
  requestDayTimeLaterDay: string
  requestDayTimeToTime: string
  anytimeReceptionEndDateTime: string
  // submissionDeadlineDay: Date// 提出締切_日
  // submissionDeadlineHour: number// 提出締切_時
  reRequestReceptFlag: string// 再提出受付フラグ
  senderCompanyName: string // 送信元会社名
  senderMailAddress: string // 送信元メールアドレス
  studentReferenceId: number // お問い合わせ先ID
  contactName: string // お問い合わせ先名
  companyName: string // お問い合わせ先内容企業名
  departmentNameAndManagerName: string // お問い合わせ先内容部署名担当者氏名
  telephoneNumber: string // お問い合わせ先内容電話番号
  mailAddress: string // お問い合わせ先内容メールアドレス
  requestSubject: string // 件名
  requestText: string // 本文
  attachment: Attachment[]
  homePage: HomePage[]
  previewSubject: string
  previewBody: string

  // contactInformation: string  //問い合わせ先
  // contactInformationCompany: string //問い合わせ先内容
  // contactInformationTel: string //問い合わせ先内容
  // handlingPersonalInformation: string //個人情報の取り扱い
  // sysVersionNumber: string // sysバージョン番号
}

// サンクスメッセージエリア
export interface ThanksMessageInput {
  [key: string]: any
  thanksMessageSetFlag: string  //サンクスメール設定フラグ
  thanksMessageTemplateId: number // サンクスメールテンプレートID
  thanksMessageTemplateName: string // サンクスメールテンプレート名	
  thanksSubject: string // サンクスメール件名
  thanksText: string // サンクスメール本文	
}

// 個人情報エリア
export interface PersonalDataInput {
  personalDataHandlingId: number // 個人情報の取り扱いID
  personalDataHandling: string // 個人情報の取り扱い名	
}

export interface ShouldShowMessageInput {
  [key: string]: boolean
  senderCompanyName: boolean
  senderMailAddress: boolean
  submissionReqSubject: boolean
  submissionReqBody: boolean
  attachment: boolean
  homePage: boolean
}


/** 送信日時入力エリア */
export interface SendTimeInput {
  [key: string]: any
  sendTimeFlag: string
  sendPlanTime: Date | null
}

/** 非表示項目 */
export interface HiddenItems {
  [key: string]: any
  replyFromMessageSendId: string
  adjustmentInterviewScheduleAdjustmentId: number[]
  adjustmentInterviewScheduleId: number[]
}

export interface Attachment {
  attachmentFileName: string
  attachmentId: string
  attachmentName: string
}

export interface HomePage {
  homePageTitle: string
  homePageUrl: string
}

export interface RecruitmentManagementFlag {
  [key: string]: any
  recruitmentManagementFlagSettingId: number
  recruitmentManagementFlagName: string
}

export interface Signature {
  [key: string]: any
  registrationName: string
  signSettingId: number
  signature: string
}

export interface MCAZS080InterviewScheduleAdjustmentMaxResultDto {
  [key: string]: any
  interviewScheduleAdjustmentId: number
  selectionManagementId: number
}

export interface RegisterValue {
  [key: string]: any
  requestTemplateSettingId: string
  submissionRequestId: string
  mcbSubmissionRequestId: string;
  destinationId: string[]
  searchCriteriaName: string
  searchCriteria: string
  // 9174 start
  searchCriteriaJson: string
  // 9174 end
  submissionsSelectId: number
  submissionsNameForCompany: string
  submissionsNameForStudent: string
  submissionsDetails: string
  submissionEntrySheetFlag: number
  submissionResumeFlag: number
  submissionResearchSummaryFlag: number
  submissionOriginalQuestionFlag: number
  submissionFileSubmissionFlag: number
  limitDateDetermineFlag: number
  determineDateTime: string
  requestDayTimeLaterDay: number
  requestDayTimeToTime: number
  anytimeReceptionEndDateTime: string
  reRequestReceptFlag: number
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  requestText: string
  requestAttachment: File[]
  requestAttachmentId: Array<string | null>
  requestAttachmentName: string[]
  requestTemplateAttachmentId: string[]
  requestTemplateAttachmentName: string[]
  homepageTitle1: string|null
  homepageTitle2: string|null
  homepageTitle3: string|null
  homepageUrl1: string|null
  homepageUrl2: string|null
  homepageUrl3: string|null
  thanksMessageSetFlag: number
  thanksMessageTemplateId: number
  thanksMessageTemplateName: string
  thanksSubject: string
  thanksText: string
  studentReferenceId: number
  contactName: string
  companyName: string
  departmentNameAndManagerName: string
  telephoneNumber: string
  mailAddress: string
  personalDataHandlingId: number
  personalDataHandling: string
  sendTimerFlag: number
  sendPlanTime: string | null
  entryIdList: string[]
  selectionManagementIdList: string[]
  jobSeekerByCompanyIdList: string[]
  companyContractId: number
  sendTarget: SendTarget[]
  status: string
  coordinationFormerJobSeekerIdentificationKeyList: string[]
  mailDestinationAddressList: string[]
  jobSeekerIdList: string[],
  submissionsContentModified: string
  contactModified: string
  myCareerBoxPrivacyPolicyModified: string
}

/** 提出リクエスト入力内容確認エリア */
export interface MessageInput {
  // exampleLink: string
  // targetInput: string
  // templateAttachmentId: string[]
  // previewSubject: string
  // previewBody: string
  // shouldShowMessageInput: ShouldShowMessageInput  
  // sysVersionNumber: string // sysバージョン番号
  submissionsNameForCompany: string //提出物内容名（企業管理用）
  submissionsNameForStudent: string //提出物内容（学生画面表示用）
  submissionsDetails: string //提出物
  limitDateDetermineFlag: string //提出締切
  determineDateTime: string //提出締切指定日時
  requestDayTimeLaterDay: string //依頼日から○日後
  requestDayTimeToTime: string //依頼日から△時まで
  anytimeReceptionEndDateTime: string //随時受付終了日時
  reRequestRecept: string //再提出受付
  senderCompanyName: string //送信元会社名
  senderMailAddress: string //送信元メールアドレス
  requestSubject: string //(提出リクエスト)件名
  requestText: string //(提出リクエスト)本文
  attachment: Attachment[]
  homePage: HomePage[]
  thanksMessageSetFlag: string //サンクスメッセージ設定フラグ
  thanksMessageTemplateName: string //サンクスメッセージテンプレート名
  thanksSubject: string //（サンクス）件名
  thanksText: string //（サンクス）本文
  contactName: string //問い合わせ先
  companyName: string //(問い合わせ先)企業名
  departmentNameAndManagerName: string //(問い合わせ先)部署名／担当者氏名
  telephoneNumber: string //(問い合わせ先)電話番号
  mailAddress: string //(問い合わせ先)メールアドレス
  personalDataHandling: string //個人情報の取り扱い
}

const title: Title = {
  selectedTemplateSettingId: '',
  submissionRequestTemplate: [],
}

const shouldShowMessageInput: ShouldShowMessageInput = {
  senderCompanyName: false,
  senderMailAddress: false,
  submissionReqSubject: false,
  submissionReqBody: false,
  attachment: false,
  homePage: false,
}

const submissionRequestInput: SubmissionRequestInput = {
  submissionsSelectId: 0,// 提出物内容ID
  submissionsNameForCompany:'',// 提出物内容名
  submissionsNameForStudent: '',// 提出物内容名（学生画面表示用）
  submissionsDetails: '',// 提出物
  limitDateDetermineFlag: '0',// 提出締切_種別	
  determineDateTime: '',
  requestDayTimeLaterDay: '',
  requestDayTimeToTime: '',
  anytimeReceptionEndDateTime: '',
  // submissionDeadlineDay: new Date,// 提出締切_日
  // submissionDeadlineHour: 0,// 提出締切_時
  reRequestReceptFlag: '0',// 再提出受付フラグ
  senderCompanyName: '', // 送信元会社名
  senderMailAddress: '', // 送信元メールアドレス
  studentReferenceId: 0, // お問い合わせ先ID
  contactName: '', // お問い合わせ先名
  companyName: '', // お問い合わせ先内容企業名
  departmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  telephoneNumber: '', // お問い合わせ先内容電話番号
  mailAddress: '', // お問い合わせ先内容メールアドレス
  requestSubject: '', // 件名
  requestText: '', // 本文
  attachment: [],
  homePage: [],
  previewSubject: '',
  previewBody: '',
  submissionEntrySheetFlag:0,
  submissionResumeFlag:0,
  submissionResearchSummaryFlag:0,
  submissionOriginalQuestionFlag:0,
  submissionFileSubmissionFlag:0
}

const thanksMessageInput : ThanksMessageInput = {
  thanksMessageSetFlag: '',  //サンクスメール設定フラグ
  thanksMessageTemplateId: 0, // サンクスメールテンプレートID
  thanksMessageTemplateName: '', // サンクスメールテンプレート名	
  thanksSubject: '', // サンクスメール件名
  thanksText: '' // サンクスメール本文	
}

const personalDataInput : PersonalDataInput ={
  personalDataHandlingId: 0, // 個人情報の取り扱いID
  personalDataHandling: '' // 個人情報の取り扱い名	
}

const sendTimeInput: SendTimeInput = {
  sendTimeFlag: '0',
  sendPlanTime: null,
}

const sendTarget: SendTarget = {
  entryId: '',
  jobSeekerByCompanyId:'',
  fullName: '',
  familyName: '',
  name: '',
  mailAddress: '',
  // #MCB1.5次開発 #9168 START
  jobSeekerIdForDisplay: '',
  // #MCB1.5次開発 #9168 END
  sendTargetJobSeekerId: '',
  mynaviMemberInfo: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  applicationDate: '',
  mcbUseStatusName: '',
  coFormerJobSeekerId: '',
  submissionRequestId: '',
  viewEndTime: new Date(), //閲覧終了日時
  // #MCB1.5次開発 #8997 START
  selectionManagementId: '',
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}

const mcbMessageDestination: McbSendTarget = {
  entryId: '',
  selectionManagementId: '',
  jobSeekerId: '',
  mycareercd: '',
  mcbMemberId: '',
  mcbSubmissionRequestId: '',
  jobSeekerByCompanyId: '',
  submissionRequestId: '',
  coordinationFormerJobSeekerIdentificationKey: '',
  notSend: false,
}

const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}

const notDisplayItem: NotDisplayItem = {
  submissionsContentModified:'',
  contactModified:'',
  myCareerBoxPrivacyPolicyModified:''
}

const sendTargetConfirmation: SendTargetConfirmation = {
  destinationSelection: '0',
  sendTarget: [],
  mcbMessageDestination: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaInfo: searchCriteriaInfo,
  filterCondition: '',
  sendToNonMember: 0,
}

const messageInput: MessageInput = {
  // exampleLink: '',
  // targetInput: '',
  attachment: [], //添付ファイル
  // templateAttachmentId: [],
  homePage: [],
  // previewSubject: '',
  // previewBody: '',
  // shouldShowMessageInput: shouldShowMessageInput,
  // sysVersionNumber: '',
  submissionsNameForCompany: '', //提出物内容名（企業管理用）
  submissionsNameForStudent: '', //提出物内容（学生画面表示用）
  submissionsDetails: '', //提出物
  limitDateDetermineFlag: '0', //提出締切
  determineDateTime: '', //提出締切指定日時
  requestDayTimeLaterDay: '', //依頼日から○日後
  requestDayTimeToTime: '', //依頼日から△時まで
  anytimeReceptionEndDateTime: '', //随時受付終了日時
  reRequestRecept: '0', //再提出受付フラグ
  senderCompanyName: '', //送信元会社名
  senderMailAddress: '', //送信元メールアドレス
  requestSubject: '', //(提出リクエスト)件名
  requestText: '', //(提出リクエスト)本文
  thanksMessageSetFlag: '1', //サンクスメッセージ設定フラグ
  thanksMessageTemplateName: '', //サンクスメッセージテンプレート名
  thanksSubject: '', //（サンクス）件名
  thanksText: '', //（サンクス）本文
  contactName: '', //問い合わせ先
  companyName: '', //(問い合わせ先)企業名
  departmentNameAndManagerName: '', //(問い合わせ先)部署名／担当者氏名
  telephoneNumber: '', //(問い合わせ先)電話番号
  mailAddress: '', //(問い合わせ先)メールアドレス
  personalDataHandling: '', //個人情報の取り扱い
}

const hiddenItems: HiddenItems = {
  replyFromMessageSendId: '',
  adjustmentInterviewScheduleAdjustmentId: [],
  adjustmentInterviewScheduleId: [],
}
const submissionContentList: MCBHS010SubmissionsContentList[] = []
const thanksMailTemplateContentList: MCBHS010ThanksMailTemplateList[] = []
const contactContentList: MCBHS010ContactList[] = []
const privacyPolicyContentList: MCBHS010MyCareerBoxPrivacyPolicyList[] = []

const recruitmentManagementFlag: RecruitmentManagementFlag[] = []

const signature: Signature[] = []

const registerValue: RegisterValue = {
  requestTemplateSettingId: '',
  submissionRequestId: '',
  mcbSubmissionRequestId: '',
  destinationId: [],
  searchCriteriaName: '',
  searchCriteria: '',
  // 9174 start
  searchCriteriaJson: '',
  // 9174 end
  submissionsSelectId: 0,
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  submissionEntrySheetFlag: 0,
  submissionResumeFlag: 0,
  submissionResearchSummaryFlag: 0,
  submissionOriginalQuestionFlag: 0,
  submissionFileSubmissionFlag: 0,
  limitDateDetermineFlag: 0,
  determineDateTime: '',
  requestDayTimeLaterDay: 0,
  requestDayTimeToTime: 0,
  anytimeReceptionEndDateTime: '',
  reRequestReceptFlag: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  requestAttachment: [],
  requestAttachmentId: [],
  requestAttachmentName: [],
  requestTemplateAttachmentId: [],
  requestTemplateAttachmentName: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  thanksMessageSetFlag: 0,
  thanksMessageTemplateId: 0,
  thanksMessageTemplateName: '',
  thanksSubject: '',
  thanksText: '',
  studentReferenceId: 0,
  contactName: '',
  companyName: '',
  departmentNameAndManagerName: '',
  telephoneNumber: '',
  mailAddress: '',
  personalDataHandlingId: 0,
  personalDataHandling: '',
  sendTimerFlag: 0,
  sendPlanTime: null,
  entryIdList: [],
  selectionManagementIdList: [],
  jobSeekerByCompanyIdList: [],
  companyContractId: 0,
  sendTarget: [],
  status: '',
  coordinationFormerJobSeekerIdentificationKeyList:[],
  jobSeekerIdList:[],
  submissionsContentModified: '',
  contactModified: '',
  myCareerBoxPrivacyPolicyModified: '',
  mailDestinationAddressList: []
}

export const fixedWordTop ='********<br/>本メールはマイナビからお送りしています。<br/>********<br/>'
export const fixedWordTopThanks ='********<br/>本メールはマイナビからお送りしています。送信専用のため返信はお受けできません。<br/>********<br/>＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br/>'
export const fixedWord = '<br/>企業から書類提出のリクエストが届きました。<br/>受け取った内容については、下記リンクからご確認ください。<br/><br/>＜リクエスト内容の確認・提出はこちらから＞<br/><span style="font-weight: bold">[この部分には自動で提出リクエストURLが入ります]</span><br/>＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿<br/>';
export const fixedWordFooter = '<br/>＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br/>本メールは、受取人のための機密情報を含んでいます。<br/>＜注意＞ <br/>本メールの内容に心当たりがない場合、あなたが発信者の意図した受取人ではない可能性がございます。<br/>心当たりがない場合は、誠にお手数ですが、下記お問い合わせ先よりご連絡ください。<br/><br/>・本メールに含まれる情報の刷出し、複写、流布はお控えください。<br/>・併せて、本メールを破棄いただきますようお願いいたします。<br/>＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿<br/>本サービスの利用においてご不明な点がございましたら、下記ページのお問い合わせ先よりご連絡ください。<br/>＜よくある質問・お問い合わせはこちら＞<br/>https://mcb.mynavi.jp/common/pages/guide/index.html<br/><br/>＜ご利用ガイドはこちら＞<br/>https://job.mynavi.jp/mcb/<br/><br/>▼発行　マイナビ - 学生向け就職活動（就活）・就職情報サイト<br/>https://job.mynavi.jp/<br/>＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br/>';
export const fixedWordBar = '=========================='
export const fixedWordIndet ='●'
export const fixedWordAttatch = '添付ファイル：このメッセージには添付ファイルが含まれます。添付ファイルはMy CareerBoxのメッセージから確認できます。'
export const fixedWordBr = '<br/>'

export {
  title,
  hiddenItems,
  submissionRequestInput,
  thanksMessageInput,
  personalDataInput,
  shouldShowMessageInput,
  messageInput,
  sendTimeInput,
  sendTarget,
  sendTargetConfirmation,
  recruitmentManagementFlag,
  registerValue,
  signature,
  submissionContentList,
  thanksMailTemplateContentList,
  contactContentList,
  privacyPolicyContentList,
  notDisplayItem
}
