import request from 'utils/request'
import { MCBHS030InitRequest } from 'types/MCBHS030/MCBHS030InitRequest'
import { MCBHS030CreateTemplateRequest } from 'types/MCBHS030/MCBHS030CreateTemplateRequest'
import { MCBHS030OverwriteTemplateRequest } from 'types/MCBHS030/MCBHS030OverwriteTemplateRequest'

export const initRequest = (apiData: MCBHS030InitRequest) => {
  return request({
    url: '/MCBHS030/init',
    method: 'post',
    data: apiData,
  })
}

export const createTemplateRequest = (apiData: MCBHS030CreateTemplateRequest) => {
  return request({
    url: '/MCBHS030/createTemplate',
    method: 'post',
    data: apiData,
  })
}

export const overwriteTemplateRequest = (apiData: MCBHS030OverwriteTemplateRequest) => {
  return request({
    url: '/MCBHS030/overwriteTemplate',
    method: 'post',
    data: apiData,
  })
}

