import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// #75311 25KH start
import {
    initCondition as InitCondition
  } from 'reducers/selectionStatusUpdateReducer'
// #75311 25KH end

export interface ProgressStatusHistoryInParm {
    jobSeekerId: number //求職者ID
    entryId: number //エントリーID
    selectionManagementId: number //選考管理
}
// #75311 25KH start
export interface MCAXS080UpdateHistryDeleteFlagRequest {
    progressStatusHistoryId: string[],
    sysVersionnumber: string[],
}
// #75311 25KH end

export interface ProgressStatusHistoryResultList {
    [key: number]: number
    // #75311 25KH start
    progressStatusHistoryId: number, //選考ステップ履歴ID
    // #75311 25KH end
    selectionName: string, //選考名
    progressName: string, //進捗名
    decisionDivision: string, //判定区分名
    manipulationType: string, //操作種別
    fullName: string, //操作者(氏名)
    manipulationTime: string, //操作日時
    operator: number, // 操作者
    // #75311 25KH start
    histryDelete:string,
    sysVersionnumber:string,
    // #75311 25KH end
    // 転職March #75621 START
    progressType:string,//進捗種別
    interviewTime:string,//面接日時
    fairueOther:string,//不合格・辞退理由・その他
    detailManipulationType:string,//詳細操作種別
    decisionDivisionType:string,
    // 転職March #75621 END
}

export interface ProgressStatusHistoryResult {
    progressStatusHistoryResult: ProgressStatusHistoryResultList[],
    openDialogFlag: boolean
}

const initialState: ProgressStatusHistoryResult = { 
    progressStatusHistoryResult: [],
    openDialogFlag: false
}

const progressStatusHistorySlice = createSlice({
    name: 'progressStatusHistory',
    initialState,
    reducers: {
        // 画面初期表示時用
        progressStatusHistoryInit(state, action: PayloadAction<ProgressStatusHistoryInParm>) {
            return state
        }, 
        setProgressStatusHistoryInit(state, action: PayloadAction<ProgressStatusHistoryResultList[]>) {
            state.progressStatusHistoryResult = action.payload
            state.openDialogFlag = true
            return state
        },
        setPageDialogOpen(state, action: PayloadAction<boolean>){
            state.openDialogFlag = false
            return state
        },
        // #75311 25KH start
        // 履歴の削除
        updateHistryDelete(state, action: PayloadAction<{MCAXS080UpdateHistryDeleteFlagRequest :MCAXS080UpdateHistryDeleteFlagRequest,initCondition :InitCondition}>) {
            return state
          },
        // #75311 25KH end
    },
})
  
export const {
    progressStatusHistoryInit,
    setProgressStatusHistoryInit,
    setPageDialogOpen,
    // #75311 25KH start
    updateHistryDelete,
    // #75311 25KH end
} = progressStatusHistorySlice.actions

export default progressStatusHistorySlice.reducer