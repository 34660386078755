import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { magiContants } from 'utils/contants'

// 郵便データ
// 表示用
export interface PostalDataForDisplay {
    // 都道府県名漢字
    prefecturesNameKanji: string
    // 市区町村名漢字
    cityNameKanji: string
    // 町域名漢字
    townNameKanji: string
}
// 選択していたデータ
export interface PostalDataForSelected {
    // 都道府県
    prefectures: string
    // 市区町村以降の住所
    addressAfterMunicipalities: string
}

// 郵便番号フォーマット
const formatPostal = (postalCode: string) => {
    return postalCode
        ? `「${postalCode.substr(0, 3)}${magiContants.HAIFUN}${postalCode.substr(3, 4)}」`
        : magiContants.EMPTY
}
const useStyles = makeStyles(theme => ({
    // 画面名
    dialogTitle: {
        backgroundColor: '#133e80',
        padding: '8px 8px',
    },
    dialogContent: {
        padding: '8px 60px 0px 60px',
    },
    title: {
        cursor: 'auto!important',
        color: (theme.palette as any).white,
        fontWeight: 700,
        fontSize: 22,
    },
    postalCodeClass: {
        fontWeight: 700,
        paddingBottom: '8px',
    },
    listConent: {
        height: '370px',
        overflowY: 'auto',
        border: '1px solid black',
        '& :hover': {
            backgroundColor: '#e6f1ff',
        },
        '& .Mui-selected': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#fcebeb',
            border: '1px solid #e2b4e3'
        },
        padding: '0px',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: '60px',
        cursor: 'pointer'
    },
    listText1: {
        marginRight: '14px',
        fontSize: '14px',
        lineHeight: '23px',
        fontFamily: 'sans-serif',
        color: '#3380cc',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    listText2: {
        marginRight: '14px',
        fontSize: '14px',
        lineHeight: '23px',
        fontFamily: 'sans-serif',
        color: '#3380cc',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    listText3: {
        fontSize: '14px',
        lineHeight: '23px',
        fontFamily: 'sans-serif',
        color: '#3380cc',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    // "キャンセルする"ボタン
    cancel: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
            backgroundColor: '#c1d1eb',
            borderColor: '#999999',
        },
        minWidth: '180px',
    },
    // "入力内容を確認する"ボタン
    confirmButton: {
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#4596e6',
            borderColor: '#3380cc',
        },
        minWidth: '180px',
    },
}))

interface Props {
    openPostalCodeDialog: boolean
    setPostalCodeDialog: (state: boolean) => void
    postalCode: string
    postalDataList: PostalDataForDisplay[]
    getSelectedData: (value: PostalDataForSelected) => void
}
const AddressModal = ({
    openPostalCodeDialog,
    setPostalCodeDialog,
    postalCode,
    postalDataList,
    getSelectedData,
}: Props) => {
    const classes = useStyles()
    const [selectedData, setSelectedData] = useState<PostalDataForSelected>({
        addressAfterMunicipalities: magiContants.EMPTY,
        prefectures: magiContants.EMPTY,
    })
    const [selectedIndex, setSelectedIndex] = useState(-2)
    useEffect(() => {
        setSelectedIndex(-2)
    }, [postalDataList])
    // "OK"ボタン押下
    const confirmButton = () => {
        if (selectedIndex !== -2 && selectedIndex !== -1) {
            getSelectedData(selectedData)
        } else {
            setPostalCodeDialog(false)
        }
    }
    // "キャンセルする"ボタン押下
    const handleCancel = () => {
        setPostalCodeDialog(false)
    }
    const onLink = (index: number, value: PostalDataForDisplay) => {
        // eslint-disable-next-line prefer-const
        let dataForSelected: PostalDataForSelected = {
            prefectures: value.prefecturesNameKanji,
            addressAfterMunicipalities: `${value.cityNameKanji}　${value.townNameKanji}`,
        }
        setSelectedData(dataForSelected)
        setSelectedIndex(index)
    }
    const onLinkOfWithOut = () => {
        // eslint-disable-next-line prefer-const
        setSelectedIndex(-1)
    }
    return (
        <>
            <Dialog open={openPostalCodeDialog} fullWidth maxWidth='md'>
                <DialogTitle className={classes.dialogTitle}>
                    <label className={classes.title}>住所選択</label>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.postalCodeClass}>
                        {formatPostal(postalCode) + 'の住所検索結果'}
                    </div>
                    <List component='div' className={classes.listConent}>
                        {postalDataList.map((data: PostalDataForDisplay, index: number) => (
                            <ListItem
                                selected={selectedIndex === index}
                                button
                                divider
                                className={classes.listItem}
                                onClick={() => {
                                    onLink(index, data)
                                }}>
                                <div title={data.prefecturesNameKanji} className={classes.listText1}>
                                    {data.prefecturesNameKanji}
                                </div>
                                <div title={data.cityNameKanji} className={classes.listText2}>{data.cityNameKanji}</div>
                                <div title={data.townNameKanji} className={classes.listText3}>{data.townNameKanji}</div>
                            </ListItem>
                        ))}
                        <ListItem
                            selected={selectedIndex === -1}
                            button
                            divider
                            className={classes.listItem}
                            onClick={onLinkOfWithOut}>
                            <div className={classes.listText1}>{magiContants.NONE}</div>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' justify='center'>
                        <Grid xs={5} item container justify='flex-end'>
                            <Button
                                className={classes.cancel}
                                onClick={handleCancel}
                                variant='contained'>
                                キャンセルする
              </Button>
                        </Grid>
                        <Grid style={{ width: '20px' }} />
                        <Grid xs={5} item>
                            <Button
                                className={classes.confirmButton}
                                onClick={confirmButton}
                                variant='contained'>
                                OK
              </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AddressModal
