import request from 'utils/request'



export const getMCAXS701InitRequest = () =>
    request({
        url: 'MCAXS701/init',
        method: 'post',
    })

