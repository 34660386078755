import { getMessage } from "common/messageUtil"

const signUpdateInitialValues = {
  count:0,
  entryDate:'',
  resisteredName: '',
  signatureText: '',
  signSettingId: '',
  sysVersionNumber: 0,
}

const entryRouteInitialValues = {
  count:0,
  addMediaSettingId: '',
  beforeMediaSettingId: '',
  afterMediaSettingId: '',
  entryDate:'',
}

const EntryRoutInitialValues = {
  addAndAfterEntryRouteInfoList: [],
  beforeEntryRouteInfoList: [],
  systVersionNumberList: [],
  recruitmentManagementDivision: '',
  entryDate:'',
}

const textMap = {
  addition: {
    addition: '追加する',
    message: getMessage('MCAXS670-006'),
  },
  update: {
    update: '変更する',
    message: getMessage('MCAXS670-007'),
  },
  cancel: {
    cancel: 'キャンセルする',
    message: getMessage('MCAXS670-018'),
  },
}

export { entryRouteInitialValues,signUpdateInitialValues, textMap ,EntryRoutInitialValues}
