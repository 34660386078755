import request from 'utils/request'
import { MCAXS280InitRequest } from 'types/MCAXS280/MCAXS280InitRequest'
import { MCAXS280ChangeSelectionRequest } from 'types/MCAXS280/MCAXS280ChangeSelectionRequest'

export const initChangeRequest = (param: MCAXS280InitRequest) =>
  request({
    url: `/MCAXS280/init`,
    method: 'post',
    data: param,
  })

export const changeRequest = (params: MCAXS280ChangeSelectionRequest) =>
request({
    url: `MCAXS280/change`,
    method: 'post',
    data: params,
})