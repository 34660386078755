import request from 'utils/request'

import { MCAZS040MessageQueryRequest } from 'types/MCAZS040/MCAZS040MessageQueryRequest'
import { MCAZS040MessageSelectedRequest } from 'types/MCAZS040/MCAZS040MessageSelectedRequest'

export const selectMessageDraftRequest = (apiData: MCAZS040MessageQueryRequest) =>
  request({
    url: '/MCAZS040/find',
    method: 'post',
    data: apiData,
  })

  export const initRequest = (apiData: MCAZS040MessageQueryRequest) =>
  request({
    url: '/MCAZS040/init',
    method: 'post',
    data: apiData,
  })  

  export const deleteMessageRequest = (apiData: MCAZS040MessageSelectedRequest) =>
  request({
    url: '/MCAZS040/deleteMessage',
    method: 'post',
    data: apiData,
  }) 

  // #5654 #59450 start
  export const getSelectionManagementApiMCAZS040 = (apiData: any) =>
    request({
    url:`/MCAZS040/getSelectionManagementIdBulkList`,
    method: 'post',
    data: apiData,
  })
  // #5654 #59450 end
 

  // export const deleteMessageRequest = (apiData: MCAZS040MessageSelectedRequest) =>
  // request({
  //   url: '/MCAZS040/deleteMessage',
  //   method: 'post',
  //   data: apiData,
  // }) 
  // 次期開発5月向#58931 start
  export const editMessageMCAZS040Request = (apiData: any) =>
  request({
    url:`/MCAZS040/editMessageMCAZS040`,
    method: `post`,
    data: apiData,
  })
  // 次期開発5月向#58931 end