
const initialValues = {
  messageId: '', // メッセージID
  messageTypeCode: '', // メッセージ種別（code）
  messageType: '', // メッセージ種別（表示用）
  sendAndReceiveDivision: '', // 送受信区分
  destinationDivision: '', //送信先区分
  sendReceivingTimeName: '', // 送受信日時(表示用)
  senderCompanyName: '', // 送信元会社名
  senderMailAddress: '', // 送信元メールアドレス
  // phase2 start
  sendTimeFlag: '',
  // phase2 end
  replyPermitFlag: '',  // 返信許可フラグ（code）
  replyPermit: '',  // 返信許可(表示用)
  repliesAllowAddress: '', // 返信お知らせ先
  barcodeDisplayFlag: '', // バーコード表示フラグ
  subject: '', // 件名
  body: '', // 本文
  messageReadFlag: '', // 既読フラグ
  sysVersionNumber: '', // sysバージョン番号
  unsubscribeFlag: '', // 退会済みフラグ
  entryList: [], // エントリー
  seminarList: [], // セミナー/説明会
  homepageList: [], // ホームページ
  attachmentFileList: [], // 添付ファイル
  jobSeekersInfoCountFlag: '', // 送受信者情報件数フラグ
  jobSeekersInfoList: [], // 送受信者情報
  buttonDisableFlag: '', // ボタンを活性化フラグ 0:非活性化 1:活性化
  buttonDisplayFlag: '', // ボタンを表示フラグ 0:非表示 1:表示
  interviewList: [],
  downloadAuthority:true,
  subjectForSending: '', // 送信用件名
  bodyForSending: '', // 送信用本文
  bulkSendFlag: '', // 一括送信フラグ
  convertingBatchCompleteFlag: '', // 変換バッチ完了フラグ
  status: '', // ステータス
  replyNecessaryFlag:'', // 要返信フラグ(code)
  replyLimitTime:'', // 返信期限
  // [phase2] start by zhangxp
  // 添付オプションタイトル
  attachedOptionTitle: '',
  // [phase2] end by zhangxp
  searchCriteriaName: '', // 検索条件名
  searchCriteria: '', // 検索条件
  // #58497 2022/05/31 start
  mySearch:[], //MY検索
  mySearchCondition: 0, //MY検索条件指定フラグ
  // #58497 2022/05/31 end
  // 次期開発12月 #72025 start
  noPermissionOrDeletedFlag: false, // 閲覧権限、削除済応募者チェックフラグ
  bulkReplyMessageCount: 0, // 一括返信チェックの件数
  bulkReplyMessageFlag: false, // 一括返信チェックのフラグ
  // 次期開発12月 #72025 end
  // 次期開発12月 #71768 start
  replyAddress: '', // 送信元メールアドレス（固定値）
  replyToMailDate: '', // #71768のリリース日時
  // 次期開発12月 #71768 end
}

// 転職+送信
const labelListChangeAndSend = [
  '送信先',
  '氏名/年齢</br>ID',
  '応募職種</br>応募種別</br>応募日時',
  '応募経路</br>送受信時選考ステップ：合否</br>選考フロー',
]
// 転職+受信
const labelListChangeAndReceive = [
  '氏名/年齢</br>ID',
  '応募職種</br>応募種別</br>応募日時',
  '応募経路</br>送受信時選考ステップ：合否</br>選考フロー',
]
// 就職+送信
const labelListEmploymentAndSend = [
  '送信先',
  '氏名</br>応募者管理ID',
  '学校</br>学部</br>学科',
  '応募経路',
  // '送受信時選考ステップ：合否</br>選考フロー',
]
// 就職+受信
const labelListEmploymentAndReceive = [
  '氏名</br>応募者管理ID',
  '学校</br>学部</br>学科',
  '応募経路',
  // '送受信時選考ステップ：合否</br>選考フロー',
]

export { initialValues, labelListChangeAndSend, labelListChangeAndReceive, labelListEmploymentAndSend, labelListEmploymentAndReceive, };