import { isEmpty } from "common/generalUtil"
import { McbAttachedOption } from "reducers/mcbMessageDetailReducer"

const selectMsgDetailInitialValues = {
    mcbMessageId: '', // MCBメッセージID
    messageTypeCode: '', // メッセージ種別（code）
    sendAndReceiveDivision: '', // 送受信区分
    sendTimeShow: '', // 送受信日時
    sendPlanTime: '', // 送信予定日時
    companyContractMediaId: '', // 企業契約媒体ID
    entryId: '', // エントリーID
    destinationDivision: '', // 送信先区分
    senderCompanyName: '', // 送信元会社名
    senderMailAddress: '', // 送信元メールアドレス
    notificationMailAddress: '', // 通知先メールアドレス
    barcodeDisplayFlag: '', // バーコード表示フラグ
    subject: '', // 件名
    body: '', // 本文
    sendTimeFlag: '',  
    readFlag: '', // 既読フラグ
    sysVersionNumber: '', // sysバージョン番号
    subjectForSending:'', // 送信用件名
    bodyForSending:'', // 送信用本文
    bulkSendFlag:'', // 一括送信フラグ
    convertingBatchCompleteFlag:'', // 変換バッチ完了フラグ
    status:'', // ステータス
    messageSendType: '', // メッセージ送信種別
    submissionContentName: '', // 提出物内容名
    submissionContentNameStudent: '', // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag: '', // 提出物エントリシートフラグ
    submissionResumeFlag: '', // 提出物履歴書フラグ
    submissionResearchSummaryFlag: '', // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag: '', // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag: '', // 提出物ファイル提出フラグ
    resubmitReceptionFlag : '', // 再提出受付フラグ
/*---------------------------------------------------------------------------------*/
    unsubscribeFlag: '', // 退会済みフラグ
    buttonDisableFlag: '', // ボタンを活性化フラグ 0:非活性化 1:活性化
    buttonDisplayFlag: '', // ボタンを表示フラグ 0:非表示 1:表示
    interviewList: [],
    attachedOptionTitle: '', // 添付オプションタイトル
}
const searchCriteriaInfoInitialValues = {
    searchCriteriaName: '', // 検索条件名
    searchCriteria: '' // 検索条件
}

const initialValues = {
  attachedList: [],
  attachmentFileList: [],
  jobSeekerInfo: [],
  jobSeekersInfoCountFlag: '0',
  selectMsgDetail: selectMsgDetailInitialValues,
  downloadAuthority: false,
  dateTimeDetermine: '',
  searchCriteriaInfo: searchCriteriaInfoInitialValues,
  // #MCB1.5次開発 #72395 START
  senderAddressFix: '' //  送信元メールアドレス固定値
  // #MCB1.5次開発 #72395 END

}
const labelListEmploymentAndSendMcb = [
  '送信先',
  '氏名</br>応募者管理ID',
  '学校</br>学部</br>学科',
]
export { initialValues, labelListEmploymentAndSendMcb};