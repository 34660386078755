import request from 'utils/request'
import { MCAXS361EntryDateRequest } from 'types/MCAXS361/MCAXS361EntryDateRequest'
import { MCAXS361EntryPresenceRequest } from 'types/MCAXS361/MCAXS361EntryPresenceRequest'
import { MCAXS361QuestionnaireResponseRequest } from 'types/MCAXS361/MCAXS361QuestionnaireResponseRequest'
import { MCAXS361SeminarReceptionRequest } from 'types/MCAXS361/MCAXS361SeminarReceptionRequest'
import { MCAXS361EventsRequest } from 'types/MCAXS361/MCAXS361EventsRequest'
import { MCAXS361SendMessageRequest } from 'types/MCAXS361/MCAXS361SendMessageRequest'
import { MCAXS361InitRequest } from 'types/MCAXS361/MCAXS361InitRequest'

export const getMCAXS361InitRequest = (interview: MCAXS361InitRequest) => 
request({
  url: '/MCAXS361/init',
  method: 'post',
  data: interview,
})

export const EntryDateRequest = (interview: MCAXS361EntryDateRequest) =>
  request({
    url: `MCAXS361/EntryDateBtnClick`,
    method: 'post',
    data: interview,
  })

export const EntryPresenceRequest = (interview: MCAXS361EntryPresenceRequest) =>
  request({
    url: '/MCAXS361/EntryPresenceBtnClick',
    method: 'post',
    data: interview,
  })

export const uestionnaireResponseRequest = (
  interview: MCAXS361QuestionnaireResponseRequest
) =>
  request({
    url: '/MCAXS361/QuestionnaireResponseBtnClick',
    method: 'post',
    data: interview,
  })

export const SeminarReceptionRequest = (
  interview: MCAXS361SeminarReceptionRequest
) =>
  request({
    url: '/MCAXS361/SeminarReceptionBtnClick',
    method: 'post',
    data: interview,
  })

//WebSeminarRequest 不要か確認する

export const EventsRequest = (interview: MCAXS361EventsRequest) =>
  request({
    url: '/MCAXS361/EventsBtnClick',
    method: 'post',
    data: interview,
  })

export const SendMessegeRequest = (interview: MCAXS361SendMessageRequest) =>
  request({
    url: '/MCAXS361/SendMessegeBtnClick',
    method: 'post',
    data: interview,
  })
