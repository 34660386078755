import request from 'utils/request'
import  MCAYS030UpdateEvaluationRequest  from 'types/MCAYS030/MCAYS030UpdateEvaluationRequest'
import MCAYS030UpdateJudgeRequest from 'types/MCAYS030/MCAYS030UpdateJudgeRequest'
import MCAYS030InitConditionRequest from 'types/MCAYS030/MCAYS030InitConditionRequest'
import MCAYS030UpdateRequest from 'types/MCAYS030/MCAYS030UpdateRequest'
import { MCAYS030NotAdoptedNotificationPreferenceCheckRequest } from 'types/MCAYS030/MCAYS030NotAdoptedNotificationPreferenceCheckRequest'
 
export const processChangeQueryListRequest = (requestPayloadList:string) => {
  const data = new FormData()
  data.append('requestDataPayload', requestPayloadList)
  return request({
    url: '/MCAYS030/init',
    method: 'post',
    data: data,
  })
}

export const updateDecideListRequest = (requestPayloadList: MCAYS030UpdateJudgeRequest) =>
  request({
    url: '/MCAYS030/updateDecide',
    method: 'post',
    data: requestPayloadList,
  })

export const progerssChangeUpdateRequest = (requestPayloadList: MCAYS030UpdateJudgeRequest) =>
  request({
    url: '/MCAYS030/progress',
    method: 'post',
    data: requestPayloadList,
  })

export const unifiedsendMessageRequest = (sendMessageRequest: MCAYS030UpdateRequest) =>
  request({
    url: '/MCAYS030/sendMessageChk',
    method: 'post',
    data: sendMessageRequest
  })

export const evaluationUpdRequest = (priority: MCAYS030UpdateEvaluationRequest) =>
  request({
    url: '/MCAYS030/updateEvaluation',
    method: 'post',
    data: priority,
  })

// [phase2] start by zhangxp
export const updateDecideListDateRequest = (payload: string) => {
  const data = new FormData()
  data.append('requestDataPayload', payload)
  return request({
    url: '/MCAYS030/updateDecide',
    method: 'post',
    data: data,
  })
}

export const progerssChangeDateUpdateRequest = (payload: string) => {
  const data = new FormData()
  data.append('requestDataPayload', payload)
  return request({
    url: '/MCAYS030/progress',
    method: 'post',
    data: data,
  })
}

export const evaluationDateUpdRequest = (payload: string) => {
  const data = new FormData()
  data.append('requestDataPayload', payload)
  return request({
    url: '/MCAYS030/updateEvaluation',
    method: 'post',
    data: data,
  })
}
// [phase2] end by zhangxp
// 次期9月対応 #58576 start 
export const notPassNotificationCheckRequest030 = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAYS030/notPassNotificationCheck/${recruitmentManagementDivision}`,
    method: 'post',
  })
export const notAdoptedNotificationPreferenceCheckRequest030 = (checkParam: MCAYS030NotAdoptedNotificationPreferenceCheckRequest) =>
request({
  url: `/MCAYS030/notAdoptedNotificationPreferenceCheck`,
  method: 'post',
  data: checkParam,
 })
// 次期9月対応 #58576 end