import request from 'utils/request'
import { MCAXS650InitRequest } from 'types/MCAXS650/MCAXS650InitRequest'
import { MCAXS650ApplicantInfoRequest } from 'types/MCAXS650/MCAXS650ApplicantInfoRequest'

export const MCAXS650InitRequestApi = (params: MCAXS650InitRequest) =>
    request({
        url: '/MCAXS650/init',
        method: 'post',
        data: params
    })

export const MCAXS650RegisterRequestApi = (params: MCAXS650ApplicantInfoRequest) =>
    request({
        url: '/MCAXS650/register',
        method: 'post',
        data: params
    })