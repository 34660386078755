import request from 'utils/request'

import { MCBHS180OutputPdfRequest } from 'types/MCBHS180/MCBHS180OutputPdfRequest'
import { MCBHS180OutputCsvRequest } from 'types/MCBHS180/MCBHS180OutputCsvRequest'
import { MCBHS180McbMessageRequest } from 'types/MCBHS180/MCBHS180McbMessageRequest'
import { MCBHS180InitRequest } from 'types/MCBHS180/MCBHS180InitRequest'
import { MCBHS180ChangeRequestDeadlineRequest } from 'types/MCBHS180/MCBHS180ChangeRequestDeadlineRequest'
import { MCBHS180ForcedStopRequest } from 'types/MCBHS180/MCBHS180ForcedStopRequest'
import { MCBHS180ToJobSeekManageRequest } from 'types/MCBHS180/MCBHS180ToJobSeekManageRequest'


export const initApi = () =>
  request({
    url: "/MCBHS180/init",
    method: 'post',
  })

export const outputPdfApi = (requestdata: MCBHS180OutputPdfRequest) =>
  request({
    url: "/MCBHS180/outputPdf",
    method: 'post',
    data: requestdata
  })

export const outputCsvApi = (requestdata: MCBHS180OutputCsvRequest) =>
  request({
    url: "/MCBHS180/outputMcbCsv",
    method: 'post',
    data: requestdata
  })

export const sendMessageApi = (requestdata: MCBHS180McbMessageRequest) =>
  request({
    url: "/MCBHS180/mcbMessage",
    method: 'post',
    data: requestdata
  })
  export const changerequestdeadlineApi = (requestdata: MCBHS180ChangeRequestDeadlineRequest) =>
  request({
    url: "/MCBHS180/changeRequestDeadline",
    method: 'post',
    data: requestdata
  })
  
  export const forcedstopApi = (requestdata: MCBHS180ForcedStopRequest) =>
  request({
    url: "/MCBHS180/forcedStop",
    method: 'post',
    data: requestdata
  })

  export const toJobSeekManageApi = (requestdata: MCBHS180ToJobSeekManageRequest) =>
  request({
    url: "/MCBHS180/toJobSeekManage",
    method: 'post',
    data: requestdata
  })

