import { dateToString } from 'common/generalUtil'
import { SearchCondition as EntryListSearchCondition } from 'pages/MCAXS020/searchConditionConfig'
import { deepEqual } from 'utils/misc'

const managementAnd = '全てと一致'
const managementOr = 'いずれかに一致'

export interface SearchCondition {
  applicantId: string | null
  name: string | null
  ageFrom: string | null
  ageTo: string | null
  sexObj: Option[] | null
  mailAddress: string | null
  spouseObj: Option[] | null
  residenceObj: Option[] | null
  finalEducationUniversityObj: Option[] | null
  educationalBackground: string[] | null
  languageSkills: string[] | null
  eligibility: string[] | null
  experienceCountObj: Option[] | null
  employmentSituationObj: Option[] | null
  preferredWorkplaceObj: Option[] | null
  jobCareer: string[] | null
  experiencedJob: string[] | null
  wholeResume: Option[] | null
  applicationFrom: string | null
  applicationTo: string | null
  memberTypeObj: Option[] | null
  applicationRouteObj: Option[] | null
  postingStartTime: string | null
  postingEndTime: string | null
  applicationTypeObj: Option | null
  questionnaireObj: QuestionnaireObj | null
  entryTypeObj: Option[] | null
  entryClassificationObj: Option[] | null
  scoutBenefitsObj: Option[] | null
  exemptionObj: Option[] | null
  unreadObj: Option[] | null
  duplicationObj: Option[] | null
  multipleObj: Option[] | null
  selectionFlowObj: Option | null
  selectionStepObj: Option[] | null
  judgmentUndeterminedObj: Option[] | null
  managementItemObj: ManagementItemObj | null
  priorityFrom: string | null
  priorityTo: string | null
  tagTxtObj: Option[] | null
  //タグ条件判定FLG
  tagJudgmentFlag: string | null
  //タグ条件判定名
  tagJudgmentName: string | null
  // タグ除外
  tagExclusionTxtObj: Option[] | null
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: string | null
  // タグ除外条件判定名
  tagExclusionJudgmentName: string | null
  // #58578 次期開発2022年5月 start
  opqObj: TestObj[] | null
  gabObj: TestObj[] | null
  imagesObj: TestObj[] | null
  cabObj: TestObj[] | null
  // #58578 次期開発2022年5月 end
}

export interface Option {
  [key: string]: any
  label: string
  value: string
}

// #58578 次期開発2022年5月 start
export interface TestObj {
  logicalName: string
  physicalName: string
  valueFrom: string
  valueTo: string
}
// #58578 次期開発2022年5月 end

export interface ApplicationRouteObj {
  applicationRouteFlag: number
  applicationRouteId: string
  applicationRouteName: string | null
}
export interface ApplicationTypeObj {
  applicationTypeId: string
  applicationTypeName: string | null
}

export interface QuestionnaireObj {
  typeCode: string
  typeName: string
  conditionList: QuestionnaireCondition[]
}

export interface QuestionnaireCondition {
  questionNumber: string
  questionTitle: string
  questionOption?: string
  answerConditionFlag: string
  answerConditionName: string
  questionTypeCode: string
  questionTypeName: string
  answerText: string
  answerCheckBoxObj: Option[]
}

export interface UnreadObj {
  unreadCode: string
  unreadName: string
}

export interface SelectionFlowObj {
  selectionFlowId: string
  selectionFlowName: string | null
}
export interface SelectionStepObj {
  selectionStepId: string
  selectionStepName: string | null
}

export interface ManagementItemObj {
  typeCode: string
  typeName: string
  conditionList: ManagementItemCondition[]
}

export interface ManagementItemCondition {
  recruitmentManagementFlagSettingId: string
  recruitmentManagementFlagName: string
  managementItemPresenceFlag: string
  managementItemPresenceName: string
  managementItemTypeCode: string
  managementItemTypeName: string
  managementItemTagText: string
  managementItemTagFrom: string
  managementItemFlagTo: string
  managementItemFlagCheckBoxObj: Option[]
  errorsMessage: string
  manageIndex: number
  manageName: string
}

export const initSearchCondition: SearchCondition = {
  applicantId: null,
  name: null,
  ageFrom: null,
  ageTo: null,
  sexObj: null,
  mailAddress: null,
  spouseObj: null,
  residenceObj: null,
  finalEducationUniversityObj: null,
  educationalBackground: null,
  languageSkills: null,
  eligibility: null,
  experienceCountObj: null,
  employmentSituationObj: null,
  preferredWorkplaceObj: null,
  jobCareer: null,
  experiencedJob: null,
  wholeResume: null,
  applicationFrom: null,
  applicationTo: null,
  memberTypeObj: null,
  applicationRouteObj: null,
  postingStartTime: null,
  postingEndTime: null,
  applicationTypeObj: null,
  questionnaireObj: null,
  entryTypeObj: null,
  entryClassificationObj: null,
  scoutBenefitsObj: null,
  exemptionObj: null,
  unreadObj: null,
  duplicationObj: null,
  multipleObj: null,
  selectionFlowObj: null,
  selectionStepObj: null,
  judgmentUndeterminedObj: null,
  managementItemObj: null,
  priorityFrom: null,
  priorityTo: null,
  tagTxtObj: null,
  tagJudgmentFlag: null,
  tagJudgmentName: null,
  // タグ除外
  tagExclusionTxtObj: null,
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: null,
  // タグ除外条件判定名
  tagExclusionJudgmentName: null,
  // #58578 次期開発2022年5月 start
  opqObj: null,
  gabObj: null,
  imagesObj: null,
  cabObj: null,
  // #58578 次期開発2022年5月 end
}

export const transform = (input: any) => {
  const [propertyNameOne, propertyNameTwo] = Object.keys(input)
  return propertyNameOne.includes('Name')
    ? {
        label: input[propertyNameOne],
        value: input[propertyNameTwo],
      }
    : {
        label: input[propertyNameTwo],
        value: input[propertyNameOne],
      }
}

// MCBリプレイス #8947 START
export const transformNumberVal = (input: any) => {
  const [propertyNameOne, propertyNameTwo] = Object.keys(input)
  return propertyNameOne.includes('Name')
    ? {
        label: input[propertyNameOne],
        value: Number(input[propertyNameTwo]),
      }
    : {
        label: input[propertyNameTwo],
        value: Number(input[propertyNameOne]),
      }
}
// MCBリプレイス #8947 END

export const transformApplicationRoute = (input: any) => {
  const [propertyNameOne, propertyNameTwo, propertyNameThree] = Object.keys(
    input
  )
  return {
    applicationRouteFlag: input[propertyNameOne],
    value: input[propertyNameTwo],
    label: input[propertyNameThree],
  }
}

export interface OrderObjArray {
  sexCodeList: { sexCode: number }[]
  spouseCodeList: { spouseCode: number }[]
  finalEducationUniversityCodeList: { finalEducationUniversityCode: number }[]
  experienceCountCodeList: { experienceCountCode: number }[]
  employmentSituationCodeList: { employmentSituationCode: number }[]
  memberTypeCodeList: { memberTypeCode: number }[]
  applicationRouteInfoList:{ applicationRouteFlag: number,applicationRouteId: number }[]
  entryTypeCodeList: { entryTypeCode: number }[]
  entryClassificationCodeList: { entryClassificationCode: number }[]
  scoutBenefitsCodeList: { scoutBenefitsCode: number }[]
  selectionStepIdList: { selectionStepId: number }[]
  judgmentUndeterminedIdList: { judgmentUndeterminedId: number }[]
}

export const initOrderObjArray: OrderObjArray = {
  sexCodeList: [],
  spouseCodeList: [],
  finalEducationUniversityCodeList: [],
  experienceCountCodeList: [],
  employmentSituationCodeList: [],
  memberTypeCodeList: [],
  applicationRouteInfoList: [],
  entryTypeCodeList: [],
  entryClassificationCodeList: [],
  scoutBenefitsCodeList: [],
  selectionStepIdList: [],
  judgmentUndeterminedIdList: [],
}

export const toEntryList = (
  input: SearchCondition,
  orderObjArray?: OrderObjArray
): EntryListSearchCondition => ({
  ...input,
  applicantId: input.applicantId
    ? input.applicantId.split(/\r\n|\n/g).filter(i => i !== '')
    : null,
  name: input.name
    ? input.name !== ''
      ? input.name.split(/\r\n|\n/g).filter(i => i !== '')
      : null
    : null,
  ageFrom: input.ageFrom !== '' ? input.ageFrom : null,
  ageTo: input.ageTo !== '' ? input.ageTo : null,
  sexObj: input.sexObj
    ? input.sexObj
        .map(i => ({ sexCode: i.value, sexName: i.label }))
        .sort((a, b) => {
          //詳細検索条件画面：性別の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { sexCode: Number(a.sexCode) },
              { sexCode: Number(b.sexCode) },
              orderObjArray.sexCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  mailAddress: input.mailAddress !== '' ? input.mailAddress : null,
  spouseObj: input.spouseObj
    ? input.spouseObj
        .map(i => ({ spouseCode: i.value, spouseName: i.label }))
        .sort((a, b) => {
          //詳細検索条件画面：配偶者の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { spouseCode: Number(a.spouseCode) },
              { spouseCode: Number(b.spouseCode) },
              orderObjArray.spouseCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  residenceObj: input.residenceObj
    ? input.residenceObj.map(i => ({
        residencePrefecturesCode: i.value,
        residencePrefecturesName: i.label,
      }))
    : null,
  finalEducationUniversityObj: input.finalEducationUniversityObj
    ? input.finalEducationUniversityObj.map(i => ({
          finalEducationUniversityCode: i.value,
          finalEducationUniversityName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：最終学歴の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { finalEducationUniversityCode: Number(a.finalEducationUniversityCode) },
              { finalEducationUniversityCode: Number(b.finalEducationUniversityCode) },
              orderObjArray.finalEducationUniversityCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  educationalBackground:
    input.educationalBackground && input.educationalBackground.length > 0
      ? input.educationalBackground
      : null,
  languageSkills:
    input.languageSkills && input.languageSkills.length > 0
      ? input.languageSkills
      : null,
  eligibility:
    input.eligibility && input.eligibility.length > 0
      ? input.eligibility
      : null,
  experienceCountObj: input.experienceCountObj
    ? input.experienceCountObj.map(i => ({
          experienceCountCode: i.value,
          experienceCountName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：経験社数の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { experienceCountCode: Number(a.experienceCountCode) },
              { experienceCountCode: Number(b.experienceCountCode) },
              orderObjArray.experienceCountCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  employmentSituationObj: input.employmentSituationObj
    ? input.employmentSituationObj.map(i => ({
          employmentSituationCode: i.value,
          employmentSituationName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：現在の就業状況の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { employmentSituationCode: Number(a.employmentSituationCode) },
              { employmentSituationCode: Number(b.employmentSituationCode) },
              orderObjArray.employmentSituationCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  preferredWorkplaceObj: input.preferredWorkplaceObj
    ? input.preferredWorkplaceObj.map(i => ({
        preferredWorkplacePrefecturesCode: i.value,
        preferredWorkplacePrefecturesName: i.label,
      }))
    : null,
  jobCareer:
    input.jobCareer && input.jobCareer.length > 0 ? input.jobCareer : null,
  experiencedJob:
    input.experiencedJob && input.experiencedJob.length > 0
      ? input.experiencedJob
      : null,
  memberTypeObj: input.memberTypeObj
  ? input.memberTypeObj.map(i => ({
          memberTypeCode: i.value,
          memberTypeName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：会員種別コードの並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { memberTypeCode: Number(a.memberTypeCode) },
              { memberTypeCode: Number(b.memberTypeCode) },
              orderObjArray.memberTypeCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  wholeResume: input.wholeResume ? input.wholeResume.map(i => i.value) : null,
  applicationFrom: input.applicationFrom ? input.applicationFrom : null,
  applicationTo: input.applicationTo ? input.applicationTo : null,
  applicationRouteObj: input.applicationRouteObj
    ? input.applicationRouteObj
        .map(item => ({
          applicationRouteFlag: Number(item.applicationRouteFlag),
          applicationRouteId: item.value.toString(),
          applicationRouteName: item.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：応募経路の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              {applicationRouteFlag:Number(a.applicationRouteFlag), applicationRouteId:Number(a.applicationRouteId)},
              {applicationRouteFlag:Number(b.applicationRouteFlag), applicationRouteId:Number(b.applicationRouteId)},
              orderObjArray.applicationRouteInfoList
            )
          } else {
            return 0
          }
        })
    : null,
  applicationTypeObj: input.applicationTypeObj
    ? {
        jobId: input.applicationTypeObj.value,
        applicationTypeId: input.applicationTypeObj.value2,
        applicationTypeName: input.applicationTypeObj.label,
      }
    : null,
  entryTypeObj: input.entryTypeObj
  ? input.entryTypeObj.map(i => ({
          entryTypeCode: i.value,
          entryTypeName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：応募種別の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { entryTypeCode: Number(a.entryTypeCode) },
              { entryTypeCode: Number(b.entryTypeCode) },
              orderObjArray.entryTypeCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  entryClassificationObj: input.entryClassificationObj
  ? input.entryClassificationObj.map(i => ({
          entryClassificationCode: i.value,
          entryClassificationName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：応募区分の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { entryClassificationCode: Number(a.entryClassificationCode) },
              { entryClassificationCode: Number(b.entryClassificationCode) },
              orderObjArray.entryClassificationCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  scoutBenefitsObj: input.scoutBenefitsObj
  ? input.scoutBenefitsObj.map(i => ({
          scoutBenefitsCode: i.value,
          scoutBenefitsName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：スカウト特典の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { scoutBenefitsCode: Number(a.scoutBenefitsCode) },
              { scoutBenefitsCode: Number(b.scoutBenefitsCode) },
              orderObjArray.scoutBenefitsCodeList
            )
          } else {
            return 0
          }
        })
    : null,
  exemptionObj: input.exemptionObj
    ? input.exemptionObj.length > 0
      ? {
          exemptionCode: input.exemptionObj[0].value,
          exemptionName: input.exemptionObj[0].label,
        }
      : null
    : null,
  unreadObj: input.unreadObj
    ? input.unreadObj.length > 0
      ? {
          unreadCode: input.unreadObj[0].value,
          unreadName: input.unreadObj[0].label,
        }
      : null
    : null,
  duplicationObj: input.duplicationObj
    ? input.duplicationObj.length > 0
      ? {
          duplicationFlag: input.duplicationObj[0].value,
          duplicationName: input.duplicationObj[0].label,
        }
      : null
    : null,
  multipleObj: input.multipleObj
    ? input.multipleObj.length > 0
      ? {
          multipleFlag: input.multipleObj[0].value,
          multipleName: input.multipleObj[0].label,
        }
      : null
    : null,
  selectionFlowObj: input.selectionFlowObj
    ? [
        {
          selectionFlowId: input.selectionFlowObj.value,
          selectionFlowName: input.selectionFlowObj.label,
        },
      ]
    : null,
  selectionStepObj: input.selectionStepObj
  ? input.selectionStepObj.map(i => ({
          selectionStepId: i.value,
          selectionStepName: i.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面：選考ステップの並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { selectionStepId: Number(a.selectionStepId) },
              { selectionStepId: Number(b.selectionStepId) },
              orderObjArray.selectionStepIdList
            )
          } else {
            return 0
          }
        })
    : null,
  judgmentUndeterminedObj: input.judgmentUndeterminedObj
    ? input.judgmentUndeterminedObj.map(i => ({
        judgmentUndeterminedId: i.value,
        judgmentUndeterminedName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面：合否の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { judgmentUndeterminedId: Number(a.judgmentUndeterminedId) },
            { judgmentUndeterminedId: Number(b.judgmentUndeterminedId) },
            orderObjArray.judgmentUndeterminedIdList
          )
        } else {
          return 0
        }
      })
    : null,
  tagTxtObj: input.tagTxtObj
    ? input.tagTxtObj.map(i => ({
        tagSettingId: i.value,
        tagName: i.label,
      }))
    : null,
  tagJudgmentFlag: input.tagJudgmentFlag ? input.tagJudgmentFlag : null,
  tagJudgmentName: input.tagJudgmentFlag ? (input.tagJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // タグ除外
  tagExclusionTxtObj:input.tagExclusionTxtObj
    ? input.tagExclusionTxtObj.map(i => ({
    tagExclusionSettingId: i.value,
    tagExclusionName: i.label,
    }))
  :null,
  tagExclusionJudgmentFlag: input.tagExclusionJudgmentFlag ? input.tagExclusionJudgmentFlag : null,
  tagExclusionJudgmentName: input.tagExclusionJudgmentName ? (input.tagExclusionJudgmentFlag === '0' ? managementAnd : managementOr) : null,

  questionnaireObj: input.questionnaireObj
    ? {
        questionnaireConditionsJudgmentFlag: Number(
          input.questionnaireObj.typeCode
        ),
        questionnaireConditionsJudgmentName:
        input.questionnaireObj.typeCode === '0' ? managementAnd : managementOr,
        questionnaireConditionsObj: input.questionnaireObj.conditionList.map(
          i => ({
            ...i,
            answerConditionFlag: Number(i.answerConditionFlag),
            answerConditionName:
              i.answerConditionFlag.toString() === '0' ? '回答あり' : '回答なし',
            answerCheckBoxObj: i.answerCheckBoxObj.map(item => ({
              answerCheckBoxId: item.value,
              answerCheckBoxName: item.label,
            })),
          })
        ),
      }
    : null,
  managementItemObj: input.managementItemObj
    ? ({
        managementItemJudgmentFlag: Number(input.managementItemObj.typeCode),
        managementItemJudgmentName:
        input.managementItemObj.typeCode === '0' ? managementAnd : managementOr,
        managementItemConditionsObj: input.managementItemObj.conditionList.map(
          i => ({
            ...i,
            managementItemPresenceFlag: Number(i.managementItemPresenceFlag),
            managementItemPresenceName:
              (() =>{
                const flag = Number(i.managementItemPresenceFlag)
                if (flag === 0) {
                  return '値がある'
                } else if (flag === 1) {
                  return '値がない'
                } else {
                  return 'を除く'
                }
              })(),
            managementItemFlagCheckBoxObj: i.managementItemFlagCheckBoxObj.map(
              item => ({
                managementItemFlagCheckBoxId: item.value,
                managementItemFlagCheckBoxName: item.label,
              })
            ),
          })
        ),
      } as any)
    : null,
  // #58578 次期開発2022年5月 start
  opqObj: input.opqObj
    ? input.opqObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            opqLogicalName: i.logicalName,
            opqPhysicalName: i.physicalName,
            opqValueFrom:
              i.valueFrom !== '' && i.valueFrom !== null
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom
                : null,
            opqValueTo:
              i.valueTo !== '' && i.valueTo !== null
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            opqTyp: i.physicalName.includes('Date') || i.physicalName.includes('date') ? '0' : '1',
            opqTypName: i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値',
          }
      )
    : null,
  gabObj: input.gabObj
    ? input.gabObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            gabLogicalName: i.logicalName,
            gabPhysicalName: i.physicalName,
            gabValueFrom:
              i.physicalName.toLowerCase().includes('exam_language') ? i.valueFrom
                : i.physicalName.toLowerCase().includes('date') && i.valueFrom !== '' && i.valueFrom !== null
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom,
            gabValueTo:
              i.valueTo !== '' && i.valueTo !== null && !i.physicalName.includes('exam_Language')
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            gabListFlag: i.physicalName.includes('exam_Language') ? (i.valueFrom.includes('英語') ? '1' : (i.valueFrom.includes('日本語') ? '0' : null)) : null,
            gabListName: i.physicalName.includes('exam_Language') ? i.valueFrom : null,
            gabTyp: i.physicalName.includes('exam_Language') ? '0' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '1' : '2'),
            gabTypName: i.physicalName.includes('exam_Language') ? 'リスト' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値'),
          }
      )
    : null,
  imagesObj: input.imagesObj
    ? input.imagesObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            imagesLogicalName: i.logicalName,
            imagesPhysicalName: i.physicalName,
            imagesValueFrom:
              i.valueFrom !== ''
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom
                : null,
            imagesValueTo:
              i.valueTo !== ''
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
          }
      )
    : null,
  cabObj: input.cabObj
    ? input.cabObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            cabLogicalName: i.logicalName,
            cabPhysicalName: i.physicalName,
            cabValueFrom:
              i.physicalName.toLowerCase().includes('exam_language') ? i.valueFrom
                : i.physicalName.toLowerCase().includes('date') && i.valueFrom !== '' && i.valueFrom !== null
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom,
            cabValueTo:
              i.valueTo !== '' && i.valueTo !== null && !i.physicalName.includes('exam_language')
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            cabListFlag: i.physicalName.includes('exam_language') ? (i.valueFrom.includes('英語') ? '1' : (i.valueFrom.includes('日本語') ? '0' : null)) : null,
            cabListName: i.physicalName.includes('exam_language') ? i.valueFrom : null,
            cabTyp: i.physicalName.includes('exam_language') ? '0' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '1' : '2'),
            cabTypName: i.physicalName.includes('exam_language') ? 'リスト' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値'),
          }
      )
    : null,
  // #58578 次期開発2022年5月 end
})

export const fromEntryList = (
  input: EntryListSearchCondition
): SearchCondition => ({
  ...input,
  applicantId: input.applicantId ? input.applicantId.join('\r\n') : '',
  name: input.name ? input.name.join('\r\n') : '',
  sexObj: input.sexObj ? input.sexObj.map(transform) : null,
  spouseObj: input.spouseObj ? input.spouseObj.map(transform) : null,
  residenceObj: input.residenceObj ? input.residenceObj.map(transform) : null,
  finalEducationUniversityObj: input.finalEducationUniversityObj
    ? input.finalEducationUniversityObj.map(transform)
    : null,
  educationalBackground: input.educationalBackground
    ? input.educationalBackground
    : [],
  languageSkills: input.languageSkills ? input.languageSkills : [],
  eligibility: input.eligibility ? input.eligibility : [],

  experienceCountObj: input.experienceCountObj
    ? input.experienceCountObj.map(transform)
    : null,
  employmentSituationObj: input.employmentSituationObj
    ? input.employmentSituationObj.map(transform)
    : null,
  preferredWorkplaceObj: input.preferredWorkplaceObj
    ? input.preferredWorkplaceObj.map(transform)
    : null,
  jobCareer: input.jobCareer ? input.jobCareer : [],
  experiencedJob: input.experiencedJob ? input.experiencedJob : [],
  memberTypeObj: input.memberTypeObj
    ? input.memberTypeObj.map(transform)
    : null,
  wholeResume: input.wholeResume
    ? input.wholeResume.map(i => ({
        label: i,
        value: i,
      }))
    : null,
  applicationFrom: input.applicationFrom ? input.applicationFrom : '',
  applicationTo: input.applicationTo ? input.applicationTo : '',
  applicationRouteObj: input.applicationRouteObj
    ? input.applicationRouteObj.map(transformApplicationRoute)
    : null,
  applicationTypeObj: input.applicationTypeObj
    ? {
      value: input.applicationTypeObj.jobId as any,
      value2: input.applicationTypeObj.applicationTypeId,
      label: input.applicationTypeObj.applicationTypeName as any,
    }
    : null,
  questionnaireObj: input.questionnaireObj
    ? {
        typeCode: input.questionnaireObj.questionnaireConditionsJudgmentFlag.toString(),
        typeName:
        Number(input.questionnaireObj.questionnaireConditionsJudgmentFlag) === 0
            ? 'AND'
            : 'OR',
        conditionList: input.questionnaireObj.questionnaireConditionsObj.map(
          i =>
            ({
              ...i,
              answerConditionFlag: i.answerConditionFlag.toString(),
              answerConditionName:
                i.answerConditionFlag.toString() === '0' ? '回答あり' : '回答なし',
              answerCheckBoxObj: i.answerCheckBoxObj.map(transform),
            } as any)
        ),
      }
    : null,
  entryTypeObj: input.entryTypeObj ? input.entryTypeObj.map(transform) : null,
  entryClassificationObj: input.entryClassificationObj
    ? input.entryClassificationObj.map(transform)
    : null,
  scoutBenefitsObj: input.scoutBenefitsObj
    ? input.scoutBenefitsObj.map(transform)
    : null,
  exemptionObj: input.exemptionObj ? [transform(input.exemptionObj)] : null,
  unreadObj: input.unreadObj ? [transform(input.unreadObj)] : null,
  duplicationObj: input.duplicationObj
    ? [transform(input.duplicationObj)]
    : null,
  multipleObj: input.multipleObj ? [transform(input.multipleObj)] : null,
  selectionFlowObj: input.selectionFlowObj
    ? input.selectionFlowObj.length > 0
      ? ({
          value: input.selectionFlowObj[0].selectionFlowId,
          label: input.selectionFlowObj[0].selectionFlowName,
        } as Option)
      : null
    : null,
  selectionStepObj: input.selectionStepObj
    ? input.selectionStepObj.map(transform)
    : null,
  judgmentUndeterminedObj: input.judgmentUndeterminedObj
    ? input.judgmentUndeterminedObj.map(transform)
    : null,
  managementItemObj: input.managementItemObj
    ? {
        typeCode: input.managementItemObj.managementItemJudgmentFlag.toString(),
        typeName:
        Number(input.managementItemObj.managementItemJudgmentFlag as any) === 0
            ? 'AND'
            : 'OR',
        conditionList: input.managementItemObj.managementItemConditionsObj.map(
          i =>
            ({
              ...i,
              managementItemPresenceFlag: i.managementItemPresenceFlag.toString(),
              managementItemPresenceName:
                (() =>{
                  const flag = Number(i.managementItemPresenceFlag)
                  if (flag === 0) {
                    return '値がある'
                  } else if (flag === 1) {
                    return '値がない'
                  } else {
                    return 'を除く'
                  }
                })(),
              managementItemFlagCheckBoxObj: i.managementItemFlagCheckBoxObj.map(
                transform
              ),
            } as any)
        ),
      }
    : null,
  tagTxtObj: input.tagTxtObj ? input.tagTxtObj.map(transform) : null,
  tagJudgmentFlag: input.tagJudgmentFlag ? input.tagJudgmentFlag : null,
  tagJudgmentName: input.tagJudgmentFlag ? (input.tagJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // タグ除外
  tagExclusionTxtObj: input.tagExclusionTxtObj ? input.tagExclusionTxtObj.map(transform) : null,
  tagExclusionJudgmentFlag: input.tagExclusionJudgmentFlag ? input.tagExclusionJudgmentFlag : null,
  tagExclusionJudgmentName: input.tagExclusionJudgmentName ? (input.tagExclusionJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // #58578 次期開発2022年5月 start
  opqObj: input.opqObj
    ? input.opqObj.map(
      i =>
        i && {
          physicalName: i.opqPhysicalName,
          logicalName: i.opqLogicalName,
          valueFrom: i.opqValueFrom === null ? '' : i.opqValueFrom,
          valueTo: i.opqValueTo === null ? '' : i.opqValueTo,
        }
    )
    : [],
  gabObj: input.gabObj
    ? input.gabObj.map(
      i =>
        i && {
          physicalName: i.gabPhysicalName,
          logicalName: i.gabLogicalName,
          valueFrom: i.gabValueFrom === null ? '' : i.gabValueFrom,
          valueTo: i.gabValueTo === null ? '' : i.gabValueTo,
        }
    )
    : [],
  imagesObj: input.imagesObj
    ? input.imagesObj.map(
      i =>
        i && {
          logicalName: i.imagesLogicalName,
          physicalName: i.imagesPhysicalName,
          valueFrom: i.imagesValueFrom === null ? '' : i.imagesValueFrom,
          valueTo: i.imagesValueTo === null ? '' : i.imagesValueTo,
        }
    )
    : [],
  cabObj: input.cabObj
    ? input.cabObj.map(
      i =>
        i && {
          logicalName: i.cabLogicalName,
          physicalName: i.cabPhysicalName,
          valueFrom: i.cabValueFrom === null ? '' : i.cabValueFrom,
          valueTo: i.cabValueTo === null ? '' : i.cabValueTo,
        }
    )
    : [],
  // #58578 次期開発2022年5月 end
})

/**
 *
 * 引数arrayの順で並び変えたいときに使用するArray.sortの内部関数
 *
 * @param a Array.sortの内部関数の第一引数
 * @param b Array.sortの内部関数の第二引数
 * @param orderObjArray 固定の並び順要素
 */
export const innerSortFunc = (
  a: { [key: string]: any },
  b: { [key: string]: any },
  orderObjArray: { [key: string]: any }[]
) => {
  let result = 0

  orderObjArray.some(i => {
    if (deepEqual(a, i)) {
      result = -1
      return true
    }
    if (deepEqual(b, i)) {
      result = 1
      return true
    }
  })

  return result
}

// 49324 二つOption配列の交集を取得
export const retainAll = (option1: Option[], option2: Option[]) => {
  if (option1 == null || option2 == null) {
    return []
  }
  const optionLabel = option1.map(obj => { return obj.label })
  const optionResult = option2.filter(option => {
    return optionLabel.indexOf(option.label) !== -1
  })
  if (optionResult.length === 0) {
    return []
  }
  return optionResult
}
