import React from 'react'
import { FieldProps } from 'formik'
import Reaptcha from '@panalbish/reaptcha-enterprise';

interface Props extends FieldProps {
  siteKey: string,
  onChange?: (value: string) => void
}

export default ({
  field,
  form,
  siteKey,
  onChange,
}: Props) => {
  const { name } = field
  let captcha : Reaptcha | null = null

  const handleChange = (e: any) => {
    form.setFieldValue(name, e);
    if (onChange) {
      onChange(e);
    }
  }

  const handleExpire = () => {
    form.setFieldValue(name, '');
    if (onChange) {
      onChange('');
    }
  }

  return (
    <Reaptcha
      size="normal"
      ref={e => (captcha = e)}
      sitekey={siteKey}
      enterprise={true}
      onVerify={handleChange}
      onExpire={handleExpire}
    />
  )
}
