import request from 'utils/request'
import { MCAXS151InitRequest } from 'types/MCAXS151/MCAXS151InitRequest'
import { MCAXS151StatusCheckRequest } from 'types/MCAXS151/MCAXS151StatusCheckRequest'
export const initRequest = (apidata: MCAXS151InitRequest) =>
  request({
    url: `/MCAXS151/init`,
    method: 'post',
    data: apidata,
  })
export const statusCheckRequest = (apidata: MCAXS151StatusCheckRequest) =>
  request({
    url: `/MCAXS151/statusCheck`,
    method: 'post',
    data: apidata,
  })
export const pdfMakingRequest = (apidata: MCAXS151InitRequest) =>
  request({
    url: `/MCAXS151/pdfMaking`,
    method: 'post',
    data: apidata,
  })
