import request from 'utils/request'
import {MCAFS010ContactUnansweredAlertRequest} from 'types/MCAFS010/MCAFS010ContactUnansweredAlertRequest'
import { MCAFS010InterviewFixedWaitingAlertRequest } from 'types/MCAFS010/MCAFS010InterviewFixedWaitingAlertRequest'
import { MCAFS010PassingStatusContactWaitingAlertRequest } from 'types/MCAFS010/MCAFS010PassingStatusContactWaitingAlertRequest'
import { MCAFS010ProgressResidenceAlertRequest } from 'types/MCAFS010/MCAFS010ProgressResidenceAlertRequest'
import { MCAFS010InterviewCancelAlertRequest } from 'types/MCAFS010/MCAFS010InterviewCancelAlertRequest'
import { MCAFS010InterviewCurrentDayRemindRequest } from 'types/MCAFS010/MCAFS010InterviewCurrentDayRemindRequest'
// 次期9月対応 #63073 start
import { MCAFS010MailSettingLinkRequest } from 'types/MCAFS010/MCAFS010MailSettingLinkRequest'
// 次期9月対応 #63073 end

export const initRequest = (recruitmentManagementDivision: string) =>
    request({
        url: `MCAFS010/init/${recruitmentManagementDivision}`,
        method: 'post',
    })

export const interFixWaitAlertOnRequest = (interviewFixedWaiting: MCAFS010InterviewFixedWaitingAlertRequest) =>
    request({
        url: `MCAFS010/alertOnInterviewFixedWaiting`,
        method: 'post',
        data: interviewFixedWaiting,
    })

export const interFixWaitAlertOffRequest = (interviewFixedWaiting: MCAFS010InterviewFixedWaitingAlertRequest) =>
    request({
        url: `MCAFS010/alertOffInterviewFixedWaiting`,
        method: 'post',
        data: interviewFixedWaiting,
    })

export const passStsContAlertOnRequest = (passingStateusContactWaiting: MCAFS010PassingStatusContactWaitingAlertRequest) =>
    request({
        url: `MCAFS010/alertOnPassingStatusContactWaiting`,
        method: 'post',
        data: passingStateusContactWaiting,
    })

export const passStsContAlertOffRequest = (passingStateusContactWaiting: MCAFS010PassingStatusContactWaitingAlertRequest) =>
    request({
        url: `MCAFS010/alertOffPassingStatusContactWaiting`,
        method: 'post',
        data: passingStateusContactWaiting,
    })

export const progsResdenceAlertOnRequest = (progressResidence: MCAFS010ProgressResidenceAlertRequest) =>
    request({
        url: `MCAFS010/alertOnProgressResidence`,
        method: 'post',
        data: progressResidence,
    })

export const progsResdenceAlertOffRequest = (progressResidence: MCAFS010ProgressResidenceAlertRequest) =>
    request({
        url: `MCAFS010/alertOffProgressResidence`,
        method: 'post',
        data: progressResidence,
    })

export const interCancelAlertOnRequest = (interviewCancel: MCAFS010InterviewCancelAlertRequest) =>
    request({
        url: `MCAFS010/alertOnInterviewCancel`,
        method: 'post',
        data: interviewCancel,
    })

export const interCancelAlertOffRequest = (interviewCancel: MCAFS010InterviewCancelAlertRequest) =>
    request({
        url: `MCAFS010/alertOffInterviewCancel`,
        method: 'post',
        data: interviewCancel,
    })

export const contUnanswrAlertOnRequest = (contUnanswerd: MCAFS010ContactUnansweredAlertRequest) =>
    request({
        url: `MCAFS010/alertOnContactUnanswered`,
        method: 'post',
        data: contUnanswerd,
    })

export const contUnanswrAlertOffRequest = (contUnanswerd: MCAFS010ContactUnansweredAlertRequest) =>
    request({
        url: `MCAFS010/alertOffContactUnanswered`,
        method: 'post',
        data: contUnanswerd,
    })

export const interCurtDayAlertOnRequest = (interviewCurrentDayRemind: MCAFS010InterviewCurrentDayRemindRequest) =>
    request({
        url: `MCAFS010/alertOnInterviewCurrentDayRemind`,
        method: 'post',
        data: interviewCurrentDayRemind,
    })

export const interCurtDayAlertOffRequest = (interviewCurrentDayRemind: MCAFS010InterviewCurrentDayRemindRequest) =>
    request({
        url: `MCAFS010/alertOffInterviewCurrentDayRemind`,
        method: 'post',
        data: interviewCurrentDayRemind,
    })

// 次期9月対応 #63073 start
export const mailSettingLinkRequest = (mailSettingLinkRequest: MCAFS010MailSettingLinkRequest) =>
request({
    url: `MCAFS010/alertMailSettingLink`,
    method: 'post',
    data: mailSettingLinkRequest,
})   
// 次期9月対応 #63073 end