import { Option } from 'componentsHsc/Toggle'
import { MCAXS561QueryRequest } from 'types/MCAXS561/MCAXS561QueryRequest'
import { MCAXS431QueryRequest } from 'types/MCAXS431/MCAXS431QueryRequest'

export const searchOptionList = [
  { label: 'OR', value: '0' },
  { label: 'AND', value: '1' },
]

export const initialValues = {
  // 基本情報
  applicantId: [],
  name: [],
  ageFrom: '',
  ageTo: '',
  sexObj: [],
  mailAddress: '',
  spouseObj: [],
  residenceObj: [] as Option[],
  finalEducationUniversityObj: [],
  educationalBackground: [],
  languageSkills: [],
  eligibility: [],
  experienceCountObj: [],
  employmentSituationObj: [],
  preferredWorkplaceObj: [] as Option[],
  jobCareer: [],
  experiencedJob: [],
  wholeResume: [],
  //応募情報
  applicationFrom: '',
  applicationTo: '',
  memberTypeObj: [],
  applicationRouteObj: [],
  applicationTypeObj: [],
  questionnaireObj: [],
  entryTypeObj: [],
  entryClassificationObj: [],
  scoutBenefitsObj: [],
  exemptionObj: [],
  unreadObj: [],
  duplicationObj: [],
  multipleObj: [],
  // 採用管理情報
  selectionFlowObj: [],
  selectionStepObj: [],
  judgmentUndeterminedObj: [],
  managementItemObj: [],
  priorityFrom: '',
  priorityTo: '',
  tagTxtObj: [],
}

export const initeRequest: MCAXS561QueryRequest = {
  // 連携元職種識別キー
  coordinationFormerJobCategoryIdentificationKey: null
}

export const initeQueryRequest: MCAXS431QueryRequest = {
  // 採用管理フラグ設定ID
  managementItemFlagCheckBoxId: null,

  // 詳細選択肢ID
  recruitmentManagementFlagSettingId: null
}