import { routeList } from "routes/routes"
import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"
import { JudgeUrl } from "."
import history from 'utils/history';

export const url = [
  '/entryDetail',
  '/applicantDetailForJobChange',
  '/applicantDetailForEmplyment',
  '/MCAVS020',
  '/messageTab/MCAZS070',
  '/dummy',
  '/MCAXS121',
  '/MCAXS130',
  '/messageSend',
  '/commonSettingsMenu/MCAXS230',
  '/MCAXS240',
  '/redirect',
  '/MCAXS640',
  '/commonSettingsMenu/MCAXS650',
  '/newLoginId',
  '/duplicateLogin',
  '/myCareerBoxDataLink/MCBCS010', //MCBCS010_My CareerBoxデータ連携
  '/myCareerBoxDataLink/MCBCS020', //MCBCS020_My CareerBoxデータ連携完了
  '/MCBCS030', //MCBCS030_My CareerBox連携対象一覧CSV作成中
  '/commonSettingsMenu/MCBCS040', //MCBCS040_My CareerBox連携対象一覧CSVダウンロード
  '/mcbMessageSend', //MCAZS220_My CareerBoxメッセージ入力
  '/changeTermRequest', // MCBリプレース MCBHS060
  '/forcedStopSubmissionRequest', // MCBHS090_提出リクエスト強制停止
  // MCBリプレース MCBHS010 START
  '/submissionRequestSend',
  // MCBリプレース MCBHS010 END
  // MCB リプレース B票#538対応 start
  '/messageTab/MCAZS210',// メッセージ詳細(My CareerBox)モックアップ
  '/submissionRequestManagementTab/MCBHS050', //提出依頼メッセージ詳細
  // MCB リプレース B票#538対応 end
]

export const windowsOpenUrl = [
  '/entryDetail',
  '/applicantDetailForJobChange',
  '/applicantDetailForEmplyment',
  '/MCAVS020',
  '/messageTab/MCAZS070',
  '/MCAXS121',
  '/MCAXS130',
  '/messageSend',
  '/pdf',
  '/myCareerBoxDataLink',
  '/MCBCS030',
  '/commonSettingsMenu/MCBCS040',
  '/mcbMessageSend',
  '/changeTermRequest',
  '/forcedStopSubmissionRequest',
  // MCBリプレース MCBHS010 START
  '/submissionRequestSend',
  // MCBリプレース MCBHS010 END
  // MCB リプレース B票#538対応 start
  '/messageTab/MCAZS210',// メッセージ詳細(My CareerBox)モックアップ
  '/submissionRequestManagementTab/MCBHS050', //提出依頼メッセージ詳細
  // MCB リプレース B票#538対応 end
]
// #5721 start
// パラメータ付きURL
export const paramLoginUrl = [
  'entryDetail?',
  'applicantDetailForEmplyment?',
  'MCAZS070?',
  'pdf/MCAXS151?',
]
// パラメータ付きURLのチェック
export const paramLoginUrlCheck = () => {
  let count = 0
  paramLoginUrl.forEach(u => {
    if (window.location.href.includes(u)) {
      count++
    }
  })
  if (count > 0) {
    return true
  } else {
    return false
  }
}
// #5721 end

// 年度対応 start
const yearChangeMessage1 = new Map([
  [routeList.applicationUpload, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  [routeList.entryUploadHistory, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  [routeList.entryUploadErrorInfoComfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  // 次期開発12月 #73504 start
  [routeList.entryUploadAutoMergeComfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  // 次期開発12月 #73504 end
  [routeList.nameCheckInput, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  [routeList.nameList, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  // [routeList.jobSeekerInfoInsertConfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_002)],
  [routeList.password, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],
  [routeList.errorReport, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],

  [routeList.jobSeekerNameInput, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_004)],
  [routeList.jobSeekerLogIn, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_004)],
  [routeList.jobSeekerInfoInsertConfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_004)],
])

const yearChangeMessage2 = new Map([
  [routeList.applicationUpload, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
  [routeList.entryUploadHistory, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
  [routeList.entryUploadErrorInfoComfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
  // 次期開発12月 #73504 start
  [routeList.entryUploadAutoMergeComfirm, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
  // 次期開発12月 #73504 end
  [routeList.nameCheckInput, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
  [routeList.nameList, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_005)],
])

const yearChangeUrl = new Map([
  [routeList.applicationUpload, routeList.applicantList],//応募者情報アップロード => 応募者一覧
  [routeList.entryUploadHistory, routeList.applicantList],//応募者情報アップロード 履歴一覧 => 応募者一覧
  [routeList.entryUploadErrorInfoComfirm, routeList.applicantList],//応募者情報アップロード エラー内容確認 => 応募者一覧
  // 次期開発12月 #73504 start
  [routeList.entryUploadAutoMergeComfirm, routeList.applicantList],
  // 次期開発12月 #73504 end
  [routeList.nameCheckInput, routeList.applicantList],//名寄せ条件入力 => 応募者一覧
  [routeList.nameList, routeList.applicantList],//名寄せ結果一覧 => 応募者一覧
  [routeList.jobSeekerNameInput, routeList.applicantList],//応募者氏名入力 => 応募者一覧
  [routeList.jobSeekerLogIn, routeList.applicantList],//応募者情報登録 => 応募者一覧
  [routeList.jobSeekerInfoInsertConfirm, routeList.applicantList],//応募者情報登録 内容確認 => 応募者一覧

  [routeList.errorReport, routeList.recruitmentStatusReportTab], //エラー報告 => 採用状況レポート
  [routeList.errorReportComplete, routeList.recruitmentStatusReportTab], //エラー報告 完了 => 採用状況レポート

  [routeList.messageOutBox, routeList.messageInBox],
  [routeList.messageReservation, routeList.messageInBox],
  [routeList.messageDraft, routeList.messageInBox],
  [routeList.messageDustBox, routeList.messageInBox],

  // MCB ロット３　TODO仮画面　start
  [routeList.mcbMessageOutBox, routeList.messageInBox], //メッセージ一覧（送信トレイ）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageReservation, routeList.messageInBox],//メッセージ一覧（送信予約）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDraft, routeList.messageInBox],//メッセージ一覧（下書き）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDustBox, routeList.messageInBox],//メッセージ一覧（ゴミ箱）(My CareerBox)=>メッセージ一覧（受信トレイ）
  // MCB ロット３　TODO仮画面　end
  
  [routeList.pdfDownloadListMcb, routeList.recruitmentStatusReportTab],//My CareerBoxPDFダウンロード一覧 => 採用状況レポート
    
  /**
   * 提出リクエストテンプレート登録 => 採用状況レポート
   * 提出リクエストテンプレート編集 => 採用状況レポート
   * 提出リクエストテンプレート登録 内容確認 => 採用状況レポート
   */
  [routeList.submissionReqTemplate, routeList.recruitmentStatusReportTab],//提出リクエストテンプレート一覧 => 採用状況レポート
  /**
   * 提出リクエスト一覧=>採用状況レポート
   * 検索条件詳細(MCB)_応募者情報=>採用状況レポート
   * 検索条件詳細(MCB)_エントリーシート／履歴書=>採用状況レポート
   * 検索条件詳細(MCB)_オリジナル設問／ファイル提出=>採用状況レポート
   * MCBファイル指定モーダル=>採用状況レポート
   * MY検索(My CareerBox)呼び出し=>採用状況レポート
   * MY検索(My CareerBox)保存 => 採用状況レポート
   * PDF出力選択(My CareerBox) => 採用状況レポート
   */
   [routeList.submissionRequestManagement, routeList.recruitmentStatusReportTab],
   [routeList.searchCriteriaMcb, routeList.recruitmentStatusReportTab],//MY検索(My CareerBox)一覧=>採用状況レポート
  /**
   * 提出リクエスト一覧=>採用状況レポート
   * 検索条件詳細(MCB)_応募者情報=>採用状況レポート
   * 検索条件詳細(MCB)_エントリーシート／履歴書=>採用状況レポート
   * 検索条件詳細(MCB)_オリジナル設問／ファイル提出=>採用状況レポート
   */
  [routeList.submissionRequestManagement, routeList.recruitmentStatusReportTab],

  // [routeList.password, routeList.password],
])

const yearChangeUrl2 = new Map([
  [routeList.applicationUpload, routeList.applicantList],
  [routeList.entryUploadHistory, routeList.applicantList],
  [routeList.entryUploadErrorInfoComfirm, routeList.applicantList],
  // 次期開発12月 #73504 start
  [routeList.entryUploadAutoMergeComfirm, routeList.applicantList],
  // 次期開発12月 #73504 end
  [routeList.nameCheckInput, routeList.applicantList],
  [routeList.nameList, routeList.applicantList],
  [routeList.jobSeekerNameInput, routeList.applicantList],
  [routeList.jobSeekerLogIn, routeList.applicantList],
  [routeList.jobSeekerInfoInsertConfirm, routeList.applicantList],
  
  [routeList.errorReport, routeList.recruitmentStatusReportTab],
  [routeList.errorReportComplete, routeList.recruitmentStatusReportTab],

  [routeList.messageOutBox, routeList.messageInBox],
  [routeList.messageReservation, routeList.messageInBox],
  [routeList.messageDraft, routeList.messageInBox],
  [routeList.messageDustBox, routeList.messageInBox],

  // MCB ロット３　TODO仮画面　start
  [routeList.mcbMessageOutBox, routeList.messageInBox], //メッセージ一覧（送信トレイ）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageReservation, routeList.messageInBox],//メッセージ一覧（送信予約）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDraft, routeList.messageInBox],//メッセージ一覧（下書き）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDustBox, routeList.messageInBox],//メッセージ一覧（ゴミ箱）(My CareerBox)=>メッセージ一覧（受信トレイ）
  // MCB ロット３　TODO仮画面　end
  
  [routeList.pdfDownloadListMcb, routeList.recruitmentStatusReportTab],//My CareerBoxPDFダウンロード一覧 => 採用状況レポート
  
  /**
   * 提出リクエストテンプレート登録 => 採用状況レポート
   * 提出リクエストテンプレート編集 => 採用状況レポート
   * 提出リクエストテンプレート登録 内容確認 => 採用状況レポート
   */
   [routeList.submissionReqTemplate, routeList.recruitmentStatusReportTab],//提出リクエストテンプレート一覧 => 採用状況レポート

  /**
   * 提出リクエスト一覧=>採用状況レポート(已完成)
   * 検索条件詳細(MCB)_応募者情報=>採用状況レポート
   * 検索条件詳細(MCB)_エントリーシート／履歴書=>採用状況レポート
   * 検索条件詳細(MCB)_オリジナル設問／ファイル提出=>採用状況レポート
   * MCBファイル指定モーダル=>採用状況レポート
   * MY検索(My CareerBox)呼び出し=>採用状況レポート
   * MY検索(My CareerBox)保存 => 採用状況レポート
   * PDF出力選択(My CareerBox) => 採用状況レポート
   */
  [routeList.submissionRequestManagement, routeList.recruitmentStatusReportTab],
  
  [routeList.searchCriteriaMcb, routeList.recruitmentStatusReportTab],//MY検索(My CareerBox)一覧=>採用状況レポート

  [routeList.password, routeList.password],
])

// MCB ロット３　start
//切替後の対象年度が「2025年」以降の場合
//直リンクからの遷移で自動的に切替／別画面で年度切替
const yearChangeUrl3 = new Map([
  [routeList.applicationUpload, routeList.applicantList],//応募者情報アップロード => 応募者一覧
  [routeList.entryUploadHistory, routeList.applicantList],//応募者情報アップロード 履歴一覧 => 応募者一覧
  [routeList.entryUploadErrorInfoComfirm, routeList.applicantList],//応募者情報アップロード エラー内容確認 => 応募者一覧
  // 次期開発12月 #73504 start
  [routeList.entryUploadAutoMergeComfirm, routeList.applicantList],
  // 次期開発12月 #73504 end
  [routeList.nameCheckInput, routeList.applicantList],//名寄せ条件入力 => 応募者一覧
  [routeList.nameList, routeList.applicantList],//名寄せ結果一覧 => 応募者一覧
  [routeList.jobSeekerNameInput, routeList.applicantList],//応募者氏名入力 => 応募者一覧
  [routeList.jobSeekerLogIn, routeList.applicantList],//応募者情報登録 => 応募者一覧
  [routeList.jobSeekerInfoInsertConfirm, routeList.applicantList],//応募者情報登録 内容確認 => 応募者一覧

  [routeList.errorReport, routeList.recruitmentStatusReportTab], //エラー報告 => 採用状況レポート
  [routeList.errorReportComplete, routeList.recruitmentStatusReportTab], //エラー報告 完了 => 採用状況レポート

  [routeList.messageOutBox, routeList.messageInBox],
  [routeList.messageReservation, routeList.messageInBox],
  [routeList.messageDraft, routeList.messageInBox],
  [routeList.messageDustBox, routeList.messageInBox],

  // MCB ロット３　TODO仮画面　start
  [routeList.mcbMessageOutBox, routeList.messageInBox], //メッセージ一覧（送信トレイ）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageReservation, routeList.messageInBox],//メッセージ一覧（送信予約）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDraft, routeList.messageInBox],//メッセージ一覧（下書き）(My CareerBox)=>メッセージ一覧（受信トレイ）
  [routeList.mcbMessageDustBox, routeList.messageInBox],//メッセージ一覧（ゴミ箱）(My CareerBox)=>メッセージ一覧（受信トレイ）
  // MCB ロット３　TODO仮画面　end
    
  [routeList.password, routeList.password],
]) 
// MCB ロット３　end


// 離脱チェック系
export const promptUrl = [
  routeList.password,
  routeList.jobSeekerNameInput,
  routeList.jobSeekerLogIn,
  routeList.jobSeekerInfoInsertConfirm,
  routeList.errorReport,
]
// 離脱しないが離脱チェックを行いたい画面
export const specialPromptUrlType1 = [
  routeList.password,
]

// 年度と関係なく表示すべき画面
export const forceDisplayUrl = [
  routeList.errorReport,
  routeList.errorReportComplete,
  routeList.duplicateLogin,
  routeList.interruption,
  routeList.newLoginId,
  routeList.envCharManual,
  routeList.networkError,
  routeList.notFound,
  routeList.error,
]

export const getYearChangeMessage1 = (url: any) => {
  let result = yearChangeMessage1.get(url)
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_001)
  return result
}

export const getYearChangeMessage2 = (url: any) => {
  let result = yearChangeMessage2.get(url)
  // 別ウィンドウ
  if ((window.opener && window.opener !== window) || JudgeUrl() || routeList.newLoginId == history.location.pathname 
      || routeList.applicantDetailForEmplyment == history.location.pathname) {
    result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_007)
  } else {
    if (document.querySelector('.mainDialog')) {
      result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_008)
    }
  }
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_006)
  return result
}

export const getYearChangeUrl = (url: any) => {
  let result = yearChangeUrl.get(url)
  return result
}

export const getYearChangeUrl2 = (url: any) => {
  let result = yearChangeUrl2.get(url)
  if (!result) {
    // my検索機能特殊処理
    if (history.location.pathname == routeList.applicantList && document.querySelector('.mainDialog') && document.querySelector('.mySearchMCAR')) {
      result = routeList.searchCriteria
    }
  }
  return result
}
// 年度対応 end
// MCB ロット３　start
export const getYearChangeUrl3 = (url: any) => {
  let result = yearChangeUrl3.get(url)
  if (!result) {
    // my検索機能特殊処理
    if (history.location.pathname == routeList.applicantList && document.querySelector('.mainDialog') && document.querySelector('.mySearchMCAR')) {
      result = routeList.searchCriteria
    }
  }
  return result
}
// MCB ロット３　end

// 転職March #75621 START
// 切り替え時メッセージ
const marchModeChangeMessage1 = new Map([
  [routeList.password, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],
  [routeList.errorReport, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],
  [routeList.entryInsEdit, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],
  [routeList.notAdoptedNotification, getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_003)],
])

// 強制エントリー一覧へ遷移ケース
export const caseOfToEntryListUrl = [
  routeList.commonSettingsMenu,
  routeList.password,
  routeList.account,
  routeList.recruitGroup,
  routeList.alertPreferenceSettings,
  routeList.recruitFlag,
  routeList.errorReport,
  routeList.errorReportComplete,
  routeList.notAdoptedNotification,
  routeList.entryInsEdit,
]

export const getMarchModeChangeMessage1 = (url: any) => {
  let result = marchModeChangeMessage1.get(url)
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_009)
  return result
}
export const getMarchModeChangeMessage2 = (url: any) => {
  let result = undefined
  // 別ウィンドウ
  if ((window.opener && window.opener !== window) || JudgeUrl() || routeList.newLoginId == history.location.pathname
    || routeList.entryDetail == history.location.pathname || routeList.applicantDetailForJobChange == history.location.pathname) {
    result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_011)
  } else {
    if (document.querySelector('.mainDialog')) {
      result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_012)
    }
  }
  if (!result) result = getMessage(magiContants.MESSAGECODE_MCAAS010_COMMON_010)
  return result
}
// 転職March #75621 END