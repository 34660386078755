/**
 * MCAUS010ReportRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // エラーID
  // -
  errorReportId: yup
    .string()
    .required(validationMessageSet.required),

  // 発生状況
  // -
  generationStatus: yup
    .string()
    .max(2000, validationMessageSet.maxLength)
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),
});