import { getMessage } from "common/messageUtil";
import { magiContants } from "utils/contants";

export const scheduledInitValues = {
  isPresented: '0',
  isFixedWaiting: '0',
  isFixed: '0',
  isCancelContact: '0',
  interviewDateFrom: '',
  interviewDateTo: '',
}

const scheduledSubmitValue = {
  searchTarget: '0',
  searchChar: '',
  statusPresented: '0',
  statusWaitingForConfirmation: '0',
  statusConfirm: '0',
  statusWithdraw: '0',
  statusCancellationContact: '0',
  statusCancel: '0',
  interviewDateFrom: '',
  interviewDateTo: '',
  placeSearch: '',
  selectionFlowSearch: '',
  progress: '',
  recruitmentManagementDivision: '',
  // WEB面接対応 start
  statusInterviewWeb: '0',
  // WEB面接対応 end
};

const searchTargetList = [
  { value: '0', label: '応募者の姓名' },
  { value: '1', label: '面接内容' },
  { value: '2', label: '面接の担当者' },
];

const statusList = [
  { name: 'statusPresented', label: '提示済み', id: '1' },
  { name: 'statusWaitingForConfirmation', label: '確定待ち', id: '2' },
  { name: 'statusConfirm', label: '確定', id: '3' },
  { name: 'statusWithdraw', label: '取り下げ', id: '4' },
  { name: 'statusCancellationContact', label: 'キャンセル連絡', id: '5' },
  { name: 'statusCancel', label: 'キャンセル', id: '6' },
];

const statusDisplayList: { [key: string]: any } = {
  '': { status: '提示済み', button: '', class: '', action: '' },
  'offer': { status: '提示済み', button: '候補日を変更する', class: 'action', action: 'change_possible_date' },
  'inquest': { status: '確定待ち', button: '面接日を確定する', class: 'confirm', action: 'fix_interview_date' },
  'fixed': { status: '確定', button: '面接日を取り下げる', class: 'action', action: 'withdraw_interview_date' },
  'client_cancel': { status: '取り下げ', button: '面接日を再調整する', class: 'action', action: 'readjust_interview_date' },
  'cancel_request': { status: 'キャンセル連絡', button: '連絡内容を確認する', class: 'confirm', action: '' },
  'cancel': { status: 'キャンセル', button: '面接日を再調整する', class: 'action', action: 'readjust_interview_date' },
};

const statusDisplayListOver: { [key: string]: any } = {
  '': { status: '提示済み', button: '', class: '', action: '' },
  'offer': { status: '期限切れ', button: '面接日を再調整する', class: 'action', action: 'readjust_interview_date' },
  'inquest': { status: '期限切れ', button: '面接日を再調整する', class: 'action', action: 'readjust_interview_date' },
  'hold': { status: '期限切れ', button: '面接日を再調整する', class: 'action', action: 'readjust_interview_date' },
  'fixed': { status: '実施済み', button: '', class: '', action: '' },
  'client_cancel': { status: '取り下げ', button: '', class: '', action: '' },
  'cancel_request': { status: 'キャンセル連絡', button: '連絡内容を確認する', class: 'confirm', action: '' },
  'cancel': { status: 'キャンセル', button: '', class: '', action: '' },
};

// WEB面接対応 start
const textMap = {
  create: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCBAS020_012),
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCBAS020_017),
  }
}
// WEB面接対応 end

export { scheduledSubmitValue, statusList, searchTargetList, statusDisplayList, statusDisplayListOver, textMap };