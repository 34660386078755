import { menuInitRequest, setErrorReportIdRequest } from 'apis/MCAUS010Api'
import { initErrorReport, initSuccess, updateErrorReport } from 'reducers/errorReportReducer'
import { openModal } from 'reducers/messageReducer'
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { routeList } from 'routes/routes'
import history from 'utils/history';

function* menuInitSaga(action: ReturnType<typeof initErrorReport>) {
  try {
    const data = yield call(menuInitRequest, action.payload)
    yield put(initSuccess(data))
    history.push(routeList.errorReport)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateErrorReportSaga(action: ReturnType<typeof updateErrorReport>) {
  try {
    yield call(setErrorReportIdRequest, action.payload)
    history.push(routeList.errorReportComplete)
  } catch (error) {
    yield put(openModal(error.message))
    history.push(routeList.home)
  }
}

export default function* errorReportSaga() {
  yield all([
    takeLatest(initErrorReport, menuInitSaga),
    takeLeading(updateErrorReport, updateErrorReportSaga),
  ])
}
