import React from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  textarea: {
    resize: 'none',
  },
}))

interface Props extends FieldProps {
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
}

export default ({
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  multiline,
  type,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field

  const handleChange = (e: any) => {
    if (!(hint && e.target.value.length > 100)) {
      form.setFieldValue(name, e.target.value)
    }
  }

  return (
    <div className={className}>
      {multiline ? (
        <textarea
          value={value ? value : ''}
          onChange={handleChange}
          placeholder={placeholder}
          className={`${classes.root} ${classes.textarea}`}
          disabled={disabled}
        />
      ) : (
        <input
          type={type ? type : 'text'}
          value={value ? value : ''}
          onChange={handleChange}
          placeholder={placeholder}
          className={classes.root}
          disabled={disabled}
        />
      )}
      {hint ? (
        <div className={classes.hint}>
          <Typography variant='caption'>{`入力${value.length} / 最大100`}</Typography>
        </div>
      ) : null}
    </div>
  )
}
