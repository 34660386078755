import request from 'utils/request'
import { MCAXS371SchoolInfoInitRequest } from 'types/MCAXS371/MCAXS371SchoolInfoInitRequest'
import { MCAXS371SchoolGroupRequest } from 'types/MCAXS371/MCAXS371SchoolGroupRequest'
import { MCAXS371StudentClassificationRequest } from 'types/MCAXS371/MCAXS371StudentClassificationRequest'

export const getMCAXS371InitRequest = (interview: MCAXS371SchoolInfoInitRequest) => 
request({
  url: '/MCAXS371/init',
  method: 'post',
  data: interview,
})

export const SchoolGroupRequest = (interview: MCAXS371SchoolGroupRequest) =>
  request({
    url: `MCAXS3x1/SchoolGroupBtnClick`,
    method: 'post',
    data: interview,
  })

export const StudentClassificationRequest = (
  interview: MCAXS371StudentClassificationRequest
) =>
  request({
    url: '/MCAXS3x1/StudentClassificationBtnClick',
    method: 'post',
    data: interview,
  })
