import request from 'utils/request'
import { MCAFS020UpdateDisplayRequest } from 'types/MCAFS020/MCAFS020UpdateDisplayRequest'
import { MCAFS020InitDisplayRequest } from 'types/MCAFS020/MCAFS020InitDisplayRequest'
// 次期9月対応 #63073 start
export const initAlertMailDisplayRequest = (initDisplayRequest: MCAFS020InitDisplayRequest) =>
    request({
        url: `MCAFS020/init`,
        method: 'post',
        data:initDisplayRequest
    })
export const updateAlertMailRequest = (notificationMailInfo: MCAFS020UpdateDisplayRequest) =>
    request({
        url: `MCAFS020/confirm`,
        method: 'post',
        data: notificationMailInfo,
    })
// 次期9月対応 #63073 end