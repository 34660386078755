import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { openModal } from 'reducers/messageReducer'
import { setSearchCondition } from 'reducers/advancedSearchReducer'
import { magiContants as contants } from 'utils/contants'
import { FormControl } from '..'
import { getMcaxs591Init, setStudentDetailsFlag } from 'reducers/advancedSearchModalReducer'
import {
  initRequest,
  DepartmentDetail,
} from '../Config/studentDetailsModalConfig'

import { Option, SchoolModalState } from '../Config/searchConditionConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  modal: {
    '& .MuiDialog-paper': {
      minWidth: '600px',
    },
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  link: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 0,
  },
  checkbox: {
    //display: 'grid',
    gridTemplateColumns: 'repeat(3, [col-start] max-content [col-end])',
    padding: 15,
  },

  button: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: '30%',
  },
  cancel: {
    marginRight: theme.spacing(2.5),
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  formContainer: {
    paddingTop: 0,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  content: {
    float: 'left',
    '& div': {
      display: 'inline-block',
      margin: '0px 19px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  title: string
  form: FormControl
  modalState: SchoolModalState
}

const StudentDetailsModal = ({ open, onClose, title, form, modalState }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { departmentDetailsList } = useSelector(
    (state: RootState) => state.advancedSearchModal.studentDetailsModalInfo
  )

  const departmentDetailsObj = form.values.departmentDetailsObj
  const options : Option[] = null !== departmentDetailsObj && departmentDetailsObj.length > 0 ?
    departmentDetailsObj.map(i => {
      return { label: i.label, value: i.value }
    }) : []
  useEffect(() => {
    if (open) {
      if (modalState.studentClassificationSelected && modalState.studentClassificationSelected.length > 0) {
        initRequest.studentClassificationId = modalState.studentClassificationSelected.map(
          i => i.value
        )
        dispatch(getMcaxs591Init(initRequest))
        if(options){
          setSelectStudentClassification(options)
        }
      } else {
        dispatch(openModal(contants.MESSAGECODE_MCAXS591_001))
        onClose()
      }
    }
  }, [open])

  const searchConditionValues = useSelector(
    (state: RootState) => state.advancedSearch.searchCondition
  )

  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openOkDialog, setOpenOkDialog] = useState(false)

  const [
    selectStudentClassification,
    setSelectStudentClassification,
  ] = useState(modalState.studentDetailsSelected || [])

  useEffect(() => {
    setSelectStudentClassification(form.values.departmentDetailsObj || [])
  }, [form.values.departmentDetailsObj])

  // キャンセル確認ダイアログ表示
  const handleClickDialog = () => {
    setOpenCancelDialog(true)
  }

  // OK確認ダイアログ表示
  const handleOkDialog = () => {
    modalState.studentClassification = true
    setOpenOkDialog(false)
    dispatch(setStudentDetailsFlag(true))
    if(!options){
      setSelectStudentClassification([])
    }
    onClose()
  }

  const handleCancel = () => {
    setOpenCancelDialog(false)
    setOpenOkDialog(false)
  }

  const handleCancelOk = () => {
    setOpenCancelDialog(false)
    onClose()
  }

  const handleStudentDetailsClick = () => {
    if (selectStudentClassification.length > 0) {
      setOpenOkDialog(true)
    } else {
      modalState.studentClassification = true
      dispatch(setStudentDetailsFlag(true))
      onClose()
    }
  }

  // チェックボックス処理
  const handleSelect = (item: DepartmentDetail) => (e: any) => {
    const region: Option = {
      value: item.subjectCode,
      label: item.subjectName,
    }
    const selectStudentClassificationOption = selectStudentClassification.filter(i => i.value === region.value)
    if (selectStudentClassificationOption.length > 0) {
      setSelectStudentClassification(selectStudentClassification.filter(i => i.value !== region.value))
    } else {
      setSelectStudentClassification([...selectStudentClassification, region])
    }
  }

  // OKボタン押下時
  const handleOk = () => {
    //Object.assign({}, filterCondition)
    const newSarchConditionValues: any = Object.assign(
      {},
      searchConditionValues
    )

    // 表示していないチェックの入った項目の削除
    const newStudentDepartmentDetailValues: any[] = []
    const newStudentDepartmentDetailObj: any[] = []
    if (selectStudentClassification.length > 0) {
      selectStudentClassification.map(i =>
        departmentDetailsList
          .filter(j => i.value === j.subjectCode)
          .map(j => {
            newStudentDepartmentDetailValues.push(j)
            newStudentDepartmentDetailObj.push(i)
          })
      )
    }

    const orderObjArray = departmentDetailsList.map(i=>({
      subjectCode: i.subjectCode,
    }))
    const sortedStudentDepartmentDetailObj = [...newStudentDepartmentDetailObj].sort((a, b) => {
      return innerSortFunc(
        {
          subjectCode: a.value,
        },
        {
          subjectCode: b.value,
        },
        orderObjArray,
      )
    })

    newSarchConditionValues.departmentDetailsConditionsObj = newStudentDepartmentDetailValues
    dispatch(setSearchCondition(newSarchConditionValues))
    setOpenOkDialog(false)
    form.setFieldValue('studentClassificationObj', modalState.studentClassificationSelected)
    form.setFieldValue('departmentDetailsObj', sortedStudentDepartmentDetailObj)
    modalState.studentDetailsSelected = sortedStudentDepartmentDetailObj
    onClose()
  }

  return (
    <div>
      <Dialog fullWidth open={open} className={classes.modal + ' mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{title}</label>
        </DialogTitle>
        <DialogContent className={classes.checkbox}>
          {departmentDetailsList
            ? departmentDetailsList.map(item => (
            <div className={classes.content}>
              <div onClick={handleSelect(item)} >
                <input type="checkbox" checked={selectStudentClassification.some(i => i.value === item.subjectCode)} />{item.subjectName}
              </div>
            </div>
            ))
          : null}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleStudentDetailsClick}
            color='primary'
            variant='contained'
            className={classes.button}>
            学生分類を指定する
          </Button>
        </DialogActions>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClickDialog}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleOk}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS591_003}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOkDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS591_002}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleOkDialog} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { StudentDetailsModal }
