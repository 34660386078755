import { initRequest, redistRequest } from 'apis/MCBHS070Api'
import {
  dateTimeToString,
  isEmpty,
  now,
} from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import moment from 'moment-timezone'
import {
  getInit,
  redistMessage,
  setBusinessCheckResult,
  setSubject,
  setBody,
  setCode,
  setInit,
  setSenderMailAddressFix,
} from 'reducers/mcbDeadlineChangeConfirmReducer'
import { openModal } from 'reducers/messageReducer'
import {
  changeConfirmState,
  closeConfirmModal,
} from 'reducers/deadlineChangeRequestReducer'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { magiContants } from 'utils/contants'
moment.tz.setDefault('Asia/Tokyo')

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const initVal = action.payload.initVal
    const serverResponse = yield call(initRequest, initVal)
    yield put(setInit(serverResponse))
    const result = action.payload.setReplace(
      serverResponse.previewDataList,
      initVal.requestSubject,
      serverResponse.changedBody
    )
    yield put(setSubject(result.subject))
    yield put(
      setBody(
        result.body
      )
    )
    // #MCB1.5次開発 #72395 START
    yield put(setSenderMailAddressFix(serverResponse.senderMailAddressFix))
    // #MCB1.5次開発 #72395 END
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* redistMessageSaga(action: ReturnType<typeof redistMessage>) {
  try {
    const formData = action.payload.formData
    const sendTargetConfirmation = action.payload.sendTargetConfirmation
    const forbiddenWords = action.payload.forbiddenWords
    const sendReplyImpossibleTime = action.payload.sendReplyImpossibleTime
    // No.6 送信先チェック
    if (sendTargetConfirmation) {
      if (!sendTargetConfirmation.some(item => !isEmpty(item.mailAddress))) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS070-006')))
        return
      }
    }

    // No.8 不正文字チェック
    if (
      formData.deadlineChangeSubject.indexOf('●●') >= 0 ||
      formData.deadlineChangeText.indexOf('●●') >= 0
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS070-008')))
      return
    }

    // No.9 No.10 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.deadlineChangeSubject.indexOf(item) >= 0) {
          isErrorSubject = true
          return
        }
        if (formData.deadlineChangeText.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS070-009')))
        return
      }
      if (isErrorBody) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCBHS070-010')))
        return
      }
    }

    // No.12 提出締切チェック 日時指定   No.13 提出締切チェック 随時受付終了
    // 提出締切チェック
    const curDate = dateTimeToString(now())
    switch (formData.limitDateDetermineFlag) {
      case 0:
        if (formData.dateTimeDetermineDate !== null && curDate !== null) {
          if (formData.dateTimeDetermineDate.substring(0,16) < curDate.substring(0,16)) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS070-012')))
            return
          }
        }
        break
      // MCBリプレイス #8630 START
      case 1:
        if (formData.requestDayTimeLaterDay !== null &&formData.requestDayTimeToTime!== null &&curDate !== null) {
          if (formData.requestDayTimeLaterDay==0) {
            if(Number(formData.requestDayTimeToTime+'00')<Number(curDate.substring(11,16).replace(':',''))){
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCBHS070-012')))
              return
              }
           }
        }
        break
      // MCBリプレイス #8630 END
      case 2:
        if (formData.anytimeReceptionEndDate !== null && curDate !== null) {
          if (formData.anytimeReceptionEndDate.substring(0,16) < curDate.substring(0,16)) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCBHS070-013')))
            return
          }
        }
        break
      default:
        break
    }

    // No.14 提出締切チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let deadlineChangeTime = ''
      const leftPadZero = (target:number | null)=>{
        return (target != null && target < 10)  ? '0' + target : String(target)
      }
      const calRequestDayTime = (
        laterDay: number | null,
        toTime: number | null,
      ) => {
        let baseMoment = moment()
        return (
          baseMoment.add(laterDay, 'day').format('YYYY/MM/DD') +
          ' ' +
          leftPadZero(toTime) +
          ':00'
        )
      }
      if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_0) {
        deadlineChangeTime = String(formData.dateTimeDetermineDate)
      } else if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_1) {
        deadlineChangeTime = calRequestDayTime(
          formData.requestDayTimeLaterDay,
          formData.requestDayTimeToTime,
        )
      } else if (String(formData.limitDateDetermineFlag) === magiContants.LIMIT_DATE_DETERMINE_FLAG_2) {
        deadlineChangeTime = String(formData.anytimeReceptionEndDate)
      }
      if (!isEmpty(deadlineChangeTime)) {
        let determineDateTime = moment(
          deadlineChangeTime
            ? deadlineChangeTime.replace(/\//g, '-')
            : deadlineChangeTime
        )
        sendReplyImpossibleTime.map(item => {
          let startDate
          let endDate
          let startDateStr = !isEmpty(item.startDateStr)
            ? item.startDateStr.trim()
            : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(
              `${startDateStr.substring(0, 4)}-${startDateStr.substring(
                4,
                6
              )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
                8,
                10
              )}:${startDateStr.substring(10, 12)}`
            )
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(
              `${endDateStr.substring(0, 4)}-${endDateStr.substring(
                4,
                6
              )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
                8,
                10
              )}:${endDateStr.substring(10, 12)}`
            )
          }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '2'? true : false
          if (startDate && endDate && functionalDivision) {
            if (
              (determineDateTime.isAfter(startDate) ||
                determineDateTime.diff(startDate, 'minute') === 0) &&
              (determineDateTime.isBefore(endDate) ||
                determineDateTime.diff(endDate, 'minute') === 0)
            ) {
              errorPeriod = `${startDateStr.substring(
                0,
                4
              )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
                6,
                8
              )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
                10,
                12
              )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
                4,
                6
              )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
                8,
                10
              )}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate && functionalDivision) {
            if (
              determineDateTime.isAfter(startDate) ||
              determineDateTime.diff(startDate, 'minute') === 0
            ) {
              errorPeriod = `${startDateStr.substring(
                0,
                4
              )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
                6,
                8
              )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
                10,
                12
              )}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate && functionalDivision) {
            // #MCB1.5次開発 #8986 END
            if (
              determineDateTime.isBefore(endDate) ||
              determineDateTime.diff(endDate, 'minute') === 0
            ) {
              errorPeriod = `～${endDateStr.substring(
                0,
                4
              )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
                6,
                8
              )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
        })
        if (isError) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessageEx('MCBHS070-014', errorPeriod)))
          return
        }
      }
    }

    // // No.15 再提出の受付可否チェック
    if (
      formData.limitDateDetermineFlag === 2 &&
      formData.reRequestReceptFlag === 1
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCBHS070-015')))
      return
    }

    //No.17 メンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr)
          ? item.startDateStr.trim()
          : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(
            `${startDateStr.substring(0, 4)}-${startDateStr.substring(
              4,
              6
            )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
              8,
              10
            )}:${startDateStr.substring(10, 12)}`
          )
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(
            `${endDateStr.substring(0, 4)}-${endDateStr.substring(
              4,
              6
            )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
          )
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if (
            (sendTime.isAfter(startDate) ||
              sendTime.diff(startDate, 'minute') === 0) &&
            (sendTime.isBefore(endDate) ||
              sendTime.diff(endDate, 'minute') === 0)
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
              4,
              6
            )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (
            sendTime.isAfter(startDate) ||
            sendTime.diff(startDate, 'minute') === 0
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          // #MCB1.5次開発 #8986 END
          if (
            sendTime.isBefore(endDate) ||
            sendTime.diff(endDate, 'minute') === 0
          ) {
            errorPeriod = `～${endDateStr.substring(
              0,
              4
            )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
              6,
              8
            )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessageEx('MCBHS070-017', errorPeriod)))
        return
      }
    }

    yield call(redistRequest, formData)
    yield put(setCode(200))
    yield put(setBusinessCheckResult(true))
  } catch (error) {
    yield put(changeConfirmState(false))
    yield put(closeConfirmModal())
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export default function* mcbDeadlineChangeConfirmSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(redistMessage, redistMessageSaga),
  ])
}
