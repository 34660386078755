import React from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Option } from './Config/searchConditionConfig'
import ReactSelect from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'
import { magiContants } from 'utils/contants'

const customStyles = {
    input: () => ({
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
    }),
    menu: (provided: any) => ({ ...provided, zIndex:1000}),
  }

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  textarea: {
    resize: 'none',
  },
}))

interface Props extends FieldProps {
  isMulti?: boolean
  hint?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  multiline?: boolean
  type?: string
  option: Option
  noOptionsMessage: string
}

export default ({
  isMulti,
  className,
  placeholder,
  field,
  form,
  hint,
  disabled,
  option,
  noOptionsMessage = magiContants.SUGGEST_NO_OPTIONS_MESSAGE,
}: Props) => {
  const classes = useStyles()
  const { name, value } = field

  const [val, setVal] = React.useState({ label: '　', value: '' })

  const handleChange = (e: any) => {
      if (!(hint && e.value.length > 100)) {
        form.setFieldValue(name, e.value)
        form.setFieldValue(`${name.split('.')[0]}.physicalName`, option.value)
        form.setFieldValue(`${name.split('.')[0]}.logicalName`, option.label)
        if (e.value === '') {
            setVal({ label: '　', value: '' })
        } else {
            setVal({ label: e.label, value: e.value })
        }
    }
  }

  React.useEffect(() => {
    if (value === '') {
        setVal({ label: '　', value: '' })
    } else {
        setVal({ label: value, value: value })
    }
  }, [value])

  const optionList = [
    { label: '　', value: '' },
    { label: '日本語', value: '日本語' },
    { label: '英語', value: '英語' },
  ]

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={val}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      filterOption={customSelectOptions.filterOption}
      noOptionsMessage={() => noOptionsMessage}
    />
  )
}
