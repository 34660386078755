import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import {
  search,
  setSearchResult,
  setInitDisplay,
  updateOnNewData,
  updateDecision,
  updateJudgementResult,
  updateSelectionSelect,
  updateProgressFilter,
  updateTagFilter,
  setJudgementStatusList,
  initSetDate,
  setInitInfo,
  setting,
  getMySearch,
  setMySearchResult,
  setSearchCondition,
  setSelectedSearch,
  setOpenFlag,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  updateFilterDisplay,
  clearFilter,
  setNeedUpdate,
  setDisplayLimit,
  setSpecificTargetFirstDisplayInfo,
  getContractMediaInfo,
  setCircleLoadingFlag,
  setDisplaySearchCriteriaTitleOpenFlag,
  setSelectedAccounts,
  setMultiMySearchLoadingInfo,
  setMultiMySearchState,
} from 'reducers/applicantListReducer'
import {
  setMcaxs351Init,
  setOpen as setAdvancedSearchOpen,
  setCurrentTab,
  submissionRequestInfo as initSubmissionRequestInfo,
} from 'reducers/advancedSearchReducer'
import {
  applicantInfoInitRequest,
  schoolInfoInitRequest,
  recruitmentManagementInit,
} from 'componentsHsc/AdvancedSearch/Config/formConfig'
import {
  searchRequest,
  updateJudgmentRequest,
  getMySearchRequest,
  getContractMediaInfoRequest,
  getMultiMySearchResultRequest,
} from 'apis/MCAXS010Api'
import { initSetDateSagaRequest, settingRequest } from 'apis/MCAXS030Api'
import { openModal } from 'reducers/messageReducer'

import {
  initFilterCondition,
  initMultiMySearchState,
  initSearchResult,
  MCAXS010SearchAcynchronousResult,
  MCAXS010PushCallSearchConditionResult,
  MultiMySearchState,
  MySearch,
} from 'pages/MCAXS010/formConfig'
import {
  initSearchCondition,
  SearchCondition,
  SelectionFlowObj,
  SelectionStepObj,
} from 'pages/MCAXS010/searchConditionConfig'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { RootState } from 'reducers'
import { deepEqual } from 'utils/misc'
import { getMCAXS361InitRequest } from 'apis/MCAXS361Api'
import { getMCAXS371InitRequest } from 'apis/MCAXS371Api'
import { getMCAXS381InitRequest } from 'apis/MCAXS381Api'
import { getMCAXS391InitRequest } from 'apis/MCAXS391Api'
import { ApplicantInfo } from 'componentsHsc/AdvancedSearch/Config/applicantConfig'
import { RecruitmentManagementInfo } from 'componentsHsc/AdvancedSearch/Config/recruitmentManagementConfig'
import { fromEntryList, toEntryList } from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { getMCAXS701InitRequest } from 'apis/MCAXS701Api'
import { MCAXS010SearchAcynchronousRequest } from 'types/MCAXS010/MCAXS010SearchAcynchronousRequest'
import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'

function* initSetDateSaga() {
  try {
    const data = yield call(initSetDateSagaRequest)
    yield put(setInitInfo(data))
    yield put(setOpenFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* settingSaga(action: ReturnType<typeof setting>) {
  try {
    yield call(settingRequest, action.payload.request)
    if (action.payload.onSubmit) {
      action.payload.onSubmit()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

const updateSearchCondition = (
  searchCondition: string,
  mCAXS361Result: ApplicantInfo,
  mCAXS391Result: RecruitmentManagementInfo
) => {
  const sc: SearchCondition = JSON.parse(searchCondition)
  const newSearchCondition = {
    ...sc,
  }
  if (sc.applicationRouteObj) {
    //応募経路情報
    newSearchCondition.applicationRouteObj = sc.applicationRouteObj.map(
      scApplicationRoute => {
        const applicationRoute = mCAXS361Result.applicationRouteList.find(
          resultApplicationRoute =>
            resultApplicationRoute.applicationRouteId ===
            Number(scApplicationRoute.applicationRouteId)
        )
        if (applicationRoute) {
          return {
            applicationRouteFlag: scApplicationRoute.applicationRouteFlag,
            applicationRouteId: scApplicationRoute.applicationRouteId,
            applicationRouteName: applicationRoute.applicationRouteName,
          }
        } else {
          return { ...scApplicationRoute }
        }
      }
    )
  }

  if (sc.selectionFlowObj) {
    //選考フロー
    const selectionFlowObj: SelectionFlowObj[] = []
    sc.selectionFlowObj.forEach(
      scSelectionFlow => {
        const selectionFlow = mCAXS391Result.selectionFlowList.find(
          resultSelectionFlow =>
            resultSelectionFlow.selectionFlowSettingId ===
            Number(scSelectionFlow.selectionFlowId)
        )
        if (selectionFlow) {
          selectionFlowObj.push({
            selectionFlowId: scSelectionFlow.selectionFlowId,
            selectionFlowName: selectionFlow.selectionName,
          })
        }
      }
    )
    newSearchCondition.selectionFlowObj = selectionFlowObj
  }

  if (sc.selectionStepObj) {
    //選考ステップ
    const selectionStepObj: SelectionStepObj[] = []
    sc.selectionStepObj.forEach(
      scSelectionStep => {
        const selectionStep = mCAXS391Result.selectionStepList.find(
          resultSelectionStep =>
            resultSelectionStep.progressStatusSettingId ===
            Number(scSelectionStep.selectionStepId)
        )
        if (selectionStep) {
          selectionStepObj.push({
            selectionStepId: scSelectionStep.selectionStepId,
            selectionStepName: selectionStep.progressName,
          })
        }
      }
    )
    newSearchCondition.selectionStepObj = selectionStepObj
  }

  return newSearchCondition
}

export function* searchSaga(action: ReturnType<typeof search>) {
  try {
    let data = initSearchResult
    let request = action.payload.request
    if (action.payload.request.searchCount === 1) {
      yield put(setCircleLoadingFlag(true))
      const recruitmentManagementDivision = yield select(
        getRecruitmentManagementDivision
      )
      const globalMenu = yield select((state: RootState) => state.globalMenu)

      const multiMySearch = action.payload.multiMySearch
      if (
        multiMySearch &&
        multiMySearch.mySearchCondition !== null &&
        ['MCAXS351', 'MCARS020'].includes(request.sourceFunctionId)
      ) {
        const searchedSelectionManagementId: string[] = yield call(
          getMultiMySearchResultSaga,
          {
            searchRequest: request,
            multiMySearch: multiMySearch,
          }
        )

        request = {
          ...request,
          mySearchCondition: multiMySearch.mySearchCondition,
          searchedSelectionManagementId: searchedSelectionManagementId,
        }
      } else {
        yield put(setMultiMySearchState(initMultiMySearchState))
      }

      const result = yield all([
        call(initAreaContactPassRequest, {
          recruitmentManagementDivision: recruitmentManagementDivision,
          lastLogoutTime: globalMenu.lastLogoutTime,
          previousLoginTime: globalMenu.previousLoginTime,
        }),
        call(getMCAXS361InitRequest, applicantInfoInitRequest),
        call(getMCAXS371InitRequest, schoolInfoInitRequest),
        call(getMCAXS381InitRequest),
        call(getMCAXS391InitRequest, recruitmentManagementInit),
        globalMenu.targetYear >= magiContants.MCB_YEAR_2025 ? call(getMCAXS701InitRequest) : undefined,
        call(searchRequest, request),
      ])

      data = result[6]
      yield put(setInitAreaContactPass(result[0]))

      //表示上限プルダウンを設定
      if (action.payload.request.displayLimit === null) {
        const defaultDisplayLimitInfo = data.displayLimitOptionInfo.find(
          i => i.selected === '1'
        )
        if (defaultDisplayLimitInfo) {
          yield put(
            setDisplayLimit({
              label: defaultDisplayLimitInfo.label,
              value: defaultDisplayLimitInfo.value,
            })
          )
        }
      }  

      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:開始 *
       *---------------------------------------*/
      const mCAXS361Result = result[1]
      const mCAXS371Response = result[2]
      const mCAXS381Response = result[3]
      const mCAXS391Result = result[4]
      const mCAXS701Response = result[5]

      if(mCAXS701Response && "MCAXS701-003" == mCAXS701Response.errorCode){
        yield put(openModal("MCAXS701-003"))
      }
      
      const mCAXS371Result = {
        graduationPeriodList: mCAXS371Response.graduationPeriodList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        philosophyHumanitiesList: mCAXS371Response.philosophyHumanitiesList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        schoolTypeList: mCAXS371Response.schoolTypeList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),

        schoolNameList: mCAXS371Response.schoolNameList,
        facultyGraduateNameList: mCAXS371Response.facultyGraduateNameList,
        departmentDepartmentNameList:
          mCAXS371Response.departmentDepartmentNameList,
        countryRegionList: mCAXS371Response.countryRegionList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        schoolGroupObj: mCAXS371Response.schoolGroupObj,
        schoolDetailsObj: mCAXS371Response.schoolDetailsObj,
        undergraduateDepartmentObj: mCAXS371Response.undergraduateDepartmentObj,
        studentClassificationObj: mCAXS371Response.studentClassificationObj,
        departmentDetailsObj: mCAXS371Response.departmentDetailsObj,
      }

      const mCAXS381Result = {
        sexList: mCAXS381Response.sexList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        languageSkillsList: mCAXS381Response.languageSkillsList,
        eligibilityList: mCAXS381Response.eligibilityList,
        memberTypeList: mCAXS381Response.memberTypeList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
      }

      const mCAXS701Result = mCAXS701Response ? {
        deliveryDetailList: mCAXS701Response.deliveryDetailList,
        deliveryStatusList: mCAXS701Response.deliveryStatusList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        deadlineTypeList: mCAXS701Response.deadlineTypeList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        submitStatusList: mCAXS701Response.submitStatusList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        errorCode:mCAXS701Response.errorCode,
      } : initSubmissionRequestInfo

      //ReduxStoreに保持
      yield put(
        setMcaxs351Init({
          mCAXS361Result,
          mCAXS371Result,
          mCAXS381Result,
          mCAXS391Result,
          mCAXS701Result,
        })
      )

      //検索条件詳細情報の更新
      if (data.searchCondition) {
        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          mCAXS361Result,
          mCAXS391Result,
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
        yield put(setSearchCondition(newSearchCondition))
        const { orderObjArray } = yield select(
          (state: RootState) => state.advancedSearch
        )
        const newSortSearchCondition = toEntryList(fromEntryList(newSearchCondition), orderObjArray)
        yield put(
          setSelectedSearch({
            searchCondition: newSortSearchCondition,
            searchConditionDisplay: data.searchConditionDisplay,
          })
        )

        const payloadMySearch = action.payload.mySearch
        if (payloadMySearch) {
          const mySearch = {
            entrySearchCriteriaSettingId:
              payloadMySearch.entrySearchCriteriaSettingId,
            searchCriteriaName: payloadMySearch.searchCriteriaName,
            searchCriteria: JSON.stringify(newSearchCondition),
            searchCriteriaDisplay: data.searchConditionDisplay,
          }
          yield put(setMySearchResult(mySearch))
        }
      }
      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:終了 *
       *---------------------------------------*/
    } else {
      yield put(setCircleLoadingFlag(false))
      yield put(
        setMultiMySearchLoadingInfo({
          open: false,
          numerator: 0,
          denominator: 0,
        })
      )
      data = yield call(searchRequest, action.payload.request)
      if (data.searchCondition) {
        const { applicantInfo, recruitmentManagementInfo } = yield select(
          (state: RootState) => state.advancedSearch
        )

        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          applicantInfo,
          recruitmentManagementInfo
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
      }
    }

    const totalCount = yield select((state: RootState) => 
      state.applicantList.searchResult.totalCount
    )
    data.flowMasterInfo = data.flowMasterInfo ? data.flowMasterInfo : []
    data.tagMasterInfo = data.tagMasterInfo ? data.tagMasterInfo : []
    data.applicantListItemInfo = data.applicantListItemInfo
      ? data.applicantListItemInfo
      : []
    data.mediaMasterInfo = data.mediaMasterInfo ? data.mediaMasterInfo : []
    data.applicantsListInfo = data.applicantsListInfo
      ? data.applicantsListInfo
      : []
    data.applicantListMediaInfo = data.applicantListMediaInfo
      ? data.applicantListMediaInfo
      : []
    data.applicantListTagsInfo = data.applicantListTagsInfo
      ? data.applicantListTagsInfo
      : []
    
    const totalCountFixed = data.totalCount ? data.totalCount : 0
    const { selectionManagementIdList } = action.payload.request
    if (action.payload.request.searchCount === 1) {
      if (
        selectionManagementIdList &&
        selectionManagementIdList.length
      ) {
        data.totalCount = selectionManagementIdList.length
        yield put(setSpecificTargetFirstDisplayInfo(data.displayInfo))
      } else {
        data.totalCount = totalCountFixed
      }
      yield put(setTotalCount(totalCountFixed))
    } else {
      if (selectionManagementIdList && selectionManagementIdList.length) {
        yield put(setTotalCount(totalCountFixed))
      }
      data.totalCount = totalCount
    }

    if (action.payload.request.searchCount === 1) {
      const isManagementOffice: boolean = data.isManagementOffice
      if (isManagementOffice) {
        if (action.payload.request.sourceFunctionId === magiContants.MCAAS010_SCREENID) {
          yield put(setAdvancedSearchOpen(true))
          yield put(setCurrentTab(2))
        } else if (action.payload.request.sourceFunctionId === magiContants.MCAXS010_SCREENID) {
          yield put(setCurrentTab(2))
        }
      }
      yield put(setNeedUpdate(false))
      yield put(updateFilterDisplay(data))
      
      if(action.payload.isInitFilter){
        yield put(clearFilter())
      }
      const filterCondition = yield select(
        (state: RootState) => state.applicantList.filterCondition
      )

      // 1回目レスポンス到着、選考フィルターなし、レスポンスを画面表示に反映
      if (
        deepEqual(initFilterCondition, filterCondition) ||
        totalCountFixed === data.displayInfo.length
      ) {
        yield put(setSearchResult(data))
        yield put(setInitDisplay({ isInitFilter: action.payload.isInitFilter, searchResult: data}))
      }
      if (data.errorCode) {
        yield put(openModal(getMessage(data.errorCode)))
      }
      
      if (action.payload.request.sourceFunctionId === magiContants.MCBHS180_SCREENID){
        yield put(setAdvancedSearchOpen(true))
        if (isManagementOffice) {
          yield put(setCurrentTab(2))
        } else {
          yield put(setCurrentTab(0))
        }
        const targetSelectionManagementId = data.displayInfo
          .filter(i => i.specificTargetDataGetFlag === '0')
          .map(i => i.experience)
          .reduce(
            (result, current) => [
              ...result,
              ...current.map(i => i.selectionManagementId),
            ],
            [] as string[]
          )
        yield put(setSelectedAccounts(targetSelectionManagementId))
      }
    } else {
      const filterCondition = yield select(
        (state: RootState) => state.applicantList.filterCondition
      )

      yield put(setSearchResult(data))
      const needUpdate = yield select((state: RootState) =>
        state.applicantList.needUpdate
      )

      yield put(setSearchCount(1))
      yield put(setHasNewData(true))
      
      // 2回目レスポンス到着、選考フィルターあり、レスポンスを画面表示に反映
      if (needUpdate || !deepEqual(initFilterCondition, filterCondition)) {
        yield put(updateOnNewData(data.displayInfo))

        const { 
          selectionFlowSelect, 
          progressStatusSelect , 
          tagSelect
        } = filterCondition

        if (selectionFlowSelect.value) {
          yield put(updateSelectionSelect(selectionFlowSelect))
        }

        if (progressStatusSelect.value) {
          yield put(updateProgressFilter(progressStatusSelect))
        }

        if (tagSelect.length) {
          yield put(updateTagFilter(tagSelect))
        }
      }

      if (action.payload.request.sourceFunctionId === magiContants.MCBHS180_SCREENID){
        yield put(updateOnNewData(data.displayInfo))
        yield put(setAdvancedSearchOpen(true))
        yield put(setCurrentTab(0))
        const targetSelectionManagementId = data.displayInfo
          .filter(i => i.specificTargetDataGetFlag === '0')
          .map(i => i.experience)
          .reduce(
            (result, current) => [
              ...result,
              ...current.map(i => i.selectionManagementId),
            ],
            [] as string[]
          )
        yield put(setSelectedAccounts(targetSelectionManagementId))
      }
    }

    if (action.payload.onSearch) {
      action.payload.onSearch()
    }

    /**
     * レスポンスの totalCount とレスポンスの applicantsListInfo の
     * リストの長さが異なる場合
     *
     * ２回目の検索を実施する
     */
    if (
      action.payload.request.searchCount === 1 &&
      totalCountFixed !== data.displayInfo.length
    ) {
      const filterCondition = yield select((state: RootState) => 
        state.applicantList.filterCondition
      )

      if (action.payload.request.sourceFunctionId === magiContants.MCBHS180_SCREENID){
        yield put(setLoadingFlag(true))
      } else {
        if (deepEqual(initFilterCondition, filterCondition)) {
        // フィルターあり、再検索(501件以上)の場合は2回目のレスポンスが来るまで全体(選考フィルター、件数、一覧表示)を"読み込み中"表示
          yield put(setLoadingFlag(false))
        } else {
          yield put(setLoadingFlag(true))
        }
      }

      yield put(setSearchCount(2))
      yield put(
        search({
          request: {
            ...request,
            searchCount: 2,
          },
          isInitFilter: action.payload.isInitFilter
        })
      )
    } else {
      yield put(setLoadingFlag(false))
    }
    yield put(setCircleLoadingFlag(false))
    yield put(
      setMultiMySearchLoadingInfo({
        open: false,
        numerator: 0,
        denominator: 0,
      })
    )
  } catch (error) {
    yield put(setCircleLoadingFlag(false))
    yield put(
      setMultiMySearchLoadingInfo({
        open: false,
        numerator: 0,
        denominator: 0,
      })
    )
    yield put(openModal(error.message))
  }
}

export function* updateDecisionSaga(action: ReturnType<typeof updateDecision>) {
  try {
    const data = yield call(updateJudgmentRequest, action.payload)

    yield put(updateJudgementResult(data))
    yield put(
      setJudgementStatusList({
        selectionManagementId: action.payload.selectionManagementId,
        judgementStatus: action.payload.decisionDivision,
        show: true,
      })
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* getMySearchSaga(action: ReturnType<typeof getMySearch>) {
  try {
    const { pushCallSearchConditionInfo }: MCAXS010PushCallSearchConditionResult = yield call(
      getMySearchRequest,
      action.payload.mySearchRequest
    )

    if (pushCallSearchConditionInfo.length >= 2 && action.payload.mySearchCondition !== null) {
      yield put(
        search({
          request: {
            ...action.payload.searchRequest,
            searchCondition: JSON.stringify(initSearchCondition),
            sourceFunctionId: 'MCARS020',
            searchCount: 1,
          },
          isInitFilter: true,
          multiMySearch: {
            mySearchCondition: action.payload.mySearchCondition,
            multiMySearchInfo: pushCallSearchConditionInfo,
            searchedSelectionManagementId: [],
          },
        })
      )
    } else {
      const getMySearchResult: MySearch = pushCallSearchConditionInfo[0]
      const searchCriteria: SearchCondition = JSON.parse(
        getMySearchResult.searchCriteria
      )
      const { searchResult } = yield select(
        (state: RootState) => state.applicantList
      )
      if (searchResult.isManagementOffice) {
        if (
          !searchCriteria.entryPresenceObj &&
          !searchCriteria.entryDateObj &&
          !searchCriteria.seminarReceptionObj &&
          !searchCriteria.eventsObj
        ) {
          throw new Error(magiContants.MESSAGECODE_MCAXS351_004)
        }
      }

      yield put(setSearchCondition(searchCriteria))
      const id = action.payload.mySearchRequest.entrySearchCriteriaSettingId[0]

      // 再検索
      yield put(setAdvancedSearchOpen(false))
      yield put(setDisplaySearchCriteriaTitleOpenFlag(false))
      yield put(
        search({
          request: {
            ...action.payload.searchRequest,
            searchCondition: getMySearchResult.searchCriteria,
            searchCount: 1,
            entrySearchCriteriaSettingId: id,
            mySearchCondition: null,
            searchedSelectionManagementId: []
          },
          isInitFilter: true,
          mySearch: getMySearchResult,
        })
      )
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* getContractMediaInfoSaga(
  action: ReturnType<typeof getContractMediaInfo>
) {
  try {
    const data = yield call(
      getContractMediaInfoRequest,
      action.payload.request
    )

    action.payload.onGetContractMediaInfo(
      data.isMultipleContractMedia,
      data.contractMediaInfoList
    )

  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getMultiMySearchResultSaga(payload: {
  searchRequest: MCAXS010SearchRequest
  multiMySearch: MultiMySearchState
}) {
  const { sourceFunctionId, listId, searchCondition } = payload.searchRequest
  const { mySearchCondition, multiMySearchInfo } = payload.multiMySearch

  const { searchResult } = yield select(
    (state: RootState) => state.applicantList
  )

  if (searchResult.isManagementOffice) {
    for (const { searchCriteria } of multiMySearchInfo) {
      const parsedSearchCriteria: SearchCondition = JSON.parse(searchCriteria)
      if (
        !parsedSearchCriteria.entryPresenceObj &&
        !parsedSearchCriteria.entryDateObj &&
        !parsedSearchCriteria.seminarReceptionObj &&
        !parsedSearchCriteria.eventsObj
      ) {
        throw new Error(magiContants.MESSAGECODE_MCAXS351_004)
      }
    }
  }
  yield put(setAdvancedSearchOpen(false))
  yield put(setDisplaySearchCriteriaTitleOpenFlag(false))
  yield put(setCircleLoadingFlag(true))

  const requestInfo: MCAXS010SearchAcynchronousRequest = {
    listId: listId,
    count: 0,
    searchCondition: '',
    mySearchCondition: mySearchCondition,
    searchedSelectionManagementId: [],
  }

  const denominator =
    sourceFunctionId === 'MCAXS351'
      ? multiMySearchInfo.length + 1
      : multiMySearchInfo.length

  let count = 0
  for (const item of multiMySearchInfo) {
    yield put(
      setMultiMySearchLoadingInfo({
        open: true,
        numerator: count,
        denominator: denominator,
      })
    )
    requestInfo.searchCondition = item.searchCriteria

    if (
      !(
        count >= 1 &&
        requestInfo.mySearchCondition === 0 &&
        requestInfo.searchedSelectionManagementId.length === 0
      )
    ) {
      const {
        searchedSelectionManagementId,
      }: MCAXS010SearchAcynchronousResult = yield call(
        getMultiMySearchResultRequest,
        requestInfo
      )
      requestInfo.searchedSelectionManagementId = searchedSelectionManagementId
    }
    count++
    requestInfo.count = count
  }

  if (sourceFunctionId === 'MCAXS351') {
    yield put(
      setMultiMySearchLoadingInfo({
        open: true,
        numerator: count,
        denominator: denominator,
      })
    )
    requestInfo.searchCondition = searchCondition

    if (
      !(
        requestInfo.mySearchCondition === 0 &&
        requestInfo.searchedSelectionManagementId.length === 0
      )
    ) {
      const {
        searchedSelectionManagementId,
      }: MCAXS010SearchAcynchronousResult = yield call(
        getMultiMySearchResultRequest,
        requestInfo
      )
  
      requestInfo.searchedSelectionManagementId = searchedSelectionManagementId
    }
    count++
  }

  const searchedSelectionManagementId =
    requestInfo.searchedSelectionManagementId

  yield put(
    setMultiMySearchState({
      mySearchCondition: mySearchCondition,
      multiMySearchInfo: multiMySearchInfo,
      searchedSelectionManagementId: searchedSelectionManagementId,
    })
  )

  yield put(
    setMultiMySearchLoadingInfo({
      open: true,
      numerator: count,
      denominator: denominator,
    })
  )

  return searchedSelectionManagementId
}

export default function* applicantListSaga() {
  yield all([
    takeLatest(search, searchSaga),
    takeEvery(updateDecision, updateDecisionSaga),
    takeLatest(initSetDate, initSetDateSaga),
    takeLatest(setting, settingSaga),
    takeEvery(getMySearch, getMySearchSaga),
    takeEvery(getContractMediaInfo, getContractMediaInfoSaga),
  ])
}
