export interface MCAPS040AutoMergeDetailInfoDto {
    // CSV行番号
    csvRowNumber: string
    // 表示用求職者ID
    jobSeekerIdForDisplay: string
    // 姓
    familyName: string
    // 名
    name: string
    // メールアドレス（PC）
    mailAddressPc: string
    // 選考名
    selectionName: string
}

export interface MCAPS040UploadAutoMergeInfoResultDto {
    // エントリーアップロード履歴ID
    entryUploadHistoryId: string
    // 選考フロー設定ID
    selectionFlowSettingId: string
    // 媒体設定ID
    mediaSettingId:string
    // 媒体名
    mediaRouteName:string
    // 実行日時
    executionTime: string
    // 実行者
    executor: string
    // テンプレート名
    templateName: string
    // アップロードファイル名
    uploadFileName: string
    // アップロード件数
    uploadCount: string
    // 自動名寄せ件数
    autoMergeCount: string
    // 自動名寄せ詳細情報
    autoMergeDetailInfo: MCAPS040AutoMergeDetailInfoDto[]
}

const initUploadAutoMergeInfoResult: MCAPS040UploadAutoMergeInfoResultDto = {
    entryUploadHistoryId: '',
    selectionFlowSettingId: '',
    mediaSettingId:'',
    mediaRouteName:'',
    executionTime: '',
    executor: '',
    templateName: '',
    uploadFileName: '',
    uploadCount: '',
    autoMergeCount: '',
    autoMergeDetailInfo: [],
}

export { initUploadAutoMergeInfoResult }