import { isEmpty } from "common/generalUtil"
import { McbAttachedOption } from "reducers/submissionRequestMessageDetailReducer"

export const dataTitleArea = {
// MCB リプレース対応 #8072 start
// #MCB1.5次開発 #81023 start
  sendReceptionClassification: 'メッセージに差し込み文字を使用している場合は、応募者へは送信時点の内容で送信されます。\r\n※提出リクエスト送信予約中のメッセージを変更したい場合は、メッセージ一覧のMy CareerBoxタブの送信予約トレイの一括操作にて送信をキャンセルください。キャンセル後、下書きトレイにて編集してください。',
// #MCB1.5次開発 #81023 end
// MCB リプレース対応 #8072 end
}

export const initialStateValue = {
  selectMcbMessageDetail: {
    submissionRequestId: 0, // 提出リクエストID
    sendAndReceiveDivision: '', // 送受信区分
    status: '', // ステータス
    convertingBatchCompleteFlag: '', // 変換バッチ完了フラグ
    sendTimeShow: '', // 送信日時
    submissionContentId: 0, // 提出物内容ID
    submissionContentName: '', // 提出物内容名
    submissionContentNameStudent: '', // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag: '', // 提出物エントリシートフラグ
    submissionResumeFlag: '', // 提出物履歴書フラグ
    submissionResearchSummaryFlag: '', // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag: '', // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag: '', // 提出物ファイル提出フラグ
    submissionDeadlineKind: '', // 提出締切_種別
    submissionDeadlineDay: '', //  提出締切_日
    submissionDeadlineHour: 0, // 提出締切_時
    resubmitReceptionFlag: '', // 再提出受付フラグ
    senderCompanyName: '', // 送信元会社名
    senderMailAddress: '', // 送信元メールアドレス
    subject: '', // 件名
    body: '', // 本文
    thanksMailSettingFlag: '', // サンクスメール設定フラグ
    thanksSubject: '', // サンクスメール件名
    thanksBody: '', // サンクスメール本文
    contactId: 0, // お問い合わせ先ID
    contactName: '', // お問い合わせ先名
    contactCompanyName: '', // お問い合わせ先内容企業名
    contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
    contactTelephoneNumber: '', // お問い合わせ先内容電話番号
    contactMailAddress: '', // お問い合わせ先内容メールアドレス
    privacyPolicyId: 0, // 個人情報の取り扱いID
    privacyPolicyName: '', // 個人情報の取り扱い名
    bulkSendFlag: '', // 一括送信フラグ
    dateTimeDetermine: '', // 提出締切
    submissionRequestUrl: '' // 提出リクエストURL
  },
  jobSeekerInfoList: [],
  pageList: [],
  attachedList: [],
  mcbJobSeekerInfoList: [],
  searchCriteriaInfo: {
    searchCriteriaName: '', // 検索条件名
    searchCriteria: '', // 検索条件
  },
  jobSeekersInfoCountFlag: '', // 送受信者情報件数フラグ
  downloadAuthority: false, // ログインユーザのアカウント権限
  // #MCB1.5次開発 #72395 START
  senderAddressFix: '' //  送信元メールアドレス固定値
  // #MCB1.5次開発 #72395 END
}