import request from 'utils/request'
import { MCAXS320InitRequest } from 'types/MCAXS320/MCAXS320InitRequest'
import { MCAXS320CancelRequest } from 'types/MCAXS320/MCAXS320CancelRequest'

export const initRequest = (params: MCAXS320InitRequest) =>
request({
    url: `MCAXS320/init`,
    method: 'post',
    data: params,
})

export const cancleRequest = (params: MCAXS320CancelRequest) =>
request({
    url: `MCAXS320/cancle`,
    method: 'post',
    data: params,
})