import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

export const initValues = {
  managementId: '',
  jobSeekerId:'',
  entryId:'',
  screenRepresentationID:''
}
export const initialNoAdoptionData = {
  nascp:'',//不採用ステータス変更設定
  asrlsrp:'',//書類選考不採用通知送信予約設定
  irlsrp:''//面接不採用通知送信予約設定
}

export const initialManagerFlowStartCommentData = {
  selectionManagementId:0,//選考管理ID
  progressStatusSettingId:0,//選考ステップ設定ID
  nonTargetFlag:'',//対象外フラグ
  initialSelectionManagementId:0,//初期選考管理ID
  decisionDivision:'',//判定区分
  starEvaluation:'',//星評価
  comment:'',//コメント
  updateTime: '',//更新日時(コメント)
  updater:'',//更新者(コメント)
  smSysVersionNumber:0,//sysバージョン番号（選考管理）
  psmSysVersionNumber:0,//sysバージョン番号（選考ステップ管理）
  seSysVersionNumber:0,//sysバージョン番号（星評価）
  // 転職March #75621 START
  failureReasonId:'',//不合格理由ID;
  declineReasonId:'',//辞退理由ID;
  interviewTime:'',//面接日時;
  inputContent:'',//入力内容;
  manipulationTime:'',//操作日時;
  // 転職March #75621 END
}

export const initialPriority = {
  selectionManagementIdArray:[],//選考管理ID
  starEvaluation:'00',//星評価
  sysVersionNumberSeva:0,//sysバージョン番号（星評価）
}

export const initialSelectionFlowInitData = {
  flag : 0,// 旗の位置
  leftSelect : 0,// 左進捗の選択位置
  rightSelect : 0,// 右進捗の選択位置
  leftGrey : false,// 左進捗のグレー表示の標識
  rightGrey : false,// 右進捗のグレー表示の標識
  target : true,// 対象の標識
  leftProgressList : [],// 左進捗の選考ステップ
  rightProgressList : [],// 右進捗の選考ステップ
}

export const initialUpdateJudge = {
  selectionManagementId:0,//選考管理ID
  sysVersionNumberSman:0,//sysバージョン番号（選考管理）
  sysVersionNumberPsma:0,//sysバージョン番号（選考ステップ管理）
  nascp:'',//不採用ステータス変更設定
  passingStatusDivisionId:'',//合否区分ID
  historyInsertDivision:'',//履歴登録区分
  progressName:'',//進捗名
  selectionName:'',//選考名
  progressStatusSettingId:0,//選考ステップ設定ID
}

export const initialDecisionProcessChangeQueryResult = {
  selectMagSelectFlowInfoOutDtoList : [],
  selectMagSelectSelectionInfoOutDto:initialManagerFlowStartCommentData,
  progressStatusSettingId:0,//選考ステップ設定ID(直前)
  passingStatusDivisionId:'',//合否区分ID
  historyInsertDivisionId:'',//履歴登録区分
  progressTypeBefore:'',//進捗種別(変更元)
  displayOrderBefore:0,//表示順(変更元)
  decisionDivisionTypeBefore:'',//判定区分種別(変更元)
  progressStatusSettingIdBefore:0,//選考ステップ設定ID(変更元)
  progressStatusSettingIdAfter:0,//選考ステップ設定ID(変更先)
  progressTypeAfter:'',//進捗種別(変更先)
  displayOrderAfter:0,//表示順(変更先)
  progressNameAfter:'',//進捗名(変更先)
  decisionDivisionTypeAfter:'',//判定区分種別(変更先)
  sysVersionNumberList:[],//バージョン番号と判定区分情報
  selectionFlowInitData:initialSelectionFlowInitData,//選考フロー初期化情報
  decisionDivisionType:'',//判定区分種別(初期表示)
  inValidCheckCount: [],//メッセージ送信エラー情報
  selectionName:'',//選考名
  // 転職March #75621 START
  failureReasonIdCodeList: [],
  declineReasonIdCodeList: [],
  // 転職March #75621 END
}

export const staticSendMessageMap = {
  staticMessageOne: 'ご注意ください！',
  staticMessageTwo: '意図せず「書類選考不合格」メッセージを配信してしまう事象が多発しています。',
  staticMessageThree: 'メッセージの内容をよくご確認のうえ、配信設定を行ってください。',
}

export const sendMessageMap = {
  MCAYS020_004: getMessage(magiContants.MESSAGECODE_MCAYS020_004),
  MCAYS020_005: getMessage(magiContants.MESSAGECODE_MCAYS020_005),
  MCAYS020_006: getMessage(magiContants.MESSAGECODE_MCAYS020_006),
  MCAYS020_007: getMessage(magiContants.MESSAGECODE_MCAYS020_007),
}

export const staticTextMap = {
  staticMessageOne: '選考ステップを',
  staticMessageTwo: 'へ変更しますか？変更後は',
  staticMessageThree: 'に移動されます。',
  buttonTip:'この応募者の情報を関係者にメールでシェアします',
  staticMessageProgress: '進捗を',
  staticMessageFour: 'へ変更しますか？',
}

// #58807 次期開発2022年9月 start
export const textMap = {
  selecting: {
    messageOne: '',
    messageTwo: '選考中一覧',
    messageThree: '全件一覧'
  },
  confrimAfter: {
    messageOne: '',
    messageTwo: '内定者一覧',
    messageThree: '内定者一覧'
  },
  noPass: {
    messageOne: '不合格',
    messageTwo: '選考対象外一覧',
    messageThree: '全件一覧'
  },
  noAdopt: {
    messageOne: '不採用',
    messageTwo: '選考対象外一覧',
    messageThree: '全件一覧'
  },
  dismiss: {
    messageOne: '辞退',
    messageTwo: '選考対象外一覧',
    messageThree: '全件一覧'
  },
  internship: {
    messageOne: 'インターンシップ参加',
    messageTwo: 'インターンシップ参加一覧',
    messageThree: ''
  },
}
// #58807 次期開発2022年9月 end
