import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TableUpdateSexInfoDisplayTarget, TableUpdateSexUpdateRequest, SexUpdateResponse, TableUpdateSexRequest } from "pages/MCBKS010/formConfig"
import { RecruitmentManagementFlag, RecruitmentManagementFlagSysVersion } from "pages/MCBKS020/formConfig"
import { MCBKS030InitRequest, MCBKS030RecruitementManagementItemChangeResponseDto, MCBKS030TableUpdateDisplayResponseDto, TableUpdateRecruitmentManagementFlagDisplayList, UpdateRequest } from "pages/MCBKS030/formConfig"

const initialState: {
  jobSeekerIdList: string[]
  tableUpdateSexInfoDisplayTargetList: TableUpdateSexInfoDisplayTarget[]
  sexUpdateNum: number
  recruitmentManagementFlagList: RecruitmentManagementFlag[]
  recruitmentManagementResponse: MCBKS030TableUpdateDisplayResponseDto
} = {
  jobSeekerIdList: [],
  tableUpdateSexInfoDisplayTargetList: [],
  sexUpdateNum: 0,
  recruitmentManagementFlagList: [],
  recruitmentManagementResponse: {
    recruitmentManagementFlagSetting: [],
    recruitmentManagementFlagSettingKey: [],
    tableUpdateRecruitmentManagementFlagDisplayList: []
  },
}

const tableUpdateSlice = createSlice({
  name: 'tableUpdate',
  initialState,
  reducers: {
    initTableUpdateSexRequest(state, action: PayloadAction<{
      mCBKS010InitRequest: TableUpdateSexRequest
    }>) {
      return state
    },
    setTableUpdateSexInfoDisplayTargetList(state, action: PayloadAction<TableUpdateSexInfoDisplayTarget[]>) {
      state.tableUpdateSexInfoDisplayTargetList = action.payload
      return state
    },
    updateTableUpdateSexRequest(state, action: PayloadAction<{
      request: TableUpdateSexUpdateRequest
      initRequest: () => void
    }>) {
      return state
    },
    initTableUpdateRecruitmentManagementFlagRequest(state) {
      return state
    },
    setTableUpdateRecruitmentManagementFlag(state, action: PayloadAction<RecruitmentManagementFlag[]>) {
      state.recruitmentManagementFlagList = action.payload
      return state
    },
    openMCBKS030Request(state, action: PayloadAction<{
      recruitmentManagementFlagSysVersion: RecruitmentManagementFlagSysVersion[]
      setErrorModalMessage: React.Dispatch<React.SetStateAction<string>>
      setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>
      setOpenMCBKS030: React.Dispatch<React.SetStateAction<boolean>>
      setOpenMCBKS020: React.Dispatch<React.SetStateAction<boolean>>
    }>) {
      return state
    },
    initTableUpdateRecruitmentManagementFlagPreferenceRequest(state, action: PayloadAction<{
      mCBKS030InitRequest: MCBKS030InitRequest
      setOpenMessageModal: React.Dispatch<React.SetStateAction<boolean>>
      setModalMessage: React.Dispatch<React.SetStateAction<string>>
    }>) {
      return state
    },
    setTableUpdateRecruitmentManagementFlagPreference(state, action: PayloadAction<MCBKS030TableUpdateDisplayResponseDto>) {
      state.recruitmentManagementResponse = action.payload
      return state
    },
    updateTableUpdateRecruitmentManagementFlag(state, action: PayloadAction<{
      request: UpdateRequest
      duplicateUpddateModal: (response: MCBKS030RecruitementManagementItemChangeResponseDto) => void
      initRequest: (page: number, isReadState: boolean) => void
      setOpenMessageModal: React.Dispatch<React.SetStateAction<boolean>>
      setModalMessage: React.Dispatch<React.SetStateAction<string>>
    }>) {
      return state
    }
  }
})

export const {
  initTableUpdateSexRequest,
  setTableUpdateSexInfoDisplayTargetList,
  updateTableUpdateSexRequest,
  initTableUpdateRecruitmentManagementFlagRequest,
  setTableUpdateRecruitmentManagementFlag,
  openMCBKS030Request,
  initTableUpdateRecruitmentManagementFlagPreferenceRequest,
  setTableUpdateRecruitmentManagementFlagPreference,
  updateTableUpdateRecruitmentManagementFlag
} = tableUpdateSlice.actions
export default tableUpdateSlice.reducer