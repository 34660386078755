import { getMCBHS171InitRequest } from 'apis/MCBHS171Api'
// import { getMCAXS521InitRequest } from 'apis/MCAXS521Api'
import {
  getMcbhs171Init,
  setMcbhs171Init,
  // getMcaxs521Init,
} from 'reducers/submissionRequestDetailedSearchModalReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { RootState } from 'reducers'
import { MCBHS171SearchRequest } from 'types/MCBHS171/MCBHS171SearchRequest'

// 提出物ファイル指定モーダル情報を取得
function* getMcbhs171InitSaga(action: ReturnType<typeof getMcbhs171Init>) {
  try {
    // 連携元システム区分、連携元企業識別コード、提出物内容IDリストを取得 MCBHS171モーダル画面へ渡る
    const sourceSystemType = '1'
    const globalMenu = yield select((state: RootState) => state.globalMenu)
    const sourceCompanyCode = globalMenu.companyId
    const submissionsContentIdList = yield select((state: RootState) => state.submissionRequestList.fileModalSubmissionId)
    const apiData: MCBHS171SearchRequest = {
      sourceSystemType: sourceSystemType,
      sourceCompanyCode: sourceCompanyCode,
      submissionsContentIdList: submissionsContentIdList,
    }
    const data = yield call(getMCBHS171InitRequest, apiData)
    yield put(setMcbhs171Init(data))
    action.payload()
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 都道府県指定モーダル情報を取得
// function* getMcaxs521InitSaga() {
//   try {
//     yield call(getMCAXS521InitRequest)
//   } catch (error) {
//     yield put(openModal(error.message))
//   }
// }


export default function* submissionRequestDetailedSearchModalSaga() {
  yield all([
    takeLatest(getMcbhs171Init, getMcbhs171InitSaga),
    // takeLatest(getMcaxs521Init, getMcaxs521InitSaga),
  ])
}
