import request from 'utils/request'
import MCAYS020UpdateEvaluationRequest from 'types/MCAYS020/MCAYS020UpdateEvaluationRequest'
import MCAYS020UpdateProgressRequest from 'types/MCAYS020/MCAYS020UpdateProgressRequest'
import MCAYS020UpdateJudgeRequest from 'types/MCAYS020/MCAYS020UpdateJudgeRequest'
import MCAYS020InitConditionRequest from 'types/MCAYS020/MCAYS020InitConditionRequest'
import MCAYS020SendMessageRequest from 'types/MCAYS020/MCAYS020SendMessageRequest'
import { MCAYS020NotAdoptedNotificationPreferenceCheckRequest } from 'types/MCAYS020/MCAYS020NotAdoptedNotificationPreferenceCheckRequest'

export const updateDecideRequest = (decide: MCAYS020UpdateJudgeRequest) =>
  request({
    url: '/MCAYS020/updateDecide',
    method: 'post',
    data: decide,
  })
  
export const processChangeUpdRequest = (progressChangeUpd: MCAYS020UpdateProgressRequest) =>
request({
  url: '/MCAYS020/processChangeUpd',
  method: 'post',
  data: progressChangeUpd,
})

export const processChangeQueryRequest = (initCondition: MCAYS020InitConditionRequest) =>
request({
  url: '/MCAYS020/initialQuery',
  method: 'post',
  data: initCondition,
})

export const sendMessageChkRequest = (sendMessage: MCAYS020SendMessageRequest) =>
request({
  url: '/MCAYS020/sendMessageChk',
  method: 'post',
  data: sendMessage,
})

export const priorityUpdRequest = (priority: MCAYS020UpdateEvaluationRequest) =>
  request({
    url: '/MCAYS020/priorityUpd',
    method: 'post',
    data: priority,
  })
// 次期9月対応 #58576 start 
export const notPassNotificationCheckRequest020 = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAYS020/notPassNotificationCheck/${recruitmentManagementDivision}`,
    method: 'post',
  })
export const notAdoptedNotificationPreferenceCheckRequest020 = (checkParam: MCAYS020NotAdoptedNotificationPreferenceCheckRequest) =>
request({
  url: `/MCAYS020/notAdoptedNotificationPreferenceCheck`,
  method: 'post',
  data: checkParam,
 })
// 次期9月対応 #58576 end

