import { getMessage } from "common/messageUtil";

const accountTypeList = [
  {value: '20', label: '管理者', type: 20},
  {value: '30', label: '準管理者', type: 30},
  {value: '40', label: '一般ユーザ', type: 40},
  {value: '50', label: '制限ユーザ', type: 50},
];
const accountTypeListTwo = [
  {value: '10', label: '統括', type: 10},
];

const initialValues = {
  name: '',
  department: '',
  mailAddress: '',
  tel: '',
  accountType: '',
  recruitmentGroup: [],
  forbidDownload: '0',
  forbidMessageReply: '0',
};
const displayRadioList = [
  {id: 'displayShow', label: '有効', value: 1},
  {id: 'displayHide', label: '無効', value: 0},
];

const initValues = {
  loginId: '',
  name: '',
  department: '',
  mailAddress: '',
  tel: '',
  accountType: '',
  recruitmentGroup: [],
  recruitmentGroupSupervising: '',
  recruitmentGroupStandard: '',
  recruitmentGroupOriginal: [],
  forbidDownload: '',
  forbidMessageReply: '',
  status: '',

};
const editResults = {
  loginId: '',
  name: '',
  department: '',
  mailAddress: '',
  tel: '',
  accountType: '',
  recruitmentGroup: [],
  recruitmentGroupName: '',
  forbidDownload: '',
  forbidMessageReply: '',
  status: 0,
  lastLoginTime: '',
  manualRegistrationFlag: '',
  companyAccountId: 0,
  versionNumberAccount: '',
  versionNumberAccountAuthorization: '',
  // 次期開発12月 #75981 start
  initialDisplayYear: '',
  targetYearList: [],
  // 次期開発12月 #75981 end
  // 転職March #75621 START
  recruitmentGroupSettingId: 0,
  // 転職March #75621 END

};
const editList = {
// 企業アカウントID
  companyAccountId: '',
// 連携元ログインID
  coordinationFormerLoginId: '',
// 手動登録フラグ
  manualRegistrationFlag: '',
  //採用管理区分
  recruitmentManagementDivision: '',
};

const messageMap: { [key: string]: string } = {
  one: getMessage('MCADS040-005'),
  two: getMessage('MCADS040-006'),

};
export {
  initialValues,
  editResults,
  accountTypeList,
  displayRadioList,
  editList,
  initValues,
  accountTypeListTwo,
  messageMap
}