import {magiContants} from "../../utils/contants";
import {getMessage} from "../../common/messageUtil";

export const initialValues = {
  autoRejection: '',
  rejectionLetterSendReservationApplicant: '',
  templateIdApplicant: 0,
  rejectionLetterSendReservationInterview: '',
  templateIdInterview: 0,
  sysVersionNumber: 0,
}
const textMap = {
  submit: {
    submit: '更新する',
    message: getMessage(magiContants.MESSAGECODE_MCAGS010_002),
  },
}
const buttonTextMap: { [key: string]: string } = {
  no: 'キャンセル',
  yes: 'OK',
  submit: '更新する',
  ok: 'OK',
}
const cancelTextMap = {
  cancel: {
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAGS010_009),
  },
}
const cancelButtonTextMap: { [key: string]: string } = {
  no: 'キャンセル',
  yes: 'OK',
}
// 転職March #75621 START
const listener = (ev: {
  preventDefault: () => void
  returnValue: string
}) => {
  ev.preventDefault()
  ev.returnValue = getMessage(magiContants.MESSAGECODE_MCAGS010_010)
}
// 転職March #75621 END
export {
  textMap,
  buttonTextMap,
  cancelTextMap,
  cancelButtonTextMap,
  // 転職March #75621 START
  listener,
  // 転職March #75621 END
}