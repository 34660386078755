import request from 'utils/request'
import { MCAZS070InitRequest } from 'types/MCAZS070/MCAZS070InitRequest'
import { MCAZS070ReplyMessageRequest } from 'types/MCAZS070/MCAZS070ReplyMessageRequest'
import { MCAZS070DownloadFileRequest } from 'types/MCAZS070/MCAZS070DownloadFileRequest'
import { MCAZS070DownloadCsvRequest } from 'types/MCAZS070/MCAZS070DownloadCsvRequest'

export const searchMessageDetailRequest = (apiData: MCAZS070InitRequest) => {
  const params = new URLSearchParams(apiData as {}).toString()

  return request({
    url: '/MCAZS070/init?' + params,
    method: 'post',
    data: apiData,
  })
}

export const memberStatusCheckRequest = (apiData: MCAZS070ReplyMessageRequest) =>
  request({
    url: '/MCAZS070/replyExecute',
    method: 'post',
    data: apiData,
  })

export const attachmentFileDownloadRequest = (apiData: MCAZS070DownloadFileRequest) =>
  request({
    url: '/MCAZS070/AttachmentFileLink',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })

export const destinationDownloadRequest = (apiData: MCAZS070DownloadCsvRequest) =>
  request({
    url: '/MCAZS070/destinationDownloadExecute',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })