import React, {useState, useEffect} from 'react'
import {TextField as MuiTextField, FormControl, PropTypes} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FieldProps} from 'formik'

const useStyles = makeStyles({
  muiText: {
    backgroundColor: '#e0e0e0',
    borderColor: '#cccccc',
  },
  muiTextN: {
    backgroundColor: '#ffffff',
    borderColor: '#c1d1eb;',
  },
  input: {
    '&::placeholder': {
      color: 'darkgray',
    },
    fontSize: '14px',
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
      pointerEvents: 'none',
    },
  },   
})

interface Props extends FieldProps {
  type?: string
  margin?: PropTypes.Margin
  placeholder?: string
  defaultValue?: string
  errorsItemIndex:number
  multiline?: boolean
  disabled?: boolean
  rows?: number
  relatedName? :string
}

const TextFieldForMessageError = ({
                     type = 'text',
                     margin = 'dense',
                     placeholder,
                     field,
                     form,
                     defaultValue,
                     errorsItemIndex,
                     multiline,
                     disabled,
                     rows,
                     relatedName = '',
                   }: Props) => {
  interface ManagementItemError {
    managementItemFlagText: string
    managementItemFlagFrom: string
    managementItemFlagTo: string
    errorsMessage: any
    manageIndex: number
    manageName: string
  }
         
  const classes = useStyles()
  const {errors, touched} = form
  const {name} = field
  const [errorsItemName] = useState(name.substring(name.lastIndexOf('.')+1))
  let errorFlg2 = function () {
    let errorFlg2 = false
    if (touched['conditionList']) {
      if ((touched['conditionList'] as any)[errorsItemIndex]) {
        if ((touched['conditionList'] as any)[errorsItemIndex].managementItemTagText) {
          errorFlg2 = (touched['conditionList'] as any)[errorsItemIndex].managementItemTagText
        }
      }
    }
    return errorFlg2
  }()

  let { errorFlg1, index } = function () {
    let errorFlg1 = false
    let index = -1
    if (errors['conditionList']) {
      let errorsList: any = errors['conditionList']
      errorsList = JSON.parse(JSON.stringify(errorsList))
      index = errorsList.findIndex((item: ManagementItemError) => {
        return errorsItemIndex === item.manageIndex && errorsItemName === item.manageName
      })
      if (index != -1) {
        errorFlg1 = true
      }
    }
    return { errorFlg1, index }
  }()

  useEffect(() => {
    if (disabled) {
      form.setFieldValue(name, '')
    }
  }, [disabled])

  const handleChange = (e: any) => {
    form.setFieldValue(name, e.target.value)
    if (relatedName) {
      if (e.target.value) {
        form.setFieldValue(relatedName, '0')
      } else {
        form.setFieldValue(relatedName, '')
      }
    }
  }

  return (
    <FormControl  error={Boolean(errorFlg1 && errorFlg2)}>
    <MuiTextField
      className={disabled ? classes.muiText : classes.muiTextN}
      variant='outlined'
      margin={margin}
      fullWidth
      multiline={multiline}
      rows={rows}
      defaultValue={defaultValue}
      type={type}
      inputProps={{spellCheck: false}}
      InputProps={{
        classes: {input: classes.input},
      }}
      error={Boolean(errorFlg1 && errorFlg2)}
      helperText={errorFlg1 && errorFlg2 ? (errors['conditionList'] as any)[index].errorsMessage : null}
      placeholder={placeholder}
      {...field}
      onChange={handleChange}
      disabled={disabled}
    />
    </FormControl>
  )
}

export default TextFieldForMessageError
