import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import logo from 'images/miws_on-navy.jpg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}))

const TopBar = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <header>
        <ul></ul>
        <b>
          <img src={logo} />
        </b>
      </header>
    </div>
  )
}

export default TopBar
