import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {initialValues} from "../pages/MCAGS010/formConfig";

interface MessageTemplate {
  messageTemplateSettingId: number,
  templateName: string,
  subject: string,
  body: string,
  sequence: number,
}

export interface NotAdoptedNotification {
  autoRejection: string,
  rejectionLetterSendReservationApplicant: string,
  templateIdApplicant: number,
  rejectionLetterSendReservationInterview: string,
  templateIdInterview: number,
  sysVersionNumber: number,
}

interface NotAdoptedNotificationInfo {
  messageTemplateList: MessageTemplate[],
  notAdoptedNotification: NotAdoptedNotification,
}

const managementList: NotAdoptedNotificationInfo = {
  messageTemplateList: [],
  notAdoptedNotification: initialValues,
};

const notAdoptedNotificationSlice = createSlice({
  name: 'notAdoptedNotification',
  initialState: managementList,
  reducers: {
    mcags010Init(state) {
      return state;
    },
    setNotAdoptedNotification(state, action: PayloadAction<NotAdoptedNotificationInfo>) {
      state = action.payload
      return state;
    },
    updateNotAdoptedNotification(
      state,
      action: PayloadAction<{ data: any; message: string }>
    ) {
        return state
    },
  },
})

const { actions, reducer } = notAdoptedNotificationSlice

export const {
  mcags010Init,
  setNotAdoptedNotification,
  updateNotAdoptedNotification,
} = actions

export default reducer
