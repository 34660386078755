import {
  init,
  setInit,
  setCode,
  setDoInit,
  setScreenDisplayItems,
  doSelectionFlowAdd,
  doSelectionFlowChange,
  doSelectionFlowRouteAdd,
  doSelectionFlowRouteChange,
  doSelectionFlowExclude,
  doSelectionFlowUnExclude,
  doWebTestResult,
  setCurrentApplicantsListInfo,
  setApplicantDetailsRequest,
  setShow,
  setOutResult,
  mcbSubmissionDownload,
  pushMcbCheckDetails,
  mcbSubmissionMesseageMake,
  mcbSubmissionRequestChange,
} from 'reducers/applicantDetailForEmplymentReducer'
import {
  openModal,
  openModalWindowClose,
  openModalWithArg,
} from 'reducers/messageReducer'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  initRequest,
  selectionFlowAddRequest,
  selectionFlowChangeRequest,
  selectionFlowRouteAddRequest,
  selectionFlowRouteChangeRequest,
  selectionFlowExcludeRequest,
  selectionFlowUnExcludeRequest,
  pdfDownloadAuthCheckRequest,
  pushMcbCheckDetailsRequest,
  checkMcbServiceStatus,
} from '../apis/MCAXS040Api'
import { mcaxs230OutputDto } from 'pages/MCAXS230/formConfig'
import { setMCAXS230InitResult } from 'reducers/jobSeekerInfoReducer'
import { magiContants } from 'utils/contants'
import { loginSuccess } from 'reducers/companyLoginReducer'
import { setTargetYear, setTargetYearForDisplay } from 'reducers/globalMenuReducer'
import { routeList } from 'routes/routes'
import { mcbContents, mcbErrorContents } from 'pages/MCAXS040/viewConfig'
import { getMessage } from 'common/messageUtil'
import axios from 'axios'
import { popupParams } from 'utils/misc'
import { getToken } from 'utils/auth'
import { MCAXS040PdfDownloadAuthCheckRequest } from 'types/MCAXS040/MCAXS040PdfDownloadAuthCheckRequest'

export function* initSaga(action: ReturnType<typeof init>) {
  const applicantDetailsRequest = action.payload.request
  const currentApplicantsListInfo = action.payload.currentApplicantsListInfo
  try {
    console.log('initSaga処理開始')
    // 年度対応 start
    const serverResponse =
      yield call(initRequest, action.payload.searchLinkFlg ? { ...applicantDetailsRequest, searchLinkFlg: action.payload.searchLinkFlg } : applicantDetailsRequest)
    // 直リンク かつ 年度切替必要の場合
    if (action.payload.searchLinkFlg && serverResponse.token) {
      const token = serverResponse.tokenHead + ' ' + serverResponse.token
      // token再設定
      yield put(loginSuccess({ token: token }))
      yield put(setTargetYear(serverResponse.targetYear))
      yield put(setTargetYearForDisplay(serverResponse.targetYear))
    }
    // 年度対応 end
    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())
    yield put(setShow(true))
    
    console.log('initSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    if (error.message === magiContants.MESSAGECODE_MCAXS040_005) {
      let newCurrentApplicantsListInfo = {
        applicantsListInfo: [ ...currentApplicantsListInfo.applicantsListInfo ],
        listIndex: currentApplicantsListInfo.listIndex
      }
      // 表示権限の無い対象をリストから削除
      newCurrentApplicantsListInfo.applicantsListInfo.splice(
        newCurrentApplicantsListInfo.listIndex,
        1
      )
      if (newCurrentApplicantsListInfo.applicantsListInfo.length > 0) {
        // listIndexを調整
        let direction = applicantDetailsRequest.directionId
        if (
          (direction === '1' && newCurrentApplicantsListInfo.listIndex > 0) ||
          (direction === '0' &&
            newCurrentApplicantsListInfo.listIndex ===
              newCurrentApplicantsListInfo.applicantsListInfo.length)
        ) {
          newCurrentApplicantsListInfo.listIndex--
        }
        yield put(setCurrentApplicantsListInfo(newCurrentApplicantsListInfo))
        let newApplicantDetailsRequest = { ...applicantDetailsRequest }
        newApplicantDetailsRequest.applicantsListInfo = [
          newCurrentApplicantsListInfo.applicantsListInfo[
            newCurrentApplicantsListInfo.listIndex
          ],
        ]
        yield put(setApplicantDetailsRequest(newApplicantDetailsRequest))
        // リトライ
        yield put(
          init({
            request: newApplicantDetailsRequest,
            currentApplicantsListInfo: newCurrentApplicantsListInfo,
            // 年度対応 start
            searchLinkFlg: action.payload.searchLinkFlg,
            // 年度対応 end
          })
        )
      } else {
        // エラー
        yield put(openModalWindowClose(error.message))
      }
    } else {
      yield put(openModal(error.message))
    }
  }
}

export function* doSelectionFlowAddSaga(
  action: ReturnType<typeof doSelectionFlowAdd>
) {
  try {
    const serverResponse = yield call(selectionFlowAddRequest, action.payload)
    yield put(setCode(serverResponse))

    console.log('doSelectionFlowAddSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowChangeSaga(
  action: ReturnType<typeof doSelectionFlowChange>
) {
  try {
    const serverResponse = yield call(
      selectionFlowChangeRequest,
      action.payload
    )
    yield put(setCode(serverResponse))

    console.log('doSelectionFlowChangeSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowRouteAddSaga(
  action: ReturnType<typeof doSelectionFlowRouteAdd>
) {
  try {
    const serverResponse = yield call(
      selectionFlowRouteAddRequest,
      action.payload
    )
    yield put(setCode(serverResponse))
    console.log('doSelectionFlowRouteAddSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowRouteChangeSaga(
  action: ReturnType<typeof doSelectionFlowRouteChange>
) {
  try {
    const serverResponse = yield call(
      selectionFlowRouteChangeRequest,
      action.payload
    )
    yield put(setCode(serverResponse))
    console.log('doSelectionFlowRouteChangeSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowExcludeSaga(
  action: ReturnType<typeof doSelectionFlowExclude>
) {
  try {
    yield call(selectionFlowExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowExcludeSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowUnExcludeSaga(
  action: ReturnType<typeof doSelectionFlowUnExclude>
) {
  try {
    yield call(selectionFlowUnExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowUnExcludeSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}
export function* doWebTestResultSaga(
  action: ReturnType<typeof doWebTestResult>
) {
  try {
    console.log('dotWebTestResultSaga処理終了')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAXS230画面を初期表示する。
function* initOutDate(action: ReturnType<typeof setOutResult>) {
  // 検索結果
  yield put(setMCAXS230InitResult(mcaxs230OutputDto))
}

function* mcbSubmissionDownloadSaga(
  action: ReturnType<typeof mcbSubmissionDownload>
) {
  try {
    const mcbInfo = action.payload.mcbInfo
    const jobSeekerId = action.payload.jobSeekerId
    const pdfDownloadAuthCheck: MCAXS040PdfDownloadAuthCheckRequest = {
      jobSeekerByCompanyId: mcbInfo.mcbJobSeekerByCompanyId,
      submissionRequestId: mcbInfo.mcbSubmissionRequestId,
    }
    yield call(pdfDownloadAuthCheckRequest, pdfDownloadAuthCheck)
    if (mcbInfo.mcbForceStopFlag === mcbContents.FORCESTOP) {
      throw Error(magiContants.MESSAGECODE_MCAXS040_007)
    }
    if (mcbInfo.mcbSubmissionStatusCode !== mcbContents.SUBMITTED) {
      throw Error(magiContants.MESSAGECODE_MCAXS040_008)
    }

    const windowSize = {
      height: '600px',
      width: '650px',
    }
    localStorage.setItem(
      'mcbPdfParam',
      JSON.stringify({
        jobSeekerByCompanyId: String(mcbInfo.mcbJobSeekerByCompanyId),
        requestId: String(mcbInfo.mcbSubmissionRequestId),
        jobSeekerId: String(jobSeekerId),
      })
    )
    window.open(
      routeList.creatingMcbPdf,
      'mcbSubmissionDownload',
      `width=${windowSize.width}, height=${windowSize.height}, resizable=yes`
    )
  } catch (error) {
    const e: any = error
    if (e.message === magiContants.MESSAGECODE_MCAXS040_007) {
      yield put(
        openModalWithArg({
          messageId: e.message,
          args: [mcbErrorContents.OUTPUT_PDF],
        })
      )
    } else {
      yield put(openModal(e.message))
    }
  }
}

// 詳細を確認するリンク（提出物内容）押下時
function* pushMcbCheckDetailsSage(
  action: ReturnType<typeof pushMcbCheckDetails>
) {
  try {
    const data = yield call(pushMcbCheckDetailsRequest, action.payload)
    const url = data.mcbCommonUrl + data.mcbSubmissionDetailUrl
    const title = '提出物確認画面'
    // MCB リプレイス #9476　start
    const params = popupParams(1280, 948)
    // MCB リプレイス #9476　end
    let urlParam = url + '?'
    urlParam = urlParam + 'sourceSystemType=' + data.sourceSystemType
    urlParam = urlParam + '&sourceCompanyCode=' + data.sourceCompanyCode
    urlParam = urlParam + '&sourceStudentCode=' + data.sourceStudentCode
    urlParam = urlParam + '&submissionRequestId=' + data.submissionRequestId
    axios
      .get(urlParam, {
        withCredentials: true,
        headers: {
          Authorization: getToken(),
        },
      })
      .then(res => {
        let newWindow = window.open(urlParam, title, params)
      })
      .catch(error => {
        console.log(error)
      })
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MyCareerBoxメッセージ作成押下時
function* mcbSubmissionMesseageMakeSaga(action: ReturnType<typeof mcbSubmissionMesseageMake>) {
  const mcbInfo = action.payload
  try{
    yield call(checkMcbServiceStatus)
    try {
      if (mcbInfo.mcbForceStopFlag === mcbContents.FORCESTOP) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_007)
      }
      if (
        ![
          mcbContents.NOT_SUBMITTED,
          mcbContents.RECEIVED,
          mcbContents.SUBMITTED,
        ].includes(mcbInfo.mcbSubmissionStatusCode)
      ) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_009)
      }
      if (mcbInfo.mcbUseStatus === mcbContents.DEACTIVATED) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_010)
      }
      
      const submissionRequestObjList = {
        jobSeekerByCompanyId: String(mcbInfo.mcbJobSeekerByCompanyId),
        submissionRequestId: String(mcbInfo.mcbSubmissionRequestId),
      }
      const messageInputRequest = {
        submissionRequestObjList: [submissionRequestObjList]//jobSeekerByCompanyIdSubmissionRequestIdList
      }
      localStorage.setItem(
        'messageInputRequest',
        JSON.stringify(messageInputRequest)
      )
  
      const windowSize = {
        height: '948px',
        width: '1280px',
      }
      window.open(
        routeList.mcbMessageSend,
        'mcbSubmissionMesseageMake',
        `width=${windowSize.width}, height=${windowSize.height}, resizable=yes`
      )
    } catch (e) {
      const error: any = e
      yield put(
        openModalWithArg({
          messageId: error.message,
          args: [mcbErrorContents.SEND_MESSAGE],
        })
      )
    }
  } catch (error) {
    yield put(openModal(getMessage(error.message)))
  }


}

// リクエスト内容変更押下時
function* mcbSubmissionRequestChangeSaga(action: ReturnType<typeof mcbSubmissionRequestChange>) {
  try{
    yield call(checkMcbServiceStatus)
    const mcbInfo = action.payload.mcbInfo
    const setRequestChangeTargetMcbInfo = action.payload.setRequestChangeTargetMcbInfo
    const handleModalOpen = action.payload.handleModalOpen
    try {
      if (mcbInfo.mcbForceStopFlag === mcbContents.FORCESTOP) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_007)
      }
      if (
        ![
          mcbContents.NOT_SUBMITTED,
          mcbContents.RECEIVED,
          mcbContents.SUBMITTED,
        ].includes(mcbInfo.mcbSubmissionStatusCode)
      ) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_009)
      }
      if (mcbInfo.mcbUseStatus === mcbContents.DEACTIVATED) {
        throw Error(magiContants.MESSAGECODE_MCAXS040_010)
      }
  
      setRequestChangeTargetMcbInfo(mcbInfo)
      handleModalOpen('mcbRequestChange')
    } catch (e) {
      const error: any = e
      yield put(
        openModalWithArg({
          messageId: error.message,
          args: [mcbErrorContents.REQUEST_CONTENTS_CHANGE],
        })
      )
    }
  } catch (error) {
    yield put(openModal(getMessage(error.message)))
  }

}

export default function* interviewListSaga() {
  yield all([
    takeEvery(init, initSaga),
    takeEvery(doSelectionFlowAdd, doSelectionFlowAddSaga),
    takeEvery(doSelectionFlowChange, doSelectionFlowChangeSaga),
    takeEvery(doSelectionFlowRouteAdd, doSelectionFlowRouteAddSaga),
    takeEvery(doSelectionFlowRouteChange, doSelectionFlowRouteChangeSaga),
    takeEvery(doSelectionFlowExclude, doSelectionFlowExcludeSaga),
    takeEvery(doSelectionFlowUnExclude, doSelectionFlowUnExcludeSaga),
    takeEvery(doWebTestResult, doWebTestResultSaga),
    takeEvery(setOutResult, initOutDate),
    takeEvery(mcbSubmissionDownload, mcbSubmissionDownloadSaga),
    takeEvery(pushMcbCheckDetails, pushMcbCheckDetailsSage),
    takeEvery(mcbSubmissionMesseageMake, mcbSubmissionMesseageMakeSaga),
    takeEvery(mcbSubmissionRequestChange, mcbSubmissionRequestChangeSaga),
  ])
}
