import React from 'react'
import { Attribute } from './interface'
import './style_MultilineEllipsie.css'

export default function MultilineEllipsie(props: Attribute) {
  // 何行を表示しますか
  const [line, setLine] = React.useState(props.line)
  // 表示内容
  const [content, setContent] = React.useState(props.content)
  // 何行を表示しますか
  const [htmlFlag, setHtmlFlag] = React.useState(props.htmlFlag)

  let classname

  // リセットの場合、状態がリセット
  React.useEffect(() => {
    if (
      line != props.line ||
      content != props.content ||
      htmlFlag != props.htmlFlag
    ) {
      setLine(props.line)
      setContent(props.content)
      setHtmlFlag(props.htmlFlag)
    }
  }, [props.line, props.content, props.htmlFlag])

  if (line == 3) {
    classname = 'ellipsis-three'
  } else {
    classname = 'ellipsis-five'
  }

  return (
    <div className={classname}>
      {htmlFlag ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <div>{content}</div>
      )}
      <span className='ellipsis-after'> ... </span>
    </div>
  )
}
