import {
  call,
  put,
  all,
  takeEvery,
  select,
  takeLeading,
} from 'redux-saga/effects'

import {
  getInitInfo,
  setInitInfo,
  uploadFileRegister,
  setPageDialogOpen,
} from 'reducers/facePhotoReducer'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import {
  facePhotoQueryRequest,
  uploadFileRegisterRequest
} from 'apis/MCAXS300Api'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'

function* searchSaga(action: ReturnType<typeof getInitInfo>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(facePhotoQueryRequest, action.payload);
    yield put(setPageDialogOpen(true));
    yield put(setInitInfo(data));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* uploadFileRegisterSaga(action: ReturnType<typeof uploadFileRegister>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.uploadImageRequest.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(uploadFileRegisterRequest, action.payload.uploadImageRequest);
    yield put(setPageDialogOpen(false));
    yield put(openSnackbar(data));
    action.payload.onOk()
  } catch (error) {
    yield put(openModal(error.message));
  }
}

export default function* facePhotoSaga() {
  yield all([
    takeEvery(getInitInfo, searchSaga),
    takeLeading(uploadFileRegister, uploadFileRegisterSaga),
  ])
}
