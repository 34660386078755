import request from 'utils/request'
import { MCAWS010InitPopupRequest } from 'types/MCAWS010/MCAWS010InitPopupRequest'
export const initPopupRequest = (initRequest: MCAWS010InitPopupRequest) =>
    request({
        url: `MCAWS010/initPopup`,
        method: 'post',
        data: initRequest,
    })

export const initAreaContactPassRequest = (initRequest: MCAWS010InitPopupRequest) =>
    request({
        url: `MCAWS010/initAreaContactPass`,
        method: 'post',
        data: initRequest,
    })
