/**
 * MCAES020CreateRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
  // 採用グループ名
  reqruitGroupName: yup
    .string()
    .trim()
    .required(validationMessageSet.required)
    .max(40, validationMessageSet.maxLength)
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
      miscRegexSet.halfWidth.test(value)
    ),

  // 選考Id
  celectionId: yup.array(),

  // 所属者id
  AffiliationId: yup
    .array(),
})
