import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    makeStyles,
    Grid,
    CardActions,
    DialogActions,
    Link,
} from '@material-ui/core'
import { TextField, LoadingDialog } from 'components'
import { Formik, Form, FastField, Field } from 'formik'
import {
    setDialogOpen,
    createsearchCriteriaName,
    updatesearchCriteria,
    checkActionInfo,
    setCheckDialogOpen,
    selectUnActionSearchCriteriaName,
    setOpenActionDialog,
} from 'reducers/selectCriteriaManagementReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import Toggle from './Toggle'
import { IntegrationReactSelect } from './Select'
import { selectRadioList, initialValues, textMap, buttonTextMap } from './formConfig'
import { magiContants, validationMessageSet } from 'utils/contants'
import InputWithCounter from './InputWithCounter'
import { getMessage } from 'common/messageUtil'
import { openModal } from 'reducers/messageReducer'
import { onKeyDown } from 'common/generalUtil'
import MCARS060 from 'pages/MCARS060'
import { toMCARS060Init } from 'reducers/actionPreReducer'
import InputWithCounterForAction from './InputWithCounterForAction'
import { setTagSettings } from 'reducers/tagManagementReducer'

const useStyles = makeStyles(theme => ({
    cancel: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
          backgroundColor: '#c1d1eb',
          borderColor: '#999999',
        },
        width: 150,
        marginRight: theme.spacing(8),
    },
    submitBtn: {
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
          backgroundColor: '#4596e6',
          borderColor: '#3380cc',
        },
        width: 150,
    },
    functionalDescription: {
        fontSize: '14px',
        color: (theme.palette as any).black,
        paddingTop: '15px',
        paddingLeft: '15px',
        // 年度対応　#6230　start
        whiteSpace:'pre-line',
        // 年度対応　#6230　end
    },
    btnGroup: {
        marginLeft: '90px',
        padding: 0,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5)
    },
    dateContainer: {
        width: '90%',
        marginTop: '30px',
        marginLeft: '25px',
        marginRight: '25px',
    },
    radiolable: {
        width: '50%',
        display: 'inline-block',
        float: 'left',
    },
    datelable: {
        paddingLeft: '0px',
        width: '50%',
        display: 'inline-block',
    },
    subTilefontSize: {
        backgroundColor: '#133e80',
        height: '40px',
        paddingTop:'10px',
    },
    subTitle: {
        color: (theme.palette as any).white,
        fontWeight: 700,
        fontSize: '18px',
        cursor: 'auto'
    },
    divHidden:{
        display: 'none',
    },
    dialogPaper: {
        overflowY: 'hidden',
      },
      dialogDiv: {
        width: '960px',
        overflow: 'auto',
        minHeight: '306px',
        maxHeight: '960px',
      },
}))

interface Props {
    escsId: string
    scName: string
    sCriteria: any
}
const MCARS030 = ({
    escsId,
    scName,
    sCriteria,
}: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [type, setType] = useState<keyof typeof textMap>('OK')
    const [initialValuesForm, setInitialValuesForm] = useState(initialValues)
    const [optionVlue, setOptionVlue] = useState('')
    const [getSearchInsertName, setSearchInsertName] = useState('')
    const [getInternalId, setInternalId] = useState('')
    const [getSearchName, setSearchName] = useState('')
    const [getVersionNumber, setVersionNumber] = useState<any>(null)
    const [getVersionNumber1, setVersionNumber1] = useState<any>(null)
    const [verNumber, setVerNumber] = useState('')
    const [verNumber1, setVerNumber1] = useState('')
    const [searchCriteria, setSearchCriteria] = useState<any>(null)

    const option = useSelector((state: RootState) => state.selectCriteriaManagement.selectDate)
    const recruitmentManagementDivision = useSelector((state: RootState) => state.globalMenu.recruitmentManagementDivision)
    const checkDialogOpen = useSelector((state: RootState) => state.selectCriteriaManagement.checkDialogOpen)
    const checkMsg = useSelector((state: RootState) => state.selectCriteriaManagement.checkMsg)
    // 次期開発9月#51248 start
    const unSchCriName = useSelector((state: RootState) => state.selectCriteriaManagement.unSchCriName)
    const [openActionFlag, setOpenActionFlag] = useState(false)
    const [getSearchInsertActionName,setSearchInsertActionName] = useState('')
    const openActionDialog = useSelector((state: RootState) => state.selectCriteriaManagement.openActionDialog)
    const globalMenu = useSelector((state: RootState) => state.globalMenu)
    const realUrl = useSelector(
        (state: RootState) => state.url
      )
    // 次期開発9月#51248 end
    // 「キャンセルする」ボタン押下
    const handleCancel = () => {
        setType('cancel')
        setOpen(true)
    }

    const handleMsgDialogClose = () => {
        setOpen(false)
        dispatch(setCheckDialogOpen(false))
    }
    const [error, setError] = useState({ isError: false, message: '' })
    // 次期開発9月#51248 start
    const [error2, setError2] = useState({ isError: false, message: '' })
    // 次期開発9月#51248 end
    const handleSubmit = (values: any) => {
        // 次期開発9月#51248 start
        if (error.isError || error2.isError) {
        // 次期開発9月#51248 end
            return
          }
        setType('OK')
        setOptionVlue(values.selectRadio)
        setSearchInsertName(values.searchInsertName)
        setSearchCriteria(sCriteria)
        setInternalId(values.internalId)
        setSearchName(values.nameCheck)
        if (verNumber === '' || values.sysVersionNumber != 0) {
            setVersionNumber(values.sysVersionNumber)
            setVersionNumber1(values.sysVersionNumber1)
        } else {
            setVersionNumber(verNumber)
            setVersionNumber1(verNumber1)
        }
        // 次期開発9月#51248 start
        if (values.selectRadio === '2') {
            // 文字の入力を確認
            if(values.searchInsertActionName.trim()===""){
                dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCARS030_004)))
            } else {
                // アクション設定でない検索条件の項目名を抽出
                setSearchInsertActionName(values.searchInsertActionName)
                dispatch(selectUnActionSearchCriteriaName(sCriteria)) 
            }
        }
        // 次期開発9月#51248 end
        if(values.selectRadio === '1'){
            if(values.searchInsertName.trim()===""){
                dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCARS030_004)))
            }else{
                if (escsId !== null && escsId !== '') {
                    dispatch(
                        checkActionInfo({
                            internalId: escsId,
                            searchName: values.searchInsertName,
                            sysVersionNumber: 0,
                            sysVersionNumber1: 0,
                            searchCriteria: sCriteria,
                        })
                    )
                } else {
                    setOpen(true)
                }
            }
        }else if(values.selectRadio === '0'){
            if(values.nameCheck===""){
                dispatch(openModal(getMessage(magiContants.MESSAGECODE_MCARS030_003)))
            }else{
                dispatch(
                    checkActionInfo({
                        internalId: values.internalId,
                        searchName: '',
                        sysVersionNumber: 0,
                        sysVersionNumber1: 0,
                        searchCriteria: sCriteria,
                    })
                )
            }  
        }
    }
    // 「OK」ボタン押下
    const handleCreate = () => {
        if (type !== magiContants.BUTTON_TYPE_CANCEL) {
            if (optionVlue === '1') {
                dispatch(
                    createsearchCriteriaName({
                        searchCriteria: searchCriteria,
                        searchName: getSearchInsertName,
                        recruitmentManagementDivision: recruitmentManagementDivision,
                    })
                )}
           else if (optionVlue === '0') {
                dispatch(
                    updatesearchCriteria({
                        internalId: getInternalId,
                        searchName: getSearchName,
                        sysVersionNumber: getVersionNumber,
                        sysVersionNumber1: getVersionNumber1,
                        searchCriteria: searchCriteria,
                    })
                )}
            setInitialValuesForm(initialValues)
        } else {
            dispatch(setDialogOpen(false))
        }
        setOpen(false)
        setError({ isError: false, message: '' })
        dispatch(setCheckDialogOpen(false))
    }

    const formModal = useSelector(
        (state: RootState) => state.selectCriteriaManagement.open
    )
    
    // 次期開発9月#51248 start
    // アクション設定可否モーダル
    const handleActionDialogClose = () => {
        dispatch(setOpenActionDialog(false))
    }
    
    // アクション設定可否モーダルで、「ok」ボタンを押下
    const handleCreateAction = () => {
        // アクション新規登録時にタグ情報は不要なのでクリアしておく
        dispatch(setTagSettings([]))
        dispatch(
            toMCARS060Init({
                unActionSearchCriteriaNameList:unSchCriName.unActionSearchCriteriaNameList,
                unActionSearchCriteriaList:unSchCriName.unActionSearchCriteriaList,
                searchCriteria: searchCriteria,
                searchName: getSearchInsertActionName,
                screenId: 'MCARS030',
                setOpenActionPreDialog:setOpenActionFlag,
            })
        )
        dispatch(setOpenActionDialog(false))
    }

    // アクション設定モーダル
    const handleDialogClose = () => {
        setSearchCriteria('')
        setOpenActionFlag(false)
    }
    
    // アクション設定可否モーダルで、「こちら」リンクを押下
    const clickActionLink = () => {
        if (recruitmentManagementDivision === '1') {
            window.open(realUrl.MCARS030_HELP_URL_1,'newWindow','resizable=yes,width=' + 1280 + ',height=' + 948 + '')
        } else if (recruitmentManagementDivision === '2') {
            window.open(realUrl.MCARS030_HELP_URL_2,'newWindow','resizable=yes,width=' + 1280 + ',height=' + 948 + '')
        }
    }
    // 次期開発9月#51248 end

    return (
        <div>
            {formModal ? <LoadingDialog className='overlay' /> : null}
            {/* 検索条件保存 */}
            <Dialog maxWidth='md' open={formModal}  fullWidth classes={{ paperScrollPaper: classes.dialogPaper }} className={'mainDialog mySearchMCAR'}>
            <DialogTitle className={classes.subTilefontSize} id="max-width-dialog-title">
                    {/* MCB ロット３ MY検索（応募管理）保存　start */}
                    <label className={classes.subTitle}>MY検索（応募管理）保存</label>
                    {/* MCB ロット３ MY検索（応募管理）保存　end */}
                </DialogTitle>
            <DialogContent className={classes.dialogDiv}>
               
                
                    <DialogContentText id="alert-dialog-description" className={classes.functionalDescription}>
                    {/* 転職March #75621 START */}
                      {globalMenu.marchResponsibleCompanyFlag == "1" ? <div dangerouslySetInnerHTML={{__html:getMessage(magiContants.MESSAGECODE_MCARS030_000_1)}} />
                      : <div dangerouslySetInnerHTML={{__html:getMessage(magiContants.MESSAGECODE_MCARS030_000)}} />
                      }
                    {/* 転職March #75621 END */}
                    </DialogContentText>
                    <Formik
                        initialValues={initialValuesForm}
                        // 相関項目チェック
                        validate={values => {
                            const errors = {
                                selectRadio: '',
                                searchName: '',
                                searchInsertName: '',
                                // 次期開発9月#51248 start
                                searchInsertActionName: '',
                                // 次期開発9月#51248 end
                            }
                            let errFlg = false
                            if (values.selectRadio === '1') {
                                if (values.searchInsertName.length > magiContants.MAX_LENGTH_MCARS030) {
                                    errors.searchInsertName =
                                    validationMessageSet.maxLength({
                                        max: magiContants.MAX_LENGTH_MCARS030,
                                      })
                                    errFlg = true
                                }
                            } else if (values.selectRadio === '0') {
                                if (values.nameCheck.length > magiContants.MAX_LENGTH_MCARS030) {
                                    errors.searchName =
                                    validationMessageSet.maxLength({
                                        max: magiContants.MAX_LENGTH_MCARS030,
                                      })
                                    errFlg = true
                                }
                            // 次期開発9月#51248 start
                            } else if (values.selectRadio === '2') {
                                if (values.searchInsertActionName.length > magiContants.MAX_LENGTH_MCARS030) {
                                    errors.searchInsertActionName =
                                    validationMessageSet.maxLength({
                                        max: magiContants.MAX_LENGTH_MCARS030,
                                    })
                                    errFlg = true
                                }
                            // 次期開発9月#51248 end
                            }
                            if (errFlg) {
                                return errors
                            }
                        }}
                        onSubmit={handleSubmit}
                        render={() => (
                            <Form onKeyDown={onKeyDown}>
                                <div className={classes.dateContainer}>
                                    <div className={classes.radiolable} >
                                        <Field
                                            name='selectRadio'
                                            component={Toggle}
                                            radiolist={selectRadioList}
                                            // 次期開発9月#51248 start
                                            setError={setError}
                                            setError2={setError2}
                                            // 次期開発9月#51248 end
                                        />
                                    </div>
                                    <div className={classes.datelable}>
                                        <Field
                                            name={'searchName'}
                                            options={option}
                                            initId={escsId}
                                            component={IntegrationReactSelect}
                                        />
                                        <Field
                                            name='searchInsertName'
                                            component={InputWithCounter}
                                            maxAmount={100}
                                            setError={setError}
                                            error = {error}
                                        />
                                        {/* 次期開発9月#51248 start */}
                                        {/* 転職March #75621 START */}
                                        {globalMenu.permissions[0] === '40' || globalMenu.permissions[0] === '50' || globalMenu.marchResponsibleCompanyFlag === "1" ? '' :
                                        <Field
                                            name='searchInsertActionName'
                                            component={InputWithCounterForAction}
                                            maxAmount={100}
                                            setError2={setError2}
                                            error2 = {error2}
                                        />}
                                        {/* 転職March #75621 END */}
                                        {/* 次期開発9月#51248 end */}
                                    </div>
                                </div>
                                <CardActions className={classes.btnGroup}>
                                    <Grid>
                                        <Button
                                            variant='contained'
                                            onClick={handleCancel}
                                            className={classes.cancel}>
                                            {textMap['cancel'].cancel}
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            className={classes.submitBtn}>
                                            {textMap['OK'].submit}
                                        </Button>
                                    </Grid>
                                </CardActions>
                                <div className ={classes.divHidden}>
                                    <div>
                                        <FastField
                                            name={'internalId'}
                                            type={'hidden'}
                                            component={TextField}
                                        />
                                    </div>
                                    <div>
                                        <FastField
                                            name={'sysVersionNumber'}
                                            type={'hidden'}
                                            component={TextField}
                                        />
                                    </div>
                                    <div>
                                        <FastField
                                            name={'sysVersionNumber1'}
                                            type={'hidden'}
                                            component={TextField}
                                        />
                                    </div>
                                    <div>
                                        <FastField
                                            name={'searchCriteria'}
                                            type={'hidden'}
                                            component={TextField}
                                        />
                                    </div>
                                    <div>
                                        <FastField
                                            name={'nameCheck'}
                                            type={'hidden'}
                                            component={TextField}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )} />
                </DialogContent>
            </Dialog>
            <Dialog open={open}>
                <DialogTitle>{textMap[type].message}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleMsgDialogClose}>{buttonTextMap.no}</Button>
                    <Button onClick={handleCreate} color='primary'>
                        {buttonTextMap.yes}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={checkDialogOpen}>
                <DialogTitle>{getMessage(checkMsg)}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleMsgDialogClose}>{buttonTextMap.no}</Button>
                    <Button onClick={handleCreate} color='primary'>
                        {buttonTextMap.yes}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* 次期開発9月#51248 start */}
            <Dialog open={openActionDialog}>
                <DialogTitle>
                {unSchCriName.unActionSearchCriteriaNameList.length > 0 ? 
                    <>
                    アクション設定に使用できない検索条件
                    {unSchCriName.unActionSearchCriteriaNameList}を除外した上で、
                    </>
                    : ''
                }
                <br/>MY検索を保存し、アクション設定画面に移動します。よろしいですか。
                <br/><br/>
                ※アクション設定に関する詳細は<Link onClick={clickActionLink} style={{cursor: 'pointer'}}>こちら</Link>をご確認ください。
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleActionDialogClose}>キャンセル</Button>
                    <Button onClick={handleCreateAction} color='primary'>OK</Button>
                </DialogActions>
            </Dialog>
            <MCARS060 entrySearchCriteriaSettingId={''} actionPreFormModal={openActionFlag} setActionPreFormModal={handleDialogClose} screenId={"MCARS030"}/>
            {/* 次期開発9月#51248 end */}
        </div>
    )
}
export default MCARS030;