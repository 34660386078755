/**
 * MCAES020UpdateRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 採用グループId
  reqruitGroupId: yup
    .string()
    .required(validationMessageSet.required),

  // 採用グループ名
  reqruitGroupName: yup
    .string()
    .required(validationMessageSet.required)
    .max(20, validationMessageSet.maxLength)
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),


  // 選考Id
  celectionId: yup
    .array(),

  // 所属者id
  AffiliationId: yup
    .array(),

  // バージョン番号
  // 選択されていない（0件）場合はnull
  versionNumber: yup
    .array()
    .required(validationMessageSet.required),
});