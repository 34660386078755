import { getMessage } from "common/messageUtil"

const initialCreateValues = {
    interviewLocationName: '',
    streetAddress: '',
    map: '',
}

const textMap = {
    register: {
        submit: '登録する',
        message: getMessage('MCAOS020-001'),
    },
    cancel: {
        cancel: 'キャンセルする',
        message: getMessage('MCAOS020-004'),
    },
}
export { initialCreateValues, textMap }