import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialValues } from 'pages/MCAXS111/formConfig'
import { MCAXS130downloadValues } from 'pages/MCAXS130/formConfig'
import { MCAXS121initialValues } from 'pages/MCAXS121/formConfig'
import { submissionRequestInfo } from './changeOthersReducer'

interface InitialValuesType {
  // MCAXS111 start
  templateInfoList: TemplateInfo[]
  selectionFlowInfoList: SelectionFlowInfo[]
  templateItemList: string[]
  selectionCheck: SelectionCheck
  initValues: InitValues
  selectionFlag: boolean
  // #57536 start
  allSelectionFlag: boolean
  // #57536 end
  selectedTemplateInfo: SelectedTemplateInfo
  selectedSelectionFlowInfo: SelectionFlowInfo
  // MCAXS111 end
  requestId: string
  status: string
  mCAXS130downloadValues: DownloadCsvRequest
  mCAXS121initialValues: InitRequest
  targetCount: number
  template: string
  selectionFlow: string
  mCAXS130Param: MCAXS130Param
}

const initialState: InitialValuesType = {
  // MCAXS111 start
  templateInfoList: [],
  selectionFlowInfoList: [],
  templateItemList: [],
  selectionCheck: {
    selectionFlag: false,
    // #57536 start
    selectedSelectionFlowId: [],
    // #57536 end
    nextPageParam: {
      selectionManagementIdList: [],
      count: 0,
      // #57536 start
      selectionFlowSettingId: [],
      // #57536 end
      selectionName: '',
      entryDataIoTemplateSettingId: '',
      templateName: '',
      // 次期開発9月対応 #58502 start
      sortKey: '',
      // 次期開発9月対応 #58502 end
      // MCBリプレース Lot3 start
      submissionRequestList: [],
      callDivision: '',
      // MCBリプレース Lot3 end
    },
  },
  initValues: initialValues,
  selectionFlag: false,
  // #57536 start
  allSelectionFlag: false,
  // #57536 end
  selectedTemplateInfo: {
    entryDataIoTemplateSettingId: '',
    templateName: '',
  },
  selectedSelectionFlowInfo: {
    selectionFlowSettingId: '', //選考フロー設定ID
    selectionName: '' //選考名
  },
  // MCAXS111 end
  requestId: '',
  status: '',
  mCAXS130downloadValues: MCAXS130downloadValues,
  mCAXS121initialValues: MCAXS121initialValues,
  targetCount: 0,
  template: '',
  selectionFlow: '',
  mCAXS130Param: {
    selectionFlow: '',
    template: '',
    targetCount: '',
    requestId: '',
  }
}

// MCAXS111 start
export interface InitValues {
  [key: string]: string | any
  entryDataIoTemplateSettingId: string
  templateName: string
  selectionFlowSettingId: string
  selectionName: string
  templateItemList: string[],
}

export interface TemplateInfo {
  [key: string]: string | any
  entryDataIoTemplateSettingId: string //エントリーデータ入出力テンプレート設定ID
  templateName: string //テンプレート名
  templateItemList: string[] //エントリーデータ入出力テンプレート設定ID
  defaultTemplateFlag: string //エントリーデータ入出力テンプレート設定ID
  selectionFlowNecessaryFlag: string //エントリーデータ入出力テンプレート設定ID
  // #57536 start
  allSelectionFlowNecessaryFlag: string //すべての選考フロー必須フラグ
  // #57536 end
}

export interface SelectionFlowInfo {
  [key: string]: string | any
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
}

export interface NextPageParam {
  [key: string]: string | any
  selectionManagementIdList: string[]
  count: number
  // #57536 start
  selectionFlowSettingId: string[]
  // #57536 end
  selectionName: string
  entryDataIoTemplateSettingId: string
  templateName: string
  // 次期開発9月 #58502 start
  sortKey: string
  // 次期開発9月 #58502 end
  // MCBリプレース Lot3 start
  submissionRequestList: submissionRequestInfo[]
  callDivision: string
  // MCBリプレース Lot3 end
}

export interface SelectionCheck {
  [key: string]: string | any
  selectionFlag: boolean
  // #57536 start
  selectedSelectionFlowId: string[]
  // #57536 end
  nextPageParam: NextPageParam
}

export interface SelectedTemplateInfo {
  [key: string]: string | any
  entryDataIoTemplateSettingId: string //エントリーデータ入出力テンプレート設定ID
  templateName: string //テンプレート名
}
// MCAXS111 end

// MCAXS121 start
export interface DownloadCsvRequest{
  requestId: string;
  template: string;
  targetCount: string;
}
export interface InitRequest{
  selectionManagementIdList: string[];
  entryDataIoTemplateSettingId: string;
  // #57536 start
  selectionFlowSettingId: string[];
  // #57536 end
  recruitmentManagementDivision: string;
  // 次期開発9月 #58502 start
  sortKey: string;
  // 次期開発9月 #58502 end
  // MCBリプレース Lot3 start
  requestList: submissionRequestInfo[]
  callDivision: string
  // MCBリプレース Lot3 end
}
export interface InitParam{
  InitRequest: InitRequest
  selectionFlow: string
  template: string
  targetCount: string
}

export interface MonitorParam{
  requestId: string
  selectionManagementIdList: string[];
  entryDataIoTemplateSettingId: string;
  // #57536 start
  selectionFlowSettingId: string[];
  // #57536 end
  recruitmentManagementDivision: string;
  // MCBリプレース #8306 start
  callDivision: string;
  // MCBリプレース #8306 end
}

export interface MCAXS130Param {
  selectionFlow: string
  template: string
  targetCount: string
  requestId: string
}

const csvOutputReducer = createSlice({
  name: 'csvInformation',
  initialState,
  reducers: {
    // MCAXS111 start
    setInitValues(state, action: PayloadAction<InitValues>){
      state.initValues = action.payload
      return state
    },
    setTemplateInfoList(state, action: PayloadAction<TemplateInfo[]>) {
      state.templateInfoList = action.payload
      return state
    },
    setSelectionFlowList(state, action: PayloadAction<SelectionFlowInfo[]>) {
      state.selectionFlowInfoList = action.payload
      return state
    },
    authorizeCheck(state, action: PayloadAction<SelectionCheck>) {
      state.selectionCheck = action.payload
      return state
    },
    setSelectionFlag(state, action: PayloadAction<boolean>){
      state.selectionFlag = action.payload
      return state
    },
    // #57536 start
    setAllSelectionFlag(state, action: PayloadAction<boolean>){
      state.allSelectionFlag = action.payload
      return state
    },
    // #57536 end
    setSelectedTemplateInfo(state, action: PayloadAction<SelectedTemplateInfo>){
      state.selectedTemplateInfo = action.payload
      return state
    },
    setSelectedSelectionFlowInfo(state, action: PayloadAction<SelectionFlowInfo>){
      state.selectedSelectionFlowInfo = action.payload
      return state
    },
    // MCAXS111 end
    // MCAXS130 start
    setRequestId(state, action: PayloadAction<string>) {
      state.requestId = action.payload
      return state
    },
    getRequestId(state, action) {
      state.requestId = action.payload
      return state
    },
    // MCAXS130 end
    // MCAXS121 start
    init(state, action: PayloadAction<InitParam>) {
      state.mCAXS121initialValues = action.payload.InitRequest
      state.mCAXS130Param.selectionFlow = action.payload.selectionFlow
      state.mCAXS130Param.targetCount = action.payload.targetCount
      state.mCAXS130Param.template = action.payload.template
      return state
    },
    monitor(state, action: PayloadAction<MonitorParam>) {
      state.requestId = action.payload.requestId
      return state
    },
    // MCAXS121 end
  },
})

export const {
  setInitValues,
  setTemplateInfoList,
  setSelectionFlowList,
  authorizeCheck,
  setSelectionFlag,
  // #57536 start
  setAllSelectionFlag,
  // #57536 end
  setSelectedTemplateInfo,
  setSelectedSelectionFlowInfo,
  init,
  monitor,
  setRequestId,
  getRequestId,
} = csvOutputReducer.actions
export default csvOutputReducer.reducer
