import { magiContants } from "utils/contants"
import { PassFailObjDto, SelectionFlowObjDto, SelectionStepObjDto } from "./searchConditionConfig"

/**
 * 選考フロー×選考ステップ×合否指定モーダル_初期表示用データ格納情報
 */
export interface FlowMasterModalInfo {
  flowMasterInfoList: FlowMasterInfo[] // 選考フロー×選考ステップ×合否情報
  selectionFlowTitleList: SelectionFlowInfo[] // 選考フロー情報
}

/** 選考フロー×選考ステップ×合否情報 */
export interface FlowMasterInfo {
  selectionFlowSettingId: string // 選考フロー設定ID
  selectionName: string // 選考名
  displayOrder: number // 表示順
  progressType: string // 進捗種別
  progressName: string // 進捗名
  decisionDivisionType: string // 判定区分種別
  progressStatusSettingId: string // 選考ステップ設定ID
  passFailList: DecisionInfo[]
}

/** 選考フロー情報 */
export interface SelectionFlowInfo {
  selectionFlowSettingId: string // 選考フロー設定ID
  selectionName: string // 選考名
}

export interface DecisionInfo {
  value: string // 判定区分
  label: string // 判定名
}

const undecided: DecisionInfo = {
  value: '0', // 判定区分
  label: '未判定', // 判定名
}
const judgment: DecisionInfo = {
  value: '1', // 判定区分
  label: '判定中', // 判定名
}
const participate: DecisionInfo = {
  value: '2', // 判定区分
  label: '参加', // 判定名
}
const pass: DecisionInfo = {
  value: '3', // 判定区分
  label: '合格', // 判定名
}
const notParticipate: DecisionInfo = {
  value: '4', // 判定区分
  label: '不参加', // 判定名
}
const fail: DecisionInfo = {
  value: '5', // 判定区分
  label: '不合格', // 判定名
}
const other: DecisionInfo = {
  value: '6', // 判定区分
  label: 'その他', // 判定名
}
export { undecided, judgment, participate, pass, notParticipate, fail, other }

/**
 * 選考フロー×選考ステップ×合否指定モーダル用選考フロー情報を作成
 *
 * @param modalInfo
 */
export const createSelenctionFlowInfo = (modalInfo: FlowMasterModalInfo) => {
  const map = new Map()
  modalInfo.flowMasterInfoList.forEach(info => {
    const selectionFlowSettingId = info.selectionFlowSettingId
    const selectionName = info.selectionName
    map.set(selectionFlowSettingId, selectionName)
  })
  const selenctionFlowInfoList: SelectionFlowInfo[] = []
  map.forEach((value, key) => {
    const selenctionFlowInfo: SelectionFlowInfo = {
      selectionFlowSettingId: key,
      selectionName: value,
    }
    selenctionFlowInfoList.push(selenctionFlowInfo)
  })

  return selenctionFlowInfoList
}
/**
 * 合否のチェックボックス情報を作成
 * 
 * @param modalInfo 
 */
export const createPassFailInfo = (modalInfo: FlowMasterModalInfo) => {
  const flowInfoList: FlowMasterInfo[] = []
  modalInfo.flowMasterInfoList.forEach(info => {
    let decisionInfoList: DecisionInfo[] = []
    if (['0', '2'].includes(info.decisionDivisionType)) {
      decisionInfoList = [undecided, judgment, pass, fail]
    } else if (['1'].includes(info.decisionDivisionType)) {
      decisionInfoList = [undecided, participate, notParticipate, other]
    } else {
      const decisionInfo: DecisionInfo = {
        value: undecided.value, //判定区分 - 未判定
        label: info.progressName, //判定名
      }
      decisionInfoList = [decisionInfo]
    }
    flowInfoList.push({
      ...info,
      passFailList: decisionInfoList,
    })
  })
  return flowInfoList
}

export interface SelectionFlowCheckList {
  selectionFlowCode: string
  selectionFlowName: string
  // 25KH #75311 start
  selectionPhaseJudgmentFlag: string
  selectionPhaseJudgmentName: string
  selectionIncludeBeforeChangeFlag: boolean
  // 25KH #75311 end
  selectionFlowSelectAllFlag: boolean
  selectionStepObj: SelectionStepCheckObj[]
  exceptFlag: boolean
}
export interface SelectionStepCheckObj {
  selectionStepCode: string
  selectionStepName: string
  passFailObj: PassFailCheckObj[]
}
export interface PassFailCheckObj {
  passFailCode: string
  passFailName: string
  checkFlag: boolean
}
// checkListの初期化
export const selectionFlowCheckListInit: SelectionFlowCheckList = {
  selectionFlowCode: '',
  selectionFlowName: '',
  // 25KH #75311 start
  selectionPhaseJudgmentFlag: '0',
  selectionPhaseJudgmentName: '現在の選考段階',
  selectionIncludeBeforeChangeFlag: false,
  // 25KH #75311 end
  selectionFlowSelectAllFlag: false,
  selectionStepObj: [],
  exceptFlag: false,
}

/**
 * 画面初期化、選考フローリンクを押下、追加するボタンを押下の時、表示の選考フローの選考スデップのよって、checkListを作成
 * すべてcheckboxの「checkFlag」初期値が「false」、チェックあり場合、「checkFlag」が「true」に変更する
 * 「追加する」、「OK」ボタンを押下時、checkListの中に、「checkFlag」が「true」のcheckboxは「検索条件」に追加する
 */
export const setSelectionFlowCheckListInit = (flowList: FlowMasterInfo[]) => {
  const selectionStepList: SelectionStepCheckObj[] = []
  flowList.map(selectedFlow => {
    const passFailList: PassFailCheckObj[] = selectedFlow.passFailList.map(option => {
      return {
        passFailCode: option.value,
        passFailName: option.label,
        checkFlag: false,
      }
    })
    const selectionStepObj: SelectionStepCheckObj = {
      selectionStepCode: selectedFlow.progressStatusSettingId,
      selectionStepName: selectedFlow.progressName,
      passFailObj: passFailList,
    }
    selectionStepList.push(selectionStepObj)
  })
  const checkList = {
    ...selectionFlowCheckListInit,
    selectionFlowCode: flowList[0] && flowList[0].selectionFlowSettingId ? flowList[0].selectionFlowSettingId : '',
    selectionFlowName: flowList[0] && flowList[0].selectionName ? flowList[0].selectionName : '',
    selectionFlowSelectAllFlag: false,
    selectionStepObj: selectionStepList,
    exceptFlag: false,
  }
  return checkList
}

/**
 * 「追加」、「OK」ボタンを押下の場合、選択エリアで選択のチェックボックスによって、追加したいの検索条件を作成する
 * @param checkList 
 * @returns 追加したいの検索条件
 */
export const createSearchCondition = (checkList: SelectionFlowCheckList) => {
  const selectionStepList: SelectionStepObjDto[] = []
  // 「選考ステップ×合否を全て選択」チェックなし場合
  if (!checkList.selectionFlowSelectAllFlag) {
    checkList.selectionStepObj.forEach(selectionStep => {
      const passFailObj: PassFailObjDto[] = []
      selectionStep.passFailObj.forEach(passFail => {
        // 「合否」checkboxがチェックあり場合、「合否checkbox」に追加する
        if (passFail.checkFlag) {
          passFailObj.push({
            passFailCode: passFail.passFailCode,
            passFailName: passFail.passFailName
          })
        }
      })
      // チェックありの「合否checkbox」がある場合、選考スデップは検索条件に追加する
      if (passFailObj.length > 0) {
        const selectionStepObj: SelectionStepObjDto = {
          selectionStepCode: selectionStep.selectionStepCode,
          selectionStepName: selectionStep.selectionStepName,
          passFailObj: passFailObj,
        }
        selectionStepList.push(selectionStepObj)
      }
    })
  }
  const selectionFlowObj: SelectionFlowObjDto = {
    selectionFlowCode: checkList.selectionFlowCode,
    selectionFlowName: checkList.selectionFlowName,
    // 25KH #75311 start
    selectionPhaseJudgmentFlag: checkList.selectionPhaseJudgmentFlag,
    selectionPhaseJudgmentName: checkList.selectionPhaseJudgmentName,
    // 25KH #75311 end
    selectionFlowSelectAllFlag: checkList.selectionFlowSelectAllFlag ? '1' : '0',
    selectionFlowSelectAllName: checkList.selectionFlowSelectAllFlag ? magiContants.SELECTION_FLOW_SELECT_ALL_NAME : '',
    selectionStepObj: selectionStepList,
    exceptCode: checkList.exceptFlag ? '1' : '0',
    exceptName: checkList.exceptFlag ? magiContants.EXCEPT_NAME : '',
  }
  return selectionFlowObj
}

/**
 * 「追加」、「OK」ボタンを押下場合、既に追加したの検索条件の中に、
 * 選択エリアで指定された条件と一致するの検索条件が存在するの場合、検索条件に追加しない
 * 
 * @param chip 既に追加したの検索条件
 * @param flowObj 選択エリアで指定された条件
 * @return true：検索条件を追加できる、false：検索条件を追加できない
 */
export const checkSameFlag = (chip: SelectionFlowObjDto, flowObj: SelectionFlowObjDto) => {
  // 選考フローが違う、検索条件を追加できる
  if (chip.selectionFlowCode !== flowObj.selectionFlowCode) {
    return true
  }
  // を除く違う、検索条件を追加できる
  if (chip.exceptCode !== flowObj.exceptCode) {
    return true
  }
  // 25KH #75311 start
  // 選考段階違う、検索条件を追加できる（選考段階指定FLGが[""]の場合、[0]として認識する）
  if (!(chip.selectionPhaseJudgmentFlag === flowObj.selectionPhaseJudgmentFlag
    || ("" === chip.selectionPhaseJudgmentFlag && "0" === flowObj.selectionPhaseJudgmentFlag))) {
    return true
  }
  // 25KH #75311 end
  // 全て選択の場合、検索条件を追加できない
  if ('1' === chip.selectionFlowSelectAllFlag && '1' === flowObj.selectionFlowSelectAllFlag) {
    return false
  }
  // 選考スデップListのLengthが違う、検索条件を追加できる
  if (chip.selectionStepObj.length !== flowObj.selectionStepObj.length) {
    return true
  }

  let checkSameFlag = false
  for (var i = 0; i < chip.selectionStepObj.length; i++) {
    if (chip.selectionStepObj[i].selectionStepCode !== flowObj.selectionStepObj[i].selectionStepCode) {
      return true
    } else {
      if (checkPassFail(chip.selectionStepObj[i].passFailObj, flowObj.selectionStepObj[i].passFailObj)) {
        return true
      } else {
        break
      }
    }
  }
  return checkSameFlag
}
/**
 * 合否一致の判断
 * @param chipPassFailObj 既に追加した検索条件の合否List
 * @param flowPassFailObj 選択エリアで指定された条件の合否List
 * @returns true：合否不一致、false：合否一致
 */
const checkPassFail = (chipPassFailObj: PassFailObjDto[], flowPassFailObj: PassFailObjDto[]) => {
  if (chipPassFailObj.length !== flowPassFailObj.length) {
    return true
  }
  for (var j = 0; j < chipPassFailObj.length; j++) {
    if (chipPassFailObj[j].passFailCode === flowPassFailObj[j].passFailCode) {
      break
    } else {
      return true
    }
  }
  return false
}
