/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    ...theme.typography.button,
    listStyle: 'none',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    '& .selected $pageLink': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
    paddingInlineStart: '0',
  },
  activeLink: {
    backgroundColor: colors.blueGrey[50],
    color: theme.palette.text.primary,
  },
  pageLink: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    outline: 'none',
    cursor: 'pointer',
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
    '&$activeLink': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    color: theme.palette.text.disabled,
    '&:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.text.disabled,
    },
  },
  button: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
  },
}))

type Props = Pick<
  ReactPaginateProps,
  'forcePage' | 'onPageChange' | 'pageCount' | 'onClick'
> & { disabled?: boolean }

const Paginate = (props: Props) => {
  const classes = useStyles()
  const {
    forcePage,
    onPageChange,
    pageCount,
    onClick,
    disabled = false,
  } = props
  const inFirstPage = forcePage === 0
  const inLastPage = forcePage === pageCount - 1
  const inLastTwoPage = forcePage === pageCount - 2

  const nextClass = `${classes.pageLink} ${
    inLastPage || disabled ? classes.disabled : ''
  }`
  const previousClass = `${classes.pageLink} ${
    inFirstPage || disabled ? classes.disabled : ''
  }`
  const pageLinkClass = `${classes.pageLink} ${
    disabled ? classes.disabled : ''
  }`

  const handleClickFirst = () => {
    if (!disabled) {
      if (!inFirstPage && onPageChange) {
        onPageChange({ selected: 0 })
      }
      if (!inFirstPage && onClick) {
        onClick({
          selected: 0,
          index: null,
          nextSelectedPage: 0,
          event: onClick,
          isPrevious: false,
          isNext: false,
          isBreak: false,
          isActive: false,
        })
      }
    }
  }

  const handleClickLast = () => {
    if (!disabled) {
      if (!inLastPage && onPageChange) {
        onPageChange({ selected: pageCount - 1 })
      }
      if (!inLastPage && onClick) {
        onClick({
          selected: 0,
          index: null,
          nextSelectedPage: pageCount - 1,
          event: onClick,
          isPrevious: false,
          isNext: false,
          isBreak: false,
          isActive: false,
        })
      }
    }
  }

  return (
    <div className={classes.root}>
      <a className={previousClass} onClick={handleClickFirst}>
        &laquo;
      </a>
      <ReactPaginate
        marginPagesDisplayed={0}
        pageRangeDisplayed={inLastPage || inLastTwoPage ? 5 : 4}
        breakLabel=''
        containerClassName={classes.container}
        nextLinkClassName={nextClass}
        previousLinkClassName={previousClass}
        pageLinkClassName={pageLinkClass}
        previousLabel='&lsaquo;'
        nextLabel='&rsaquo;'
        {...props}
        onClick={disabled ? () => false : onClick}
      />
      <a className={nextClass} onClick={handleClickLast}>
        &raquo;
      </a>
    </div>
  )
}

export default Paginate
