/**
 * MCAZS040MessageQueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({


  // 検索文字
  searchWordText: yup
    .string()
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

  // 更新日（FROM）
  updateFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 更新日（TO）
  updateToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),
  attachment: yup.object().shape({

  // 添付_あり
  attachmentExist: yup
  .string()
  .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

  // 添付_なし
  attachmentNot: yup
   .string()
   .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
}),

  // 返信希望アイコン
  replyNecessaryFlag: yup.object().shape({
    // 表示あり
    replyNecessaryCheckOn: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 表示なし
    replyNecessaryCheckOff: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  }),

  // 送信期間（FROM）
  replyLimitFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 送信期間（TO）
  replyLimitToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),
    
  // 一括送信のみ
  onlyBulkSend: yup
  .string()
  .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),


  // ページ数
  countPage: yup
    .number()
    .min(1, validationMessageSet.minValue)
});