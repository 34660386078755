import {
  call,
  put,
  select,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'

import {
  fetchAccoutList,
  createAccount,
  setAccount,
  updateAccount,
  deleteAccount,
} from 'reducers/accountReducer'
import { openSnackbar } from 'reducers/messageReducer'
import { getAccountList } from 'selectors'
import {
  fetchRequest,
  createRequest,
  updateRequest,
  deleteRequest,
} from 'apis/accountApi'
import { getMessage } from 'common/messageUtil'

function* fetchSaga() {
  try {
    const data = yield call(fetchRequest)
    yield put(setAccount(data))
  } catch (error) {
    yield put(openSnackbar(error))
  }
}

function* updateSaga(action: ReturnType<typeof updateAccount>) {
  const { data, message } = action.payload
  try {
    yield call(updateRequest, data)
    const accountList: ReturnType<typeof getAccountList> = yield select(
      getAccountList
    )
    yield put(
      setAccount(
        accountList.map(i =>
          i.loginId === data.loginId ? { ...i, ...data } : i
        )
      )
    )
    yield put(openSnackbar(message))
  } catch (error) {
    yield put(openSnackbar(error))
  }
}

function* deleteSaga(action: ReturnType<typeof deleteAccount>) {
  const id = action.payload
  try {
    yield call(deleteRequest, id)
    const accountList: ReturnType<typeof getAccountList> = yield select(
      getAccountList
    )
    yield put(openSnackbar('2'))
    yield put(setAccount(accountList.filter(i => i.id !== id)))
  } catch (error) {
    yield put(openSnackbar(error))
  }
}

function* createSaga(action: ReturnType<typeof createAccount>) {
  try {
    const data = yield call(createRequest, action.payload)
    const accountList: ReturnType<typeof getAccountList> = yield select(
      getAccountList
    )
    yield put(
      setAccount([...accountList, { ...data, ...action.payload }] as any)
    )
    yield put(openSnackbar('1'))
  } catch (error) {
    yield put(openSnackbar(error))
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(fetchAccoutList, fetchSaga),
    takeEvery(updateAccount, updateSaga),
    takeEvery(createAccount, createSaga),
    takeEvery(deleteAccount, deleteSaga),
  ])
}
