import request from 'utils/request'
import { MCAXS640EntryInfoRequest } from 'types/MCAXS640/MCAXS640EntryInfoRequest'
import { MCAXS640EntryInitRequest } from 'types/MCAXS640/MCAXS640EntryInitRequest'

export const mCAXS640InitRequest = (entry: MCAXS640EntryInitRequest) =>
  request({
    url: '/MCAXS640/init',
    method: 'post',
    data: entry,
  })
export const editRequest = (entry: MCAXS640EntryInfoRequest) =>
request({
  url: '/MCAXS640/edit',
  method: 'post',
  data: entry,
})
