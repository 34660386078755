
const initialValues = {
  entryDataIoTemplateSettingId: '',
  templateName: '',
  selectionFlowSettingId: '',
  selectionName: '（以下から選択）',
  templateItemList: [],
}

const textMap = {
  cancel: 'キャンセルする',
  createFile: 'ファイルを作成する',
}

export {
  initialValues,
  textMap,
}
