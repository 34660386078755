import request from 'utils/request'
import { ApplicantDetailsRequest, NotAdoptedNotificationRequest } from 'pages/MCAXS050/apiConfig'
import { MCAXS050ScreenTransitionRequest } from 'types/MCAXS050/MCAXS050ScreenTransitionRequest'
import { MCAXS050RouteScreenTransitionRequest } from 'types/MCAXS050/MCAXS050RouteScreenTransitionRequest'
import { MCAXS050UpdateExcludeRequest } from 'types/MCAXS050/MCAXS050UpdateExcludeRequest'
import { MCAXS050UpdateUnExcludeRequest } from 'types/MCAXS050/MCAXS050UpdateUnExcludeRequest'
import { MCAXS050SendMessageRequest } from 'types/MCAXS050/MCAXS050SendMessageRequest'
import { MCAXS050ServiceModeUpdateRequest } from 'pages/MCAXS050/apiConfig'

export const initRequest = (interview: ApplicantDetailsRequest) =>
  request({
    url: '/MCAXS050/init',
    method: 'post',
    data: interview,
  })

export const attachedFileRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/attachedFile',
    method: 'post',
    data: interview,
  })

export const pdfOutputRequest = (interview: MCAXS050ScreenTransitionRequest) =>
  request({
    url: '/MCAXS050/pdfOutput',
    method: 'post',
    data: interview,
  })

export const createNewMessageRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/createNewMessage',
    method: 'post',
    data: interview,
  })

export const selectionFlowAddRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowAdd',
    method: 'post',
    data: interview,
  })

export const selectionFlowChangeRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowChange',
    method: 'post',
    data: interview,
  })

export const selectionFlowRouteAddRequest = (
  interview: MCAXS050RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowRouteAdd',
    method: 'post',
    data: interview,
  })

export const selectionFlowRouteChangeRequest = (
  interview: MCAXS050RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowRouteChange',
    method: 'post',
    data: interview,
  })

export const selectionFlowExcludeRequest = (
  interview: MCAXS050UpdateExcludeRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowExclude',
    method: 'post',
    data: interview,
  })

export const selectionFlowUnExcludeRequest = (
  interview: MCAXS050UpdateUnExcludeRequest
) =>
  request({
    url: '/MCAXS050/selectionFlowUnExclude',
    method: 'post',
    data: interview,
  })

export const profileFacePhotoUploadRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/profileFacePhotoUpload',
    method: 'post',
    data: interview,
  })

export const profileEditRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/profileEdit',
    method: 'post',
    data: interview,
  })

export const entryHistoryNewReservationRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/entryHistoryNewReservation',
    method: 'post',
    data: interview,
  })

export const entryHistoryCancelRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/entryHistoryCancel',
    method: 'post',
    data: interview,
  })

export const managementItemUpdateRequest = (
  interview: MCAXS050ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS050/managementItemUpdate',
    method: 'post',
    data: interview,
  })

export const sendMessageChkRequest = (
  interview: MCAXS050SendMessageRequest
) =>
  request({
    url: '/MCAXS050/sendMessageChk',
    method: 'post',
    data: interview,
  })

export const notAdoptedNotificationRequest = (
  interview: NotAdoptedNotificationRequest
) =>
  request({
    url: '/MCAXS050/notAdoptedNotification',
    method: 'post',
    data: interview,
  })

  export const serviceModeUpdateRequest = (
    interview: MCAXS050ServiceModeUpdateRequest
  ) =>
    request({
      url: '/MCAXS050/serviceModeUpdate',
      method: 'post',
      data: interview,
    })