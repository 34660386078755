import request from 'utils/request'
import { MCBAS020SearchConditionRequest } from 'types/MCBAS020/MCBAS020SearchConditionRequest'
import { MCBAS020InterviewScheduleRequest } from 'types/MCBAS020/MCBAS020InterviewScheduleRequest'
import { MCBAS020InterviewWebUrlRequest } from 'types/MCBAS020/MCBAS020InterviewWebUrlRequest'

// 画面初期表示
export const initRequest = (apiData: MCBAS020SearchConditionRequest) =>
  request({
    url: '/MCBAS020/init',
    method: 'post',
    data: apiData,
})

// 検索
export const searchRequest = (apiData: MCBAS020SearchConditionRequest) =>
  request({
    url: '/MCBAS020/search',
    method: 'post',
    data: apiData,
})

// 面接調整状態整合性チェック
export const checkVersionNumberRequest = (apiData: MCBAS020InterviewScheduleRequest) =>
  request({
    url: '/MCBAS020/checkVersionNumber',
    method: 'post',
    data: apiData,
})

// WEB面接対応 start
// WEB面接URLを発行する。
export const createInterviewWebUrlRequest = (apiData: MCBAS020InterviewWebUrlRequest) =>
  request({
    url: '/MCBAS020/createInterviewWebUrl',
    method: 'post',
    data: apiData,
})

// WEB面接URLをキャンセルする。
export const cancelInterviewWebUrlRequest = (apiData: MCBAS020InterviewWebUrlRequest) =>
  request({
    url: '/MCBAS020/cancelInterviewWebUrl',
    method: 'post',
    data: apiData,
})
// WEB面接対応 end