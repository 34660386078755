
export interface InitResult {

  tagSuggestList: TagSuggestList[]

  tagRegisteredList: TagRegisteredList[]

  tagInCommonUseList: TagInCommonUseList[]
}

export interface TagSuggestList {
  // タグ設定ID
  // -
  tagSettingId: string
  // タグ名称
  // -
  tagName: string
}

export interface TagRegisteredList {
  // タグ設定ID
  // -
  tagSettingId: string
  // タグ名称
  // -
  tagName: string
  // タグマネジメントID
  // -
  tagManagementId: string
}

export interface TagInCommonUseList {
  // タグ設定ID
  // -
  tagSettingId: string
  // タグ名称
  // -
  tagName: string
}

const tagSuggestList : TagSuggestList = {
  tagSettingId: "",
  tagName: "",
}

const tagRegisteredList : TagRegisteredList = {
  tagSettingId: "",
  tagName: "",
  tagManagementId: "",
}

const tagInCommonUseList : TagInCommonUseList = {
  tagSettingId: "",
  tagName: "",
}

const initResult : InitResult = {
  tagSuggestList: [],
  tagRegisteredList: [],
  tagInCommonUseList: [],
}

export interface InitRequest {
  screenId: string
  jobSeekerId: string[]
  selectionManagementId: string[]
  entryId: string[]
}

const initRequest : InitRequest = {
  screenId: "",
  jobSeekerId: [],
  entryId: [],
  selectionManagementId: [],
}

export interface AddListJobchangeRequest {

  selectionId: string

  jobSeekerId: string[]

  selectionManagementId: string[]

  tagName: string[]

  tagSettingId: string[]

  tagManagementId: string[]

  delTagSettingId: string[]

  delTagManagementId: string[]
}

const addListJobchangeRequest : AddListJobchangeRequest = {
  selectionId: "",
  jobSeekerId: [""],
  selectionManagementId: [""],
  tagName: [""],
  tagSettingId: [""],
  tagManagementId: [""],
  delTagSettingId: [""],
  delTagManagementId: [""],
}

export {
  initResult,
  tagSuggestList,
  tagRegisteredList,
  tagInCommonUseList,
  addListJobchangeRequest,
  initRequest,
}
