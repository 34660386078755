import moment from 'moment'
import { now, stringToDateTime } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import {
  redistRequest,
  initRequest,
} from 'apis/MCAZS091Api'
import { changeConfirmState, closeConfirmModal } from 'reducers/messageSendReducer'
import {
  getInit,
  setInit,
  setIsSending,
  setCode,
  setBusinessCheckResult,
  redistMessage,
  setRegisterResult,
  openDetailDialog,
} from 'reducers/messageSendConfirmReducer'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { openModal } from 'reducers/messageReducer'
import * as apiConfig from 'pages/MCAZS091/apiConfig'
import { isFileExist } from 'common/businessUtil'
import {
  setSearchCriteria,
  setDetailDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const initVal = action.payload.initVal

    const serverResponse = yield call(initRequest, initVal)
    yield put(setInit(serverResponse))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* redistMessageSaga(action: ReturnType<typeof redistMessage>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files

    // ファイル存在チェック
    if (files.some(item => !item || item.size <= 0)) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCAZS091-033')))
      return
    }
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if(!isFile){
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCAZS091-033')))
          return
        }
      }
    }
    
    const suffixCheck = [
      'ai','art','doc','fla','gif','jpg','jpeg','lzh','mpeg','mpg','pdf','png','pps','ppt','psd','txt','xls','zip','docx','xlsx'
    ]
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const lastIndexOf = file.name.lastIndexOf('.')
        const suffix = file.name.substring(lastIndexOf + 1)
        for (var j = 0; j < suffixCheck.length; j++) {
          if (suffixCheck[j] == suffix.toLowerCase()) {
            break
          } else {
            if(suffixCheck[j] != 'xlsx') {
              continue
            } else {
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCAZS091-030')))
              return
            }
          }
        }
      }
    }

    // 送信予約時刻チェック
    if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
      const sendPlanTime = formData.sendPlanTime != null ? stringToDateTime(formData.sendPlanTime) : null
      if (sendPlanTime) {
        const targetDate = moment(sendPlanTime)
        const minTime = moment(now()).add(magiContants.RESERVATION_MIN_MINUTES_LENGTH, 'minutes')
        if (targetDate <= minTime) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCAZS091-052')))
          return
        }
      }
    }

    yield put(setIsSending(true))
    const serverResponse: apiConfig.MCAZS091RegisterResult = yield call(redistRequest, formData, files)
    yield put(setRegisterResult(serverResponse))
    yield put(setCode(200))
    yield put(setBusinessCheckResult(true))
    yield put(setIsSending(false))
  } catch (error) {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision || !action.payload.formData.notAdoptedNotificationType){
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
    }
    yield put(setIsSending(false))
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* openDetailDialogSaga(action: ReturnType<typeof openDetailDialog>) {
  yield put(setSearchCriteria(action.payload))
  yield put(setDetailDialogOpen(true))
}

export default function* messageConfirmSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(redistMessage, redistMessageSaga),
    takeLatest(openDetailDialog, openDetailDialogSaga),
  ])
}
