export const initValues = {
  jobSeekerId: '',// 求職者ID
  entryId:'',// エントリーID
  recruitmentManagementDivision:''// 採用管理区分
}
export const initialSelectFacePhotoData = {
  facePhotoManagementId:'',// 顔写真管理ID
  sysVersionNumber:0// sysバージョン番号
}

export const init = {
  operationType:0//操作種別
}

// 画像ラジオボタン
export const facePhotoRadioList = [
  { label: '画像をアップロードする', value: 0, index: 1},
  { label: '画像を削除する', value: 1, index: 2}
]
