import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMessage } from 'common/messageUtil'

import { SelectionFlowInitData, Status } from './selectionStatusUpdateReducer'

import {
  ScreenTransitionInput,
  Option,
  SortCondition,
  SubmissionRequestsListInfo,
  SearchResult,
  MySearch,
  initScreenTransitionInput,
  initeSearchRequest,
  initMySearch,
  initSortCondition,
  initSearchResult,
  initOption,
  Data,
  SelectedSearch,
  initSelectedSearch,
  initSearchRequest,
  initMcbCheckDetailsRequest,
} from 'pages/MCBHS040/formConfig'
import {
  SearchCondition,
  initSearchCondition,
} from 'pages/MCBHS040/searchConditionConfig'
// import { initialSelectionFlowInitData } from 'pages/MCAYS030/formConfig'

import { MCBHS040SearchRequest } from 'types/MCBHS040/MCBHS040SearchRequest'
import { MCBHS040GetMySearchRequest } from 'types/MCBHS040/MCBHS040GetMySearchRequest'
import { MCBHS040McbCheckDetailsRequest } from 'types/MCBHS040/MCBHS040McbCheckDetailsRequest'
import { MCBHS120OrderRequest } from 'types/MCBHS120/MCBHS120OrderRequest'

// import { FilterCondition, FilterDisplay, FilterDisplayProgressInfo, initFilterCondition, initFilterDisplay } from 'pages/MCAXS010/formConfig'
import { MCBIS020GetMySearchRequest } from 'types/MCBIS020/MCBIS020GetMySearchRequest'
import { MCBIS020SearchRequest } from 'types/MCBIS020/MCBIS020SearchRequest'


export interface ActionPreListData {
  entrySearchCriteriaSettingId: string
  searchCriteriaName: string

}

const initialState: {
  screenTransitionInput: ScreenTransitionInput //一覧画面遷移時の入力情報
  searchRequest: MCBHS040SearchRequest //検索リクエスト
  MCBsearchRequest: MCBIS020SearchRequest //MY検索（My CareerBox）リクエスト
  mySearch: MySearch //My検索設定情報
  sortCondition: SortCondition //ソート条件
  page: number // 表示ページ番号
  searchResult: SearchResult // 検索結果
  data: Data[] //一覧表示用に整形した検索結果
  searchCondition: any
  selectedSearch: SelectedSearch
  selectedAccounts: string[] //チェックボックス選択状態
  orderRequest: MCBHS120OrderRequest
  openFlag: boolean
  refreshFlag: number
  loadingFlag: boolean
  hasNewData: boolean
  searchCount: number
  totalCount: number
  needUpdate: boolean
  sreachBtnFlg: boolean
  displayLimit: Option
  circleLoadingFlag: boolean
  displaySearchCriteriaTitleOpenFlag: boolean
  searchConditions: string
  changeRadioValue: number
  searchConditionsConfirm: string
  changeRadioValueConfirm: number
  ActionPreList: ActionPreListData[]
  createDialogOpen: boolean
  // 提出物内容IDリスト MCBHS161渡す用
  fileModalSubmissionId: number[] | null
  flag: number
  McbCheckDetailsRequest: MCBHS040McbCheckDetailsRequest
  selectAllStatus: boolean
} = {
  screenTransitionInput: initScreenTransitionInput,
  searchRequest: initeSearchRequest,
  mySearch: initMySearch,

  sortCondition: initSortCondition,
  page: 0,
  searchResult: initSearchResult,
  data: [],
  selectedAccounts: [],
  orderRequest: {
    invisibleItemInfo: [],
    displayItemInfo: [],
  },
  openFlag: false,
  searchCondition: initSearchCondition,
  selectedSearch: initSelectedSearch,
  refreshFlag: 0,
  loadingFlag: false,
  hasNewData: false,
  searchCount: 1,
  totalCount: 0,
  needUpdate: false,
  sreachBtnFlg: false,
  displayLimit: { label: '', value: '' },
  circleLoadingFlag: false,
  displaySearchCriteriaTitleOpenFlag: false,
  searchConditions: '',
  changeRadioValue: 1,
  searchConditionsConfirm: '',
  changeRadioValueConfirm: 1,
  ActionPreList: [],
  createDialogOpen: false,
  flag: 0,
  fileModalSubmissionId: [],
  MCBsearchRequest: initSearchRequest,
  McbCheckDetailsRequest: initMcbCheckDetailsRequest,
  selectAllStatus: false,
}

const submissionRequestListSlice = createSlice({
  name: 'submissionRequestList',
  initialState,
  reducers: {
    // MCBIS020 初期表示
    getActionPreList(state) {
      return state
    },
    // MCBIS020 初期表示
    setActionPreList(state, action: PayloadAction<ActionPreListData[]>) {
      state.ActionPreList = action.payload
      return state
    },
    // MCBIS020 自分画面（非）表示
    setCreateDialogOpen(state, action: PayloadAction<boolean>) {
      state.createDialogOpen = action.payload
      return state
    },
    // MCBIS020
    getMySearchJobChange(
      state,
      action: PayloadAction<{
        mySearchRequest: MCBIS020GetMySearchRequest
        searchRequest: MCBIS020SearchRequest
      }>
    ) {
      state.MCBsearchRequest = action.payload.searchRequest
      const newScreenTransitionInput = state.screenTransitionInput
      newScreenTransitionInput.screenId =
        action.payload.searchRequest.sourceFunctionId
      state.screenTransitionInput = newScreenTransitionInput

      return state
    },
    myInitFlag(state) {
      return state;
    },
    setFlag(state, action: PayloadAction<number>) {
      state.flag = action.payload;
      return state;
    },
    getMySearch(
      state,
      action: PayloadAction<{
        mySearchRequest: MCBHS040GetMySearchRequest
        searchRequest: MCBHS040SearchRequest
      }>
    ) {
      state.searchRequest = action.payload.searchRequest
      const newScreenTransitionInput = state.screenTransitionInput
      newScreenTransitionInput.screenId =
        action.payload.searchRequest.sourceFunctionId
      state.screenTransitionInput = newScreenTransitionInput

      return state
    },
    search(
      state,
      action: PayloadAction<{
        request: MCBHS040SearchRequest
        isInitFilter: boolean
        mySearch?: MySearch
        onSearch?: () => void
      }>
    ) {
      const request = {
        ...action.payload.request,
        entrySearchCriteriaSettingId: null
      }
      state.searchRequest = request
      return state
    },
    updateScreenTransitionInput(
      state,
      action: PayloadAction<ScreenTransitionInput>
    ) {
      state.screenTransitionInput = action.payload
      return state
    },
    updateSearchRequest(state, action: PayloadAction<MCBHS040SearchRequest>) {
      state.searchRequest = action.payload
      return state
    },
    // stateに提出物内容IDリストを設定
    searchFileModalRequest(
      state,
      action: PayloadAction<Array<any>>
    ) {
      const submissionsContentIdList = (action.payload).map((item: any) =>{
        return item.value
      })
      state.fileModalSubmissionId = submissionsContentIdList
      return state
    },
    searchSubmissionRequest(
      state,
      action: PayloadAction<{
        request: MCBHS040SearchRequest
        isInitFilter: boolean
        mySearch?: MySearch
        onSearch?: () => void
      }>
    ) {
      const request = {
        ...action.payload.request,
        entrySearchCriteriaSettingId: null
      }
      state.searchRequest = request
      return state
    },
    setSearchResult(state, action: PayloadAction<SearchResult>) {
      state.searchResult = action.payload
      return state
    },
    resetData(state) {
      state.data = []
      return state
    },
    setSortCondition(state, action: PayloadAction<SortCondition>) {
      state.sortCondition = action.payload
      return state
    },
    setInitDisplay(
      state,
      action: PayloadAction<{
        isInitFilter: boolean
        searchResult: SearchResult
      }>
    ) {
      const searchResult = action.payload.searchResult

      const sortCondition = state.sortCondition
      
      const sortedFilteredDisplayInfoList = createDisplayOrderInfoArray(
        searchResult.displayInfo,
        sortCondition
      )

      state.data = sortedFilteredDisplayInfoList
      state.page = 0
      state.selectedAccounts = []

      state.searchResult.totalCount = state.totalCount
      
      switch (state.searchRequest.sourceFunctionId) {
        case 'MCBIS010':
          state.searchCondition =
            searchResult.searchCondition !== null
              ? JSON.parse(searchResult.searchCondition)
              : null

          state.mySearch = {
            entrySearchCriteriaSettingId:
              state.screenTransitionInput.entrySearchCriteriaSettingId,
            searchCriteriaName:
              searchResult.searchCriteriaName !== null
                ? searchResult.searchCriteriaName
                : '',
            searchCriteria:
              searchResult.searchCondition !== null
                ? searchResult.searchCondition
                : '',
            searchCriteriaDisplay: searchResult.searchConditionDisplay,
          }
          break
        default:
          break
      }
      return state
    },
    updateSortCondition(
      state,
      action: PayloadAction<{
        order: string
        searchResult: SearchResult
      }>) {
      const { totalCount } = state
      const searchResult = action.payload.searchResult

      if (searchResult.displayInfo.length < totalCount) {
        state.needUpdate = true
      }

      state.sortCondition.order = action.payload.order
      state.page = 0
      const order = action.payload.order
      const sortCondition = state.sortCondition
      sortCondition.order = order

      const sortedFilteredDisplayInfoList = createDisplayOrderInfoArray(
        searchResult.displayInfo,
        sortCondition
      )

      state.data = sortedFilteredDisplayInfoList
      state.sortCondition.order = order
      state.selectedAccounts = []
      return state
    },
    updatePage(
      state,
      action: PayloadAction<{
        page: number
        searchResult: SearchResult
      }>) {
      state.page = action.payload.page
      state.selectedAccounts = []
      const page = action.payload.page
      const searchResult = action.payload.searchResult

      if (state.hasNewData) {
        const { sortCondition } = state

        state.data = createDisplayOrderInfoArray(
          searchResult.displayInfo,
          sortCondition
        )
        state.hasNewData = false
      }

      /**
       * Pagination out of range before the second request returns
       */
      const rowsPerPage = 100
      const startIndex = page * rowsPerPage
      const endIndex =
        startIndex + rowsPerPage > state.data.length
          ? state.data.length
          : startIndex + rowsPerPage

      if (state.data.slice(startIndex, endIndex).length === 0) {
        state.needUpdate = true
      }

      return state
    },
    updateOnSelectAll(state, action: PayloadAction<Data[]>) {
      const displayInfo = action.payload
      if (state.hasNewData) {
        const { sortCondition } = state

        state.data = createDisplayOrderInfoArray(
          displayInfo,
          sortCondition
        )
        state.hasNewData = false
      }

      if (displayInfo.length < state.totalCount) {
        state.needUpdate = true
      }

      return state
    },
    setSelectedAccounts(state, action: PayloadAction<string[]>) {
      state.selectedAccounts = action.payload
      return state
    },
    initDisplayItems(state) {
      return state
    },
    setInitInfo(state, action: PayloadAction<any>) {
      state.orderRequest = action.payload
    },
    settingDisplayItems(
      state,
      action: PayloadAction<{
        request: MCBHS120OrderRequest
        onSubmit: (() => void) | undefined
      }>
    ) {
      return state
    },
    setting(
      state,
      action: PayloadAction<{
        request: MCBHS120OrderRequest
        onSubmit: (() => void) | undefined
      }>
    ) {
      return state
    },
    setMySearchResult(state, action: PayloadAction<MySearch>) {
      state.mySearch = action.payload
      return state
    },
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      state.searchCondition = action.payload
      
      const newSearchRequest = state.searchRequest
      newSearchRequest.searchCondition = JSON.stringify(action.payload)
      state.searchRequest = newSearchRequest

      return state
    },
    setSelectedSearch(state, action: PayloadAction<SelectedSearch>) {
      state.selectedSearch = action.payload
      return state
    },
    setOpenFlag(state, action: PayloadAction<boolean>) {
      state.openFlag = action.payload
      return state
    },
    setMCBHS040RefreshFlag(state) {
      state.refreshFlag = state.refreshFlag === 0 ? 1 : 0
    },
    setLoadingFlag(state, action: PayloadAction<boolean>) {
      state.loadingFlag = action.payload
      return state
    },
    setHasNewData(state, action: PayloadAction<boolean>) {
      state.hasNewData = action.payload
      return state
    },
    setSearchCount(state, action: PayloadAction<number>) {
      state.searchCount = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    setNeedUpdate(state, action: PayloadAction<boolean>) {
      state.needUpdate = action.payload
    },
    setSreachBtnFlg(state, action: PayloadAction<boolean>) {
      state.sreachBtnFlg = action.payload
    },
    setDisplayLimit(state, action: PayloadAction<Option>) {
      state.displayLimit = action.payload
      return state
    },
    setSearchConditions(state, action: PayloadAction<string>) {
      state.searchConditions = action.payload
      return state
    },
    setChangeRadioValue(state, action: PayloadAction<number>) {
      state.changeRadioValue = action.payload
      return state
    },
    setSearchConditionsConfirm(state, action: PayloadAction<string>) {
      state.searchConditionsConfirm = action.payload
      return state
    },
    setChangeRadioValueConfirm(state, action: PayloadAction<number>) {
      state.changeRadioValueConfirm = action.payload
      return state
    },
    setCircleLoadingFlag(state, action: PayloadAction<boolean>) {
      state.circleLoadingFlag = action.payload
      return state
    },
    setDisplaySearchCriteriaTitleOpenFlag(state, action: PayloadAction<boolean>) {
      state.displaySearchCriteriaTitleOpenFlag = action.payload
      return state
    },
    updateOnNewData(state,action: PayloadAction<Data[]>) {
      const { sortCondition } = state
      let displayInfo = action.payload

      if (
        state.screenTransitionInput.requestList.length === 0
      ) {
        let cantReferenceInfoCount = 0
        state.searchResult.totalCount = state.totalCount - cantReferenceInfoCount
      } else {
        state.searchResult.totalCount = displayInfo.length
      }

      state.data = createDisplayOrderInfoArray(
        displayInfo,
        sortCondition
      )
      state.hasNewData = false
      state.needUpdate = false
      return state
    },
    // MCBリプレース MCBHS161 START
    getMcbhs161Init(state) {
      return state
    },
    doOriginalMcbServiceCheck(state,
      action: PayloadAction<{
        handleOriginalQuestionSelectClick: (() => void)
      }>){
      return state
    },
    getMcbhs161SubmissionClick(state, action: PayloadAction<{
      open: [{
        submissionFile: boolean;
      }, React.Dispatch<React.SetStateAction<{
        submissionFile: boolean;
      }>>]
    }>) {
      return state
    },    
    // MCBリプレース MCBHS161 END
    pushMcbCheckDetails(state, action: PayloadAction<MCBHS040McbCheckDetailsRequest>) {
      return state
    },
    setSelectAllStatus(state, action: PayloadAction<boolean>) {
      state.selectAllStatus = action.payload
      return state
    },
    // MCBリプレース MCBHS211 START
    doMcbhs211McbServiceCheck(state,
      action: PayloadAction<{
        newValue: number
        submissionsObj: any
        doChangeEvent: ((submissionsObj: any, newValue: number) => void)}>){
      return state
    }
    // MCBリプレース MCBHS211 END    
  },
})

export const {
  updateScreenTransitionInput,
  updateSearchRequest,
  searchSubmissionRequest,
  setSearchResult,
  resetData,
  setSortCondition,
  setInitDisplay,
  updateSortCondition,
  updatePage,
  updateOnSelectAll,
  setSelectedAccounts,
  initDisplayItems,
  setInitInfo,
  settingDisplayItems,
  setting,
  setMySearchResult,
  setSearchCondition,
  setSelectedSearch,
  setOpenFlag,
  setMCBHS040RefreshFlag,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  setNeedUpdate,
  setSreachBtnFlg,
  setDisplayLimit,
  setSearchConditions,
  setChangeRadioValue,
  setSearchConditionsConfirm,
  setChangeRadioValueConfirm,
  setCircleLoadingFlag,
  setDisplaySearchCriteriaTitleOpenFlag,
  // MCBIS020
  getMySearchJobChange,
  search,
  getMySearch,
  getActionPreList,
  setActionPreList,
  setCreateDialogOpen,
  myInitFlag,
  setFlag,
  updateOnNewData,
  searchFileModalRequest,
  // MCBHS161
  getMcbhs161Init,
  getMcbhs161SubmissionClick,
  doOriginalMcbServiceCheck,
  pushMcbCheckDetails,
  setSelectAllStatus,
  // MCBHS211
  doMcbhs211McbServiceCheck,  
} = submissionRequestListSlice.actions
export default submissionRequestListSlice.reducer

/**
 *
 * 表示順情報作成処理
 *
 * @param filterdList
 * @param sortCondition
 */
const createDisplayOrderInfoArray = (
  displayInfoList: Data[],
  sortCondition: SortCondition
) => {
  //表示順情報にデータを追加
  const displayOrderInfoArray = new Array(displayInfoList.length)
  for (let i = 0; i < displayInfoList.length; i++) {
    displayOrderInfoArray[i] = ({
      data: displayInfoList[i], //画面表示情報
      jobSeekerIdForDisplay: displayInfoList[i].id.jobSeekerIdForDisplay,//displayInfoList[i].id.jobSeekerIdForDisplay, //表示用求職者ID
      nameKana: displayInfoList[i].nameKana, //カナ氏名
      collegeMasterDisplayOrder: displayInfoList[i].collegeMasterDisplayOrder, //大学マスタ表示順
      requestDate: displayInfoList[i].submissionRequests[0].requestPeriod.requestDate, //送信日時
    })
  }
  //表示順情報をソート
  switch (sortCondition.order) {
    case '0':
      //[0]応募者管理ID順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第2ソート：送信日時（降順）（降順：nullは一番下）
        if (a.requestDate === null) {
          return 1
        }
        if (b.requestDate === null) {
          return -1
        }
        if (a.requestDate < b.requestDate) {
          return 1
        }
        if (a.requestDate > b.requestDate) {
          return -1
        }
        //第4ソート：提出リクエストID（降順）
        if (a.submissionRequestId < b.submissionRequestId) {
          return 1
        }
        if (a.submissionRequestId > b.submissionRequestId) {
          return -1
        }
        return 0
      })
      break
    case '1':
      //[1]カナ氏名順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：カナ氏名（昇順）
        if (a.nameKana > b.nameKana) {
          return 1
        }
        if (a.nameKana < b.nameKana) {
          return -1
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：送信日時（降順）（降順：nullは一番下）
        if (a.requestDate === null) {
          return 1
        }
        if (b.requestDate === null) {
          return -1
        }
        if (a.requestDate < b.requestDate) {
          return 1
        }
        if (a.requestDate > b.requestDate) {
          return -1
        }
        //第4ソート：提出リクエストID（降順）
        if (a.submissionRequestId < b.submissionRequestId) {
          return 1
        }
        if (a.submissionRequestId > b.submissionRequestId) {
          return -1
        }
        return 0
      })
      break
    case '2':
      //[2]学校順
      displayOrderInfoArray.sort(function (a, b) {
        //第1ソート：大学マスタ表示順（昇順）
        if (a.collegeMasterDisplayOrder > b.collegeMasterDisplayOrder) {
          return 1
        }
        if (a.collegeMasterDisplayOrder < b.collegeMasterDisplayOrder) {
          return -1
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：送信日時（降順）（降順：nullは一番下）
        if (a.requestDate === null) {
          return 1
        }
        if (b.requestDate === null) {
          return -1
        }
        if (a.requestDate < b.requestDate) {
          return 1
        }
        if (a.requestDate > b.requestDate) {
          return -1
        }
        //第4ソート：提出リクエストID（降順）
        if (a.submissionRequestId < b.submissionRequestId) {
          return 1
        }
        if (a.submissionRequestId > b.submissionRequestId) {
          return -1
        }
        return 0
      })
      break
    default:
      console.log('不正なソートキー')
  }

  const result = []
  for (let i = 0; i < displayInfoList.length; i++) {
    result.push(displayOrderInfoArray[i].data)
  }
  return result
}
