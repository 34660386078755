import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getInit } from 'reducers/mcbMessageForcedStopReducer'
import { openModal } from 'reducers/messageReducer'
import { initRequest } from 'apis/MCBHS110Api'

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    yield call(initRequest, action.payload)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* messageInputSaga() {
  yield all([takeEvery(getInit, initSaga)])
}
