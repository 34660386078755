import { call, put, all, takeEvery } from 'redux-saga/effects'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import {
  editcontentconfirmationReducer,
  selectEntryInsList,
  setEntryInsList,
  editcontentconfirmationInsReducer,
  selectPostalCodeList,
  setPostalCodeList,
  editInsReducer,
  mCAXS640InitReducer,
  initInsReducer,
  mCAXS220selectPostalCodeList,
  setOutResult,
} from 'reducers/entryReducer'
import { editReducer, initReducer, setInitResult } from 'reducers/entryReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { initMCAXS220Request, AddressSelectRequest, insRequest } from 'apis/MCAXS220Api'
import { initRequest, checkRequest, autoInputRequest } from 'apis/MCAXS240Api'
import { magiContants } from 'utils/contants'
import { editRequest, mCAXS640InitRequest } from 'apis/MCAXS640Api'
import { registerRequest, insInitRequest } from 'apis/MCAXS630Api'
import { getMessage } from 'common/messageUtil'
import { initialValues } from 'pages/MCAXS240/formConfig'

//MCAXS220初期画面
function* selectEntryInsListSaga(
  action: ReturnType<typeof selectEntryInsList>
) {
  try {
    // 検索処理
    const data = yield call(initMCAXS220Request, action.payload)
    // 検索結果取得
    yield put(setEntryInsList(data))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}
// MCAXS240画面を初期表示する。
function* initOutDate(action: ReturnType<typeof setOutResult>) {
  // 検索結果
  yield put(setInitResult(initialValues))
}

function* initSaga(action: ReturnType<typeof initReducer>) {
  try {
    //初期検索
    const data = yield call(initRequest, action.payload)
    if (data.length === 0) {
      yield put(openModal(magiContants.MESSAGECODE_RESULT_NULL))
    } else {
      // 検索結果取得
      yield put(setEntryInsList(data))
      // 検索結果
      yield put(setInitResult(data))
      if (action.payload.callFlg === "1") {
        const path = {
          pathname: routeList.entryEdit,
          state: {
            recruitmentManagementDivision: action.payload.recruitmentManagementDivision,
            entryId: action.payload.entryId,
            callFlg: "0",
          },
        }
        history.push(path)
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS220"入力内容を確認する"ボタン押下時
function* confirmationInsSaga(action: ReturnType<typeof editInsReducer>) {
  try {
    const registerData = yield call(insRequest, action.payload.params1)
    if (registerData === 'Successed') {
        const editdata = yield call(insInitRequest)
        if (editdata === 'Successed') {
            const path = {
              pathname: routeList.entryEditConfimIns,
              state: action.payload,
            }
            history.push(path)
        }
    }   
  } catch (error) {
    yield put(openModal(error.message))
  }
}
//MCAXS240"入力内容を確認する"ボタン押下時
function* confirmationSaga(action: ReturnType<typeof editReducer>) {
  try {
    //MCAXS240"入力した項目の値をチェック
    const editdata = yield call(checkRequest, action.payload.params1)
    if (editdata === 'Successed') {
       const mCAXS640EntryInitRequest = {
        entryId: action.payload.params1.entryId
       }
       //MCAXS640 画面初期表示時
       const editConfirmationData = yield call(mCAXS640InitRequest, mCAXS640EntryInitRequest)
       if (editConfirmationData === 'Successed') {
          const path = {
              pathname: routeList.entryEditConfim,
              state: action.payload,
          }
       history.push(path)
     }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
//MCAXS220"郵便番号から自動入力する"ボタン押下時
function* mCAXS220AutoInputSaga(
  action: ReturnType<typeof selectPostalCodeList>
) {
  try {
    const editdata = yield call(AddressSelectRequest, {
      postalCode: action.payload,
    })
    // 住所選択モーダル情報の検索結果
    yield put(setPostalCodeList(editdata))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
//MCAXS240"郵便番号から自動入力する"ボタン押下時
function* autoInputSaga(action: ReturnType<typeof selectPostalCodeList>) {
  try {
    const editdata = yield call(autoInputRequest, {
      postalCode: action.payload,
    })
    // 住所選択モーダル情報の検索結果
    yield put(setPostalCodeList(editdata))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAXS630初期画面
function* insOkSaga(action: ReturnType<typeof initInsReducer>) {
  try {
    // 確認内容
    yield put(initInsReducer(action.payload))
  } catch (error) {
    // 異常
    yield put(openModal(error.message))
  }
}
// "登録する"ボタン押下時
function* editcontentconfirmationInsSaga(
  action: ReturnType<typeof editcontentconfirmationInsReducer>
) {
  try {
    const editConfimdata = yield call(registerRequest, action.payload.params1)
    let ids: string[] = []
    ids[0] = editConfimdata
    const path = {
      pathname: routeList.entryInsEdit,
      state: {
        handleFlg: '',
      },
    }
    history.push(path)
    yield put(
      openSnackbar(getMessage(magiContants.MESSAGECODE_MCAXS630_005, ids))
    )
  } catch (error) {
    if (error.message) {
      const path = {
        pathname: routeList.entryInsEdit,
        state: {
            recruitmentManagementDivision: action.payload.params2.recruitmentManagementDivision,
            handleFlg: '1',
        },
      }
      yield history.push(path)
  }
    yield put(openModal(error.message))
  }
}

// "更新する"ボタン押下時
function* editcontentconfirmationSaga(
  action: ReturnType<typeof editcontentconfirmationReducer>
) {
  try {
    yield call(editRequest, action.payload.params1)
        history.push(routeList.entryDetail)
    yield put(openSnackbar(getMessage(magiContants.EXCEPTIONCODE_MCAXS640_003)))
  } catch (error) {
    if (error.message) {
      const path = {
        pathname: routeList.entryEdit,
        state: {
            entryId: action.payload.params2.entryId,
            recruitmentManagementDivision: action.payload.params2.recruitmentManagementDivision,
            initFlag: '1',
        },
      }
      yield history.push(path)
  }
    yield put(openModal(error.message))
  }
}

export default function* entrySaga() {
  yield all([
    takeEvery(selectEntryInsList, selectEntryInsListSaga),
    takeEvery(initReducer, initSaga),
    takeEvery(editInsReducer, confirmationInsSaga),
    takeEvery(mCAXS640InitReducer as any, insOkSaga),
    takeEvery(editReducer, confirmationSaga),
    takeEvery(mCAXS220selectPostalCodeList, mCAXS220AutoInputSaga),
    takeEvery(selectPostalCodeList, autoInputSaga),
    takeEvery(
      editcontentconfirmationInsReducer,
      editcontentconfirmationInsSaga
    ),
    takeEvery(editcontentconfirmationReducer, editcontentconfirmationSaga),
    takeEvery(setOutResult, initOutDate),
  ])
}
