import request from 'utils/request'
import { MCAOS020CreateRequest } from 'types/MCAOS020/MCAOS020CreateRequest'

export const setInterviewApi = (interview: MCAOS020CreateRequest) =>
  request({
    url: '/MCAOS020/resister',
    method: 'post',
    data: interview,
  })
export const getMapUrl = (mapUrlCode: string) =>
  request({
    url: `/MCAOS020/getMap/${mapUrlCode}`,
    method: 'post',
  })
