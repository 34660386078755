//添付
const attachmentList = [
  { name: 'attachmentExist', label: 'あり', id: '1' },
  { name: 'attachmentNot', label: 'なし', id: '2' },
]
//検索対象
const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
]
//メッセージ種別
const messageTypeCheckList = [
  { name: 'messageSubmissionRequest', label: '提出リクエスト', id: '1' },
  { name: 'messageDeadlineChange', label: '締切変更', id: '2' },
  { name: 'messageForcedStop', label: '強制停止', id: '3' },
  { name: 'messageMyCareerBox', label: 'My CareerBoxメッセージ', id: '4' },
  { name: 'messageThanksMyCareerBox', label: 'サンクス', id: '5' },
]
//初期値
const initialValues = {
  //検索対象
  searchTargetList: '0',
  //検索文字
  searchWordText: '',
  //送信期間（FROM）
  sentFromDate: '',
  //送信期間（TO）
  sentToDate: '',
  //提出締切日（FROM）
  sentFromDateDeadline: '',
  //提出締切日（TO）
  sentToDateDeadline: '',
  //添付
  attachment: {
    //添付_あり
    attachmentExist: '0',
    //添付_なし
    attachmentNot: '0',
  },
  //一括送信のみ
  onlyBulkSend: '0',
  //ソート順
  sort: '0',
  //ページ数
  countPage: 1,
  //全件選択フラグ
  selectedFlag: 0,
  // 
  //メッセージ種別
  messageTypeFlag: {
    //メッセージ種別_提出リクエスト(チェック済)
    messageSubmissionRequest: '1',
    //メッセージ種別_締切変更(チェック済)
    messageDeadlineChange: '1',
    //メッセージ種別_強制停止(チェック済)
    messageForcedStop: '1',
    //メッセージ種別_My CareerBoxメッセージ(チェック済)
    messageMyCareerBox: '1',
    //メッセージ種別_サンクス(未チェック)
    messageThanksMyCareerBox: '0',
  },
}
//検索条件
const submitCondition = {
  //検索対象
  searchTargetList: '0',
  //検索文字
  searchWordText: '',
  //送信期間（FROM）
  sentFromDate: '',
  //送信期間（TO）
  sentToDate: '',
  //提出締切日（FROM）
  sentFromDateDeadline: '',
  //提出締切日（TO）
  sentToDateDeadline: '',
  //添付_あり
  attachmentExist: '0',
  //添付_なし
  attachmentNot: '0',
  //一括送信のみ
  onlyBulkSend: '0',
  //ソート順
  sort: '0',
  //ページ数
  countPage: 1,
  //全件選択フラグ
  selectedFlag: '0',
  //メッセージ種別_提出リクエスト
  messageSubmissionRequest: '1',
  //メッセージ種別_締切変更
  messageDeadlineChange: '1',
  //メッセージ種別_強制停止
  messageForcedStop: '1',
  //メッセージ種別_My CareerBoxメッセージ
  messageMyCareerBox: '1',
  //メッセージ種別_サンクス
  messageThanksMyCareerBox: '0',
  //メッセージ送信ID
  messageSendId: '',
  //sysバージョン番号
  sysVersionNumber: '',
  // 初回表示フラグ
  firstDispFlag:''
}

export {
  //初期値
  initialValues,
  //添付
  attachmentList,
  //検索条件
  submitCondition,
  //検索対象
  templateOptionList,
  //メッセージ種別
  messageTypeCheckList,
}
