import {
  Button,
  Card,
  CardActions,
  CardContent,
  colors,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { GroupOptions, Label, TextField, Toggle } from 'componentsHsc'
import { FastField, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Account } from 'reducers/accountReducer'
import { backToAccountPage } from 'utils/misc'
import { accountRequestValidation } from 'validations'
import { initialValues, itemList, textMap } from './formConfig'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  back: {
    margin: theme.spacing(0, 0, 3),
  },
  cancel: {
    color: (theme.palette as any).white,
    backgroundColor: 'darkgray',
  },
  formGroup: {
    padding: theme.spacing(1),
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#43a047',
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
}))

interface Props {
  account?: Account
  onSubmit: (account: Account) => void
  onCancel?: () => void
}

const AccountEdit = ({
  account,
  onSubmit,
  onCancel = backToAccountPage,
}: Props) => {
  const classes = useStyles()
  const [state, setState] = useState(account)
  const [showModal, setShowModal] = useState(false)
  const mode = Boolean(state) ? 'edit' : 'create'

  const handleModalOpen = (data: any) => {
    setState(data)
    setShowModal(true)
  }

  const handleModalClose = () => setShowModal(false)

  const handleCancel = () => {
    setShowModal(false)
    onCancel()
  }

  const handleCreate = () => {
    if (state) {
      onSubmit(state)
    }
    handleCancel()
  }

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        className={classes.back}
        onClick={handleCancel}>
        <ArrowBackIcon />
        一覧に戻る
      </Button>
      <Card>
        <Formik
          onSubmit={handleModalOpen}
          validationSchema={accountRequestValidation}
          initialValues={
            state
              ? { ...state, permissions: state.permissions.map(i => i.name) }
              : initialValues
          }
          render={() => (
            <Form>
              <CardContent>
                <Typography gutterBottom variant='h3'>
                  {textMap[mode].title}
                </Typography>
                {itemList(mode).map(i => (
                  <Grid
                    container
                    alignItems='center'
                    justify='space-around'
                    key={i.name}>
                    <Label>{i.label}</Label>
                    <Grid item xs={9} className={classes.formGroup}>
                      {i.name === 'status' ? (
                        <FastField key={i.name} {...i} component={Toggle} />
                      ) : i.name === 'permissions' ? (
                        <FastField
                          key={i.name}
                          {...i}
                          component={GroupOptions}
                        />
                      ) : (
                        <FastField key={i.name} {...i} component={TextField} />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </CardContent>
              <CardActions className={classes.buttonGroup}>
                <Button
                  type='submit'
                  variant='contained'
                  className={classes.confirmButton}>
                  {textMap[mode].submit}
                </Button>
                <Button
                  className={classes.cancel}
                  variant='contained'
                  onClick={handleCancel}>
                  キャンセルする
                </Button>
              </CardActions>
            </Form>
          )}
        />
      </Card>
      <Dialog onClose={handleModalClose} open={showModal}>
        <DialogTitle>{textMap[mode].message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleModalClose}>いいえ</Button>
          <Button onClick={handleCreate} color='primary'>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AccountEdit
