import { MCAXS611InitRequest } from 'types/MCAXS611/MCAXS611InitRequest'

/**
 * 学部・学科詳細指定モーダル_初期表示用データ格納情報
 */
export interface SchoolDepartmentDetailsInfo {
  undergraduateDepartmentDetailsList: UndergraduateDepartmentDetailInfo[] // 学部・学科詳細リスト
}

/** 学部・学科詳細リスト */
export interface UndergraduateDepartmentDetailInfo {
  [key: string]: any
  schoolTypeCode: string // 学校種別コード
  schoolTypeName: string // 学校種別名称
  collegeCode: string // 大学コード
  collegeName: string // 大学名称
  facultyCode: string // 学部コード
  facultyName: string // 学部名称
  subjectCode: string // 学科コード
  subjectName: string // 学科名称
  subjectInformationDagagaMasterId: string // 学科情報DAGAGAマスタID

}

export const initRequest: MCAXS611InitRequest = {
  schoolDetailsId: null, // 学校詳細ID
}
