import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
}

const ToggleAndOr = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const handleRadioCheck = (value: string) => {
    let result = undefined
    switch (name) {
      case 'mcbCategoryAndOrObj':
        result = form.values.mcbCategoryAndOrObj && form.values.mcbCategoryAndOrObj.value === value
        break
      case 'scoutCharacterConditionJudgment':
        result = form.values.scoutCharacterConditionJudgment && form.values.scoutCharacterConditionJudgment.value === value
        break
      // MCBリプレース MCAXS701 START  
      // 提出物内容(結合条件)
      case 'deliveryDetailConditionAndOrObj':
        result = form.values.deliveryDetailConditionAndOrObj && form.values.deliveryDetailConditionAndOrObj.value === value
        break
      // MCBリプレース MCAXS701 END    
      default:
        break
    }
    return result
  }
  const handleChange = (e: any) => {
    if (e.target.value !== undefined) {
      const option: Option = { value: e.target.value, label: e.target.name }
      form.setFieldValue(name, option)
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value} onClick={handleChange}>
        {optionList.map(i => (
          <FormControlLabel style={i.value === '1' ? { marginLeft: '36px', paddingBottom: '8px' } : { paddingBottom: '8px' }}
            key={i.value}
            control={
              <Radio
                color='primary'
                value={i.value}
                disabled={disabled}
                checked={handleRadioCheck(i.value)}
                name={i.label}
              />
            }
            label={i.label}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default ToggleAndOr
