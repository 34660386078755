import React from 'react'

interface Props {
  jobChange?: boolean
}

export default ({ jobChange = false }: Props) => {
  return (
    <header>
      <ul>
        <li>
          <a href='report.html' className='icon-header-report'>
            採用状況レポート
          </a>
        </li>
        <li>
          <a
            href='applicant-search.html'
            className='icon-header-management current'>
            応募管理
          </a>
        </li>
        <li>
          <a
            href='message-employment-inbox.html'
            className='icon-header-message'>
            メッセージ
          </a>
        </li>
        <li>
          <a
            href='scheduledList-employment.html'
            className='icon-header-scheduledList'>
            面接調整
          </a>
        </li>
      </ul>
      {jobChange ? (
        <b className='job-change'>
          <span>MIWS</span>中途採用
        </b>
      ) : (
        <b>
          <span>MIWS</span>新卒採用
        </b>
      )}
      <div>
        <b>株式会社マイナビ/山田 太郎さん</b>
        <a href='#' className='icon-logout' title='ログアウトする'>
          ログアウト
        </a>
        <a href='#' className='icon-notice' title='お知らせ'>
          お知らせ<i>10</i>
        </a>
        <a href='#' className='icon-help' title='マニュアル'>
          マニュアル
        </a>
        <a href='#' className='icon-settings' title='共通設定'>
          共通設定
        </a>
        <a href='#' className='icon-log' title='一括履歴操作'>
          一括履歴操作
        </a>
      </div>
    </header>
  )
}
