import { initRequest as initRequestForMCBLS020 } from 'apis/MCBLS020Api'
import { initRequest as initRequestForMCBLS030 } from 'apis/MCBLS030Api'
import { openModal } from 'reducers/messageReducer'
import {
  pdfMakingInit,
  getInitDataForDownload,
} from 'reducers/mcbPdfGeneratingReducer'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import history from 'utils/history'
import { routeList } from 'routes/routes'

// MCBLS030
function* pdfMakingInitSaga(action: ReturnType<typeof pdfMakingInit>) {
  try {
    const data = yield call(initRequestForMCBLS030, action.payload)
    const pathAndParam = {
      pathname: routeList.downloadMcbPdf,
      state: { ...data, s3Key: [data.s3Key, data.filename] },
    }
    history.push(pathAndParam)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBLS020
function* getInitDataForDownloadSaga(
  action: ReturnType<typeof getInitDataForDownload>
) {
  try {
    yield call(initRequestForMCBLS020, action.payload)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* selectionFlowSaga() {
  yield all([
    takeEvery(pdfMakingInit, pdfMakingInitSaga),
    takeEvery(getInitDataForDownload, getInitDataForDownloadSaga),
  ])
}
