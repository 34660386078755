import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAXS320InitRequest } from 'types/MCAXS320/MCAXS320InitRequest'
import { MCAXS320CancelRequest } from 'types/MCAXS320/MCAXS320CancelRequest'
import { MCAXS310InitRequest } from 'types/MCAXS310/MCAXS310InitRequest'
import { MCAXS310ReserveRequest } from 'types/MCAXS310/MCAXS310ReserveRequest'

export interface seminarCancellation {
    coordinationFormerSeminarId: string; // 連携元セミナーID
    seminarScheduleId: string // セミナー日程ID
    eventDate: string; // 開始日
    venueLocationName: string; // 開催地場所名
    startTime: string; // 開始時間
    endTime: string; // 終了時間
    seminarName: string; // セミナー名
    contractMediaName: string; // 応募経路名
    entryReceptionTime: string; // エントリー受付日時
    sysVersionNumber: string;  // sysバージョン番号
    seminarScheduleTitle: string; // セミナー日程タイトル
}

export interface MediaInfoRow {
  companyContractMediaId: number // 企業契約応募経路ID
  contractMediaId: number // 契約応募経路ID
  contractMediaName: string  // 契約応募経路名
}

export interface SeminarInfoRow {
  companyContractMediaId: number // 企業契約応募経路ID
  seminarId: number // セミナーID
  seminarName: string // セミナー名
  seminarScheduleId: number; //セミナー日程ID
  eventDate: string // 開始日
  venueLocationCode:string //開催地場所コード
  venueLocationName: string // 開催地場所名
  startTime: string // 開始時間
  endTime: string // 終了時間
  seminarScheduleTitle: string //セミナー日程タイトル
  coordinationFormerEntryBoxId: string // 連携元エントリーボックスID
  // フェーズ2改修
  eventDateText:string //開催日テキスト
  invisibleFlag:string //非表示フラグ
  publicFlag:string //非公開フラグ
}

export interface SeminarList {
  seminarScheduleId:number
  mediaInfo: MediaInfoRow[]
  seminarInfo: SeminarInfoRow[]
  contractMediaId: number 
  contractMediaName: string 
  seminarId: number 
  seminarName: string 
  pageDialogOpenFlag: boolean
  seminarCancellation: seminarCancellation
}

const initialState: SeminarList = {
  seminarScheduleId:-1,
  mediaInfo: [],
  seminarInfo:[],
  contractMediaId: 0, 
  contractMediaName: "", 
  seminarId: 0,
  seminarName: "",
  pageDialogOpenFlag:false,
  seminarCancellation:{
    coordinationFormerSeminarId:"",
    seminarScheduleId:"",
    eventDate:"",
    venueLocationName:"",
    startTime:"",
    endTime:"",
    seminarName:"",
    contractMediaName:"",
    entryReceptionTime:"",
    sysVersionNumber:"",
    seminarScheduleTitle:""
  }
}

const  SeminarCancellationSlice = createSlice({
    name: 'seminarCancellation',
    initialState,
    reducers: {
      //初期表示時
      fetchSeminarCancellation(state,action: PayloadAction<{request: MCAXS320InitRequest, handleModalOpen: (modalName: string) => void}>) {
        return state
      },
      setSeminarCancellation(state,action: PayloadAction<seminarCancellation>){
        state.seminarCancellation = action.payload
        return state;
      },
      cancleSeminarCancellation(
        state,
        action: PayloadAction<{
          request: MCAXS320CancelRequest
          onOk: () => void
      }>) {
        return state
      },
      //MCAXS310 初期表示時
      seminarInit(state,action:PayloadAction<MCAXS310InitRequest>) {
        return state
      },
      setSeminarInit(state, action:PayloadAction<SeminarList>) {
          state.seminarInfo=action.payload.seminarInfo
          state.mediaInfo=action.payload.mediaInfo
          state.contractMediaId=action.payload.contractMediaId
          state.contractMediaName=action.payload.contractMediaName
          state.seminarId = action.payload.seminarId
          state.seminarName = action.payload.seminarName
          return state;
      },
      seminarRegister(
        state,
        action: PayloadAction<{
          request: MCAXS310ReserveRequest
          onOk: () => void
      }>) {
        return state
      },
      setProps(state,action: PayloadAction<any>){
        state={...state,...action.payload}
        return state
      },
      setAddPageDialogOpen(state, action: PayloadAction<boolean>) {
        state.pageDialogOpenFlag = action.payload
        return state
      },
      setPageOpen(state, action: PayloadAction<boolean>) {
        state.pageDialogOpenFlag = action.payload
        return state
    },
    }
  })
export const {
    fetchSeminarCancellation,
    setSeminarCancellation,
    cancleSeminarCancellation,
    seminarInit,
    setSeminarInit,
    seminarRegister,
    setProps,
    setAddPageDialogOpen,
    setPageOpen,
} = SeminarCancellationSlice.actions

export default SeminarCancellationSlice.reducer