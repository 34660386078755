import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteValues } from '../pages/MCBLS010/formConfig'
import * as apiConfig from 'pages/MCBLS010/apiConfig'
import { MCBLS010SearchRequest } from 'types/MCBLS010/MCBLS010SearchRequest'
import { MCBLS010DeletePdfDownloadRecordRequest } from 'types/MCBLS010/MCBLS010DeletePdfDownloadRecordRequest'
import { MCBLS010InitResult } from 'types/MCBLS010/MCBLS010InitResult'
import { MCBHS190DetailRequest } from 'types/MCBHS190/MCBHS190DetailRequest'
import { MCBHS190CreateRequest } from 'types/MCBHS190/MCBHS190CreateRequest'
import { MCBHS190InitRequest } from 'types/MCBHS190/MCBHS190InitRequest'
import { MCBLS010ViewErrorCheckModalRequest } from 'types/MCBLS010/MCBLS010ViewErrorCheckModalRequest'
import { MCBLS010TransitionSubmissionRequestListRequest } from 'types/MCBLS010/MCBLS010TransitionSubmissionRequestListRequest'
import { MCBLS010PdfDownloadRequest } from 'types/MCBLS010/MCBLS010PdfDownloadRequest'
import { MCBLS040InitRequest } from 'types/MCBLS040/MCBLS040InitRequest'

  // 一覧画面の検索結果
  export interface PdfList {
    [key: string]: string | number | null
    outputTitle: string //タイトル
    submissionContentName1: string //出力提出物内容
    submissionContentName2: string //出力提出物内容
    submissionContentName3: string //出力提出物内容
    status: string //状態
    submissionCount: number //提出件数
    fullName: string //実行者
    executionDatetime: string //実行日時
    downloadDatetime: string //ダウンロード日時
    errorContent:string //エラー内容
    downloadFullpath:string //ダウンロード物格納パ
    submissionRequestId:number //提出リクエストID
    jobSeekerByCompanyId:number //企業別求職者ID  
    pdfDownloadManagementId:number //PDFダウンロード管理ID
  }
  // 提出件数結果
  export interface submissionNumberDataList {
    [key: string]: string | number | null
    jobSeekerByCompanyId: string //企業別求職者ID
    submissionRequestId: string //提出リクエストID
  }
  // MCBリプレース MCBHS190 start
  export interface pdfDownloadInfoList {
    //[key: string]: string | number
      initSearchList : MCBHS190InitSearchOutDto[]; //初期検索出力リスト
      submissionContentList : MCBHS190SubmissionContentDto[]; //提出物内容リスト
      submissionCountInteger : number; // PDF出力上限件数
  }
  
  export interface MCBHS190InitSearchOutDto {
    submissionRequestId : number; // 提出リクエストID
    jobSeekerId : number; // 求職者ID
    jobSeekerByCompanyId : number; // 企業別求職者ID
    submissionEntrySheetPdfFullpath : string; // 提出物エントリシートPDF格納パス
    submissionResumePdfFullpath : string; // 提出物履歴書PDF格納パス
    submissionResearchSummaryPdfFullpath : string; // 提出物研究概要書PDF格納パス
    submissionOriginalQuestionPdfFullpath : string; // 提出物オリジナル設問PDF格納パス
    submissionContentId: number; // 提出物内容ID
    submissionContentName : string; // 提出物内容名
    submissionContentNameStudent : string; // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag : string; // 提出物エントリシートフラグ
    submissionResumeFlag : string; // 提出物履歴書フラグ
    submissionResearchSummaryFlag : string; // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag : string; // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag : string; // 提出物ファイル提出フラグ
    mcbSubmissionRequestId : number; // MCB提出リクエストID
    myCareerCd : string; // MyCareerCD
  }
  
  export interface MCBHS190SubmissionContentDto {
    submissionContentId : number; // 提出物内容ID
    submissionContentName : string; // 提出物内容名
    submissionContentNameStudent : string; // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag : string; // 提出物エントリシートフラグ
    submissionResumeFlag : string;// 提出物履歴書フラグ
    submissionResearchSummaryFlag : string; // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag : string; // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag : string; // 提出物ファイル提出フラグ
    submissionCount : number; // 提出物数
    mcbSubmissionRequestId : number; // MCB提出リクエストID
    myCareerCd : string; // MyCareerCD
  }
  
  export interface mCBHS190DetailRequest {
    submissionRequestObj : MCBHS190SubmissionBox[]; // 提出リクエストリスト
  }
  
  export interface mCBHS190DetailInfoList {
    pdfDownloadDetailObj : MCBHS190SubmissionContentDto[]; // 提出リクエストリスト
  }
  
  export interface MCBHS190SubmissionBox {
    jobSeekerByCompanyId : string; // 企業別求職者ID
    submissionRequestId : string; // 提出リクエストID
    submissionContentId : string; // 提出物内容ID
    submissionContentName : string; // 提出物内容名
  }
  
  export interface mCBHS190CreateRequest {
    companyId : string; // 企業ID
    submissionRequestObj : MCBHS190SubmissionInDto[]; // 提出リクエスト
    submissionEntrySheetFlag : number; // 提出物エントリーシートフラグ
    submissionResumeFlag : number; // 提出物履歴書フラグ
    submissionResearchSummaryFlag : number; // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag : number; // 提出物オリジナル設問フラグ
    submissionFileSubmitFlag : number; // 提出物ファイル提出フラグ
    outputFormatFlag : number; // 出力形式フラグ
    fileNameFlag : number; // ファイル名形式フラグ
    createFolderClassification : number|undefined; // フォルダ作成区分
    outputTitle : string; // 出力タイトル
    submissionCount : number; // 提出数
    applicantSortKind : number; // 応募者ソート順
    selectJobSeekerIdList: number[]; // 求職者ID
    outputSubmissionContents : number[]; // 出力提出物内容id
  }
  
  export interface MCBHS190SubmissionInDto {
    submissionRequestId : string; // 提出リクエストID
    jobSeekerId : string; // 求職者ID
    jobSeekerByCompanyId : string; // 企業別求職者ID
    submissionEntrySheetPdfFullpath : string; // 提出物エントリシートPDF格納パス
    submissionResumePdfFullpath : string; // 提出物履歴書PDF格納パス
    submissionResearchSummaryPdfFullpath : string; // 提出物研究概要書PDF格納パス
    submissionOriginalQuestionPdfFullpath : string; // 提出物オリジナル設問PDF格納パス
    submissionContentId: string; // 提出物内容ID
    submissionContentName : string; // 提出物内容名
    submissionContentNameStudent : string; // 提出物内容名（学生画面表示用）
  }


  
  const mCBHS190SubmissionBoxValue : MCBHS190SubmissionBox ={
    jobSeekerByCompanyId : '',
    submissionRequestId : '',
    submissionContentId : '',
    submissionContentName : '',
  }
  
  const mCBHS190DetailRequestValue : MCBHS190DetailRequest ={
    submissionRequestObj : [],
  }
  
  const mCBHS190CreateRequestValue : MCBHS190CreateRequest ={
    companyId: '',
    submissionRequestObj: [],
    submissionEntrySheetFlag: 0,
    submissionResumeFlag: 0,
    submissionResearchSummaryFlag: 0,
    submissionOriginalQuestionFlag: 0,
    submissionFileSubmitFlag: 0,
    outputFormatFlag: 0,
    fileNameFlag: 0,
    createFolderClassification: 0,
    outputTitle: '',
    submissionCount: 0,
    applicantSortKind: 0,
    selectJobSeekerIdList: [],
    outputSubmissionContents: []
  }
  
  const pdfDownloadInfoListValue : pdfDownloadInfoList ={
    initSearchList : [],
    submissionContentList : [],
    submissionCountInteger: 0
  }
  
  const pdfDownloadDetailInfoListValue : mCBHS190DetailInfoList ={
    pdfDownloadDetailObj : []
  }
  
  const initSearchListValue: MCBHS190InitSearchOutDto = {
      submissionRequestId : 0,
      jobSeekerId : 0,
      jobSeekerByCompanyId : 0, 
      submissionEntrySheetPdfFullpath : '', 
      submissionResumePdfFullpath : '', 
      submissionResearchSummaryPdfFullpath : '', 
      submissionOriginalQuestionPdfFullpath : '', 
      submissionContentId: 0, 
      submissionContentName : '', 
      submissionContentNameStudent : '', 
      submissionEntrySheetFlag : '',
      submissionResumeFlag : '', 
      submissionResearchSummaryFlag : '', 
      submissionOriginalQuestionFlag : '',
      submissionFileSubmissionFlag : '', 
      mcbSubmissionRequestId : 0,
      myCareerCd : ''
  }
  
  
  
  const submissionContentListValue: MCBHS190SubmissionContentDto = {
      submissionContentId : 0,
      submissionContentName : '', 
      submissionContentNameStudent : '',
      submissionEntrySheetFlag : '', 
      submissionResumeFlag : '',
      submissionResearchSummaryFlag : '', 
      submissionOriginalQuestionFlag : '',
      submissionFileSubmissionFlag : '',
      submissionCount : 0,
      mcbSubmissionRequestId : 0,
      myCareerCd : ''
  }
  
  const mCBHS190SubmissionInDtoValue : MCBHS190SubmissionInDto = {
      submissionRequestId : '',
      jobSeekerId : '',
      jobSeekerByCompanyId : '',
      submissionEntrySheetPdfFullpath : '',
      submissionResumePdfFullpath : '',
      submissionResearchSummaryPdfFullpath : '',
      submissionOriginalQuestionPdfFullpath : '',
      submissionContentId: '',
      submissionContentName : '',
      submissionContentNameStudent : '',
  }
  

  // MCBリプレース MCBHS190 end
  // MCBLS040初期表示
  const mCBLS040InitRequestValue : MCBLS040InitRequest = {
    targetNumber : '',
}

  interface mcbPdfDownload {
    // 検索条件
    pdfManagementListSearchCondition: MCBLS010SearchRequest
    // 削除条件
    pdfManagementDeleteCondition: MCBLS010DeletePdfDownloadRecordRequest
    // 検索結果
    pdfManagementListResults: PdfList[]
    // ステータス結果
    errorDataListResults: string
    // 削除結果
    deleteIdRequestCondition: string
    // チェック結果
    checkLogOutFlg: boolean
    // 提出件数結果
    submissionNumberData: submissionNumberDataList[]
    // 初期表示
    pdfManagementInit: MCBLS010SearchRequest
    // 初期表示結果
    pdfManagementInitResult: MCBLS010InitResult
    // タグを指定する画面の表示フラグ
    show: boolean
    messageFlagResult: MessageFlag
    // MCBHS190初期検索結果
    PdfDownloadInfoListResults: pdfDownloadInfoList
    // MCBHS190もっと見る条件
    PdfDownloadDetailCondition : mCBHS190DetailRequest
    // MCBHS190もっと見る結果
    PdfDownloadDetailInfoListResults: string
    // MCBHS190ファイルを作成する条件
    PdfDownloadCreateCondition : mCBHS190CreateRequest
    // MCBHS190オープンフラグ
    MCBHS190OpenFlg: boolean
    // MCBLS040オープンフラグ
    MCBLS040OpenFlg: boolean
    // MCBLS040初期表示条件
    MCBLS040InitCondition: MCBLS040InitRequest
    // MCBLS040初期表示結果
    MCBLS040InitResults: string
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
    PdfCount: number
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
  }

export interface MessageFlag {
  [key: string]: number
  messageFlagVal: number
}

const messageFlag = {
  messageFlagVal: -1
}

const initialState: mcbPdfDownload = {
  pdfManagementListResults: [],
  errorDataListResults: '',
  deleteIdRequestCondition: '',
  checkLogOutFlg: false,
  pdfManagementInit: apiConfig.initRequest,
  pdfManagementInitResult: apiConfig.initResult,
  submissionNumberData: [],
  show: false,
  messageFlagResult: messageFlag,
  pdfManagementListSearchCondition: apiConfig.initRequest,
  pdfManagementDeleteCondition: deleteValues,
  // MCBリプレース MCBHS190 start
  PdfDownloadInfoListResults: pdfDownloadInfoListValue,
  PdfDownloadDetailCondition: mCBHS190DetailRequestValue,
  PdfDownloadDetailInfoListResults: '',
  PdfDownloadCreateCondition: mCBHS190CreateRequestValue,
  MCBHS190OpenFlg: false,
  // MCBリプレース MCBHS190 end
  MCBLS040InitResults: '',
  MCBLS040InitCondition: mCBLS040InitRequestValue,
  MCBLS040OpenFlg: false,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
  PdfCount: 0,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
}

const pdfManagementSlice = createSlice({
  name: 'mcbPdfManagement',
    initialState,
  reducers: {
    // "検索する"ボタン押下時にDBを検索
    selectPdfSubmissionList(
      state,
      action: PayloadAction<MCBLS010SearchRequest>
    ) {
      return state
    },
    setPdfSubmissionList(state, action: PayloadAction<PdfList[]>) {
      state.pdfManagementListResults = action.payload
      return state
    },
    // ステータス結果
    setErrorDataList(state, action: PayloadAction<string>) {
      state.errorDataListResults = action.payload
      return state
    },
    setErrorconfirmationClick(state, action: PayloadAction<MCBLS010ViewErrorCheckModalRequest>) {
      return state
    },
    setLinkClickSubmission(state, action: PayloadAction<MCBLS010TransitionSubmissionRequestListRequest>) {
      return state
    },
    // "検索する"ボタン押下時に検索条件を保持
    setPdfSearchCondition(
      state,
      action: PayloadAction<MCBLS010SearchRequest>
    ) {
      return state
    },
    setPdfManagementListSearchCondition(
      state,
      action: PayloadAction<MCBLS010SearchRequest>
    ) {
      state.pdfManagementListSearchCondition = action.payload
      return state
    },
    // "削除"ボタン押下時にDBを更新
    deletePdfManagement(state, action: PayloadAction<MCBLS010DeletePdfDownloadRecordRequest>) {
      return state
    },
    deletePdfManagementFlg(state, action: PayloadAction<string>) {
      state.deleteIdRequestCondition = action.payload
      return state
    },
    deletePdfManagementCheck(state, action: PayloadAction<boolean>) {
      state.checkLogOutFlg = action.payload
      return state
    },
    getinitPdf(state, action: PayloadAction<MCBLS010SearchRequest>) {
      state.pdfManagementInit = action.payload
      return state
    },
    setinitPdf(state, action: PayloadAction<MCBLS010InitResult>) {
      state.pdfManagementInitResult = action.payload
      return state
    },

    setPdfDownloadShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload
      return state
    },
    // MCBリプレース MCBHS190 start
    // 画面初期表示時、DBを検索
    selectInitInfo(
      state,
      action: PayloadAction<MCBHS190InitRequest>
    ) {
      return state
    },
    selectInitResult(state, action: PayloadAction<pdfDownloadInfoList>) {
      state.PdfDownloadInfoListResults = action.payload
      return state
    },
    clickLookDetailListInfo(state, action: PayloadAction<MCBHS190DetailRequest & {lookDetail: (value: React.SetStateAction<boolean>) => void}>) {
      state.PdfDownloadDetailCondition = action.payload
      return state
    },
    clickLookDetailListResult(state, action: PayloadAction<string>) {
      state.PdfDownloadDetailInfoListResults = action.payload
      return state
    },
    creatListInfo(state, action: PayloadAction<MCBHS190CreateRequest>) {
      state.PdfDownloadCreateCondition = action.payload
      return state
    },
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
    pdfCount(state,action:PayloadAction<number>){
      state.PdfCount = action.payload
      return state
    },
    // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
    // MCBHS190オープンフラグ設定
    setMCBHS190OpenFlg(state, action: PayloadAction<boolean>) {
      state.MCBHS190OpenFlg = action.payload
      return state
    },
    // MCBリプレース MCBHS190 end
    // MCBリプレース MCBLS040 start
    setMCBLS040OpenFlg(state, action: PayloadAction<boolean>) {
      state.MCBLS040OpenFlg = action.payload
      return state
    },
    setMCBLS040InitInfo(state, action: PayloadAction<MCBLS040InitRequest>) {
      return state
    },
    setMCBLS040CloseInfo(state, action: PayloadAction<boolean>) {
      return state
    },
    // MCBリプレース MCBLS040 end
  },
})

export const {
  selectPdfSubmissionList,
  setPdfSubmissionList,
  setErrorDataList,
  setErrorconfirmationClick,
  setLinkClickSubmission,
  setPdfSearchCondition,
  setPdfManagementListSearchCondition,
  deletePdfManagement,
  deletePdfManagementFlg,
  deletePdfManagementCheck,
  getinitPdf,
  setinitPdf,
  setPdfDownloadShow,
  // MCBリプレース MCBHS190 start
  selectInitInfo,
  selectInitResult,
  clickLookDetailListInfo,
  clickLookDetailListResult,
  creatListInfo,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）start
  pdfCount,
  // #8371 MCBHS190_PDF出力対象選択（My CareerBox）end
  setMCBHS190OpenFlg,
  // MCBリプレース MCBHS190 end
  setMCBLS040CloseInfo,
  setMCBLS040InitInfo,
  setMCBLS040OpenFlg,
} = pdfManagementSlice.actions

export default pdfManagementSlice.reducer
