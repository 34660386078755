import { Chip, FormControlLabel, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldGroup, FormField, SubTitle, Title } from 'componentsHsc'
import { TextFieldForMessage } from 'components'
import { FastField, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from './GroupOptions'
import { SelectOptions } from './Select'
import { FastSelectOptions } from 'componentsHsc/AdvancedSearch/FastSelect'
import { ManagementItemCondition, TestObj } from './Config/searchConditionConfig'
import {
  setManagementModalStateJobChange,
  getMcaxs501Init,
} from 'reducers/itemSpecificationReducer'
import { setManagementModalOpen } from 'reducers/advancedSearchJobChangeReducer'
import { setSearchCondition } from 'reducers/entryListReducer'
import { toEntryList } from './Config/searchConditionConfig'
import { getMessage } from 'common/messageUtil'
import { dateToString, isEmpty } from 'common/generalUtil'
import Modal from './Modal/Modal'
import {
  opqObject,
  opqTitles,
  gabObject,
  gabTitles,
  imagesObject,
  imagesTitles,
  cabObject,
  cabTitles,
} from '../AdvancedSearch/Config/modalConfig'
import CancelIcon from '@material-ui/icons/Cancel'
import { magiContants } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  threeColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    maxWidth: '295px',
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  tag: {
    paddingLeft: 10,
    marginRight: 80,
  },
  datePickerDiv: {
    width: '130px',
    float: 'left'
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: theme.spacing(1.625, 0.625, 0, 1)
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '130px',
    top: '6px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '130px',
    marginTop: '6px',
  },
  cursor: {
    '& div': {
      cursor: 'pointer!important',
    }
  },
  tagSelectAllStyle: {
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexWrap: 'wrap'
    },
  },
  frequentlyUsedTagRow: {
    margin: '-16px 80px 16px 80px !important',
  },
}))

interface Props {
  form: FormControl
}

// #58578 次期開発2022年5月 start
const generateLabelModal = (input: TestObj) => {
  if (input.physicalName.toLowerCase().includes('exam_language')) {
    const valueFrom = input.valueFrom
    return `${input.logicalName} ${valueFrom}`
  } else {
    const valueFrom = input.physicalName.includes('Date') || input.physicalName.includes('date')
      ? isEmpty(input.valueFrom)
        ? ''
        : dateToString(new Date(input.valueFrom))
      : input.valueFrom
    const valueTo = input.physicalName.includes('Date') || input.physicalName.includes('date')
      ? isEmpty(input.valueTo)
        ? ''
        : dateToString(new Date(input.valueTo))
      : input.valueTo

    const from = valueFrom
      ? valueTo
        ? valueFrom === valueTo
          ? `${valueFrom}`
          : `${valueFrom} ～ `
        : `${valueFrom} ～`
      : ''
    const to = valueTo ? (valueFrom ? valueFrom === valueTo ? '' : valueTo : `～ ${valueTo}`) : ''

    const logicalName = '' === from && '' === to ? '' : input.logicalName
    return `${logicalName} ${from}${to}`
  }
}
const modalData = {
  OPQ情報: { object: opqObject, title: opqTitles },
  GAB情報: { object: gabObject, title: gabTitles },
  イメジス情報: { object: imagesObject, title: imagesTitles },
  CAB情報: { object: cabObject, title: cabTitles },
}
const nameSet = {
  OPQ情報: 'opqObj',
  GAB情報: 'gabObj',
  イメジス情報: 'imagesObj',
  CAB情報: 'cabObj',
}
type SHLTitle = keyof typeof modalData
// #58578 次期開発2022年5月 end

const generateLabel = (item: ManagementItemCondition) => {
  let valueText: string = ''
  const itemText: string = item.recruitmentManagementFlagName

  if (item.managementItemPresenceFlag === '1') {
    valueText = '値がない'
  } else {
    switch (item.managementItemTypeCode) {
      case '10':
        if (item.managementItemTagText === '') {
          valueText = '値がある全て'
        } else {
          valueText = `[${item.managementItemTagText}]が含まれる`
        }
        break
      case '20':
        if (
          item.managementItemTagFrom === '' &&
          item.managementItemFlagTo === ''
        ) {
          valueText = '値がある全て'
        } else if (item.managementItemTagFrom === item.managementItemFlagTo) {
          valueText = item.managementItemTagFrom
        } else if (item.managementItemTagFrom !== item.managementItemFlagTo) {
          valueText = `${item.managementItemTagFrom}～${item.managementItemFlagTo}`
        }
        break
      case '30':
        if (item.managementItemFlagCheckBoxObj.length === 0) {
          valueText = '値がある全て'
        } else {
          const valueTextList = item.managementItemFlagCheckBoxObj.map(
            i => i.label
          )
          valueText = valueTextList.join('、')
        }
        if (item.managementItemPresenceFlag === '2') {
          valueText = valueText + ' を除く'
        }
        break
      default:
        break
    }
  }

  return `${itemText} ${valueText}`
}

// 採用管理情報
const RecruitmentManagement = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const invisibleRecruitmentManagementFlagSettingIdList = useSelector(
    (state: RootState) =>
      state.itemSpecification.invisibleRecruitmentManagementFlagSettingIdList
  )

  // 転職March start
  const {
    serviceMode,
    marchServiceFlag
  } = useSelector((state: RootState) => state.globalMenu)
  // 転職March end

  const [tagExclusionCondition, setTagExclusionCondition] = useState('1') // 判定条件（タグ除外） ANR/OR
  const [tagCondition, setTagCondition] = useState('1') // 判定条件（タグ） ANR/OR
  const handleTagExclusionCondition = (e: any) => {
    const tagExclusionConditionObj = e.target.value === tagExclusionCondition ? '' : e.target.value
    setTagExclusionCondition(tagExclusionConditionObj)
  }
  const { conditionList = [], typeName = '' } =
    form.values.managementItemObj || {}

  // 初期表示データ設定
  const {
    selectionFlowList,
    selectionStepList,
    judgementList,
    tagList,
    frequentlyUsedTagList,
    displayShlInfo   // #58578 次期開発2022年5月 
  } = useSelector(
    (state: RootState) =>
      state.advancedSearchJobChange.recruitmentManagementInfo
  )

  const selectionFlowOptionList = selectionFlowList != null ? selectionFlowList.map(i => {
    return { label: i.selectionName, value: i.selectionFlowSettingId }
  }) : []

  const selectionStepOptionList = selectionStepList != null ? selectionStepList.map(i => {
    return { label: i.progressName, value: i.progressStatusSettingId }
  }) : []

  const judgementOptionList = judgementList.map(i => {
    return { label: i.itemName, value: i.itemCode }
  })

  const tagOptionList = tagList.map(i => {
    return { label: i.tagName, value: String(i.tagSettingId) }
  })

  // タグ除外
  const tagExclusionOptionList = tagList.map(i => {
    return { label: i.tagName, value: String(i.tagSettingId) }
  })
  const frequentlyUsedTagOptionList = frequentlyUsedTagList.map(i => {
    return { label: i.tagName, key: String(i.tagSettingId) }
  })
  // よく使われるタグ除外
  const frequentlyUsedTagExclusionOptionList = frequentlyUsedTagList.map(i => {
    return { label: i.tagName, key: String(i.tagSettingId) }
  })

  /**
   * よく使われるタグクリック
   */
  const tagClick = (data: any) => (e: any) => {
    const newTag = { label: data.label, value: data.key }
    const newTagDate = form.values['tagTxtObj']
    if (newTagDate) {
      //タグが入力されている場合
      if (!newTagDate.map(tag => tag.value).includes(newTag.value)) {
        //重複が無ければ選択したタグを追加
        newTagDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagTxtObj', newTagDate)
      } else {
        //重複があれば何もしない
        return
      }
    } else {
      //タグが未入力の場合、タグを追加
      form.setFieldValue('tagTxtObj', [{ label: data.label, value: data.key }])
    }
  }

  /**
   * よく使われるタグ除外クリック
   */
  const tagExclusionClick = (data: any) => (e: any) => {
    const newTagExclusion = { label: data.label, value: data.key }
    const newTagExclusionDate = form.values['tagExclusionTxtObj']
    if (newTagExclusionDate) {
      //タグ除外が入力されている場合
      if (!newTagExclusionDate.map(tagExclusion => tagExclusion.value).includes(newTagExclusion.value)) {
        //重複が無ければ選択したタグ除外を追加
        newTagExclusionDate.push({ label: data.label, value: data.key })
        form.setFieldValue('tagExclusionTxtObj', newTagExclusionDate)
      } else {
        //重複があれば何もしない
        return
      }
    } else {
      //タグ除外が未入力の場合、タグを追加
      form.setFieldValue('tagExclusionTxtObj', [{ label: data.label, value: data.key }])
    }
  }

  useEffect(() => {
    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: true,
        isJobChange: true,
        toggle: () => { },
      })
    )
    // 検索一覧に戻る_預け入れタグ除外条件判定FLGを取得します
    if (!isEmpty(form.values['tagExclusionJudgmentFlag'])) {
      setTagExclusionCondition(String(form.values['tagExclusionJudgmentFlag']))
    }
    // 検索一覧に戻る_預け入れタグ条件判定FLGを取得します
    if (!isEmpty(form.values['tagJudgmentFlag'])) {
      setTagCondition(String(form.values['tagJudgmentFlag']))
    }
  }, [])

  //タグ除外条件判定FLG と タグ除外条件判定名_(タグ除外とタグ除外条件判定FLGの変化をモニターします)
  useEffect(() => {
    if (form.values['tagExclusionTxtObj'] != null && form.values['tagExclusionTxtObj'].length > 0) {
      form.setFieldValue('tagExclusionJudgmentFlag', tagExclusionCondition)
      form.setFieldValue('tagExclusionJudgmentName', tagExclusionCondition === '0' ? '全てと一致' : 'いずれかに一致')
    }
  }, [form.values['tagExclusionTxtObj'], tagExclusionCondition])

  //タグ条件判定FLG と タグ条件判定名_(タグとタグ条件判定FLGの変化をモニターします)
  useEffect(() => {
    if (form.values['tagTxtObj'] != null && form.values['tagTxtObj'].length > 0) {
      form.setFieldValue('tagJudgmentFlag', tagCondition)
      form.setFieldValue('tagJudgmentName', tagCondition === '0' ? '全てと一致' : 'いずれかに一致')
    }
  }, [form.values['tagTxtObj'], tagCondition])

  const managementModalState = useSelector(
    (state: RootState) => state.itemSpecification.managementModalStateJobChange
  )
  const handleManagementSettingClick = () => {
    let isInitManagementItemObj = true
    if (
      managementModalState.conditionList &&
      managementModalState.conditionList.length > 0
    )
      if (form.values.managementItemObj) {
        isInitManagementItemObj = false
        const newManagementModalState = {
          ...managementModalState,
        }
        const managementItemObj = form.values.managementItemObj
        newManagementModalState.typeCode = managementItemObj.typeCode
        const newCond: ManagementItemCondition[] = []
        if (newManagementModalState.conditionList) {
          newManagementModalState.conditionList.forEach(i => {
            const findResult = managementItemObj.conditionList.find(
              itemObjCondition =>
                itemObjCondition.recruitmentManagementFlagSettingId ===
                i.recruitmentManagementFlagSettingId
            )
            if (findResult) {
              newCond.push(findResult)
            } else {
              newCond.push({
                ...i,
                managementItemPresenceFlag: '',
                managementItemTagText: '',
                managementItemTagFrom: '',
                managementItemFlagTo: '',
                managementItemFlagCheckBoxObj: [],
              })
            }
          })
        }

        newManagementModalState.conditionList = newCond
        dispatch(setManagementModalStateJobChange(newManagementModalState))
      }

    dispatch(
      getMcaxs501Init({
        isInitManagementItemObj: isInitManagementItemObj,
        isJobChange: true,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setManagementModalOpen(true))
        },
      })
    )
  }

  const handleChipDelete = (
    conditionList: ManagementItemCondition[],
    index: number
  ) => {
    const newConditionList = conditionList.slice()
    newConditionList.splice(index, 1)
    if (newConditionList.length > 0) {
      form.setFieldValue('managementItemObj.conditionList', newConditionList)
    } else {
      form.setFieldValue('managementItemObj', null)
    }
  }

  // AND/OR状態を取得
  const handleTagCondition = (e: any) => {
    const tagConditionObj = e.target.value === tagCondition ? '' : e.target.value
    setTagCondition(tagConditionObj)
  }

  const optionSet = {
    selectionStepObj: selectionStepOptionList,
    judgmentUndeterminedObj: judgementOptionList
  }

  const handleSelectAll = (name: keyof typeof optionSet) => (e: any) => {
    form.setFieldValue(name, e.target.checked ? optionSet[name] : [])
  }

  const isChecked = (name: keyof typeof optionSet) => {
    const newValue = form.values[name]
    if (newValue && newValue.length === optionSet[name].length) {
      return true
    }

    return false
  }

  // 全て選択（タグ）
  const tagHandleSelectAll = (tagAll: {
    label: string,
    value: string
  }[]) => (e: any) => {
    form.setFieldValue('tagTxtObj', e.target.checked ? tagAll : [])
  }

  // 全て選択（タグ） 状態
  const tagIsChecked = () => {
    const newValue = form.values['tagTxtObj']
    if (newValue && newValue.length === tagOptionList.length && newValue.length > 0) {
      return true
    }
    return false
  }

  const handleTagExclusionSelectAll = (tagExclusionSelectAll: {
    label: string,
    value: string
  }[]) => (e: any) => {
    form.setFieldValue('tagExclusionTxtObj', e.target.checked ? tagExclusionSelectAll : [])
  }

  const tagExclusionChecked = () => {
    const newValue = form.values['tagExclusionTxtObj']
    if (newValue && newValue.length === tagOptionList.length && newValue.length > 0) {
      return true
    }
    return false
  }

  // #58578 次期開発2022年5月 start
  const OPQSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.OPQSubmitFlag)
  const GABSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.GABSubmitFlag)
  const ImagesSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.ImagesSubmitFlag)
  const CABSubmitFlag = useSelector((state: RootState) => state.advancedSearchModal.CABSubmitFlag)

  const OPQModalObj = useSelector((state: RootState) => state.advancedSearchModal.OPQModalObj)
  const GABModalObj = useSelector((state: RootState) => state.advancedSearchModal.GABModalObj)
  const ImagesModalObj = useSelector((state: RootState) => state.advancedSearchModal.ImagesModalObj)
  const CABModalObj = useSelector((state: RootState) => state.advancedSearchModal.CABModalObj)

  const opqObj = OPQSubmitFlag ? form.values.opqObj || [] : OPQModalObj || []
  const gabObj = GABSubmitFlag ? form.values.gabObj || [] : GABModalObj || []
  const imagesObj = ImagesSubmitFlag ? form.values.imagesObj || [] : ImagesModalObj || []
  const cabObj = CABSubmitFlag ? form.values.cabObj || [] : CABModalObj || []

  const handleDeleteOPQObj = (option: TestObj) => () => {
    if (opqObj) {
      const deleteNumber = opqObj.indexOf(option)
      if (deleteNumber >= 0) {
        opqObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = opqObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('opqObj', newConditionList)
      } else {
        form.setFieldValue('opqObj', null)
      }
    }
  }
  const handleDeleteGABObj = (option: TestObj) => () => {
    if (gabObj) {
      const deleteNumber = gabObj.indexOf(option)
      if (deleteNumber >= 0) {
        gabObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = gabObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('gabObj', newConditionList)
      } else {
        form.setFieldValue('gabObj', null)
      }
    }
  }
  const handleDeleteImages = (option: TestObj) => () => {
    if (imagesObj) {
      const deleteNumber = imagesObj.indexOf(option)
      if (deleteNumber >= 0) {
        imagesObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = imagesObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('imagesObj', newConditionList)
      } else {
        form.setFieldValue('imagesObj', null)
      }
    }
  }
  const handleDeleteCABObj = (option: TestObj) => () => {
    if (cabObj) {
      const deleteNumber = cabObj.indexOf(option)
      if (deleteNumber >= 0) {
        cabObj[deleteNumber] = {
          logicalName: '',
          physicalName: '',
          valueFrom: '',
          valueTo: '',
        }
      }
      const newConditionList = cabObj
      if (newConditionList && newConditionList.length > 0) {
        form.setFieldValue('cabObj', newConditionList)
      } else {
        form.setFieldValue('cabObj', null)
      }
    }
  }
  // Modal
  const [state, setState] = useState({
    general: false,
    management: false,
  })
  const [currentModal, setCurrentModal] = useState<SHLTitle>('OPQ情報')
  //適性検査モーダル:検索分類選択state
  const [selectedSubtitle, setSelectedSubtitle] = useState(
    modalData[currentModal].title[0]
  )
  const handleClick = (title: SHLTitle) => () => {
    setSelectedSubtitle(modalData[currentModal].title[0])
    setCurrentModal(title)
    setState({ ...state, general: true })
  }
  const handleModal = (modalName: string, open = true) => () => {
    setState({
      ...state,
      [modalName]: open,
    })
  }
  // #58578 次期開発2022年5月 end

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>選考フロー/選考ステップ</SubTitle>
        <FormField>
          <Title title='選考フロー' className={classes.title} />
          <FastField
            name='selectionFlowObj'
            optionList={selectionFlowOptionList}
            defaultValue={form.values.selectionFlowObj}
            placeholder='選択してください。'
            component={SelectOptions}
            className={classes.cursor}
            isClearable
          />
        </FormField>
        {/* 転職March start */}
        <FormField helperText={serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1 ? getMessage('MCAXS431-003') :getMessage('MCAXS431-002')}>
        {/* 転職March end */}
          <Title
            title='選考ステップ'
            className={classes.title}
            onSelectAll={selectionStepOptionList.length > 0 ? handleSelectAll('selectionStepObj') : undefined}
            checked={isChecked('selectionStepObj')} />
          <FastField
            name='selectionStepObj'
            component={GroupOptions}
            row
            optionList={selectionStepOptionList}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS431-001')}>
          <Title
            title='合否'
            className={classes.title}
            onSelectAll={handleSelectAll('judgmentUndeterminedObj')}
            checked={isChecked('judgmentUndeterminedObj')} />
          <FastField
            name='judgmentUndeterminedObj'
            component={GroupOptions}
            row
            optionList={judgementOptionList}
          />
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>優先度</SubTitle>
        <FormField>
          <Title title='管理項目' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button onClick={handleManagementSettingClick} />
            {conditionList.length > 0 && (
              <div
                className={
                  window.navigator.userAgent
                    .toLocaleLowerCase()
                    .match(/(trident)\/([\d.]+)/)
                    ? classes.andOrStyleIE
                    : classes.andOrStyleOther
                }>
                {conditionList.filter(i =>
                  invisibleRecruitmentManagementFlagSettingIdList.includes(
                    i.recruitmentManagementFlagSettingId
                  )
                ).length === conditionList.length
                  ? undefined
                  : typeName}
              </div>
            )}
            {conditionList.length > 0 && (
              <span>
                {conditionList.map((item, index) =>
                  invisibleRecruitmentManagementFlagSettingIdList.includes(
                    item.recruitmentManagementFlagSettingId
                  ) ? (
                    undefined
                  ) : (
                    <Chip
                      title={generateLabel(item)}
                      className={classes.chip}
                      key={index}
                      label={generateLabel(item)}
                      onDelete={() => handleChipDelete(conditionList, index)}
                    />
                  )
                )}
              </span>
            )}
          </div>
        </FormField>
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='優先度' className={classes.title} />
          <div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='priorityFrom'
                component={TextFieldForMessage}
                placeholder='半角数字' /></div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='priorityTo'
                component={TextFieldForMessage}
                placeholder='半角数字' /></div>
          </div>
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='タグ' className={classes.title} />
          <div>
            <Field
              name='tagTxtObj'
              isMulti
              component={FastSelectOptions}
              className={classes.cursor}
              defaultValue={form.values.tagTxtObj}
              optionList={tagOptionList}
              noOptionsMessage='一致する候補が見つかりません'
              placeholder='キーワードを入力'
            />
            <div style={{ marginLeft: '10px' }}>
              {/* AND/OR */}
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='tagCondition'
                    onChange={handleTagCondition}
                    checked={tagCondition === '0'}
                  />
                }
                label='AND'
              />
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='1'
                    name='tagCondition'
                    onChange={handleTagCondition}
                    checked={tagCondition === '1'}
                  />
                }
                label='OR'
              />
            </div>
          </div>
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField className={classes.frequentlyUsedTagRow}>
          <div className={classes.tagSelectAllStyle}>
            <Title title='よく使われるタグ：'
              className={classes.tag}
              onSelectAll={tagHandleSelectAll(tagOptionList)}
              checked={tagIsChecked()}
            />
          </div>
          <div style={{ marginLeft: '-10px' }}>
            {frequentlyUsedTagOptionList.map(data => (
              <Chip
                key={data.key}
                onClick={tagClick(data)}
                label={data.label}
                className={classes.chip}
              />
            ))}
          </div>
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='タグ（除外）' className={classes.title} />
          <div>
            <Field
              name='tagExclusionTxtObj'
              isMulti
              component={FastSelectOptions}
              className={classes.cursor}
              defaultValue={form.values.tagExclusionTxtObj}
              optionList={tagExclusionOptionList}
              noOptionsMessage='一致する候補が見つかりません'
              placeholder='キーワードを入力'
            />
            <div style={{ marginLeft: '10px' }}>
              {/* AND/OR */}
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='0'
                    name='tagExclusionCondition'
                    onChange={handleTagExclusionCondition}
                    checked={tagExclusionCondition === '0'}
                  />
                }
                label='AND'
              />
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    value='1'
                    name='tagExclusionCondition'
                    onChange={handleTagExclusionCondition}
                    checked={tagExclusionCondition === '1'}
                  />
                }
                label='OR'
              />
            </div>
          </div>
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField className={classes.frequentlyUsedTagRow}>
          <div className={classes.tagSelectAllStyle}>
            <Title title='よく使われるタグ：'
              className={classes.tag}
              onSelectAll={handleTagExclusionSelectAll(tagExclusionOptionList)}
              checked={tagExclusionChecked()}
            />
          </div>
          <div style={{ marginLeft: '-10px' }}>
            {frequentlyUsedTagExclusionOptionList.map(data => (
              <Chip
                key={data.key}
                onClick={tagExclusionClick(data)}
                label={data.label}
                className={classes.chip}
              />
            ))}
          </div>
        </FormField>
        }
        {/* 転職March end */}
      </FieldGroup>
      {/* #58578 次期開発2022年5月 start */
        displayShlInfo.displayOpq || displayShlInfo.displayGab || displayShlInfo.displayImages || displayShlInfo.displayCab ?
          <FieldGroup>
            <SubTitle variant='h3'>評価</SubTitle>
            {displayShlInfo.displayOpq && (
              <FormField>
                <Title title='OPQ' className={classes.title} />
                <div className={classes.modal}>
                  <Button onClick={handleClick('OPQ情報')} />
                  {opqObj.length > 0 && (
                    <span>
                      {opqObj.map(i =>
                        i && i.physicalName && i.physicalName !== '' ? (
                          <Chip
                            title={generateLabelModal(i)}
                            key={i.physicalName}
                            className={classes.chip}
                            label={generateLabelModal(i)}
                            onDelete={handleDeleteOPQObj(i)}
                            deleteIcon={<CancelIcon />}
                          />
                        ) : null
                      )}
                    </span>
                  )}
                </div>
              </FormField>
            )}
            {displayShlInfo.displayGab && (
              <FormField>
                <Title title='GAB' className={classes.title} />
                <div className={classes.modal}>
                  <Button onClick={handleClick('GAB情報')} />
                  {gabObj.length > 0 && (
                    <span>
                      {gabObj.map(i =>
                        i && i.physicalName && i.physicalName !== '' ? (
                          <Chip
                            title={generateLabelModal(i)}
                            key={i.physicalName}
                            className={classes.chip}
                            label={generateLabelModal(i)}
                            onDelete={handleDeleteGABObj(i)}
                            deleteIcon={<CancelIcon />}
                          />
                        ) : null
                      )}
                    </span>
                  )}
                </div>
              </FormField>
            )}
            {displayShlInfo.displayImages && (
              <FormField>
                <Title title='イメジス' className={classes.title} />
                <div className={classes.modal}>
                  <Button onClick={handleClick('イメジス情報')} />
                  {imagesObj.length > 0 && (
                    <span>
                      {imagesObj.map(
                        i =>
                          i &&
                          i.physicalName &&
                          i.physicalName !== '' && (
                            <Chip
                              title={generateLabelModal(i)}
                              // key={i.logicalName}
                              className={classes.chip}
                              label={generateLabelModal(i)}
                              onDelete={handleDeleteImages(i)}
                              deleteIcon={<CancelIcon />}
                            />
                          )
                      )}
                    </span>
                  )}
                </div>
              </FormField>
            )}
            {displayShlInfo.displayCab && (
              <FormField>
                <Title title='CAB' className={classes.title} />
                <div className={classes.modal}>
                  <Button onClick={handleClick('CAB情報')} />
                  {cabObj.length > 0 && (
                    <span>
                      {cabObj.map(i =>
                        i && i.physicalName && i.physicalName !== '' ? (
                          <Chip
                            title={generateLabelModal(i)}
                            key={i.physicalName}
                            className={classes.chip}
                            label={generateLabelModal(i)}
                            onDelete={handleDeleteCABObj(i)}
                            deleteIcon={<CancelIcon />}
                          />
                        ) : null
                      )}
                    </span>
                  )}
                </div>
              </FormField>
            )}
          </FieldGroup>
          : null
      }
      <Modal
        title={`${currentModal}を指定`}
        optionSet={modalData[currentModal].object}
        subtitleList={modalData[currentModal].title}
        form={form}
        open={state.general}
        onClose={handleModal('general', false)}
        name={nameSet[currentModal]}
        selectedSubtitleState={[selectedSubtitle, setSelectedSubtitle]}
      />
      {/* #58578 次期開発2022年5月 end */}
    </>
  )
}

export default RecruitmentManagement
