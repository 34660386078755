import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBHS110InitRequest } from 'types/MCBHS110/MCBHS110InitRequest'

const messageInitRequest: MCBHS110InitRequest = {
  sendCount: 0,
  emailFailedCount: 0,
}

const initialState: {
  messageInitRequest: MCBHS110InitRequest
} = {
  messageInitRequest: messageInitRequest,
}

const mcbMessageForcedStopSlice = createSlice({
  name: 'mcbMessageForcedStop',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCBHS110InitRequest>) {
      console.log('Reducer getInit1')
      const initRequest = action.payload
      return state
    },
  },
})

export const { getInit } = mcbMessageForcedStopSlice.actions
export default mcbMessageForcedStopSlice.reducer
