export const initMCBJS040SubmissionReqTemplateList = {
  returnRequestBody: '',// プレビュー本文;
    // #MCB1.5次開発 #72395 START
  returnThanksBody: '',// プレビューサンクス本文;
  senderMailAddressFix: '' //送信元メールアドレス固定
  // #MCB1.5次開発 #72395 END
}

export const initialTemplateAttachmentInfo = {
  templateAttachmentName: [],
  templateAttachmentSize: [],
}

export const initialValues = {
  recruitmentManagementDivision: '',//採用管理区分
  templateId: '', //提出リクエストテンプレート設定ID
  templateName: '', //テンプレート名
  targetYear:'',  //対象年度
  destinationDivision: '0', //送信先区分
  submissionsSelectId:'', //提出物内容選択ID
  submissionContentName: '',  //提出物内容（企業管理用）
  submissionContentStudent: '', //提出物内容（学生画面表示用）
  submissionNames: '', //提出物
  submissionsEntryFlg: '', //提出物エントリシートフラグ
  submissionsResumeFlg: '', //提出物履歴書フラグ
  submissionsResearchFlg: '', //提出物研究概要書フラグ
  submissionsOriginalFlg: '', //提出物オリジナル設問フラグ
  submissionsFileFlg: '', //提出物ファイル提出フラグ
  deadline: '0', //提出〆切区分
  deadlineDate: '', //提出〆切（日時指定）
  deadlineAfterDay: '', //提出〆切（依頼日から（日後））
  deadlineAfterDayTime: '', //提出〆切（依頼日から（時））
  deadlineAnyTime: '',  //提出〆切（随時受付終了）
  resubmission: '0',  //再提出の受付区分
  homepageTitle1: '', //ホームページタイトル1
  homepageTitle2: '', //ホームページタイトル2
  homepageTitle3: '', //ホームページタイトル3
  homepageUrl1: '', //ホームページURL1
  homepageUrl2: '', //ホームページURL2
  homepageUrl3: '', //ホームページURL3
  settingThanksEmail: '0',  //サンクスメール設定区分
  thanksMailTemplateId: '0', //サンクスメールテンプレートID
  thanksEmailTemplate: '',  //サンクスメールテンプレート
  thanksEmailSubject: '', //件名（サンクスメール）
  thanksEmailBody: '',  //本文（サンクスメール）
  studentReferenceId: '', //問い合わせ先選択ID
  contactInformation: '', //問い合わせ先
  contactCompanyName: '', // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  contactTelephoneNumber: '', // お問い合わせ先内容電話番号
  contactMailAddress: '', // お問い合わせ先内容メールアドレス
  personalDataHandlingId: '', //個人情報の取り扱い選択ID
  handlingPersonalInformation: '',  //個人情報の取り扱い名
  senderCompanyName: '',  //送信元会社名
  senderMailAddress: '',  //送信元メールアドレス
  submissionReqSubject: '',	//件名（提出リクエストメッセージ）
  submissionReqBody: '',	//本文（提出リクエストメッセージ）
  attachment: initialTemplateAttachmentInfo,  //添付ファイル[]
  templateattachmentList: [], //テンプレート添付ファイル情報[]
  sysVersionNumber: '', //sysバージョン番号
  forbiddenWordsList: [], //禁止語リスト
  previewThanksBody: '', //サンクス本文固定文言
  previewRequestBody: '' //リクエスト本文固定文言

}


export const initialSubmitValues = {
  recruitmentManagementDivision: '',//採用管理区分
  templateId: '', //提出リクエストテンプレート設定ID
  templateName: '', //テンプレート名
  targetYear:'',  //対象年度
  destinationDivision: '0', //送信先区分
  submissionsSelectId:'', //提出物内容選択ID
  submissionContentName: '',  //提出物内容（企業管理用）
  submissionContentStudent: '', //提出物内容（学生画面表示用）
  submissionNames: '', //提出物
  submissionsEntryFlg: '', //提出物エントリシートフラグ
  submissionsResumeFlg: '', //提出物履歴書フラグ
  submissionsResearchFlg: '', //提出物研究概要書フラグ
  submissionsOriginalFlg: '', //提出物オリジナル設問フラグ
  submissionsFileFlg: '', //提出物ファイル提出フラグ
  deadline: '0', //提出〆切区分
  deadlineDate: '', //提出〆切（日時指定）
  deadlineAfterDay: '', //提出〆切（依頼日から（日後））
  deadlineAfterDayTime: '', //提出〆切（依頼日から（時））
  deadlineAnyTime: '',  //提出〆切（随時受付終了）
  resubmission: '0',  //再提出の受付区分
  homepageTitle1: '', //ホームページタイトル1
  homepageTitle2: '', //ホームページタイトル2
  homepageTitle3: '', //ホームページタイトル3
  homepageUrl1: '', //ホームページURL1
  homepageUrl2: '', //ホームページURL2
  homepageUrl3: '', //ホームページURL3
  settingThanksEmail: '0',  //サンクスメール設定区分
  thanksMailTemplateId: '0', //サンクスメールテンプレートID
  thanksEmailTemplate: '',  //サンクスメールテンプレート
  thanksEmailSubject: '', //件名（サンクスメール）
  thanksEmailBody: '',  //本文（サンクスメール）
  studentReferenceId: '', //問い合わせ先選択ID
  contactInformation: '', //問い合わせ先
  contactCompanyName: '', // お問い合わせ先内容企業名
  contactDepartmentNameAndManagerName: '', // お問い合わせ先内容部署名担当者氏名
  contactTelephoneNumber: '', // お問い合わせ先内容電話番号
  contactMailAddress: '', // お問い合わせ先内容メールアドレス
  personalDataHandlingId: '', //個人情報の取り扱い選択ID
  handlingPersonalInformation: '',  //個人情報の取り扱い名
  senderCompanyName: '',  //送信元会社名
  senderMailAddress: '',  //送信元メールアドレス
  submissionReqSubject: '',	//件名（提出リクエストメッセージ）
  submissionReqBody: '',	//本文（提出リクエストメッセージ）
  attachment: initialTemplateAttachmentInfo,  //添付ファイル[]
  templateattachmentList: [], //テンプレート添付ファイル情報[]
  sysVersionNumber: '', //sysバージョン番号
  forbiddenWordsList: [], //禁止語リスト
  previewThanksBody: '', //サンクス本文固定文言
  previewRequestBody: '' //リクエスト本文固定文言

}