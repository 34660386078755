import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
const classificatiList = [
  { name: 'classificationSent', label: '送信', id: '2' },
  { name: 'classificationDraft', label: '下書き', id: '4' },
]

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
]

const initialValues = {
  searchTargetList: '0',
  searchWordText: '',
  deleteFromDate: '',
  deleteToDate: '',
  classification: {
    classificationSent: '0',
    classificationDraft: '0',
  },
  sort: '0',
  countPage: 1,
  selectedFlag: 0,
}

const dustMcbboxSubmitValue = {
  searchTargetList: '0',
  searchWordText: '',
  deleteFromDate: '',
  deleteToDate: '',
  classificationSent: '0',
  classificationDraft: '0',
  sort: '0',
  countPage: 1,
  selectedFlag: '0',
  firstDispFlag:''
}

const selectedMcbDraftListInit = {
  mcbMessageInfoListDto: [],
  searchTargetList: '',
  searchWordText: '',
  deleteFromDate: '',
  deleteToDate: '',
  classificationSent: '', 
  classificationDraft: '', 
  selectedFlag: '',
  sort: '',
  countPage: 0,
  status: '',
  firstDispFlag:''
}

const messageMap: { [key: string]: string } = {
  delete: getMessage(magiContants.MESSAGECODE_MCAZS200_004),
  undo: getMessage(magiContants.MESSAGECODE_MCAZS200_003),
}

export {
  initialValues,
  classificatiList,
  messageMap,
  dustMcbboxSubmitValue,
  templateOptionList,
  selectedMcbDraftListInit,
}
