
// 初期画面初期化
const initialValues = {
  loginId: '',
  companyId: '',
  outputSubmissionsIdArray: [],
  outputSubmissionsConditions: { value: '0', label: 'AND' },
  executionDateFrom: '',
  executionDateTo: '',
  executorList: { value: '0', label: '全て' },
  executorId: '',
  stateList: [{value: '1', label: '作成完了'}],
  creatingFlag: '',
  createCompleteFlag: '',
  creationErrorFlag: '',
}

// 検索画面初期化
const submitValues = {
  loginId: '',
  companyId: '',
  outputSubmissionsIdArray: [],
  outputSubmissionsConditions: 0,
  executionDateFrom: '',
  executionDateTo: '',
  executorList: null,
  executorId: '',
  stateList: null,
  creatingFlag: '',
  createCompleteFlag: '',
  creationErrorFlag: '',
}

// 削除ボタン初期化
const deleteValues = {
  pdfDownloadManagementId: '',
  downloadFullpath:'',
  submissionCount:'',
}

export interface PdfList {
  [key: string]: string | number | null
    outputTitle: string //タイトル
    submissionContentName1: string //出力提出物内容
    submissionContentName2: string //出力提出物内容
    submissionContentName3: string //出力提出物内容
    status: string //状態
    submissionCount: number //提出件数
    //errorCheckButton: string //エラー確認
    fullName: string //実行者
    executionDatetime: string //実行日時
    downloadDatetime: string //ダウンロード日時
    errorContent:string //エラー内容
    downloadFullpath:string //ダウンロード物格納パ
    submissionRequestId:number //提出リクエストID
    jobSeekerByCompanyId:number //企業別求職者ID  
    pdfDownloadManagementId:number //PDFダウンロード管理ID
    //deleteButton: string //削除
}

export interface PdfDownloadRequestInfo {
  //出力提出物内容情報
  submissionContentInfoList: Option[]

  //PDFダウンロード管理情報
  pdfDownloadManagementInfoList: string[]
}

export interface Option {
  [key: string]: any
  label: string
  value: string
}

export { initialValues, submitValues, deleteValues}
