import {all, call, put, takeLatest, select,} from 'redux-saga/effects'
import {openModal} from 'reducers/messageReducer'
import {
  selectDuplicateIdentificationTargetBalanceCount,
  selectInitDuplicateIdentificationResult,
  setInitDuplicateIdentificationResult,
  setSelectDuplicateIdentificationTargetBalanceCount,
  initSelectCheck,
  setInitSelectCheck,
  duplicateIdentificationInit,
  duplicateIdentificationInitRelsult,
} from 'reducers/nameCheckListReducer'
import {
  selectDuplicateIdentificationTargetBalanceCountRequest,
  selectInitDuplicateIdentificationResultRequest
} from "apis/MCAQS020Api";
import { getRecruitmentManagementDivision } from 'selectors/authSelectors';
import { initApi, executeApi } from 'apis/MCAQS010Api';
import { routeList } from 'routes/routes';
import history from 'utils/history';

/**
 * call url set init result to reducer MCAQS020
 * @param action reducer return value
 */
function* selectInitDuplicateIdentificationResultSaga(action: ReturnType<typeof selectInitDuplicateIdentificationResult>) {
  try {
    const searchCriteriaValue = {
      fullNameCheck: '',
      schoolCheck: '',
      schoolFacultyCheck: '',
      schoolFacultySubjectCheck: '',
      homePhoneCheck: '',
      mobilePhoneCheck: '',
      pcAddressCheck: '',
      mobileAddressCheck: '',
    };
    const allResultValue = {
      duplicateIdentificationInfoList: [],
      searchCriteria: searchCriteriaValue,
    }
    yield put(setInitDuplicateIdentificationResult(allResultValue));
    const data = yield call(selectInitDuplicateIdentificationResultRequest, action.payload);
    yield put(setInitDuplicateIdentificationResult(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

/**
 * call url set click resutl to reducer MCAQS020
 * @param action reducer return value
 */
function* selectDuplicateIdentificationTargetBalanceCountSaga(action: ReturnType<typeof selectDuplicateIdentificationTargetBalanceCount>) {
  try {
    yield put(setSelectDuplicateIdentificationTargetBalanceCount(''));
    const data = yield call(selectDuplicateIdentificationTargetBalanceCountRequest, action.payload);
    yield put(setSelectDuplicateIdentificationTargetBalanceCount(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* initSelectCheckSaga(action: ReturnType<typeof initSelectCheck>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(initApi,recruitmentManagementDivision);
    yield put(setInitSelectCheck(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* duplicateIdentificationInitSaga(action: ReturnType<typeof duplicateIdentificationInit>) {
  try {
    const data = yield call(executeApi, action.payload);
    yield put(duplicateIdentificationInitRelsult(data));
      const path = {
        pathname: routeList.nameList,
        state: data,
      }
      history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* nameCheckListSaga() {
  yield all([
    //call url set init result to reducer MCAQS020
    takeLatest(selectInitDuplicateIdentificationResult, selectInitDuplicateIdentificationResultSaga),
    //call url set click resutl to reducer MCAQS020
    takeLatest(selectDuplicateIdentificationTargetBalanceCount, selectDuplicateIdentificationTargetBalanceCountSaga),
    // =============================MCAQS010 START==========================
    takeLatest(initSelectCheck, initSelectCheckSaga),
    takeLatest(duplicateIdentificationInit, duplicateIdentificationInitSaga),
    // =============================MCAQS010 END============================
  ])
}
