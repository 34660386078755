import React ,{ useState } from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { magiContants } from 'utils/contants'
import { useErrorStyle } from 'components'
import { makeStyles  ,Dialog, Button, DialogTitle, DialogActions,  } from '@material-ui/core'
import { getMessage } from 'common/messageUtil'

interface Props extends FieldProps {
    optionList?: any[] | undefined
    placeholder?: string
    customSelectStyles?: any
    isSearchable?: boolean
    onChange?: (value: any) => void
    isDialogFlg?: boolean
    dialogMessageId?: string
}
const useStyles = makeStyles({
    container: {
        width: '100%'
    }
})

const SelectOfTargetYear = ({ optionList, placeholder = '', customSelectStyles, isSearchable, onChange, isDialogFlg = false, dialogMessageId = "", form, field }: Props) => {
    const { errors, touched } = form
    const { value, name } = field
    const errorclasses = useErrorStyle()
    const classes = useStyles()
    const globalMenu = useSelector((state: RootState) => state.globalMenu)
    let myOptionList = optionList ? optionList : globalMenu.targetYearDatas
    const [openTargetYearDialog, setOpenTargetYearDialog] = useState(false)
    const [changeOption, setChangeOption] = useState({value:'',label:''})

    const defaultSelectStyles = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            // 年度対応　#6347 start
            '&:hover': {
                borderColor: errors[name] && touched[name] ? '#e53935' : '#c1d1eb',
              },
              '&:focus': {
                borderColor: errors[name] && touched[name] ? '#e53935' : '#3f71bd',
              },
              borderColor: errors[name] && touched[name] ? '#e53935' : '#8593a6',
              boxShadow: '0px 0px 0px 0px #8593a6',
            // 年度対応　#6347 end
        }),
        input: () => ({
            margin: 0,
            paddingBottom: 0,
            paddingTop: 0,
            borderColor: errors[name] && touched[name] ? '#e53935' : '#8593a6'
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex:2,
        }),
    }
    const onYearChange = (option: any) => {
        setChangeOption(option)
        if (value !== option.value) {
            
            if (isDialogFlg) {
                setOpenTargetYearDialog(true)
            } else {
                form.setFieldValue(field.name, option.value, true)
                form.setFieldTouched(field.name, true, true)
                if (onChange !== undefined) {
                    onChange(option)
                }
            }
        }
    }

    const handleTargetYearChange = () => {
        form.setFieldValue(field.name, changeOption.value, true)
        form.setFieldTouched(field.name, true, true)
        if (onChange !== undefined) {
            onChange(changeOption)
        }
        setOpenTargetYearDialog(false)
    }

    return (
        <>
        <div className={classes.container}>
            <Select
                options={myOptionList}
                styles={{ ...defaultSelectStyles, ...customSelectStyles }}
                onChange={onYearChange}
                value={myOptionList.find(i => i.value == value)}
                placeholder={placeholder}
                noOptionsMessage={({ inputValue }) => {
                    return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
                }}
                isSearchable={isSearchable}
            />
            {errors[name] && touched[name] ? (
                <p className={errorclasses.helperText} style={{ fontFamily: "'Noto Sans JP', sans-serif" }}>{errors[name]}</p>
            ) : null}
        </div>
        <Dialog open={openTargetYearDialog}>
              <DialogTitle>{getMessage(dialogMessageId)}</DialogTitle>
              <DialogActions>
                <Button
                  onClick={()=>{setOpenTargetYearDialog(false)}}>
                  キャンセル
                </Button>
                <Button
                  onClick={()=>{handleTargetYearChange()}}
                  color='primary'>
                  OK
                </Button>
              </DialogActions>
        </Dialog>
        </>
    )
}

export default SelectOfTargetYear