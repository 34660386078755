import request from 'utils/request'

import { MCAXS201AddressRequest } from 'types/MCAXS201/MCAXS201AddressRequest'
import { MCAXS201ApplicantInfoRequest } from 'types/MCAXS201/MCAXS201ApplicantInfoRequest'
import { MCAXS201SchoolDetailedDivisionInfoRequest } from 'types/MCAXS201/MCAXS201SchoolDetailedDivisionInfoRequest'
import { MCAXS201SchoolInfoRequest } from 'types/MCAXS201/MCAXS201SchoolInfoRequest'

export const MCAXS201AutoInputRequestApi = (apiData: MCAXS201AddressRequest) =>
  request({
    url: '/MCAXS201/autoInput',
    method: 'post',
    data: apiData,
  })

export const MCAXS201VocationalSchoolMajorSelectRequestApi = (
  apiData: MCAXS201SchoolDetailedDivisionInfoRequest
) =>
  request({
    url: '/MCAXS201/vocationalSchoolMajorSelect',
    method: 'post',
    data: apiData,
  })

export const MCAXS201CheckRequestApi = (
  apiData: MCAXS201ApplicantInfoRequest
) =>
  request({
    url: '/MCAXS201/check',
    method: 'post',
    data: apiData,
  })

export const mcaxs201GraduateSchoolDepartmentApi = (
  params: MCAXS201SchoolDetailedDivisionInfoRequest
) =>
  request({
    url: 'MCAXS201/graduateSchoolDepartment',
    method: 'post',
    data: params,
  })

export const mcaxs201GraduateSchoolDepartmentSeelectApi = (
  params: MCAXS201SchoolDetailedDivisionInfoRequest
) =>
  request({
    url: 'MCAXS201/graduateSchoolDepartmentSeelect',
    method: 'post',
    data: params,
  })
export const mcaxs201JuniorCollegeSubjectSelectApi = (
  params: MCAXS201SchoolDetailedDivisionInfoRequest
) =>
  request({
    url: 'MCAXS201/juniorCollegeSubjectSelect',
    method: 'post',
    data: params,
  })
export const mcaxs201SchoolInitialOkApi = (params: MCAXS201SchoolInfoRequest) =>
  request({
    url: 'MCAXS201/schoolInitialOk',
    method: 'post',
    data: params,
  })
