import request from 'utils/request'
import { MCAZS200MessageQueryRequest } from 'types/MCAZS200/MCAZS200MessageQueryRequest'
import { MCAZS200MessageSelectRequest } from 'types/MCAZS200/MCAZS200MessageSelectRequest'


export const initMcbDustboxRequest = (apiData: MCAZS200MessageQueryRequest) =>
  request({
    url: '/MCAZS200/init',
    method: 'post',
    data: apiData,
  })

export const selectMcbMessageDustboxRequest = (apiData: MCAZS200MessageQueryRequest) =>
request({
  url: '/MCAZS200/find',
  method: 'post',
  data: apiData,
})

export const undoMcbDustboxMessageRequest = (apiData: MCAZS200MessageSelectRequest) =>
  request({
    url: '/MCAZS200/undoMessage',
    method: 'post',
    data: apiData,
  })

export const deleteMcbDustboxMessageRequest = (
  apiData: MCAZS200MessageSelectRequest
) =>
  request({
    url: '/MCAZS200/deleteMessage',
    method: 'post',
    data: apiData,
  })

export const getSelectionManagementApiMCAZS200 = (apiData: any) =>
  request({
  url:`/MCAZS200/showSendObjEntryList`,
  method: 'post',
  data: apiData,
})

