import request from 'utils/request'
import { MCBIS030InitRequest } from 'types/MCBIS030/MCBIS030InitRequest'
import { MCBIS030CreateRequest } from 'types/MCBIS030/MCBIS030CreateRequest'
import { MCBIS030CreateRequestEmployment } from 'types/MCBIS030/MCBIS030CreateRequestEmployment'

export const initRequest = (selectCriteria: MCBIS030InitRequest) =>
    request({
        url: 'MCBIS030/init',
        method: 'post',
        data: selectCriteria,
    })

export const updateRequest = (selectCriteria: MCBIS030CreateRequest) =>
    request({
        url: `MCBIS030/okkizon`,
        method: 'post',
        data: selectCriteria,
    })
    
export const createRequest = (selectCriteria: MCBIS030CreateRequestEmployment) =>
    request({
        url: `MCBIS030/oksinki`,
        method: 'post',
        data: selectCriteria,
    })
