import request from 'utils/request'
import { MCAAS010CompanyAccountIdRequest } from 'types/MCAAS010/MCAAS010CompanyAccountIdRequest'
import { MCAAS010GetNewApplicantsCountRequest } from 'types/MCAAS010/MCAAS010GetNewApplicantsCountRequest'
import { MCAAS010SwitchRequest } from 'types/MCAAS010/MCAAS010SwitchRequest'
import { MCAAS010TargetYear } from 'types/MCAAS010/MCAAS010TargetYear'
import { MCAAS010TargetService } from 'types/MCAAS010/MCAAS010TargetService'
export const menuInitRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/init',
    method: 'post',
    data: params,
  })

export const menuLogoutRequest = (params: MCAAS010CompanyAccountIdRequest) =>
  request({
    url: '/MCAAS010/logout',
    method: 'post',
    data: params,
  })

export const menuGetUserInfoRequest = () =>
  request({
    url: '/MCAAS010/getUserInfo',
    method: 'post',
  })

export const getNewApplicantsCountRequest = (params: MCAAS010GetNewApplicantsCountRequest) =>
  request({
    url: '/MCAAS010/getNewApplicantsCount',
    method: 'post',
    data: params,
  })

export const getUnreadCVSCountRequest = () =>
  request({
    url: '/MCAAS010/getUnreadCVSCount',
    method: 'post',
  })

export const getUnreadMessagesCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/getUnreadMessagesCount',
    method: 'post',
    data: params,
  })

export const getWaitForScheduleCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/getWaitForScheduleCount',
    method: 'post',
    data: params,
  })

export const menuInitNewApplicantsCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/initNewApplicantsCount',
    method: 'post',
    data: params,
  })

export const menuInitUnreadCVSCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/initUnreadCVSCount',
    method: 'post',
    data: params,
  })

export const menuInitUnreadMessagesCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/initUnreadMessagesCount',
    method: 'post',
    data: params,
  })

export const menuInitWaitForScheduleCountRequest = (params: MCAAS010SwitchRequest) =>
  request({
    url: '/MCAAS010/initWaitForScheduleCount',
    method: 'post',
    data: params,
  })

// 年度対応 start
export const yearChangeRequest = (params: MCAAS010TargetYear) =>
  request({
    url: '/MCAAS010/yearChange',
    method: 'post',
    data: params,
  })

export const getYearDataequest = () =>
  request({
    url: '/MCAAS010/getYearData',
    method: 'post',
  })
// 年度対応 end

// 転職March #75621 START
export const serviceModeInitialRequest = () =>
  request({
    url: '/MCAAS010/serviceModeInitial',
    method: 'post',
  })

export const serviceChangeRequest = (params: MCAAS010TargetService) =>
  request({
    url: '/MCAAS010/serviceChange',
    method: 'post',
    data: params,
  })
  
export const initUnreadMarchEntryCount = () =>
  request({
    url: '/MCAAS010/initUnreadMarchEntryCount',
    method: 'post',
  })
// 転職March #75621 END