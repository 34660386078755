import request from '../utils/request'
import {MCADS040LoginIdRequest} from "../types/MCADS040/MCADS040LoginIdRequest";

export const selectEditListRequest = (editList: MCADS040LoginIdRequest) =>
  request({
    url: '/MCADS040/init',
    method: 'post',
    data: editList,
  })

export const selectRecruitmentNameRequest = (companyAccountId: string) =>
  request({
    url: `/MCADS040/initRecruitmentId/${companyAccountId}`,
    method: 'post',
  })

