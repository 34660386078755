type OptionType = {
  [key: string]: any,
}

type OptionsType = Array<OptionType>

interface Candidate {
  label: string
  value: string
  data: any
}

const trimString = (str: string) => str.replace(/^\s+|\s+$/g, '');
const stringify = (candidate: Candidate) => `${candidate.label}`;

const filterOption = (candidate: Candidate, rawInput: string) => {
  if (candidate.data.__isNew__) {
    return true
  }

  const input = trimString(rawInput).toLowerCase()
  const option= trimString(stringify(candidate)).toLowerCase()

  return option.includes(input)
}

const compareOption = (inputValue = '', option: OptionType) => {
  const candidate = String(inputValue).toLowerCase();
  const optionLabel = String(option.label).toLowerCase();
  return optionLabel === candidate;
}

const isValidNewOption = (
  inputValue: string,
  selectValue: OptionsType,
  selectOptions: OptionsType
) => {
  return !(
    !inputValue ||
    selectValue.some(option => compareOption(inputValue, option)) ||
    selectOptions.some(option => compareOption(inputValue, option))
  )
}

const getNewOptionData = (inputValue: string, optionLabel: any) => ({
  label: optionLabel,
  value: `${inputValue}`,
  __isNew__: true,
})

export default {
  filterOption,
  isValidNewOption,
  getNewOptionData
}