import {
  Button,
  colors,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldGroup, SubTitle } from 'componentsHsc'
import React, { useEffect, useState } from 'react'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector, useDispatch } from 'react-redux'
import { getMcaxs481Init, pushOperationLogFor88924 } from 'reducers/advancedSearchModalReducer'
import { Seminar, SeminarLevel2, SeminarLevel3 } from 'componentsHsc/AdvancedSearch/Config/seminarDateModalConfig'
import { dateToString, dateTimeToString, isEmpty } from 'common/generalUtil'
import { magiContants as contants } from 'utils/contants'
import {
  SeminarReceptionObj,
  SeminarReceptionWhichOneObj,
  SeminarReceptionIndividualObj,
  SeminarReceptionIndividualObj2,
} from '../Config/searchConditionConfig'
import CustomScrollbar from 'react-custom-scrollbars'
import { openModal } from 'reducers/messageReducer'
import { now } from 'moment'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    actions: {
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dialogTitle: {
        color: (theme.palette as any).white,
        backgroundColor: '#133e80',
        '&:hover': {
            backgroundColor: '#133e80',
        },
    },
    title: {
        color: (theme.palette as any).white,
        fontWeight: 900,
        fontSize: 18,
        cursor:'auto',
    },
    select: {
        display: 'flex',
        '& > :first-child': {
            flex: 1,
        },
        '& > :last-child': {
            flex: 3,
        },
        '& span.MuiFormControlLabel-label': {
            color: colors.grey[600],
        },
        height: 'auto'
    },
    content: {
        margin: theme.spacing(1, 2),
        '& div': {
            display: 'inline-block',
            margin: '0px 20px 0px 0px',
            cursor: 'pointer',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        }
    },
    selectAll: {
        '& div': {
            display: 'inline-block',
            margin: '8px 20px 0px 0px',
            cursor: 'pointer',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        }
    },
    content2: {
        margin: theme.spacing(1, 2),
     },
    cancel: {
        color: (theme.palette as any).black,
        backgroundColor: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#c1d1eb',
        },
        width: 130,
        marginRight: '20px',
    },
    confirmButton: {
        color: (theme.palette as any).white,
        backgroundColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#23598e',
        },
        width: 130,
        marginLeft: '20px !important',
    },
    dividerColor: {
        backgroundColor: colors.grey[600],
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labelTitle: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        cursor:'auto',
    },
    subContent: {
        margin: theme.spacing(2),
        '& .Mui-selected': {
            backgroundColor: 'transparent !important',
            color: '#1950a6',
            textDecoration: 'underline',
            '& span': {
                color: '#1950a6',
            },
        },
        height: '100%',
    },
    divider: {
        margin: theme.spacing(1, 0),
        backgroundColor: 'lightgrey',
    },
    seminarName: {
        wordBreak:'break-all',
        wordWrap:'break-word',
    },
    seminarName2: {
        '& > div > :first-child':{
            overflowX: 'hidden !important',
        }
    },
    selectAllOfForm: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
        '& div': {
            display: 'inline-block',
            margin: '0px 20px 0px 0px',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '14px',
        },
        '& div input': {
            marginRight: '5px',
            cursor: 'pointer',
        }
    },
    containerOfForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    subtitleOfForm: {
        marginTop: theme.spacing(1),
        alignSelf: 'flex-start',
    },
}))

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

const optionList = [
  { label: '予約あり', value: '1' },
  { label: '予約なし', value: '0' },
  { label: 'キャンセル', value: '2' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

interface RdoSeleDate {
    [key: string]: string
    dateId: string
    rdoVal: string
}

interface SeleDateOfForm {
    [key: string]: string | any
    value: string
    coordinate: {
        index1: number
        index2: number
    }
}

const SeminarDateModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)

    const [rdoDateSelect, setRdoDateSelect] = useState<RdoSeleDate[]>([]) // 「各受付ごと」の受付状況チェック
    const [seminarSelect, setSeminarSelect] = useState('') // 「いずれかの日程」と「各受付ごと」のラジオボタン
    const [whichOne, setWhichOne] = useState('') // 「いずれかの日程」の受付状況チェック
    const [individual, setIndividual] = useState('0') // 「各受付ごと」の日付間の検索方法 ANR/OR
    const [selectedSub, setSelectedSub] = useState('') // 説明会・面接名リストクリック時に画面のスクロール
    const [selectAllFlag, setSelectAllFlag] = useState('') // 「各受付ごと」の一括チェック
    const [selectAllFlagOfForm, setSelectAllFlagOfForm] = useState<SeleDateOfForm[]>([]) // フォームIDごとに一括チェック
    const [/* state */, setState] = useState();

    const handleSubClick = (index1: number, index2: number) => () => {
        setSelectedSub(index1 + '_' + index2)
        const toTop = document.getElementById(`sel_${index1}_${index2}`)
        if (toTop) {
            toTop.scrollIntoView()
        }
    }

    const getSeminarListLengthForLog = () => {
        // undefinedなら初期状態
        if(seminarList !== undefined){
            return `${seminarList.length}`
        }
        // 0ならセミナー無、0以外ならセミナーあり
        else{
            return "undefined"
        }
    }
    useEffect(() => {
        const log = {
            location:"useEffect_before_request",
            open: open,
            storedTime: moment(new Date()).format('MM/DD HH:mm:ss'),
            seminarListLength: getSeminarListLengthForLog(),
        }
        dispatch(pushOperationLogFor88924(JSON.stringify(log)))
        dispatch(getMcaxs481Init())
    },[open])

    useEffect(() => {
        if (open) {
            setSelectedSub('')
            if (form.values.seminarReceptionObj) {
                setSeminarSelect(
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString()
                )
                if (
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString() ===
                    '0'
                ) {
                    setRdoDateSelect([])
                    setSelectAllFlag('')
                    if (form.values.seminarReceptionObj.seminarReceptionWhichOneObj) {
                        let whichoneObj =
                            form.values.seminarReceptionObj.seminarReceptionWhichOneObj
                                .seminarReceptionWhichOneFlag !== null
                                ? form.values.seminarReceptionObj.seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag.toString()
                                : ''
                        setWhichOne(whichoneObj)
                    }
                } else if (
                    form.values.seminarReceptionObj.seminarReceptionConditionJudgmentFlag.toString() ===
                    '1'
                ) {
                    setWhichOne('')
                    if (form.values.seminarReceptionObj.seminarReceptionIndividualObj) {
                        const individualObj =
                            form.values.seminarReceptionObj.seminarReceptionIndividualObj
                        let individualFlg =
                            individualObj.seminarReceptionIndividualJudgmentFlag !== null
                                ? individualObj.seminarReceptionIndividualJudgmentFlag.toString()
                                : ''
                        setIndividual(individualFlg)
                        if (individualObj.seminarReceptionIndividualObj2) {
                            let flglst2: RdoSeleDate[] = []
                            const rdoValSortList: string[] = []
                            const individualObj2 =
                                individualObj.seminarReceptionIndividualObj2
                            let seminarCount : number = 0
                            
                            log80680(seminarList,"useEffect_seminarList") && seminarList.map(item => {
                                log80680(item.seminarLevel2Dto,"useEffect_item.seminarLevel2Dto") && item.seminarLevel2Dto.map(item1 => {
                                    log80680(item1.seminarLevel3Dto,"useEffect_item1.seminarLevel3Dto") && item1.seminarLevel3Dto.map(item2 => {
                                        seminarCount++;
                                        individualObj2.map(info => {
                                            if (item2.seminarScheduleId.toString() === info.seminarReceptionIndividualSeminarScheduleId.toString()) {
                                                flglst2.push({
                                                    dateId: info.seminarReceptionIndividualSeminarScheduleId,
                                                    rdoVal: info.seminarReceptionIndividualFlag.toString()
                                                })
                                                rdoValSortList.push(info.seminarReceptionIndividualFlag.toString())
                                            }
                                        })
                                    })
                                })
                            })
                            
                            rdoValSortList.sort()
                            if(rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1] && rdoValSortList.length == seminarCount){
                                setSelectAllFlag(rdoValSortList[0])
                            }else {
                                setSelectAllFlag('')
                            }
                            setRdoDateSelect(flglst2)
                        }
                    }
                }
            }else {
                setSeminarSelect('')
                setWhichOne('')
                setIndividual('0')
                setSelectAllFlag('')
                setRdoDateSelect([])
                setSelectAllFlagOfForm([])
            }
        }
    }, [open])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }

  const handleClose = () => {
    setConfirm(false)
    onClose()
  }

  const { seminarList } = useSelector(
    (state: RootState) => state.advancedSearchModal.seminarDateModalInfo
  )
  const { operationLogFor88924 } = useSelector(
    (state: RootState) => state.advancedSearchModal
  )
  const propsInfo = {
    open: open, 
}

  const useStateInfo = {
    confirm: confirm,
    rdoDateSelect: rdoDateSelect.length, 
    seminarSelect: seminarSelect,
    whichOne: whichOne, 
    individual: individual,
    selectedSub: selectedSub,
    selectAllFlag: selectAllFlag,
    selectAllFlagOfForm: selectAllFlagOfForm.length,
  }

  const useSelectorInfo = {
    seminarList:
      seminarList === undefined
        ? {}
        : seminarList.map((item, i) => {
            if (item.seminarLevel2Dto === undefined) {
              return {
                seminarListIndex: i,
                contractMediaId: item.contractMediaId,
                contractMediaName: item.contractMediaName,
              }
            } else {
              return {
                seminarListIndex: i,
                contractMediaId: item.contractMediaId,
                contractMediaName: item.contractMediaName,
                seminarLevel2Dto: item.seminarLevel2Dto
                  .filter(item1 => item1.seminarLevel3Dto === undefined)
                  .map((item1, j) => {
                    return {
                      seminarLevel2DtoIndex: j,
                      coordinationFormerSeminarId:
                        item1.coordinationFormerSeminarId, // 連携元セミナーID
                      seminarId: item1.seminarId, // セミナーID
                      seminarName: item1.seminarName, // セミナー名
                      publicFlag: item1.publicFlag, // 公開フラグ
                      publicName: item1.publicName, // 公開フラグ名
                    }
                  }),
              }
            }
          }),
  }

  const log80680 = (item: any, location: string) => {
    if (item === undefined) {
      const latestLog = {
          location:"log80680",
          open: open,
          storedTime: moment(new Date()).format('MM/DD HH:mm:ss'),
          seminarListLength: getSeminarListLengthForLog(),
      }
      let newOperationLogFor88924: string[] = [...operationLogFor88924]
      newOperationLogFor88924.push(JSON.stringify(latestLog))
      const log = {
        title: '#80680_調査用ログ_MCAXS481_SeminarDateModal',
        location: location,
        propsInfo: propsInfo,
        useStateInfo: useStateInfo,
        useSelectorInfo: useSelectorInfo,
        operationLogFor88924: newOperationLogFor88924,
      }
      setState(()=>{
        //error boundaryがキャッチしないエラー用
        throw new Error(JSON.stringify(log))
      })
      throw new Error(JSON.stringify(log))
    }
    return true
  }

  const handleSubmit = () => {
    let seminarReceptionObj: SeminarReceptionObj = {
      seminarReceptionConditionJudgmentFlag: 1,
      seminarReceptionConditionJudgmentName: '',
      seminarReceptionWhichOneObj: null,
      seminarReceptionIndividualObj: null,
    }

        var searchValue: any = document.getElementsByName('selectSearch')
        if (searchValue) {
            if (searchValue[0].checked) {
                seminarReceptionObj.seminarReceptionConditionJudgmentFlag = 0
                seminarReceptionObj.seminarReceptionConditionJudgmentName =
                    'いずれかの日程'
                let seminarReceptionWhichOneObj: SeminarReceptionWhichOneObj = {
                    seminarReceptionWhichOneFlag: 0,
                    seminarReceptionWhichOneName: '',
                }
                var searchValue1: any = document.getElementsByName('selectSearch1')
                if (searchValue1) {
                    if (searchValue1[0].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 0
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '予約あり'
                    } else if (searchValue1[1].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 1
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '予約なし'
                    } else if (searchValue1[2].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 2
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            'キャンセル'
                    } else if (searchValue1[3].checked) {
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag = 3
                        seminarReceptionWhichOneObj.seminarReceptionWhichOneName =
                            '採用担当による手動予約'
                    }
                    if(seminarReceptionWhichOneObj.seminarReceptionWhichOneFlag == 0 
                        && seminarReceptionWhichOneObj.seminarReceptionWhichOneName == ''){
                        dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                        return
                    }
                    seminarReceptionObj.seminarReceptionWhichOneObj = seminarReceptionWhichOneObj
                }
            } else if (searchValue[1].checked) {
                seminarReceptionObj.seminarReceptionConditionJudgmentFlag = 1
                seminarReceptionObj.seminarReceptionConditionJudgmentName = '各受付ごと'
                var searchValue2: any = document.getElementsByName('selectSearch2')
                if (searchValue2) {
                    let seminarReceptionIndividualObj: SeminarReceptionIndividualObj = {
                        seminarReceptionIndividualJudgmentFlag: 0,
                        seminarReceptionIndividualJudgmentName: '',
                        seminarReceptionIndividualObj2: [],
                    }
                    if (searchValue2[0].checked) {
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentFlag = 0
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentName =
                            'AND'
                    } else if (searchValue2[1].checked) {
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentFlag = 1
                        seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentName =
                            'OR'
                    }

                    seminarList.map((item, index) => {
                        item.seminarLevel2Dto.map((item1, index1) => {
                            item1.seminarLevel3Dto.map((item2, index2) => {
                                var searchValue3: any = document.getElementsByName(
                                    `selectSearch3[${index}_${index1}_${index2}]`
                                )
                                if (searchValue3) {
                                    //開始時刻、終了時刻の文字列作成
                                    let startTime = dateTimeToString(
                                        item2.startTime
                                    )
                                    let endTime = dateTimeToString(item2.endTime)
                                    if (startTime) {
                                        startTime = startTime.substring(11)
                                    }
                                    if (endTime) {
                                        endTime = endTime.substring(11)
                                    }

                                    let seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2 = {
                                        seminarReceptionIndividualApplicationRouteId: item2.contractMediaId,
                                        seminarReceptionIndividualApplicationRouteName: item2.contractMediaName,
                                        seminarReceptionIndividualCoordinationFormerSeminarId: item2.coordinationFormerSeminarId,
                                        seminarReceptionIndividualSeminarId: item2.seminarId,
                                        seminarReceptionIndividualSeminarName: item2.seminarName,
                                        seminarReceptionIndividualPublicFlag: item2.publicFlag,
                                        seminarReceptionIndividualPublicName: item2.publicName,
                                        seminarReceptionIndividualSeminarScheduleId: item2.seminarScheduleId,
                                        seminarReceptionIndividualSeminarScheduleTitle: item2.seminarScheduleTitle,
                                        seminarReceptionIndividualInvisibleFlag: item2.invisibleFlag,
                                        seminarReceptionIndividualInvisibleName: item2.invisibleName,
                                        seminarReceptionIndividualEventDate: item2.eventDate ? dateToString(item2.eventDate) : '',
                                        seminarReceptionIndividualStartTime: startTime ? startTime : '',
                                        seminarReceptionIndividualEndTime: endTime ? endTime : '',
                                        seminarReceptionIndividualVenueLocationCode: item2.venueLocationCode,
                                        seminarReceptionIndividualVenueLocationName: item2.venueLocationName,
                                        seminarReceptionIndividualFlag: 0,
                                        seminarReceptionIndividualName: '',
                                        seminarReceptionIndividualEventDateText: item2.eventDateText,
                                    }

                                    if (searchValue3[0].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 0
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '予約あり'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[1].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 1
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '予約なし'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[2].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 2
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            'キャンセル'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    } else if (searchValue3[3].checked) {
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualFlag = 3
                                        seminarReceptionIndividualObj2.seminarReceptionIndividualName =
                                            '採用担当による手動予約'
                                        seminarReceptionIndividualObj.seminarReceptionIndividualObj2.push(
                                            seminarReceptionIndividualObj2
                                        )
                                    }
                                }
                            })
                        })
                    })

                    if(seminarReceptionIndividualObj.seminarReceptionIndividualObj2.length == 0){
                        dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                        return
                    }
                    seminarReceptionObj.seminarReceptionIndividualObj = seminarReceptionIndividualObj
                }
            } else {
                dispatch(openModal(contants.MESSAGECODE_MCAXS481_002))
                return
            }
        } 
    if (
      seminarReceptionObj.seminarReceptionIndividualObj !== null ||
      seminarReceptionObj.seminarReceptionWhichOneObj !== null
    ) {
      form.setFieldValue('seminarReceptionObj', seminarReceptionObj)
    }
    handleClose()
    // TODO: formik
  }

    const createSeminarName = (seminar: SeminarLevel2) => {
        let result = seminar.coordinationFormerSeminarId + ' ' + seminar.seminarName
        if (seminar.publicFlag === '0') {
            result += ' 【' + seminar.publicName + '】'
        }
        return result
    }

    const createSeminarScheduleName = (seminar: SeminarLevel3) => {
        // [phase2] 連携された対象セミナー情報の開催日がNULL(開催日テキストの指定)の場合に、開催日の代わりに開催日テキストの内容を表示する start
        let result = ''
        let eventDate = dateToString(seminar.eventDate)
        result += seminar.seminarScheduleTitle
        if (seminar.invisibleFlag === '1') {
        result += ' 【' + seminar.invisibleName + '】'
        }
        if(isEmpty(eventDate)){
        result += !isEmpty(seminar.eventDateText) ? ' ' + seminar.eventDateText : ''
        }else{
        result += ' ' + dateToString(seminar.eventDate)
        let startTime = dateTimeToString(seminar.startTime)
        let endTime = dateTimeToString(seminar.endTime)
        if (startTime || endTime) {
            result += ' '
            if (startTime) {
            result += startTime.substring(11)
            }
            result += '～'
            if (endTime) {
            result += endTime.substring(11)
            }
        }
        }
        result += ' ' + seminar.venueLocationName
        return result
        // [phase2] end
    }

    const handleSelectAll = (e: string) => {
        const selectValue = e === selectAllFlag ? '' : e
        let tmpval2: RdoSeleDate[] = []
        seminarList.map(item => {
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
                })
            })
        })
        setSelectAllFlag(selectValue)
        setRdoDateSelect(tmpval2)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const handleSelectAll2 = async (e: string) => {
        await handleSelectAll(e)
        let tempDataOfForm = [...selectAllFlagOfForm]
        seminarList.map((item: any, index1: number) => {
            item.seminarLevel2Dto.map((item1: any, index2: number) => {
                let doms = document.getElementsByName(`selectSearch5[${index1.toString()}_${index2.toString()}]`)
                let tempValue = ''
                if (doms) {
                    for (let i = 0; i < doms.length; i++) {
                        if ((doms[i] as any).checked) {
                            tempValue = i.toString()
                        }
                    }
                    let tempIndex = tempDataOfForm.findIndex(item2 => item2.coordinate.index1 == index1 && item2.coordinate.index2 == index2)
                    if (tempIndex != -1) {
                        tempDataOfForm[tempIndex].value = tempValue
                    } else {
                        tempDataOfForm.push({value:tempValue,coordinate:{index1:index1,index2:index2}})
                    }
                    
                }
            })
        })
        setSelectAllFlagOfForm(tempDataOfForm)
    }

    const handleSeminarSelect = (e: any) => {
        if('0' === e.target.value){
            setRdoDateSelect([])
            setIndividual('')
            setSelectAllFlag('')
            setSelectAllFlagOfForm([])
        }else if('1' === e.target.value){
            setWhichOne('')
            setIndividual('0')
        }
        setSeminarSelect(e.target.value)
    }

    const handleWhichOne = (e: any) => {
        const whichOneObj = e.target.value === whichOne ? '' : e.target.value
        setWhichOne(whichOneObj)
        // 次期9月対応 #63457 start
        // 受付状況ラジオボタン（いずれかの日程）の場合 
        // 受付予約ラジオボタンの"いずれかの日程"をチェックありにする。
        setSeminarSelect('0')
        setRdoDateSelect([])
        // ANR/ORの設定
        setIndividual('')
        // 「各受付ごと」の一括チェック：選択しない
        setSelectAllFlag('')
        // 次期9月対応 #63457 end
        setSelectAllFlagOfForm([])
    }

    const handleIndividual = (e: any) => {
        setIndividual(e.target.value)
    }

    const handleRdoDateSelect = (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        let tmpval2: RdoSeleDate[] = []
        rdoDateSelect.map(info => {
            if (info.dateId.toString() === seminar.seminarScheduleId.toString()) {
                tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: rdoSeleData && rdoSeleData.rdoVal === values ? '' : values })
            } else {
                tmpval2.push(info)
            }
        })
        if (!rdoSeleData) {
            tmpval2.push({ dateId: seminar.seminarScheduleId, rdoVal: values })
        }
        let count = 0
        seminarList.map(item => {
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    count++
                })
            })
        })
        const rdoValSortList: string[] = []
        tmpval2.forEach(i => {
            rdoValSortList.push(i.rdoVal)
        })
        rdoValSortList.sort()
        if(rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length-1]){
            setSelectAllFlag(rdoValSortList[0])
        }else {
            setSelectAllFlag('')
        }
        setRdoDateSelect(tmpval2)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const handleRdoDateSelect2 = async (seminar: SeminarLevel3, values: string, index1: number, index2: number) => {
        await handleRdoDateSelect(seminar, values, index1, index2)
        let doms = document.getElementsByName(`selectSearch5[${index1}_${index2}]`)
        let tempDataOfForm = [...selectAllFlagOfForm]
        let tempValue = ''
        if (doms) {
            for (let i = 0; i < doms.length; i++) {
                if ((doms[i] as any).checked) {
                    tempValue = i.toString()
                }
            }
            let tempIndex = tempDataOfForm.findIndex(item => item.coordinate.index1 == index1 && item.coordinate.index2 == index2)
            if (tempIndex != -1) {
                tempDataOfForm[tempIndex].value = tempValue
            } else {
                tempDataOfForm.push({value:tempValue,coordinate:{index1:index1,index2:index2}})
            }
            setSelectAllFlagOfForm(tempDataOfForm)
        }
    }

    const rdoCheckedZero = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "0") {
                return true
            }
        }
        return false
    }

    const rdoCheckedOne = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "1") {
                return true
            }
        }
        return false
    }

    const rdoCheckedTwo = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "2") {
                return true
            }
        }
        return false
    }
    
    const rdoCheckedThree = (seminar: SeminarLevel3) => {
        let rdoSeleData = rdoDateSelect.find(item => {
            return item.dateId.toString() === seminar.seminarScheduleId.toString()
        })
        if (rdoSeleData) {
            if (rdoSeleData.rdoVal === "3") {
                return true
            }
        }
        return false
    }

    
    const handleSelectAllOfForm = (e: string, seminarId: string, index1: number, index2: number) => {
        // 操作記録
        let selectValue = ''
        let tempSelectAllFlagOfForm = [...selectAllFlagOfForm]
        let index = tempSelectAllFlagOfForm.findIndex(item => item.coordinate.index1 == index1 && item.coordinate.index2 == index2)
        if (index == -1) {
            selectValue = e
            let tempSeleDataOfForm: SeleDateOfForm = { coordinate: { index1: index1, index2: index2 }, value: selectValue }
            setSelectAllFlagOfForm(tempSelectAllFlagOfForm.concat([tempSeleDataOfForm]))
        } else {
            selectValue = e == selectAllFlagOfForm[index].value ? '' : e
            let tempSeleDataOfForm: SeleDateOfForm = { coordinate: { index1: index1, index2: index2 }, value: selectValue }
            tempSelectAllFlagOfForm[index] = tempSeleDataOfForm
            setSelectAllFlagOfForm(tempSelectAllFlagOfForm)
        }
        // データ保持
        let tmpval2: RdoSeleDate[] = []
        seminarList.map(item => {
            item.seminarLevel2Dto.filter(i => i.seminarId == seminarId).map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    tmpval2.push({ dateId: item2.seminarScheduleId, rdoVal: selectValue })
                })
            })
        })
        let tempRdoDateSelect = [...rdoDateSelect]
        let addTempRdoDateSelect: RdoSeleDate[] = []
        tmpval2.forEach(i => {
            let tempIndex = tempRdoDateSelect.findIndex(item => item.dateId == i.dateId)
            if (tempIndex == -1) {
                addTempRdoDateSelect.push(i)
            } else {
                tempRdoDateSelect[tempIndex] = i
            }
        })
        let result = tempRdoDateSelect.concat(addTempRdoDateSelect)
        // 全チェック判定
        let count = 0
        seminarList.map(item => {
            item.seminarLevel2Dto.map(item1 => {
                item1.seminarLevel3Dto.map(item2 => {
                    count++
                })
            })
        })
        const rdoValSortList: string[] = []
        result.forEach(i => {
            rdoValSortList.push(i.rdoVal)
        })
        rdoValSortList.sort()
        if (rdoValSortList.length === count && rdoValSortList[0] === rdoValSortList[rdoValSortList.length - 1]) {
            setSelectAllFlag(rdoValSortList[0])
        } else {
            setSelectAllFlag('')
        }
        setRdoDateSelect(result)
        // 次期9月対応 #63457 start 
        // 「各受付ごと」選択する
        setSeminarSelect('1')
        //「いずれかの日程」の受付状況チェック：選択しない
        setWhichOne('')
        individual ? setIndividual(individual) : setIndividual('0')
        // 次期9月対応 #63457 end 
    }

    const rdoCheckedOfForm = (e: string, seminar: SeminarLevel2) => {
        return log80680(seminar.seminarLevel3Dto,"rdoCheckedOfForm_seminar.seminarLevel3Dto") && seminar.seminarLevel3Dto.every(item => {
            let temp = rdoDateSelect.filter(item2 => item2.dateId == item.seminarScheduleId && item2.rdoVal == e)
            if (temp && temp.length > 0) {
                return true
            } else {
                return false
            }
        })
    }

    return (
        <div>
            <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
                <DialogTitle className={classes.dialogTitle}>
                    <label className={classes.title}>セミナー受付日程・予約状況を指定</label>
                </DialogTitle>
                <DialogContent>
                    <RadioGroup>
                        <FormControlLabel
                            control={
                                <Radio
                                    color='primary'
                                    value='0'
                                    name='selectSearch'
                                    onChange={handleSeminarSelect}
                                    checked={'0' === seminarSelect}
                                />
                            }
                            label='いずれかの日程'
                        />
                        <RadioGroup row className={classes.content2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='0'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'0' === whichOne}
                                    />
                                }
                                label='予約あり'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='1'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'1' === whichOne}
                                    />
                                }
                                label='予約なし'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='2'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'2' === whichOne}
                                    />
                                }
                                label='キャンセル'
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        color='primary'
                                        value='3'
                                        name='selectSearch1'
                                        onClick={handleWhichOne}
                                        checked={'3' === whichOne}
                                    />
                                }
                                label='採用担当による手動予約'
                            />
                        </RadioGroup>
                        <Divider className={classes.divider} />
                        <FormControlLabel
                            control={
                                <Radio
                                    color='primary'
                                    value='1'
                                    name='selectSearch'
                                    onChange={handleSeminarSelect}
                                    checked={'1' === seminarSelect}
                                />
                            }
                            label='各受付ごと'
                        />
                        <div className={classes.subTitle}>
                            <RadioGroup row>
                                <FormLabel className={classes.labelTitle}>日付間の検索方法</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            value='0'
                                            name='selectSearch2'
                                            onChange={handleIndividual}
                                            checked={'0' === individual}
                                        />
                                    }
                                    label='AND'
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            value='1'
                                            name='selectSearch2'
                                            onChange={handleIndividual}
                                            checked={'1' === individual}
                                        />
                                    }
                                    label='OR'
                                />
                            </RadioGroup>
                            <RadioGroup row className={classes.selectAll}>
                                <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                                <div onClick={() => handleSelectAll2("0")}>
                                    <input type="radio" name='selectSearch4' value='0' checked={'0' === selectAllFlag} />予約あり
                                </div>
                                <div onClick={() => handleSelectAll2("1")}>
                                    <input type="radio" name='selectSearch4' value='1' checked={'1' === selectAllFlag} />予約なし
                                </div>
                                <div onClick={() => handleSelectAll2("2")}>
                                    <input type="radio" name='selectSearch4' value='2' checked={'2' === selectAllFlag} />キャンセル
                                </div>
                                <div onClick={() => handleSelectAll2("3")}>
                                    <input type="radio" name='selectSearch4' value='3' checked={'3' === selectAllFlag} />採用担当による手動予約
                                </div>
                            </RadioGroup>
                        </div>
                        <div className={classes.select}>
                            <div className={classes.subContent}>
                                <CustomScrollbar autoHeight={true} autoHeightMin={0} autoHeightMax={500} className={classes.seminarName}>
                                    {seminarList
                                        ? seminarList.map((seminar1, index1) => (
                                            <div>
                                                <div>{seminar1.contractMediaName}</div>
                                                {log80680(seminar1.seminarLevel2Dto,"return_seminar1.seminarLevel2Dto") && seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                                                    <>
                                                        <List>
                                                            <ListItem
                                                                button
                                                                selected={selectedSub === `${index1}_${index2}`}
                                                                onClick={handleSubClick(index1, index2)}>
                                                                <ListItemText
                                                                    primary={createSeminarName(seminar2)}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </>
                                                ))}
                                            </div>
                                        ))
                                        : null}
                                </CustomScrollbar>
                            </div>
                            <div style={{ height: '100%' }} className={`${classes.seminarName} ${classes.seminarName2}`}>
                                <CustomScrollbar autoHeight={true} autoHeightMax={500}>
                                    {seminarList
                                        ? seminarList.map((seminar1, index1) => (
                                            <>
                                                <SubTitle variant='h4'>
                                                    {seminar1.contractMediaName}
                                                </SubTitle>
                                                {log80680(seminar1.seminarLevel2Dto,"return_seminar1.seminarLevel2Dto") && seminar1.seminarLevel2Dto.map((seminar2, index2) => (
                                                    <>
                                                        <FieldGroup id={`sel_${index1}_${index2}`}>
                                                            <div className={classes.containerOfForm}>
                                                                <div>
                                                                    <Typography variant='h5' className={classes.subtitleOfForm}>
                                                                        {createSeminarName(seminar2)}
                                                                    </Typography>
                                                                </div>
                                                                <RadioGroup row className={classes.selectAllOfForm}>
                                                                    <FormLabel className={classes.labelTitle}>一括チェック</FormLabel>
                                                                    <div onClick={() => handleSelectAllOfForm("0", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='0' checked={rdoCheckedOfForm('0', seminar2)} />予約あり
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("1", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='1' checked={rdoCheckedOfForm('1', seminar2)} />予約なし
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("2", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='2' checked={rdoCheckedOfForm('2', seminar2)} />キャンセル
                                                                    </div>
                                                                    <div onClick={() => handleSelectAllOfForm("3", seminar2.seminarId, index1, index2)}>
                                                                        <input type="radio" name={`selectSearch5[${index1}_${index2}]`} value='3' checked={rdoCheckedOfForm('3', seminar2)} />採用担当による手動予約
                                                                    </div>
                                                                </RadioGroup>
                                                                <Divider className={classes.divider} />
                                                            </div>
                                                            { log80680(seminar2.seminarLevel3Dto,"return_seminar2.seminarLevel3Dto") && seminar2.seminarLevel3Dto.map((seminar3, index3) => (
                                                                <>
                                                                    <div>{createSeminarScheduleName(seminar3)}</div>
                                                                    <div className={classes.content}>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "0", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='0' checked={rdoCheckedZero(seminar3)} />予約あり
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "1", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='1' checked={rdoCheckedOne(seminar3)} />予約なし
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "2", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='2' checked={rdoCheckedTwo(seminar3)} />キャンセル
                                                                        </div>
                                                                        <div onClick={() => handleRdoDateSelect2(seminar3, "3", index1, index2)}>
                                                                            <input type="radio" name={`selectSearch3[${index1}_${index2}_${index3}]`} value='3' checked={rdoCheckedThree(seminar3)} />採用担当による手動予約
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </FieldGroup>
                                                    </>
                                                ))}
                                            </>
                                        ))
                                        : null}
                                </CustomScrollbar>
                            </div>
                        </div>
                    </RadioGroup>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        onClick={handleClick()}
                        color='primary'
                        variant='contained'
                        className={classes.cancel}>
                        キャンセルする
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color='primary'
                        variant='contained'
                        className={classes.confirmButton}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS481_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SeminarDateModal
