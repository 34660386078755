import React, { useRef } from 'react'
import { FieldProps } from 'formik'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { getYear, getMonth } from 'date-fns'
import { ja } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'
import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { dateTimeToString } from 'common/generalUtil'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .react-datepicker-wrapper': {
      flex: '1 0 0',
      marginRight: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
    '& .react-datepicker__navigation': {
      top: '13px'
    }
  },
  inputField: {
    width: '410px',
    minHeight: '40px',
    maxHeight: '40px',
    padding: '0px 20px 0 8px',
    background: 'none #fff',
    border: '1px solid #8593a6',
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    lineHeight: '30px',
  },
}))

export const useErrorStyle = makeStyles({
  helperText: {
    marginTop: '2px',
    background: 'none #fff',
    fontSize: '11px',
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
})

interface Props extends FieldProps, ReactDatePickerProps {
  className?: string
  changeState: Function
}

export default ({
  className,
  form,
  field,
  changeState,
  onChange,
  ...otherProps
}: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const { errors, touched } = form
  const inputRef = useRef<DatePicker>(null)
  const errorclasses = useErrorStyle()

  const handleChange = (date: Date | null) => {
    form.setFieldValue(name, date)
  }

  const handleIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.setFocus()
    }
  }

  let optionProps = {}

  if (otherProps.selectsStart) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
    }
  }

  if (otherProps.selectsEnd) {
    optionProps = {
      startDate: form.values.startDate,
      endDate: form.values.endDate,
      minDate: form.values.startDate,
    }
  }

  return (
    <FormControl fullWidth>
      <div className={`${classes.root} ${className}`}>
        <DatePicker
          className={classes.inputField}
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={10}
          dateFormat='yyyy/MM/dd HH:mm'
          locale={ja}
          ref={inputRef}
          placeholderText=''
          selected={isNaN(Date.parse(value)) ? null : new Date(value)}
          onChange={handleChange}
          timeCaption="時間"
          renderCustomHeader={({ 
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div>
              <button 
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={decreaseMonth} 
                disabled={prevMonthButtonDisabled}>
              </button>
              <div className="react-datepicker__current-month">
                {`${getYear(date)}年${getMonth(date) + 1}月`}
              </div>
              <button 
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}>
              </button>
            </div>
          )}
          {...otherProps}
          {...optionProps}
        />
        <button
          type='button'
          className='btn only-icon icon-input_calender'
          onClick={handleIconClick}
        />
      </div>
      {errors[name] && touched[name] ? (
        <p className={errorclasses.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}
