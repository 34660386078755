import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCARS060SaveRequest } from 'types/MCARS060/MCARS060SaveRequest'

//要求データ定義 begin
export interface requestParam {
    entrSearchCriteriaSettingId: string[]
    displayOrder: string,
    recruitmentManagementDivision:string,
    sysVersionNumber:number[]
  }
//要求データ定義 end


export interface tagList {
    tagSettingId: string// タグ設定ID
    tagName: string// タグ名称      
}

//データの種類を定義  start
//アクション設定 start
export interface ActionPreEditData {
    searchCriteriaName: string
    searchCriteria: string
    notApplicableSetting: string
    selectionFlowSettingIds:number[]
    selectionFlowSettingId:number 
    selectionNames:string[],
    progressStatusSettingId:number 
    progressStatusSettingIds:number[]
    progressNames:string[]
    recruitmentManagementDivisions:string[]
    systemSelectionFlowMasterIds:number[]
    tagContent:string
    recruitManagementFlagContent:string
    priorityOrder:number
    tagSettingIds:number[]
    entrySearchCriteriaSettingId:number
    recruitmentManagementFlagSettingId:number[]
    recruitmentManagementFlagOptionSettingId:number[]
    settingString:string[]
    settingNumber:number[]
    recruitmentManagementDivision:string
    sysVersionNumber:number
    tagList:tagList[]
}
//アクション設定 end
//アクション一覧 start
export interface ActionPreListData {
    searchCriteriaName: string
    searchCriteriaSummary: string
    actionSummary: string
    updateTime: string
    fullName: string
    priorityOrder: string
    entrySearchCriteriaSettingId: string,
    sysVersionNumber: number
  }
//アクション一覧 end
//アクション結合 start
interface actionData{
    actionPreList: ActionPreListData[]
    actionPreEdit:ActionPreEditData
    tagOpenFlag:boolean
}
//アクション結合 end
//データの種類を定義  end




//state初期化 begin
const initialState: actionData = {
    actionPreList:[],
    actionPreEdit:{
        searchCriteriaName: '',
        searchCriteria: '',
        notApplicableSetting: '',
        selectionFlowSettingId:0,
        selectionFlowSettingIds:[],
        selectionNames:[],
        progressStatusSettingId:0,
        progressStatusSettingIds:[],
        progressNames:[],
        recruitmentManagementDivisions:[],
        systemSelectionFlowMasterIds:[],
        tagContent:'',
        recruitManagementFlagContent:'',
        priorityOrder:0,
        tagSettingIds:[],
        entrySearchCriteriaSettingId:0,
        recruitmentManagementFlagSettingId:[],
        recruitmentManagementFlagOptionSettingId:[],
        settingString:[],
        settingNumber:[],
        recruitmentManagementDivision:'',
        sysVersionNumber: 1,
        tagList: []
    },
    tagOpenFlag:false
}
//state初期化 begin



const acionSlice = createSlice({
    name: 'Action',
    initialState,
    reducers: {
        fetchActionPreSet(state,action:PayloadAction<{entrySearchCriteriaSetting:number,setOpenActionPreDialog:Function}>) {
            return state
        },
        setActionPreSet(state, action: PayloadAction<ActionPreEditData>) {
            state.actionPreEdit=action.payload
        },
        saveActionPreSet(state,action: PayloadAction<{reqestInfo:MCARS060SaveRequest,closeFunction:Function,screenId:String}>){
            return state
        },
        setProps(state,action: PayloadAction<any>){
            state.actionPreEdit={...(state.actionPreEdit),...action.payload}
        },
        fetchActionPreList(state) {
            return state
          },
          setActionPreList(state, action: PayloadAction<ActionPreListData[]>) {
           state.actionPreList=action.payload
          },
          sendOrder(state, action: PayloadAction<requestParam>) {
            return state;
          },
          setTagOpenFlag(state, action: PayloadAction<boolean>) {
              state.tagOpenFlag = action.payload
              return state;
          },
          // 次期開発9月#51248 start
          toMCARS060Init(state, action: PayloadAction<{unActionSearchCriteriaNameList:string[],unActionSearchCriteriaList:string[],searchCriteria:any,screenId:string,searchName:string,setOpenActionPreDialog:Function}>) {
              return state;
          },
          // 次期開発9月#51248 end
    },
})

export const {
    fetchActionPreSet,
    setActionPreSet,
    saveActionPreSet,
    // 次期開発9月#51248 start
    toMCARS060Init,
    // 次期開発9月#51248 end
    setProps,
    fetchActionPreList,
    setActionPreList,
    sendOrder,
    setTagOpenFlag    
} = acionSlice.actions
export default acionSlice.reducer