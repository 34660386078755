import { initRequest, statusCheckRequest, pdfMakingRequest } from 'apis/MCAXS151Api'
import { initRequest as initRequestForMCAXS141 } from 'apis/MCAXS141Api'
import { openModal } from 'reducers/messageReducer'
import {
  getInitData,
  getStatusData,
  setInitData,
  setStatusData,
  setStartFlag,
  setCount,
  getInitDataForDownload,
  setCompanyId,
  pdfMaking,
} from 'reducers/pdfGeneratingReducer'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { now } from 'moment'
import { routeList } from 'routes/routes'
import history from 'utils/history'
// MCAXS151
// 画面を初期表示する。
function* getInitDataSaga(action: ReturnType<typeof getInitData>) {
  try {
    const data = yield call(initRequest, action.payload)
    yield put(setInitData(data))
    yield put(setStartFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 定期実行
function* getStatusDataSaga(action: ReturnType<typeof getStatusData>) {
  try {
    const data = yield call(statusCheckRequest, action.payload)
    yield put(setStatusData(data))
    yield put(setCount(now()))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAXS141
// 定期実行
function* getInitDataForDownloadSaga(
  action: ReturnType<typeof getInitDataForDownload>
) {
  try {
    const data = yield call(initRequestForMCAXS141, action.payload)
    yield put(setCompanyId(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 画面をPDF作成中。
function* getPDFMakingSaga(action: ReturnType<typeof pdfMaking>) {
  try {
    const data = yield call(pdfMakingRequest, action.payload)
    yield put(setInitData(data))
    yield put(setStartFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* selectionFlowSaga() {
  yield all([
    // MCAXS151
    // 初期表示
    takeEvery(getInitData, getInitDataSaga),
    // 定期実行
    takeEvery(getStatusData, getStatusDataSaga),
    // MCAXS151
    takeEvery(getInitDataForDownload, getInitDataForDownloadSaga),
    takeEvery(pdfMaking, getPDFMakingSaga),
  ])
}
