import request from 'utils/request'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'

export const getInitApi = (recruitmentManagementDivision: string, initRequest: MCAXS111initRequest) =>
  request({
    url: `/MCAXS111/init/${recruitmentManagementDivision}`,
    method: 'post',
    data: initRequest
  })

export const authorizeCheckApi = () =>
request({
  url: "/MCAXS111/authorizeCheck",
  method: 'post',
})