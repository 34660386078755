import request from 'utils/request'
import { MCAPS030QueryRequest } from 'types/MCAPS030/MCAPS030QueryRequest'

// 初期表示
export const initEntryUploadErrorComfirmApi = (param: MCAPS030QueryRequest) =>
  request({
    url: '/MCAPS030/init',
    method: 'post',
    data: param
  })

// 再アップロードする
export const reuploadApi = (param: MCAPS030QueryRequest) =>
  request({
    url: '/MCAPS030/reuploading',
    method: 'post',
    data: param
  })