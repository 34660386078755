
import request from 'utils/request'

export const progressStatusChangeInitRequest = (param: number[]) =>
  request({
    url: '/MCAXS110/init',
    method: 'post',
    data: param,
  })

export const recommendCheckRequest = (param: string) => {
  const data = new FormData()
  data.append('requestDataPayload', param)
  return request({
    url: "/MCAXS110/recommendCheck",
    method: 'post',
    data: data,
  })
}

export const updateProgressRequest = (payload: string) => {
  const data = new FormData()
  data.append('requestDataPayload', payload)
  return request({
    url: '/MCAXS110/ok',
    method: 'post',
    data: data,
  })
}