import {MCBJS010InitDisplayRequest} from "types/MCBJS010/MCBJS010InitDisplayRequest"
import {MCBJS010UpdateDisplayStatusRequest} from "types/MCBJS010/MCBJS010UpdateDisplayStatusRequest"
import {MCBJS010CopySubmissionReqTemplateRequest} from "types/MCBJS010/MCBJS010CopySubmissionReqTemplateRequest"
import request from 'utils/request'
import { SelectTemplateResultDto } from "reducers/submissionReqTemplateReducer"
import { MCBJS010EditSubmissionReqTemplateRequest } from "types/MCBJS010/MCBJS010EditSubmissionReqTemplateRequest"

export const selectSubmissionReqTemplateListRequest = (param:MCBJS010InitDisplayRequest) =>
  request({
    url: '/MCBJS010/init',
    method: 'post',
    data: param,
})

export const addSubmissionReqTemplateListRequest = (recruitmentManagementDivision:string) =>
  request({
    url:  `/MCBJS010/register/${recruitmentManagementDivision}`,
    method: 'post',
})

export const executeSubmissionReqTemplateListRequest = (param:MCBJS010UpdateDisplayStatusRequest) =>
  request({
    url: '/MCBJS010/execute',
    method: 'post',
    data: param,
})
export const findSubmissionReqTemplateListRequest = 
(param: MCBJS010EditSubmissionReqTemplateRequest) =>
  request({
    url: '/MCBJS010/find',
    method: 'post',
    data: param,
})

export const copySubmissionReqTemplateListRequest = (param:MCBJS010CopySubmissionReqTemplateRequest) =>
  request({
    url: '/MCBJS010/copy',
    method: 'post',
    data: param,
})

export const updateSubmissionReqTemplateSortRequest = (param:SelectTemplateResultDto[]) =>
  request({
    url: '/MCBJS010/displayOrder',
    method: 'post',
    data: param,
})