import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getMessage } from 'common/messageUtil'
import { MCARS060SaveRequest } from 'types/MCARS060/MCARS060SaveRequest'
import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogContentText,
  Grid,
  DialogActions,
  Box,
  colors,
  DialogContent,
} from '@material-ui/core'
import {
  Title,
  SubTitle,
  FieldGroup,
  Page,
  FormField,
  SelectForMessage,
  LoadingDialog,
} from 'components'
import SelectForMessageReact from './SelectForMessageReact'
import Checkbox from './ActionSetCheckBox'
import Toggle from './ActionSetToggle'
import { Formik, Form, Field } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { RootState } from 'reducers'
import { fetchActionPreList, setTagOpenFlag, setActionPreSet } from 'reducers/actionPreReducer'
import {
  setProps,
  saveActionPreSet,
  fetchActionPreSet,
} from '../../reducers/actionPreReducer'
import { magiContants } from 'utils/contants'
import color from '@material-ui/core/colors/amber'
import Select from 'pages/MCAXS170/Select'
import { setSelectedTag, setTagSettings } from 'reducers/tagManagementReducer'
import { onKeyDown } from 'common/generalUtil'

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(5),
  },
  wordTitleProps: {
    marginTop: theme.spacing(1.5),
    fontWeight: 600,
  },
  radioCss: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(1.5),
  },
  desc: {
    marginTop: theme.spacing(5),
  },
  moveGrid: {
    alignSelf: 'center',
  },
  fieldGroup: {
    marginBottom: '0px',
  },
  cancelButton: {
    color: '#1a1a1a',
    backgroundColor: '#e0e0e0',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: '#cbcbcb',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    width: 150,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    width: 150,
  },
  inactiveButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    border: '1px solid',
    borderColor: '#3380cc',
    borderRadius: '4px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#4596e6',
    },
    '&:disabled': {
      color: '#999999',
      backgroundColor: '#e0e0e0',
      borderColor: '#cccccc',
    },
  },
  buttonGroup: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(5)
  },
  textContainer: {
    height: '100px',
  },
  searchCriteriaBox: {
    marginLeft: theme.spacing(-1),
  },
  formField: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1.5),
  },
  formFieldTop: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(0.5),
  },
  gridRight: {
    marginTop: theme.spacing(0.5),
  },
  formFieldBottom: {
    marginLeft: theme.spacing(5),
  },
  inputField: {
    flex: 3,
  },
  dialog: {
    '& .MuiDialog-scrollPaper': {
      alignItems: window.navigator.userAgent
        .toLocaleLowerCase()
        .match(/(trident)\/([\d.]+)/) ? 'stretch' : 'center',
    },
    '& .MuiDialog-paperScrollPaper': {
      overflow: 'auto',
      overflowY: 'hidden',
    },
    '& .MuiDialogContent-root': {
      minWidth: '960px',
    },
  },
}))

interface props {
  entrySearchCriteriaSettingId: string
  actionPreFormModal: boolean
  setActionPreFormModal: Function
  screenId: string
}
const MACRS060 = ({
  entrySearchCriteriaSettingId,
  actionPreFormModal,
  setActionPreFormModal,
  screenId,
}: props) => {
  const [openSureBack, setOpenSureBack] = useState(false)
  const [openSureSubmit, setOpenSureSubmit] = useState(false)
  const [openCheck, setOpenCheck] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [formINfo, setFormInfo] = useState<any>()
  const classes = useStyles()
  const dispatch = useDispatch()
  const RecruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )

  const data = useSelector((state: RootState) => state.actionPre.actionPreEdit)
  const getFormValues = () => {
    return {
      useFilter: 1,
    }
  }
  const selectionOptions = data.selectionFlowSettingIds.reduce(
    (acc: Array<any>, cur, index) => {
      if (
        (!acc
          .map(cur1 => {
            return cur1.value
          })
          .includes(cur)) &&
          (data.recruitmentManagementDivisions[index] != '1' ||
          data.systemSelectionFlowMasterIds[index] == null)
      ) {
        acc.push({
          label: data.selectionNames[index],
          value: data.selectionFlowSettingIds[index],
        })
      }
      return acc
    },
    []
  )
  const progressOptions = data.progressStatusSettingIds.reduce(
    (acc: Array<any>, cur, index) => {
      if (
        data.selectionFlowSettingIds
          .reduce((acc1: Array<number>, cur1, index1) => {
            if (cur1 == data.selectionFlowSettingId) {
              acc1.push(index1)
            }
            return acc1
          }, [])
          .includes(index)
      ) {
        var obj: any = new Object()
        obj.label = data.progressNames[index]
        obj.value = data.progressStatusSettingIds[index]
        acc.push(obj)
      }
      return acc
    },
    [{ value: -1, label: '（以下から選択）' }]
  )

  const changeSelectionFlag = (event: any) => {
    dispatch(
      setProps({
        selectionFlowSettingId: event.target.value,
        progressStatusSettingId: -1,
      })
    )
  }
  const changeProgressFlag = (event: any) => {
    dispatch(setProps({ progressStatusSettingId: event.target.value }))
  }
  const executantOptionList = [
    { value: 1, label: '使う', id: 'use' },
    { value: 0, label: '使わない', id: 'notuse' },
  ]

  const changeNotApplicableSetting = (newValue: any) => {
    dispatch(setProps({ notApplicableSetting: newValue }))
  }
  const toggleChange = (value: any) => {
    if (value == '0') {
      var obj: any = new Object()
      obj.progressStatusSettingId = -1
      obj.selectionFlowSettingId = -1
      obj.notApplicableSetting = 0
      obj.tagContent = ''
      obj.recruitManagementFlagContent = ''
      dispatch(setProps(obj))
      dispatch(setSelectedTag([]))
    }
  }
  const sendMessage = () => {
    dispatch(
      saveActionPreSet({
        reqestInfo: formINfo,
        closeFunction: setActionPreFormModal,
        screenId,
      })
    )
    setOpenSureSubmit(false)
    setOpenDel(false)
  }

  const tagSettingIds = useSelector((state: RootState) => state.tagManagement.TagSettings)
  const sendToBehind = (info: any) => {
    if (
      info.useFilter == 1 &&
      (data.notApplicableSetting == null || data.notApplicableSetting == '0') &&
      data.progressStatusSettingId == -1 &&
      data.selectionFlowSettingId == -1 &&
      data.tagContent == '' &&
      data.recruitManagementFlagContent == ''
    ) {
      setOpenCheck(true)
    } else {
      const saveData: MCARS060SaveRequest = {
        searchCriteriaName: data.searchCriteriaName,
        useFilter: info.useFilter,
        notApplicableSetting: data.notApplicableSetting,
        entrySearchCriteriaSettingId: data.entrySearchCriteriaSettingId,
        selectionFlowSetting: data.notApplicableSetting == '1' ? -1 : data.selectionFlowSettingId,
        progressStatus: data.progressStatusSettingId,
        tagSetting: tagSettingIds && tagSettingIds.length > 0  ? tagSettingIds : data.tagSettingIds,
        recruitmentManageFlagSetting: data.recruitmentManagementFlagSettingId,
        sysVersionNumber: data.sysVersionNumber,
        priorityOrder: data.priorityOrder,
        recruitmentManagementFlagOptionSettingId:
          data.recruitmentManagementFlagOptionSettingId,
        settingString: data.settingString,
        settingNumber: data.settingNumber,
        selectionFlowSettingText:
          data.selectionNames[
          data.selectionFlowSettingIds.findIndex(v => {
            return v == data.selectionFlowSettingId
          })
          ],
        progressStatusText:
          data.progressNames[
          data.progressStatusSettingIds.findIndex(v => {
            return v == data.progressStatusSettingId
          })
          ],
        tagSettingText: data.tagContent,
        recruitmentManageFlagSettingText: data.recruitManagementFlagContent,
        recruitmentManagementDivision: RecruitmentManagementDivision,
        recruitmentManagementFlagSettingId: [1, 1, 1],
      }
      setFormInfo(saveData)
      if (info.useFilter == '0') {
        setOpenDel(true)
      } else {
        setOpenSureSubmit(true)
      }
    }
  }
  const back = () => {
    setOpenSureBack(false)
    setActionPreFormModal(false)
    dispatch(setTagSettings([]))
  }

  const defaultProps = {
    bgcolor: 'background.paper',
    m: 1,
    style: { width: '5rem', height: '5rem' },
    borderColor: 'text.primary',
  }

  const [openTagDialog, setOpenTagDialog] = useState(false)
  const handleOpenTagDialog = () => {
    dispatch(setTagOpenFlag(true))
    setOpenTagDialog(true)
  }

  const handleClose = (tagSettingList: Map<string, string>) => {
    const tagSequence = [...tagSettingList.values()]
      .join('、')
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    const tagContentDate =
      tagSettingList.size == 0
        ? ''
        : "<span style='font-weight: 600;'>" + tagSequence + '</span>'
    dispatch(setActionPreSet({ ...data, tagSettingIds: [...tagSettingList.keys()].map(Number), tagContent: tagContentDate }))
    setOpenTagDialog(false)
  }

  const requestVal = {
    screenId: 'MCARS060',
    jobSeekerId: [],
    selectionManagementId: [],
    entryId: [],
  }

  return (
    <>
      {actionPreFormModal ? <LoadingDialog className='overlay' /> : null}
      <Dialog
        open={actionPreFormModal}
        fullWidth
        maxWidth='md'
        className={classes.dialog + ' mainDialog'}>
        <DialogContent>
          <div>
            <SubTitle>アクション設定</SubTitle>
          </div>
          <Formik
            onSubmit={sendToBehind}
            initialValues={getFormValues()}
            enableReinitialize
            render={props => (
              <Form onKeyDown={onKeyDown}>
                <FieldGroup className={classes.fieldGroup}>
                  <Grid item xs={11}>
                    <Typography
                      variant='body1'
                      gutterBottom
                      className={classes.description}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getMessage(
                            magiContants.MESSAGECODE_MCARS060_000
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Typography variant='h6' className={classes.wordTitleProps}>
                    <span>
                      <b>検索条件名</b>
                    </span>
                  </Typography>
                  <p>
                    &nbsp;&nbsp;{data.searchCriteriaName}
                  </p>
                  <Typography variant='h6' className={classes.wordTitleProps}>
                    <span>
                      <b>検索条件</b>
                    </span>
                  </Typography>

                  <Box
                    display='flex'
                    justifyContent='center'
                    className={classes.searchCriteriaBox}>
                    <Box
                      border={1}
                      {...defaultProps}
                      style={{
                        overflow: 'auto',
                        width: '100%',
                        height: '210px',
                        padding: '7px',
                      }}
                      dangerouslySetInnerHTML={{ __html: data.searchCriteria }}
                    />
                  </Box>

                  <Typography variant='h6' className={classes.wordTitleProps}>
                    <span>
                      <b>アクションの設定有無</b>
                    </span>
                    <br />
                  </Typography>
                  <Field
                    name={'useFilter'}
                    className={classes.radioCss}
                    optionList={executantOptionList}
                    component={Toggle}
                    pageController={toggleChange}
                  />

                  <Typography variant='h6' className={classes.wordTitleProps}>
                    <span>
                      <b>フィルターのアクション</b>
                    </span>
                  </Typography>
                  <FormField className={classes.formField}>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='対象外設定' />
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        field={{
                          name: 'notApplicableSetting',
                          value: data.notApplicableSetting,
                        }}
                        label='対象外にする'
                        component={Checkbox}
                        dis={props.values.useFilter == 0}
                        changeReduce={changeNotApplicableSetting}
                      />
                    </Grid>
                  </FormField>
                  <FormField className={classes.formFieldTop}>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='選考フロー設定' />
                    </Grid>
                    <Grid item xs={5} className={classes.gridRight}>
                      <Field
                        field={{
                          name: 'selectionFlowSettingId',
                          value: data.selectionFlowSettingId,
                        }}
                        key={selectionOptions}
                        optionList={selectionOptions}
                        className={classes.inputField}
                        component={SelectForMessageReact}
                        disabled={
                          props.values.useFilter == 0 ||
                          data.notApplicableSetting == '1'
                        }
                        onChange={(event: any) => changeSelectionFlag(event)}
                      />
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </FormField>
                  {/* <FormField className={classes.formFieldTop}>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='選考ステップ設定' />
                    </Grid>
                    <Grid item xs={5} className={classes.formFieldTop}>
                      <Field
                        field={{name:"progressStatusSettingId",value:data.progressStatusSettingId}}
                        key={progressOptions}
                        optionList={progressOptions}
                        className={classes.inputField}
                        component={SelectForMessage}
                        disabled={props.values.useFilter == 0 ||
                          data.notApplicableSetting == '1' ||
                          data.selectionFlowSettingId == -1
                        }
                        onChange={(event: any) => changeProgressFlag(event)}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.moveGrid}></Grid>
                  </FormField> */}
                  <FormField className={classes.formFieldBottom}>
                    <Grid item xs={3}>
                      <Title title='タグ設定' />
                    </Grid>
                    <Grid item xs={8}>
                      <Button
                        className={classes.inactiveButton}
                        variant='contained'
                        size='medium'
                        style={{ width: '150px' }}
                        onClick={handleOpenTagDialog}
                        disabled={props.values.useFilter == 0}>
                        タグ設定
                      </Button>
                    </Grid>
                  </FormField>
                  <FormField>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='' />
                    </Grid>
                    <Grid item xs={8}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.tagContent,
                        }}></div>
                    </Grid>
                  </FormField>
                  {/* <FormField>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='管理項目設定' />
                    </Grid>
                    <Grid item xs={8}>
                      <Button
                        className={classes.inactiveButton}
                        variant='contained'
                        disabled={
                          props.values.useFilter == 0 ||
                          data.notApplicableSetting == '1'
                        }>
                        管理項目設定
                      </Button>
                    </Grid>
                  </FormField>
                  <FormField>
                    <Grid item xs={3} className={classes.moveGrid}>
                      <Title title='' />
                    </Grid>
                    <Grid item xs={8}>
                      <div dangerouslySetInnerHTML={{__html:data.recruitManagementFlagContent}}></div>
                    </Grid>
                  </FormField> */}
                  <FormField className={classes.buttonGroup}>
                    <Grid item xs={5}>
                      <Button
                        className={classes.cancelButton}
                        variant='contained'
                        size='medium'
                        onClick={() => {
                          setOpenSureBack(true)
                        }}>
                        キャンセルする
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        className={classes.confirmButton}
                        variant='contained'
                        size='medium'
                        type='submit'>
                        保存する
                      </Button>
                    </Grid>
                  </FormField>
                </FieldGroup>
                <FormField>
                  <Dialog open={openSureBack}>
                    <DialogTitle>{getMessage('MCARS060-008')}</DialogTitle>
                    <DialogActions>
                      <Button
                        color='default'
                        onClick={() => setOpenSureBack(false)}>
                        キャンセル
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          back()
                        }}>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={openSureSubmit}>
                    <DialogTitle>{getMessage('MCARS060-010')}</DialogTitle>
                    <DialogActions>
                      <Button
                        color='default'
                        onClick={() => setOpenSureSubmit(false)}>
                        キャンセル
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          sendMessage()
                        }}>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={openDel}>
                    <DialogTitle>{getMessage('MCARS060-009')}</DialogTitle>
                    <DialogActions>
                      <Button color='default' onClick={() => setOpenDel(false)}>
                        キャンセル
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          sendMessage()
                        }}>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={openCheck}>
                    <DialogTitle>{getMessage('MCARS060-003')}</DialogTitle>
                    <DialogActions>
                      <Button color='primary' onClick={() => setOpenCheck(false)}>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Select
                    open={openTagDialog}
                    requestVal={requestVal}
                    onCloseApplicantList={handleClose}
                    onCancel={() => setOpenTagDialog(false)}></Select>
                </FormField>
              </Form>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MACRS060
