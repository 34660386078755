import { MCBHS100InitRequest } from 'types/MCBHS100/MCBHS100InitRequest'
import { MCBHS100RegisterRequest } from 'types/MCBHS100/MCBHS100RegisterRequest'
import request from 'utils/request'

export const initRequest = (apiData: MCBHS100InitRequest) => {
  return request({
    url: '/MCBHS100/init',
    method: 'post',
    data: apiData,
  })
}
export const redistRequest = (
  apiData: MCBHS100RegisterRequest
) => {
  const data = new FormData()
  data.append('sendRegister', encodeURIComponent(JSON.stringify(apiData)))

  return request({
    url: '/MCBHS100/sendRegister',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
