import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventModalInfo } from 'componentsHsc/AdvancedSearch/Config/eventModalConfig'
import { SeminarDateModalInfo } from 'componentsHsc/AdvancedSearch/Config/seminarDateModalConfig'
import { SeminarMediaModalInfo } from 'componentsHsc/AdvancedSearch/Config/seminarMediaModalConfig'
import { ManagementModalInfo } from 'componentsHsc/AdvancedSearch/Config/managementModalConfig'
import { EntryExitModalInfo } from 'componentsHsc/AdvancedSearch/Config/entryExitModalConfig'
import { EntryDateModalInfo } from 'componentsHsc/AdvancedSearch/Config/entryDateModalConfig'
import { StudentModalInfo } from 'componentsHsc/AdvancedSearch/Config/studentModalConfig'
import { MCAXS561QueryRequest } from 'types/MCAXS561/MCAXS561QueryRequest'
import { EntryReplyModalInfo } from 'componentsHsc/AdvancedSearch/Config/entryReplyModalConfig'
import { SchoolGroupModalInfo } from 'componentsHsc/AdvancedSearch/Config/schoolGroupModalConfig'
import { MCAXS591InitRequest } from 'types/MCAXS591/MCAXS591InitRequest'
import { StudentDetailsModalInfo } from 'componentsHsc/AdvancedSearch/Config/studentDetailsModalConfig'
import { MCAXS601InitRequest } from 'types/MCAXS601/MCAXS601InitRequest'
import { SchoolDetailsModalInfo } from 'componentsHsc/AdvancedSearch/Config/schoolDetailsModalConfig'
import { MCAXS611InitRequest } from 'types/MCAXS611/MCAXS611InitRequest'
import { SchoolDepartmentDetailsInfo } from 'componentsHsc/AdvancedSearch/Config/schoolDepartmentDetailsConfig'
import { SendMessegeTemplateObj, TestObj ,MCAXS541ReturnDisplayDto} from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'
import { createSelenctionFlowInfo, createPassFailInfo, FlowMasterModalInfo } from 'componentsHsc/AdvancedSearch/Config/SelectionFlowStepPassFailModalConfig'
import { WebInterviewParticipationModalInfo } from 'componentsHsc/AdvancedSearch/Config/webInterviewParticipationModalConfig'

const initialState = {
  eventModalInfo: {} as EventModalInfo,
  seminarDateModalInfo: {} as SeminarDateModalInfo,
  // 24KH #61555 start
  webInterviewParticipationModalInfo: {} as WebInterviewParticipationModalInfo,
  // 24KH #61555 end
  seminarMediaModalInfo: {} as SeminarMediaModalInfo,
  managementModalInfo: {} as ManagementModalInfo,
  entryExitModalInfo: {} as EntryExitModalInfo,
  entryDateModalInfo: {} as EntryDateModalInfo,
  studentModalInfo: {} as StudentModalInfo,
  entryReplyModalInfo: {} as EntryReplyModalInfo,
  schoolGroupModalInfo: {} as SchoolGroupModalInfo,
  studentDetailsModalInfo: {} as StudentDetailsModalInfo,
  schoolDetailsModalInfo: {} as SchoolDetailsModalInfo,
  schoolDepartmentDetailsInfo: {} as SchoolDepartmentDetailsInfo,
  schoolGroupDetailsFlag: false,
  schoolDetailsDepartmentFlag: false,
  studentDetailsFlag: false,
  ImagesSubmitFlag: true,
  GABSubmitFlag: true,
  OPQSubmitFlag: true,
  CABSubmitFlag: true,
  ImagesModalObj: [] as TestObj[] | null,
  GABModalObj: [] as TestObj[] | null,
  OPQModalObj: [] as TestObj[] | null,
  CABModalObj: [] as TestObj[] | null,
  SendMessegeTemplateObj: [] as SendMessegeTemplateObj[],
  // phase2 start
  mCAXS541ReturnDisplayDto: {} as MCAXS541ReturnDisplayDto,
  // phase2 end
  flowMasterModalInfo: {} as FlowMasterModalInfo,
  operationLogFor88924: [] as string[],
}

const advancedSearchModalSlice = createSlice({
  name: 'advancedsearchmodal',
  initialState,
  reducers: {
    // イメジス情報指定モーダル情報を取得
    getMcaxs441Init(state) {
      return state
    },
    // GAB情報指定モーダル情報を取得
    getMcaxs451Init(state) {
      return state
    },
    // CAB情報指定モーダル情報を取得
    getMcaxs461Init(state) {
      return state
    },
    // イベント参加状況指定モーダル情報を取得
    getMcaxs471Init(state) {
      return state
    },
    // イベント参加状況指定モーダル情報を設定
    setMcaxs471Init(state, action: PayloadAction<EventModalInfo>) {
      return {
        ...state,
        eventModalInfo: action.payload,
      }
    },
    // セミナー受付日程・予約状況指定モーダル情報を取得
    getMcaxs481Init(state) {
      return state
    },
    // セミナー受付日程・予約状況指定モーダル情報を設定
    setMcaxs481Init(state, action: PayloadAction<SeminarDateModalInfo>) {
      return {
        ...state,
        seminarDateModalInfo: action.payload,
      }
    },
    // Webセミナー視聴状況指定モーダル情報を取得
    getMcaxs491Init(state) {
      return state
    },
    // Webセミナー視聴状況指定モーダル情報を設定
    setMcaxs491Init(state, action: PayloadAction<SeminarMediaModalInfo>) {
      return {
        ...state,
        seminarMediaModalInfo: action.payload,
      }
    },
    // 管理項目指定モーダル情報を取得
    getMcaxs501Init(state) {
      return state
    },
    // 管理項目指定モーダル情報を設定
    setMcaxs501Init(state, action: PayloadAction<ManagementModalInfo>) {
      return {
        ...state,
        managementModalInfo: action.payload,
      }
    },
    // エントリー有無指定モーダル情報を取得
    getMcaxs511Init(state, action: PayloadAction<() => void>) {
      return state
    },
    // エントリー有無指定モーダル情報を設定
    setMcaxs511Init(state, action: PayloadAction<EntryExitModalInfo>) {
      return {
        ...state,
        entryExitModalInfo: action.payload,
      }
    },
    // 都道府県指定モーダル情報を取得
    getMcaxs521Init(state) {
      return state
    },
    // エントリー日指定モーダル情報を取得
    getMcaxs531Init(state, action: PayloadAction<{initializeErrors: (data:any) => void}>) {
      return state
    },
    // エントリー日指定モーダル情報を設定
    setMcaxs531Init(state, action: PayloadAction<EntryDateModalInfo>) {
      if (action.payload){
        if (action.payload.entryBoxInfoList){
          action.payload.entryBoxInfoList.forEach((item) =>{
            item.contractMediaId = item.contractMediaId.toString()
            item.entryBoxId = item.entryBoxId.toString()
          })
        }
        if (action.payload.externalEntryInfoList){
          action.payload.externalEntryInfoList.forEach((item) =>{
            item.mediaSettingId = item.mediaSettingId.toString()
          })
        }
      }
      return {
        ...state,
        entryDateModalInfo: action.payload,
      }
    },
    // メッセージ指定モーダル情報を取得
    getMcaxs541Init(state) {
      return state
    },
    // メッセージ指定モーダル情報を設定
    setMcaxs541Init(state , action: PayloadAction<MCAXS541ReturnDisplayDto>) {
      state.mCAXS541ReturnDisplayDto = action.payload
      return state
    },
    // 学生分類指定モーダル情報を取得
    getMcaxs551Init(state) {
      return state
    },
    // 学生分類指定モーダル情報を設定
    setMcaxs551Init(state, action: PayloadAction<StudentModalInfo>) {
      return {
        ...state,
        studentModalInfo: action.payload,
      }
    },
    // アンケート回答内容指定モーダル情報を取得
    getMcaxs561Init(state, action: PayloadAction<MCAXS561QueryRequest>) {
      return state
    },
    // アンケート回答内容指定モーダル情報を設定
    setMcaxs561Init(state, action: PayloadAction<EntryReplyModalInfo>) {
      return {
        ...state,
        entryReplyModalInfo: action.payload,
      }
    },
    // 学校グループ指定モーダル情報を取得
    getMcaxs571Init(state) {
      return state
    },
    // 学校グループ指定モーダル情報を設定
    setMcaxs571Init(state, action: PayloadAction<SchoolGroupModalInfo>) {
      return {
        ...state,
        schoolGroupModalInfo: action.payload,
      }
    },
    // OPQ情報指定モーダル情報を取得
    getMcaxs581Init(state) {
      return state
    },
    // 学科詳細指定モーダル情報を取得
    getMcaxs591Init(state, action: PayloadAction<MCAXS591InitRequest>) {
      return state
    },
    // 学科詳細指定モーダル情報を設定
    setMcaxs591Init(state, action: PayloadAction<StudentDetailsModalInfo>) {
      return {
        ...state,
        studentDetailsModalInfo: action.payload,
      }
    },
    // 学校詳細指定モーダル情報を取得
    getMcaxs601Init(state, action: PayloadAction<MCAXS601InitRequest>) {
      return state
    },
    // 学校詳細指定モーダル情報を設定
    setMcaxs601Init(state, action: PayloadAction<SchoolDetailsModalInfo>) {
      return {
        ...state,
        schoolDetailsModalInfo: action.payload,
      }
    },
    // 学部・学科詳細指定モーダル情報を取得
    getMcaxs611Init(state, action: PayloadAction<MCAXS611InitRequest>) {
      return state
    },
    // 学部・学科詳細指定モーダル情報を設定
    setMcaxs611Init(state, action: PayloadAction<SchoolDepartmentDetailsInfo>) {
      return {
        ...state,
        schoolDepartmentDetailsInfo: action.payload,
      }
    },
    setSchoolGroupDetailsFlag(state, action: PayloadAction<boolean>){
      return {
        ...state,
        schoolGroupDetailsFlag: action.payload,
      }
    },
    setSchoolDetailsDepartmentFlag(state, action: PayloadAction<boolean>){
      return {
        ...state,
        schoolDetailsDepartmentFlag: action.payload,
      }
    },
    setStudentDetailsFlag(state, action: PayloadAction<boolean>){
      return {
        ...state,
        studentDetailsFlag: action.payload,
      }
    },
    // イメジス情報を指定
    setImagesSubmitFlag(state, action: PayloadAction<boolean>){
      state.ImagesSubmitFlag = action.payload
      return state
    },
    setImagesModalObj(state, action: PayloadAction<TestObj[] | null>){
      state.ImagesModalObj = action.payload
      return state
    },
    // GAB情報を指定
    setGABSubmitFlag(state, action: PayloadAction<boolean>){
      state.GABSubmitFlag = action.payload
      return state
    },
    setGABModalObj(state, action: PayloadAction<TestObj[] | null>){
      state.GABModalObj = action.payload
      return state
    },
    // OPQ情報を指定
    setOPQSubmitFlag(state, action: PayloadAction<boolean>){
      state.OPQSubmitFlag = action.payload
      return state
    },
    setOPQModalObj(state, action: PayloadAction<TestObj[] | null>){
      state.OPQModalObj = action.payload
      return state
    },
    // CAB情報を指定
    setCABSubmitFlag(state, action: PayloadAction<boolean>){
      state.CABSubmitFlag = action.payload
      return state
    },
    setCABModalObj(state, action: PayloadAction<TestObj[] | null>){
      state.CABModalObj = action.payload
      return state
    },
    // 選考フロー×選考ステップ×合否指定モーダル情報を取得
    getMcaxs691Init(state) {
      return state
    },
    // 選考フロー×選考ステップ×合否指定モーダル情報を設定
    setMcaxs691Init(state, action: PayloadAction<FlowMasterModalInfo>) {
      // 選考フロー×選考ステップ×合否指定モーダル_初期表示用データ格納情報
      const flowMasterModalInfo: FlowMasterModalInfo = {
        flowMasterInfoList: createPassFailInfo(action.payload), // 選考フロー×選考ステップ×合否情報
        selectionFlowTitleList: createSelenctionFlowInfo(action.payload), // 選考フロー情報
      }
      return {
        ...state,
        flowMasterModalInfo: flowMasterModalInfo,
      }
    },
    // 24KH #61555 start
    // WEB面談参加状況を指定モーダル情報を取得
    getMcaxs621Init(state) {
      return state
    },
    // WEB面談参加状況を指定モーダル情報を設定
    setMcaxs621Init(state, action: PayloadAction<WebInterviewParticipationModalInfo>) {
      return {
        ...state,
        webInterviewParticipationModalInfo: action.payload,
      }
    },
    // 24KH #61555 end
    pushOperationLogFor88924(state, action: PayloadAction<string>) {
      const MAX_LENGTH_OPERATION_LOG = 10
      const operationLogFor88924 = state.operationLogFor88924
      operationLogFor88924.push(action.payload)
      if(operationLogFor88924.length > MAX_LENGTH_OPERATION_LOG){
        operationLogFor88924.shift()
      }
      state.operationLogFor88924 = operationLogFor88924
      return state
    },
  },
})

export const {
  getMcaxs441Init,
  getMcaxs451Init,
  getMcaxs461Init,
  getMcaxs471Init,
  setMcaxs471Init,
  getMcaxs481Init,
  setMcaxs481Init,
  getMcaxs491Init,
  setMcaxs491Init,
  getMcaxs501Init,
  setMcaxs501Init,
  getMcaxs511Init,
  setMcaxs511Init,
  getMcaxs521Init,
  getMcaxs531Init,
  setMcaxs531Init,
  getMcaxs541Init,
  setMcaxs541Init,
  getMcaxs551Init,
  setMcaxs551Init,
  getMcaxs561Init,
  setMcaxs561Init,
  getMcaxs571Init,
  setMcaxs571Init,
  getMcaxs581Init,
  getMcaxs591Init,
  setMcaxs591Init,
  getMcaxs601Init,
  setMcaxs601Init,
  getMcaxs611Init,
  setMcaxs611Init,
  setSchoolGroupDetailsFlag,
  setSchoolDetailsDepartmentFlag,
  setStudentDetailsFlag,
  // イメジス情報を指定
  setImagesSubmitFlag,
  setImagesModalObj,
  // GAB情報を指定
  setGABSubmitFlag,
  setGABModalObj,
  // OPQ情報を指定
  setOPQSubmitFlag,
  setOPQModalObj,
  // CAB情報を指定
  setCABSubmitFlag,
  setCABModalObj,
  getMcaxs691Init,
  setMcaxs691Init,
  // 24KH #61555 start
  getMcaxs621Init,
  setMcaxs621Init,
  // 24KH #61555 end
  pushOperationLogFor88924,
} = advancedSearchModalSlice.actions
export default advancedSearchModalSlice.reducer
