import request from 'utils/request'
import { MCAXS030OrderRequest } from 'types/MCAXS030/MCAXS030OrderRequest'


export const initSetDateSagaRequest = () =>
  request({
    url: '/MCAXS030/init',
    method: 'post',
})
export const settingRequest = (apiData: MCAXS030OrderRequest) =>
  request({
    url: '/MCAXS030/setting',
    method: 'post',
    data:apiData
})