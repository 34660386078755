import moment from 'moment-timezone'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { isEmpty, now, stringToDateTime } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import {
  confirm,
  getInit,
  getPreview,
  saveDraft,
  setCode,
  setInit,
  setPreview,
  setScreenDisplayItems,
  openConfirmModal,
  setCloseWindow,
  destinationDownload,
  openDetailDialog,
  setChangedBody,
} from 'reducers/submissionRequestForcedStopReducer'
import { openModal } from 'reducers/messageReducer'
import {
  initRequest,
  draftRequest,
  previewRequest,
  confirmRequest,
  destinationDownloadRequest,
} from 'apis/MCBHS090Api'
import {
  setSearchCriteria,
  setDetailDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
moment.tz.setDefault('Asia/Tokyo')

const ALL_CONTRACT_MEDIA = 'MAGI' //すべての企業契約応募経路が対象

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const serverResponse = yield call(initRequest, action.payload)
    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* saveDraftSaga(action: ReturnType<typeof saveDraft>) {
  try {
    const { formData } = action.payload

    yield call(draftRequest, formData)
    yield put(setCode(200))
    yield put(setCloseWindow(true))
  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest, formData)
    yield put(setPreview(serverResponse.previewDataList))
    yield put(setChangedBody(serverResponse.changedBody))
    action.payload.setHasDisplayPreview(true)
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* confirmSaga(action: ReturnType<typeof confirm>) {
  try {
    const formData = action.payload.formData
    const forbiddenWords: string[] = action.payload.forbiddenWords

    // 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.requestSubject.indexOf(item) >= 0) {
          isErrorSubject = true
          return
        }
        if (formData.requestText.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(openModal(getMessage('MCBHS090-011')))
        return
      }
      if (isErrorBody) {
        yield put(openModal(getMessage('MCBHS090-012')))
        return
      }
    }

    // 不正文字チェック
    if (
      formData.requestSubject.indexOf('●●') >= 0 ||
      formData.requestText.indexOf('●●') >= 0
    ) {
      yield put(openModal(getMessage('MCBHS090-013')))
      return
    }

    // メンテナンス期間チェック
    if (
      action.payload.sendReplyImpossibleTime &&
      action.payload.sendReplyImpossibleTime.length > 0
    ) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      action.payload.sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr)
          ? item.startDateStr.trim()
          : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(
            `${startDateStr.substring(0, 4)}-${startDateStr.substring(
              4,
              6
            )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
              8,
              10
            )}:${startDateStr.substring(10, 12)}`
          )
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(
            `${endDateStr.substring(0, 4)}-${endDateStr.substring(
              4,
              6
            )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
          )
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if (
            (sendTime.isAfter(startDate) ||
              sendTime.diff(startDate, 'minute') === 0) &&
            (sendTime.isBefore(endDate) ||
              sendTime.diff(endDate, 'minute') === 0)
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
              4,
              6
            )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (
            sendTime.isAfter(startDate) ||
            sendTime.diff(startDate, 'minute') === 0
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          // #MCB1.5次開発 #8986 END
          if (
            sendTime.isBefore(endDate) ||
            sendTime.diff(endDate, 'minute') === 0
          ) {
            errorPeriod = `～${endDateStr.substring(
              0,
              4
            )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
              6,
              8
            )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(openModal(getMessageEx('MCBHS090-014', errorPeriod)))
        return
      }
    }

    // 送信先チェック
    if (action.payload.sendTargetConfirmation) {
      if (
        !action.payload.sendTargetConfirmation.some(
          item => !isEmpty(item.mailAddress)
        )
      ) {
        yield put(openModal(getMessage('MCBHS090-017')))
        return
      }
    }
    yield call(confirmRequest, formData)
    yield put(openConfirmModal())
  } catch (error) {
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}
export function* destinationDownloadSaga(
  action: ReturnType<typeof destinationDownload>
) {
  const data = yield call(destinationDownloadRequest, action.payload)
  if (data.data.type.indexOf('application/json') !== -1) {
    yield put(openModal('MCBHS090-010'))
  } else {
    var blob = new Blob([data.data])
    const date = new Date()
    var curr_date = date.getDate() + ''
    var curr_month = date.getMonth() + 1 + ''
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + ''
    var curr_minutue = date.getMinutes() + ''
    curr_month = curr_month.length < 2 ? '0' + curr_month : curr_month
    curr_date = curr_date.length < 2 ? '0' + curr_date : curr_date
    curr_hour = curr_hour.length < 2 ? '0' + curr_hour : curr_hour
    curr_minutue = curr_minutue.length < 2 ? '0' + curr_minutue : curr_minutue
    const yymmdd =
      curr_year + curr_month + curr_date + '_' + curr_hour + curr_minutue
    const fileName = 'request_job_seeker_list_' + yymmdd + '.csv'
    if (navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName)
    } else {
      var a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = fileName
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
    }
  }
}
export function* openDetailDialogSaga(
  action: ReturnType<typeof openDetailDialog>
) {
  yield put(setSearchCriteria(action.payload))
  yield put(setDetailDialogOpen(true))
}

export default function* submissionRequestForcedStopSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(saveDraft, saveDraftSaga),
    takeLatest(getPreview, previewSaga),
    takeLatest(confirm, confirmSaga),
    takeLatest(destinationDownload, destinationDownloadSaga),
    takeLatest(openDetailDialog, openDetailDialogSaga),
  ])
}
