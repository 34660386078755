
export interface InitResult {
  [key: string]: any
  submissionContentInfo: SubmissionContentInfo[]
  pdfDownloadManagementInfo:PdfDownloadManagementInfo[]
}

export interface SubmissionContentInfo {
  [key: string]: any
  // タグ設定ID
  // -
  submissionContentId: string
  // タグ名称
  // -
  submissionContentName: string
}

export interface PdfDownloadManagementInfo {
  [key: string]: string | number | null
  // PDFダウンロード管理ID
  // -
  pdfDownloadManagementId: number
  // タイトル
  // -
  outputTitle: string
  // 出力提出物内容1
  // -
  submissionContentName1: string
  // 出力提出物内容2
  // -
  submissionContentName2: string
  // 出力提出物内容3
  // -
  submissionContentName3: string
  // ステータス
  // -
  status: string
  // 提出件数
  // -
  submissionCount: string
  // 実行者
  // -
  fullName: string
  // 実行日時
  // -
  executionDatetime: string
  // ダウンロード日時
  // -
  downloadDatetime: string
  // エラー内容
  // -
  errorContent: string
  // ダウンロード物格納パス
  // -
  downloadFullpath: string
  //提出リクエストID
  // -
  submissionRequestId:number 
  //企業別求職者ID  
  // -
  jobSeekerByCompanyId:number
}


const initResult : InitResult = {
  submissionContentInfo: [],
  pdfDownloadManagementInfo:[],
}

export interface InitRequest {
  loginId: string,
  companyId: string,
  outputSubmissionsIdArray: [],
  outputSubmissionsConditions: number,
  executionDateFrom: string,
  executionDateTo: string,
  executorList: [],
  executorId: string,
  stateList: [],
  creatingFlag: boolean,
  createCompleteFlag: true,
  creationErrorFlag: boolean,
  searchSegment:number,
}

export interface stateList{
  // 作成完了フラグ
  // -
  createCompleteFlag: true

}

export interface executorList{
  // 実行者ID
  // -
  executorId: '0'

  // 検索区分
  // -
  searchSegment: 0
}

const initRequest : InitRequest = {
  loginId: '',
  companyId: '',
  outputSubmissionsIdArray: [],
  outputSubmissionsConditions: 0,
  executionDateFrom: '',
  executionDateTo: '',
  executorList: [],
  executorId: '',
  stateList: [],
  creatingFlag: false,
  createCompleteFlag: true,
  creationErrorFlag: false,
  searchSegment: 0,
}



export {
  initResult,
  initRequest,
}
