/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAZS091InitRequest } from 'types/MCAZS091/MCAZS091InitRequest'
import { MCAZS091InitResult } from 'types/MCAZS091/MCAZS091InitResult'
import { MCAZS091RegisterResult } from 'types/MCAZS091/MCAZS091RegisterResult'
import { MCAZS091RegisterRequest } from 'types/MCAZS091/MCAZS091RegisterRequest'
import * as viewConfig from 'pages/MCAZS091/viewConig'

const messageRegisterRequest: MCAZS091RegisterRequest = {
  messageTemplateSettingId: '',
  destinationId: [''],
  selectionManagementId: [''],
  destination: 1,
  // [phase2] start by zhangxp
  sendToNonMember: 0,
  // [phase2] end by zhangxp
  senderCompanyName: '',
  senderMailAddress: '',
  allowReply: 0,
  subject: '',
  body: '',
  attachment: [],
  attachmentId: [''],
  templateAttachmentId: [''],
  templateAttachmentName: [''],
  mynaviAttachedOption: '',
  homePageTitle1: '',
  homePageTitle2: '',
  homePageTitle3: '',
  homePageUrl1: '',
  homePageUrl2: '',
  homePageUrl3: '',
  barcodeDisplayFlag: 0,
  sendTimer: 0,
  sendPlanTime: '',
  notifyNotification: 0,
  notifyMailAddress: '',
  requiredTimeId: '',
  interviewContents: '',
  interviewDateN: [''],
  interviewLocationIdN: [0],
  chargeN: [''],
  fileManagementId: [],
  fileManagementName: [],
  entryId: [0],
  interviewScheduleAdjustmentId: 0,
  adjustmentInterviewScheduleId: [],
  sysVersionNumber: '',
  messageSendId: '',
  interviewScheduleAdjustmentStatus: '',
  interviewScheduleAdjustmentSelection: '',
  adjustmentInterviewScheduleAdjustmentId: '',
  interviewDurationId: '',
  interviewDetail: '',
  interviewScheduleId: [0],
  interviewStartTime: [''],
  interviewStaffName: [''],
  interviewScheduleStatus: [''],
  adjustmentInterviewDurationId: '',
  adjustmentInterviewDetail: '',
  adjustmentInterviewStartTime: [''],
  adjustmentInterviewStaffName: [''],
  adjustmentInterviewScheduleStatus: [''],
  adjustmentInterviewLocationId: [],
  manipulationKubun: '',
  seminarId: [0],
  entryBoxId: [0],
  sendTimeFlag: '',
  replyPermitFlag: '',
  replyNecessaryFlag: '',
  replyLimitTime: null,
  destinationDivision: '',
  manualRegistrationFlag: [''],
  memberType: [],
  decisionDivision: [''],
  progressStatusSettingId: [0],
  notSend: [true],
  notificationMailAddress: '',
  replyFromMessageSendId: '',
  fullName: '',
  draftMessageSendId: 0,
  messageSendIds: [],
  interviewScheduleAdjustmentMax: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
  mySearchCondition: null,
  mySearchCriteriaNameJson: [],
  mySearchCriteriaJson: [],
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
  notAdoptedNotificationType: null,
  interviewScheduleIdWeb: 0,
  interviewWebEnableFlag: '',
  interviewWebUrlSendFlag: '',
  sysVersionNumberWeb: '',
  senderMessageId: [],
}

const initRequest: MCAZS091InitRequest = {
  replyFromMessageSendId: null,
  messageTemplateSettingId: "",
  entryId: [],
  selectionManagementId: [],
  memberType: [],
  messageType: [],
  destination: 0,
  // [phase2] start by zhangxp
  sendToNonMember: 0,
  // [phase2] end by zhangxp
  senderCompanyName: "",
  senderMailAddress: "",
  allowReply: 0,
  subject: "",
  text: "",
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  mynaviAttachedOption: "",
  homepageTitleN: "",
  homepageUrlN: "",
  barcodeDisplay: 0,
  sendTimer: 0,
  sendPlanTime: "",
  notifyNotification: 0,
  notifyMailAddress: "",
  interviewScheduleAdjustmentId: 0,
  interviewScheduleAdjustmentStatus: "",
  interviewDurationId: "",
  interviewDetail: "",
  interviewScheduleIdN: [],
  interviewStartTimeN: [],
  interviewLocationSettingId: [],
  interviewStaffName: [],
  interviewScheduleStatus: [],
  selectionFlow: [],
  flagBodyExistFlg: false,
  flagValueBodyExistFlg: false,
}

const initResult: MCAZS091InitResult = {
  adjustmentInterviewScheduleAdjustmentId: [],
  alertMessageIdList: [],
  noValueOnlySendList: [],
  noValueBulkSendList: [],
  multiProgressFlg: false,
  replyMailAddress: "",
}

const registerResult: MCAZS091RegisterResult = {
  fileManagementIds: [],
  attachmentNames: [],
  addFailureCount: 0,
}

const screenDisplayItems: viewConfig.ScreenDisplayItems = {
  title: viewConfig.title,
  interviewSchedule: viewConfig.interviewSchedule,
  messageInput: viewConfig.messageInput,
  sendTargetConfirmation: viewConfig.sendTargetConfirmation,
  sendTimeInput: viewConfig.sendTimeInput,
  recruitmentManagementFlag: viewConfig.recruitmentManagementFlag,
  seminarSchedule: viewConfig.seminarSchedule,
  seminar: viewConfig.seminar,
  signature: viewConfig.signature,
}

const initialState: {
  messageComfInitRequest: MCAZS091InitRequest
  messageComInitResult: MCAZS091InitResult
  messageComfRegisterResult: MCAZS091RegisterResult
  draftRequest: MCAZS091RegisterRequest
  businessCheckResult: boolean
  alertMessageIdList: string[]
  screenDisplayItems: viewConfig.ScreenDisplayItems
  subject: string
  body: string
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  notSendList: boolean[]
  isSending: boolean
} = {
  messageComfInitRequest: initRequest,
  messageComInitResult: initResult,
  messageComfRegisterResult: registerResult,
  draftRequest: messageRegisterRequest,
  businessCheckResult: false,
  alertMessageIdList: [],
  screenDisplayItems: screenDisplayItems,
  subject: "",
  body: "",
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  notSendList: [],
  isSending: false,
}

const messageSendConfirmSlice = createSlice({
  name: 'messageSendConfirm',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<{ initVal: MCAZS091InitRequest }>) {
      return state
    },
    setInit(state, action: PayloadAction<MCAZS091InitResult>) {
      state.messageComInitResult = action.payload
      return state
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload
      return state
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = false
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setAlertMessageId(state, action: PayloadAction<string>) {
      state.alertMessageIdList.push(action.payload)
      return state
    },
    setCount(state, action: PayloadAction<{ successfulCountMessage: number, successfulCountEmail: number, failedCount: number }>) {
      state.successfulCountMessage = action.payload.successfulCountMessage
      state.successfulCountEmail = action.payload.successfulCountEmail
      state.failedCount = action.payload.failedCount
      return state
    },
    setNotSend(state, action: PayloadAction<boolean[]>) {
      state.notSendList = action.payload
      return state
    },
    redistMessage(
      state,
      action: PayloadAction<{
        formData: MCAZS091RegisterRequest
        files: Array<any | null>
      }>
    ) {
      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
    setRegisterResult(state, action: PayloadAction<MCAZS091RegisterResult>) {
      state.messageComfRegisterResult = action.payload
      return state
    },
    setIsSending(state, action: PayloadAction<boolean>) {
      state.isSending = action.payload
      return state
    },
    openDetailDialog(state, action: PayloadAction<viewConfig.SearchCriteriaInfo>) {
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setSubject,
  setBody,
  setCode,
  setAlertMessageId,
  setCount,
  setNotSend,
  setBusinessCheckResult,
  redistMessage,
  setRegisterResult,
  setIsSending,
  openDetailDialog,
} = messageSendConfirmSlice.actions
export default messageSendConfirmSlice.reducer
