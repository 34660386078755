import { magiContants } from 'utils/contants'

export interface Row {
  [key: string]: any
  interviewLocationId: string
  displayOrder: number
  sysVersionNumber: number
  interviewLocationType: string
  recruitmentManagementDivision:string
  mapUrl:string
}
export const initialValues: Row[] = []
interface InterviewList {
  interviewListResults: Row[]
  updateInterviewListResults: Row[]
  interviewListCount: number
}
const InterviewListInitialValues: InterviewList = {
  interviewListResults: initialValues,
  updateInterviewListResults: initialValues,
  interviewListCount: 0,
}
const maxcount: number = magiContants.MAX_MCAOS010_MAXCOUNT
const msg: string = magiContants.MAX_MCAOS010_MAXCOUNT_MSG
const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
  reMapButtonTip:'この面接場所の地図を表示します'
}
export interface InterviewListAndCount {
  interviewList: Row[]
  count: number
}
export { InterviewListInitialValues, maxcount, msg,textMap }
