import request from 'utils/request'
import { MCACS020PasswordUpdateRequest } from 'types/MCACS020/MCACS020PasswordUpdateRequest'
import { MCACS020TemPasswordUpdateRequest } from 'types/MCACS020/MCACS020TemPasswordUpdateRequest'


export const getInit = (companyAccountId: string) =>
  request({
    url: `/MCACS020/init/${companyAccountId}`,
    method: 'post',
})
export const updatePasswordRequest = (apiData: MCACS020PasswordUpdateRequest) =>
  request({
    url: '/MCACS020/resisterPassword',
    method: 'post',
    data: apiData,
  })
export const temUpdatePasswordRequest = (apiData: MCACS020TemPasswordUpdateRequest) =>
  request({
    url: '/MCACS020/resisterTemPassword',
    method: 'post',
    data: apiData,
})

