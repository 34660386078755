import request from 'utils/request'

import { MCAHS010BulkOperationRequest } from 'types/MCAHS010/MCAHS010BulkOperationRequest'
import { MCAHS010OrderRequest } from 'types/MCAHS010/MCAHS010OrderRequest'
import { MCAHS010CopyRequest } from 'types/MCAHS010/MCAHS010CopyRequest'


export const getInitListRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAHS010/init/${recruitmentManagementDivision}`,
    method: 'post',
})

export const getCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAHS010/getCount/${recruitmentManagementDivision}`,
    method: 'post',
})

export const mCAHS010BulkOperationRequest = (apiData: MCAHS010BulkOperationRequest) =>
  request({
    url: '/MCAHS010/updateInvisibleFlag',
    method: 'post',
    data: apiData,
})

export const mCAHS010OrderRequest = (apiData: MCAHS010OrderRequest) =>
  request({
    url: '/MCAHS010/updateDisplayOrder',
    method: 'post',
    data: apiData,
})
// #72427 次期開発2022年12月 start
export const managementCopyApi = (apiData: MCAHS010CopyRequest) =>
  request({
    url: '/MCAHS010/copy',
    method: 'post',
    data: apiData,
})
// #72427 次期開発2022年12月 end