import request from 'utils/request'
import { MCAES010EditRequest } from 'types/MCAES010/MCAES010EditRequest'
import { MCAES020CreateRequest } from 'types/MCAES020/MCAES020CreateRequest'
import { MCAES020UpdateRequest } from 'types/MCAES020/MCAES020UpdateRequest'
import { MCAES020DeleteRequest } from 'types/MCAES020/MCAES020DeleteRequest'


export const getInitRequest = (apiData: MCAES010EditRequest) =>
  request({
    url: `/MCAES020/init`,
    method: 'post',
    data: apiData,
})

export const insertRequest = (apiData: MCAES020CreateRequest) =>
  request({
    url: '/MCAES020/new',
    method: 'post',
    data: apiData,
})

export const updateRequest = (apiData: MCAES020UpdateRequest) =>
  request({
    url: '/MCAES020/update',
    method: 'post',
    data: apiData,
})

export const deleteRequest = (apiData: MCAES020DeleteRequest) =>
  request({
    url: '/MCAES020/delete',
    method: 'post',
    data: apiData,
})