const codeMap = new Map([
  // 学生区分
  ['studentDivision_2', '大学院'],
  ['studentDivision_3', '大学'],
  ['studentDivision_4', '短大'],
  ['studentDivision_5', '高専'],
  ['studentDivision_6', '専門学校'],
  ['studentDivision_8', '海外の学校'],

  // 性別
  ['sex_0', '未選択'],
  ['sex_1', '男性'],
  ['sex_2', '女性'],

  // 文理区分
  ['humanitiesAndSciencesDivision_1', '文系'],
  ['humanitiesAndSciencesDivision_2', '理系'],
  ['humanitiesAndSciencesDivision_7', 'その他'],

  // 取得学位
  ['overseasSchoolDegree_10', 'Bachelor'],
  ['overseasSchoolDegree_20', 'Master'],
  ['overseasSchoolDegree_30', 'MBA'],
  ['overseasSchoolDegree_40', 'Ph.D.'],
  ['overseasSchoolDegree_90', 'その他'],
  ['overseasSchoolDegree_', '選択なし'],

  // 専攻分野
  ['vocationalSchoolMajor_201', 'ビジネス系'],
  ['vocationalSchoolMajor_203', '外国語系'],
  ['vocationalSchoolMajor_205', '文学系'],
  ['vocationalSchoolMajor_207', '教育系'],
  ['vocationalSchoolMajor_209', '人文・教養・総合科学系'],
  ['vocationalSchoolMajor_211', '福祉系'],
  ['vocationalSchoolMajor_221', '機械系'],
  ['vocationalSchoolMajor_223', '電気・電子系'],
  ['vocationalSchoolMajor_225', '化学系'],
  ['vocationalSchoolMajor_227', '土木・建築系'],
  ['vocationalSchoolMajor_229', '航空宇宙・船舶海洋系'],
  ['vocationalSchoolMajor_231', '情報工学系'],
  ['vocationalSchoolMajor_233', '生物系'],
  ['vocationalSchoolMajor_235', '材料系'],
  ['vocationalSchoolMajor_237', '数学・情報科学系'],
  ['vocationalSchoolMajor_239', '物理系'],
  ['vocationalSchoolMajor_241', '地学系'],
  ['vocationalSchoolMajor_243', '資源系'],
  ['vocationalSchoolMajor_245', '経営・管理系'],
  ['vocationalSchoolMajor_251', '農学系'],
  ['vocationalSchoolMajor_253', '獣医・畜産系'],
  ['vocationalSchoolMajor_261', '医学・歯学系'],
  ['vocationalSchoolMajor_263', '薬学系（４年制）'],
  ['vocationalSchoolMajor_264', '薬学系（６年制）'],
  ['vocationalSchoolMajor_265', '医療・保健・看護系'],
  ['vocationalSchoolMajor_271', '家政・生活科学系'],
  ['vocationalSchoolMajor_275', '保育系'],
  ['vocationalSchoolMajor_281', '体育・健康科学系'],
  ['vocationalSchoolMajor_291', '芸術系'],
  ['vocationalSchoolMajor_293', 'デザイン系'],
  ['vocationalSchoolMajor_297', 'その他理系'],
  ['vocationalSchoolMajor_299', '学科不明'],
  ['vocationalSchoolMajor_421','ＩＴ・マルチメディア系'],
  ['vocationalSchoolMajor_423','建築・インテリア系'],
  ['vocationalSchoolMajor_425','医療・福祉・栄養・美容系'],
  ['vocationalSchoolMajor_427','その他工業・技術・環境系'],
  ['vocationalSchoolMajor_429','ビジネス・語学・経営系'],
  ['vocationalSchoolMajor_431','旅行・ホテル・ブライダル系'],
  ['vocationalSchoolMajor_433','アート・ファッション系'],
  ['vocationalSchoolMajor_449','その他'],

  // 国・地域
  ['overseasSchoolCountryRegion_110', 'アメリカ'],
  ['overseasSchoolCountryRegion_120', 'カナダ'],
  ['overseasSchoolCountryRegion_210', 'イギリス'],
  ['overseasSchoolCountryRegion_220', 'ドイツ'],
  ['overseasSchoolCountryRegion_230', 'フランス'],
  ['overseasSchoolCountryRegion_290', '西欧諸国（上記以外）'],
  ['overseasSchoolCountryRegion_310', '中国'],
  ['overseasSchoolCountryRegion_320', '韓国'],
  ['overseasSchoolCountryRegion_390', 'アジア諸国（上記以外）'],
  ['overseasSchoolCountryRegion_490', '東欧・ロシア'],
  ['overseasSchoolCountryRegion_510', 'オーストラリア'],
  ['overseasSchoolCountryRegion_520', 'ニュージランド'],
  ['overseasSchoolCountryRegion_590', 'オセアニア諸国（上記以外）'],
  ['overseasSchoolCountryRegion_690', '南米諸国'],
  ['overseasSchoolCountryRegion_790', 'アフリカ諸国'],
  ['overseasSchoolCountryRegion_890', '中東諸国'],

  // 州(アメリカの場合)
  ['overseasSchoolState_10', 'Alabama'],
  ['overseasSchoolState_20', 'Alaska'],
  ['overseasSchoolState_30', 'Arizona'],
  ['overseasSchoolState_40', 'Arkansas'],
  ['overseasSchoolState_50', 'California'],
  ['overseasSchoolState_60', 'Colorado'],
  ['overseasSchoolState_70', 'Connecticut'],
  ['overseasSchoolState_80', 'Delaware'],
  ['overseasSchoolState_90', 'District of Columbia'],
  ['overseasSchoolState_100', 'Florida'],
  ['overseasSchoolState_110', 'Georgia'],
  ['overseasSchoolState_120', 'Hawaii'],
  ['overseasSchoolState_130', 'Idaho'],
  ['overseasSchoolState_140', 'Illinois'],
  ['overseasSchoolState_150', 'Indiana'],
  ['overseasSchoolState_160', 'Iowa'],
  ['overseasSchoolState_170', 'Kansas'],
  ['overseasSchoolState_180', 'Kentucky'],
  ['overseasSchoolState_190', 'Louisiana'],
  ['overseasSchoolState_200', 'Maine'],
  ['overseasSchoolState_210', 'Maryland'],
  ['overseasSchoolState_220', 'Massachusetts'],
  ['overseasSchoolState_230', 'Michigan'],
  ['overseasSchoolState_240', 'Minnesota'],
  ['overseasSchoolState_250', 'Mississippi'],
  ['overseasSchoolState_260', 'Missouri'],
  ['overseasSchoolState_270', 'Montana'],
  ['overseasSchoolState_280', 'Nebraska'],
  ['overseasSchoolState_290', 'Nevada'],
  ['overseasSchoolState_300', 'New Hampshire'],
  ['overseasSchoolState_310', 'New Jersey'],
  ['overseasSchoolState_320', 'New Mexico'],
  ['overseasSchoolState_330', 'New York'],
  ['overseasSchoolState_340', 'North Carolina'],
  ['overseasSchoolState_350', 'North Dakota'],
  ['overseasSchoolState_360', 'Ohio'],
  ['overseasSchoolState_370', 'Oklahoma'],
  ['overseasSchoolState_380', 'Oregon'],
  ['overseasSchoolState_390', 'Pennsylvania'],
  ['overseasSchoolState_400', 'Rhode Island'],
  ['overseasSchoolState_410', 'South Carolina'],
  ['overseasSchoolState_420', 'South Dakota'],
  ['overseasSchoolState_430', 'Tennessee'],
  ['overseasSchoolState_440', 'Texas'],
  ['overseasSchoolState_450', 'Utah'],
  ['overseasSchoolState_460', 'Vermont'],
  ['overseasSchoolState_470', 'Virginia'],
  ['overseasSchoolState_480', 'Wisconsin'],
  ['overseasSchoolState_490', 'Washington'],
  ['overseasSchoolState_500', 'West Virginia'],
  ['overseasSchoolState_510', 'Wyoming'],

  // 会員種別
  ['memberType_1', 'マイナビ会員'],
  ['memberType_5', '非会員（外部エントリー）'],

])

export const getDynamicCode = (describe: string, codeID: string) => {
  return codeMap.get(describe + '_' + codeID) || ''
}
