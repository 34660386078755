import { getMCAXS561InitRequest } from 'apis/MCAXS561Api'
import { getMCAXS501InitRequest } from 'apis/MCAXS501Api'
import {
  getMcaxs501Init,
  getMcaxs561Init,
  getMcaxs561InitJobChange,
  setMcaxs501Init,
  setMcaxs561Init,
  setMcaxs561InitJobChange,
} from 'reducers/itemSpecificationReducer'
import { openModal } from 'reducers/messageReducer'
import { all, put, takeLatest, call } from 'redux-saga/effects'

// 管理項目情報
function* getMcaxs501InitSaga(action: ReturnType<typeof getMcaxs501Init>) {
  try {
    const { managementItemList } = yield call(getMCAXS501InitRequest)
    yield put(
      setMcaxs501Init({
        result: managementItemList.map((item: any) => ({
          id: item.recruitmentManagementFlagSettingId,
          title: item.recruitmentManagementFlagName,
          type: item.managementItemType,
          invisibleFlag: item.invisibleFlag === '1',
          optionList: item.managementItemFlagCheckBoxList
            ? item.managementItemFlagCheckBoxList.map((option: any) => ({
                value: option.managementItemFlagCheckBoxId,
                label: option.managementItemFlagCheckBoxName,
              }))
            : null,
        })),
        isInitManagementItemObj: action.payload.isInitManagementItemObj,
        isJobChange: action.payload.isJobChange,
        invisibleRecruitmentManagementFlagSettingIdList: managementItemList
          .filter((i: any) => i.invisibleFlag === '1')
          .map((i: any) => i.recruitmentManagementFlagSettingId),
      })
    )
    action.payload.toggle()
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// アンケート回答内容情報(就職)
function* getMcaxs561InitSaga(action: ReturnType<typeof getMcaxs561Init>) {
  try {
    const { questionaireList } = yield call(
      getMCAXS561InitRequest,
      action.payload.request
    )
    yield put(
      setMcaxs561Init({
        result: questionaireList.map((item: any) => ({
          ...item,
          questionInfoList: item.questionInfoList
            ? item.questionInfoList.map((questionInfo: any) => ({
                ...questionInfo,
                answerCheckBoxList: questionInfo.answerCheckBoxList
                  ? questionInfo.answerCheckBoxList.map((option: any) => ({
                      value: option.answerCheckBoxId,
                      label: option.answerCheckBoxName,
                    }))
                  : null,
              }))
            : null,
        })),
        qro: action.payload.qro,
      })
    )
    action.payload.toggle()
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// アンケート回答内容情報(転職)
function* getMcaxs561InitSagaJobChange(
  action: ReturnType<typeof getMcaxs561InitJobChange>
) {
  try {
    const { questionaireJobChangeList } = yield call(
      getMCAXS561InitRequest,
      action.payload.request
    )
    yield put(
      setMcaxs561InitJobChange({
        result: questionaireJobChangeList.map((item: any, index: number) => ({
          id: item.questionNumber.toString(),
          questionOption: item.option,
          title: item.questionTitle,
          type: item.questionTypeCode,
          optionList: item.answerCheckBoxList
            ? item.answerCheckBoxList.map((option: any) => ({
                value: option.answerCheckBoxId,
                label: option.answerCheckBoxName,
              }))
            : null,
        })),
        isInitSelectedQuestionnaireObj:
          action.payload.isInitSelectedQuestionnaireObj,
      })
    )
    action.payload.toggle()
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* advancedSearchSaga() {
  yield all([takeLatest(getMcaxs501Init, getMcaxs501InitSaga)])
  yield all([takeLatest(getMcaxs561Init, getMcaxs561InitSaga)])
  yield all([
    takeLatest(getMcaxs561InitJobChange, getMcaxs561InitSagaJobChange),
  ])
}
