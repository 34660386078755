/**
 * MCADS010QueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  //氏名
  name: yup.string().test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

  // アカウント種別
  accountTypes: yup.object().shape({
    // アカウント種別_統括
    accountTypeSupervising: yup
    .string()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  
    // アカウント種別_管理者
    accountTypeAdministrator: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // アカウント種別_準管理者
    accountTypeSemiManager: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // アカウント種別_一般ユーザ
    accountTypeGeneralUser: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // アカウント種別_制限ユーザ
    accountTypeLimitUser: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

  }),

  // ステータス_無効を含む
  invalidUser: yup
    .string()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
});