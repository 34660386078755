import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import { Prompt } from 'react-router-dom'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

interface Props {
  openFlag: boolean
  messageId: string
}

const PromptBack = ({ openFlag, messageId }: Props) => {
  const backPath: string[] = magiContants.BACK_PATH.split(',')
  // 年度対応 start
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  return (
    <Prompt
      message={x => {
        const flag = backPath.find((i: string) => {
          if (x.pathname.indexOf(i) > -1) {
            return true
          }
        })
        if (flag) {
          return true
        } else {
          return getMessage(messageId)
        }
      }}
      when={openFlag && 
        (globalMenu.recruitmentManagementDivision == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
          || (globalMenu.recruitmentManagementDivision == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && !globalMenu.promptForbiddenFlg))}
    />
  )
  // 年度対応 end
}

export default PromptBack
