import { Grid, TextField as MuiTextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
    input: {
        fontSize: '14px',
        '&:disabled': {
            color: '#999999',
            borderColor: '#cccccc',
        },
    },
    textCounter: {
        float: 'right',
        minWidth: 120,
        textAlign: 'right',
    },
}))

interface Props extends FieldProps {
    maxAmount?: number
    defaultValue?: any
    disabled?: boolean
}

const InputWithPostalCode = ({
    field,
    form,
    defaultValue,
    maxAmount,
    disabled,
}: Props) => {
    const classes = useStyles()
    const { errors, touched } = form
    const { value, name } = field

    useEffect(() => {
        if (!disabled) {
            if (touched[name]) {
                form.setFieldValue(name, '')
                form.setFieldTouched(name, true)
            }
        } else {
            form.setFieldValue(name, defaultValue)
            form.setFieldTouched(name, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <MuiTextField
                        disabled={disabled}
                        type='text'
                        margin='dense'
                        variant='outlined'
                        fullWidth
                        InputProps={{
                            classes: { input: classes.input },
                        }}
                        helperText={errors[name] && touched[name] ? errors[name] : null}
                        error={Boolean(errors[name] && touched[name])}
                        {...field}
                    />
                </Grid>
                <Grid item xs={12} className={classes.textCounter}>
                    <Typography gutterBottom variant='h6'>
                        {`入力 ${value ? value.length : 0} / 最大 ${maxAmount}`}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default InputWithPostalCode
