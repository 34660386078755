import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { magiContants as contants } from 'utils/contants'
import { FormControl } from '..'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { openModal } from 'reducers/messageReducer'
import { getMcaxs571Init, setSchoolGroupDetailsFlag } from 'reducers/advancedSearchModalReducer'
import { Option, SchoolModalState } from '../Config/searchConditionConfig'
import { transform } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'

const optionSet = [
  '旧帝大',
  '関東国公立',
  '関西国公立',
  'その他国公立・東日本',
  'その他国公立・西日本',
  '北海道私大',
  '東北私大',
  '関東私大Ａ',
  '関東私大Ｂ',
  '山梨私大',
  '信越私大',
  '北陸私大',
  '静岡私大',
  '東海私大',
  '関西私大Ａ',
  '関西私大Ｂ',
  '中国・四国私大',
  '九州・沖縄私大',
  '女子大・国公立',
  '女子大（私立）・北海道',
  '女子大（私立）・東北',
  '女子大（私立）・関東',
  '女子大（私立）・東海',
  '女子大（私立）・関西',
  '女子大（私立）・中国／四国',
  '女子大（私立）・九州／沖縄',
  '短大（国公立）',
  '短大（私立）・北海道',
  '短大（私立）・東北',
  '短大（私立）・関東（東京除く）',
  '短大（私立）・東京',
  '短大（私立）・中部（東海除く）',
  '短大（私立）・東海',
  '短大（私立）・関西',
  '短大（私立）・中国／四国',
  '短大（私立）・九州／沖縄',
  '高専・北海道',
  '高専・東北',
  '高専・関東',
  '高専・信越',
  '高専・北陸',
  '高専・東海',
  '高専・関西',
  '高専・中国／四国',
  '高専・九州',
  '日本校・通信大学・その他大学',
  'その他短期大学',
  'その他高専',
  '専門学校・その他',
  '医歯・医療・看護系等大学',
  '海外学校',
]

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  link: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  checkbox: {
    //display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: 15,
  },
  button: {
    marginLeft: theme.spacing(2.5),
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: '30%',
  },
  modalButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px'
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important'
  },
  content: {
    float: 'left',
    '& div': {
      display: 'inline-block',
      margin: '0px 19px 0px 0px',
      cursor: 'pointer',
    },
    '& div input': {
      marginRight: '5px',
      cursor: 'pointer',
    }
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  title: string
  form: FormControl
  modalState: SchoolModalState
}

const SchoolGroupModal = ({ open, onClose, title, form, modalState }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { schoolGroupBreakdownLink, schoolGroupList } = useSelector(
    (state: RootState) => state.advancedSearchModal.schoolGroupModalInfo
  )

  const schoolGroupDetailsFlag = useSelector((state: RootState) => state.advancedSearchModal.schoolGroupDetailsFlag)
  const schoolGroupObj = form.values.schoolGroupObj
  const options : Option[] = schoolGroupObj != null && schoolGroupObj.length > 0 ? 
    schoolGroupObj.map(i => {
      return { label: i.label, value: i.value }
    }) : []
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openOkDialog, setOpenOkDialog] = useState(false)

  useEffect(() => {
    if (open) {
      dispatch(getMcaxs571Init())
      if(options && !schoolGroupDetailsFlag){
        setSelected(options)
      }
    }
  }, [open])

  const [currentModal, setCurrentModal] = useState('')
  const [modalStatus, setModalStatus] = useState({
    confirm: false,
  })

  // OK/Cancel
  const handleConfirm = (title: string, open = true) => () => {
    setCurrentModal(title)
    setModalStatus({ ...modalStatus, confirm: open })
  }

  // 学校詳細を指定する
  const handleClick = (open = true) => () => {
    if (selected.length) {
      setCurrentModal('schoolDetails')
      modalState.schoolGroupSelected = selected
      modalState.schoolDetails = true
      dispatch(setSchoolGroupDetailsFlag(false))
      onClose()
    } else {
      dispatch(openModal(contants.MESSAGECODE_MCAXS571_001))
    }
  }

  const [selected, setSelected] = useState(modalState.schoolGroupSelected || [])

  useEffect(() => {
    setSelected(form.values.schoolGroupObj || [])
  }, [form.values.schoolGroupObj])

  const handleSelect = (option: Option) => (e: any) => {
    const selectedOption = selected.filter(i => i.value === option.value)
    if (selectedOption.length > 0) {
      setSelected(selected.filter(i => i.value !== option.value))
    } else {
      setSelected([...selected, option])
    }
  }
  const handleCancel = () => {
    setOpenCancelDialog(false)
    setOpenOkDialog(false)
  }

  const handleCancelOk = () => {
    setOpenCancelDialog(false)
    dispatch(setSchoolGroupDetailsFlag(false))
    onClose()
  }

  // キャンセル確認ダイアログ表示
  const handleClickDialog = () => {
    setOpenCancelDialog(true)
  }

  // OK確認ダイアログ表示
  const handleOkDialog = () => {
    setOpenOkDialog(true)
  }

  const handleSubmit = () => {
    //選択した値を画面の選択肢の並び順でソート
    const orderObjArray = schoolGroupList.map(i=>({schoolGroupCode: i.schoolGroupCode }))
    const sortedSelected = [...selected].sort((a, b) => {
        return innerSortFunc(
          { schoolGroupCode: a.value },
          { schoolGroupCode: b.value },
          orderObjArray,
        )
    })

    handleConfirm(currentModal, false)()
    dispatch(setSchoolGroupDetailsFlag(false))
    setOpenOkDialog(false)
    onClose()
    // 学校詳細、学部・学科詳細の情報は削除
    form.setFieldValue('undergraduateDepartmentObj', [])
    form.setFieldValue('schoolDetailsObj', [])
    form.setFieldValue('schoolGroupObj', sortedSelected)
    modalState.schoolDepartmentDetailsSelected = []
    modalState.schoolDetailsSelected = []
    modalState.schoolGroupSelected = sortedSelected
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{title}</label>
        </DialogTitle>
        <DialogContent>
          <Link
            href={schoolGroupBreakdownLink}
            target='blank'
            className={classes.link}>
            学校グループの内訳はこちら
          </Link>
          <div className={classes.checkbox}>
            {schoolGroupList
              ? schoolGroupList
                  .map(transform)
                  .map(option => (
                    <div className={classes.content}>
                      <div onClick={handleSelect(option)} >
                        <input type="checkbox" checked={selected.some(item => item.value === option.value)} />{option.label}
                      </div>
                    </div>
                  ))
              : null}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleClick()}
              color='primary'
              variant='contained'
              className={classes.modalButton}>
              学校詳細を指定する
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleClickDialog}
              color='primary'
              variant='contained'
              className={classes.cancel}>
              キャンセルする
            </Button>
            <Button
              onClick={handleOkDialog}
              color='primary'
              variant='contained'
              className={classes.confirmButton}>
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      
      <Dialog open={openCancelDialog} onClose={handleCancel}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS571_002}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOkDialog} onClose={handleConfirm(currentModal, false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS571_003}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleSubmit} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SchoolGroupModal
