
export const resetModal = {
  // オープンフラグ
  openCheckModal:false,
  // メッセージ内容
  modalMessage:'',
  // メッセージId
  modalMessageId:''
}
export const initialMCBCS010InitDto = {
  // 選択全件数
  cooperativeNumber:0,
  // 重複件数
  numberOfLinkedRecords:0,
  // MCBカテゴリーリスト
  mcbCategoryList: [],
  // 企業IDリスト
  companyIdList:[],
  // 選考管理IDリスト
  selectionManagementIdList:[],
  // 選考フロー設定IDリスト
  selectionFlowIdList:[],
}

export const initPage = {
  // 学生アップロードリスト名
  cooperationTargetSearchConditions:'',
  // MCBカテゴリー
  mcbCategory: '',
  // 企業ID
  companyId:'',
}

export const initNextPageParam = {
　// 選考管理IDリスト
  selectionManagementIdList: [],
  // 対象件数
  count: 0,
　// 選考フロー設定IDリスト
  selectionFlowSettingIdList: [],
　// リクエストID
  requestId: '',
  // MCBカテゴリー
  mcbCategory: '',
  //連携元企業識別キー
  coordinationFormerCompanyIdentificationKey:''
}
