import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import AccountRequestDto from 'types/accountRequestDto'

export interface Permission {
  [key: string]: string | null
  id: string
  name: string
  sortOrder: string | null
  status: string | null
}

export interface Account {
  [key: string]: string | Permission[]
  id: string //管理者ID
  name: string //氏名
  department: string //部署名
  email: string //メールアドレス
  loginId: string //ログインID
  password: string //パスワード
  salt: string //パスワードSALT
  onetimePasswordFlag: string //ワンタイムパスワードフラグ
  passwordExpireDate: string //パスワード有効期限
  status: string //ステータス
  lastLoginDate: string //最終ログイン日時
  registrationDate: string //登録日時
  registrationId: string //登録者
  updateDate: string //更新日時
  updateId: string //更新者
  permissions: Permission[] //権限
}

const initialState: Account[] = []

const accountSlice = createSlice({
  name: 'accout',
  initialState,
  reducers: {
    fetchAccoutList(state) {
      return state
    },
    setAccount(state, action: PayloadAction<Account[]>) {
      return action.payload
    },
    createAccount(state, action: PayloadAction<AccountRequestDto>) {
      return state
    },
    updateAccount(
      state,
      action: PayloadAction<{ data: AccountRequestDto; message: string }>
    ) {
      return state
    },
    deleteAccount(state, action: PayloadAction<string>) {
      return state
    },
  },
})

export const {
  fetchAccoutList,
  setAccount,
  createAccount,
  updateAccount,
  deleteAccount,
} = accountSlice.actions
export default accountSlice.reducer
