/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Dialog, makeStyles, DialogTitle, Grid, DialogContent, List, DialogActions, Button, ListItem } from '@material-ui/core'
import { magiContants } from 'utils/contants'
import { useDispatch } from 'react-redux'
import { openModal } from 'reducers/messageReducer'
import { getMessage } from 'common/messageUtil'

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#133e80',
        padding: '8px',
    },
    title: {
        color: '#ffffff',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    // "キャンセルする"ボタン
    cancel: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
            backgroundColor: '#c1d1eb',
            borderColor: '#999999',
        },
        minWidth: '180px',
    },
    // "入力内容を確認する"ボタン
    confirmButton: {
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
            backgroundColor: '#4596e6',
            borderColor: '#3380cc',
        },
        minWidth: '180px',
    },
    dialogContent: {
        padding: '8px 60px 0px 60px',
    },
    gridStyle: {
        width: '100%',
        height: '335px',
    },
    list: {
        padding: '0px',
        margin: '1%',
        height: '50px',
        width: '7.4%',
        display: 'inline-block',
        '& :hover': {
            backgroundColor: '#e6f1ff',
        },
        '& .Mui-selected': {
            backgroundColor: '#fcebeb',
            border: '2px solid #e2b4e3'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#fcebeb',
            border: '2px solid #e2b4e3'
        },
    },
    list1: {
        padding: '0px',
        margin: '1%',
        height: '50px',
        width: '7.4%',
        display: 'inline-block',
        '& :hover': {
            backgroundColor: '#ffffff',
        },
        '& .Mui-selected': {
            backgroundColor: '#ffffff',
            border: '2px solid #ffffff'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#ffffff',
            border: '2px solid #ffffff'
        },
    },
    list2: {
        marginLeft: '6%',
        padding: '0px',
        margin: '1%',
        height: '50px',
        width: '7.4%',
        display: 'inline-block',
        '& :hover': {
            backgroundColor: '#e6f1ff',
        },
        '& .Mui-selected': {
            backgroundColor: '#fcebeb',
            border: '2px solid #e2b4e3'
        },
        '& .Mui-selected:hover': {
            backgroundColor: '#fcebeb',
            border: '2px solid #e2b4e3'
        },
    },
    listItem: {
        margin: '1%',
        height: '50px',
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '30px',
        fontSize: '18px',
        color: '#3380CC',
        border: '2px solid rgb(225, 228, 232)',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    listItemNone: {
        margin: '1%',
        height: '50px',
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '30px',
        fontSize: '18px',
        color: '#3380CC',
        border: '2px solid #ffffff',
        borderRadius: '5px',
        cursor: 'default',
    }
}))

export interface FiftyPhonogramData {
    [key: string]: any
    value: string
    label: string
    index: number
}

interface Props {
    fiftyPhonogramDialog: boolean
    setFiftyPhonogramDialog: (state: boolean) => void
    getFiftyPhonogramData: (state: FiftyPhonogramData) => void
    fiftyPhonogramData: FiftyPhonogramData
    messageId: string
}

const FiftyPhonogram = ({ fiftyPhonogramDialog, setFiftyPhonogramDialog, getFiftyPhonogramData, fiftyPhonogramData, messageId }: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [data, setData] = useState<FiftyPhonogramData>({ value: magiContants.EMPTY, label: magiContants.EMPTY, index: 0 })

    const fiftyPhonogram = [
        { value: 'ア', label: 'あ', index: 1 },
        { value: 'カ,ガ', label: 'か', index: 2 },
        { value: 'サ,ザ', label: 'さ', index: 3 },
        { value: 'タ,ダ', label: 'た', index: 4 },
        { value: 'ナ', label: 'な', index: 5 },
        { value: 'ハ,パ,バ', label: 'は', index: 6 },
        { value: 'マ', label: 'ま', index: 7 },
        { value: 'ヤ', label: 'や', index: 8 },
        { value: 'ラ', label: 'ら', index: 9 },
        { value: 'ワ', label: 'わ', index: 10 },
        { value: 'イ', label: 'い', index: 11 },
        { value: 'キ,ギ', label: 'き', index: 12 },
        { value: 'シ,ジ', label: 'し', index: 13 },
        { value: 'チ,ヂ', label: 'ち', index: 14 },
        { value: 'ニ', label: 'に', index: 15 },
        { value: 'ヒ,ピ,ビ', label: 'ひ', index: 16 },
        { value: 'ミ', label: 'み', index: 17 },
        { value: '　', label: '　', index: -1 },
        { value: 'リ', label: 'り', index: 19 },
        { value: '　', label: '　', index: -1 },
        { value: 'ウ,ヴ', label: 'う', index: 21 },
        { value: 'ク,グ', label: 'く', index: 22 },
        { value: 'ス,ズ', label: 'す', index: 23 },
        { value: 'ツ,ヅ', label: 'つ', index: 24 },
        { value: 'ヌ', label: 'ぬ', index: 25 },
        { value: 'フ,プ,ブ', label: 'ふ', index: 26 },
        { value: 'ム', label: 'む', index: 27 },
        { value: 'ユ', label: 'ゆ', index: 28 },
        { value: 'ル', label: 'る', index: 29 },
        { value: '　', label: '　', index: -1 },
        { value: 'エ', label: 'え', index: 31 },
        { value: 'ケ,ゲ', label: 'け', index: 32 },
        { value: 'セ,ゼ', label: 'せ', index: 33 },
        { value: 'テ,デ', label: 'て', index: 34 },
        { value: 'ネ', label: 'ね', index: 35 },
        { value: 'ヘ,ペ,ベ', label: 'へ', index: 36 },
        { value: 'メ', label: 'め', index: 37 },
        { value: '　', label: '　', index: -1 },
        { value: 'レ', label: 'れ', index: 39 },
        { value: '　', label: '　', index: -1 },
        { value: 'オ', label: 'お', index: 41 },
        { value: 'コ,ゴ', label: 'こ', index: 42 },
        { value: 'ソ,ゾ', label: 'そ', index: 43 },
        { value: 'ト,ド', label: 'と', index: 44 },
        { value: 'ノ', label: 'の', index: 45 },
        { value: 'ホ,ポ,ボ', label: 'ほ', index: 46 },
        { value: 'モ', label: 'も', index: 47 },
        { value: 'ヨ', label: 'よ', index: 48 },
        { value: 'ロ', label: 'ろ', index: 49 },
        { value: '　', label: '　', index: -1 },
    ]
    useEffect(() => {
        if (fiftyPhonogramData.index === 0) {
            setSelectedIndex(0)
            setData({ value: magiContants.EMPTY, label: magiContants.EMPTY, index: 0 })
        } else {
            setSelectedIndex(fiftyPhonogramData.index)
        }
    }, [fiftyPhonogramDialog])

    const onLink = (data: FiftyPhonogramData) => {
        setSelectedIndex(data.index)
        setData(data)
    }

    const handleCancel = () => {
        setSelectedIndex(0)
        setFiftyPhonogramDialog(false)
    }

    const handleConfirm = () => {
        if (data.value === "") {
            dispatch(openModal(getMessage(messageId)))
        } else {
            getFiftyPhonogramData(data)
        }
    }

    return (
        <>
            {/* 学校名（頭文字）選択 */}
            <Dialog open={fiftyPhonogramDialog} fullWidth maxWidth='md'>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid className={classes.title}>学校名（頭文字）選択</Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid className={classes.gridStyle}>
                        {fiftyPhonogram.map(data => (
                            <>
                                {data.value === magiContants.BLANK_ZENKAKU ?
                                    <><List component='div' className={classes.list1}>
                                        <ListItem className={classes.listItemNone} button tabIndex={-1} disableRipple={true}>{data.label}</ListItem>
                                    </List></> :
                                    <><List component='div' className={data.index.toString().substr(data.index.toString().length - 1, data.index.toString().length) === "6" ? classes.list2 : classes.list}>
                                        <ListItem
                                            className={classes.listItem}
                                            selected={selectedIndex === data.index}
                                            button
                                            onClick={() => { onLink(data) }}>{data.label}</ListItem>
                                    </List></>}
                            </>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' justify='center'>
                        <Grid xs={5} item container justify='flex-end'>
                            <Button className={classes.cancel} onClick={handleCancel} variant='contained'>キャンセルする</Button>
                        </Grid>
                        <Grid style={{ width: '20px' }} />
                        <Grid xs={5} item>
                            <Button className={classes.confirmButton} onClick={handleConfirm} variant='contained'>OK</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FiftyPhonogram