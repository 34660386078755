import {
  all,
  call,
  select,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import {
  mcbOutBoxInit,
  setMessageOutBoxListResults,
  setMessageOutBoxListAllCount,
  setMcbMessageOutBoxListSearchCondition,
  // "ゴミ箱へ移動"ボタンを押下
  deleteOutBoxMessage,
  setSentFromDate,
  setMessageFlagResult,
  selectReceiveCount,
  selectReserveCount,
  selectDraftCount,
  // MCBリプレイス #8782 START
  selectMcbDraftCount,
  selectMcbReserveCount,
  // MCBリプレイス #8782 END
  getFailSelectionManagementIdBulkList,
  getSelectionManagementIdBulkListMCAZS170,

  // MCAZS200
  setMcbMessageDustboxListSearchCondition,
  initMcbDustboxSelect,
  undoMcbDustboxMessage,
  deleteMcbDustboxMessage,
  setMcbMessageDustboxListResults,
  setMcbMessageDustboxListAllCount,
  setMcbDeleteFromDate,
  // MCAZS200
  initSelectMCAZS180,
  editMessageMCAZS180,
  setReserveFromDate,
  setMcbMessageReservationListAllCount,
  getSelectionManagementIdBulkListMCAZS190,
  setMcbMessageDraftListSearchCondition,
  setMcbMessageDraftListResults,
  setMcbMessageDraftListAllCount,
  initMcbSelect,
  setMcbUpdateFromDate,
  deleteMcbMessage,
  setNomalBoxIconFlag,
  // 検索条件
  setMcbMessageReservationListSearchCondition,
  setMcbMessageReservationListResults,
  deleteMessageMCAZS180,
  sendMessageMCAZS180,
  getFailSelectionManagementIdBulkListMCAZS180,
  getSelectionManagementIdBulkListMCAZS180,
  editMessageMCAZS190,
  getSelectionManagementIdBulkListMCAZS200,
  // editMessageMCAZS200,
  getinitAreaOutBox,
  getinitAreaReservation,
  getinitAreaDraft,
  getinitAreaDustBox,
  // MCB リプレース #7965 再対応 start
  setMcbReserveCount,
  setMcbDraftCount,
  // MCB リプレース #7965 再対応 end
  // MCBリプレイス #8782 START
  setReceiveCount,
  setReserveCount,
  setDraftCount,
  // MCBリプレイス #8782 END
} from 'reducers/mcbMessageListReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  selectMessageOutBoxRequest,
  outBoxInitRequest,
  moveTrashMessageRequest,
  showEntryListRequest,
  showSendObjEntryListRequest,
} from 'apis/MCAZS170Api'
import {
  selectMcbMessageReservationRequest,
  initMcbReservationRequest,
  deleteMcbMessageReservationRequest,
  sendMcbMessageReservationRequest,
  editMessageMCAZS180Request,
  getSelectionManagementApiMCAZS180,
  getMcbFailSelectionManagementApiMCAZS180,
} from 'apis/MCAZS180Api'
import {
  initMcbDustboxRequest,
  selectMcbMessageDustboxRequest,
  deleteMcbDustboxMessageRequest,
  undoMcbDustboxMessageRequest,
  getSelectionManagementApiMCAZS200,
  // editMessageMCAZS200Request,
} from 'apis/MCAZS200Api'
import { magiContants } from 'utils/contants'
import { routeList } from 'routes/routes'
import history from 'utils/history'
import { getMessage } from 'common/messageUtil'
import {
  deleteMcbMessageRequest,
  editMessageMCAZS190Request,
  getSelectionManagementApiMCAZS190,
  initMcbRequest,
  selectMcbMessageDraftRequest,
} from 'apis/MCAZS190Api'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
// MCBリプレイス #8782 START
import { getRecruitmentManagementDivision} from 'selectors/authSelectors'
import {
  receiveCountRequest,
  reserveCountRequest,
  draftCountRequest,
  mcbReserveCountRequest,
  mcbDraftCountRequest,
} from 'apis/MCAZS010Api'
// MCBリプレイス #8782 END
// #MCB1.5次開発 #81023 start
import { searchSubmissionRequestMessageDetail } from 'reducers/submissionRequestMessageDetailReducer'
import { openModalWithArg } from 'reducers/messageReducer'
// #MCB1.5次開発 #81023 end
// MCAZS170 START
/**
 * 初期表示
 * @param action 
 */
function* mcbOutBoxInitSelectSaga(action: ReturnType<typeof mcbOutBoxInit>) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(outBoxInitRequest, action.payload)
    yield put(setSentFromDate(data.sentFromDate))
    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if(data.nomalBoxIconFlag!=null){
      yield put(setNomalBoxIconFlag(data.nomalBoxIconFlag))
    }
    // MCBリプレイス #8782 START
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    // MCBリプレイス #8782 END
    //put message list
    if (
      data.selectSendMsgList != null &&
      data.selectSendMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
/**
 * "検索"ボタン押下
 */
function* setMcbMessageOutBoxListSearchConditionSaga(
  action: ReturnType<typeof setMcbMessageOutBoxListSearchCondition>
) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMessageOutBoxRequest, action.payload)
    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if (
      data.selectSendMsgList != null &&
      data.selectSendMsgList.length > magiContants.INTEGER_0
    ) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
  } catch (error) {
    yield put(openModal(error.message))
  }
}
/**
 * "ゴミ箱へ移動"ボタンを押下
 * @param action 
 */
function* deleteOutBoxMessageSaga(
  action: ReturnType<typeof deleteOutBoxMessage>
) {
  try {
    yield put(setMessageOutBoxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(moveTrashMessageRequest, action.payload)
    yield put(setMessageOutBoxListAllCount(data.sendMsgCount))
    if (data.selectSendMsgList.length > magiContants.INTEGER_0) {
      yield put(setMessageOutBoxListResults(data.selectSendMsgList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS170_006))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
/**
 * 送信対象リンク押下
 * @param action 
 */
function* showEntryListRequestSaga(
  action: ReturnType<typeof getFailSelectionManagementIdBulkList>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS170',
      bulkManipulationId: data.messageSendId,
      // MCBリプレイス #9041 START
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS170_007),
      // MCBリプレイス #9041 END
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      // MCB リプレース #7945対応 start
      selectionManagementId: [data.selectionManagementId],
      // MCB リプレース #7945対応 end
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const failSelectionManagementsIds = yield call(showEntryListRequest,sendObjTransitionEntryListRequest)
    if (data.bulkSendFlag === magiContants.BULK_SEND_FLAG_1) {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        specificSelectionManagementIdList: failSelectionManagementsIds,
      }
    } else {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        screenId: 'MCAZS170',
        specificSelectionManagementIdList: [data.selectionManagementId],
      }
    }
    let pathName = ''
    pathName = routeList.applicantList
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* getSelectionManagementIdMCAZS170Saga(
  action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS170>
) {
  try {
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS170',
      bulkManipulationId: data.messageSendId,
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS170_008),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      // MCB リプレース #7945対応 start
      selectionManagementId: [data.selectionManagementId],
      // MCB リプレース #7945対応 end
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const selectionManagementsIds = yield call(
      showSendObjEntryListRequest,
      sendObjTransitionEntryListRequest
    )
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    pathName = routeList.applicantList
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCBリプレイス #8782 START
// 受信トレー件数取得
function* selectReceiveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(receiveCountRequest,recruitmentManagementDivision);
    yield put(setReceiveCount(data))
  } catch (error){
    yield put(openModal(error.message));
  }
}
// 下書き件数取得
function* selectDraftCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(draftCountRequest,recruitmentManagementDivision);
    yield put(setDraftCount(data))
  } catch (error){
    yield put(openModal(error.message));
  }
}
// 送信予約件数取得
function* selectReserveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(reserveCountRequest,recruitmentManagementDivision);
    yield put(setReserveCount(data))
  } catch (error){
    yield put(openModal(error.message));
  }
}
// Mcb送信予約件数取得
function* selectMcbReserveCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(mcbReserveCountRequest,recruitmentManagementDivision);
    yield put(setMcbReserveCount(data))
  } catch (error){
    yield put(openModal(error.message));
  }
}
// Mcb下書き件数取得
function* selectMcbDraftCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(mcbDraftCountRequest,recruitmentManagementDivision);
    yield put(setMcbDraftCount(data))
  } catch (error){
    yield put(openModal(error.message));
  }
}
// MCBリプレイス #8782 END
// MCAZS170 END
export default function* mcbMessageListSaga() {
  yield all([
    // MCAZS170 START
    // MCAZS170 初期
    takeLatest(mcbOutBoxInit, mcbOutBoxInitSelectSaga),
    // "検索"ボタン押下
    takeLatest(
      setMcbMessageOutBoxListSearchCondition,
      setMcbMessageOutBoxListSearchConditionSaga
    ),
    takeLatest(deleteOutBoxMessage, deleteOutBoxMessageSaga),
    takeLatest(
      getFailSelectionManagementIdBulkList,
      showEntryListRequestSaga
    ),
    takeLatest(
      getSelectionManagementIdBulkListMCAZS170,
      getSelectionManagementIdMCAZS170Saga
    ),
    // MCBリプレイス #8782 START
    takeEvery(selectReceiveCount, selectReceiveCountSaga),
    takeEvery(selectReserveCount, selectReserveCountSaga),
    takeEvery(selectDraftCount, selectDraftCountSaga),
    takeEvery(selectMcbReserveCount,selectMcbReserveCountSaga),
    takeEvery(selectMcbDraftCount,selectMcbDraftCountSaga),
    // MCBリプレイス #8782 END
    // MCAZS170 END
    // MCAZS180 START
    // MCAZS180 初期
    takeLatest(initSelectMCAZS180, initSelectMCAZS180Saga),
    // "検索"ボタン押下
    takeLatest(
      setMcbMessageReservationListSearchCondition,
      setMcbMessageReservationListSearchConditionSaga
    ),
    // 送信をキャンセル"ボタン押下
    takeLatest(deleteMessageMCAZS180, deleteMessageMCAZS180Saga),
    // すぐに送信"ボタン押下
    takeLatest(sendMessageMCAZS180, sendMessageMCAZS180Saga),
    // 行選択時
    takeLatest(editMessageMCAZS180, editMessageMCAZS180Saga),
    // "送信失敗表示"リンク押下時
    takeLatest(getFailSelectionManagementIdBulkListMCAZS180, getFailSelectionManagementIdMCAZS180Saga),
    // "送信対象表示"リンク押下時
    takeLatest(getSelectionManagementIdBulkListMCAZS180, getSelectionManagementIdBulkListMCAZS180Saga),
    // MCAZS180 END
    // MCAZS190
    takeLatest(
      getSelectionManagementIdBulkListMCAZS190,
      getSelectionManagementIdMCAZS190Saga
    ),
    takeLatest(
      setMcbMessageDraftListSearchCondition,
      setMcbMessageDraftListSearchConditionSaga
    ),
    takeLatest(initMcbSelect, initMcbSelectSaga),
    takeEvery(deleteMcbMessage, deleteMcbMessageSaga),
    takeLatest(editMessageMCAZS190, editMessageMCAZS190Saga),
    // MCAZS190
    // MCAZS200
    // MCAZS200 初期
    takeLatest(initMcbDustboxSelect, initMcbDustboxSelectSaga),
    // "検索"ボタン押下
    takeLatest(
      setMcbMessageDustboxListSearchCondition,
      setMcbMessageDustboxListSearchConditionSaga
    ),
    takeLatest(undoMcbDustboxMessage, undoMcbDustboxMessageSaga),
    takeLatest(deleteMcbDustboxMessage, deleteMcbDustboxMessageSaga),
    // takeLatest(editMessageMCAZS200, editMessageMCAZS200Saga),
    takeLatest(
      getSelectionManagementIdBulkListMCAZS200,
      getSelectionManagementIdMCAZS200Saga
    ),
    // MCAZS200
    takeLatest(getinitAreaOutBox, getinitAreaOutBoxRequest),
    takeLatest(getinitAreaReservation, getinitAreaReservationRequest),
    takeLatest(getinitAreaDraft, getinitAreaDraftRequest),
    takeLatest(getinitAreaDustBox, getinitAreaDustBoxRequest),
  ])
}
// MCAZS180
// MCAZS180 初期
function* initSelectMCAZS180Saga(
  action: ReturnType<typeof initSelectMCAZS180>
  
) {
  try {
    yield put(setMcbMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initMcbReservationRequest, action.payload)
    yield put(setReserveFromDate(data.reserveFromDate))
    yield put(setMcbMessageReservationListAllCount(data.count))
    if(data.nomalBoxIconFlag!=null){
      yield put(setNomalBoxIconFlag(data.nomalBoxIconFlag))
    }
    // MCBリプレイス #8782 START
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    // MCBリプレイス #8782 END
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMcbMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAZS180 "検索"ボタン押下
function* setMcbMessageReservationListSearchConditionSaga(
  action: ReturnType<typeof setMcbMessageReservationListSearchCondition>
) {
  try {
    yield put(setMcbMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMcbMessageReservationRequest, action.payload)
    yield put(setMcbMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMcbMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信をキャンセル
function* deleteMessageMCAZS180Saga(
  action: ReturnType<typeof deleteMessageMCAZS180>
) {
  try {
    yield put(setMcbMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(deleteMcbMessageReservationRequest, action.payload)
    yield put(setMcbMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMcbMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS180_007))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// すぐに送信"ボタン押下
function* sendMessageMCAZS180Saga(
  action: ReturnType<typeof deleteMessageMCAZS180>
) {
  try {
    yield put(setMcbMessageReservationListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(sendMcbMessageReservationRequest, action.payload)
    yield put(setMcbMessageReservationListAllCount(data.count))
    if (
      data.resultList != null &&
      data.resultList.length > magiContants.INTEGER_0
    ) {
      yield put(setMcbMessageReservationListResults(data.resultList))
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS180_008))
  } catch (error) {
    //#MCB1.5次開発 #8986 START
    const arr = error.message.split(',')
    const messageId = arr[0]
    if(messageId ==  magiContants.MESSAGECODE_MCAZS180_016){
      const args = [arr[1]]
      yield put(openModalWithArg({messageId,args}))
    }else{
      yield put(openModal(error.message))
    }
    //#MCB1.5次開発 #8986 END
  }
}
// MCB リプレース #7964(B票#480,#481対応済) start
// 行選択
function* editMessageMCAZS180Saga(action: ReturnType<typeof editMessageMCAZS180>) {
  try {
    const messageType = action.payload.messageType
    const reservationMessages = action.payload.reservationMessages
    yield call(editMessageMCAZS180Request, reservationMessages)
    // #MCB1.5次開発 #81023 start
    if (messageType == "51") {
        const submissionRequestMessageDetailRequest = {
          flag: '0',
          request: {
            messageSendId: reservationMessages.messageSendId[0],
            jobSeekerSort: '1',
            screenId: 'MCBHS050',
            mcbMessageId:'',
          }
        }
        yield put(searchSubmissionRequestMessageDetail(submissionRequestMessageDetailRequest)) 
    // #MCB1.5次開発 #81023 end
    } else {
      const mcbMessageInputRequest = localStorage.getItem('mcbMessageInputRequest')
      // ・上記以外の場合、My CareerBoxメッセージ入力画面
      localStorage.setItem('messageInputRequest', mcbMessageInputRequest ? JSON.stringify({
        ...JSON.parse(mcbMessageInputRequest),
        // MCBメッセージ送信ID
        draftMessageSendId: reservationMessages.messageSendId[0],
        // システムバージョン番号
        sysVersionNumber: reservationMessages.sysVersionNumber,
      }) : JSON.stringify({
        // MCBメッセージ送信ID
        draftMessageSendId: reservationMessages.messageSendId[0],
        // システムバージョン番号
        sysVersionNumber: reservationMessages.sysVersionNumber,
      })
      )
      window.open(routeList.mcbMessageSend, '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCB リプレース #7964(B票#480,#481対応済)  end
// "送信失敗表示"リンク押下時
function* getFailSelectionManagementIdMCAZS180Saga(action: ReturnType<typeof getFailSelectionManagementIdBulkListMCAZS180>){
  try{
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS180',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS180_009),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      // MCB リプレース対応 #7945対応 start
      selectionManagementId: [data.selectionManagementId],
      // MCB リプレース対応 #7945対応 end
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const failSelectionManagementsIds = yield call(getMcbFailSelectionManagementApiMCAZS180,sendObjTransitionEntryListRequest)
    if (data.bulkSendFlag === magiContants.BULK_SEND_FLAG_1) {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        specificSelectionManagementIdList: failSelectionManagementsIds,
      }
    } else {
      transitionEntryListRequest = {
        ...transitionEntryListRequest,
        screenId: 'MCAZS180',
        specificSelectionManagementIdList: [data.selectionManagementId.toString()],
      }
    }
    // 就職：応募者検索・一覧（応募者単位）
    let pathName = routeList.applicantList
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// "送信対象表示"リンク押下時
function* getSelectionManagementIdBulkListMCAZS180Saga(action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS180>){
  try{
    const data = action.payload
    let transitionEntryListRequest = {
      listId: '0',
      screenId: 'MCAZS180',
      bulkManipulationId: (data.messageSendId as number).toString(),
      specificCriteriaName: getMessage(magiContants.MESSAGECODE_MCAZS180_010),
      specificSelectionManagementIdList: [] as string[],
    }
    let sendObjTransitionEntryListRequest = {
      listId: transitionEntryListRequest.listId,
      calledFunctionId: transitionEntryListRequest.screenId,
      messageSendId: transitionEntryListRequest.bulkManipulationId,
      // MCB リプレース対応 #7945対応 start
      selectionManagementId: [data.selectionManagementId],
      // MCB リプレース対応 #7945対応 end
      searchConditionOverview: transitionEntryListRequest.specificCriteriaName,
    }
    const selectionManagementsIds = yield call(getSelectionManagementApiMCAZS180, sendObjTransitionEntryListRequest)
    transitionEntryListRequest = {
      ...transitionEntryListRequest,
      screenId :'MCAZS180',
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    // 就職：応募者検索・一覧（応募者単位）
    let pathName = routeList.applicantList
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAZS180 END
// ===========================================MCAZS190 START=================================================
// "検索"ボタン押下
function* setMcbMessageDraftListSearchConditionSaga(
  action: ReturnType<typeof setMcbMessageDraftListSearchCondition>
) {
  try {
    yield put(setMcbMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMcbMessageDraftRequest, action.payload)
    yield put(setMcbMessageDraftListAllCount(data.draftCount))
    if (
      data.mcazs190McbSubmissionStatusSearchOutDto != null &&
      data.mcazs190McbSubmissionStatusSearchOutDto.length >
        magiContants.INTEGER_0
    ) {
      yield put(
        setMcbMessageDraftListResults(
          data.mcazs190McbSubmissionStatusSearchOutDto
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbReserveCount())
    yield put(selectMcbDraftCount())
    // MCBリプレイス #8782 END
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 初期
function* initMcbSelectSaga(action: ReturnType<typeof initMcbSelect>) {
  try {
    yield put(setMcbMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initMcbRequest, action.payload)
    yield put(setMcbUpdateFromDate(data.updateFromDate))
    yield put(setMcbMessageDraftListAllCount(data.draftCount))
    if(data.nomalBoxIconFlag!=null){
      yield put(setNomalBoxIconFlag(data.nomalBoxIconFlag))
    }
    // MCBリプレイス #8782 START
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    // MCBリプレイス #8782 END
    if (
      data.mcazs190McbSubmissionStatusSearchOutDto != null &&
      data.mcazs190McbSubmissionStatusSearchOutDto.length > magiContants.INTEGER_0) {
      yield put(
        setMcbMessageDraftListResults(
          data.mcazs190McbSubmissionStatusSearchOutDto
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ダイアログの"ゴミ箱へ移動"ボタン押下時
function* deleteMcbMessageSaga(action: ReturnType<typeof deleteMcbMessage>) {
  try {
    yield put(setMcbMessageDraftListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(deleteMcbMessageRequest, action.payload)
    yield put(setMcbMessageDraftListAllCount(data.draftCount))
    if (
      data.mcazs190McbSubmissionStatusSearchOutDto != null &&
      data.mcazs190McbSubmissionStatusSearchOutDto.length > magiContants.INTEGER_0) {
      yield put(
        setMcbMessageDraftListResults(
          data.mcazs190McbSubmissionStatusSearchOutDto
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS190_004))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信対象リンク押下時、送信対象の選考管理ID取得する
function* getSelectionManagementIdMCAZS190Saga(action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS190>
) {
  try {
    const selectionManagementsIds = yield call(getSelectionManagementApiMCAZS190,action.payload)
    const transitionEntryListRequest = {
      ...action.payload,
      screenId :'MCAZS190',
      bulkManipulationId: action.payload.messageSendId,
      specificCriteriaName: action.payload.searchConditionOverview,
      specificSelectionManagementIdList: selectionManagementsIds,
    }
    let pathName = ''
    // 就職：応募者検索・一覧（応募者単位）
    pathName = routeList.applicantList
    const path = {
      pathname: pathName,
      state: {
        request: transitionEntryListRequest,
      },
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 行選択時
function* editMessageMCAZS190Saga(action: ReturnType<typeof editMessageMCAZS190>) {
  try {
    // MCB リプレース #7964(B票#482,#483,#484,#485対応済)  start
    const messageType = action.payload.messageType
    const mCAZS190MessageInputRequest = action.payload.mCAZS190MessageInputRequest
    yield call(editMessageMCAZS190Request, mCAZS190MessageInputRequest)
    switch (messageType) {
      // 提出リクエスト
      case "51":
        const mcbMessageInputRequest = localStorage.getItem('mcbMessageInputRequest')
        localStorage.setItem('mcbMessageInputRequest',mcbMessageInputRequest? JSON.stringify({
              ...JSON.parse(mcbMessageInputRequest),
              // MCBメッセージ送信ID
              draftMessageSendId: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
              // ステータス
              status: '10'
            }): JSON.stringify({
              // MCBメッセージ送信ID
              draftMessageSendId: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
              // ステータス
              status: '10'
            })
        )
        window.open(routeList.submissionRequestSend, '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
        break;
      //  強制停止
      case "53":
        // 強制停止の場合、提出リクエスト強制停止画面へ
        const forcedStopRequest = localStorage.getItem('forcedStopRequest')
        localStorage.setItem(
          'forcedStopRequest',
          forcedStopRequest
            ? JSON.stringify({
              ...JSON.parse(forcedStopRequest),
              // MCBメッセージ送信ID
              draftMessageSendID: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
            })
            : JSON.stringify({
              // MCBメッセージ送信ID
              draftMessageSendID: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
            })
        )
        window.open(routeList.forcedStopSubmissionRequest, '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
        break;
      //提出締切変更  
      case "52":
        const deadlineChangeRequest = localStorage.getItem('changeRequest')
        localStorage.setItem(
          'changeRequest',
          deadlineChangeRequest
            ? JSON.stringify({
              ...JSON.parse(deadlineChangeRequest),
              // MCBメッセージ送信ID
              draftMessageSendID: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
            })
            : JSON.stringify({
              // MCBメッセージ送信ID
              draftMessageSendID: mCAZS190MessageInputRequest.messageSendId[0],
              // システムバージョン番号
              sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
            })
        )
        window.open(routeList.ChangeTermRequest, '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
        break;
      // My CareerBoxメッセージ入力
      default:
        // メッセージ入力画面に遷移する
        const messageInputRequest = localStorage.getItem('messageInputRequest')
        const newParams = {
          // MCBメッセージ送信ID
          draftMessageSendId: mCAZS190MessageInputRequest.messageSendId[0],
          // システムバージョン番号
          sysVersionNumber: mCAZS190MessageInputRequest.sysVersionNumber,
        }
        localStorage.setItem(
          'messageInputRequest',
          messageInputRequest
            ? JSON.stringify({
              ...JSON.parse(messageInputRequest),
              ...newParams,
            })
            : JSON.stringify(newParams)
        )
        window.open(routeList.mcbMessageSend, '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + 1280 + ',height=' + 948 + ',left=' + 10 + ',top=' + 10 + '')
        break;
    }
    // MCB リプレース #7964(B票#482,#483,#484,#485対応済)  end
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ===========================================MCAZS190 END=================================================

// ===========================================MCAZS200 START===============================================
// MCAZS200 初期
function* initMcbDustboxSelectSaga(
  action: ReturnType<typeof initMcbDustboxSelect>
) {
  try {
    yield put(setMcbMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(initMcbDustboxRequest, action.payload)
    yield put(setMcbDeleteFromDate(data.deleteFromDate))
    yield put(setMcbMessageDustboxListAllCount(data.duxtBoxCount))
    if(data.nomalBoxIconFlag!=null){
      yield put(setNomalBoxIconFlag(data.nomalBoxIconFlag))
    }
    // MCBリプレイス #8782 START
    yield put(selectReceiveCount())
    yield put(selectReserveCount())
    yield put(selectDraftCount())
    // MCBリプレイス #8782 END
    if (
      data.mcazs200McbSubmissionStatusSearchOutDtoList != null &&
      data.mcazs200McbSubmissionStatusSearchOutDtoList.length >
        magiContants.INTEGER_0
    ) {
      yield put(
        setMcbMessageDustboxListResults(
          data.mcazs200McbSubmissionStatusSearchOutDtoList
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAZS200 "検索"ボタン押下
function* setMcbMessageDustboxListSearchConditionSaga(
  action: ReturnType<typeof setMcbMessageDustboxListSearchCondition>
) {
  try {
    yield put(setMcbMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(selectMcbMessageDustboxRequest, action.payload)
    yield put(setMcbMessageDustboxListAllCount(data.duxtBoxCount))
    if (
      data.mcazs200McbSubmissionStatusSearchOutDtoList != null &&
      data.mcazs200McbSubmissionStatusSearchOutDtoList.length >
        magiContants.INTEGER_0
    ) {
      yield put(
        setMcbMessageDustboxListResults(
          data.mcazs200McbSubmissionStatusSearchOutDtoList
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ダイアログの"元に戻す"ボタン押下時
function* undoMcbDustboxMessageSaga(
  action: ReturnType<typeof undoMcbDustboxMessage>
) {
  try {
    yield put(setMcbMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data = yield call(undoMcbDustboxMessageRequest, action.payload)
    if (
      data.mcazs200McbSubmissionStatusSearchOutDtoList != null &&
      data.mcazs200McbSubmissionStatusSearchOutDtoList.length >
        magiContants.INTEGER_0
    ) {
      yield put(
        setMcbMessageDustboxListResults(
          data.mcazs200McbSubmissionStatusSearchOutDtoList
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(setMcbMessageDustboxListAllCount(data.duxtBoxCount))
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS200_005))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ダイアログの"完全に削除"ボタン押下時
function* deleteMcbDustboxMessageSaga(
  action: ReturnType<typeof deleteMcbDustboxMessage>
) {
  try {
    yield put(setMcbMessageDustboxListResults([]))
    yield put(setMessageFlagResult(-1))
    const data =  yield call(deleteMcbDustboxMessageRequest, action.payload)
    if (
      data.mcazs200McbSubmissionStatusSearchOutDtoList != null &&
      data.mcazs200McbSubmissionStatusSearchOutDtoList.length >
        magiContants.INTEGER_0
    ) {
      yield put(
        setMcbMessageDustboxListResults(
          data.mcazs200McbSubmissionStatusSearchOutDtoList
        )
      )
    } else {
      yield put(setMessageFlagResult(0))
    }
    yield put(setMcbMessageDustboxListAllCount(data.duxtBoxCount))
    // MCBリプレイス #8782 START
    yield put(selectMcbDraftCount())
    yield put(selectMcbReserveCount())
    // MCBリプレイス #8782 END
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAZS200_006))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信対象リンク押下時、送信対象の選考管理ID取得する
function* getSelectionManagementIdMCAZS200Saga(action: ReturnType<typeof getSelectionManagementIdBulkListMCAZS200>
  ) {
    
    try {
      const selectionManagementsIds = yield call(getSelectionManagementApiMCAZS200,action.payload)
      const transitionEntryListRequest = {
        ...action.payload,
        screenId :'MCAZS200',
        bulkManipulationId: action.payload.messageSendId,
        specificCriteriaName: action.payload.searchConditionOverview,
        specificSelectionManagementIdList: selectionManagementsIds,
      }
      let pathName = ''
      // 就職：応募者検索・一覧（応募者単位）
      pathName = routeList.applicantList
      const path = {
        pathname: pathName,
        state: {
          request: transitionEntryListRequest,
        },
      }
      history.push(path)
    } catch (error) {
      yield put(openModal(error.message))
    }
  }

// ===========================================MCAZS200 END===============================================
// 送信トレーアラートエリア初期表示
function* getinitAreaOutBoxRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {lastLogoutTime: getGlobalMenu.lastLogoutTime,
    previousLoginTime: getGlobalMenu.previousLoginTime,
    recruitmentManagementDivision: getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 送信予約アラートエリア初期表示
function* getinitAreaReservationRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {lastLogoutTime: getGlobalMenu.lastLogoutTime,
    previousLoginTime: getGlobalMenu.previousLoginTime,
    recruitmentManagementDivision: getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 下書きエリア初期表示
function* getinitAreaDraftRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {lastLogoutTime: getGlobalMenu.lastLogoutTime,
    previousLoginTime: getGlobalMenu.previousLoginTime,
    recruitmentManagementDivision: getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// ゴミ箱エリア初期表示
function* getinitAreaDustBoxRequest() {
  try {
    const getGlobalMenu = yield select(getGlobalMenuData)
    const actionArea = {lastLogoutTime: getGlobalMenu.lastLogoutTime,
    previousLoginTime: getGlobalMenu.previousLoginTime,
    recruitmentManagementDivision: getGlobalMenu.recruitmentManagementDivision,
    }
    // 初期データ取得
    const dataArea = yield call(initAreaContactPassRequest, actionArea)
    // 初期表示_アラートエリア
    yield put(setInitAreaContactPass(dataArea))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
