import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  jobCategory: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
}))

export default () => {
  const classes = useStyles()

  return (
    <div className='box-filter'>
      <em>選考フィルター</em>
      <div>
        <b>選考フロー</b>
        <select>
          <option>すべて</option>
          <option>XXXXXXXXXXXXXXXXXXXXXXXX</option>
        </select>
      </div>
      <div>
        <b>進捗</b>
        <p className='group-horizontal fix5'>
          <label>
            <input type='radio' name='filter-radio-01' />
            <mark>
              すべて<i>12</i>
            </mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-01' />
            <mark>
              書類選考<i>0</i>
            </mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-01' />
            <mark>
              1次面接<i>12</i>
            </mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-01' />
            <mark>
              2次面接<i>12</i>
            </mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-01' />
            <mark>
              最終面接<i>12</i>
            </mark>
          </label>
        </p>
      </div>
      <div>
        <b>判定</b>
        <p className='group-horizontal'>
          <label>
            <input type='radio' name='filter-radio-02' />
            <mark>すべて</mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-02' />
            <mark>判定中</mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-02' />
            <mark>合格</mark>
          </label>
          <label>
            <input type='radio' name='filter-radio-02' />
            <mark>不合格</mark>
          </label>
        </p>
      </div>
      <div>
        <b>氏名</b>
        <input type='text' placeholder='氏名を入力' />
        <b className={classes.jobCategory}>応募職種</b>
        <input type='text' />
      </div>
      <div className='box-tags'>
        <b>タグ</b>
        <div>
          <button type='button' className='btn on-icon icon-tagManagement'>
            タグを指定する
          </button>
          <ul>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>リファラル</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>大学卒</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
            <li>
              <label>
                <input type='checkbox' defaultChecked={true} />
                <mark>xxxxxxxxxxxxxxx</mark>
              </label>
            </li>
          </ul>
          <small>よく使われるタグ</small>
          <ol>
            <li>
              <button type='button' className='btn'>
                スクリーニング〇
              </button>
            </li>
            <li>
              <button type='button' className='btn'>
                現場確認
              </button>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
