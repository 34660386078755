import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core'
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import { magiContants as contants, magiContants, validationMessageSet } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import {
  getinitTag,
  insertTag,
  insertTagJob,
  setSelectedTag,
  setShow,
} from 'reducers/tagManagementReducer'
import { RootState } from 'reducers'
import * as apiConfig from 'pages/MCAXS170/apiConfig'
import * as yup from 'yup'
import customSelectOptions from 'utils/customSelectOptions'
import { LoadingDialog } from 'components'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  actions: {
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '24px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '24px !important',
  },
  tagTitle: {
    margin: theme.spacing(3, 0, 1),
  },
  tag: {
    color: '#1950a6',
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  select: {
    margin: theme.spacing(1, 0),
    '& div' : {
      cursor: 'pointer!important',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
  select2: {
    margin: theme.spacing(1, 0),
    '& div' : {
      cursor: 'pointer!important',
      borderColor: '#ff0000!important',
      boxShadow: '0 0 0 0 #ff0000',
    },
    '& input': {
      cursor: 'pointer!important',
    },
  },
}))

interface Option {
  value: string
  label: string
}

interface TagNames {
  value: string
  label: string
}

interface RequestVal {
  screenId: string
  jobSeekerId: string[]
  selectionManagementId: string[]
  entryId: string[]
}

const initRequest = {
  jobSeekerId: [''],
  entryId: [''],
}

interface oldTagList {
  tagName: string
  tagSettingId: string
  tagManagementId: string
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: (theme.palette as any).white,
    color: (theme.palette as any).black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

interface Props {
  open: boolean
  requestVal: RequestVal
  onCloseApplicantDetail?: (event: any) => void
  onCloseApplicantList?: (tagSettingIdList: Map<string, string>) => void
  onCancel: () => void
  selectTag?: Option[]
}

const Select = ({
  open,
  onCloseApplicantDetail,
  onCloseApplicantList,
  onCancel,
  requestVal,
  selectTag,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [targetVal, setTargetVal] = useState(0)
  const [targetVal2, setTargetVal2] = useState(0)
  const recruitmentManagementDivision = useSelector(
    (state: RootState) => state.globalMenu.recruitmentManagementDivision
  )
  useEffect(() => {
    if (open) {
      dispatch(getinitTag(requestVal))
      if (
        recruitmentManagementDivision ===
        contants.RECRUITMENT_MANAGEMENT_DIVISION_1
      ) {
        //新卒
        setTargetVal(requestVal.selectionManagementId.length)
        setTargetVal2(requestVal.jobSeekerId.length)
      } else {
        //中途
        setTargetVal(requestVal.selectionManagementId.length)
      }
    }
  }, [open])
  const tagInitResult = useSelector(
    (state: RootState) => state.tagManagement.TagManagementInitResult
  )
  let tagInitInfos: Option[] = []
  tagInitResult.tagSuggestList.map(i => {
      tagInitInfos.push({
        value: i.tagSettingId.toString(),
        label: i.tagName,
      })
  })
  const {selectedTag, show} = useSelector(
    (state: RootState) => state.tagManagement
  )
  const rs060TagList = useSelector((state:RootState) => state.actionPre.actionPreEdit.tagList)
  const [oldTagList,setOldTagList] = useState([] as oldTagList[])
  const tagSettingIds = useSelector((state: RootState) => state.tagManagement.TagSettings)
  const [error, setError] = useState({ isError: false, message: '' })
  const [seleTag, setSeleTag] = useState<Option[]>([])
  useEffect(() => {
    setError({ isError: false, message: '' })
    if ('MCAXS260' === requestVal.screenId && ((recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 && requestVal.jobSeekerId.length > 1) || recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 && requestVal.selectionManagementId.length > 1)) {
        dispatch(setSelectedTag([]))
    } else {
        if (tagInitResult.tagRegisteredList && tagInitResult.tagRegisteredList.length > 0) {
          dispatch(
            setSelectedTag(
              tagInitResult.tagRegisteredList.map(i => ({
                value: i.tagSettingId.toString(),
                label: i.tagName,
              }))
            )
          )
          const oldTagList = tagInitResult.tagRegisteredList.map(i => {
            return { tagName: i.tagName, tagSettingId: i.tagSettingId.toString(), tagManagementId:i.tagManagementId}
          })
          setOldTagList(oldTagList)
        } else {
            dispatch(setSelectedTag([]))
            setOldTagList([])
            if('MCARS060' === requestVal.screenId && (rs060TagList && rs060TagList.length > 0 || tagSettingIds && tagSettingIds.length > 0)){
              if(tagSettingIds && tagSettingIds.length > 0){
                const tagContentList = tagSettingIds.map(j => {
                    return tagInitResult.tagSuggestList.filter(i => Number(i.tagSettingId) === j)[0]
                })
                dispatch(setSelectedTag(
                  tagContentList.map(i => ({
                    value: i.tagSettingId.toString(),
                    label: i.tagName,
                  }))
                ))
              }else{
                dispatch(setSelectedTag(
                  rs060TagList.map(i => ({
                    value: i.tagSettingId.toString(),
                    label: i.tagName,
                  }))))
              }
            }
            if (['MCAXS010', 'MCAXS020'].includes(requestVal.screenId)) {
                if (selectTag) {
                    setSeleTag(
                        selectTag.map(i => ({
                            value: i.value,
                            label: i.label,
                        }))
                    )
                }
            }
        }
    }
  }, [tagInitResult.tagRegisteredList, selectTag])
  const selectedTagYup = yup.object().shape({
    tagName:yup.mixed()
      .test(
        'testString',
        '',
        async function (value: any[]) {
          let flg = false
          let message
          await test.validate({ testStrings: value.map(i => i).join('') }).then(async () => {
            await yup.mixed()
            .test(
              'maxSpace',
              validationMessageSet.required(),
              (value: any[]) => {return value && value.every((i: any) => {
                return i.trim().length != 0;
              })}
            ).validate(value,{abortEarly:false}).catch(err=>{
              message = Array.isArray(err.errors) ? err.errors.join('\n') : err.errors
              flg = true
            })
          }).catch(err => {
            message = err.errors
            flg = true
          })
          if (flg) {
            return this.createError({ message: message })
          } else {
            return true
          }
        })
      })
  const test = yup.object().shape({
    testStrings: yup.string().test('myStringLocale', '', () => true)
  })
  const handleChange = (value: any) => {
    let varss =[]
    for (var i in value) {
      let vars 
      if (value[i].label.length === undefined) {
        vars = value[i].value
        value[i].label = vars
      } else {
        vars = value[i].label
      }
      varss.push(vars) 
    }
    selectedTagYup.validate({ tagName: varss })
    .then()
    .catch(err => {
      setError({ isError: true, message: err.errors })
    })
    if (['MCAXS010', 'MCAXS020'].includes(requestVal.screenId)) {
        setSeleTag(value)
    }
    dispatch(setSelectedTag(value))
    setError({ isError: false, message: '' })
  }

  // Tooltip
  const [tooltipState, setTooltipState] = useState(
    tagInitResult.tagSuggestList.reduce((result, current) => {
      result[current.tagSettingId] = false
      return result
    }, {} as any)
  )

  useEffect(() => {
    setTooltipState(
      tagInitResult.tagSuggestList.reduce((result, current) => {
        result[current.tagSettingId] = false
        return result
      }, {} as any)
    )
  }, tagInitResult.tagSuggestList)

  const handleTooltipClose = (id: string) => () => {
    setTooltipState({
      ...tooltipState,
      [id]: false,
    })
  }

  // Modals
  const [modals, setModals] = useState({
    cancel: false,
    error: false,
    confirm: false,
  })

  const handleModals = (modalName: string, status = true) => () => {
    setModals({
      ...modals,
      [modalName]: status,
    })
  }

  const handleClick = (val: apiConfig.TagInCommonUseList) => () => {
    const option = {
      value: val.tagSettingId,
      label: val.tagName,
    }
    if(['MCAXS010', 'MCAXS020'].includes(requestVal.screenId)) {
      if (seleTag) {
        if (seleTag.length) {
          if (!seleTag.some(i => i.value === option.value)) {
            setSeleTag([...seleTag, option])
          } else {
            setTooltipState({
              ...tooltipState,
              [val.tagSettingId]: true,
            })
          }
        } else {
          setSeleTag([option])
        }
      } else {
        setSeleTag([option])
      }
    } else {
      if (selectedTag) {
        if (selectedTag.length) {
          if (!selectedTag.some(i => i.value.toString() === option.value.toString())) {
            dispatch(setSelectedTag([...selectedTag, option]))
          } else {
            setTooltipState({
              ...tooltipState,
              [val.tagSettingId]: true,
            })
          }
        } else {
          dispatch(setSelectedTag([option]))
        }
      } else {
        dispatch(setSelectedTag([option]))
      }
    }
  }

  const [errVal, setErrVal] = useState('')

  const handleSave = () => {
    if (error.isError) {
      return
    }
    setErrVal('')
    let errflg = false
    if (selectedTag) {
      if (selectedTag.length > 10) {
        if ('MCAXS260' === requestVal.screenId) {
          if ((recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_1 && targetVal2 > 1) 
            || (recruitmentManagementDivision === contants.RECRUITMENT_MANAGEMENT_DIVISION_2 && targetVal > 1)) {
            setErrVal(contants.MESSAGECODE_MCAXS170_006)
            errflg = true
          }
        } else {
          for (var i = 0; i < selectedTag.length; i++) {
            if (selectedTag[i].label.length > 20) {
              setErrVal(contants.MESSAGECODE_MCAXS170_007)
              errflg = true
              break
            }
          }
        }
      } else {
        for (var i = 0; i < selectedTag.length; i++) {
          if (selectedTag[i].label.length > 20) {
            setErrVal(contants.MESSAGECODE_MCAXS170_007)
            errflg = true
            break
          }
        }
      }
    }
    if (errflg) {
      handleModals('error')()
    } else {
      handleModals('confirm')()
    }
  }

  const handleClose = (modalName: string) => {
    modals.cancel = false
    modals.error = false
    modals.confirm = false
    if('cancel' == modalName){
      dispatch(setShow(false))
    }
  }

  const handleRegist = (event: any) => {
    if (['MCAXS010', 'MCAXS020'].includes(requestVal.screenId)) {
      if (onCloseApplicantList !== undefined) {
        if (seleTag) {
          let obj = new Map<string, string>()
          seleTag.forEach(item => {
            obj.set(item.value.toString(), item.label.toString())
          })
          onCloseApplicantList(obj)
        } else {
          onCloseApplicantList(new Map<string, string>())
        }
      }
    } else if ('MCARS060' === requestVal.screenId) {
      dispatch(
        insertTagJob({
          request: {
            screenId: requestVal.screenId,
            selectionId: '0',
            tagManagementId: selectedTag ? selectedTag.map(i => i.value) : [],
            delTagSettingId: [],
            delTagManagementId: [],
            jobSeekerId: requestVal.jobSeekerId,
            selectionManagementId: requestVal.selectionManagementId,
            tagSettingId: selectedTag ? selectedTag.map(i => i.value) : [],
            tagName: selectedTag ? selectedTag.map(i => i.label) : [],
            entryId: requestVal.entryId,
          },
          onCloseApplicantList: onCloseApplicantList !== undefined ? onCloseApplicantList : undefined,
          selectedTag: selectedTag ? selectedTag : null
        })
      )
    } else {
      dispatch(
        insertTagJob({
          request: {
            screenId: requestVal.screenId,
            selectionId: '0',
            tagManagementId: selectedTag ? selectedTag.map(i => i.value) : [],
            delTagSettingId: oldTagList ? oldTagList.map(i => i.tagSettingId) : [],
            delTagManagementId: oldTagList ? oldTagList.map(i => i.tagManagementId) : [],
            jobSeekerId: requestVal.jobSeekerId,
            selectionManagementId: requestVal.selectionManagementId,
            tagSettingId: selectedTag ? selectedTag.map(i => i.value) : [],
            tagName: selectedTag ? selectedTag.map(i => i.label) : [],
            entryId: requestVal.entryId,
          },
          onClose:
            onCloseApplicantDetail !== undefined
              ? onCloseApplicantDetail
              : undefined,
          event:
            onCloseApplicantDetail !== undefined ? { ...event } : undefined,
          onCloseApplicantList: undefined,
          selectedTag: null,
        })
      )
    }
  }

  const selectStyles = {
    menuPortal: (base: any) => ({
      ...base,
      zIndex: '9999 !important',
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: '9999 !important',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '2px 16px',
    }),
  }

  if(show){
    return (
      <>
        {open ? <div><LoadingDialog className='overlay' /></div> : null}
        <Dialog fullWidth open={open} maxWidth='md' className={'mainDialog'}>
          <DialogTitle className={classes.dialogTitle}>
            <label className={classes.title}>{['MCAXS010', 'MCAXS020', 'MCARS060'].includes(requestVal.screenId) ? 'タグを指定する' : 'タグ付け'}</label>
          </DialogTitle>
          <DialogContent>
            <div className='ps-container ps'>
              <div>
                {requestVal.screenId === 'MCAXS260'
                  ? recruitmentManagementDivision ===
                    contants.RECRUITMENT_MANAGEMENT_DIVISION_1
                    ? '対象件数：' + targetVal + ' ( ' + targetVal2 + ' ) ' + '件'
                    : '対象件数：' + targetVal + '件'
                  : null}
                  <p>
                    タグの追加・削除ができます。<br />
                    キーワード入力でタグの追加ができます。<br />
                    また、よく使われているタグのリンクをクリックすることでも追加されます。
                  </p>
              </div>
              {
                ['MCAXS010', 'MCAXS020'].includes(requestVal.screenId) ?
                <>
                <div>登録されているタグ</div>
                <ReactSelect
                  className={classes.select}
                  isMulti
                  options={tagInitInfos}
                  value={seleTag}
                  onChange={handleChange}
                  styles={selectStyles}
                  placeholder=' '
                  noOptionsMessage={() => magiContants.REACT_SELECT_NOOPTIONSMESSAGE}
                  menuPortalTarget={document.body}
                  isClearable={false}
                  filterOption={customSelectOptions.filterOption}
                />
                </>
                :
                <>
                <div>              
                {requestVal.selectionManagementId.length === 1
                  ? '登録されているタグ': '付与するタグ'}              
                </div>
                <CreatableSelect
                  className={`${
                    error.isError
                    ? classes.select2
                    : classes.select}`}
                  isMulti
                  options={tagInitInfos}
                  value={selectedTag}
                  onChange={handleChange}
                  placeholder=' '
                  styles={selectStyles}
                  menuPortalTarget={document.body}
                  formatCreateLabel={(inputValue) => (<p>"{inputValue}"{contants.MESSAGEINFO_NEW_REGULATIONS_ADD}</p>)}
                  noOptionsMessage={() => magiContants.REACT_SELECT_NOOPTIONSMESSAGE}
                  isClearable={false}
                  { ...customSelectOptions as any }
                />
                <div style = {{color : '#e53935' , fontSize: '11px', paddingLeft: '14px'}}>
                  {error.isError && error.message !== '' ? error.message : null}</div>
                </>
              }
              <p>※Enterキー押下で入力内容をセットします。</p>
              <div>
                <h2 className={classes.tagTitle}>よく使われているタグ</h2>
                {tagInitResult.tagInCommonUseList.map(item => (
                  <ClickAwayListener
                    onClickAway={handleTooltipClose(item.tagSettingId)}>
                    <LightTooltip
                      key={item.tagSettingId}
                      title='既に指定されています'
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={tooltipState[item.tagSettingId]}
                      onClose={handleTooltipClose(item.tagSettingId)}
                      placement='top'>
                      <span className={classes.tag} onClick={handleClick(item)}>
                        {item.tagName}
                      </span>
                    </LightTooltip>
                  </ClickAwayListener>
                ))}
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              color='primary'
              variant='contained'
              className={classes.cancel}
              onClick={handleModals('cancel')}>
              キャンセルする
            </Button>
            <Button
              className={classes.confirmButton}
              color='primary'
              variant='contained'
              onClick={handleSave}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Cancel Modal */}
        <Dialog open={modals.cancel} fullWidth maxWidth='sm'>
            <DialogTitle>
            {getMessage(contants.MESSAGECODE_MCAXS170_004)}
            </DialogTitle>
          <DialogActions>
            <Button onClick={handleModals('cancel', false)}>キャンセル</Button>
            <Button
              onClick={() => {
                handleClose('cancel')
                onCancel()
              }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Error Modal */}
        <Dialog 
          open={modals.error}
          fullWidth maxWidth='sm'
          transitionDuration={0}>
          <DialogTitle>エラー</DialogTitle>
          <DialogContent>
            <DialogContentText>{getMessage(errVal)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModals('error', false)}>OK</Button>
          </DialogActions>
        </Dialog>
  
        {/* Confirm Modal */}
        <Dialog open={modals.confirm} fullWidth maxWidth='sm'>
              <DialogTitle>
                {/* 年度対応 start */}
                {/* 年度対応 #6419 start */}
                {(recruitmentManagementDivision == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 || 'MCAXS010' == requestVal.screenId || 'MCARS060' == requestVal.screenId) ? getMessage(contants.MESSAGECODE_MCAXS170_003)
                     : getMessage(contants.MESSAGECODE_MCAXS170_007_1)}
                {/* 年度対応 #6419 end */}
                {/* 年度対応 end */}     
              </DialogTitle>
          <DialogActions>
            <Button onClick={handleModals('confirm', false)}>キャンセル</Button>
            <Button
              onClick={(event: any) => {
                handleClose('confirm')
                handleRegist(event)
              }}
              value='ok'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }else{
    return(<></>)
  }
}

export default Select
