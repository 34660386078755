import React from 'react';

interface Props {
  title: string;
  type: string;
}

export default ({ title, type }: Props) => {
  return (
    <button type="button" className={`btn on-icon icon-btn_${type}`}>
      {title}
    </button>
  );
};
