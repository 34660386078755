import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getMessage } from 'common/messageUtil'
import { FieldGroup, FormField, SubTitle } from 'componentsHsc'
import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import GroupOptions from 'componentsHsc/AdvancedSearchJobChange/GroupOptions'
import {
  QuestionnaireCondition,
  QuestionnaireConditionNested,
} from '../Config/itemConfig'
import TextField from './TextField'
import Toggle from './Toggle'
import UncheckableToggle from '../UncheckableToggle'
import { setSearchCondition } from 'reducers/applicantListReducer'
import { SearchCondition, toEntryList } from '../Config/searchConditionConfig'
import { innerSortFunc } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { onKeyDown } from 'common/generalUtil'
import { Virtuoso } from 'react-virtuoso'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important',
    height: '70vh',
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor: 'auto',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  dialogContentRoot: {
    padding: '0px 0px 8px 24px !important',
    overflowY: 'hidden !important' as any,
  },
  typeRadioRoot: {
    padding: '10px 0px 0px 5px',
  },
  lastField: {
    paddingBottom: '100px',
  },
}))

const optionList = [
  { label: '回答あり', value: '0' },
  { label: '回答なし', value: '1' },
]

const typeList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: SearchCondition
}

const EntryReplyModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    itemSpecsEntryReply,
    entryReplyModalState,
    entryReplyModalItems,
  } = useSelector((state: RootState) => state.itemSpecification)

  const handleSave = (data: any) => {
    const newForm = { ...form }
    const newCondList: QuestionnaireCondition[] = []
    const dataConditionList = data.conditionList as QuestionnaireConditionNested[]

    dataConditionList.forEach(i => {
      i.questionInfoList.forEach(j => {
        newCondList.push({
          questionnaireResponseApplicationRouteId:
            i.questionnaireResponseApplicationRouteId,
          questionnaireResponseApplicationRouteName:
            i.questionnaireResponseApplicationRouteName,
          questionnaireResponsecoordinationFormerEntryBoxId:
            i.questionnaireResponsecoordinationFormerEntryBoxId,
          questionnaireResponseEntryBoxId: i.questionnaireResponseEntryBoxId,
          questionnaireResponseEntryBoxName:
            i.questionnaireResponseEntryBoxName,
          questionnaireResponseQuestionNumber:
            j.questionnaireResponseQuestionNumber,
          questionnaireResponseQuestionTitle:
            j.questionnaireResponseQuestionTitle,
          questionnaireResponseQuestionAnswerConditionFlag:
            j.questionnaireResponseQuestionAnswerConditionFlag,
          questionnaireResponseQuestionAnswerConditionName:
            j.questionnaireResponseQuestionAnswerConditionFlag === '0'
              ? '回答あり'
              : '回答なし',
          questionnaireResponseQuestionTypeCode:
            j.questionnaireResponseQuestionTypeCode,
          questionnaireResponseQuestionTypeName:
            j.questionnaireResponseQuestionTypeName,
          questionnaireResponseAnswerText: j.questionnaireResponseAnswerText,
          questionnaireResponseAnswerCheckBoxObj:
            j.questionnaireResponseAnswerCheckBoxObj,
        })
      })
    })

    const newConditionList = newCondList
      .filter(i => i.questionnaireResponseQuestionAnswerConditionFlag !== '')
      .map(i => ({
        ...i,
        questionnaireResponseAnswerText:
          i.questionnaireResponseQuestionAnswerConditionFlag === '1'
            ? ''
            : i.questionnaireResponseAnswerText,
        questionnaireResponseAnswerCheckBoxObj:
          i.questionnaireResponseQuestionAnswerConditionFlag === '1'
            ? []
            : [...i.questionnaireResponseAnswerCheckBoxObj].sort((a, b) => {
                //選択肢の並びを画面順にソート
                const itemSpec = itemSpecsEntryReply.find(
                  j => j.entryBoxId === i.questionnaireResponseEntryBoxId
                )
                if (itemSpec) {
                  const orderObjArray = itemSpec.questionInfoList.find(
                    j =>
                      j.questionNumber === i.questionnaireResponseQuestionNumber
                  )
                  if (orderObjArray) {
                    return innerSortFunc(
                      { answerCheckBoxName: a.label },
                      { answerCheckBoxName: b.label },
                      orderObjArray.answerCheckBoxList.map(j => ({
                        answerCheckBoxName: j.label,
                      }))
                    )
                  } else {
                    return 0
                  }
                } else {
                  return 0
                }
              }),
      }))
    if (newConditionList.length > 0) {
      const newData = { ...data }
      newData.conditionList = newConditionList
      newForm.questionnaireResponseObj = newData
    } else {
      newForm.questionnaireResponseObj = null
    }
    dispatch(setSearchCondition(toEntryList(newForm)))
    onClose()
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    toggle(false)()
    onClose()
  }

  const fieldCheck = (index: number, index2: number, values: any) => {
    const target = values.conditionList[index].questionInfoList[index2]
    if (target.questionnaireResponseQuestionAnswerConditionFlag !== undefined) {
      if (values.conditionList !== null && target) {
        //テキスト入力またはチェックボックスがあります。
        if (
          (target.questionnaireResponseAnswerText !== '' ||
            target.questionnaireResponseAnswerCheckBoxObj.length > 0) &&
          target.questionnaireResponseQuestionAnswerConditionFlag !== '1'
        ) {
          Object.defineProperty(
            target,
            'questionnaireResponseQuestionAnswerConditionFlag',
            { value: '0' }
          )
        }
        //標準チェックボックスの状態
        if (target.questionnaireResponseQuestionAnswerConditionFlag === '') {
          return false
        }

        return target.questionnaireResponseQuestionAnswerConditionFlag !== '0'
      }
    }
  }

  let mySubmitForm: ((arg0: any) => void) | null = null

  const bindSubmit = (submitForm: any) => {
    mySubmitForm = submitForm
  }

  const handleSubmitMyForm = (e: any) => {
    if (mySubmitForm) {
      mySubmitForm(e)
    }
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>アンケート回答内容指定</label>
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Formik
            initialValues={entryReplyModalState}
            enableReinitialize
            onSubmit={handleSave}
            render={formikProps => {
              const { values } = formikProps
              bindSubmit(formikProps.submitForm)
              return (
                <>
                  <Form onKeyDown={onKeyDown}>
                    <div className={`ps-container ps ${classes.root}`}>
                      {(() => {
                        const items = (() => {
                          const resultList = []
                          for (
                            let i = 0;
                            i < entryReplyModalItems.length;
                            i++
                          ) {
                            const result = entryReplyModalItems[i].item.map(
                              (item, index) => (
                                <>
                                  {i === 0 && index === 0 && (
                                    <FastField
                                      name='typeCode'
                                      component={Toggle}
                                      optionList={typeList}
                                      row
                                      target='questionCondition'
                                      className={classes.typeRadioRoot}
                                    />
                                  )}
                                  {item.index === 0 && (
                                    <SubTitle variant='h4' noUnderline>
                                      {item.entryBoxInfo.applicationRouteName +
                                        ' ' +
                                        item.entryBoxInfo
                                          .coordinationFormerEntryBoxId +
                                        ' ' +
                                        item.entryBoxInfo.entryBoxName}
                                    </SubTitle>
                                  )}
                                  <FieldGroup
                                    key={item.questionNumber}
                                    className={
                                      i === entryReplyModalItems.length - 1 &&
                                      index ===
                                        entryReplyModalItems[i].item.length - 1
                                        ? classes.lastField
                                        : ''
                                    }>
                                    <SubTitle variant='h5'>
                                      {'Q. ' + item.questionTitle}
                                    </SubTitle>
                                    {/* 回答なし/あり */}
                                    <FormField>
                                      <FastField
                                        name={`conditionList[${item.entryBoxInfo.index}].questionInfoList[${item.index}].questionnaireResponseQuestionAnswerConditionFlag`}
                                        optionList={optionList}
                                        row
                                        component={UncheckableToggle}
                                        target='answerCondition'
                                        targetIndex={item.entryBoxInfo.index}
                                        targetIndex2={item.index}
                                      />
                                    </FormField>
                                    <FormField>
                                      {item.questionTypeCode === '1'? (
                                        <Field
                                          name={`conditionList[${item.entryBoxInfo.index}].questionInfoList[${item.index}].questionnaireResponseAnswerText`}
                                          component={TextField}
                                          placeholder='回答を入力'
                                          disabled={fieldCheck(
                                            item.entryBoxInfo.index,
                                            item.index,
                                            values
                                          )}
                                          validate={async (value: any) => {
                                            let errorFlg = false
                                            let message = ''
                                            await yup.object().shape({
                                              [`conditionList[${item.entryBoxInfo.index}].questionInfoList[${item.index}].questionnaireResponseAnswerText`]: yup.string()
                                            }).validate({
                                              [`conditionList[${item.entryBoxInfo.index}].questionInfoList[${item.index}].questionnaireResponseAnswerText`]: value
                                            }).catch(err => {
                                              errorFlg = true
                                              message = err.message.props.children
                                            })
                                            if (errorFlg) {
                                              return message
                                            } else {
                                              return null
                                            }
                                          }}
                                        />
                                      ) : item.questionTypeCode === '2' ||
                                        item.questionTypeCode === '3' ? (
                                        <Field
                                          name={`conditionList[${item.entryBoxInfo.index}].questionInfoList[${item.index}].questionnaireResponseAnswerCheckBoxObj`}
                                          component={GroupOptions}
                                          optionList={item.answerCheckBoxList}
                                          row
                                          disabled={fieldCheck(
                                            item.entryBoxInfo.index,
                                            item.index,
                                            values
                                          )}
                                        />
                                      ) : (
                                        undefined
                                      )}
                                    </FormField>
                                  </FieldGroup>
                                </>
                              )
                            )
                            resultList.push(result)
                          }
                          return resultList
                        })()

                        return (
                          <Virtuoso
                            translate={''}
                            style={{ height: '100%' }}
                            totalCount={items.length}
                            itemContent={(index: number) => (
                              <WindowedRow items={items[index]} />
                            )}
                          />
                        )
                      })()}
                    </div>
                  </Form>
                </>
              )
            }}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={toggle(true)}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmitMyForm}
            variant='contained'
            color='primary'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={show} onClose={toggle(false)}>
        <DialogTitle>{getMessage('MCAXS561-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EntryReplyModal

interface WindowedRowProps {
  items: JSX.Element[]
}

const WindowedRow = React.memo(({ items }: WindowedRowProps) => {
  return (
    <>
      {items.map(item => (
        <>{item}</>
      ))}
    </>
  )
})
