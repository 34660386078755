import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getMessage } from 'common/messageUtil'
import { FieldGroup, FormField, SubTitle } from 'componentsHsc'
import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import { FormControl } from '..'
import GroupOptions from '../GroupOptions'
import { QuestionnaireCondition } from '../Config/itemConfig'
import TextField from './TextField'
import Toggle from './Toggle'
import UncheckableToggle from '../UncheckableToggle'
import { setSearchCondition } from 'reducers/entryListReducer'
import { SearchCondition, toEntryList, innerSortFunc } from '../Config/searchConditionConfig'
import { onKeyDown } from 'common/generalUtil'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
}))

const optionList = [
  { label: '回答あり', value: '0' },
  { label: '回答なし', value: '1' },
]

const typeList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

interface Props {
  open: boolean
  onClose: () => void
  form: SearchCondition
}

const EntryReplyModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { questionaire, entryReplyModalStateJobChange } = useSelector(
    (state: RootState) => state.itemSpecification
  )

  const handleSave = (data: any) => {
    const newForm = { ...form }
    const newConditionList = (data.conditionList as QuestionnaireCondition[])
      .filter(i => i.answerConditionFlag !== '')
      .map(i => ({
        ...i,
        questionOption: i.questionOption ? i.questionOption : '',
        answerText: i.answerConditionFlag === '1' ? '' : i.answerText,
        answerCheckBoxObj:
          i.answerConditionFlag === '1'
            ? []
            : [...i.answerCheckBoxObj].sort((a, b) => {
                //選択肢の並びを画面順にソート
                const orderObjArray = questionaire.find(j => j.id === i.questionNumber 
                  && j.questionOption === i.questionOption
                  && j.title === i.questionTitle 
                  && j.type === i.questionTypeCode
                )
                if (orderObjArray) {
                  return innerSortFunc(
                    { answerCheckBoxName: a.label },
                    { answerCheckBoxName: b.label },
                    orderObjArray.optionList.map(j => ({ answerCheckBoxName: j.label }))
                  )
                } else {
                  return 0
                }
              }),
      }))
    if (newConditionList.length > 0) {
      const newData = { ...data }
      newData.conditionList = newConditionList
      newForm.questionnaireObj = newData
    } else {
      newForm.questionnaireObj = null
    }
    dispatch(setSearchCondition(toEntryList(newForm)))
    onClose()
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    toggle(false)()
    onClose()
  }

  const fieldCheck = (num: number, values: any) => {
    if (values.conditionList[num].answerConditionFlag !== undefined) {
      if (values.conditionList !== null && values.conditionList[num]) {
        //テキスト入力またはチェックボックスがあります。
        if (
          (values.conditionList[num].answerText !== '' ||
            values.conditionList[num].answerCheckBoxObj.length > 0) &&
          values.conditionList[num].answerConditionFlag !== '1'
        ) {
          Object.defineProperty(
            values.conditionList[num],
            'answerConditionFlag',
            { value: '0' }
          )
        }
        //標準チェックボックスの状態
        if (values.conditionList[num].answerConditionFlag === '') {
          return false
        }
        return values.conditionList[num].answerConditionFlag !== '0'
      }
    }
  }

  let mySubmitForm: ((arg0: any) => void) | null = null;

  const bindSubmit = (submitForm: any) => {
    mySubmitForm = submitForm
  }

  const handleSubmitMyForm = (e: any) => {
    if (mySubmitForm) {
        mySubmitForm(e);
    }
  };

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg'>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>企業から応募者への質問内容回答を指定</label>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={entryReplyModalStateJobChange}
            // validationSchema={}
            enableReinitialize
            onSubmit={handleSave}
            render={formikProps => {
              const { values } = formikProps
              bindSubmit(formikProps.submitForm)
              return (
                <>
                <Form onKeyDown={onKeyDown}>
                  <div className='ps-container ps'>
                    {/* AND/OR */}
                    <FastField
                      name='typeCode'
                      component={Toggle}
                      optionList={typeList}
                      row
                      target='questionCondition'
                    />
                    {questionaire.map((item, index) => (
                      <FieldGroup key={item.title}>
                        <SubTitle variant='h5'>{'Q' + item.id + '. ' + item.title}</SubTitle>
                        <FormField>
                          {/* 回答なし/あり */}
                          <FastField
                            name={`conditionList[${index}].answerConditionFlag`}
                            optionList={optionList}
                            row
                            component={UncheckableToggle}
                            target='answerCondition'
                            targetIndex={index}
                          />
                        </FormField>
                        <FormField>
                          {item.type === '1' ? (
                            <Field
                              name={`conditionList[${index}].answerText`}
                              component={TextField}
                              placeholder='回答を入力'
                              disabled={fieldCheck(index, values)}
                              validate={async (value: any) => {
                                let errorFlg = false
                                let message = ''
                                await yup.object().shape({
                                  [`conditionList[${index}].answerText`]: yup.string()
                                }).validate({
                                  [`conditionList[${index}].answerText`]: value
                                }).catch(err => {
                                  errorFlg = true
                                  message = err.message.props.children
                                })
                                if (errorFlg) {
                                  return message
                                } else {
                                  return null
                                }
                              }}
                            />
                          ) : item.type === '2' || item.type === '3' ? (
                            <Field
                              name={`conditionList[${index}].answerCheckBoxObj`}
                              component={GroupOptions}
                              optionList={item.optionList}
                              row
                              disabled={fieldCheck(index, values)}
                            />
                          ) : (
                            <Field
                              name={`conditionList[${index}].answerCheckBoxObj`}
                              component={GroupOptions}
                              optionList={item.optionList}
                              row
                              disabled={fieldCheck(index, values)}
                            />
                          )}
                        </FormField>
                      </FieldGroup>
                    ))}
                  </div>
                </Form>
                </>
              )
            }}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={toggle(true)}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmitMyForm}
            variant='contained'
            color='primary'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={show} onClose={toggle(false)}>
        <DialogTitle>{getMessage('MCAXS561-001')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EntryReplyModal
