import request from 'utils/request'

import { MCADS010QueryRequest } from 'types/MCADS010/MCADS010QueryRequest'
import { MCADS010OperateBulkRequest } from 'types/MCADS010/MCADS010OperateBulkRequest'

export const selectAccountRequest = (apiData: MCADS010QueryRequest) =>
  request({
    url: '/MCADS010/findAccount',
    method: 'post',
    data: apiData,
  })

export const selectGourpRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCADS010/findGroup/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const updateByInvalidRequest = (apiData: MCADS010OperateBulkRequest[]) =>
  request({
    url: '/MCADS010/updateByInvalid',
    method: 'post',
    data: apiData,
  })

export const updateByValidRequest = (apiData: MCADS010OperateBulkRequest[]) =>
  request({
    url: '/MCADS010/updateByValid',
    method: 'post',
    data: apiData,
  })

export const updateByPasswordRequest = (apiData: MCADS010OperateBulkRequest[]) =>
  request({
    url: '/MCADS010/reissuePassword',
    method: 'post',
    data: apiData,
  })

export const updateInvDataByPwdRequest = (apiData: MCADS010OperateBulkRequest[]) =>
  request({
    url: '/MCADS010/reissueInvDataPwd',
    method: 'post',
    data: apiData,
  })

export const selectLoginCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCADS010/selectLoginCount/${recruitmentManagementDivision}`,
    method: 'post',
  })