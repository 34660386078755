const initialValues = {
    fullNameCheck: '0',
    homePhoneCheck: '0',
    mobilePhoneCheck: '0',
    pcAddressCheck: '0',
    mobileAddressCheck: '0',
    schoolCheck: '0',
    schoolFacultyCheck: '0',
    schoolFacultySubjectCheck: '0',
    recruitmentManagementDivision: ''
};
export { initialValues };