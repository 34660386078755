import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    height: 'inherit !important',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  body?: any;
}

export default ({ open, onClose, title, body }: Props) => {
  const classes = useStyles();

  return (
    <div className={`modal ${open ? 'show' : ''}`}>
      <aside className={`status-history show ${classes.content}`}>
        <header>
          <h1>{title}</h1>
        </header>
        {body ? (
          <div className="ps-container ps">
            <section>{body}</section>
          </div>
        ) : null}
        <footer>
          <button type="button" className="btn" onClick={onClose}>
            いいえ
          </button>
          <button type="button" className="btn" onClick={onClose}>
            はい
          </button>
        </footer>
      </aside>
    </div>
  );
};
