import request from 'utils/request'
import MCBCS010OutputCsvRequest from 'types/MCBCS010/MCBCS010OutputCsvRequest'
import MCBCS010CooperationRequest from 'types/MCBCS010/MCBCS010CooperationRequest'
import MCBCS010DuplicateDataRequest from 'types/MCBCS010/MCBCS010DuplicateDataRequest'

export const initRequest = (requestData: MCBCS010DuplicateDataRequest) =>
request({
  url: '/MCBCS010/init',
  method: 'post',
  data: requestData,
})

export const outputCsvDownloadRequest = (requestData: MCBCS010OutputCsvRequest) =>
request({
  url: '/MCBCS010/download',
  method: 'post',
  data: requestData,
})

export const cooperationRequest = (requestData:MCBCS010CooperationRequest) => request({
  url: '/MCBCS010/cooperation',
  method: 'post',
  data: requestData,
})
