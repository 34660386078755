import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { dateTimeToString } from 'common/generalUtil'
import { magiContants } from 'utils/contants'

import * as apiConfig from 'pages/MCBHS030/apiConfig'

import { MCBHS030InitRequest } from 'types/MCBHS030/MCBHS030InitRequest'
import { MCBHS030CreateTemplateRequest } from 'types/MCBHS030/MCBHS030CreateTemplateRequest'
import { MCBHS030OverwriteTemplateRequest } from 'types/MCBHS030/MCBHS030OverwriteTemplateRequest'

const messageInitRequest: MCBHS030InitRequest = {
  selectedRequestTemplateSettingId: "",
  sendTimeFlag : 0,
  sendCount : "",
  emailFailureCount : 0,
  requestTemplateSettingId : "",
  requestTemplateName : "",
  countTemp : 0 ,
  maxtempcount : 0
}

interface templateNameOption {
  value: string
  label: string
  sysVersionNumber:string
}

const initialState: {
  submissionRequestInitRequest: MCBHS030InitRequest
   initResult: apiConfig.InitResultt
  newTempName: string
  templateNameOption: templateNameOption
} = {
  submissionRequestInitRequest: messageInitRequest,
   initResult: apiConfig.initResultt,
  newTempName: "",
  templateNameOption: {
    value: '',
    label: '',
    sysVersionNumber:''
  }
}

const submissionRequestSendCompSlice = createSlice({
  name: 'submissionRequestSendComp',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCBHS030InitRequest>) {
      console.log('Reducer getInit')
       const initRequest = action.payload
      return state
    },
    setInit(state, action: PayloadAction<MCBHS030InitRequest>) {
      state.submissionRequestInitRequest = action.payload
      return state
    },
    createTemplate(
      state,
      action: PayloadAction<{
        formData: MCBHS030CreateTemplateRequest
      }>
    ) {
      return state
    },
    orverWrite(
      state,
      action: PayloadAction<{
        formData: MCBHS030OverwriteTemplateRequest
      }>
    ) {
      return state
    },
    setTemplateNameSelect(state,action:PayloadAction<templateNameOption>){
      state.templateNameOption = action.payload
      return state 
    }
  },
})

export const {
  getInit,
  setInit,
  createTemplate,
  orverWrite,
  setTemplateNameSelect,
} = submissionRequestSendCompSlice.actions
export default submissionRequestSendCompSlice.reducer
