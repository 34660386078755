import request from 'utils/request'

export const getInitListRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAES010/init/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const createCountCheck = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAES010/new/${recruitmentManagementDivision}`,
    method: 'post',
  })

export const copyCountCheck = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAES010/copy/${recruitmentManagementDivision}`,
    method: 'post',
  })
