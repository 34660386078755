import request from 'utils/request'
import { MCAKS020UpdateRequest } from 'types/MCAKS020/MCAKS020UpdateRequest'

export const updateInitRequest = () =>
  request({
    url: '/MCAKS020/init',
    method: 'post',
  })

export const updateRequest = (apiData: MCAKS020UpdateRequest) =>
  request({
    url: '/MCAKS020/change',
    method: 'post',
    data: apiData,
  })
