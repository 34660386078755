import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  getSelectionFlowPreferenceList,
  setSelectionFlowPreference,
  createAffiliationRecruitment,
  updateAffiliationRecruitment, 
  deleteAffiliationRecruitment,
} from 'reducers/affiliationRecruitmentReducer'
import { getInitRequest, insertRequest, updateRequest, deleteRequest } from 'apis/MCAES020Api'
import { openSnackbar } from 'reducers/messageReducer'


function* getInitSaga(action: ReturnType<typeof getSelectionFlowPreferenceList>) {
  try {
    const data = yield call(getInitRequest, action.payload)
    yield put(setSelectionFlowPreference(data))
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

function* createAffiliationRecruitmentSaga(action: ReturnType<typeof createAffiliationRecruitment>) {
  try {
    yield call(insertRequest, action.payload)
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

function* updateAffiliationRecruitmentSaga(action: ReturnType<typeof updateAffiliationRecruitment>) {
  try {
    yield call(updateRequest, action.payload)
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

function* deleteAffiliationRecruitmentSaga(action: ReturnType<typeof deleteAffiliationRecruitment>) {
  try {
    yield call(deleteRequest, action.payload)
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}

export default function* affiliationInitSaga() {
  yield all([
    takeEvery(getSelectionFlowPreferenceList, getInitSaga),
    takeEvery(createAffiliationRecruitment, createAffiliationRecruitmentSaga),
    takeEvery(updateAffiliationRecruitment, updateAffiliationRecruitmentSaga),
    takeEvery(deleteAffiliationRecruitment, deleteAffiliationRecruitmentSaga),
  ])
}
