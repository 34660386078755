import {
  call,
  put,
  all,
  takeEvery,
  select,
  takeLeading,
} from 'redux-saga/effects'

import {
  processChangeInit,
  processChangeMessageInit,
  setProcessChangeInit,
  setPageDialogOpen,
  updateDecide,
  setUpdateDecide,
  sendMessageChk,
  setSendMessageChk,
  sendMessageChkTemp,
  setSendMessageChkTemp,
  processChangeUpd,
  setProcessChangeUpd,
  priorityUpd,
  setpriorityUpd,
  setUpdateFlag,
  //S030
  processChangeListInit,
  processChangeMessageListInit,
  setProcessChangeListInit,
  setPageDialogListOpen,
  updateDecideList,
  setUpdateDecideList,
  sendMessage,
  setSendMessage,
  sendMessageTemp,
  setSendMessageTemp,
  evaluationUpd,
  setEvaluationUpd,
  progressChangeUpdate,
  progressChangeUpdateMessage,
  setProgressChangeUpdate,
  leftEmpty,
  setLeftEmpty,
  toLeft,
  setToLeft,
  leftReset,
  setLeftReset,
  leftResetStatus,
  setLeftResetStatus,
  leftTemp,
  setLeftTemp,
  findReMaDivision,
  setFindReMaDivision,
  decisionDivisionBlack,
  setDecisionDivisionBlack,
  setErrorMessageFlag,
  setDecisionDivisionListAndFlag,
  setOpenSelectionStepAlertMessageFlag,
  setOpenDecideAlertMessageFlag,
  setDecideUpdateCommitFlag,
  setProcessChangeCommitFlag,
  setOpenSelectionStepAlertMessageFlag030,
  setProcessChangeCommitFlag030,
  setOpenDecideAlertMessageFlag030,
  setDecideUpdateCommitFlag030,
  notPassNotificationCheck020,
  notAdoptedNotificationPreferenceCheck020,
  setOpenDecideFlag,
  setOpenFlag,
  setOpenTwoFlag,
  notPassNotificationCheck030,
  notAdoptedNotificationPreferenceCheck030,
  setOpenDecideFlag030,
  setOpenFlag030,
  setOpenTwoFlag030,
  setOpenSpecialSelStepAlertMessageFlag030
} from 'reducers/selectionStatusUpdateReducer'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import {
  processChangeQueryRequest,
  updateDecideRequest,
  sendMessageChkRequest,
  processChangeUpdRequest,
  priorityUpdRequest,
  notPassNotificationCheckRequest020,
  notAdoptedNotificationPreferenceCheckRequest020,
} from 'apis/MCAYS020Api'
import {
  processChangeQueryListRequest,
  updateDecideListRequest,
  unifiedsendMessageRequest,
  evaluationUpdRequest,
  progerssChangeUpdateRequest,
  evaluationDateUpdRequest,
  progerssChangeDateUpdateRequest,
  updateDecideListDateRequest,
  notPassNotificationCheckRequest030,
  notAdoptedNotificationPreferenceCheckRequest030,
} from 'apis/MCAYS030Api'
import { getGlobalMenuData, getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { routeList } from 'routes/routes'
import { magiContants } from 'utils/contants'

function* searchSaga(action: ReturnType<typeof processChangeInit>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(processChangeQueryRequest, action.payload);
    yield put(setProcessChangeInit(data));
    yield put(setPageDialogOpen(true));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* searchMessageSaga(action: ReturnType<typeof processChangeMessageInit>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(processChangeQueryRequest, action.payload);
    yield put(setProcessChangeInit(data));
  } catch (error) {
    if (magiContants.MESSAGECODE_RESULT_NULL === error.message) {
      yield put(openModal(magiContants.MESSAGECODE_MAACOMMON_010));
    } else {
      yield put(openModal(error.message));
    }
  }
}

function* updateDecideSaga(action: ReturnType<typeof updateDecide>) {
  try {
    const data = yield call(updateDecideRequest, action.payload);
    let obj = JSON.parse(JSON.stringify(data))
    yield put(setUpdateDecide(data));
    // 転職March #75621 START
    const {
      recruitmentManagementDivision,
      marchResponsibleCompanyFlag,
      marchServiceFlag,
      serviceMode,
    } = yield select(getGlobalMenuData)
    if (
      recruitmentManagementDivision == '2' &&
      (marchResponsibleCompanyFlag === '1' ||
        (marchServiceFlag === '1' && serviceMode === '1'))
    ) {
      // 「合否・選考ステップを変更」初期化
      yield put(
        processChangeInit({
          selectionManagementId: action.payload.selectionManagementId,
          recruitmentManagementDivision: recruitmentManagementDivision,
        })
      )
    }
    // 転職March #75621 END
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(obj.message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* sendMessageChkSaga(action: ReturnType<typeof sendMessageChk>) {
  try {
    if (undefined != action.payload.info && null != action.payload.info) {
      const data = yield call(sendMessageChkRequest, action.payload.info);
      yield put(setSendMessageChk(data));
      let lengthFlag = false
      for(let i = 0;i<data.length;i++){
        if('' != data[i]){
          lengthFlag = true
          break
        }
      }
      if(!lengthFlag){
        action.payload.onSendMessage()
      }
    }
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* sendMessageChkTempSaga(action: ReturnType<typeof sendMessageChkTemp>) {
  try {
    yield put(setSendMessageChkTemp([]));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* processChangeUpdSaga(action: ReturnType<typeof processChangeUpd>) {
  try {
    const data = yield call(processChangeUpdRequest, action.payload);
    let obj = JSON.parse(JSON.stringify(data))
    yield put(setProcessChangeUpd(data));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(obj.message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* priorityUpdSaga(action: ReturnType<typeof priorityUpd>) {
  try {
    const data = yield call(priorityUpdRequest, action.payload);
    let obj = JSON.parse(JSON.stringify(data))
    yield put(setpriorityUpd(data));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(obj.updMessage));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

//S030
function* searchListSaga(action: ReturnType<typeof processChangeListInit>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(processChangeQueryListRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setProcessChangeListInit(data));
    yield put(setDecisionDivisionListAndFlag({decisionDivisionList: data.decisionDivisionList, decisionDivisionEqualsFlag:data.decisionDivisionEqualsFlag}));
    // 転職March #75621 START
    yield data.message ? put(openSnackbar(data.message)) : null;
    // 転職March #75621 END
    yield put(setPageDialogListOpen(true));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* searchMessageListSaga(action: ReturnType<typeof processChangeMessageListInit>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision
    const data = yield call(processChangeQueryListRequest, encodeURIComponent(JSON.stringify(action.payload)));
    if (magiContants.MESSAGECODE_MCAYS030_002 === data.message) {
      yield put(openModal(magiContants.MESSAGECODE_MAACOMMON_010));
    } else {
      yield put(setProcessChangeListInit(data));
      yield put(setDecisionDivisionListAndFlag({decisionDivisionList: data.decisionDivisionList, decisionDivisionEqualsFlag:data.decisionDivisionEqualsFlag}));
      // 転職March #75621 START
      yield data.message ? put(openSnackbar(data.message)) : null;
      // 転職March #75621 END
    }
  } catch (error) {
    if (magiContants.MESSAGECODE_RESULT_NULL === error.message) {
      yield put(openModal(magiContants.MESSAGECODE_MAACOMMON_010));
    } else {
      yield put(openModal(error.message));
    }
  }
}
function* updateDecideListSaga(action: ReturnType<typeof updateDecideList>) {
  try {
    const data = yield call(updateDecideListDateRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setUpdateDecideList(data));
    yield put(setDecisionDivisionListAndFlag({decisionDivisionList: data.decisionDivisionList, decisionDivisionEqualsFlag:data.decisionDivisionEqualsFlag}));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(data.message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* progressChangeUpdateSaga(action: ReturnType<typeof progressChangeUpdate>) {
  try {
    const data = yield call(progerssChangeDateUpdateRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setProgressChangeUpdate(data));
    yield put(setDecisionDivisionListAndFlag({decisionDivisionList: data.decisionDivisionList, decisionDivisionEqualsFlag:data.decisionDivisionEqualsFlag}));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(data.message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* progressChangeUpdateMessageSaga(action: ReturnType<typeof progressChangeUpdate>) {
  try {
    const data = yield call(progerssChangeDateUpdateRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setProgressChangeUpdate(data));
    yield put(setDecisionDivisionListAndFlag({decisionDivisionList: data.decisionDivisionList, decisionDivisionEqualsFlag:data.decisionDivisionEqualsFlag}));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(data.message));
  } catch (error) {
    if (magiContants.MESSAGECODE_UPDATE_FAILED === error.message || magiContants.MESSAGECODE_MCAYS030_002 === error.message) {
      yield put(openModal(magiContants.MESSAGECODE_MAACOMMON_010));
    } else {
      yield put(openModal(error.message));
    }
  }
}
function* sendMessageSaga(action: ReturnType<typeof sendMessage>) {
  try {
    if (undefined != action.payload.info && null != action.payload.info) {
      const data = yield call(unifiedsendMessageRequest, action.payload.info);
      yield put(setSendMessage(data));
      let lengthFlag = false
      for(let i = 0;i<data.length;i++){
        if('' != data[i]){
          lengthFlag = true
          break
        }
      }
      if(lengthFlag){
        yield put(setErrorMessageFlag(true));
      }else{
        action.payload.onSendMessage()
      }
    }
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* sendMessageTempSaga(action: ReturnType<typeof sendMessageTemp>) {
  try {
    yield put(setSendMessageTemp([]));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* evaluationUpdSaga(action: ReturnType<typeof evaluationUpd>) {
  try {
    const data = yield call(evaluationDateUpdRequest, encodeURIComponent(JSON.stringify(action.payload)));
    yield put(setEvaluationUpd(data));
    yield put(setUpdateFlag(true));
    yield put(openSnackbar(data.updMessage));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* leftEmptySaga(action: ReturnType<typeof leftEmpty>) {
  try {
    yield put(setLeftEmpty(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* toLeftSaga(action: ReturnType<typeof toLeft>) {
  try {
    yield put(setToLeft(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* leftResetSaga(action: ReturnType<typeof leftReset>) {
  try {
    yield put(setLeftReset(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* leftResetStatusSaga(action: ReturnType<typeof leftResetStatus>) {
  try {
    yield put(setLeftResetStatus(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* leftTempSaga(action: ReturnType<typeof leftTemp>) {
  try {
    yield put(setLeftTemp(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* findReMaDivisionSaga(action: ReturnType<typeof findReMaDivision>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield put(setFindReMaDivision(recruitmentManagementDivision));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}

function* decisionDivisionBlackSaga(action: ReturnType<typeof decisionDivisionBlack>) {
  try {
    yield put(setDecisionDivisionBlack(action.payload));
  } catch (error) {
    yield put(openSnackbar(error.message));
  }
}
// 次期9月対応 #58576 start
// MCAYS020
function* notPassNotificationCheckSaga020(action: ReturnType<typeof notPassNotificationCheck020>) {
  try {
    const data = yield call(notPassNotificationCheckRequest020, action.payload);
    if (data) {
      yield put(setOpenDecideFlag(false))
      yield put(setOpenDecideAlertMessageFlag(true))
    } else {
      yield put(setDecideUpdateCommitFlag(true))
    }
  } catch (error) {
    yield put(openModal(error.message));
  }
}
function* notAdoptedNotificationPreferenceCheckSaga020(action: ReturnType<typeof notAdoptedNotificationPreferenceCheck020>) {
  try {
    const data = yield call(notAdoptedNotificationPreferenceCheckRequest020, action.payload);
    if (data) {
      yield put(setOpenFlag(false))
      yield put(setOpenTwoFlag(false))
      yield put(setOpenSelectionStepAlertMessageFlag(true))
    } else {
      yield put(setProcessChangeCommitFlag(true))
    }
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// MCAYS030
  function* notPassNotificationCheckSaga030(action: ReturnType<typeof notPassNotificationCheck030>) {
    try {
      const data = yield call(notPassNotificationCheckRequest030, action.payload);
      if (data) {
        yield put(setOpenDecideFlag030(false))
        yield put(setOpenDecideAlertMessageFlag030(true))
      } else {
        yield put(setDecideUpdateCommitFlag030(true))
      }
    } catch (error) {
      yield put(openModal(error.message));
    }
  }
  function* notAdoptedNotificationPreferenceCheckSaga030(action: ReturnType<typeof notAdoptedNotificationPreferenceCheck030>) {
    try {
      const data = yield call(notAdoptedNotificationPreferenceCheckRequest030, action.payload);
      // 不採用通知設定チェックメッセージ出力
      if (data == '1') {
        yield put(setOpenFlag030(false))
        yield put(setOpenTwoFlag030(false))
        yield put(setOpenSelectionStepAlertMessageFlag030(true))
        // 不採用通知設定特殊選考チェックメッセージ出力
      } else if (data == '2') {
        yield put(setOpenFlag030(false))
        yield put(setOpenTwoFlag030(false))
        yield put(setOpenSpecialSelStepAlertMessageFlag030(true))
        // メッセージ出力しない
      } else {
        yield put(setProcessChangeCommitFlag030(true))
      }
    } catch (error) {
      yield put(openModal(error.message));
    }
  }
// 次期9月対応 #58576 end
export default function* processChangeSaga() {
  yield all([
    takeEvery(processChangeInit, searchSaga),
    takeEvery(processChangeMessageInit, searchMessageSaga),
    takeLeading(updateDecide, updateDecideSaga),
    takeEvery(sendMessageChk, sendMessageChkSaga),
    takeEvery(sendMessageChkTemp, sendMessageChkTempSaga),
    takeLeading(processChangeUpd, processChangeUpdSaga),
    takeLeading(priorityUpd, priorityUpdSaga),
    // 次期9月対応 #58576 start
    // MCAYS020
    takeEvery(notPassNotificationCheck020, notPassNotificationCheckSaga020),
    takeEvery(notAdoptedNotificationPreferenceCheck020, notAdoptedNotificationPreferenceCheckSaga020),
    // MCAYS030
    takeEvery(notPassNotificationCheck030, notPassNotificationCheckSaga030),
    takeEvery(notAdoptedNotificationPreferenceCheck030, notAdoptedNotificationPreferenceCheckSaga030),
    // 次期9月対応 #58576 end
    //S030
    takeEvery(processChangeListInit, searchListSaga),
    takeEvery(processChangeMessageListInit, searchMessageListSaga),
    takeLeading(updateDecideList, updateDecideListSaga),
    takeLeading(progressChangeUpdate, progressChangeUpdateSaga),
    takeLeading(progressChangeUpdateMessage, progressChangeUpdateMessageSaga),
    takeEvery(sendMessage, sendMessageSaga),
    takeEvery(sendMessageTemp, sendMessageTempSaga),
    takeLeading(evaluationUpd, evaluationUpdSaga),
    takeEvery(leftEmpty, leftEmptySaga),
    takeEvery(leftReset, leftResetSaga),
    takeEvery(leftResetStatus, leftResetStatusSaga),
    takeEvery(leftTemp, leftTempSaga),
    takeEvery(findReMaDivision, findReMaDivisionSaga),
    takeEvery(decisionDivisionBlack, decisionDivisionBlackSaga),
    takeEvery(toLeft, toLeftSaga),
  ])
}
