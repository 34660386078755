import request from 'utils/request'
import { MCAXS340InitRequest } from 'pages/MCAXS340/formConfig'
import { MCAXS340UpdateFlagRequest } from 'types/MCAXS340/MCAXS340UpdateFlagRequest'

export const initRMFInfoApi = (params: MCAXS340InitRequest) =>
  request({
    url: '/MCAXS340/init',
    method: 'post',
    data: params,
  })

export const insertByRMFApi = (params: MCAXS340UpdateFlagRequest) =>
  request({
    url: '/MCAXS340/ok',
    method: 'post',
    data: params,
  })

// [phase2] start by zhangxp
export const insertByRMFApiRequest = (payload: string) => {
  const data = new FormData()
  data.append('requestDataPayload', payload)
  return request({
    url: '/MCAXS340/ok',
    method: 'post',
    data: data,
  })
}
// [phase2] end by zhangxp