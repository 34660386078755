import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { getMessage } from 'common/messageUtil'
import { FieldGroup, FormField, SubTitle, Title } from 'componentsHsc'
import { TextFieldForMessage } from 'components'
import { FastField, Field } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { useModal } from 'utils'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from './GroupOptions'
import TextFIeldForMessageTabAllowed from './TextFIeldForMessageTabAllowed'
import RegionModal from './Modal/RegionModal'
import RegionModalWorkPlace from './Modal/RegionModalWorkPlace'
import { Select } from './Select'
import { MultiSelect } from './MultiSelect'
import { Option } from './Config/searchConditionConfig'
import { GroupSelect } from './GroupSelect'
import { Area } from './Modal/RegionModal'
import { magiContants } from 'utils/contants'

export const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '120px',
    marginBottom: theme.spacing(1),
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  threeColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  fourColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  datePickerDiv:{
    width: '130px', 
    float: 'left'
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: theme.spacing(1.625, 0.625, 0, 1)
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
}))

interface Props {
  form: FormControl
}

// 基本情報タブ
const Basic = ({ form }: Props) => {
  const classes = useStyles()
  // phase2 Start
  // Modal
  const useModalResidence = (initialValue = false) => {
    const [showResidence, setShowResidence] = useState(initialValue);
    const toggleResidence = (newValue: boolean) => () => setShowResidence(newValue);
    return [showResidence, toggleResidence] as const;
  };

  const useModalWorkPlace = (initialValue = false) => {
    const [showWorkPlace, setShowWorkPlace] = useState(initialValue);
    const toggleWorkPlace = (newValue: boolean) => () => setShowWorkPlace(newValue);
    return [showWorkPlace, toggleWorkPlace] as const;
  };
  const [showResidence, toggleResidence] = useModalResidence()
  const [showWorkPlace, toggleWorkPlace] = useModalWorkPlace()

  const handleClickResidence = () => {
    setSelectedArea('hokkaidotohoku')
    toggleResidence(true)()
  }

  const handleClickWorkPlace = () => {
    setSelectedArea('hokkaidotohoku')
    toggleWorkPlace(true)()
  }
  // phase2 End
  const residenceObj = form.values.residenceObj ? form.values.residenceObj : []

  const preferredWorkplaceObj = form.values.preferredWorkplaceObj
    ? form.values.preferredWorkplaceObj
    : []

  const handleDelete = (option: Option, type: string) => () => {
    if (type === 'residenceObj') {
      form.setFieldValue(
        'residenceObj',
        residenceObj ? residenceObj.filter(i => i !== option) : null
      )
    } else if (type === 'preferredWorkplaceObj') {
      form.setFieldValue(
        'preferredWorkplaceObj',
        preferredWorkplaceObj
          ? preferredWorkplaceObj.filter(i => i !== option)
          : null
      )
    }
  }

  // 初期表示データ設定
  const {
    sexList,
    spouseList,
    finalEducationList,
    educationBackgroundList,
    languageSkillsList,
    eligibilityList,
    experienceCountList,
    employmentSituationList,
    jobCareerList,
    experiencedJobList,
  } = useSelector((state: RootState) => state.advancedSearchJobChange.basicInfo)

  const educationalBackground = educationBackgroundList.map(i => {
    return { label: i, value: i }
  })

  const languageSkillsSuggest = languageSkillsList.map(i => {
    return { label: i, value: i }
  })

  const eligibilitySuggest = eligibilityList.map(i => {
    return { label: i, value: i }
  })

  const jobCareer = jobCareerList.map(i => {
    return { label: i, value: i }
  })

  const experiencedJob = experiencedJobList.map(i => {
    return { label: i, value: i }
  })

  
  //都道府県モーダル用state
  const [selectedArea, setSelectedArea] = useState<Area>('hokkaidotohoku')

  // 転職March #90253 START
  const {
    serviceMode,
    marchServiceFlag,
  } = useSelector((state: RootState) => state.globalMenu)
  // 転職March #90253 END

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>プロフィール</SubTitle>
        <FormField helperText={getMessage('MCAXS421_001')}>
          <Title title='利用者番号' className={classes.title} />
          <FastField
            name='applicantId'
            multiline
            component={TextFieldForMessage}
            placeholder='利用者番号を⼊⼒ ※複数登録時は改⾏区切り'
            multiLineTextArea
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS421_002')}>
          <Title title='氏名／氏名カナ' className={classes.title} />
          <FastField
            name='name'
            multiline
            component={TextFIeldForMessageTabAllowed}
            placeholder='⽒名／⽒名カナを⼊⼒ ※複数登録時は改⾏区切り'
            multiLineTextArea
          />
        </FormField>
        <FormField>
          <Title title='年齢' className={classes.title} />
          <div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='ageFrom'
                component={TextFieldForMessage}
                placeholder='半角数字' /></div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='ageTo'
                component={TextFieldForMessage}
                placeholder='半角数字' /></div>
          </div>
        </FormField>
        <FormField>
          <Title title='性別' className={classes.title} />
          <Field
            name='sexObj'
            row
            component={GroupOptions}
            optionList={sexList}
          />
        </FormField>
        <FormField>
          <Title title='メールアドレス' className={classes.title} />
          <FastField
            name='mailAddress'
            component={TextFieldForMessage}
            placeholder='メールアドレスを入力'
          />
        </FormField>
        <FormField>
          <Title title='配偶者' className={classes.title} />
          <Field
            name='spouseObj'
            row
            component={GroupOptions}
            optionList={spouseList}
          />
        </FormField>
        <FormField>
          <Title title='居住地（都道府県）' className={classes.title} />
          <div className={classes.modal}>
            {/* phase2 Start */}
            <Button onClick={handleClickResidence} />
            {/* phase2 End */}
            {residenceObj.length > 0 && (
              <span>
                {residenceObj.map(i => (
                  <Chip
                    key={i.value}
                    className={classes.chip}
                    label={i.label}
                    onDelete={handleDelete(i, 'residenceObj')}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>学歴</SubTitle>
        <FormField>
          <Title title='最終学歴' className={classes.title} />
          <Field
            name='finalEducationUniversityObj'
            row
            component={GroupOptions}
            optionList={finalEducationList}
          />
        </FormField>
        {/* 転職March #90253 START */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='学歴 (キーワード)' className={classes.title} />
          <div>
            <Field
                name='educationalBackground'
                isMulti
                component={GroupSelect}
                className={classes.cursor}
                optionList={educationalBackground}
                placeholder='キーワードを入力する'
              />
          </div>  
        </FormField>
        }
      </FieldGroup>
      {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
      ? ''
      :
      <FieldGroup>
        <SubTitle variant='h3'>資格・スキル</SubTitle>
        <FormField>
          <Title title='語学スキル' className={classes.title} />
          <Field
            name='languageSkills'
            component={Select}
            className={classes.cursor}
            defaultValue={form.values.languageSkills}
            optionList={languageSkillsSuggest}
            isMulti
            placeholder='語学スキルを入力'
          />
        </FormField>
        <FormField>
          <Title title='保有資格' className={classes.title} />
          <Field
            name='eligibility'
            isMulti
            component={Select}
            className={classes.cursor}
            defaultValue={form.values.eligibility}
            optionList={eligibilitySuggest}
            placeholder='保有資格を入力'
          />
        </FormField>
      </FieldGroup>
      }
      {/* 転職March #90253 END */}
      <FieldGroup>
        <SubTitle variant='h3'>職務経歴</SubTitle>
        <FormField>
          <Title title='経験社数' className={classes.title} />
          <Field
            name='experienceCountObj'
            row
            component={GroupOptions}
            optionList={experienceCountList}
          />
        </FormField>
        <FormField>
          <Title title='現在の就業状況' className={classes.title} />
          <Field
            name='employmentSituationObj'
            row
            component={GroupOptions}
            optionList={employmentSituationList}
          />
        </FormField>
        <FormField>
          <Title title='希望勤務地（都道府県）' className={classes.title} />
          {/* phase2 Start */}
          <div className={classes.modal}>
            <button
              type='button'
              className={`btn high ${classes.button}`}
              onClick={handleClickWorkPlace}>
              指定する
            </button>
            {/* phase2 End */}
            {preferredWorkplaceObj.length > 0 && (
              <span>
                {preferredWorkplaceObj.map(i => (
                  <Chip
                    key={i.value}
                    className={classes.chip}
                    label={i.label}
                    onDelete={handleDelete(i, 'preferredWorkplaceObj')}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
        {/* 転職March #90253 START */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='職務経歴' className={classes.title} />
          <Field
            name='jobCareer'
            isMulti
            component={Select}
            className={classes.cursor}
            defaultValue={form.values.jobCareer}
            optionList={jobCareer}
            placeholder='職務履歴を入力する'
          />
        </FormField>
        }
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='経験職種' className={classes.title} />
          <Field
            name='experiencedJob'
            isMulti
            component={Select}
            className={classes.cursor}
            defaultValue={form.values.experiencedJob}
            optionList={experiencedJob}
            placeholder='経験職種を入力する'
          />
        </FormField>
        }
        {/* 転職March #90253 END */}
      </FieldGroup>
      <FieldGroup>
        <SubTitle variant='h3'>フリーワード</SubTitle>
        <FormField>
          <Title title='履歴書全体 (キーワード)' className={classes.title} />
          <div>
            <FastField
              name='wholeResume'
              component={MultiSelect}
              placeholder='キーワードを入力する'
            />
            <p>※Enterキー押下で入力内容をセットします。</p>
          </div>
        </FormField>
      </FieldGroup>
      {/* phase2 Start */}
      <RegionModal
        open={showResidence}
        onClose={toggleResidence(false)}
        form={form}
        name={'residenceObj'}
        selectedAreaState={[selectedArea, setSelectedArea]}
      />
      <RegionModalWorkPlace
        open={showWorkPlace}
        onClose={toggleWorkPlace(false)}
        form={form}
        name={'preferredWorkplaceObj'}
        selectedAreaState={[selectedArea, setSelectedArea]}
      />
      {/* phase2 End */}
    </>
  )
}

export default Basic
