import { magiContants } from "utils/contants"
import { getMessage } from "common/messageUtil"

const maxcount: number = magiContants.CHECK_MCAZS130_MAXCOUNT
const msg: string = magiContants.CHECK_MCAZS130_MAXCOUNT_MSG
const textMap = {
  register: {
    renew: '新規に登録する',
    message: getMessage('MCAZS130-001'),
  },

}
interface InterviewList {
  interviewLocationListCount: number
}
  const InterviewLocationListInitialValues: InterviewList = {
    interviewLocationListCount: 0,
  }
export {InterviewLocationListInitialValues,textMap, maxcount, msg} 