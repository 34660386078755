import { openModal, openModalWindowClose } from 'reducers/messageReducer'
import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import {
  init,
  setInit,
  setInitMarchParam,
  setCode,
  setDoInit,
  setScreenDisplayItems,
  doSelectionFlowChange,
  doSelectionFlowRouteChange,
  doSelectionFlowExclude,
  doSelectionFlowUnExclude,
  mcaxs620Init,
  mcaxs620DoSelectionFlowChange,
  mcaxs620DoSelectionFlowRouteChange,
  mcaxs620DoSelectionFlowExclude,
  mcaxs620DoSelectionFlowUnExclude,
  setCurrentApplicantsListInfo,
  setApplicantDetailsRequest,
  setShowMCAXS050,
  setShowMCAXS620,
  sendMessageChk,
  setSendMessageChk,
  setSendMessageErrorFlag,
  notAdoptedNotification,
} from 'reducers/entryDetailReducer'
import {
  initRequest,
  selectionFlowChangeRequest,
  selectionFlowRouteChangeRequest,
  selectionFlowExcludeRequest,
  selectionFlowUnExcludeRequest,
  sendMessageChkRequest,
  notAdoptedNotificationRequest,
  serviceModeUpdateRequest,
} from '../apis/MCAXS050Api'
import {
  mcaxs620InitRequest,
  mcaxs620SelectionFlowChangeRequest,
  mcaxs620SelectionFlowRouteChangeRequest,
  mcaxs620SelectionFlowExcludeRequest,
  mcaxs620SelectionFlowUnExcludeRequest,
} from '../apis/MCAXS620Api'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { routeList } from 'routes/routes'
import { fetchSelectionFlowChange } from 'reducers/selectionFlowReducer'
import { RootState } from 'reducers'
import { loginSuccess } from 'reducers/companyLoginReducer'
import { setServiceMode, setServiceModeForDisplay } from 'reducers/globalMenuReducer'
import { MCAXS050ServiceModeUpdateRequest } from 'pages/MCAXS050/apiConfig'

const SUCCESS_CODE = 200

export function* initSaga(action: ReturnType<typeof init>) {
  const applicantDetailsRequest = action.payload.request
  const currentApplicantsListInfo = action.payload.currentApplicantsListInfo
  try {

    let serviceMode: string = yield select((state: RootState) => state.globalMenu.serviceMode)
    const marchResponsibleCompanyFlag: string = yield select((state: RootState) => state.globalMenu.marchResponsibleCompanyFlag)
    if(action.payload.searchLinkFlg || 'MCACS010' == applicantDetailsRequest.sourceFunctionId ){
      const serviceModeUpdateParam: MCAXS050ServiceModeUpdateRequest = {
        selectionManagementId:
          applicantDetailsRequest.applicantsListInfo[0].selectionManagementId,
        searchLinkFlg: action.payload.searchLinkFlg,
        sourceFunctionId: action.payload.request.sourceFunctionId,
      }
      const serviceModeUpdateResponse: any =  yield call(serviceModeUpdateRequest, serviceModeUpdateParam)
      // Marchモード切替必要な場合
      if(serviceModeUpdateResponse.token){
        const token = serviceModeUpdateResponse.tokenHead + ' ' + serviceModeUpdateResponse.token
        // token再設定
        yield put(loginSuccess({ token: token }))
        // サービスモード再設定
        serviceMode = serviceModeUpdateResponse.marchModeFlag
        yield put(setServiceMode(serviceMode))
        yield put(setServiceModeForDisplay(serviceMode))
      }
    }
    yield put(setInitMarchParam({serviceMode: serviceMode, marchResponsibleCompanyFlag: marchResponsibleCompanyFlag}))

    //本来のリクエスト処理
    const serverResponse = yield call(initRequest, applicantDetailsRequest)

    console.log('initSaga処理開始')

    // const serverResponse = JSON.parse(Stub)

    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())
    yield put(setShowMCAXS050(true))

    console.log('initSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    if (error.message === magiContants.MESSAGECODE_MCAXS050_005) {
      let newCurrentApplicantsListInfo = {
        applicantsListInfo: [ ...currentApplicantsListInfo.applicantsListInfo ],
        listIndex: currentApplicantsListInfo.listIndex
      }
      // 表示権限の無い対象をリストから削除
      newCurrentApplicantsListInfo.applicantsListInfo.splice(
        newCurrentApplicantsListInfo.listIndex,
        1
      )
      if (newCurrentApplicantsListInfo.applicantsListInfo.length > 0) {
        // listIndexを調整
        let direction = applicantDetailsRequest.directionId
        if (
          (direction === '1' && newCurrentApplicantsListInfo.listIndex > 0) ||
          (direction === '0' &&
            newCurrentApplicantsListInfo.listIndex ===
              newCurrentApplicantsListInfo.applicantsListInfo.length)
        ) {
          newCurrentApplicantsListInfo.listIndex--
        }
        yield put(setCurrentApplicantsListInfo(newCurrentApplicantsListInfo))
        let newApplicantDetailsRequest = { ...applicantDetailsRequest }
        newApplicantDetailsRequest.applicantsListInfo = [
          newCurrentApplicantsListInfo.applicantsListInfo[
            newCurrentApplicantsListInfo.listIndex
          ],
        ]
        yield put(setApplicantDetailsRequest(newApplicantDetailsRequest))
        // リトライ
        yield put(
          init({
            request: newApplicantDetailsRequest,
            currentApplicantsListInfo: newCurrentApplicantsListInfo,
          })
        )
      } else {
        // エラー
        yield put(openModalWindowClose(error.message))
      }
    } else {
      yield put(openModal(error.message))
    }
  }
}

export function* mcaxs620InitSaga(action: ReturnType<typeof mcaxs620Init>) {
  try {
    const serverResponse = yield call(mcaxs620InitRequest, action.payload)

    console.log('initSaga処理開始')

    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())
    yield put(setShowMCAXS620(true))

    console.log('initSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowChangeSaga(
  action: ReturnType<typeof doSelectionFlowChange>
) {
  try {
    //SV：他利用者更新チェック処理
    yield call(selectionFlowChangeRequest, action.payload.screenTransitionRequest)
    yield put(setCode(SUCCESS_CODE))
    yield put(fetchSelectionFlowChange(action.payload.initRequest))
    action.payload.setOpenModal(true)
    console.log('doSelectionFlowChangeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* mcaxs620DoSelectionFlowChangeSaga(
  action: ReturnType<typeof mcaxs620DoSelectionFlowChange>
) {
  try {
    //SV：他利用者更新チェック処理
    yield call(mcaxs620SelectionFlowChangeRequest, action.payload.screenTransitionRequest)
    yield put(setCode(SUCCESS_CODE))
    yield put(fetchSelectionFlowChange(action.payload.initRequest))
    action.payload.setOpenModal(true)
    console.log('doSelectionFlowChangeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowRouteChangeSaga(
  action: ReturnType<typeof doSelectionFlowRouteChange>
) {
  try {
    //SV：他利用者更新チェック処理
    yield call(selectionFlowRouteChangeRequest, action.payload)
    yield put(setCode(SUCCESS_CODE))
    console.log('doSelectionFlowRouteChangeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* mcaxs620DoSelectionFlowRouteChangeSaga(
  action: ReturnType<typeof mcaxs620DoSelectionFlowRouteChange>
) {
  try {
    //SV：他利用者更新チェック処理
    yield call(mcaxs620SelectionFlowRouteChangeRequest, action.payload)
    yield put(setCode(SUCCESS_CODE))
    console.log('doSelectionFlowRouteChangeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowExcludeSaga(
  action: ReturnType<typeof doSelectionFlowExclude>
) {
  try {
    //SV：対象外にする処理
    yield call(selectionFlowExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowExcludeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* mcaxs620DoSelectionFlowExcludeSaga(
  action: ReturnType<typeof mcaxs620DoSelectionFlowExclude>
) {
  try {
    //SV：対象外にする処理
    yield call(mcaxs620SelectionFlowExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowExcludeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* doSelectionFlowUnExcludeSaga(
  action: ReturnType<typeof doSelectionFlowUnExclude>
) {
  try {
    //SV：対象外から戻す処理
    yield call(selectionFlowUnExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowUnExcludeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* mcaxs620DoSelectionFlowUnExcludeSaga(
  action: ReturnType<typeof mcaxs620DoSelectionFlowUnExclude>
) {
  try {
    //SV：対象外から戻す処理
    yield call(mcaxs620SelectionFlowUnExcludeRequest, action.payload)
    yield put(setDoInit())
    console.log('doSelectionFlowUnExcludeSaga処理終了')
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

function* sendMessageChkSaga(action: ReturnType<typeof sendMessageChk>) {
  try {
    if (undefined != action.payload.request && null != action.payload.request) {
      const data = yield call(sendMessageChkRequest, action.payload.request)
      yield put(setSendMessageChk(data))
      let lengthFlag = false
      for (let i = 0; i < data.length; i++) {
        if ('' != data[i]) {
          lengthFlag = true
          break
        }
      }
      if (lengthFlag) {
        yield put(setSendMessageErrorFlag(true))
      } else {
        action.payload.onSendMessage()
      }
    }
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

//ワンクリック不採用通知
export function* notAdoptedNotificationSaga(action: ReturnType<typeof notAdoptedNotification>) {
  try{
    const newMsgWindow = {
      height: '948px',
      width: '1280px',
    }
    yield call(notAdoptedNotificationRequest, action.payload)
    const messageInputRequest = {
      screenId: 'MCAXS050',
      jobSeekerId: [action.payload.jobSeekerId],
      entryId: [action.payload.entryId],
      selectionManagementId: [action.payload.selectionManagementId],
      notAdoptedNotificationType: action.payload.notAdoptedNotificationType,
      selectionManagementSysVersionNumber:
        action.payload.selectionManagementSysVersionNumber,
      progressManagementSysVersionNumber:
        action.payload.progressManagementSysVersionNumber,
    }
    localStorage.setItem(
      'messageInputRequest',
      JSON.stringify(messageInputRequest)
    )
    window.open(
      routeList.messageSend,
      'newWindow',
      `width=${newMsgWindow.width}, height=${newMsgWindow.height}, resizable=yes`
    )
  } catch (e) {
    const error = e as any
    //エラーパターンに応じたエラーモーダル表示
    if(error.message === magiContants.MESSAGECODE_MCAXS050_017) {
      action.payload.setOpenErrorModal(true)
    } else {
      
      yield put(openModal(getMessage(error.message)))
    }
  }
}

export default function* interviewListSaga() {
  yield all([
    takeEvery(init, initSaga),
    takeEvery(doSelectionFlowChange, doSelectionFlowChangeSaga),
    takeEvery(doSelectionFlowRouteChange, doSelectionFlowRouteChangeSaga),
    takeEvery(doSelectionFlowExclude, doSelectionFlowExcludeSaga),
    takeEvery(doSelectionFlowUnExclude, doSelectionFlowUnExcludeSaga),
    takeEvery(mcaxs620Init, mcaxs620InitSaga),
    takeEvery(mcaxs620DoSelectionFlowChange, mcaxs620DoSelectionFlowChangeSaga),
    takeEvery(
      mcaxs620DoSelectionFlowRouteChange,
      mcaxs620DoSelectionFlowRouteChangeSaga
    ),
    takeEvery(
      mcaxs620DoSelectionFlowExclude,
      mcaxs620DoSelectionFlowExcludeSaga
    ),
    takeEvery(
      mcaxs620DoSelectionFlowUnExclude,
      mcaxs620DoSelectionFlowUnExcludeSaga
    ),
    takeEvery(sendMessageChk, sendMessageChkSaga),
    takeEvery(notAdoptedNotification, notAdoptedNotificationSaga),
  ])
}
