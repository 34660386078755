import React, { CSSProperties, HTMLAttributes, useEffect } from 'react'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import {
  createStyles,
  emphasize,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles'
import { MenuItem, Typography, Paper, Chip } from '@material-ui/core'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import Select, {components as componentsML, MenuListComponentProps} from 'react-select'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { ControlProps } from 'react-select/src/components/Control'
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu'
import { MultiValueProps } from 'react-select/src/components/MultiValue'
import { OptionProps } from 'react-select/src/components/Option'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { Omit } from '@material-ui/types'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'

export interface OptionType {
  label: string
  value: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
      minWidth: 290,
    },
    input: {
      display: 'flex',
      padding: theme.spacing(0.5, 1),
      height: 'auto',
      cursor: 'pointer'
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      ' & > div:last-child': {
        width: '0px',
        margin: '0px',
      },
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
      textAlign: 'center',
    },
    singleValue: {
      fontSize: 16,
      whiteSpace:'nowrap',
      overflow:'hidden',
      textOverflow:'ellipsis',
      maxWidth: 'calc(100% - 20px)',
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  })
)

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      variant='outlined'
      margin='normal'
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      {...{component: 'div'} as any}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  )
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

function MenuList (props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer,
  MenuList,
}

interface Props extends FieldProps {
  options: OptionType[]
  placeholder?: string
  multipul?: boolean
  defaultValue?: {label: '', value: ''}
  onMenuOpen?: () => void
  onMenuClose?: () => void
  searchable?: boolean
  setSettingId?: (value: any) => void
}

export default function IntegrationReactSelect({
  field,
  form,
  options,
  multipul,
  placeholder = 'Select...',
  defaultValue,
  onMenuOpen,
  onMenuClose,
  searchable,
  setSettingId,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const { name, value } = field
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  useEffect(() => {
    if(defaultValue !=null && defaultValue.label !== "") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      form.setFieldValue(name, defaultValue)
    }
  }, [defaultValue])

  const handleChange = (newValue: any) => {
    form.setFieldValue(name, newValue)
    if(setSettingId){
      setSettingId( newValue !== null? newValue: '' )
    }
  }

  return (
    <Select 
      classes={classes}
      styles={selectStyles}
      TextFieldProps={{
        InputLabelProps: {
          htmlFor: 'react-select-multiple',
          shrink: true,
        },
      }}
      isMulti={multipul}
      placeholder={placeholder}
      options={options}
      components={components}
      isClearable={true}
      value={value}
      onChange={handleChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isSearchable={searchable}
      noOptionsMessage={() => magiContants.REACT_SELECT_NOOPTIONSMESSAGE}
      filterOption={customSelectOptions.filterOption}
    />
  )
}
