import { MCAXS121InitRequest } from 'types/MCAXS121/MCAXS121InitRequest'

const MCAXS121initialValues: MCAXS121InitRequest = {
  selectionManagementIdList: [],
  entryDataIoTemplateSettingId: '',
  // #57536 start
  selectionFlowSettingId: [],
  // #57536 end
  recruitmentManagementDivision: '',
  // 次期開発9月 #58502 start
  sortKey: '',
  // 次期開発9月 #58502 end
  // MCBリプレース Lot3 start
  requestList: [],
  callDivision: '',
  // MCBリプレース Lot3 end
}
export { MCAXS121initialValues }
