import store from 'store'
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode'
import { getTokenCopy } from './auth';
import { isEqual } from 'lodash'

const TOKEN_HEAD = 'Bearer '

// モード切り替えた感知チェック
export const marchModeCheck = (token: any) => {
    if (store.getState().globalMenu.serviceMode) {
        if (token.march != store.getState().globalMenu.serviceMode) {
            return true
        }
    }
    return false
}

// トークンにモードだけ変更チェック
export const tokenCheckOfModeChanged = (token: any) => {
    let tokenCopyString = getTokenCopy()
    if (tokenCopyString) {
        let index = tokenCopyString.lastIndexOf('.')
        let jwtStringOfCopy = tokenCopyString.substring(TOKEN_HEAD.length, index + 1)
        let tokenCopy: any = jwt_decode(jwtStringOfCopy)
        if (tokenCopy.march) {
            return isEqual({ ...token, march: '', exp: '' }, { ...tokenCopy, march: '', exp: '' })
        }
    }
    return false
}

// トークンからJWT対象のMarchモード取得
export const getMarchModeFromToken = () => {
    let tokenString = Cookies.get('client-jwt')
    if (tokenString) {
        let index = tokenString.lastIndexOf('.')
        let jwtString = tokenString.substring(TOKEN_HEAD.length, index + 1)
        const token: any = jwt_decode(jwtString)
        if (token.march) {
            return token.march
        }
    }
    return ''
}