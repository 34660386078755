import request from 'utils/request'
import { MCAXS020SearchRequest } from 'types/MCAXS020/MCAXS020SearchRequest'
import { MCAXS020UpdateJudgmentRequest } from 'types/MCAXS020/MCAXS020UpdateJudgmentRequest'
import { MCAXS020GetMySearchRequest } from 'types/MCAXS020/MCAXS020GetMySearchRequest'
import { MCAXS020UpdateExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateExcludeRequest'
import { MCAXS020UpdateUnExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateUnExcludeRequest'
import { MCAXS020PagerMaxCardRequest } from 'types/MCAXS020/MCAXS020PagerMaxCardRequest'
import { MCAXS020ChangeDisplayTypeRequest } from 'types/MCAXS020/MCAXS020ChangeDisplayTypeRequest'
import { MCAXS020ServiceModeUpdateRequest } from 'types/MCAXS020/MCAXS020ServiceModeUpdateRequest'
import { MCAXS020CheckSelectionFlowandStepRequest } from 'types/MCAXS020/MCAXS020CheckSelectionFlowandStepRequest'

export const serviceModeUpdateRequest = (apiData: MCAXS020ServiceModeUpdateRequest) =>
  request({
    url: '/MCAXS020/serviceModeUpdate',
    method: 'post',
    data: apiData,
  })

export const searchRequest = (apiData: MCAXS020SearchRequest) =>
  request({
    url: '/MCAXS020/init',
    method: 'post',
    data: apiData,
  })

export const updateJudgmentRequest = (apiData: MCAXS020UpdateJudgmentRequest) =>
  request({
    url: '/MCAXS020/pushJudgmentListItem',
    method: 'post',
    data: apiData,
  })

export const getMySearchRequest = (apiData: MCAXS020GetMySearchRequest) =>
  request({
    url: '/MCAXS020/pushCallSearchCondition',
    method: 'post',
    data: apiData,
  })

export const updateExcludeRequest = (apiData: MCAXS020UpdateExcludeRequest) =>
  request({
    url: '/MCAXS020/pushOutsideTheTarget',
    method: 'post',
    data: apiData,
  })

export const updateUnExcludeRequest = (
  apiData: MCAXS020UpdateUnExcludeRequest
) =>
  request({
    url: '/MCAXS020/pushReturnFromOutsideTheTarget',
    method: 'post',
    data: apiData,
  })

export const changeListDisplayTypeRequest = (
  apiData: MCAXS020ChangeDisplayTypeRequest
) =>
  request({
    url: '/MCAXS020/changeListDisplayType',
    method: 'post',
    data: apiData,
  })

export const PagerMaxCardRequest = (
  apiData: MCAXS020PagerMaxCardRequest
) =>
  request({
    url: '/MCAXS020/pushPushPagerSelect',
    method: 'post',
    data: apiData,
  })

export const checkNotAdoptedNotificationPreferenceRequest = () =>
  request({
    url: '/MCAXS020/checkNotAdoptedNotificationPreference',
    method: 'post',
  })

export const checkSelectionFlowandStepRequest = (
  apiData: MCAXS020CheckSelectionFlowandStepRequest
) =>
  request({
    url: '/MCAXS020/checkSelectionFlowandStep',
    method: 'post',
    data: apiData,
  })