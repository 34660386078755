import React from 'react'
import { FieldProps } from 'formik'
import ReactSelect, {components as componentsML, MenuListComponentProps,} from 'react-select'
import customSelectOptions from 'utils/customSelectOptions'

const customStyles = {
  input: () => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
    cursor: 'pointer',
  }),
}

interface Option {
  value: string
  label: string
}
// MCBリプレイス #8707 START
function MenuList(props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div
        id='menuListScrollDiv'
        style={{ display: 'inline-block', minWidth: '100%' }}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList,
}
// MCBリプレイス #8707 END
interface Props extends FieldProps {
  optionList: Option[]
  noOptionsMessage: string
  placeholder?: string
  className?: string
  isMulti?: boolean
}

export default ({
  isMulti,
  className,
  optionList,
  noOptionsMessage,
  placeholder,
  field,
  form,
}: Props) => {
  const { name, value } = field

  const handleChange = (option: any) => {
    if (isMulti) {
      form.setFieldValue(name, option ? (option as Option[]).map(i => i) : [])
    } else {
      form.setFieldValue(name, option as Option)
    }
  }

  return (
    <ReactSelect
      isMulti={isMulti}
      name={name}
      className={className}
      styles={customStyles}
      options={optionList}
      value={optionList.find(i => i.value === value)}
      noOptionsMessage={() => noOptionsMessage}
      placeholder={placeholder}
      onChange={handleChange}
      filterOption={customSelectOptions.filterOption}
      // MCBリプレイス #8707 START
      components={components}
      // MCBリプレイス #8707 END
    />
  )
}
