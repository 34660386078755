/**
 * MCAES020DeleteRequest validation
 */

import * as yup from 'yup'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 採用グループId
  reqruitGroupId: yup
    .string()
    .required(validationMessageSet.required),

  // バージョン番号
  versionNumber: yup
    .string()
    .required(validationMessageSet.required),
});