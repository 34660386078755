import moment from 'moment-timezone'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getDiffFromToday, isEmpty, now, stringToDateTime, stringToDate } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import * as viewConfig from 'pages/MCAZS080/viewConig'
import {
  confirm,
  getInit,
  getPreview,
  saveDraft,
  setCode,
  setInit,
  setPreview,
  setScreenDisplayItems,
  openConfirmModal,
  open060ConfirmModal,
  setCloseWindow,
  csvDownload,
  openDetailDialog,
  setConfirmRequest,
  getNotAdoptedNotification,
} from 'reducers/messageSendReducer'
import { openModal, openModalWithArg } from 'reducers/messageReducer'
import {
  initRequest,
  draftRequest,
  previewRequest,
  confirmRequest,
  csvDownloadRequest,
  notAdoptedNotificationRequest,
} from 'apis/MCAZS080Api'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { isFileExist } from 'common/businessUtil'
import {
  setSearchCriteria,
  setDetailDialogOpen,
} from 'reducers/selectCriteriaManagementReducer'
moment.tz.setDefault('Asia/Tokyo')

const ALL_CONTRACT_MEDIA = 'MAGI' //すべての企業契約応募経路が対象

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
      const serverResponse = yield call(initRequest, action.payload)
      yield put(setInit(serverResponse))
      yield put(setScreenDisplayItems())
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* saveDraftSaga(action: ReturnType<typeof saveDraft>) {
  try {
    const { 
      formData, 
      files, 
      attachmentFileRegistrationMax,
      readableFlag
    } = action.payload

    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    
    // 選考フロー権限チェック(就職の場合)
    if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision){
      if (readableFlag === magiContants.INVALID) {
        yield put(openModal(getMessage('MCAZS080-073')))
        return
      }
    }

    // ファイル数のチェック
    if (files.length > attachmentFileRegistrationMax) {
      yield put(openModal(getMessage('MCAZS080-019')))
      return
    }

    // ファイル存在チェック
    if (files.some(item => !item || item.size <= 0)) {
      yield put(openModal(getMessage('MCAZS080-020')))
      return
    }
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const isFile = yield call(isFileExist, file)
        if(!isFile){
          yield put(openModal(getMessage('MCAZS080-020')))
          return
        }
      }
    }

    // ファイルの拡張子チェック
    const suffixCheck = [
      'ai','art','doc','fla','gif','jpg','jpeg','lzh','mpeg','mpg','pdf','png','pps','ppt','psd','txt','xls','zip','docx','xlsx'
    ]
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const lastIndexOf = file.name.lastIndexOf('.')
        const suffix = file.name.substring(lastIndexOf + 1)
        for (var j = 0; j < suffixCheck.length; j++) {
          if (suffixCheck[j] == suffix.toLowerCase()) {
            break
          } else {
            if(suffixCheck[j] != 'xlsx') {
              continue
            } else {
              yield put(openModal(getMessage('MCAZS080-017')))
              return
            }
          }
        }
      }
    }

    // 同一添付ファイルチェック
    const addedfileNameList = files.map(file => file.name)
    const fullFileNameList = addedfileNameList.concat(formData.attachmentName)
    const fileNameList = fullFileNameList.filter(v => v)
    if (fileNameList.length > 1) {
      const beforeLength = fileNameList.length
      const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
      const afterLength = newFileNameList.length
      if (beforeLength !== afterLength) {
        yield put(openModal(getMessage('MCAZS080-026')))
        return
      }
    }
    
    // ファイルのサイズチェック
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const fileSize = file.size
        if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
          yield put(openModal(getMessage('MCAZS080-018')))
          return
        }
      }
    }

    yield call(draftRequest, formData, files)
    yield put(setCode(200))
    yield put(setCloseWindow(true))
  } catch (error) {
    if (error.message){
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* previewSaga(action: ReturnType<typeof getPreview>) {
  try {
    const formData = action.payload.formData
    const serverResponse = yield call(previewRequest, formData)
    
    yield put(setPreview(serverResponse))
  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export function* confirmSaga(action: ReturnType<typeof confirm>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files
    const entryTypeList: string[] = action.payload.entryTypeList
    const viewEndTimeList: Date[] = action.payload.viewEndTimeList
    const forbiddenWords: string[] = action.payload.forbiddenWords
    const entryBoxList: viewConfig.MynaviAttachedOptionEntryBox[] = action.payload.entryBoxList
    const seminarList: viewConfig.MynaviAttachedOptionSeminar[] = action.payload.seminarList
    const attachmentFileRegistrationMax = action.payload.attachmentFileRegistrationMax
    const mynaviAttachedOptionMax = action.payload.mynaviAttachedOptionMax
    const sendToNonMemberCheck = action.payload.sendToNonMemberCheck
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const readableFlag = action.payload.readableFlag
    
    // 選考フロー権限チェック(就職の場合)
    if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision){
      if (readableFlag === magiContants.INVALID) {
        yield put(openModal(getMessage('MCAZS080-073')))
        return
      }
    }

    if (files.length > 0 || formData.attachmentId.length > 0) {

      // ファイル数のチェック
      if (files.length > attachmentFileRegistrationMax) {
        yield put(openModal(getMessage('MCAZS080-023')))
        return
      }

      // ファイル存在チェック
      if (files.length > 0) {
        if (files.some(item => !item || item.size <= 0)) {
          yield put(openModal(getMessage('MCAZS080-024')))
          return
        }
      }
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if(!isFile){
            yield put(openModal(getMessage('MCAZS080-024')))
            return
          }
        }
      }
        
      // ファイルの拡張子チェック
      if (files.length > 0) {
          const suffixCheck = [
          'ai','art','doc','fla','gif','jpg','jpeg','lzh','mpeg','mpg','pdf','png','pps','ppt','psd','txt','xls','zip','docx','xlsx'
        ]
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const lastIndexOf = file.name.lastIndexOf('.')
            const suffix = file.name.substring(lastIndexOf + 1)
            for (var j = 0; j < suffixCheck.length; j++) {
              if (suffixCheck[j] == suffix.toLowerCase()) {
                break
              } else {
                if(suffixCheck[j] != 'xlsx') {
                  continue
                } else {
                  yield put(openModal(getMessage('MCAZS080-017')))
                  return
                }
              }
            }
          }
        }
      }

      // 同一添付ファイルチェック
      const addedfileNameList = files.map(file => file.name)
      const fullFileNameList = addedfileNameList.concat(formData.attachmentName)
      const fileNameList = fullFileNameList.filter(v => v)
      if (fileNameList.length > 1) {
        const beforeLength = fileNameList.length
        const newFileNameList = fileNameList.filter((x, index, self) => self.indexOf(x) === index)
        const afterLength = newFileNameList.length
        if (beforeLength !== afterLength) {
          yield put(openModal(getMessage('MCAZS080-026')))
          return
        }
      }

      // ファイルのサイズチェック
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileSize = file.size
            if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
              yield put(openModal(getMessage('MCAZS080-022')))
              return
            }
          }
        }
      }

      // 添付ファイル送信経路チェック
      if (String(formData.destination) === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
        if (String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY && !formData.sendTargetConfirmation.sendTarget.some(item => item.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_NON_MEMBER && item.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_DEACTIVATED)) {
          // 送信先：e-mailのみ かつ　非会員あるいは退会済みの場合、チェックOK
        } else {
          yield put(openModal(getMessage('MCAZS080-025')))
          return
        }
      }

    }

    // バーコード表示送信経路チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      if (String(formData.barcodeDisplay) === magiContants.BARCODE_DISPLAY_FLAG_SHOW) {
        yield put(openModal(getMessage('MCAZS080-027')))
        return
      }
    }

    // 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.subject.indexOf(item) >= 0 && formData.manipulationSelection !== magiContants.SELECTION_NUMBER_FIX && formData.manipulationSelection !== magiContants.SELECTION_NUMBER_SEND) {
          isErrorSubject = true
          return
        }
        if (formData.text.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(openModal(getMessage('MCAZS080-028')))
        return
      }
      if (isErrorBody) {
        yield put(openModal(getMessage('MCAZS080-029')))
        return
      }
    }

    // ホームページURLチェック
    if (((formData.homepageTitle1 && formData.homepageTitle1 !== '') && (formData.homepageUrl1 === null || formData.homepageUrl1 === ''))
      || ((formData.homepageTitle2 && formData.homepageTitle2 !== '') && (formData.homepageUrl2 === null || formData.homepageUrl2 === ''))
      || ((formData.homepageTitle3 && formData.homepageTitle3 !== '') && (formData.homepageUrl3 === null || formData.homepageUrl3 === ''))
      ) {
      yield put(openModal(getMessage('MCAZS080-032')))
      return
    }

    // 不正文字チェック
    if (formData.subject.indexOf('●●') >= 0 || formData.text.indexOf('●●') >= 0) {
      yield put(openModal(getMessage('MCAZS080-033')))
      return
    }

    // 送信予約可否チェック
    if (formData.manipulationKubun === magiContants.ACTION_FIX_INTERVIEW || formData.manipulationSelection === magiContants.SELECTION_NUMBER_FIX || formData.manipulationSelection === magiContants.SELECTION_NUMBER_SEND) {
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
          yield put(openModal(getMessage('MCAZS080-035')))
        return
      }
    }
    
    // 送信予約期間チェック
    if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
      if (formData.sendPlanTime === null || formData.sendPlanTime === '') {
        yield put(openModal(getMessage('MCAZS080-036')))
        return
      }
      if (formData.sendPlanTime) {
        const sendPlanTime = stringToDateTime(formData.sendPlanTime)
        if (sendPlanTime !== null) {
          if (sendPlanTime < now()) {
            yield put(openModal(getMessage('MCAZS080-037')))
            return
          }
          const targetDate = moment(sendPlanTime)
          const toDate = moment(now()).add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day').set('hour', 23).set('minutes', 59).set('second', 59)
          if (targetDate > toDate) {
            yield put(openModal(getMessage('MCAZS080-043')))
            return
          }
          if ((formData.entryBoxId && formData.entryBoxId.length > 0) || (formData.seminarId && formData.seminarId.length > 0)) {
            let isError = false
            if (formData.entryBoxId && formData.entryBoxId.length > 0) {
              entryBoxList.map(item => {
                if (formData.entryBoxId.some(entryBoxId => entryBoxId === String(item.entryBoxId))) {
                  const startDate = moment(item.displayStartDate)
                  const endDate = moment(item.displayEndDate).set('hour', 23).set('minutes', 59).set('second', 59)
                  const sendPlanTime = moment(stringToDateTime(formData.sendPlanTime))
                  if (sendPlanTime.isBefore(startDate) || sendPlanTime.isAfter(endDate)) {
                    isError = true
                    return
                  }
                }
              })
            }
            if (formData.seminarId && formData.seminarId.length > 0) {
              seminarList.map(item => {
                if (formData.seminarId.some(seminarId => seminarId === String(item.seminarId))) {
                  const startDate = moment(item.displayStartDate)
                  const endDate = moment(item.displayEndDate).set('hour', 23).set('minutes', 59).set('second', 59)
                  const sendPlanTime = moment(stringToDateTime(formData.sendPlanTime))
                  if (sendPlanTime.isBefore(startDate) || sendPlanTime.isAfter(endDate)) {
                    isError = true
                    return
                  }
                }
              })
            }
            if (isError) {
              yield put(openModal(getMessage('MCAZS080-044')))
              return
            }
          }
          const minTime = moment(now()).add(magiContants.RESERVATION_MIN_MINUTES_LENGTH, 'minutes')
          if (targetDate <= minTime) {
            yield put(openModal(getMessage('MCAZS080-045')))
            return
          }
        }
      }
    }

    // メンテナンス期間チェック
    if (formData.sendReplyImpossibleTime && formData.sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(formData.sendPlanTime.replace(/\//g,'-'))
        }
      }
      formData.sendReplyImpossibleTime.map(item => {
        if (formData.contractMediaId.indexOf(item.contractMediaIdStr.trim()) >= 0 || item.contractMediaIdStr.trim() === ALL_CONTRACT_MEDIA) {
          let startDate
          let endDate
          let startDateStr = !isEmpty(item.startDateStr) ? item.startDateStr.trim() : ''
          let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
          if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
            startDate = moment(`${startDateStr.substring(0, 4)}-${startDateStr.substring(4, 6)}-${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}`)
          }
          if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
            endDate = moment(`${endDateStr.substring(0, 4)}-${endDateStr.substring(4, 6)}-${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`)
          }
          if (startDate && endDate) {
            if ((sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) && (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0)) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
          if (startDate && !endDate) {
            if (sendTime.isAfter(startDate) || sendTime.diff(startDate, 'minute') === 0) {
              errorPeriod = `${startDateStr.substring(0, 4)}/${startDateStr.substring(4, 6)}/${startDateStr.substring(6, 8)} ${startDateStr.substring(8, 10)}:${startDateStr.substring(10, 12)}～`
              isError = true
              return
            }
          }
          if (!startDate && endDate) {
            if (sendTime.isBefore(endDate) || sendTime.diff(endDate, 'minute') === 0) {
              errorPeriod = `～${endDateStr.substring(0, 4)}/${endDateStr.substring(4, 6)}/${endDateStr.substring(6, 8)} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
              isError = true
              return
            }
          }
        }
      })
      if (isError) {
        yield put(openModal(getMessageEx('MCAZS080-034', errorPeriod)))
        return
      }
    }

    // 送信先チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      if (entryTypeList.some(item => item === magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION)) {
        yield put(openModal(getMessage('MCAZS080-049')))
        return
      }
    }
    
    // 送信先チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      if (formData.senderMailAddress === null || formData.senderMailAddress === '') {
        if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision){
          yield put(openModal(getMessage('MCAZS080-050')))
        }else if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 === recruitmentManagementDivision){
          yield put(openModal(getMessage('MCAZS080-060')))
        }
        return
      }
    }

    // 送信先チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
      if (formData.sendTargetConfirmation) {
        if (!formData.sendTargetConfirmation.sendTarget.some(item => item.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_NON_MEMBER)) {
          yield put(openModal(getMessage('MCAZS080-051')))
          return
        }
      }
    }

    // 送信先チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED) {
      if (formData.sendTargetConfirmation) {
        if (!formData.sendTargetConfirmation.sendTarget.some(item => item.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_NON_MEMBER || !isEmpty(item.mailAddress))) {
          yield put(openModal(getMessage('MCAZS080-052')))
          return
        }
      }
    }

    // 送信先チェック
    if (String(formData.destination) === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
      if (formData.sendTargetConfirmation) {
        if (!formData.sendTargetConfirmation.sendTarget.some(item => !isEmpty(item.mailAddress))) {
          yield put(openModal(getMessage('MCAZS080-053')))
          return
        }
      }
    }

    // 送信先チェック
    if (formData.sendTargetConfirmation) {
      if (!formData.sendTargetConfirmation.sendTarget.some(item => item.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_DEACTIVATED) && sendToNonMemberCheck == magiContants.SEND_TO_NON_MEMBER) {
        yield put(openModal(getMessage('MCAZS080-054')))
        return
      }
    }

    // エントリー有効期限チェック
    if (viewEndTimeList && viewEndTimeList.length > 0) {
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_IMMEDIATE) {
        const currentDate = moment(now())
        if (viewEndTimeList.some(item => item && currentDate.isAfter(moment(item)))) {
          yield put(openModal(getMessage('MCAZS080-056')))
          return
        }
      }
      if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION) {
        const sendPlanTime = moment(stringToDateTime(formData.sendPlanTime))
        if (viewEndTimeList.some(item => item && sendPlanTime.isAfter(moment(item)))) {
          yield put(openModal(getMessage('MCAZS080-057')))
          return
        }
      }
    }
    
    if (formData.manipulationSelection !== 0) {
      // 候補日有無チェック
      if (!formData.interviewStartTimeN || formData.interviewStartTimeN.length <= 0 || !formData.interviewStartTimeN.some(item => !isEmpty(item))) {
        yield put(openModal(getMessage('MCBAS010-003')))
        return
      }
      // 候補日時過去チェック
      let isError = false
      formData.interviewStartTimeN.map(interviewStartTimeString => {
        let interviewStartTime: Date|null = null
        if (interviewStartTimeString) {
          interviewStartTime = stringToDateTime(interviewStartTimeString)
        }
        if (interviewStartTime) {
          if (getDiffFromToday(interviewStartTime) <= 0) {
            isError = true
          }
        }
      })
      if (isError) {
        yield put(openModal(getMessage('MCBAS010-005')))
        return
      }
      // 候補日が日時が90日後の23:59を超える場合チェック#5118
      isError = false
      formData.interviewStartTimeN.map(interviewStartTimeString => {
        let interviewStartTime: Date|null = null
        if (interviewStartTimeString) {
          interviewStartTime = stringToDateTime(interviewStartTimeString)
        }

        const targetDate = moment(interviewStartTime)
        const toDate = moment(now()).add(magiContants.INTERVIEWDATE_MAX_DATE_LENGTH, 'day').set('hour', 23).set('minutes', 59).set('second', 59)
        if (targetDate > toDate) {
          isError = true

        }
      })
      if (isError) {
        
        yield put(openModal(getMessage('MCBAS010-012')))
        return
      }
    }
    // 候補日時過去チェック
    if (formData.manipulationKubun === magiContants.ACTION_FIX_INTERVIEW && !isEmpty(formData.interviewStartTime)) {
      let isError = false
      let interviewStartTime: Date|null = null
      const interviewStartTimeString = formData.interviewStartTime
      interviewStartTime = stringToDateTime(interviewStartTimeString)
      if (interviewStartTime) {
        if (getDiffFromToday(interviewStartTime) <= 0) {
          isError = true
        }
      }
      if (isError) {
        yield put(openModal(getMessage('MCBAS010-005')))
        return
      }
    }
    // 配信予約日と候補日の整合性チェック
    if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION && !isEmpty(formData.sendPlanTime)) {
      let isError = false
      const sendPlanTime = moment(formData.sendPlanTime)
      formData.interviewStartTimeN.map(startTime => {
        if (moment(startTime).isBefore(sendPlanTime, 'days')) {
          isError = true
        }
      })
      if (isError) {
        yield put(openModal(getMessage('MCBAS010-004')))
        return
      }
    }

    // 応募者希望時間内チェック
    if (formData.manipulationSelection === magiContants.SELECTION_NUMBER_FIX) {
      const jobSeekerInterviewDesiredSchedule = formData.jobSeekerInterviewDesiredSchedule
      if (jobSeekerInterviewDesiredSchedule) {
        let isError = false
        const durationTime = formData.interviewDurationTime ? formData.interviewDurationTime : magiContants.DURATION_ACTUAL_TIME_MINUTE_OTHER
        formData.interviewStartTimeN.map(startTime => {
          if (!isEmpty(startTime)) {
            const scheduleDateStart = moment(startTime).add(-(moment(startTime).utcOffset() + now().getTimezoneOffset()), 'minute')
            const scheduleDateEnd = moment(startTime).add(-(moment(startTime).utcOffset() + now().getTimezoneOffset()), 'minute').add(durationTime, 'minute')
            let isMatched = false
            const currentDateList = jobSeekerInterviewDesiredSchedule.filter(item => moment(item.interviewPreferredDate).add(moment(item.startTime).hour(), 'hour').add(moment(item.startTime).minute(), 'minute').date() === scheduleDateStart.date())
            if (currentDateList.length <= 0) {
              isError = true
            } else {
              const desiredSchedule = currentDateList[0]
              let interviewPreferredDate: Date = desiredSchedule.interviewPreferredDate
              let startTime: Date = desiredSchedule.startTime
              let endTime: Date = desiredSchedule.endTime
              let startValue = moment(interviewPreferredDate).add(moment(startTime).hour(), 'hour').add(moment(startTime).minute(), 'minute')
              let endValue = moment(interviewPreferredDate).add(moment(endTime).hour(), 'hour').add(moment(endTime).minute(), 'minute')
              let list: any[] = []
              // 同日の希望日時で開始終了時間がつながるレコードがあれば1件にまとめる
              currentDateList.map((item, index) => {
                const currentStart = moment(item.interviewPreferredDate).add(moment(item.startTime).hour(), 'hour').add(moment(item.startTime).minute(), 'minute')
                const currentEnd = moment(item.interviewPreferredDate).add(moment(item.endTime).hour(), 'hour').add(moment(item.endTime).minute(), 'minute')
                if ((currentStart.isAfter(startValue) || currentStart.diff(startValue, 'minute') === 0) && (currentStart.isBefore(endValue) || currentStart.diff(endValue, 'minute') === 0)) {
                  endTime = item.endTime
                  endValue = currentEnd
                } else {
                  list = [...list, {
                    interviewPreferredDate: interviewPreferredDate,
                    timePeriodCode: 0,
                    startTime: startTime,
                    endTime: endTime,
                  }]
                  startTime = item.startTime
                  endTime = item.endTime
                  startValue = currentStart
                  endValue = currentEnd
                }
                if (currentDateList.length === index + 1) {
                  list = [...list, {
                    interviewPreferredDate: interviewPreferredDate,
                    timePeriodCode: 0,
                    startTime: startTime,
                    endTime: endTime,
                  }]
                }
              })
              list.map(desiredSchedule => {
                if (!isMatched) {
                  const start = moment(desiredSchedule.interviewPreferredDate).add(moment(desiredSchedule.startTime).hour(), 'hour').add(moment(desiredSchedule.startTime).minute(), 'minute')
                  const end = moment(desiredSchedule.interviewPreferredDate).add(moment(desiredSchedule.endTime).hour(), 'hour').add(moment(desiredSchedule.endTime).minute(), 'minute')
                  if ((scheduleDateStart.diff(start, 'minute') === 0 || scheduleDateStart.isAfter(start)) && (scheduleDateEnd.diff(end, 'minute') === 0 || scheduleDateEnd.isBefore(end))) {
                    isMatched = true
                    isError = false
                  } else {
                    isError = true
                  }
                }
              })
            }
          }
        })
        if (isError) {
          yield put(openModal(getMessage('MCBAS010-009')))
          return
        }
      }
    }

    // 不可日時間内チェック
    if (formData.manipulationSelection === magiContants.SELECTION_NUMBER_OTHER || formData.manipulationSelection === magiContants.SELECTION_NUMBER_PRESENT) {
      if (formData.jobSeekerInterviewNotPossibleSchedule) {
        let isError = false
        formData.interviewStartTimeN.map(startTime => {
          if (!isEmpty(startTime)) {
            const durationTime = formData.interviewDurationTime
            const scheduleDateStart = moment(startTime).add(-(moment(startTime).utcOffset() + now().getTimezoneOffset()), 'minute')
            const scheduleDateEnd = moment(startTime).add(-(moment(startTime).utcOffset() + now().getTimezoneOffset()), 'minute').add(durationTime, 'minute')
            formData.jobSeekerInterviewNotPossibleSchedule.map(notPossiblechedule => {
              const start = moment(notPossiblechedule.interviewNotPossibleDate).add(moment(notPossiblechedule.startTime).hour(), 'hour').add(moment(notPossiblechedule.startTime).minute(), 'minute')
              const end = moment(notPossiblechedule.interviewNotPossibleDate).add(moment(notPossiblechedule.endTime).hour(), 'hour').add(moment(notPossiblechedule.endTime).minute(), 'minute')
              if ((scheduleDateStart.diff(start) === 0 || scheduleDateStart.isAfter(start)) && (scheduleDateEnd.diff(end) === 0 || scheduleDateEnd.isBefore(end))) {
                isError = true
              }
              if ((scheduleDateEnd.isAfter(start)) && (scheduleDateEnd.diff(end) === 0 || scheduleDateEnd.isBefore(end))) {
                isError = true
              }
              if ((scheduleDateStart.diff(start) === 0 || scheduleDateStart.isAfter(start)) && (scheduleDateStart.isBefore(end)) && (scheduleDateEnd.isAfter(end))) {
                isError = true
              }
            })
          }
        })
        if (isError) {
          yield put(openModal(getMessage('MCBAS010-009')))
          return
        }
      }
    }
    
    // マイナビ添付オプション上限チェック
    if ((formData.entryBoxId ? formData.entryBoxId.length : 0 ) + (formData.seminarId ? formData.seminarId.length : 0) > mynaviAttachedOptionMax) {
      yield put(openModal(getMessage('MCAZS080-046', [String(mynaviAttachedOptionMax)])))
      return
    }

    // マイナビ添付オプションチェック
    if (formData.contractMediaId && formData.contractMediaId.length > 1) {
      if ((formData.entryBoxId && formData.entryBoxId.length > 0) || (formData.seminarId && formData.seminarId.length > 0)) {
        if(String(formData.destination) !== magiContants.DESTINATION_DIVISION_EMAIL_ONLY){
          yield put(openModal(getMessage('MCAZS080-047')))
          return
        }
      }
    }

    // 置換文字チェック_セミナー画面が複数
    if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision){
      const matchedSeminarScreenBody = formData.text.match(/{@seminar[0-9]*}/g)
      // 差込できないのを除く
      let matchedSeminarIdArray: string[] = matchedSeminarScreenBody ? matchedSeminarScreenBody.map(i => i.match(/{@seminar(\S*)}/) ? (i.match(/{@seminar(\S*)}/) as any)[1] : i) : []
      let count: number = matchedSeminarIdArray.length
      let seminarFlag: string[] = formData.seminar ? formData.seminar.map(i => i.seminarId.toString()) : []
      let tempArray: string[] = matchedSeminarIdArray.filter(i => seminarFlag.indexOf(i) != -1)
      count = count - matchedSeminarIdArray.filter(i => tempArray.indexOf(i) == -1).length

      const matchedSeminarIdScreenBody = formData.text.match(/{@seminarId[0-9]*}/g)
      // 差込できないのを除く
      let matchedArraySeminarId: string[] = matchedSeminarIdScreenBody ? matchedSeminarIdScreenBody.map(i => i.match(/{@seminarId(\S*)}/) ? (i.match(/{@seminarId(\S*)}/) as any)[1] : i) : []
      let countSeminerId: number = matchedArraySeminarId.length
      let seminarIdFlag: string[] = formData.seminar ? formData.seminar.map(i => i.coordinationFormerSeminarId.toString()) : []
      let tempArraySeminerId: string[] = matchedArraySeminarId.filter(i => seminarIdFlag.indexOf(i) != -1)
      countSeminerId = countSeminerId - matchedArraySeminarId.filter(i => tempArraySeminerId.indexOf(i) == -1).length
      if(count + countSeminerId > 1) {
        yield put(openModal(getMessage('MCAZS080-062')))
        return
      }
    }

    // 応募者からの返信期限過去日チェック
    if (!isEmpty(formData.repliesLimit)) {
      const repliesLimit = stringToDate(formData.repliesLimit)
      if (repliesLimit !== null) {
        // 応募者からの返信期限が過去日の場合
        if (getDiffFromToday(repliesLimit) < 0) {
          yield put(openModal(getMessage('MCAZS080-066')))
          return
        }
        const toDate = moment(repliesLimit).add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day').set('hour', 23).set('minutes', 59).set('second', 59)
        
        // 送信予約時、応募者からの返信期限＜送信予約日時の場合
        if (String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION && !isEmpty(formData.sendPlanTime)) {
          const sendPlanTime = moment(formData.sendPlanTime)
          if (moment(repliesLimit).isBefore(sendPlanTime, 'days')) {
            yield put(openModal(getMessage('MCAZS080-067')))
            return
          }
        }
      } 
    }

    // 差し込み文字チェック_セミナー画面、管理項目のみが使用されている
    if(magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 === recruitmentManagementDivision){
      let chkBody =  formData.text
      chkBody = chkBody.replace(/{@seminar[0-9]*}/g,'')
      chkBody = chkBody.replace(/{@seminarId[0-9]*}/g,'')
      chkBody = chkBody.replace(/{@flagvalue[0-9]*}/g,'')
      chkBody = chkBody.trim()

      // 差込できないのを除く
      // セミナー画面
      const matchedSeminarBody = formData.text.match(/{@seminar[0-9]*}/g)
      let matchedSeminarIdArray: string[] = matchedSeminarBody ? matchedSeminarBody.map(i => i.match(/{@seminar(\S*)}/) ? (i.match(/{@seminar(\S*)}/) as any)[1] : i) : []
      let seminarFlag: string[] = formData.seminar ? formData.seminar.map(i => i.seminarId.toString()) : []
      let unmatchSeminarArray: string[] = matchedSeminarIdArray.filter(i => seminarFlag.indexOf(i) == -1)

      // セミナー画面（新）
      const matchedSeminarIdBody = formData.text.match(/{@seminarId[0-9]*}/g)
      let matchedArraySeminarId: string[] = matchedSeminarIdBody ? matchedSeminarIdBody.map(i => i.match(/{@seminarId(\S*)}/) ? (i.match(/{@seminarId(\S*)}/) as any)[1] : i) : []
      let seminarIdFlag: string[] = formData.seminar ? formData.seminar.map(i => i.coordinationFormerSeminarId.toString()) : []
      let unmatchSeminarIdArray: string[] = matchedArraySeminarId.filter(i => seminarIdFlag.indexOf(i) == -1)

      // 管理項目
      const matchedFlagValueBody = formData.text.match(/{@flagvalue[0-9]*}/g)
      let matchedflagValueIdArray: string[] = matchedFlagValueBody ? matchedFlagValueBody.map(i => i.match(/{@flagvalue(\S*)}/) ? (i.match(/{@flagvalue(\S*)}/) as any)[1] : i) : []
      let flagValueFlag: string[] = formData.recruitmentManagementFlag ? formData.recruitmentManagementFlag.map(i => i.recruitmentManagementFlagSettingId.toString()) : []
      let unmatchFlagValueArray: string[] = matchedflagValueIdArray.filter(i => flagValueFlag.indexOf(i) == -1)

      if(!chkBody && unmatchSeminarArray.length === 0 && unmatchFlagValueArray.length === 0 && unmatchSeminarIdArray.length === 0){
        yield put(openModal(getMessage('MCAZS080-074')))
        return
      }
    }
    // WEB面接URL送付用差し込み文字チェックを実施する  WEB面接対応
    if (formData.interviewWebUrlSendFlag === magiContants.INTERVIEW_WEB_URL_SEND_FLAG){
      // WEB面接が有効の場合本文に「{@interview_datetime}」「{@interview_url}」のいずれか挿入されていなかった場合エラーとする。
      if (formData.text.indexOf('{@interview_datetime}') == -1 || formData.text.indexOf('{@interview_url}') == -1){
        yield put(openModal(getMessage('MCAZS080-075')))
        return
      }
    }
    // 差し込み文字チェック_送信元件名、送信元本文のみが使用されている
    let chkSubject =  formData.subject
    chkSubject = chkSubject.replace(/{@reply_title}/g,'')
    chkSubject = chkSubject.trim()
    let chkBody =  formData.text
    chkBody = chkBody.replace(/{@reply_body}/g,'')
    chkBody = chkBody.trim()
    if((!chkSubject || !chkBody)){
      yield put(openModal(getMessage('MCAZS080-078')))
      return
    }

    // 差し込み文字チェック_返信メッセージではないのに送信元件名、送信元本文が使用されている
    let matchedSubject =  formData.subject.match(/{@reply_title}/g)
    let matchedBody =  formData.text.match(/{@reply_body}/g)
    let senderMessageId = formData.senderMessageId[0]
    if(((matchedSubject && matchedSubject.length > 0) || (matchedBody && matchedBody.length > 0)) && !senderMessageId){
      yield put(openModal(getMessage('MCAZS080-079')))
      return
    }

    yield call(confirmRequest, formData, files)
    yield put(openConfirmModal())

  } catch (error) {
    if (error.message === 'MCBAS010-006') {
      yield put(open060ConfirmModal())
    } else if (error.message){
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export function* csvDownloadSaga(action: ReturnType<typeof csvDownload>) {
  const data = yield call(csvDownloadRequest, action.payload)
  if (data.data.type.indexOf('application/json') !== -1) {
    yield put(openModal(magiContants.MESSAGECODE_MCAZS080_071))
  } else {
    var blob = new Blob([data.data]);
    const date = new Date();
    var curr_date = date.getDate() + "";
    var curr_month = (date.getMonth() + 1) + "";
    const curr_year = date.getFullYear()
    var curr_hour = date.getHours() + "";
    var curr_minutue = date.getMinutes() + "";
    curr_month = curr_month.length < 2 ? "0" + curr_month : curr_month;
    curr_date = curr_date.length < 2 ? "0" + curr_date : curr_date;
    curr_hour = curr_hour.length < 2 ? "0" + curr_hour : curr_hour;
    curr_minutue = curr_minutue.length < 2 ? "0" + curr_minutue : curr_minutue;
    const yymmdd = curr_year + curr_month + curr_date + "_" + curr_hour + curr_minutue;
    const fileName = "message_job_seeker_list_" + yymmdd + ".csv"
    if (navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    }
  }
}
export function* openDetailDialogSaga(action: ReturnType<typeof openDetailDialog>) {
  yield put(setSearchCriteria(action.payload))
  yield put(setDetailDialogOpen(true))
}

export function* notAdoptedNotificationSaga(action: ReturnType<typeof getNotAdoptedNotification>) {
  try {
    const serverResponse = yield call(notAdoptedNotificationRequest, action.payload)
    yield put(setInit(serverResponse))
    yield put(setScreenDisplayItems())

  } catch (error) {
    yield put(setCode(error.code))
    yield put(openModal(error.message))
  }
}

export default function* messageInputSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(saveDraft, saveDraftSaga),
    takeLatest(getPreview, previewSaga),
    takeLatest(confirm, confirmSaga),
    takeLatest(csvDownload, csvDownloadSaga),
    takeLatest(openDetailDialog, openDetailDialogSaga),
    takeLatest(getNotAdoptedNotification, notAdoptedNotificationSaga),
  ])
}
