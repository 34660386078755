
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {MCAXS260TargetIdRequest} from 'types/MCAXS260/MCAXS260TargetIdRequest'
import {MCAXS260OutputPdfRequest} from 'types/MCAXS260/MCAXS260OutputPdfRequest'
import {MCAXS260MailShareRequest} from 'types/MCAXS260/MCAXS260MailShareRequest'
import { MCAXS111initRequest } from 'types/MCAXS111/MCAXS111initRequest'
import MCBCS010DuplicateDataRequest from 'types/MCBCS010/MCBCS010DuplicateDataRequest'
import { MCAXS260InitResultDto, MCAXS260InitResultValue } from 'pages/MCAXS260/formConfig'
import { MCAXS260OutputMcbPdfRequest } from 'types/MCAXS260/MCAXS260OutputMcbPdfRequest'
import { MCAXS260OutputMcbCsvRequest } from 'types/MCAXS260/MCAXS260OutputMcbCsvRequest'
import { MCAXS260SendMcbRequestMessageRequest } from 'types/MCAXS260/MCAXS260SendMcbRequestMessageRequest'
import { MCAXS260OtherOperationOfMcbRequest } from 'types/MCAXS260/MCAXS260OtherOperationOfMcbRequest'
export interface submissionRequestInfo {
    // 企業別求職者ID
    jobSeekerByCompanyId: string
  
    // 提出リクエストID
    submissionRequestId: string
  
    // 求職者ID
    jobSeekerId: string
  }
export interface snackBarForCheckData{
    openCheckModal:boolean,
    modalMessage:string
}
const resetModal:snackBarForCheckData={
        openCheckModal:false,
        modalMessage:""
}
interface initType{
    validTestFlag:string,
    initResultData:MCAXS260InitResultDto,
    snackBarForCheckData:snackBarForCheckData
    mCAXS111Open: boolean
    mCAXS340Open: boolean
    openDialogData: snackBarForCheckData
    submitCheckDialogFlag: boolean
    submissionRequestInfo: submissionRequestInfo[]
}
const initialState:initType={
    validTestFlag:"",
    initResultData:MCAXS260InitResultValue,
    snackBarForCheckData:{
        openCheckModal:false,
        modalMessage:""
    },
    mCAXS111Open: false,
    mCAXS340Open: false,
    openDialogData: resetModal,
    submitCheckDialogFlag: false,
    submissionRequestInfo: [],
}
const changeOthersSlice = createSlice({
    name: 'interview',
    initialState,
    reducers: {
        init(state){
        },
        setState(state,action:PayloadAction<MCAXS260InitResultDto>){
            let data = JSON.parse(JSON.stringify(action.payload.data))
            state.validTestFlag=data.validTestFlag
            state.initResultData=action.payload;
          return state; 
        },
        excludedata(state,action:PayloadAction<{request: MCAXS260TargetIdRequest, onclose?: () => void}>){
        },
        restoredata(state,action:PayloadAction<{request: MCAXS260TargetIdRequest, onclose?: () => void}>){
        },
        // [phase2] start by zhangxp
        deletedata(state,action:PayloadAction<{request: MCAXS260TargetIdRequest, onclose?: () => void}>){
        },
        // [phase2] end by zhangxp
        outputPdfdata(state,action:PayloadAction<MCAXS260OutputPdfRequest>){
        },
        mailSharedata(state,acton:PayloadAction<MCAXS260MailShareRequest>){
        },
        sendMessagedata(state,acton:PayloadAction<any>){},
        outputCsvdata(state, action: PayloadAction<MCAXS111initRequest>){
            return state
        },
        setMCAXS111Open(state, action: PayloadAction<boolean>){
            state.mCAXS111Open = action.payload
            return state
        },
        openModel(state,action:PayloadAction<string>){
            state.snackBarForCheckData.modalMessage=action.payload;
            state.snackBarForCheckData.openCheckModal=true
        },
        openDialog(state,action:PayloadAction<snackBarForCheckData>){
            state.openDialogData = action.payload
            return state
        },
        closeModel(state){
            state.snackBarForCheckData=resetModal
            return state;
        },
        setMCAXS340Open(state, action: PayloadAction<boolean>){
            state.mCAXS340Open = action.payload
            return state
        },
        // [phase2] start by zhangxp
        mcbCheck(state, action: PayloadAction<MCBCS010DuplicateDataRequest>){  
        },
        // [phase2] end by zhangxp

        // MCBリプレース MCAXS260 start
        // 求職者IDリストを利用して、提出リクエストIDリストを取得し、画面に返却する
        setSubmissionRequestInfo(state, action: PayloadAction<submissionRequestInfo[]>){
            state.submissionRequestInfo = action.payload
            return state
        },
        // 提出データのCSV出力ボタン押下
        outputMcbCsvData(state, action: PayloadAction<MCAXS260OutputMcbCsvRequest>) { },
        // 提出データのPDF出力ボタン押下
        outputMcbPdfData(state, action: PayloadAction<MCAXS260OutputMcbPdfRequest>) { },
        // 未提出有チェックのダイアログ
        setSubmitCheckDialog(state, action: PayloadAction<boolean>) {
            state.submitCheckDialogFlag = action.payload
            return state
        },
        // 提出リクエストボタン押下
        sendMcbRequestMessageData(state, acton: PayloadAction<MCAXS260SendMcbRequestMessageRequest>) { },
        // メッセージ・締切変更・強制停止ボタン押下
        otherOperationOfMcbData(state, acton: PayloadAction<MCAXS260OtherOperationOfMcbRequest>) { },
        // MCBリプレース MCAXS260 end
    }
})
export const{
    init,
    setState,
    excludedata,
    restoredata,
    deletedata,
    outputPdfdata,
    mailSharedata,
    sendMessagedata,
    outputCsvdata,
    openModel,
    closeModel,
    openDialog,
    setMCAXS111Open,
    setMCAXS340Open,
    mcbCheck,
    // MCBリプレース MCAXS260 start
    setSubmissionRequestInfo,
    outputMcbCsvData,
    outputMcbPdfData,
    setSubmitCheckDialog,
    sendMcbRequestMessageData,
    otherOperationOfMcbData,
    // MCBリプレース MCAXS260 end
}=changeOthersSlice.actions
export default changeOthersSlice.reducer