import { call, put, all, takeEvery, select, takeLeading } from 'redux-saga/effects'
import {
  updateSignSortOrder, getSignList, getSignListSuccess,
  setSignCreate, getSignById, updateSign, deleteSign, setSign,
  setUpdateDialogOpen, setCreateDialogOpen, checkSignCount,
} from 'reducers/signReducer'
import { updateRequest, getSignListRequest, checkSignCountRequest } from 'apis/MCANS010Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { signIns } from 'apis/MCANS020Api'
import { getSignByIdApi, updateSignApi, deleteSignApi } from 'apis/MCANS030Api'
import { magiContants } from 'utils/contants'

// -----------------MCANS0I0_署名一覧_START------------------------//
export function* getSignListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    const data = yield call(getSignListRequest, recruitmentManagementDivision);
    yield put(getSignListSuccess(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* checkSignCountSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    yield call(checkSignCountRequest, recruitmentManagementDivision)
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateSaga(action: ReturnType<typeof updateSignSortOrder>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.map((item) => (
      item.recruitmentManagementDivision = recruitmentManagementDivision
    ))
    yield call(updateRequest, action.payload);
    yield getSignListSaga();
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCANS0I0_署名一覧_END------------------------//

// -----------------MCANS020_署名登録_START------------------------//
function* setSignSaga(action: ReturnType<typeof setSignCreate>) {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    action.payload.recruitmentManagementDivision = recruitmentManagementDivision;
    const message = yield call(signIns, action.payload);
    yield getSignListSaga();
    yield put(setCreateDialogOpen(false))
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// -----------------MCANS020_署名登録_END------------------------//

// -----------------MCANS030_署名編集_START------------------------//
function* getSignBySettingId(action: ReturnType<typeof getSignById>) {
  try {
    const data = yield call(getSignByIdApi, action.payload);
    yield put(setUpdateDialogOpen(true));
    yield put(setSign(data));
  } catch (error) {
    if (error.message === magiContants.MESSAGECODE_RESULT_NULL) {
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL));
    } else {
      yield put(openModal(error.message));
    }
  }
}

function* signUpdate(action: ReturnType<typeof updateSign>) {
  try {
    const message = yield call(updateSignApi, action.payload);
    yield getSignListSaga();
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}

function* signDelete(action: ReturnType<typeof deleteSign>) {
  const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
  try {
    const message = yield call(deleteSignApi, action.payload);
    const data = yield call(getSignListRequest, recruitmentManagementDivision);
    yield put(getSignListSuccess(data));
    yield put(setUpdateDialogOpen(false));
    yield put(openSnackbar(message));
  } catch (error) {
    yield put(openModal(error.message));
  }
}
// -----------------MCANS030_署名編集_END------------------------//

export default function* signListSaga() {
  yield all([
    // -----------------MCANS0I0_署名一覧_START------------------------//
    takeEvery(updateSignSortOrder, updateSaga),
    takeEvery(checkSignCount, checkSignCountSaga),
    takeEvery(getSignList, getSignListSaga),
    // -----------------MCANS0I0_署名一覧_END------------------------//

    // -----------------MCANS020_署名登録_START------------------------//
    takeLeading(setSignCreate, setSignSaga),
    // -----------------MCANS020_署名登録_END------------------------//

    // -----------------MCANS030_署名編集_START------------------------//
    takeEvery(getSignById, getSignBySettingId),
    takeLeading(updateSign, signUpdate),
    takeLeading(deleteSign, signDelete),
    // -----------------MCANS030_署名編集_END------------------------//
  ])
}
