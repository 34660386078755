import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  divider: {
    marginTop: theme.spacing(2),
     backgroundColor: 'lightgrey',
  },
}));
interface Props {
    flag?: boolean;
}
const SubTitle: React.FC<Props> = ({ children, flag}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        {children}
      </Typography>
        {
            flag?'':<Divider className={classes.divider} />
        }
    </div>
  );
};

export default SubTitle;
