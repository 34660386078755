import { MCAXS670initRequest } from 'types/MCAXS670/MCAXS670initRequest'
import { MCAXS670mediaAdditionalRequest } from 'types/MCAXS670/MCAXS670mediaAdditionalRequest'
import { MCAXS670mediaChangeRequest } from 'types/MCAXS670/MCAXS670mediaChangeRequest'
import request from 'utils/request'


export const getInitInfoApi = (getInitInfo: MCAXS670initRequest) =>
  request({
    url: `MCAXS670/init`,
    method: 'post',
    data: getInitInfo,
  })
  

  export const addMediaAdditionalApi = (addMediaAdditional: MCAXS670mediaAdditionalRequest) =>
  request({
    url: '/MCAXS670/additional',
    method: 'post',
    data: addMediaAdditional,
  })
  
  export const updatemediaChangeApi = (updatemediaChange: MCAXS670mediaChangeRequest) =>
  request({
    url: '/MCAXS670/change',
    method: 'post',
    data: updatemediaChange,
  })

