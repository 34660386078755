import React from 'react'
import { FieldProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useErrorStyle } from 'componentsHsc'

export interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  optionList: Option[]
  className?: string
  row?: boolean
  disabled?: boolean
  myChange: Function
  formName?: string
  target?: string
  targetIndex?: number
  selectOptionList?: Option[]
}

const UncheckableToggle = ({
  field,
  form,
  optionList,
  className,
  row,
  disabled,
  myChange,
  formName,
  target,
  targetIndex,
  selectOptionList,
}: Props) => {
  const classes = useErrorStyle()
  const { errors, touched } = form
  const { name } = field
  const value = field.value || '0'
  const selectOptionName = name.split('.')[0] + '.managementItemFlagCheckBoxObj'

  const handleChange = (e: any) => {
    if(e.target.value !== undefined){
      switch (target) {
        case 'answerCondition':
        case 'managementItemPresence':
          if (handleRadioCheck(e.target.value)) {
            form.setFieldValue(name, '')
            if (undefined != selectOptionList && e.target.value === '0') {
              form.setFieldValue(selectOptionName, [])
            }
          } else {
            form.setFieldValue(name, e.target.value)
            if (undefined != selectOptionList && e.target.value === '0') {
              form.setFieldValue(selectOptionName, selectOptionList)
            }
          }
          break
        default:
          form.setFieldValue(name, e.target.value)
          break
      }
    }
  }

  const handleRadioCheck = (value: string) => {
    if(value !== undefined){
      let result = undefined
      switch (target) {
        case 'answerCondition':
          if (targetIndex !== undefined) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex]
                .answerConditionFlag === value
          }
          break
        case 'managementItemPresence':
          if (targetIndex !== undefined) {
            result =
              form.values.conditionList.length > 0 &&
              form.values['conditionList'][targetIndex]
                .managementItemPresenceFlag === value
          }
          break
        default:
          break
      }
      return result
    }
  }

  return (
    <FormControl
      error={Boolean(errors[name] && touched[name])}
      className={className}>
      <RadioGroup row={row} value={value} onClick={handleChange}>
        {optionList.map(i => (
          <FormControlLabel
            key={i.value}
            control={
              <Radio
                color='primary'
                value={i.value}
                disabled={disabled}
                checked={handleRadioCheck(i.value)}
                name={i.label}
              />
            }
            label={i.label}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default UncheckableToggle
