import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useErrorStyle } from 'components'
import { FieldProps } from 'formik'
import React from 'react'

interface Props extends FieldProps {
  label: string
  optionList: {
    label: string
    value: string | number
  }[]
  pageController:any
}

const Toggle = ({ field, form, label, pageController,optionList, ...others}: Props) => {
  const classes = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form
  const handleChange = (e: any) => {
      if(e.target.value=="0"){
      if(pageController!=null){
          pageController(e.target.value);
          form.setFieldValue("notApplicableSetting","0");
          form.setFieldValue("selectionFlowSettingId",{value:-1,label:"（以下から選択）"});
          form.setFieldValue("progressStatus",{value:-1,label:"（以下から選択）"});
      }
    }
    form.setFieldValue(name, Number.parseInt(e.target.value))
  
  }
  return (
    <FormControl error={Boolean(errors[name] && touched[name])}>
      <RadioGroup row value={value} onChange={handleChange} {...others}>
        {optionList.map(i => (
          <FormControlLabel 
            control={<Radio color='primary' value={i.value} />}
            label={i.label}
            key={i.value}
          />
        ))}
      </RadioGroup>
      {errors[name] && touched[name] ? (
        <p className={classes.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}

export default Toggle