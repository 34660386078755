import request from 'utils/request'
import { MCARS030InitRequest } from 'types/MCARS030/MCARS030InitRequest'
import { MCARS030CreateRequest } from 'types/MCARS030/MCARS030CreateRequest'
import { MCARS030CreateRequestEmployment } from 'types/MCARS030/MCARS030CreateRequestEmployment'
// 次期開発9月#51248 start
import { MCARS030ChangeSchCriToActionRequest } from 'types/MCARS030/MCARS030ChangeSchCriToActionRequest'
// 次期開発9月#51248 end

export const initRequest = (selectCriteria: MCARS030InitRequest) =>
    request({
        url: 'MCARS030/init',
        method: 'post',
        data: selectCriteria,
    })

export const updateRequest = (selectCriteria: MCARS030CreateRequest) =>
    request({
        url: `MCARS030/okkizon`,
        method: 'post',
        data: selectCriteria,
    })
    
export const createRequest = (selectCriteria: MCARS030CreateRequestEmployment) =>
    request({
        url: `MCARS030/oksinki`,
        method: 'post',
        data: selectCriteria,
    })

export const checkRequest = (selectCriteria: MCARS030CreateRequest) =>
    request({
        url: `MCARS030/okcheck`,
        method: 'post',
        data: selectCriteria,
    })

// 次期開発9月#51248 start
export const selectUnActionSearchCriteriaNameRequest = (selectCriteria: string) =>
    request({
        url: `MCARS030/selectUnActionSearchCriteriaName`,
        method: 'post',
        data: selectCriteria,
    })

export const changeSearchCriteriaToActionRequest = (selectCriteria: MCARS030ChangeSchCriToActionRequest) =>
    request({
        url: `MCARS030/changeSearchCriteriaToAction`,
        method: 'POST',
        data: selectCriteria
    })
// 次期開発9月#51248 end
