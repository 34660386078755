import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import momentTz from 'moment-timezone'
import * as apiConfig from 'pages/MCBHS090/apiConfig'
import * as formConfig from 'pages/MCBHS090/formConfig'
import * as viewConfig from 'pages/MCBHS090/viewConig'
import { MCBHS090Request } from 'types/MCBHS090/MCBHS090Request'
import { MCBHS090DraftRequest } from 'types/MCBHS090/MCBHS090DraftRequest'
import { MCBHS090InitRequest } from 'types/MCBHS090/MCBHS090InitRequest'
import { MCBHS090PreviewRequest } from 'types/MCBHS090/MCBHS090PreviewRequest'
import { MCBHS090DestinationDownloadRequest } from 'types/MCBHS090/MCBHS090DestinationDownloadRequest'
import { isEmpty } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
momentTz.tz.setDefault('Asia/Tokyo')

const screenDisplayItems: viewConfig.ScreenDisplayItems = {
  messageInput: viewConfig.messageInput,
  sendTargetConfirmation: viewConfig.sendTargetConfirmation,
  sendTimeInput: viewConfig.sendTimeInput,
  signature: viewConfig.signature,
}

const initialState: {
  messageInitRequest: MCBHS090InitRequest
  initResult: apiConfig.InitResult
  businessCheckResult: boolean
  screenDisplayItems: viewConfig.ScreenDisplayItems
  confirmRequest: MCBHS090Request
  draftRequest: MCBHS090DraftRequest
  registerValue: viewConfig.RegisterValue
  hasCloseWindow: boolean
  hasCloseWindowByDraft: boolean
  runConfirm: boolean
  openConfirm: boolean
  isInitialized: boolean
  alertMessageList: string[]
  previewDataList: apiConfig.PreviewResult[]
  subjectText: string
  changedBody: string
} = {
  messageInitRequest: apiConfig.messageInitRequest,
  initResult: apiConfig.initResult,
  businessCheckResult: true,
  screenDisplayItems: screenDisplayItems,
  confirmRequest: apiConfig.confirmRequest,
  draftRequest: apiConfig.draftRequest,
  registerValue: viewConfig.registerValue,
  hasCloseWindow: false,
  hasCloseWindowByDraft: false,
  runConfirm: false,
  openConfirm: false,
  isInitialized: false,
  alertMessageList: [],
  previewDataList: [],
  subjectText: '',
  changedBody: '',
}

const submissionRequestForcedStopSlice = createSlice({
  name: 'submissionRequestForcedStop',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCBHS090InitRequest>) {
      state.hasCloseWindow = true
      const initRequest = action.payload
      state.messageInitRequest = initRequest
      state.messageInitRequest.sysVersionNumber = initRequest.sysVersionNumber
      state.messageInitRequest.draftMessageSendID =
        initRequest.draftMessageSendID
      return state
    },
    setInit(state, action: PayloadAction<apiConfig.InitResult>) {
      state.hasCloseWindow = false
      state.initResult = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setScreenDisplayItems(state) {
      // 初期値設定
      const data: apiConfig.InitResult = state.initResult
      // 送信先 3：My CareerBox
      state.screenDisplayItems.sendTargetConfirmation.destinationSelection =
        magiContants.DESTINATION_DIVISION_MYCAREERBOX
      // 検索条件名
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName =
        data.searchCriteriaName
      // 検索条件
      state.screenDisplayItems.sendTargetConfirmation.searchCriteria =
        data.searchCriteria
      // 送信対象
      let destinationInfoList: viewConfig.SendTarget[] = []
      let entryIdList: string[] = []
      if (data.destinationInfo) {
        data.destinationInfo.map(target => {
          const destinationInfo: viewConfig.SendTarget = viewConfig.sendTarget
          // エントリーID
          destinationInfo.entryId = target.entryId
          // 氏名
          destinationInfo.fullName = target.familyName + ' ' + target.name
          // メールアドレス
          destinationInfo.mailAddress = target.mailAddress
          // #MCB1.5次開発 #9168 START
          // 応募者管理ID
          destinationInfo.jobSeekerIdForDisplay = target.jobSeekerIdForDisplay
          // #MCB1.5次開発 #9168 END
          // #MCB1.5次開発 #8997 START
          destinationInfo.readableFlag = target.readableFlag
		      // #MCB1.5次開発 #8997 END
          // 会員種別
          const memberInfo = target.unsubscribedJobSeekerId
            ? magiContants.MEMBERS_TYPE_DEACTIVATED
            : target.memberType
          destinationInfo.mynaviMemberInfo = memberInfo
          // My CareerBox利用状況
          destinationInfo.mcbUseStatusName =
            target.mcbUseStatus === magiContants.MCBUSE_STATUS_1 ||
            target.mcbUseStatus === magiContants.MCBUSE_STATUS_2
              ? magiContants.MCBUSE_STATUS_NAME_1
              : magiContants.EMPTY
          // 学校
          destinationInfo.schoolName = target.schoolName
          //学部
          destinationInfo.facultyName = target.facultyName
          //学科
          destinationInfo.subjectName = target.subjectName
          // 応募日時
          const applicationDate: string | null = momentTz(
            target.entryReceptionTime
          ).format('YYYY/MM/DD HH:mm')
          destinationInfo.applicationDate = applicationDate
            ? applicationDate
            : '-'
          destinationInfo.viewEndTime = target.entryReceptionTime
          const newDestinationInfo = JSON.parse(JSON.stringify(destinationInfo))
          destinationInfoList = [...destinationInfoList, newDestinationInfo]
          entryIdList = [...entryIdList, target.entryId]
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = destinationInfoList
      // mcb送信対象
      state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination =
        data.mcbDestinationInfo
      if (data.submissionContent) {
        // 提出物内容名（企業管理用）
        state.screenDisplayItems.messageInput.submissionsNameForCompany =
          data.submissionContent.submissionContentName
        // 提出物内容名（学生画面表示用）
        state.screenDisplayItems.messageInput.submissionsNameForStudent =
          data.submissionContent.submissionContentNameStudent
        // 提出物
        state.screenDisplayItems.messageInput.submissionsDetails = formConfig.submissionsDetailsList
          .filter(item => data.submissionContent[item[0]] === 1)
          .reduce(
            (preResult, item) =>
              isEmpty(preResult) ? item[1] : `${preResult}、${item[1]}`,
            ''
          )
      }
      // 送信元会社名
      state.screenDisplayItems.messageInput.senderCompanyName = data.senderCompanyName
        ? data.senderCompanyName
        : ''
      // 送信元メールアドレス
      state.screenDisplayItems.messageInput.senderMailAddress = data.senderMailAddress
        ? data.senderMailAddress
        : ''
      // 署名
      state.screenDisplayItems.signature = data.signature
      // 件名
      state.screenDisplayItems.messageInput.requestSubject = data.subject
      // 本文
      state.screenDisplayItems.messageInput.requestText = data.body
      // 目標初期値
      state.screenDisplayItems.messageInput.targetInput = 'subject'
      // 送信日時 すぐ送信：0
      state.screenDisplayItems.sendTimeInput.transmission =
        magiContants.SEND_TIME_FLAG_IMMEDIATE

      state.isInitialized = true
      return state
    },
    getPreview(
      state,
      action: PayloadAction<{
        formData: MCBHS090PreviewRequest
        setHasDisplayPreview: (value: boolean) => void
      }>
    ) {
      state.previewDataList = []
      return state
    },
    setPreview(state, action: PayloadAction<apiConfig.PreviewResult[]>) {
      state.previewDataList = action.payload
      return state
    },
    inputSenderCompanyName(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderCompanyName = replacedValue
      return state
    },
    inputSenderMailAddress(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderMailAddress = replacedValue
      return state
    },
    inputSubject(
      state,
      action: PayloadAction<{ target: string; value: string }>
    ) {
      let target = action.payload.target
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.requestSubject = replacedValue
      return state
    },
    inputBody(state, action: PayloadAction<{ target: string; value: string }>) {
      let target = action.payload.target
      let value = action.payload.value
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.requestText = value
      return state
    },
    changeTargetInput(state, action: PayloadAction<{ target: string }>) {
      let target = action.payload.target
      state.screenDisplayItems.messageInput.targetInput = target
      return state
    },
    deleteSendTarget(state, action: PayloadAction<{ id: string }>) {
      const entryId = action.payload.id
      const sendTargetList =
        state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let newSendTargetList: viewConfig.SendTarget[] = []
      if (sendTargetList) {
        sendTargetList.map(item => {
          if (item.entryId !== entryId) {
            newSendTargetList = [...newSendTargetList, item]
          }
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = newSendTargetList

      const mcbMessageDestinationList =
        state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination
      let newMcbSendTargetList: viewConfig.McbSendTarget[] = []
      if (mcbMessageDestinationList) {
        mcbMessageDestinationList.map(item => {
          if (item.entryId != entryId) {
            newMcbSendTargetList = [...newMcbSendTargetList, item]
          }
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination = newMcbSendTargetList
      return state
    },
    setDraftReqeust(state) {
      const sendTarget =
        state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let entryIdList: string[] = []
      if (sendTarget) {
        sendTarget.map(item => {
          entryIdList = [...entryIdList, item.entryId]
        })
      }
      // 送信対象ID
      state.draftRequest.destinationId = entryIdList
      // 検索条件名
      state.draftRequest.searchCriteriaName =
        state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName
      // 9174 start
      // 検索条件
      state.draftRequest.searchCriteria = state.initResult.searchCriteria
      // 検索条件(json)
      state.draftRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
      // 9174 end
      // 送信元会社名
      state.draftRequest.senderCompanyName =
        state.screenDisplayItems.messageInput.senderCompanyName
      // 送信元メールアドレス
      state.draftRequest.senderMailAddress =
        state.screenDisplayItems.messageInput.senderMailAddress
      // 件名
      state.draftRequest.requestSubject =
        state.screenDisplayItems.messageInput.requestSubject
      // 本文
      state.draftRequest.requestText =
        state.screenDisplayItems.messageInput.requestText
      // タイマー送信するフラグ
      state.draftRequest.sendTimerFlag = 0
      // sysバージョン番号
      state.draftRequest.sysVersionNumber = state.initResult.sysVersionNumber
      // MCBメッセージ詳細DTOリスト
      state.draftRequest.messageUtilMcbMessagesDetailDtoList =
        state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination
      // 編集元下書きメッセージ送信ID
      state.draftRequest.sourceDraftMessageSendId = state.messageInitRequest
        .draftMessageSendID
        ? String(state.messageInitRequest.draftMessageSendID)
        : ''
    },
    saveDraft(
      state,
      action: PayloadAction<{
        formData: MCBHS090DraftRequest
      }>
    ) {
      return state
    },
    setConfirmRequest(state) {
      const confirmRequest: MCBHS090Request = {
        destinationId: [],
        searchCriteriaName: '',
        searchCriteria: '',
        submissionsSelectId: null,
        submissionsNameForCompany: '',
        submissionsNameForStudent: '',
        submissionsDetails: '',
        senderCompanyName: '',
        senderMailAddress: '',
        requestSubject: '',
        requestText: '',
        sendTimerFlag: 0,
        messageUtilMcbMessagesDetailDtoList: [],
      }

      const registerValue: viewConfig.RegisterValue = {
        destinationId: [],
        submissionsSelectId: '0',
        submissionsNameForCompany: '',
        submissionsNameForStudent: '',
        submissionsDetails: '',
        senderCompanyName: '',
        senderMailAddress: '',
        subject: '',
        body: '',
        sysVersionNumber: '',
        messageSendId: '',
        messageInput: viewConfig.messageInput,
        sendTargetConfirmation: viewConfig.sendTargetConfirmation,
        sendTimeInput: viewConfig.sendTimeInput,
        searchCriteriaJson: '',
      }
      // 送信対象ID
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const destinationId: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.entryId
        )
        confirmRequest.destinationId = destinationId
        registerValue.destinationId = destinationId
      }
      // 検索条件名
      confirmRequest.searchCriteriaName =
        state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName
      // 検索条件
      confirmRequest.searchCriteria = state.initResult.searchCriteriaJson
      // 提出物内容選択ID
      if (state.initResult.submissionContent) {
        confirmRequest.submissionsSelectId =
          state.initResult.submissionContent.submissionContentId
        registerValue.submissionsSelectId =
          state.initResult.submissionContent.submissionContentId
      }
      // 提出物名(企業管理用)
      confirmRequest.submissionsNameForCompany =
        state.screenDisplayItems.messageInput.submissionsNameForCompany
      registerValue.submissionsNameForCompany =
        state.screenDisplayItems.messageInput.submissionsNameForCompany
      // 提出物名(学生画面用)
      confirmRequest.submissionsNameForStudent =
        state.screenDisplayItems.messageInput.submissionsNameForStudent
      registerValue.submissionsNameForStudent =
        state.screenDisplayItems.messageInput.submissionsNameForStudent
      // 提出物
      confirmRequest.submissionsDetails =
        state.screenDisplayItems.messageInput.submissionsDetails
      registerValue.submissionsDetails =
        state.screenDisplayItems.messageInput.submissionsDetails
      // 送信元会社名
      const senderCompanyName =
        state.screenDisplayItems.messageInput.senderCompanyName
      confirmRequest.senderCompanyName = senderCompanyName
      registerValue.senderCompanyName = senderCompanyName

      // 送信元メールアドレス
      const senderMailAddress =
        state.screenDisplayItems.messageInput.senderMailAddress
      confirmRequest.senderMailAddress = senderMailAddress
      registerValue.senderMailAddress = senderMailAddress
      // 件名
      const subject = state.screenDisplayItems.messageInput.requestSubject
      confirmRequest.requestSubject = subject
      registerValue.subject = subject
      // 本文
      const body = state.screenDisplayItems.messageInput.requestText
      confirmRequest.requestText = body
      registerValue.body = body

      // タイマー送信するフラグ
      const sendTimeInput = state.screenDisplayItems.sendTimeInput
      confirmRequest.sendTimerFlag = Number(sendTimeInput.transmission)
      registerValue.sendTimeInput = sendTimeInput

      // MCBメッセージ詳細DTOリスト
      confirmRequest.messageUtilMcbMessagesDetailDtoList =
        state.screenDisplayItems.sendTargetConfirmation.mcbMessageDestination

      // sysバージョン番号
      registerValue.sysVersionNumber = state.messageInitRequest.sysVersionNumber
        ? String(state.messageInitRequest.sysVersionNumber)
        : ''
      // 下書きメッセージ送信ID
      registerValue.messageSendId = state.messageInitRequest.draftMessageSendID
      // メッセージ入力エリア
      registerValue.messageInput = state.screenDisplayItems.messageInput
      // 送信対象確認エリア
      registerValue.sendTargetConfirmation =
        state.screenDisplayItems.sendTargetConfirmation
      // 検索条件json
      registerValue.searchCriteriaJson = state.initResult.searchCriteriaJson

      state.confirmRequest = confirmRequest
      state.registerValue = registerValue

      state.runConfirm = true
      return state
    },
    confirm(
      state,
      action: PayloadAction<{
        formData: MCBHS090Request
        forbiddenWords: string[]
        sendReplyImpossibleTime: apiConfig.SendReplyImpossibleTime[]
        sendTargetConfirmation: viewConfig.SendTarget[]
      }>
    ) {
      return state
    },
    changeConfirmState(state, action: PayloadAction<boolean>) {
      state.runConfirm = action.payload
      return state
    },
    openConfirmModal(state) {
      state.openConfirm = true
      return state
    },
    closeConfirmModal(state) {
      state.openConfirm = false
      return state
    },
    setCloseWindow(state, action: PayloadAction<boolean>) {
      state.hasCloseWindowByDraft = action.payload
      return state
    },
    setSubjectText(state, action: PayloadAction<string>) {
      state.subjectText = action.payload
      return state
    },
    destinationDownload(
      state,
      action: PayloadAction<MCBHS090DestinationDownloadRequest>
    ) {
      return state
    },
    openDetailDialog(
      state,
      action: PayloadAction<viewConfig.SearchCriteriaInfo>
    ) {
      return state
    },
    setChangedBody(state,action: PayloadAction<string>){
      state.changedBody = action.payload
      return state
    }
  },
})

export const {
  getInit,
  setInit,
  setCode,
  setScreenDisplayItems,
  getPreview,
  setPreview,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubject,
  inputBody,
  changeTargetInput,
  deleteSendTarget,
  setDraftReqeust,
  saveDraft,
  setConfirmRequest,
  confirm,
  changeConfirmState,
  openConfirmModal,
  closeConfirmModal,
  setCloseWindow,
  setSubjectText,
  destinationDownload,
  openDetailDialog,
  setChangedBody,
} = submissionRequestForcedStopSlice.actions
export default submissionRequestForcedStopSlice.reducer
