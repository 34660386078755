import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import Select, {components as componentsML, MenuListComponentProps } from 'react-select'
import { useErrorStyle } from 'components'

export interface OptionType {
  label: string
  value: string
  sysVersionNumber: string
  sysVersionNumber1: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      fontSize: '11px',
      marginTop: '4px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily:
        'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      lineHeight: '1em',
      letterSpacing: '0.33px',
      color: '#e53935',
      whiteSpace: 'pre-wrap',
    },
    control: {
      marginBottom: '10px',
      width: '267px',
    },
  })
)

function MenuList (props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList, 
}

interface Props extends FieldProps {
  options: OptionType[],
  initId: string,
}
export function IntegrationReactSelect({ field, form, initId, options }: Props) {
  const { errors, touched } = form
  const { name, value } = field
  const classes = useStyles()
  const errorclasses = useErrorStyle()
  const optionZero = { label: '（以下から選択）', value: '', sysVersionNumber: '', sysVersionNumber1: '' } as OptionType
  const optionList = [optionZero, ...options]
  const [menuValue, setMenuValue] = useState(optionZero)
  const selectStyles = {
    control: (provided: any) => ({ 
      ...provided, 
      minHeight: '30px',
      cursor:'pointer',
    }),
    input: (provided: any) => ({ 
      ...provided, 
      margin: '0px',
      paddingBottom: '0px',
      paddingTop: '0px',
      '& input': {
        height: '20px',
        lineHeight: '18px',
      },
    }),
    indicatorsContainer: (provided: any) => ({ 
      ...provided, 
      height: '30px',
    }),
    option: (provided: any) => ({ 
      ...provided, 
      whiteSpace: 'nowrap',
      lineHeight: '10px', 
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1400 })
  }

  useEffect(() => {
    if (initId) {
      form.setFieldValue(name, initId)
      form.setFieldValue('internalId', initId)
      options.find(item => {
        if (item.value == initId) {
          form.setFieldValue('nameCheck', item.label)
          form.setFieldValue('sysVersionNumber', item.sysVersionNumber)
          form.setFieldValue('sysVersionNumber1', item.sysVersionNumber1)
        }
        return item.value == initId
      })
    }
  }, [options])

  const handleChange = (e: any) => {
    if (e.value !== value) {
      if (e.value) {
        options.find(item => {
          if (item.value == e.value) {
            form.setFieldValue('nameCheck', item.label)
            form.setFieldValue('sysVersionNumber', item.sysVersionNumber)
            form.setFieldValue('sysVersionNumber1', item.sysVersionNumber1)
          }
          return item.value == e.value
        })
        form.setFieldValue(name, e.value)
        form.setFieldValue('internalId', e.value)
      } else {
        form.setFieldValue(name, '')
        form.setFieldValue('nameCheck', '')
        form.setFieldValue('internalId', '')
        form.setFieldValue('sysVersionNumber', '')
        form.setFieldValue('sysVersionNumber1', '')
      }
    }
  }

  return (
    
    <FormControl className={classes.control}>
    <div>
      <Select 
        classes={classes}
        styles={selectStyles}
        options={optionList}
        components={components}
        isClearable={false}
        value={optionList.filter(i => i.value == value)}
        defaultValue={optionList.filter(i => i.value == value)}
        placeholder={''}
        onChange={handleChange}
        isSearchable={true}
        filterOption={customSelectOptions.filterOption}
        noOptionsMessage={({ inputValue }) => {
          return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
        }}
        menuPlacement={'auto'}
        // 次期開発9月#51248 start
        isDisabled={form.values.selectRadio === '1' || form.values.selectRadio === '2' ? true : false}
        // 次期開発9月#51248 end
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
    </div>
      {errors[name] && touched[name] ? (
        <p className={errorclasses.helperText}>{errors[name]}</p>
      ) : null}
    </FormControl>
  )
}
