/** 画面表示項目 */
export interface ScreenDisplayItems {
  recruitmentManagementDivision: string
  title: Title
  interviewSchedule: InterviewSchedule
  messageInput: MessageInput  
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  notification: Notification
  recruitmentManagementFlag: RecruitmentManagementFlag[]
  seminarSchedule: SeminarSchedule[]
  seminar: Seminar[]
  signature: Signature[]
  hiddenItems: HiddenItems
}

/** タイトルエリア */
export interface Title {
  [key: string]: any
  selectedTemplateSettingId: string
  messageTemplate: MessageTemplate[]
}

export interface MessageTemplate {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  sendToNonMember: number
  senderCompanyName: string
  senderMailAddress: string
  replayPermitFlag: string
  subject: string
  body: string
  reservedScheduleSendFlag: string
  barcordDisplayFlag: string
  sequence: number
  replyNecessaryFlag: string // 要返信フラグ
  replyLimitTime: string // 返信期限
  sysVersionNumber: string // sysバージョン番号
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  [key: string]: any
  destinationSelection: string
  sendTarget: SendTarget[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: SearchCriteriaInfo
  mySearchCondition: string| null
  conversionMySearch: MySearch[]
  filterCondition: string
  // phase2 start
  sendToNonMember: number
  // phase2 end
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}

export interface MySearch {
  mySearchName: string //MY検索条件名
  mySearchCriteria: string //MY検索条件
}

/** 送信対象 */
export interface SendTarget {
  [key: string]: any
  entryId: string
  selectionManagementId: string
  progressStatusSettingId: string
  decisionDivision: string
  manualRegistrationFlag: string
  fullName: string
  mailAddress: string
  age: number
  jobSeekerId: string
  mynaviMemberInfo: string
  applicationType: string
  entryType: string
  viewStartTime: Date
  viewEndTime: Date
  jobTitle: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  progress: string
  progressType: string
  selectionFlowSettingId: number
  selectionClassification: string
  selectionFlow: string
  invisibleDestination: string
  deleteDestination: string
  notSend: boolean
  applicantScreeningImmunityFlag: string
  sentMessageCount: number
   // phase2 start
   sendToNonMember: number
   // phase2 end
   readableFlag: string
   senderMessageId: number | null
}

/** 面接日程調整エリア */
export interface InterviewSchedule {
  [key: string]: any
  action: string
  manipulationSelection: number
  manipulationKubun: string
  interviewDetail: string
  requiredTimeId: string
  interviewDurationSelect: InterviewDurationSelect[]
  interviewLocation: InterviewLocation[]
  candidateDate: CandidateDate[]
  sysVersionNumber: number | null
  shouldShowInterviewSchedule: boolean
}

export interface InterviewDurationSelect {
  [key: string]: any
  interviewDurationId: string
  actualTime: string
  displayTime: string
}

export interface InterviewLocation {
  [key: string]: any
  id: number
  name: string
  address: string
  mapUrl: string
  type: string
  deleteFlag: boolean
}

export interface CandidateDate {
  [key: string]: any
  interviewStartTime: string
  interviewLocationId: string
  interviewStaffName: string
}

/** メッセージ入力エリア */
export interface MessageInput {
  [key: string]: any
  headingMessageInput: string
  headingMessageInputDescription: string
  exampleLink: string
  replyAllow: string
  repliesNecessary: string
  repliesLimit: string | null
  sendToNonMember: number
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: Attachment[]
  entryBox: MynaviAttachedOptionEntryBox[]
  seminar: MynaviAttachedOptionSeminar[]
  entryBoxId: string[]
  seminarId: string[]
  homePage: HomePage[]
  barcodeDisplay: string
  previewSubject: string
  previewBody: string
  shouldShowMessageInput: ShouldShowMessageInput  
  sysVersionNumber: string // sysバージョン番号
}

export interface ShouldShowMessageInput {
  [key: string]: boolean
  replyAllow: boolean
  repliesNecessary: boolean
  repliesLimit: boolean
  senderCompanyName: boolean
  senderMailAddress: boolean
  subject: boolean
  body: boolean
  attachment: boolean
  entryBox: boolean
  seminar: boolean
  homePage: boolean
  barcodeDisplay: boolean
}

/** 送信日時入力エリア */
export interface SendTimeInput {
  [key: string]: any
  transmission: string
  sendPlanTime: Date | null
}

/** お知らせ通知エリア */
export interface Notification {
  [key: string]: any
  notificationMailAddress: string
  notificationSelect: string
  shouldShowNotification: boolean
}

/** 非表示項目 */
export interface HiddenItems {
  [key: string]: any
  replyFromMessageSendId: string
  adjustmentInterviewScheduleAdjustmentId: number[]
  adjustmentInterviewScheduleId: number[]
}

export interface MynaviAttachedOptionEntryBox {
  entryBoxId: number
  mynaviAttachedOptionName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  previewUrl: string
}

export interface MynaviAttachedOptionSeminar {
  seminarId: number
  mynaviAttachedOptionName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  previewUrl: string
}

export interface Attachment {
  attachmentFileName: string
  attachmentId: string|null
  attachmentName: string
}

export interface HomePage {
  homePageTitle: string
  homePageUrl: string
}

export interface RecruitmentManagementFlag {
  [key: string]: any
  recruitmentManagementFlagSettingId: number
  recruitmentManagementFlagName: string
}

export interface SeminarSchedule {
  [key: string]: any
  seminarScheduleId: number
  seminarScheduleTitle: string	//セミナー日程タイトル
  eventDate: Date
  //[phase2] 開催日テキスト start
  eventDateText: string
  //[phase2] 開催日テキスト end
  venueLocationName: string
  startTime: Date
  endTime: Date
  coordinationFormerSeminarScheduleIdentificationKey: string
  invisibleFlag: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  // 備考
  remarks: string
  // 開催曜日
  eventWeek: string
  displayFlag: boolean
}
//[phase2] start
export interface Seminar {
  [key: string]: any
  seminarId: number
  coordinationFormerSeminarId: number
  seminarName: string
  publicFlag: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  seminarScheduleDisplay: string
  displayFlag: boolean
}
//[phase2] end
export interface Signature {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}

export interface MCAZS080InterviewScheduleAdjustmentMaxResultDto {
  [key: string]: any
  interviewScheduleAdjustmentId: number
  selectionManagementId: number
}

export interface RegisterValue {
  [key: string]: any
  messageType: string[]
  messageTemplateSettingId: string|null
  destinationId: string[]
  selectionManagementId: string[]
  memberType: string[]
  destination: number
  senderCompanyName: string
  senderMailAddress: string
  allowReply: number
  subject: string
  body: string
  attachment: any[]
  attachmentId: Array<string|null>
  templateAttachmentId: string[]
  templateAttachmentName: string[]
  mynaviAttachedOption: string
  homePageTitle1: string|null
  homePageTitle2: string|null
  homePageTitle3: string|null
  homePageUrl1: string|null
  homePageUrl2: string|null
  homePageUrl3: string|null
  barcodeDisplayFlag: number
  sendTimer: number
  sendPlanTime: string|null
  notifyNotification: number
  notifyMailAddress: string
  requiredTimeId: string
  interviewContents: string
  interviewDateN: (string|null)[]
  interviewLocationIdN: (number|null)[]
  chargeN: string[]
  fileManagementId: string[]
  fileManagementName: string[]
  entryId: number[]
  interviewScheduleAdjustmentId: number
  sysVersionNumber: string
  messageSendId: string
  replyFromMessageSendId: string | null
  interviewScheduleAdjustmentStatus: string
  interviewScheduleAdjustmentSelection: string
  adjustmentInterviewScheduleAdjustmentId: string
  interviewDurationId: string|null
  interviewDetail: string
  interviewScheduleId: number[]
  interviewStartTime: (string|null)[]
  interviewStaffName: string[]
  interviewScheduleStatus: string[]
  adjustmentInterviewDurationId: string|null
  adjustmentInterviewDetail: string
  adjustmentInterviewScheduleId: number[]
  adjustmentInterviewStartTime: (string|null)[]
  adjustmentInterviewStaffName: string[]
  adjustmentInterviewScheduleStatus: string[]
  newInterviewScheduleStatus: string[]
  adjustmentInterviewLocationId: (number|null)[]
  manipulationKubun: string
  seminarId: number[]
  entryBoxId: number[]
  sendTimeFlag: string
  replyPermitFlag: string
  replyNecessaryFlag: string
  replyLimitTime: string | null
  destinationDivision: string
  manualRegistrationFlag: string[]
  decisionDivision: string[]
  progressStatusSettingId: number[]
  notSend: boolean[]
  notificationMailAddress: string
  selectionFlowSettingId: number[]
  title: Title
  interviewSchedule: InterviewSchedule
  messageInput: MessageInput
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  recruitmentManagementFlag: RecruitmentManagementFlag[]
  seminarSchedule: SeminarSchedule[]
  seminar: Seminar[]
  signature: Signature[]
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  fullName: string
  //phase2 start
  sendToNonMember: number
  //phase2 end
  jobSeekerId:string[]
  interviewScheduleAdjustmentMax: MCAZS080InterviewScheduleAdjustmentMaxResultDto[]
  searchCriteriaJson: string
  selectionManagementSysVersionNumber: number | null
  progressManagementSysVersionNumber: number | null
  notAdoptedNotificationType: string | null
  interviewScheduleIdWeb: number
  interviewWebEnableFlag: string
  interviewWebUrlSendFlag: string
  mySearchCondition: string|null
  mySearchCriteriaNameJson: string[]
  mySearchCriteriaJson: string[]
  sysVersionNumberWeb: string
  senderMessageId: (number|null)[]
}

const title: Title = {
  selectedTemplateSettingId: '',
  messageTemplate: [],
}

const interviewSchedule: InterviewSchedule = {
  action: '',
  manipulationKubun: '',
  manipulationSelection: 0,
  interviewDetail: '',
  requiredTimeId: '',
  interviewDurationSelect: [],
  interviewLocation: [],
  candidateDate: [],
  sysVersionNumber: null,
  shouldShowInterviewSchedule: false,
}

const interviewDurationSelect: InterviewDurationSelect = {
  interviewDurationId: '',
  actualTime: '',
  displayTime: '',
}

const interviewLocation: InterviewLocation = {
  id: 0,
  name: '',
  address: '',
  mapUrl: '',
  type: '',
  deleteFlag: false,
}

const shouldShowMessageInput: ShouldShowMessageInput = {
  replyAllow: false,
  repliesNecessary: false,
  repliesLimit: false,
  senderCompanyName: false,
  senderMailAddress: false,
  subject: false,
  body: false,
  attachment: false,
  entryBox: false,
  seminar: false,
  homePage: false,
  barcodeDisplay: false,
}

const messageInput: MessageInput = {
  headingMessageInput: '',
  headingMessageInputDescription: '',
  exampleLink: '',
  replyAllow: '0',
  repliesNecessary: '0',
  repliesLimit: '',
  sendToNonMember: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  templateAttachmentId: [],
  entryBox: [],
  seminar: [],
  entryBoxId: [],
  seminarId: [],
  homePage: [],
  homePageTitle: [],
  homePageUrl: [],
  barcodeDisplay: '0',
  previewSubject: '',
  previewBody: '',
  shouldShowMessageInput: shouldShowMessageInput,
  sysVersionNumber: '',
}

const sendTimeInput: SendTimeInput = {
  transmission: '0',
  sendPlanTime: null,
}

const notification: Notification = {
  notificationMailAddress: '',
  notificationSelect: '0',
  shouldShowNotification: false,
}

const sendTarget: SendTarget = {
  entryId: '',
  selectionManagementId: '',
  progressStatusSettingId: '',
  decisionDivision: '',
  manualRegistrationFlag: '',
  fullName: '',
  mailAddress: '',
  age: 0,
  jobSeekerId: '',
  mynaviMemberInfo: '',
  applicationType: '',
  entryType: '',
  viewStartTime: new Date,
  viewEndTime: new Date,
  jobTitle: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  applicationDate: '',
  progress: '',
  progressType: '',
  selectionFlowSettingId: 0,
  selectionClassification: '',
  selectionFlow: '',
  invisibleDestination: '',
  deleteDestination: '',
  notSend: false,
  applicantScreeningImmunityFlag: '',
  sentMessageCount: 0,
  // phase2 start
  sendToNonMember: 0,
  // phase2 end
  readableFlag: '',
  senderMessageId: null,
}

const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}

const mySearch: MySearch = {
  mySearchName: '',
  mySearchCriteria: '',
}

const sendTargetConfirmation: SendTargetConfirmation = {
  destinationSelection: '0',
  sendTarget: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaInfo: searchCriteriaInfo,
  mySearchCondition: null,
  conversionMySearch: [],
  filterCondition: '',
  // phase2 start
  sendToNonMember: 0,
  // phase2 end
}

const hiddenItems: HiddenItems = {
  replyFromMessageSendId: '',
  adjustmentInterviewScheduleAdjustmentId: [],
  adjustmentInterviewScheduleId: [],
}

const recruitmentManagementFlag: RecruitmentManagementFlag[] = []

const seminarSchedule: SeminarSchedule[] = []

const seminar: Seminar[] = []

const signature: Signature[] = []

const registerValue: RegisterValue = {
  messageType: [],
  messageTemplateSettingId: '',
  destinationId: [],
  selectionManagementId: [],
  memberType: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  allowReply: 0,
  subject: '',
  body: '',
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  mynaviAttachedOption: '',
  homePageTitle1: null,
  homePageTitle2: null,
  homePageTitle3: null,
  homePageUrl1: null,
  homePageUrl2: null,
  homePageUrl3: null,
  barcodeDisplayFlag: 0,
  sendTimer: 0,
  sendPlanTime: null,
  notifyNotification: 0,
  notifyMailAddress: '',
  requiredTimeId: '',
  interviewContents: '',
  interviewDateN: [],
  interviewLocationIdN: [],
  chargeN: [],
  fileManagementId: [],
  fileManagementName: [],
  entryId: [],
  interviewScheduleAdjustmentId: 0,
  sysVersionNumber: '',
  messageSendId: '',
  replyFromMessageSendId: null,
  interviewScheduleAdjustmentStatus: '',
  interviewScheduleAdjustmentSelection: '',
  adjustmentInterviewScheduleAdjustmentId: '',
  interviewDurationId: null,
  interviewDetail: '',
  interviewScheduleId: [],
  interviewStartTime: [],
  interviewStaffName: [],
  interviewScheduleStatus: [],
  adjustmentInterviewDurationId: null,
  adjustmentInterviewDetail: '',
  adjustmentInterviewScheduleId: [],
  adjustmentInterviewStartTime: [],
  adjustmentInterviewStaffName: [],
  adjustmentInterviewScheduleStatus: [],
  newInterviewScheduleStatus: [],
  adjustmentInterviewLocationId: [],
  manipulationKubun: '',
  seminarId: [],
  entryBoxId: [],
  sendTimeFlag: '',
  replyPermitFlag: '',
  replyNecessaryFlag: '',
  replyLimitTime: '',
  destinationDivision: '',
  manualRegistrationFlag: [],
  decisionDivision: [],
  progressStatusSettingId: [],
  notSend: [],
  notificationMailAddress: '',
  selectionFlowSettingId: [],
  title: title,
  interviewSchedule: interviewSchedule,
  messageInput: messageInput,
  sendTargetConfirmation: sendTargetConfirmation,
  sendTimeInput: sendTimeInput,
  recruitmentManagementFlag: recruitmentManagementFlag,
  seminarSchedule: seminarSchedule,
  seminar: seminar,
  signature: signature,
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  fullName: '',
  //phase2 start
  sendToNonMember: 0,
  //phase2 end
  jobSeekerId:[],
  interviewScheduleAdjustmentMax: [],
  searchCriteriaJson: '',
  mySearchCondition: null,
  mySearchCriteriaNameJson: [],
  mySearchCriteriaJson: [],
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
  notAdoptedNotificationType: null,
  interviewScheduleIdWeb: 0,
  interviewWebEnableFlag: '',
  interviewWebUrlSendFlag:'',
  sysVersionNumberWeb:'',
  senderMessageId:[],
}

export {
  title,
  hiddenItems,
  interviewSchedule,
  interviewDurationSelect,
  interviewLocation,
  messageInput,
  shouldShowMessageInput,
  sendTimeInput,
  notification,
  sendTarget,
  sendTargetConfirmation,
  recruitmentManagementFlag,
  registerValue,
  seminarSchedule,
  seminar,
  signature
}
