import { call, put, all, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { RootState } from 'reducers'
import {
  searchSubmissionRequest,
  setSearchResult,
  setInitDisplay,
  initDisplayItems,
  setInitInfo,
  settingDisplayItems,
  setting,
  setMySearchResult,
  setSearchCondition,
  setSelectedSearch,
  setOpenFlag,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  setNeedUpdate,
  setSreachBtnFlg,
  setDisplayLimit,
  setCircleLoadingFlag,
  getActionPreList,
  setActionPreList,
  setCreateDialogOpen,
  getMySearch,
  search,
  setDisplaySearchCriteriaTitleOpenFlag,
  updateOnNewData,
  setOpenFlag as setAdvancedSearchOpen,
  setSelectedAccounts,
  updateOnSelectAll,
  setSelectAllStatus,
  getMcbhs161Init,
  getMcbhs161SubmissionClick,
  doOriginalMcbServiceCheck,
  pushMcbCheckDetails,
  doMcbhs211McbServiceCheck,  
} from 'reducers/submissionRequestListReducer'
import {
  setSearchConditionSelectInit,
  setOpen as setMcbAdvancedSearchOpen,
} from 'reducers/submissionRequestDetailedSearchReducer'
import { openModal } from 'reducers/messageReducer'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { searchRequest, pushMcbCheckDetailsRequest } from 'apis/MCBHS040Api'
import { initSetDateSagaRequest, settingRequest } from 'apis/MCBHS120Api'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import { getMCBHS141InitRequest } from 'apis/MCBHS141Api'
import { getMCBHS131InitRequest } from 'apis/MCBHS131Api'
import { getMCBHS151InitRequest } from 'apis/MCBHS151Api'
import { selectCriteriaRequest } from 'apis/MCBIS020Api'
import { getMySearchRequest } from 'apis/MCBHS040Api'
import { initSearchResult } from 'pages/MCBHS040/formConfig'
import { fromEntryList, toEntryList } from 'componentsHsc/AdvancedSearch/Config/searchMCBConditionConfig'
import { setFlag } from 'reducers/submissionRequestListReducer'
import { SearchCondition } from 'pages/MCBHS040/searchConditionConfig'
import { ApplicantBasicInfo } from 'pages/MCBHS040/DetailedSearch/Config/ApplicantBasicConfig'
import { MCBIS020InitRequest } from 'types/MCBIS020/MCBIS020InitRequest'
import { getMCBHS161InitRequest,getMcbhs161SubmissionClickRequestApi } from 'apis/MCBHS161Api'
import { magiContants } from 'utils/contants'
import { getMcbhs171Init } from 'reducers/submissionRequestDetailedSearchModalReducer'
import { getMcbhs211McbServiceCheckApi } from 'apis/MCBHS211Api'
import axios from 'axios'
import { popupParams } from 'utils/misc'
import { getToken } from 'utils/auth'


const updateSearchCondition = (
  searchCondition: string,
  mCBHS131Result: ApplicantBasicInfo,
  // mCBHS141Result: SubmissionRequestInfo,
  // mCBHS151Result: EntrySheetInfo,
) => {
  const sc: SearchCondition = JSON.parse(searchCondition)
  const newSearchCondition = {
    ...sc,
  }

  return newSearchCondition
}

function* initDisplayItemsSaga() {
  try {
    const data = yield call(initSetDateSagaRequest)
    yield put(setInitInfo(data))
    yield put(setOpenFlag(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* settingSaga(action: ReturnType<typeof setting>) {
  try {
    yield call(settingRequest, action.payload.request)
    if (action.payload.onSubmit) {
      action.payload.onSubmit()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* settingDisplayItemsSaga(action: ReturnType<typeof settingDisplayItems>) {
  try {
    yield call(settingRequest, action.payload.request)
    if (action.payload.onSubmit) {
      action.payload.onSubmit()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBIS020 初期表示
function* getActionPreListSaga() {
  try {
    yield put(setActionPreList([]))
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCBIS020InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 初期データ取得
    const data = yield call(selectCriteriaRequest, request)
    yield put(setFlag(1))
    // 自分画面を表示
    yield put(setCreateDialogOpen(true))
    yield put(setActionPreList(data))
  } catch (error) {
    yield put(setFlag(1))
    yield put(setCreateDialogOpen(true));
  }
}
// MCBIS020 MY検索(My CareerBox)呼び出し押下
export function* getMySearchSaga(action: ReturnType<typeof getMySearch>) {
  try {
    const data = yield call(getMySearchRequest, action.payload.mySearchRequest)
    const searchCriteria: SearchCondition = JSON.parse(data.searchCriteria)
    yield put(setSearchCondition(searchCriteria))
    const id = action.payload.mySearchRequest.entrySearchCriteriaSettingId

    //再検索
    yield put(setMcbAdvancedSearchOpen(false))
    yield put(setDisplaySearchCriteriaTitleOpenFlag(false))
    yield put(
      searchSubmissionRequest({
        request: {
          ...action.payload.searchRequest,
          searchCondition: data.searchCriteria,
          searchCount: 1,
          entrySearchCriteriaSettingId: id
        },
        isInitFilter: true,
        mySearch: data,
      })
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}


export function* searchSubmissionRequestSaga(action: ReturnType<typeof searchSubmissionRequest>) {
  try {
    let data = initSearchResult
    if (action.payload.request.searchCount === 1) {
      yield put(setCircleLoadingFlag(true))
      const recruitmentManagementDivision = yield select(
        getRecruitmentManagementDivision
      )
      const globalMenu = yield select((state: RootState) => state.globalMenu)

      const result = yield all([
        call(initAreaContactPassRequest, {
          recruitmentManagementDivision: recruitmentManagementDivision,
          lastLogoutTime: globalMenu.lastLogoutTime,
          previousLoginTime: globalMenu.previousLoginTime,
        }),
        call(getMCBHS131InitRequest),
        call(getMCBHS141InitRequest),
        call(getMCBHS151InitRequest),
        call(searchRequest, action.payload.request),
      ])

      data = result[4]
      yield put(setInitAreaContactPass(result[0]))

      //表示上限プルダウンを設定
      if (action.payload.request.displayLimit === null) {
        const defaultDisplayLimitInfo = data.displayLimitOptionInfo.find(
          i => i.selected === '1'
        )
        if (defaultDisplayLimitInfo) {
          yield put(
            setDisplayLimit({
              label: defaultDisplayLimitInfo.label,
              value: defaultDisplayLimitInfo.value,
            })
          )
        }
      }

      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:開始 *
       *---------------------------------------*/
      const mCBHS141Response = result[2]
      const mCBHS131Response = result[1]
      const mCBHS151Response = result[3]

      if(mCBHS141Response && "MCBHS141-001" == mCBHS141Response.errorCode){
        yield put(openModal("MCBHS141-001"))
      }

      const mCBHS141Result = {
        submissionContentList: mCBHS141Response.submissionContentList,
        submissionDeadlineOptionList: mCBHS141Response.submissionDeadlineOptionList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        submissionStatusOptionList: mCBHS141Response.submissionStatusOptionList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        confirmationStatusList: mCBHS141Response.confirmationStatusList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        errorCode:mCBHS141Response.errorCode
      }
      const mCBHS131Result = {
        schoolNameList: mCBHS131Response.schoolNameList,
        facultyGraduateNameList: mCBHS131Response.facultyGraduateNameList,
        mcbUsageOptionList: mCBHS131Response.mcbUsageOptionList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
      }
      const mCBHS151Result = {
        researchContentList: mCBHS151Response.researchContentList,
        sexList: mCBHS151Response.sexList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        languageSkillsList: mCBHS151Response.languageSkillsList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        academicList: mCBHS151Response.academicList.map((option: any) => ({
          value: option.focusedAcademicWorkId,
          label: option.focusedAcademicWorkName,
        })),
        eligibilityList: mCBHS151Response.eligibilityList.map((option: any) => ({
          value: option.eligibilityId,
          label: option.eligibilityName,
        })),
      }

      yield put(
        setSearchConditionSelectInit({
          mCBHS141Result,
          mCBHS131Result,
          mCBHS151Result,
        })
      )
      //MCBリプレース MCBHS040 END

      //検索条件詳細情報の更新
      if (data.searchCondition) {
        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          mCBHS131Result,
          // mCBHS141Result,
          // mCBHS151Result,
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
        yield put(setSearchCondition(newSearchCondition))
        const newSortSearchCondition = toEntryList(fromEntryList(newSearchCondition))
        yield put(
          setSelectedSearch({
            searchCondition: newSortSearchCondition,
            searchConditionDisplay: data.searchConditionDisplay,
          })
        )

        const payloadMySearch = action.payload.mySearch
        if (payloadMySearch) {
          const mySearch = {
            entrySearchCriteriaSettingId:
              payloadMySearch.entrySearchCriteriaSettingId,
            searchCriteriaName: payloadMySearch.searchCriteriaName,
            searchCriteria: JSON.stringify(newSearchCondition),
            searchCriteriaDisplay: data.searchConditionDisplay,
          }
          yield put(setMySearchResult(mySearch))
        }
      }
      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:終了 *
       *---------------------------------------*/
    } else {
      yield put(setCircleLoadingFlag(false))
      data = yield call(searchRequest, action.payload.request)
      if (data.searchCondition) {
        const { mCBHS131Result, mCBHS141Result, mCBHS151Result } = yield select(
          (state: RootState) => state.submissionRequestList
        )

        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          mCBHS131Result,
          // mCBHS141Result,
          // mCBHS151Result,
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
        yield put(setSearchCondition(newSearchCondition))
        const newSortSearchCondition = toEntryList(fromEntryList(newSearchCondition))
        yield put(
          setSelectedSearch({
            searchCondition: newSortSearchCondition,
            searchConditionDisplay: data.searchConditionDisplay,
          })
        )
      }
    }

    const totalCount = yield select((state: RootState) => state.submissionRequestList.searchResult.totalCount)
    
    data.submissionRequestListItemInfo = data.submissionRequestListItemInfo ? data.submissionRequestListItemInfo : []
    data.submissionRequestsListInfo = data.submissionRequestsListInfo ? data.submissionRequestsListInfo : []
    
    const totalCountFixed = data.totalCount ? data.totalCount : 0
    if (action.payload.request.searchCount === 1) {
        data.totalCount = totalCountFixed
      yield put(setTotalCount(totalCountFixed))
    } else {
      data.totalCount = totalCount
    }

    if (action.payload.request.searchCount === 1) {
      // 事務局かつ提出リクエスト管理タブ押下時、MCB検索条件詳細画面を開く
      const isManagementOffice: boolean = data.isManagementOffice
      if (isManagementOffice) {
        if (action.payload.request.sourceFunctionId === magiContants.MCAAS010_SCREENID) {
          yield put(setMcbAdvancedSearchOpen(true)) 
        }
      }
      yield put(setNeedUpdate(false))
      yield put(setSreachBtnFlg(false))
      // 1回目レスポンス到着、選考フィルターなし、レスポンスを画面表示に反映
      yield put(setSearchResult(data))
      yield put(setInitDisplay({ isInitFilter: action.payload.isInitFilter, searchResult: data}))

    } else {
      yield put(setSearchResult(data))
      const needUpdate = yield select((state: RootState) =>
        state.submissionRequestList.needUpdate
      )
      yield put(setSearchCount(1))
      yield put(setHasNewData(true))
      // 2回目レスポンス到着、選考フィルターあり、レスポンスを画面表示に反映
      if (needUpdate) {
        yield put(updateOnNewData(data.displayInfo))
      }
    }

    if (action.payload.onSearch) {
      action.payload.onSearch()
    }
    
    // MCAXS260(応募管理（その他を一括更新）)の場合
    // 選択状態
    if (data.sourceFunctionId === 'MCAXS260') {
      const displayInfoTemp = data.displayInfo
      .slice(0, data.displayInfo.length > 100 ? 100 : data.displayInfo.length)

      const submissionRequestsList = displayInfoTemp
        .map(item =>item.submissionRequests)
        .flat()
      const jsbcIdSrIdList = submissionRequestsList
        .map(item => item.jsbcIdSrId)

        yield put(setSelectedAccounts(jsbcIdSrIdList))
        yield put(setSelectAllStatus(true))
        yield put(updateOnSelectAll(data.displayInfo))
    }

    /**
     * レスポンスの totalCount とレスポンスの submissionRequestListInfo の
     * リストの長さが異なる場合
     *
     * ２回目の検索を実施する
     */
    if (
      action.payload.request.searchCount === 1 &&
      totalCountFixed !== data.displayInfo.length
    ) {

      // フィルターあり、再検索(501件以上)の場合は2回目のレスポンスが来るまで全体(選考フィルター、件数、一覧表示)を"読み込み中"表示
      yield put(setLoadingFlag(false))

      yield put(setSearchCount(2))
      yield put(
        searchSubmissionRequest({
          request: {
            ...action.payload.request,
            searchCount: 2,
          },
          isInitFilter: action.payload.isInitFilter
        })
      )
    } else {
      yield put(setLoadingFlag(false))
    }
    yield put(setCircleLoadingFlag(false))
  } catch (error) {
    yield put(setCircleLoadingFlag(false))
    // MCBリプレース #8257 START
    yield put(setSreachBtnFlg(false))
    yield put(setInitDisplay({ isInitFilter: action.payload.isInitFilter, searchResult: initSearchResult}))
    yield put(setSearchResult(initSearchResult))
    // MCBリプレース #8257 END
    yield put(openModal(error.message))
  }

}

// MCBHS161権限チェック
function* getMcbhs161InitSaga() {
  try {
    yield call(getMCBHS161InitRequest)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* getMcbhs161SubmissionClickSaga(action: ReturnType<typeof getMcbhs161SubmissionClick>) {
  try {
    const data: any = yield call(getMcbhs161SubmissionClickRequestApi)
    let [state , setState] = action.payload.open
    if (data == '0') {
      const openFileSelectModal = () => {
        setState({
          ...state,
          submissionFile: true,
        })
      }
      yield put(getMcbhs171Init(openFileSelectModal))
    }
    else {
      const openFileSelectModal = () => {
        setState({
          ...state,
          submissionFile: false,
        })
      }
      yield put(getMcbhs171Init(openFileSelectModal))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// オリジナル指定ボタン押下時、MCBサービス停止チェック行う
function* doOriginalMcbServiceCheckSaga(action: ReturnType<typeof doOriginalMcbServiceCheck>) {
  try {
    const data: any = yield call(getMcbhs161SubmissionClickRequestApi);
    if (data == '0') {
      action.payload.handleOriginalQuestionSelectClick()
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 詳細を確認するリンク（提出物内容）押下時
function* pushMcbCheckDetailsSage(action: ReturnType<typeof pushMcbCheckDetails>) {
  try {
    const data = yield call(pushMcbCheckDetailsRequest, action.payload)
    const url = data.mcbCommonUrl + data.mcbSubmissionDetailUrl
    const title = '提出物確認画面'
    // MCBリプレイス #8682 START
    const params = popupParams(1280, 948)
    // MCBリプレイス #8682 END
    let urlParam = url + "?"
    urlParam = urlParam + "sourceSystemType=" + data.sourceSystemType
    urlParam = urlParam + "&sourceCompanyCode=" + data.sourceCompanyCode
    urlParam = urlParam + "&sourceStudentCode=" + data.sourceStudentCode
    urlParam = urlParam + "&submissionRequestId=" + data.submissionRequestId
    axios.get(urlParam, {
      withCredentials: true,
      headers: {
        Authorization: getToken(),
      },
    }).then(res => {
      let newWindow = window.open(urlParam, title, params)
    }).catch(error => {
      console.log(error)
    })

  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 検索条件詳細（My CareerBox）画面でタブ押下時、MCBサービス停止チェック行う
function* doMcbhs211McbServiceCheckSaga(action: ReturnType<typeof doMcbhs211McbServiceCheck>) {
  try {
    const data: any = yield call(getMcbhs211McbServiceCheckApi);
    if (data == '0') {
      action.payload.doChangeEvent(action.payload.submissionsObj, action.payload.newValue)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* submissionRequestListSaga() {
  yield all([
    takeLatest(searchSubmissionRequest, searchSubmissionRequestSaga),
    takeLatest(initDisplayItems, initDisplayItemsSaga),
    takeLatest(settingDisplayItems, settingDisplayItemsSaga),
    takeLatest(setting, settingSaga),
    // MCBIS020
    takeEvery(getActionPreList, getActionPreListSaga),
    takeEvery(getMySearch, getMySearchSaga),
    // MCBリプレース MCBHS161 START
    takeLatest(getMcbhs161Init, getMcbhs161InitSaga),
    takeLatest(getMcbhs161SubmissionClick, getMcbhs161SubmissionClickSaga),
    takeLatest(doOriginalMcbServiceCheck, doOriginalMcbServiceCheckSaga),
    // MCBリプレース MCBHS161 END
    takeEvery(pushMcbCheckDetails, pushMcbCheckDetailsSage),
    // MCBリプレース MCBHS211 START
    takeLatest(doMcbhs211McbServiceCheck, doMcbhs211McbServiceCheckSaga),
    // MCBリプレース MCBHS211 END    
  ])
}