import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {submitValues} from '../pages/MCADS010/formConfig'
import {MCADS010QueryRequest} from 'types/MCADS010/MCADS010QueryRequest'
import {MCADS010OperateBulkRequest} from 'types/MCADS010/MCADS010OperateBulkRequest'
import {MCADS020CreateRequest} from '../types/MCADS020/MCADS020CreateRequest'
import {initialValues} from '../pages/MCADS020/formConfig'
import {MCADS030CreateRequest} from 'types/MCADS030/MCADS030CreateRequest'
import {MCADS040LoginIdRequest} from '../types/MCADS040/MCADS040LoginIdRequest'
import {editList, editResults, initValues} from '../pages/MCADS040/formConfig'
import {MCADS050ProcessRequest} from '../types/MCADS050/MCADS050ProcessRequest'
import {initVersionNumberAndCompanyAccountValues} from '../pages/MCADS030/formConfig'

export interface ExtraAccounts {
  [key: string]: string | number

  contractMediaName: string //企業契約応募経路名
  loginId: string //ログインID
  fullName: string //氏名
  departmentName: string //部署名
  mailAddress: string //メールアドレス
  phoneNumber: string //電話番号
  accountType: string //アカウント種別
  accountTypeShow: string //アカウント種別（表示用）
  recruitmentGroupName: string //採用グループ
  recruitmentGroupNameShow: string //採用グループ（表示用）
  validFlag: string //ステータス
  status: string //ステータス（表示用）
  lastLoginTime: string //最終ログイン日時
  lastLoginTimeShow: string //最終ログイン日時（表示用）
  sysVersionNumberCoAccAuth: number //「企業アカウント認証」のsysバージョン番号
  sysVersionNumberCoAcc: number //「企業アカウント」のsysバージョン番号
  companyAccountId: number //企業アカウントID
  manualRegistrationFlag: string //手動登録フラグ
}

export interface NewRecruitmentName {
  [key: string]: string | number

  value: number
  label: string
}

export interface initValues {
  [key: string]: string | number | string[]

  loginId: string
  name: string
  department: string
  mailAddress: string
  tel: string
  accountType: string
  recruitmentGroupSupervising: string
  recruitmentGroupStandard: string
  recruitmentGroupOriginal: string[]
  forbidDownload: string
  forbidMessageReply: string
  status: string
}

export interface Edits {
  [key: string]: string | number | string[]

  loginId: string // ログインID
  name: string // 氏名
  department: string // 部署
  mailAddress: string // メールアドレス
  tel: string // 電話番号
  accountType: any // アカウント種別
  recruitmentGroupName: string //採用グループ name
  recruitmentGroup: any[] //採用グループ
  forbidDownload: string // データダウンロードを禁止する
  forbidMessageReply: string // メッセージ返信を禁止する
  status: number //ステータス
  lastLoginTime: string //最終ログイン日時
  manualRegistrationFlag: string //手動登録フラグ
  companyAccountId: number //企業アカウントID
  versionNumberAccount: string //「企業アカウント」のsysバージョン番号
  versionNumberAccountAuthorization: string //「企業アカウント認証」のsysバージョン番号
  // 次期開発12月 #75981 start
  initialDisplayYear: any // 初期表示年度
  targetYearList:string[] // 表示可能な年度
  // 次期開発12月 #75981 end
  // 転職March #75621 START
  recruitmentGroupSettingId: number
  // 転職March #75621 END
}

export interface RecruitmentGroups {
  [key: string]: string

  recruitmentGroupSettingId: string //採用グループ設定ID
  recruitmentGroupName: string //採用グループ名
}

export interface recruitmentGroupSettingIdGroups {
  [key: string]: string

  companyAccountId: string //企業アカウントID
  recruitmentGroupName: string //採用グループ設定ID
  recruitmentGroupSettingId: string //採用グループ名
}

export interface TableVersionNumberBean {
  [key: string]: string

  versionNumberAccount: string
  versionNumberAccountAuthorization: string
}

export interface initVersionNumberAndCompanyAccountBean {
  versionNumber: string
  companyAccountId: string
}

interface ExtraAccountList {
  extraAccountListSearchCondition: MCADS010QueryRequest
  extraAccountListResults: ExtraAccounts[]
  recruitmentGroupsResults: RecruitmentGroups[]
  loginCount: number
  RecruitmentGroupsResults: RecruitmentGroups[]
  RecruitmentIdAccountInsert: number
  RecruitmentIdAccountUpdate: number
  RecruitmentIdAccountConfirm: number
  FormValueSave: MCADS020CreateRequest
  TemporaryLoginId: string
  VersionNumber: string
  loginCountI: number
  loginCountConfirmI: number
  EditList: MCADS040LoginIdRequest
  EditListResults: Edits
  companyAccountId: string
  recruitmentGroupSettingIdGroupsResults: recruitmentGroupSettingIdGroups[]
  initValuesResults: initValues
  initVersionNumberAndCompanyAccountBean: initVersionNumberAndCompanyAccountBean
  mcabs010InitResultDto: MCABS010InitResultDto
  open: boolean
  loadingFlag: boolean
  updateOpenFlag:boolean
  openUpdateModal:boolean
  // 次期開発12月 #75981 start
  initialDisplayYear:string[]
  // 次期開発12月 #75981 end
}
export interface MCABS010InitResultDto {
  ssoLogin: boolean
  // MCBリプレース MCABS010 START
  authToken: string
  submissionContentURL: string
  inquiryURL: string
  privacyPolicyURL: string
  thanksMailTemplateURL: string
  // MCBリプレース MCABS010 END
}
const mcabs010InitResultDto = {
  // SSOアカウントフラグ
  ssoLogin: false,
  // MCBリプレース MCABS010 START
  authToken: '',
  submissionContentURL: '',
  inquiryURL: '',
  privacyPolicyURL: '',
  thanksMailTemplateURL: '',
  // MCBリプレース MCABS010 END
  }

const accountList: ExtraAccountList = {
  extraAccountListSearchCondition: submitValues,
  extraAccountListResults: [],
  recruitmentGroupsResults: [],
  loginCount: Number.MAX_VALUE,
  loginCountI: Number.MAX_VALUE,
  loginCountConfirmI: Number.MAX_VALUE,
  RecruitmentGroupsResults: [],
  RecruitmentIdAccountInsert: 0,
  RecruitmentIdAccountUpdate: 0,
  RecruitmentIdAccountConfirm: 0,
  FormValueSave: initialValues,
  TemporaryLoginId: '',
  VersionNumber: '',
  EditList: editList,
  EditListResults: editResults,
  companyAccountId: '',
  recruitmentGroupSettingIdGroupsResults: [],
  initValuesResults: initValues,
  initVersionNumberAndCompanyAccountBean: initVersionNumberAndCompanyAccountValues,
  mcabs010InitResultDto: mcabs010InitResultDto,
  open: false,
  loadingFlag: false,
  updateOpenFlag:false,
  openUpdateModal:false,
  // 次期開発12月 #75981 start
  initialDisplayYear:[]
  // 次期開発12月 #75981 end
}

const extraAccountSlice = createSlice({
  name: 'accout',
  initialState: accountList,
  reducers: {
    setUpdateOpenFlag(state,action:PayloadAction<boolean>){
      state.updateOpenFlag = action.payload
      return state
    },
    setUpdateModal(state,action:PayloadAction<boolean>){
      state.openUpdateModal = action.payload
      return state
    },
    setExtraAccountListResults(state, action: PayloadAction<ExtraAccounts[]>) {
      state.extraAccountListResults = action.payload
      return state
    },
    selectExtraAccountList(state, action: PayloadAction<MCADS010QueryRequest>) {
      return state
    },
    setExtraAccountListSearchCondition(
      state,
      action: PayloadAction<MCADS010QueryRequest>
    ) {
      state.extraAccountListSearchCondition = action.payload
      return state
    },
    selectRecruitmentGroup(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    setRecruitmentGroupResults(
      state,
      action: PayloadAction<RecruitmentGroups[]>
    ) {
      state.recruitmentGroupsResults = action.payload
      return state
    },
    updateByInvalid(
      state,
      action: PayloadAction<{
        data: MCADS010OperateBulkRequest[]
        message: string
      }>
    ) {
      return state
    },
    updateByValid(
      state,
      action: PayloadAction<{
        data: MCADS010OperateBulkRequest[]
        message: string
      }>
    ) {
      return state
    },
    updateByPassword(
      state,
      action: PayloadAction<{
        data: MCADS010OperateBulkRequest[]
        message: string
      }>
    ) {
      return state
    },
    updateInvDataByPwd(
      state,
      action: PayloadAction<{
        data: MCADS010OperateBulkRequest[]
        message: string
      }>
    ) {
      return state
    },
    selectLoginCount(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    selectLoginCountI(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    selectLoginCountConfirmI(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    setLoginCountResults(state, action: PayloadAction<number>) {
      state.loginCount = action.payload
      return state
    },
    setLoginCountResultsI(state, action: PayloadAction<number>) {
      state.loginCountI = action.payload
      return state
    },
    setLoginCountResultsConfirmI(state, action: PayloadAction<number>) {
      state.loginCountConfirmI = action.payload
      return state
    },
    queryRecruitmentGroup(state) {
      return state
    },
    setRecruitmentGroupsResults(
      state,
      action: PayloadAction<RecruitmentGroups[]>
    ) {
      state.RecruitmentGroupsResults = action.payload
      return state
    },
    // 次期開発12月 #75981 start
    setInitialDisplayYear(state, action:PayloadAction<string[]>) {
      state.initialDisplayYear = action.payload
      return state
    },
    // 次期開発12月 #75981 end
    selectRecruitmentIdInsert(state, action: PayloadAction<string[]>) {
      return state
    },
    setRecruitmentIdInsert(state, action: PayloadAction<number>) {
      state.RecruitmentIdAccountInsert = action.payload
      return state
    },
    selectRecruitmentIdUpdate(state, action: PayloadAction<string[]>) {
      return state
    },
    setRecruitmentIdUpdate(state, action: PayloadAction<number>) {
      state.RecruitmentIdAccountUpdate = action.payload
      return state
    },
    selectRecruitmentIdConfirm(state, action: PayloadAction<string[]>) {
      return state
    },
    setRecruitmentIdConfirm(state, action: PayloadAction<number>) {
      state.RecruitmentIdAccountConfirm = action.payload
      return state
    },
    setFormValue(state, action: PayloadAction<MCADS020CreateRequest>) {
      state.FormValueSave = action.payload
      return state
    },
    selectLoginCountAndUpdate(
      state,
      action: PayloadAction<{ data: MCADS030CreateRequest; message: string }>
    ) {
      return state
    },
    selectVersionNumberByTemporaryLoginId(
      state,
      action: PayloadAction<string>
    ) {
      return state
    },
    setVersionNumberByTemporaryLoginId(
      state,
      action: PayloadAction<initVersionNumberAndCompanyAccountBean>
    ) {
      state.initVersionNumberAndCompanyAccountBean = action.payload
      return state
    },
    selectEditList(state, action: PayloadAction<MCADS040LoginIdRequest>) {
      return state
    },
    setEditList(state, action: PayloadAction<Edits>) {
      state.EditListResults = action.payload
      return state
    },
    selectRecruitmentName(state, action: PayloadAction<string>) {
      return state
    },
    setRecruitmentName(
      state,
      action: PayloadAction<recruitmentGroupSettingIdGroups[]>
    ) {
      state.recruitmentGroupSettingIdGroupsResults = action.payload
      return state
    },
    toIUDSubmit(
      state,
      action: PayloadAction<{ data: MCADS050ProcessRequest; message: string }>
    ) {
      return state
    },
    // SSOアカウントフラグを取得
    selectSsoLoginFlag(state) {
      return state
    },
    // SSOアカウントフラグを設定
    setSsoLoginFlag(
      state,
      action: PayloadAction<MCABS010InitResultDto>
    ) {
      state.mcabs010InitResultDto = action.payload
      state.open = true
      return state
    },
    setLoadingFlag(state, action: PayloadAction<boolean>) {
      state.loadingFlag = action.payload
      return state
    }
  },
})
export const {
  selectExtraAccountList,
  setExtraAccountListSearchCondition,
  setExtraAccountListResults,
  selectRecruitmentGroup,
  setRecruitmentGroupResults,
  updateByInvalid,
  updateByValid,
  updateByPassword,
  updateInvDataByPwd,
  selectLoginCount,
  selectLoginCountI,
  selectLoginCountConfirmI,
  setLoginCountResultsConfirmI,
  setLoginCountResults,
  setLoginCountResultsI,
  queryRecruitmentGroup,
  setRecruitmentGroupsResults,
  // 次期開発12月 #75981 start
  setInitialDisplayYear,
  // 次期開発12月 #75981 end
  selectRecruitmentIdInsert,
  setRecruitmentIdInsert,
  selectRecruitmentIdUpdate,
  setRecruitmentIdUpdate,
  setFormValue,
  selectLoginCountAndUpdate,
  selectVersionNumberByTemporaryLoginId,
  setVersionNumberByTemporaryLoginId,
  selectEditList,
  setEditList,
  selectRecruitmentName,
  setRecruitmentName,
  toIUDSubmit,
  selectRecruitmentIdConfirm,
  setRecruitmentIdConfirm,
  selectSsoLoginFlag,
  setSsoLoginFlag,
  setLoadingFlag,
  setUpdateOpenFlag,
  setUpdateModal,
} = extraAccountSlice.actions

export default extraAccountSlice.reducer
