import { getMessage } from "common/messageUtil"

const signUpdateInitialValues = {
  resisteredName: '',
  signatureText: '',
  signSettingId: '',
  sysVersionNumber: 0,
}

const signDeleteInitialValues = {
  signSettingId: '',
  sysVersionNumber: 0,
}

const textMap = {
  delete: {
    delete: '削除する',
    message: getMessage('MCANS030-002'),
  },
  update: {
    update: '更新する',
    message: getMessage('MCANS030-003'),
  },
  cancel: {
    cancel: 'キャンセルする',
    message: getMessage('MCANS030-004'),
  },
}

export { signUpdateInitialValues, signDeleteInitialValues, textMap }
