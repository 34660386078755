import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntryUploadHistoryData, entryUploadHistoryDataValues, uploadHistoryInfoDto } from 'pages/MCAPS020/formConfig'
import { MCAPS020ApplicantListRequest } from 'types/MCAPS020/MCAPS020ApplicantListRequest'
import { MCAPS020DuplicateRequest } from 'types/MCAPS020/MCAPS020DuplicateRequest'
import { MCAPS020ErrorRequest } from 'types/MCAPS020/MCAPS020ErrorRequest'
import { MCAPS020QueryRequest } from 'types/MCAPS020/MCAPS020QueryRequest'
import { MCAPS010InitRequest } from 'types/MCAPS010/MCAPS010InitRequest'
import { MCAPS030QueryRequest } from 'types/MCAPS030/MCAPS030QueryRequest'
import { UploadErrorInfoResultDto, initUploadErrorInfoResult } from 'pages/MCAPS030/formConfig'
import { MCAPS010IdRequest } from 'types/MCAPS010/MCAPS010IdRequest'
import { MCAPS020AutoMergeConfirmRequest } from 'types/MCAPS020/MCAPS020AutoMergeConfirmRequest'
import { MCAPS040InitRequest } from 'types/MCAPS040/MCAPS040InitRequest'
import { initUploadAutoMergeInfoResult, MCAPS040UploadAutoMergeInfoResultDto } from 'pages/MCAPS040/formConfig'



//CSV入出力テンプレート情報定義
export interface Template {
  entryDataIoTemplateSettingId: string //エントリーデータ入出力テンプレート設定ID
  templateName: string //テンプレート名
  uploadType: string //アップロード種別
}
//選考情報定義
export interface Selection {
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
}
//応募経路情報定義
export interface Media {
  mediaSettingId: string //応募経路設定ID
  mediaRouteName: string //応募経路名
}
//
export interface EntryUploadSheet {
  templateInfo: Template[] //CSV入出力テンプレート情報
  selectionInfo: Selection[] //選考情報
  mediaInfo: Media[] //応募経路情報
  entryDataIoTemplateSettingId: string //エントリーデータ入出力テンプレート設定ID
  selectionFlowSettingId: string //選考フロー設定ID
  mediaSettingId: string //応募経路設定ID

}

interface EntryUploadData {
  entryUploadSheet: EntryUploadSheet
  entryUploadHistoryData: EntryUploadHistoryData
  uploadErrorInfoResult: UploadErrorInfoResultDto
  // 次期開発12月 #73504 start
  uploadAutoMergeInfoResult: MCAPS040UploadAutoMergeInfoResultDto
  // 次期開発12月 #73504 end
}
const initialState: EntryUploadData = {
  entryUploadSheet: {
    templateInfo: [],
    selectionInfo: [],
    mediaInfo: [],
    entryDataIoTemplateSettingId: '',
    selectionFlowSettingId: '',
    mediaSettingId: ''
  },
  entryUploadHistoryData: entryUploadHistoryDataValues,
  uploadErrorInfoResult: initUploadErrorInfoResult,
  // 次期開発12月 #73504 start
  uploadAutoMergeInfoResult: initUploadAutoMergeInfoResult,
  // 次期開発12月 #73504 end
}



const entryUploadSlice = createSlice({
  name: 'accout',
  initialState,
  reducers: {
    // MCAPS010 START
    fetchEntryUploadSheet(state,action:PayloadAction<MCAPS010InitRequest>) {
      return state
    },
    setEntryUploadSheet(state, action: PayloadAction<EntryUploadSheet>) {
      state.entryUploadSheet = action.payload
    },
    // setTemplatesInfo(state, action: PayloadAction<any>) {
    //   state.entryUploadSheet.TemplatesInfo = { ...(state.entryUploadSheet.TemplatesInfo), ...action.payload }
    // },
    updateEntryUploadSheet(state) {
      return state
    },
    register(state,action:PayloadAction<MCAPS010IdRequest>){
      return state;
    },
    // MCAPS010 END

    // MCAPS020 START
    // 初期表示
    initEntryUplodaHistory(state, action: PayloadAction<{ messageId: any, recruitmentManagementDivision: string }>) {
      return state
    },
    // 初期表示
    setEntryUploadHistory(state, action: PayloadAction<EntryUploadHistoryData>) {
      state.entryUploadHistoryData.templateInfo = action.payload.templateInfo
      state.entryUploadHistoryData.uploadHistoryInfo = action.payload.uploadHistoryInfo
    },
    // 「検索する」ボタン押下時
    selectEntryUplodaHistory(state, action: PayloadAction<MCAPS020QueryRequest>) {
      return state
    },
    // 「検索する」ボタン押下時 + 初期表示
    cleanEntryUploadHistory(state, action: PayloadAction<uploadHistoryInfoDto[]>) {
      state.entryUploadHistoryData.uploadHistoryInfo = action.payload
    },
    // 「検索する」ボタン押下時
    setSelectEntryUplodaHistory(state, action: PayloadAction<EntryUploadHistoryData>) {
      state.entryUploadHistoryData.uploadHistoryInfo = action.payload.uploadHistoryInfo
    },
    // 「アップロード件数」リンク押下
    uploadCount(state, action: PayloadAction<MCAPS020ApplicantListRequest>) {
      return state
    },
    // 「自動適用件数」リンク押下
    filterAutoCount(state, action: PayloadAction<MCAPS020ApplicantListRequest>) {
      return state
    },
    // 「重複件数」リンク押下
    duplicateCount(state, action: PayloadAction<MCAPS020DuplicateRequest>) {
      return state
    },
    // 「エラー確認」リンク押下
    errorInfoCount(state, action: PayloadAction<MCAPS020ErrorRequest>) {
      return state
    },
    // 次期開発12月 #73504 start
    // 「自動名寄せ件数」リンク押下
    autoMergeCount(state, action: PayloadAction<MCAPS020AutoMergeConfirmRequest>) {
      return state
    },
    // 次期開発12月 #73504 end
    // MCAPS020 END

    //MCAPS030 START
    // 初期表示
    initEntryUploadErrorComfrim(state, action: PayloadAction<MCAPS030QueryRequest>) {
      return state
    },
    // 初期表示
    setErrorComfrim(state, action: PayloadAction<UploadErrorInfoResultDto>) {
      state.uploadErrorInfoResult = action.payload
    },
    //再アップロードする
    reuploading(state, action: PayloadAction<MCAPS030QueryRequest>) {
      return state
    },
    //MCAPS030 END

    // 次期開発12月 #73504 start
    //MCAPS040 START
    // 初期表示
    initEntryUploadAutoMergeComfrim(state, action: PayloadAction<MCAPS040InitRequest>) {
      return state
    },
    // 初期表示
    setAutoMergeComfrim(state, action: PayloadAction<MCAPS040UploadAutoMergeInfoResultDto>) {
      state.uploadAutoMergeInfoResult = action.payload
    },
    //MCAPS040 END
    // 次期開発12月 #73504 end
  },
})

export const {
  fetchEntryUploadSheet,
  setEntryUploadSheet,
  // setTemplatesInfo,
  register,
  updateEntryUploadSheet,
  // MCAPS020 START
  initEntryUplodaHistory,
  setEntryUploadHistory,
  cleanEntryUploadHistory,
  selectEntryUplodaHistory,
  setSelectEntryUplodaHistory,
  uploadCount,
  filterAutoCount,
  duplicateCount,
  errorInfoCount,
  // MCAPS020 END
  // MCAPS030 START
  initEntryUploadErrorComfrim,
  setErrorComfrim,
  reuploading,
  // MCAPS030 END
  // 次期開発12月 #73504 start
  autoMergeCount,
  initEntryUploadAutoMergeComfrim,
  setAutoMergeComfrim,
  // 次期開発12月 #73504 end
} = entryUploadSlice.actions
export default entryUploadSlice.reducer
