import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCBHS190InitRequest } from 'types/MCBHS190/MCBHS190InitRequest'
import { MCBHS190StatusCheckRequest } from 'types/MCBHS190/MCBHS190StatusCheckRequest'
import { MCAXS141InitRequest } from 'types/MCAXS141/MCAXS141InitRequest'
export interface ModalForCheck {
  openCheckModal: boolean
  modalMessage: string
}

// 画面初期表示用
export interface ItemProperty {
  targetNumber: number // 対象件数
  requestId: string // リクエストID
}

// redux state定義
const initialState: {
  // 初期化対象件数
  initData: ItemProperty
  status: string
  startFlag: boolean
  count: number
  companyId: string
} = {
  initData: {
    targetNumber: 0, // 対象件数
    requestId: '', // リクエストID
  },
  status: '01',
  startFlag: false,
  count: 0,
  companyId: '',
}

const pdfOutputReducer = createSlice({
  name: 'pdfOutput',
  initialState,
  reducers: {
    // PDF作成中
    // 初期表示
    // PDF作成中 初期データ取得
    getInitData(state, action: PayloadAction<MCBHS190InitRequest>) {
      return state
    },
    // PDF作成中 初期データ取得
    setInitData(state, action: PayloadAction<ItemProperty>) {
      state.initData = action.payload
      return state
    },

    // 定期実行
    // PDF作成中 定期実行データ取得
    getStatusData(state, action: PayloadAction<MCBHS190StatusCheckRequest>) {
      return state
    },
    // PDF作成中 定期実行データ設定
    setStatusData(state, action: PayloadAction<string>) {
      state.status = action.payload
      return state
    },
    // 初期処理フラグ設定
    setStartFlag(state, action: PayloadAction<boolean>) {
      state.startFlag = action.payload
      return state
    },
    setCount(state, action: PayloadAction<number>) {
      state.count = action.payload
      return state
    },

    // PDFダウロード
    // 初期処理
    getInitDataForDownload(state, action: PayloadAction<MCAXS141InitRequest>) {
      return state
    },
    // 企業ID設定
    setCompanyId(state, action: PayloadAction<string>) {
      state.companyId = action.payload
      return state
    },
    // PDF作成中 初期データ取得
    pdfMaking(state, action: PayloadAction<MCBHS190InitRequest>) {
      return state
    },
  },
})

export const {
  // MCBHS190
  // 初期表示
  getInitData,
  setInitData,
  // 定期実行
  getStatusData,
  setStatusData,
  setStartFlag,
  setCount,
  //MCAXS141
  getInitDataForDownload,
  setCompanyId,
  pdfMaking,
} = pdfOutputReducer.actions

//MCAXS151
export default pdfOutputReducer.reducer
