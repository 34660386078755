import {
  copyCountCheck,
  createCountCheck,
  getInitListRequest,
} from 'apis/MCAES010Api'
import {
  deleteRequest,
  getInitRequest,
  insertRequest,
  updateRequest,
} from 'apis/MCAES020Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  createAffiliationRecruitment,
  deleteAffiliationRecruitment,
  getRecruitmentGroupCount,
  getRecruitmentGroupTableData,
  getSelectionFlowPreferenceList,
  getRecruitmentGroupCopyFlag,
  setAffiliationOptionList,
  setAffiliationTableData,
  setDialogOpen,
  setRecruitmentGroupInfo,
  setRecruitmentGroupTableData,
  setSelectionOptionList,
  setSelectionTableData,
  updateAffiliationRecruitment,
  setCopyFlag,
} from 'reducers/recruitmentGroupReducer'
import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { initialFormValues } from '../pages/MCAES020/formConfig'
import { MCAES020CreateRequest } from 'types/MCAES020/MCAES020CreateRequest'
import { MCAES020UpdateRequest } from 'types/MCAES020/MCAES020UpdateRequest'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'

function* getRecruitmentGroupTableDataSaga() {
  const recruitmentManagementDivision: ReturnType<
    typeof getRecruitmentManagementDivision
  > = yield select(getRecruitmentManagementDivision)
  try {
    const data = yield call(getInitListRequest, recruitmentManagementDivision)
    yield put(setRecruitmentGroupTableData(data))
  } catch (error) {
  }
}

function* getCountSaga(action: ReturnType<typeof getRecruitmentGroupCount>) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    if (action.payload === '0') {
      yield call(createCountCheck, recruitmentManagementDivision)
      yield put(getSelectionFlowPreferenceList(action.payload))
      yield put(setCopyFlag(false))
    } else {
      yield call(copyCountCheck, recruitmentManagementDivision)
      yield put(getSelectionFlowPreferenceList(action.payload))
      yield put(getRecruitmentGroupCopyFlag(true))
      yield put(setCopyFlag(true))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAES020

function* closeAndInit() {
  yield put(setDialogOpen(false))
  yield put(setRecruitmentGroupInfo(initialFormValues))
  yield put(setSelectionTableData([]))
  yield put(setAffiliationTableData([]))
  yield put(setSelectionOptionList([]))
  yield put(setAffiliationOptionList([]))
}

function* getInitSaga(
  action: ReturnType<typeof getSelectionFlowPreferenceList>
) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(
      getInitRequest,
      {recruitmentGroupId: action.payload, recruitmentManagementDivision: recruitmentManagementDivision} 
    )
    if (data.recGroupPreSelect !== null) {
      const groupPreSelect = {
        recruitmentGroupSettingId:
          data.recGroupPreSelect.recruitmentGroupSettingId ||
          initialFormValues.recruitmentGroupSettingId,
        reqruitGroupName:
          data.recGroupPreSelect.recruitmentGroupName ||
          initialFormValues.reqruitGroupName,
        recruitmentType:
          data.recGroupPreSelect.recruitmentGroupType ||
          initialFormValues.recruitmentType,
        sysVersionNumber:
          data.recGroupPreSelect.sysVersionNumber ||
          initialFormValues.sysVersionNumber,
        celectionId: data.selectFlwPreSelectResult.map(
          (i: any) => i.selectionFlowSettingId
        ),
        AffiliationId: data.selectCompanyAccountResult.map(
          (i: any) => i.companyAccountId
        ),
        selectionTable: data.selectFlwPreSelectResult,
        affiliationTable: data.selectCompanyAccountResult,
      }
      yield put(setRecruitmentGroupInfo(groupPreSelect))
    }
    if (data.selectFlwPreSelectResult.length > magiContants.INTEGER_0) {
      yield put(setSelectionTableData(data.selectFlwPreSelectResult))
    }
    if (data.selectCompanyAccountResult.length > magiContants.INTEGER_0) {
      yield put(setAffiliationTableData(data.selectCompanyAccountResult))
    }
    yield put(setSelectionOptionList(data.selectFlwPreSelectSuggestList))
    yield put(setAffiliationOptionList(data.companyAccountSelectSuggestList))
    yield put(setDialogOpen(true))
  } catch (error) {
    if (error.message === getMessage("401")) {
      yield put(openModal(error.message))
    } else {
      yield put(openSnackbar(error.message))
    }
  }
}

function* createAffiliationRecruitmentSaga(
  action: ReturnType<typeof createAffiliationRecruitment>
) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data: MCAES020CreateRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    yield call(insertRequest, data)
    yield put(
      setRecruitmentGroupTableData(
        yield call(getInitListRequest, recruitmentManagementDivision)
      )
    )
    yield closeAndInit()
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAES020_015))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateAffiliationRecruitmentSaga(
  action: ReturnType<typeof updateAffiliationRecruitment>
) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data: MCAES020UpdateRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    yield call(updateRequest, data)
    yield put(
      setRecruitmentGroupTableData(
        yield call(getInitListRequest, recruitmentManagementDivision)
      )
    )
    yield closeAndInit()
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAES020_016))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* deleteAffiliationRecruitmentSaga(
  action: ReturnType<typeof deleteAffiliationRecruitment>
) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    yield call(deleteRequest, action.payload)
    yield closeAndInit()
    yield put(
      setRecruitmentGroupTableData(
        yield call(getInitListRequest, recruitmentManagementDivision)
      )
    )
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAES020_017))
  } catch (error) {
    if(error.message === magiContants.MESSAGECODE_RESULT_NULL){
      yield put(setRecruitmentGroupTableData([]))
      return
    }
    yield put(openModal(error.message))
  }
}
// MCAES020

export default function* recruitmentGroupSaga() {
  yield all([
    takeEvery(getRecruitmentGroupTableData, getRecruitmentGroupTableDataSaga),
    takeEvery(getRecruitmentGroupCount, getCountSaga),
    takeEvery(getSelectionFlowPreferenceList, getInitSaga),
    takeLeading(createAffiliationRecruitment, createAffiliationRecruitmentSaga),
    takeLeading(updateAffiliationRecruitment, updateAffiliationRecruitmentSaga),
    takeLeading(deleteAffiliationRecruitment, deleteAffiliationRecruitmentSaga),
  ])
}
