import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BasicInfo } from 'componentsHsc/AdvancedSearch/Config/basicConfig'
import { SchoolInfo } from 'componentsHsc/AdvancedSearch/Config/schoolConfig'
import { ApplicantInfo } from 'componentsHsc/AdvancedSearch/Config/applicantConfig'
import { searchCondition } from 'componentsHsc/AdvancedSearch/Config/formConfig'
import { RecruitmentManagementInfo } from 'componentsHsc/AdvancedSearch/Config/recruitmentManagementConfig'
import { MCAXS371SchoolInfoInitRequest } from 'types/MCAXS371/MCAXS371SchoolInfoInitRequest'
import { MCAXS361InitRequest } from 'types/MCAXS361/MCAXS361InitRequest'
import { MCAXS391RecruitmentInfoInitRequest } from 'types/MCAXS391/MCAXS391RecruitmentInfoInitRequest'
import {
  SearchCondition,
  initSearchCondition,
  initOrderObjArray,
} from 'componentsHsc/AdvancedSearch/Config/searchConditionConfig'
import {
  SearchCondition as MCAXS010SearchCondition,
  initSearchCondition as MCAXS010InitSearchCondition,
} from 'pages/MCAXS010/searchConditionConfig'
import { SubmissionRequestInfo } from 'componentsHsc/AdvancedSearch/Config/SubmissionRequestConfig'


/**
 * 応募情報_初期表示用データ格納情報
 */
const applicantInfo: ApplicantInfo = {
  //応募経路
  applicationRouteList: [],
  //対象応募経路
  targetApplicationRouteList: [],
  //スカウト応募経路
  scoutApplicationRouteList: [],
  //応募歓迎応募経路
  welcomeApplicationRouteList: [],
  //エントリー日条件
  entryDateConditionObj: [],
  //エントリー有無条件
  entryPresenceConditionObj: [],
  //アンケート回答内容条件
  questionnaireResponseConditionObj: [],
  //セミナー受付日程・予約状況（各受付ごと）条件
  seminarReceptionIndividualObj2: [],
  //Webセミナー視聴状況条件
  webSeminarConditionObj: [],
  //イベント参加状況（各イベントごと）条件
  eventsIndividualObj2: [],
  // スカウト特徴
  scoutFeatureObj: [],
}

/**
 * 学校情報_初期表示用データ格納情報
 */
const schoolInfo: SchoolInfo = {
  graduationPeriodList: [], // 卒業時期
  philosophyHumanitiesList: [], // 文理区分
  schoolTypeList: [], // 学校種別
  schoolNameList: [], // 学校名
  facultyGraduateNameList: [], // 学部名・研究科名
  departmentDepartmentNameList: [], // 学科名・専攻名
  countryRegionList: [], // 国・地域
  schoolGroupObj: [], // 学校グループ条件
  schoolDetailsObj: [], // 学校グループ（学校詳細）条件
  undergraduateDepartmentObj: [], // 学校グループ（学部・学科詳細）条件
  studentClassificationObj: [], // 学生分類条件
  departmentDetailsObj: [], // 学生分類（学科詳細）条件
}

/**
 * 基本情報_初期表示用データ格納情報
 */
const basicInfo: BasicInfo = {
  sexList: [], // 性別情報
  languageSkillsList: [], // 語学スキルサジェスト情報
  eligibilityList: [], // 保有資格サジェスト情報
  memberTypeList: [], // 会員情報
}

/**
 * 採用管理情報_初期表示用データ格納情報
 */
const recruitmentManagementInfo: RecruitmentManagementInfo = {
  selectionFlowList: [], // 選考フロー情報
  selectionStepList: [], // 選考ステップ情報
  judgementList: [], // 合否判定
  tagList: [], // タグ
  frequentlyUsedTagList: [], // よく使うタグ
  managementItemConditionObj: [], // 管理項目条件
  // [phase2] start
  mcbCategoryList: [], // MCBカテゴリー条件
  // [phase2] end 
  displayShlInfo: {
    displayImages: false,
    displayGab: false,
    displayOpq: false,
    displayCab: false,
  }, //SHL項目表示情報
}
// MCBリプレース MCAXS701 START
/**
 * MY CareerBOX情報_初期表示用データ格納情報
 */
export const submissionRequestInfo: SubmissionRequestInfo = {
  deliveryStatusList: [],
  deliveryDetailList: [],
  deadlineTypeList: [],
  submitStatusList: [],
  errorCode: ''
}
// MCBリプレース MCAXS701 END
const initialState = {
  applicantInfo,
  schoolInfo,
  basicInfo,
  recruitmentManagementInfo,
  searchCondition: initSearchCondition,
  open: false,
  entryReplyModalOpen: false, //アンケート回答モーダル開閉フラグ
  managementModalOpen: false, //管理項目モーダル開閉フラグ
  MCARS030SCriteriaParam: MCAXS010InitSearchCondition, //My検索保存パラメータ
  orderObjArray: initOrderObjArray,
  currentTab: 0, //初期表示タブ
  submissionRequestInfo,//MY CareerBOX情報
}

const advancedSearchSlice = createSlice({
  name: 'advancedsearch',
  initialState,
  reducers: {
    // 検索条件詳細初期表示情報を取得
    getMcaxs351Init(
      state,
      action: PayloadAction<{
        mCAXS361Request: MCAXS361InitRequest
        mCAXS371Request: MCAXS371SchoolInfoInitRequest
        mCAXS391Request: MCAXS391RecruitmentInfoInitRequest
        mCAXS010SearchCondition?: MCAXS010SearchCondition
        // MCBリプレース MCAXS701 START
        mCAXS701Result: SubmissionRequestInfo
      }>
    ) {
      return state
    },
    // 検索条件詳細初期表示情報を設定
    setMcaxs351Init(
      state,
      action: PayloadAction<{
        mCAXS361Result: ApplicantInfo
        mCAXS371Result: SchoolInfo
        mCAXS381Result: BasicInfo
        mCAXS391Result: RecruitmentManagementInfo
        mCAXS701Result: SubmissionRequestInfo
      }>
    ) {
      state.applicantInfo = action.payload.mCAXS361Result
      state.schoolInfo = action.payload.mCAXS371Result
      state.basicInfo = action.payload.mCAXS381Result
      state.recruitmentManagementInfo = action.payload.mCAXS391Result
      state.submissionRequestInfo = action.payload.mCAXS701Result

      const { sexList, memberTypeList } = action.payload.mCAXS381Result
      const {
        graduationPeriodList,
        philosophyHumanitiesList,
        schoolTypeList,
        countryRegionList,
      } = action.payload.mCAXS371Result
      const {
        applicationRouteList,
        targetApplicationRouteList,
      } = action.payload.mCAXS361Result
      const {
        selectionFlowList,
        selectionStepList,
        judgementList
      } = action.payload.mCAXS391Result
      const {
        deliveryStatusList,
        deadlineTypeList,
        submitStatusList,
        deliveryDetailList,
        errorCode,
      } = action.payload.mCAXS701Result
      // MCBリプレース MCAXS701 END
      state.orderObjArray = {
        //画面の並び順にソートする処理用のオブジェクト
        sexCodeList: sexList.map(i => ({ sexCode: Number(i.value) })),
        memberTypeCodeList: memberTypeList.map(i => ({
          memberTypeCode: Number(i.value),
        })),
        graduationPeriodCodeList: graduationPeriodList.map(i => ({
          graduationPeriodCode: Number(i.value),
        })),
        philosophyHumanitiesCodeList: philosophyHumanitiesList.map(i => ({
          philosophyHumanitiesCode: Number(i.value),
        })),
        schoolTypeIdList: schoolTypeList.map(i => ({
          schoolTypeId: Number(i.value),
        })),
        countryRegionCodeList: countryRegionList.map(i => ({
          countryRegionCode: Number(i.value),
        })),
        applicationRouteInfoList: applicationRouteList.map(i => ({
          applicationRouteFlag: Number(i.applicationRouteFlag),
          applicationRouteId: Number(i.applicationRouteId),
        })),
        targetApplicationRouteInfoList: targetApplicationRouteList.map(
          i => ({
            targetApplicationRouteFlag: Number(i.applicationRouteFlag),
            targetApplicationRouteId: Number(i.applicationRouteId),
          })
        ),
        selectionFlowIdList: selectionFlowList.map(i => ({
          selectionFlowId: Number(i.selectionFlowSettingId),
        })),
        selectionStepList: selectionStepList.map(i => ({
          selectionStepId: Number(i.progressStatusSettingId),
        })),
        judgmentUndeterminedCodeList: judgementList.map(i => ({
          judgmentUndeterminedCode: Number(i.itemCode),
        })),
      }
      return state
    },
    // 検索条件設定
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      state.searchCondition = action.payload
      return state
    },
    // 検索条件エリア開閉フラグを設定
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
      return state
    },
    // アンケート回答モーダルの開閉フラグを設定
    setEntryReplyModalOpen(state, action: PayloadAction<boolean>) {
      state.entryReplyModalOpen = action.payload
      return state
    },
    // 管理項目モーダルの開閉フラグを設定
    setManagementModalOpen(state, action: PayloadAction<boolean>) {
      state.managementModalOpen = action.payload
      return state
    },
    // 管理項目モーダルの開閉フラグを設定
    setMCARS030SCriteriaParam(state, action: PayloadAction<MCAXS010SearchCondition>) {
      state.MCARS030SCriteriaParam = action.payload
      return state
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload
      return state
    },
    setCurrentTabMcb(state, action: PayloadAction<number>) {
      return state
    },
  },
})

export const {
  getMcaxs351Init,
  setMcaxs351Init,
  setSearchCondition,
  setOpen,
  setEntryReplyModalOpen,
  setManagementModalOpen,
  setMCARS030SCriteriaParam,
  setCurrentTab,
  setCurrentTabMcb,
} = advancedSearchSlice.actions
export default advancedSearchSlice.reducer
