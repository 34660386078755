import request from 'utils/request'
import { requestParam } from '../reducers/actionPreReducer'
export const fetchEntrySearchCriteriaPreferenceList = (requestBody: any) =>
  request({
    url: '/MCARS050/getList',
    method: 'post',
    data: requestBody
  })
export const sort = (requestparam: requestParam) =>
  request({
    url: '/MCARS050/sort',
    method: 'post',
    data: requestparam
  })
