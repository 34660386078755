import request from 'utils/request'

import { MCAZS010MessageQueryRequest } from 'types/MCAZS010/MCAZS010MessageQueryRequest'
import { MCAZS010MessageAllSelecteRequest } from 'types/MCAZS010/MCAZS010MessageAllSelecteRequest'

export const initReceiveRequest = (apiData: MCAZS010MessageQueryRequest) =>
request({
  url: '/MCAZS010/init',
  method: 'post',
  data: apiData,
})  

export const selectMessageReceiveRequest = (apiData: MCAZS010MessageQueryRequest) =>
  request({
    url: '/MCAZS010/find',
    method: 'post',
    data: apiData,
  })

  export const moveToTrashMessageRequest = (apiData: MCAZS010MessageAllSelecteRequest) =>
  request({
    url: '/MCAZS010/moveTrashMessage',
    method: 'post',
    data: apiData,
  })

export const receiveCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAZS010/receiveCoun/${recruitmentManagementDivision}`,
    method: 'post',
})

export const reserveCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAZS010/reserveCount/${recruitmentManagementDivision}`,
    method: 'post',
})

export const draftCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAZS010/draftCount/${recruitmentManagementDivision}`,
    method: 'post',
})

export const sendMessageMCAZS010Request = (apiData: MCAZS010MessageQueryRequest) =>
request({
  url: '/MCAZS010/getMessageSendIds',
  method: 'post',
  data: apiData,
})  

// MCB ���b�g�R start
export const mcbReserveCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAZS010/mcbReserveCount/${recruitmentManagementDivision}`,
    method: 'post',
})

export const mcbDraftCountRequest = (recruitmentManagementDivision: string) =>
  request({
    url: `/MCAZS010/mcbDraftCount/${recruitmentManagementDivision}`,
    method: 'post',
})
// MCB ���b�g�R end
