import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
// 初期画面初期化
const initialValues = {
  surname: '',
  firstName: '',
  recruitmentManagementDivision: '',
}

const mcaxs191NameRequest = {
  surname: '', // 姓
  firstName: '', // 名
  recruitmentManagementDivision: '', // 採用管理区分
}

export interface MCAXS191Init {
  surname: string // 姓
  firstName: string // 名
  screenId: string // 遷移元画面ID
}

const mcaxs191Init = {
  surname: '', // 姓
  firstName: '', // 名
  screenId: '', // 遷移元画面ID
}

const messageMap: { [key: string]: string } = {
  updateShowHideFlag: getMessage(magiContants.MESSAGECODE_MCAXS191_002),
}

// 年度対応 start
export const listener = (ev: {
  preventDefault: () => void
  returnValue: string
}) => {
  ev.preventDefault()
  ev.returnValue = getMessage(magiContants.MESSAGECODE_MCAXS191_001)
}
// 年度対応 end

export { initialValues, mcaxs191NameRequest, messageMap, mcaxs191Init }
