import { magiContants } from "utils/contants"
import { getMessage } from "common/messageUtil"

const initialFormValues = {
  recruitmentGroupSettingId: '',
  reqruitGroupName: '',
  recruitmentType: '',
  sysVersionNumber: 0,
  celectionId: [],
  AffiliationId: [],
  selectionTable: [],
  affiliationTable: [],
}

const recruitmentGroupInfoInitialValues = {
  recruitmentGroupSettingId: '',
  recruitmentGroupName: '',
  recruitmentType: '',
  sysVersionNumber: 0,
}

const editRequest = {
  recruitmentGroupId: '',
  recruitmentManagementDivision: ''
}

const initReqruitGroupName = ''

const textMap = {
  register: {
    message: getMessage(magiContants.MESSAGECODE_MCAES020_011),
  },
  update: {
    message: getMessage(magiContants.MESSAGECODE_MCAES020_012),
  },
  cancel: {
    message: getMessage(magiContants.MESSAGECODE_MCAES020_013),
  },
  delete: {
    message: getMessage(magiContants.MESSAGECODE_MCAES020_014),
  },
}

const titleTextMap = {
  selection: {
    title: '選考フロー名'
  },
  affiliation: {
    title: '氏名'
  },
  selectionTip: {
    title: 'この選考フローを対象から外します'
  },
  affiliationTip: {
    title: 'このアカウントを対象から外します'
  },
}

const buttonTextMap: { [key: string]: string } = {
  no: 'キャンセル',
  yes: 'OK',
  cancel: 'キャンセルする',
  ok: 'OK',
}

export {
  initialFormValues,
  recruitmentGroupInfoInitialValues,
  initReqruitGroupName,
  textMap,
  titleTextMap,
  buttonTextMap,
  editRequest,
}
