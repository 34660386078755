import React, {useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import Select, {components as componentsML, MenuListComponentProps } from 'react-select'
import { useErrorStyle } from 'components'


export interface OptionType {
  label: string
  value: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      fontSize: '11px',
      marginTop: '4px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily:
        'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      lineHeight: '1em',
      letterSpacing: '0.33px',
      color: '#e53935',
      whiteSpace: 'pre-wrap',
    },
    formControlSize: {
      width: '95%',
      marginTop:'0px',
    },
  })
)

function MenuList (props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div id='menuListScrollDiv' style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList,
}

interface Props extends FieldProps {
  optionList: OptionType[]
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultValue?: boolean
  secondFormName?: string
}

const SelectMessageReact = ({
  className,
  optionList,
  placeholder,
  field,
  form,
  disabled,
  onChange,
  defaultValue,
  secondFormName,
}: Props) => {
  const classes = useStyles()
  const errorclasses = useErrorStyle()
  const { name, value } = field
  const { errors, touched } = form
  const optionZero = (placeholder ? { label: placeholder, value: ''} : { label: '（以下から選択）', value: ''}) as OptionType
  const options = [optionZero, ...optionList]
  const [menuValue, setMenuValue] = useState(placeholder ? optionZero : null)
  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '30px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#c1d1eb',
      },
      '&:focus': {
        borderColor: '#3f71bd',
      },
      borderColor: errors[name] && touched[name] ? '#e53935' : '#8593a6',
      boxShadow: '0px 0px 0px 0px #8593a6',
    }),
    input: (provided: any) => ({ 
      ...provided, 
      margin: '0px',
      paddingBottom: '0px',
      paddingTop: '0px',
      '& input': {
        height: '20px',
        lineHeight: '18px',
      },
    }),
    indicatorsContainer: (provided: any) => ({ 
      ...provided, 
      height: '30px',
    }),
    option: (provided: any) => ({
      ...provided,
      whiteSpace: 'nowrap',
      lineHeight: '10px',
    }),
  }

  const handleChange = (newValue: any) => {
    if (newValue.value !== value) {
      form.setFieldValue(name, newValue.value)
      form.setFieldTouched(name, true)
      if (secondFormName) {
        // eslint-disable-next-line prefer-const
        let item: any = optionList.find((i: OptionType) => {
          return i.value.toString() === newValue.value.toString()
        })
        form.setFieldValue(secondFormName, item ? item.label : '')
        form.setFieldTouched(secondFormName, true)
      }
      if (newValue.value !== magiContants.PREFECTURES_OFFLINE) {
        if (secondFormName) {
          if (secondFormName === 'prefectures' || secondFormName === 'prefecturesName') {
            form.setFieldValue('postalCode', '')
          } else if (secondFormName === 'vacPrefectures' || secondFormName === 'vacPrefecturesName') {
            form.setFieldValue('vacPostalCode', '')
          }
        }
      }
    }
  }
  
  return (
    <>
      <div className={className}>
        <Select 
          classes={classes}
          styles={selectStyles}
          placeholder={placeholder}
          options={options}
          components={components}
          isClearable={false}
          value={options.filter(i => i.value == value)}
          defaultValue={options.filter(i => i.value == value)}
          onChange={handleChange}
          isSearchable={true}
          filterOption={customSelectOptions.filterOption}
          noOptionsMessage={({ inputValue }) => {
            return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
          }}
          menuPlacement={'auto'}
          // menuPortalTarget={document.body}
          // menuPosition={'fixed'}
        />
      </div>
      {errors[name] && touched[name] ? (
        <p className={errorclasses.helperText} style={{ fontFamily: "'Noto Sans JP', sans-serif" }}>{errors[name]}</p>
      ) : null}
    </>
  )
}
export default SelectMessageReact
