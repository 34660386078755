import {
  Button, IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle,
  List, ListItem, ListItemText,
  makeStyles, Tooltip as MuiTooptip, Typography, withStyles, Chip,
  FormControlLabel, Checkbox, Radio, RadioGroup,
} from "@material-ui/core"
import { getMessage } from "common/messageUtil"
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { FormControl } from '..'
import { RootState } from "reducers"
import {
  FlowMasterInfo, SelectionFlowInfo, selectionFlowCheckListInit,
  DecisionInfo, setSelectionFlowCheckListInit, checkSameFlag, createSearchCondition,
} from 'componentsHsc/AdvancedSearch/Config/SelectionFlowStepPassFailModalConfig'
import { FormField } from "components"
import CancelIcon from '@material-ui/icons/Cancel'
import {
  SelectionFlowObjDto, SelectionFlowStepPassFailObj
} from "../Config/searchConditionConfig"
import { magiContants } from "utils/contants"
import { isEmpty } from "common/generalUtil"

const useStyles = makeStyles(theme => ({
  noCheck: {
    background: 'url(../../css/img/common/checkbox.png) no-repeat 12px 12px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '12px',
    width: '12px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  checked: {
    backgroundColor: '#3f71bd',
    backgroundPosition: 'center center',
    borderColor: '#3f71bd',
    background: 'url(../../css/img/common/checkbox.png) no-repeat 12px 12px #fff',
    border: '1px solid #8592a6',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-block',
    height: '12px',
    width: '12px',
    verticalAlign: 'middle',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor: 'auto',
  },
  iconButton: {
    padding: 0,
    float: 'right',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogContainer: {
    overflowY: 'hidden',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: '#263238',
      textDecoration: 'underline',
      '& span': {
        color: '#263238',
      },
    },
  },
  scrollList: {
    padding: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
    wordBreak: 'break-all',
    msOverflowStyle: 'auto',
    maxHeight: `${document.body.clientHeight - 420}px`,
    '& .MuiListItem-root': {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    '& span': {
      whiteSpace: 'pre-wrap',
    },
  },
  selectionPhaseJudgment: {
    marginLeft: '45px',
    paddingLeft: '4px',
  },
  checkBox: {
    width: '80%',
    display: 'block',
    marginTop: '0px'
  },
  selectAllCheckBox: {
    marginLeft: '45px',
    paddingLeft: '6px'
  },
  selectionStepCheckBox: {
    marginLeft: '45px',
    maxWidth: '533px',
  },
  exceptCheckBox: {
    margin: '10px 65px 0px',
    '& .MuiFormControl-root': {
      verticalAlign: 'middle',
    }
  },
  selectionStepName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hrStyle: {
    borderColor: 'black',
    margin: '2px 0',
  },
  passFailCheckBox: {
    marginLeft: '35px',
    '& label': {
      paddingRight: theme.spacing(5)
    },
    '& .MuiFormControlLabel-label': {
      minWidth: '42px',
    }
  },
  chipcontent: {
    maxHeight: '120px',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    msOverflowStyle: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    '& span': {
      whiteSpace: 'pre-wrap',
    },
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '30px !important',
  },
  checkBoxRoot: {
    padding: '6px 5px 5px 5px',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  checkBoxLabel: {
    '& .MuiIconButton-label>input': {
      width: 0,
    },
  },
}))

const Tooltip = withStyles({
  tooltip: {
    fontSize: '1em',
  },
  popper: {
    whiteSpace: 'pre-line'
  }
})(MuiTooptip)

const selectionIncludeBeforeChange = {
  value: '1', label: '選考フロー変更前の履歴を含む'
}
const SelectionFlowSelectAll = {
  value: '1', label: '選考ステップ×合否を全て選択'
}
const SelectAll = {
  value: '1', label: '全て選択'
}
const Except = {
  value: '1', label: 'を除く'
}
const searchList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]
// 25KH #75311 start
const selectionPhaseList = [
  { label: '現在の選考段階', value: '0' },
  { label: '過去の履歴を含む選考段階', value: '1' }, 
]
// 25KH #75311 end
/**
 * 選考フロー×選考ステップ×合否検索条件を生成
 */
export const generateLabelSelectionFlowStepPassFail = (item: SelectionFlowObjDto) => {
  let resultText: string = ''
  if (item.selectionFlowName) {
    resultText += item.selectionFlowName + '：'
  }
  // 25KH #75311 start
  if (isEmpty(item.selectionPhaseJudgmentFlag) || '0' === item.selectionPhaseJudgmentFlag) {
    resultText += '現在 '
  } else if ('1' === item.selectionPhaseJudgmentFlag) {
    resultText += '履歴 選考変更前を含む '
  }else{
    resultText += '履歴 選考変更前を含まない '
  }
  // 25KH #75311 end
  if ('1' === item.selectionFlowSelectAllFlag) {
    resultText += item.selectionFlowSelectAllName
  } else {
    if (item.selectionStepObj.length > 0) {
      item.selectionStepObj.forEach(stepObj => {
        if (stepObj.passFailObj.length > 0) {
          resultText += stepObj.selectionStepName + '（'
          stepObj.passFailObj.forEach(passFail => {
            resultText += passFail.passFailName + '、'
          })
          if ('、' === resultText.charAt(resultText.length - 1).toString()) {
            resultText = resultText.substring(0, resultText.length - 1)
          }
          resultText += '）、'
        }
      })
      if ('、' === resultText.charAt(resultText.length - 1).toString()) {
        resultText = resultText.substring(0, resultText.length - 1)
      }
    }
  }
  if ('1' === item.exceptCode) {
    resultText += item.exceptName
  }
  return resultText
}
export const stringToEllipsis = (target: string | null) => {
  if (target !== null && target.length > magiContants.MCAXS691_LABEL_LENGTH) {
    return target.slice(0, magiContants.MCAXS691_LABEL_LENGTH) + "..."
  } else {
    return target
  }
}

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

const SelectionFlowStepPassFailModal = ({ open, onClose, form }: Props) => {
  const classes = useStyles()

  // チェックボックスCSS
  const icon = () => { return <div className={classes.noCheck} /> }
  const iconChecked = () => { return <div className={classes.checked} /> }

  // 「選考フロー情報」と「選考フロー×選考ステップ×合否情報」の統合情報
  const { selectionFlowTitleList, flowMasterInfoList } = useSelector((state: RootState) => state.advancedSearchModal.flowMasterModalInfo)

  // 選択済みの選考フロー順番号
  const [selectedSub, setSelectedSub] = useState(0)
  // 選択済み選考フローの選考ステップ×合否情報
  const [selectedFlowList, setSelectedFlowList] = useState([] as FlowMasterInfo[])
  // 選択済み選考フローのcheckList、チェックありのcheckboxはtrue、チェックなしのchekcboxはfalse
  const [checkList, setCheckList] = useState(selectionFlowCheckListInit)
  // 追加済みの「選考フロー×選考ステップ×合否検索条件」
  const [chipArea, setChipArea] = useState([] as SelectionFlowObjDto[])
  // 検索条件指定 AND/OR
  const [searchFlag, setSearchFlag] = useState(searchList[0].value)
  // 「キャンセルする」ボタンのダイアログ
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)

  // モーダル初期化
  useEffect(() => {
    if (open) {
      setSelectedSub(0)
      let flowList: FlowMasterInfo[] = []
      if (selectionFlowTitleList && selectionFlowTitleList.length > 0) {
        flowList = flowMasterInfoList.filter(item => item.selectionFlowSettingId === selectionFlowTitleList[0].selectionFlowSettingId)
      }
      setSelectedFlowList(flowList)
      setCheckList(setSelectionFlowCheckListInit(flowList))
      if (form.values.selectionFlowStepPassFailObj) {
        const flowStepPassFail = form.values.selectionFlowStepPassFailObj
        setSearchFlag(flowStepPassFail.searchConditionFlag)
        if (flowStepPassFail.selectionFlowObj) {
          setChipArea(flowStepPassFail.selectionFlowObj.slice())
        } else {
          setChipArea([])
        }
      } else {
        setChipArea([])
        setSearchFlag(searchList[0].value)
      }
    }
  }, [open, flowMasterInfoList])

  // 選考フローリンクを選択する
  const handleSubClick = (selectedFlowTitle: SelectionFlowInfo, index: number) => () => {
    if (selectedSub !== index) {
      setSelectedSub(index)
      const flowList = flowMasterInfoList.filter(item => item.selectionFlowSettingId === selectedFlowTitle.selectionFlowSettingId)
      setSelectedFlowList(flowList)
      setCheckList(setSelectionFlowCheckListInit(flowList))
      const toTop = document.getElementById('scrollToListTop')
      if (toTop) {
        toTop.scrollIntoView()
      }
    }
  }

  // 「選考ステップ×合否を全て選択」checkbox
  const handleSelectionFlowSelectAllClick = () => (event: any) => {
    // すべてのcheckboxのチェック状態を設定する
    checkList.selectionStepObj.forEach(selectionStep => {
      selectionStep.passFailObj.forEach(passFail => {
        passFail.checkFlag = event.target.checked
      })
    })
    setCheckList({
      ...checkList,
      selectionFlowSelectAllFlag: event.target.checked,
    })
  }

  // 「全て選択」checkbox
  const handleSelectAllClick = (item: FlowMasterInfo) => (event: any) => {
    const selectionStepList = checkList.selectionStepObj.find(obj => obj.selectionStepCode === item.progressStatusSettingId)
    // 「選考ステップ設定ID」によって、「全て選択」チェックボックスのチェック状態を設定する
    if (selectionStepList) {
      selectionStepList.passFailObj.forEach(item => {
        item.checkFlag = event.target.checked
      })
    }
    // すべてのcheckboxが選択されているかどうかを判断し、「選考ステップ×合否を全て選択」checkboxのチェック状態を設定する
    let selectionFlowSelectAllFlag = true
    for (var i = 0; i < checkList.selectionStepObj.length; i++) {
      for (var j = 0; j < checkList.selectionStepObj[i].passFailObj.length; j++) {
        if (!checkList.selectionStepObj[i].passFailObj[j].checkFlag) {
          selectionFlowSelectAllFlag = false
          break;
        }
      }
    }
    setCheckList({
      ...checkList,
      selectionFlowSelectAllFlag: selectionFlowSelectAllFlag,
    })
  }
  // 「全て選択」checkboxのチェック状態を判断する
  const handleSelectAllCheck = (item: FlowMasterInfo) => {
    const selectionStepList = checkList.selectionStepObj.find(obj => obj.selectionStepCode === item.progressStatusSettingId)
    if (selectionStepList && selectionStepList.passFailObj) {
      let checkFlag = true
      for (var i = 0; i < selectionStepList.passFailObj.length; i++) {
        if (!selectionStepList.passFailObj[i].checkFlag) {
          checkFlag = false
          break;
        }
      }
      return checkFlag
    }
    return false
  }

  // 「合否」checkbox
  const handlePassFailClick = (item: FlowMasterInfo, option: DecisionInfo) => (event: any) => {
    const selectionStepList = checkList.selectionStepObj.find(obj => obj.selectionStepCode === item.progressStatusSettingId)
    if (selectionStepList) {
      const passFail = selectionStepList.passFailObj.find(obj => obj.passFailCode === option.value)
      // 「選考ステップ設定ID」と「判定区分種別」によって、「合否」チェックボックスのチェック状態を設定する
      if (passFail) {
        passFail.checkFlag = event.target.checked
      }
    }
    // すべてのcheckboxが選択されているかどうかを判断し、「選考ステップ×合否を全て選択」checkboxのチェック状態を設定する
    let selectionFlowSelectAllFlag = true
    for (var i = 0; i < checkList.selectionStepObj.length; i++) {
      for (var j = 0; j < checkList.selectionStepObj[i].passFailObj.length; j++) {
        if (!checkList.selectionStepObj[i].passFailObj[j].checkFlag) {
          selectionFlowSelectAllFlag = false
          break;
        }
      }
    }
    setCheckList({
      ...checkList,
      selectionFlowSelectAllFlag: selectionFlowSelectAllFlag,
    })
  }
  // 「合否」checkboxのチェック状態を判断する
  const handlepassFailCheck = (item: FlowMasterInfo, option: DecisionInfo) => {
    const selectionStepList = checkList.selectionStepObj.find(obj => obj.selectionStepCode === item.progressStatusSettingId)
    if (selectionStepList) {
      const passFail = selectionStepList.passFailObj.find(obj => obj.passFailCode === option.value)
      if (passFail) {
        return passFail.checkFlag
      }
    }
    return false
  }

  // 「追加」ボックスの活性判断
  const addBottonDisabled = () => {
    let selectionFlowSelectAllFlag = true
    for (var i = 0; i < checkList.selectionStepObj.length; i++) {
      for (var j = 0; j < checkList.selectionStepObj[i].passFailObj.length; j++) {
        if (checkList.selectionStepObj[i].passFailObj[j].checkFlag) {
          selectionFlowSelectAllFlag = false
          break;
        }
      }
    }
    return selectionFlowSelectAllFlag;
  }
  // 「追加」ボタンを押下
  const handleAddSearch = () => {
    const selectionFlowObj = createSearchCondition(checkList);
    let chipList: SelectionFlowObjDto[] = []
    let sameFlag = true
    if (chipArea && chipArea.length > 0) {
      for (var i = 0; chipArea.length > i; i++) {
        chipList.push(chipArea[i])
        if (sameFlag) {
          sameFlag = checkSameFlag(chipArea[i], selectionFlowObj)
        }
      }
    }
    // 一致の検索条件存在しないの場合、検索条件追加できる
    if (sameFlag) {
      chipList.push(selectionFlowObj)
    }
    setChipArea(chipList)
    setCheckList(setSelectionFlowCheckListInit(selectedFlowList))
    const toTop = document.getElementById('scrollToListTop')
    if (toTop) {
      toTop.scrollIntoView()
    }
    setAddSearchFlag(true)
  }
  // 「追加」ボタンを押下場合、最新の検索条件にスクロール
  // 検索条件を削除の場合、検索条件エリアスクロールしない
  const [addSearchFlag, setAddSearchFlag] = useState(false)
  useEffect(() => {
    const toBottom = document.getElementById('chipScrollToBottom')
    if (toBottom && addSearchFlag) {
      toBottom.scrollIntoView()
      setAddSearchFlag(false)
    }
  }, [chipArea])

  // 選択済みの検索条件を削除する
  const handleDeleteChip = (item: SelectionFlowObjDto) => () => {
    if (chipArea && chipArea.length > 0) {
      let chipvallist: SelectionFlowObjDto[] = chipArea
      const newConditionList = chipvallist.filter(i => i !== item)
      if (newConditionList && newConditionList.length > 0) {
        setChipArea([])
        setChipArea(newConditionList)
      } else {
        setChipArea([])
      }
    }
  }

  // キャンセルするボタンを押下
  const handleCancel = () => {
    setCancelDialogOpen(true)
  }
  const handleDialogClose = () => {
    setCancelDialogOpen(false)
    onClose()
  }
  // OKボタンを押下
  const handleSubmit = () => {
    const selectionFlowObj = createSearchCondition(checkList)
    let selectionFlowStepPassFailObj: SelectionFlowStepPassFailObj = {
      searchConditionFlag: searchFlag,
      searchConditionName: '0' === searchFlag ? 'AND' : 'OR',
      selectionFlowObj: [],
    }
    let sameFlag = true
    if (chipArea && chipArea.length > 0) {
      for (var i = 0; chipArea.length > i; i++) {
        selectionFlowStepPassFailObj.selectionFlowObj.push(chipArea[i])
        if (sameFlag) {
          sameFlag = checkSameFlag(chipArea[i], selectionFlowObj)
        }
      }
    }
    // 一致の検索条件存在しない、「選考ステップ×合否を全て選択」チェックありまたは「合否」チェックありの場合、検索条件追加できる
    if (sameFlag && ('1' === selectionFlowObj.selectionFlowSelectAllFlag || selectionFlowObj.selectionStepObj.length > 0)) {
      selectionFlowStepPassFailObj.selectionFlowObj.push(selectionFlowObj)
    }
    if (selectionFlowStepPassFailObj.selectionFlowObj &&
      selectionFlowStepPassFailObj.selectionFlowObj.length > 0) {
      form.setFieldValue('selectionFlowStepPassFailObj', selectionFlowStepPassFailObj)
    } else {
      form.setFieldValue('selectionFlowStepPassFailObj', null)
    }
    onClose()
  }
  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='md'>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>{'選考フロー×選考ステップ×合否　を指定'}</label>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '0px' }}>
          <div>
            <Typography variant='body1' gutterBottom style={{ float: 'left', color: 'black' }}>
              <div dangerouslySetInnerHTML={{ __html: getMessage(magiContants.MESSAGECODE_MCAXS691_000) }} />
            </Typography>
            <Tooltip title={getMessage(magiContants.MESSAGECODE_MCAXS691_002)} placement='top' tabIndex={-1}>
              <IconButton className={classes.iconButton}>
                <HelpOutlineIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogContent className={classes.dialogContainer}>
          <div className='ps-container ps'>
            <div className={classes.select}>
              <List className={classes.scrollList} style={{ marginTop: '-4px' }}>
                {selectionFlowTitleList ? selectionFlowTitleList.map((selectionFlowTitle, index) => (
                  <ListItem
                    button
                    key={selectionFlowTitle.selectionFlowSettingId}
                    selected={selectedSub === index}
                    onClick={handleSubClick(selectionFlowTitle, index)} >
                    <ListItemText className={classes.listItemText} primary={selectionFlowTitle.selectionName} />
                  </ListItem>
                )) : null}
              </List>
              <div style={{ borderLeft: '1px solid gray' }}>
                <List className={classes.scrollList}>
                  <div id='scrollToListTop' />
                  {/* 25KH #75311 start */}
                  <RadioGroup row value={checkList.selectionPhaseJudgmentFlag}
                    className={classes.selectionPhaseJudgment}
                    onChange={event => setCheckList({
                      ...checkList,
                      selectionPhaseJudgmentFlag: event.target.value,
                      selectionPhaseJudgmentName: event.target.value ==='0' ? event.target.name : magiContants.SELECTION_INCLUDE_BEFORE_CHANGE,
                      selectionIncludeBeforeChangeFlag: event.target.value ==='0' ? false : true,
                    })}>
                    {selectionPhaseList.map(i => (
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            value={i.value}
                            checked={i.value === (checkList.selectionPhaseJudgmentFlag === '2' ? '1' : checkList.selectionPhaseJudgmentFlag)}
                            name={i.label}
                            style={{ padding: '9px 5px' }}
                          />
                        }
                        label={i.label}
                      />
                    ))}
                    <div style={{ margin: '6px 0px 0px -20px' }}>
                      <span style={{ fontSize: 13 }}>{'（'}</span>
                      <FormControlLabel style={{ margin: '-4px 2px 0px -3px' }}
                        control={
                          <Checkbox
                            color='primary'
                            name={'SelectionIncludeBeforeChangeFlag'}
                            onChange={event => setCheckList({
                              ...checkList,
                              selectionPhaseJudgmentFlag: !event.target.checked ? '2': checkList.selectionPhaseJudgmentFlag,
                              selectionPhaseJudgmentName: !event.target.checked ? magiContants.SELECTION_NOT_INCLUDE_BEFORE_CHANGE 
                                  : magiContants.SELECTION_INCLUDE_BEFORE_CHANGE,
                              selectionIncludeBeforeChangeFlag: event.target.checked,
                            })}
                            checked={checkList.selectionIncludeBeforeChangeFlag}
                            value={selectionIncludeBeforeChange.value}
                            className={classes.checkBoxRoot}
                            disabled={'0' === checkList.selectionPhaseJudgmentFlag}
                            icon={icon()}
                            checkedIcon={iconChecked()}
                          />
                        }
                        label={selectionIncludeBeforeChange.label}
                      />
                      <span style={{ fontSize: 13 }}>{'）'}</span>
                    </div>
                  </RadioGroup>
                  <br/>
                  {/* 25KH #75311 end */}
                  <FormField className={`${classes.checkBox} ${classes.selectAllCheckBox}`}>
                    <FormControlLabel className={classes.checkBoxLabel}
                      control={
                        <Checkbox
                          color='primary'
                          name={'selectionFlowSelectAllFlag'}
                          onChange={handleSelectionFlowSelectAllClick()}
                          checked={checkList.selectionFlowSelectAllFlag}
                          value={SelectionFlowSelectAll.value}
                          className={classes.checkBoxRoot}
                          icon={icon()}
                          checkedIcon={iconChecked()}
                        />
                      }
                      label={SelectionFlowSelectAll.label}
                    />
                  </FormField>
                  {selectedFlowList ? selectedFlowList.map(item =>
                    <FormField className={`${classes.checkBox} ${classes.selectionStepCheckBox}`}>
                      <div className={classes.selectionStepName}>
                        <span style={{ paddingBottom: '5px' }}>{item.progressName}</span>
                        {['0', '1', '2'].includes(item.decisionDivisionType) ?
                          <FormControlLabel className={classes.checkBoxLabel}
                            control={
                              <Checkbox
                                color='primary'
                                name={`${item.progressStatusSettingId}selectAllFlag`}
                                onChange={handleSelectAllClick(item)}
                                checked={handleSelectAllCheck(item)}
                                value={SelectAll.value}
                                className={classes.checkBoxRoot}
                                icon={icon()}
                                checkedIcon={iconChecked()}
                              />
                            }
                            label={SelectAll.label}
                          />
                          : undefined}
                      </div>
                      <hr className={classes.hrStyle} />
                      <div className={classes.passFailCheckBox}>
                        {item.passFailList.map((option, passFailIndex) => (
                          <>
                            <FormControlLabel className={classes.checkBoxLabel}
                              control={
                                <Checkbox
                                  color='primary'
                                  name={`${item.progressStatusSettingId}passFailObj[${passFailIndex}]`}
                                  onChange={handlePassFailClick(item, option)}
                                  checked={handlepassFailCheck(item, option)}
                                  value={option.value}
                                  className={classes.checkBoxRoot}
                                  icon={icon()}
                                  checkedIcon={iconChecked()}
                                />
                              }
                              label={option.label}
                            />
                          </>
                        ))}
                      </div>
                    </FormField>
                  ) : null}
                </List>
                <FormField className={`${classes.checkBox} ${classes.exceptCheckBox}`}>
                  <FormControlLabel key={Except.value} className={classes.checkBoxLabel}
                    control={
                      <Checkbox
                        color='primary'
                        name={'exceptFlag'}
                        onChange={event => setCheckList({
                          ...checkList,
                          exceptFlag: event.target.checked,
                        })}
                        checked={checkList.exceptFlag}
                        value={Except.value}
                        className={classes.checkBoxRoot}
                        icon={icon()}
                        checkedIcon={iconChecked()}
                        tabIndex={-1}
                      />
                    }
                    label={Except.label}
                  />
                  <Button
                    disabled={addBottonDisabled()}
                    onClick={handleAddSearch}
                    color='primary'
                    variant='contained'
                    className={classes.confirmButton}>
                    追加
                  </Button>
                </FormField>
              </div>
            </div>
          </div>
        </DialogContent>
        {chipArea && chipArea.length > 0 && (
          <DialogContent className={classes.dialogContainer}>
            <hr className={classes.hrStyle} />
            <div style={{ float: 'left' }}>
              <RadioGroup row value={searchFlag}
                onChange={event => setSearchFlag(event.target.value)}>
                {searchList.map(i => (
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        value={i.value}
                        checked={i.value === searchFlag}
                        name={i.label}
                      />
                    }
                    label={i.label}
                  />
                ))}
              </RadioGroup>
            </div>
            <div className={classes.chipcontent}>
              {chipArea.map(item => (
                <Chip
                  className={classes.chip}
                  label={stringToEllipsis(generateLabelSelectionFlowStepPassFail(item))}
                  title={generateLabelSelectionFlowStepPassFail(item)}
                  onDelete={handleDeleteChip(item)}
                  deleteIcon={<CancelIcon />}
                />
              ))}
              <div id='chipScrollToBottom' />
            </div>
          </DialogContent>
        )}

        <DialogActions className={classes.actions}>
          <Button
            onClick={handleCancel}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelDialogOpen}>
        <DialogTitle>{getMessage(magiContants.MESSAGECODE_MCAXS691_001)}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleDialogClose} color='primary'>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default SelectionFlowStepPassFailModal