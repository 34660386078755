import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CommentUpdate {
  selectionManagementId: number[] //選考管理ID
  comment: string //コメント
  screenId:string
  prevScreenId:string
}

const initialState: CommentUpdate = {
  selectionManagementId: [], //選考管理ID
  comment: '', //コメント
  screenId:'',
  prevScreenId:'',
}
const commentUpdateSlice = createSlice({
    name: 'accout',
    initialState,
    reducers: {
      // 画面初期表示時用
      commentInit(state) {
        return state
      }, 

      commentUpdate(state, action: PayloadAction<{request: CommentUpdate, onclose?: () => void}>) {
        return state;
      },
      setInputValuesAgain(state, action: PayloadAction<CommentUpdate>) {
        state = action.payload
        return state;
      }
    },
  })
  
  export const {
    commentInit,
    commentUpdate,
    setInputValuesAgain,
  } = commentUpdateSlice.actions

  export default commentUpdateSlice.reducer