import {
  mCBIS010InitRequest,
  mCBIS010SortRequest,
  mCBIS010OpenSearchDetailRequest,
  mCBIS010OpenSearchTitleRequest,
  mCBIS010UpdateSearchTitleRequest,
  mCBIS010ExecuteRequest,
  mCBIS010selectSearchCriteriaRequest,
} from 'apis/MCBIS010Api'
import history from 'utils/history'
import { routeList } from 'routes/routes'

import { initRequest, updateRequest, createRequest } from 'apis/MCBIS030Api'
import {

  getSearchCriteriaMcbSightInfo,
  setSearchCriteriaMcbSightInfo,
  updatescsMcbDisplayOrder,
  getSearchCriteriaMcb,
  setSearchCriteria,
  setDetailDialogOpen,
  getSearchCriteriaMcbName,
  setSearchCriteriaMcbName,
  setEditDialogOpen,
  updateSearchCriteriaMcbName,
  deleteSearchCriteriaMcb,
  getSelectCriteriaList,
  createsearchCriteriaName,
  setDialogOpen,
  setSelectData,
  updatesearchCriteria,
  setEscSettingId,

  selectSearchCriteriaMcb,
} from 'reducers/selectCriteriaManagementMcbReducer'
import { MCBIS010InitRequest } from 'types/MCBIS010/MCBIS010InitRequest'
import { MCBIS010OrderRequest } from 'types/MCBIS010/MCBIS010OrderRequest'
import { MCBIS010IdRequest } from 'types/MCBIS010/MCBIS010IdRequest'
import { MCBIS010UpdateSearchTitleRequest } from 'types/MCBIS010/MCBIS010UpdateSearchTitleRequest'
import { MCBIS010BatchProcessRequest } from 'types/MCBIS010/MCBIS010BatchProcessRequest'
import { MCARS030InitRequest } from 'types/MCARS030/MCARS030InitRequest'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'

// MCBIS010 画面初期表示
function* getSearchCriteriaMcbSightInfoSaga() {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCBIS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 初期データ取得
    const data = yield call(mCBIS010InitRequest, request)
    yield put(setSearchCriteriaMcbSightInfo(data))
  } catch (error) {
  }
}

// MCBIS010 並び替え
function* updatescsMcbDisplayOrderSaga(action: ReturnType<typeof updatescsMcbDisplayOrder>) {
  try {
    let updateOrders: MCBIS010OrderRequest = {
      entrSearchCriteriaSettingId: [],
      displayOrder: [],
      versionNumber: [],
    }
    action.payload.forEach(item => {
      updateOrders.entrSearchCriteriaSettingId.push(
        item.entrySearchCriteriaSettingId
      )
      updateOrders.displayOrder.push(item.displayOrder)
      updateOrders.versionNumber.push(item.sysVersionNumber)
    })
    // 並び替え
    yield call(mCBIS010SortRequest, updateOrders)

    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let request: MCBIS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 最新データ取得
    const data = yield call(mCBIS010InitRequest, request)
    yield put(setSearchCriteriaMcbSightInfo(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBIS010 検索条件取得
function* getSearchCriteriaMcbSaga(action: ReturnType<typeof getSearchCriteriaMcb>) {
  try {
    let searchCriteria: MCBIS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload,
    }
    // 検索条件取得
    const data = yield call(mCBIS010OpenSearchDetailRequest, searchCriteria)
    yield put(setSearchCriteria(data))
    // 「検索条件名」画面を表示
    yield put(setDetailDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBIS010 検索条件名取得
function* getSearchCriteriaMcbNameSaga(action: ReturnType<typeof getSearchCriteriaMcbName>) {
  try {
    let searchCriteriaMcbName: MCBIS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload,
    }
    // 検索条件名取得
    const data = yield call(mCBIS010OpenSearchTitleRequest, searchCriteriaMcbName)
    yield put(setSearchCriteriaMcbName(data))
    // 「検索条件名の設定」画面を表示
    yield put(setEditDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBIS010 検索条件名更新
function* updateSearchCriteriaMcbNameSaga(action: ReturnType<typeof updateSearchCriteriaMcbName>) {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let updateSearchTitle: MCBIS010UpdateSearchTitleRequest = {
      entrSearchCriteriaSettingId: action.payload.entrySearchCriteriaSettingId,
      searchCriteriaName: action.payload.searchCriteriaName,
      versionNumber: action.payload.sysVersionNumber,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 検索条件名更新
    yield call(mCBIS010UpdateSearchTitleRequest, updateSearchTitle)

    // 最新データ取得
    let request: MCBIS010InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(mCBIS010InitRequest, request)
    yield put(setSearchCriteriaMcbSightInfo(data))
    // 「検索条件名の設定」画面を非表示
    yield put(setEditDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBIS010_006)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCBIS010 検索条件削除
function* deleteSearchCriteriaMcbSaga(action: ReturnType<typeof deleteSearchCriteriaMcb>) {
  try {
    // 採用管理区分
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    let deleteInfo: MCBIS010BatchProcessRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    // 検索条件削除
    const data = yield call(mCBIS010ExecuteRequest, deleteInfo)
    yield put(setSearchCriteriaMcbSightInfo(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBIS010_002)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}


// 初期化表示
export function* getSelectCriteriaListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<typeof getRecruitmentManagementDivision> = yield select(getRecruitmentManagementDivision)
    let request: MCARS030InitRequest = {
      recruitmentManagementDivision: recruitmentManagementDivision,
    }
    const data = yield call(initRequest, request);
    yield put(setSelectData(data));
    yield put(setDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 検索条件名を更新する
export function* updatesearchCriteriaSaga(action: ReturnType<typeof updatesearchCriteria>) {
  try {
    yield call(updateRequest, action.payload);
    // データ更新成功
    yield put(setDialogOpen(false))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBIS030_009)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 検索条件名を登録する
export function* createsearchCriteriaSaga(action: ReturnType<typeof createsearchCriteriaName>) {
  try {
    const data = yield call(createRequest, action.payload);
    // チェック正常、データ保存成功
    yield put(setDialogOpen(false))
    yield put(setEscSettingId(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCBIS030_009)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}


export function* selectSearchCriteriaMcbSaga(action:ReturnType<typeof selectSearchCriteriaMcb>){
  try{
    let selectCriteria: MCBIS010IdRequest = {
      entrSearchCriteriaSettingId: action.payload.entrySearchCriteriaSetting
    }
    yield call(mCBIS010selectSearchCriteriaRequest,selectCriteria)
        // MCBHS010:提出リクエスト管理
        const applicantManagement = {
          pathname: routeList.submissionRequestManagement,
          state: {
            request: {
              ...action.payload.MCBHS040initScreenTransitionInput,
              entrySearchCriteriaSettingId: action.payload.entrySearchCriteriaSetting,
              screenId: 'MCBIS010'
            }
          },
        }
        history.push(applicantManagement)
  } catch(error) {
    yield put(openModal(error.message))
  }
}

export default function* selectCriteriaMcbSaga() {
  yield all([
    // MCBIS010
    takeEvery(getSearchCriteriaMcbSightInfo, getSearchCriteriaMcbSightInfoSaga),
    takeLatest(updatescsMcbDisplayOrder, updatescsMcbDisplayOrderSaga),
    takeLatest(getSearchCriteriaMcb, getSearchCriteriaMcbSaga),
    takeLatest(getSearchCriteriaMcbName, getSearchCriteriaMcbNameSaga),
    takeLatest(updateSearchCriteriaMcbName, updateSearchCriteriaMcbNameSaga),
    takeLatest(deleteSearchCriteriaMcb, deleteSearchCriteriaMcbSaga),
    // MCBIS030
    takeEvery(getSelectCriteriaList, getSelectCriteriaListSaga),
    takeLatest(createsearchCriteriaName, createsearchCriteriaSaga),
    takeLatest(updatesearchCriteria, updatesearchCriteriaSaga),
    takeEvery(selectSearchCriteriaMcb,selectSearchCriteriaMcbSaga),
  ])
}
