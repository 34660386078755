const templateTypeList = [
  { name: 'searchTemplateTypeGeneral', label: '汎用', id: '1' },
  { name: 'searchTemplateTypeOffer', label: '合格通知', id: '2' },
  { name: 'searchTemplateTypeFailure', label: '不採用通知', id: '3' },
];

const initialFilterValues = {
  searchTemplateName: '',
  templateTypes: {
    searchTemplateTypeGeneral: '0',
    searchTemplateTypeOffer: '0',
    searchTemplateTypeFailure: '0',
  },
  searchTemplateWord: '',
  searchTemplateHidden: '0',
};

const submitValues = {
  targetYear: '',
  recruitmentManagementDivision: '',
};

const initialMCBJS010SubmissionReqTemplateList = {
  submissionReqTemplateSearchCondition: submitValues,
  targetYear: '',
  recruitmentManagementDivision: '',
  maxSubmissionReqTemplate: '',
  submissionReqTemplateResult: [],
}

const initialopenModal = {
  openCheckModal: false,
  modalMessage: '',
}

const textMap: { [key: string]: string } = {
  reOrderTip: 'ドラッグ＆ドロップで表示順を入れ替えます',
}

export { initialFilterValues, templateTypeList, submitValues, initialMCBJS010SubmissionReqTemplateList, initialopenModal, textMap };
