import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Dialog, colors, DialogTitle, Grid, CardActions, DialogContent } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import { routeList } from 'routes/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import history from 'utils/history'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { setMcaxs191ClearReducer } from 'reducers/jobSeekerInfoReducer'

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: '#133e80',
    },
    title: {
        color: (theme.palette as any).white,
        fontWeight: 900,
        fontSize: 24,
        cursor:'auto',
      },
    cancelButton: {
        color: '#1a1a1a',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#e0e0e0',
        borderColor: '#cbcbcb',
        '&:hover': {
          backgroundColor: '#c1d1eb',
          borderColor: '#999999',
        },
        minWidth: 150,
        float:"left",
    },
    linkButton: {
        margin: theme.spacing(2, 0, 2, 4),
        color: '#ffffff',
        border: '1px solid',
        borderRadius: '4px',
        backgroundColor: '#3380cc',
        borderColor: '#3380cc',
        '&:hover': {
          backgroundColor: '#4596e6',
          borderColor: '#3380cc',
        },
        minWidth: 150,
    
    },
    fontSize: {
        margin: theme.spacing(2, 0, 1, 3),
        fontSize: 18,
        cursor:'auto',
    },
    fontStyle: {
        display:"flex",
        alignItems:"center",
    },
    leftFont: {
        margin: theme.spacing(0, 0, 0, 1),
        cursor:'auto',
    },
    dialogPaper: {
        overflowY: 'hidden',
    },
    dialogDiv: {
        width: '960px',
        height: 'auto',
        overflow: 'auto',
    },
}))
export interface Props{
    openFlag:boolean
    setOpenFlag: (state: boolean) => void
}
const MCAXS180 = ({openFlag, setOpenFlag}:Props) => {
    const permissions = useSelector(
        (state: RootState) => state.globalMenu.permissions
      )
    const permission = () => {
        for (let i = 0; i < permissions.length; i++) {
          if (permissions[i] !== '10' && permissions[i] !== '20' && permissions[i] !== '30' && permissions[i] !== '40' && permissions[i] !== '50') {
            history.push(routeList.error)
          }
        }
    };
    if(openFlag){
        permission()
    }
    const handleClose = () => {
        setOpenFlag(false)
      }
    const classes = useStyles()
    const EditDialogPaper =styled(Paper)({
        minWidth:730
    });
    const handleUploadApplicantInformation = () => {
        history.push(routeList.applicationUpload)
      }
    const dispatch = useDispatch()
    const mcaxs191Clear = () => {
      dispatch(setMcaxs191ClearReducer())
      history.push(routeList.jobSeekerNameInput)
      }
    return (
        <Dialog
        fullWidth={true} maxWidth='md'
        classes={{ paperScrollPaper: classes.dialogPaper }}
        className={'mainDialog'}
        open={openFlag}>
            <DialogTitle className={classes.dialogTitle}>
                <label className={classes.title}>応募者情報の追加</label>
            </DialogTitle>
            <DialogContent className={classes.dialogDiv}>
            <label className={classes.fontSize} dangerouslySetInnerHTML={{__html: getMessage(magiContants.MESSAGECODE_MCAXS180_000)}} />
            <Grid container>
                <Grid item xs={3}>
                    <Button
                        onClick={mcaxs191Clear}
                        variant='contained'
                        className={classes.linkButton}>
                        1件ずつ手動登録
                    </Button>
                </Grid>
                <Grid item xs={9} className={classes.fontStyle}>
                <label className={classes.leftFont}>登録画面から1件ずつ手動で入力して応募者を登録します。</label>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <Button
                        onClick={handleUploadApplicantInformation}
                        variant='contained'
                        className={classes.linkButton}>
                        CSVアップロード
                    </Button>
                </Grid>
                <Grid item xs={9} className={classes.fontStyle}>
                <label className={classes.leftFont}>CSVファイルのアップロードにより応募者を一括で登録、更新します。</label>
                </Grid>
            </Grid>
            <CardActions >
                <Button
                    onClick={handleClose}
                    variant='contained'
                    className={classes.cancelButton}>
                    キャンセルする
                </Button>
            </CardActions>
            </DialogContent>
        </Dialog>
    )
}

export default MCAXS180