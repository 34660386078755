const receiveSubmitValue = {
    searchTargetList: '0',
    searchWordText: '',
    receiveFromDate: '',
    receiveToDate: '',
    unreadCheck: '0',
    unsentCheck: '0',
    sentCheck: '0',
    attachmentExist: '0',
    attachmentNot: '0',
    messageType: '',
    selection: '',
    progress: '',
    judge: '',
    sort:'0',
    countPage:1,
    selectedFlag: 0,
    recruitmentManagementDivision:'',
    removeFunctionId:'',
};

const statusList = [
    { name: 'unreadCheck', label: '未読', id: '1' },
    { name: 'unsentCheck', label: '未返信', id: '2' },
    { name: 'sentCheck', label: '返信済み', id: '3' }
];

const attachmentList = [
    { name: 'attachmentExist', label: 'あり', id: '1' },
    { name: 'attachmentNot', label: 'なし', id: '2' }
];

const searchTargetList = [
    { value: '0', label: '件名' },
    { value: '1', label: '本文' },
    { value: '2', label: '氏名' },
];
const messageType = [
    { value: '01', label: '通常' },
    { value: '30', label: 'お問い合わせ' },
    { value: '31', label: '匿名' },
    { value: '03', label: '面接日程調整' },
];
const judgeParticipation = [
    { value: 2, label: '参加' },
    { value: 4, label: '不参加' },
    { value: 6, label: 'その他' },
];
const judgepassing = [
    { value: 1, label: '判定中' },
    { value: 3, label: '合格' },
    { value: 5, label: '不合格' },
];
export { receiveSubmitValue, statusList, attachmentList, searchTargetList, messageType, judgeParticipation, judgepassing };