import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAES020UpdateRequest } from 'types/MCAES020/MCAES020UpdateRequest'
import { MCAES010EditRequest } from 'types/MCAES010/MCAES010EditRequest'
import { MCAES020CreateRequest } from 'types/MCAES020/MCAES020CreateRequest'
import { MCAES020DeleteRequest } from 'types/MCAES020/MCAES020DeleteRequest'
import {
  recruitmentGroupInfoInitialValues,
  initReqruitGroupName,
} from '../pages/MCAES020/formConfig'

export interface Permission {
  [key: string]: string | null
  id: string
  name: string
  sortOrder: string | null
  status: string | null
}

export interface RecruitmentGroupInfo {
  [key: string]: string | any
  recruitmentGroupSettingId: string //採用グループ設定ID
  recruitmentGroupName: string //採用グループ名
  recruitmentType: string //採用グループ種別
  sysVersionNumber: number //sysバージョン番号
}

export interface SelectionFlowPreference {
  [key: string]: string | any
  selectionFlowSettingId: string //選考フロー設定ID
  selectionName: string //選考名
  selectionType: string //選考種別
  selectionClassification: string //選考分類
  selectionStartDate: Date //選考開始日
  selectionEndDate: Date //選考終了日
  selectionEndFlag: string //選考終了フラグ
  sysVersionNumber: number //sysバージョン番号
  permissions: Permission[] //権限
}

export interface Affiliation {
  [key: string]: string | any
  companyAccountId: string //選考フロー設定ID
  loginId: string //選考名
  fullName: string //選考種別
  departmentName: string //選考分類
  accountType: string //選考開始日
  validFlag: Date //選考終了日
  sysVersionNumber: number //sysバージョン番号
  permissions: Permission[] //権限
}

const initialState: {
  recruitmentGroupInfo: RecruitmentGroupInfo
  initReqruitGroupName: string
  selectionFlowPreferenceTableData: SelectionFlowPreference[]
  affiliationTableData: Affiliation[]
  selectionFlowPreferenceList: SelectionFlowPreference[]
  affiliationList: Affiliation[]
} = {
  recruitmentGroupInfo: recruitmentGroupInfoInitialValues,
  initReqruitGroupName: '',
  selectionFlowPreferenceTableData: [],
  affiliationTableData: [],
  selectionFlowPreferenceList: [],
  affiliationList: [],
}

const affiliationRecruitmentSlice = createSlice({
  name: 'affiliationRecruitment',
  initialState,
  reducers: {
    getSelectionFlowPreferenceList(state, action: PayloadAction<MCAES010EditRequest>) {
      debugger
      return state
    },
    setSelectionFlowPreference(
      state,
      action: PayloadAction<{
        recGroupPreSelect: RecruitmentGroupInfo
        SelectFlwPreSelectResult: SelectionFlowPreference[]
        SelectCompanyAccountResult: Affiliation[]
        selectFlwPreSelectSuggestList: SelectionFlowPreference[]
        companyAccountSelectSuggestList: Affiliation[]
      }>
    ) {
      state.recruitmentGroupInfo = action.payload.recGroupPreSelect
      state.selectionFlowPreferenceTableData =
        action.payload.SelectFlwPreSelectResult
      state.affiliationTableData = action.payload.SelectCompanyAccountResult
      state.selectionFlowPreferenceList =
        action.payload.selectFlwPreSelectSuggestList
      state.affiliationList = action.payload.companyAccountSelectSuggestList
      return state
    },

    createAffiliationRecruitment(
      state,
      action: PayloadAction<MCAES020CreateRequest>
    ) {
      return state
    },
    updateAffiliationRecruitment(
      state,
      action: PayloadAction<MCAES020UpdateRequest>
    ) {
      return state
    },
    deleteAffiliationRecruitment(
      state,
      action: PayloadAction<MCAES020DeleteRequest>
    ) {
      return state
    },
  },
})

export const {
  getSelectionFlowPreferenceList,
  setSelectionFlowPreference,
  createAffiliationRecruitment,
  updateAffiliationRecruitment,
  deleteAffiliationRecruitment,
} = affiliationRecruitmentSlice.actions
export default affiliationRecruitmentSlice.reducer
