import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialMCAMS010MessageTemplateList,initialopenModal } from 'pages/MCAMS010/formConfig'
import { initialMCAMS020ReturnDisplayDto,initialopenModalArgs } from 'pages/MCAMS020/formConfig'
import { initMCAMS040MessageTemplateList, initialSubmitValues } from 'pages/MCAMS040/formConfig'
import {MCAMS010InitDisplayRequest} from 'types/MCAMS010/MCAMS010InitDisplayRequest'
import {MCAMS010CopyMessageTemplateRequest} from 'types/MCAMS010/MCAMS010CopyMessageTemplateRequest'
import {MCAMS010UpdateDisplayStatusRequest} from 'types/MCAMS010/MCAMS010UpdateDisplayStatusRequest'
import { MCAMS020InitDisplayRequest } from 'types/MCAMS020/MCAMS020InitDisplayRequest'
import {MCAMS020messageTemplateInputRequest} from 'types/MCAMS020/MCAMS020messageTemplateInputRequest'
import { MCAMS040generateMessageTemplateRequest } from 'types/MCAMS040/MCAMS040generateMessageTemplateRequest'
import { MCAMS040InitDisplayRequest } from 'types/MCAMS040/MCAMS040InitDisplayRequest'
import { MCAMS030InitDisplayRequest } from 'types/MCAMS030/MCAMS030InitDisplayRequest'
import { MCAMS030deleteMessageTemplateRequest } from 'types/MCAMS030/MCAMS030deleteMessageTemplateRequest'
import { MCAMS030messageTemplateInputRequest } from 'types/MCAMS030/MCAMS030messageTemplateInputRequest'
import { RecruitmentManagementFlag } from 'pages/MCAZS080/viewConig'
import { McbMessageReplaceBodyRequest } from 'types/McbMessageReplaceBodyRequest'

export interface InitCondition {
    templateId:number//メッセージテンプレート設定ID
}

export interface MCAMS020SearchHomepageResultDto{
  messageTemplateSettingId:number	//メッセージテンプレートID
  sequence:number	//連番
  homePageTitle:string	//ホームページタイトル
  homePageUrl:string	//ホームページURL
}
export interface MCAMS020SearchAttachmentFileResultDto{
  messageTemplateSettingId:number	//メッセージテンプレートID
  sequence:number	//連番
  attachmentName:string	//添付ファイル名
  fileManagementId:string	//ファイル管理ID
  fileSize:string	//ファイルサイズ
}
interface MCAMS020SearchEntryBoxResultDto{
  messageTemplateSettingId:number	//メッセージテンプレートID
  sequence:number	//連番
  entryBoxId:number	//エントリーボックスID 
}
interface MCAMS020SearchSeminarResultDto{
  messageTemplateSettingId:number	//メッセージテンプレートID
  sequence:number	//連番
  seminarId:number	//セミナーID
}
interface MCAMS020MynaviAttachedOptionEntryBoxResultDto{
  entryBoxId:number	//エントリーボックスID
  mynaviAttachedOptionName:string	//マイナビ添付オプション名  
  contractMediaName:string	//契約応募経路名
  displayStartDate:Date	//表示開始日
  displayEndDate:Date	//表示終了日 
}
interface MCAMS020MynaviAttachedOptionSeminarResultDto{
  seminarId:number	//セミナーID
  mynaviAttachedOptionName:string	//マイナビ添付オプション名
  contractMediaName:string	//契約応募経路名
  displayStartDate:Date	//表示開始日
  displayEndDate:Date	//表示終了日 
}
interface MCAMS020RecruitmentManagementFlagResultDto{
  recruitmentManagementFlagSettingId:number	//採用管理フラグ設定ID
  recruitmentManagementFlagName:string	//採用管理フラグ名
}
interface MCAMS020SeminarScheduleResultDto{
  seminarScheduleId:number//セミナー日程ID
  seminarScheduleTitle:string	//セミナー日程タイトル
  coordinationFormerSeminarScheduleIdentificationKey:string	//連携元セミナー日程識別キー
  invisibleFlag:string //非表示フラグ
  eventDate:Date	//開催日
  //[phase2] 開催日テキスト start
  eventDateText: string
  //[phase2] 開催日テキスト end
  venueLocationName:string	//開催地場所名
  startTime:Date	//開始時間
  endTime:Date	//終了時間
  contractMediaName:string	//契約応募経路名
  displayStartDate:Date//表示開始日
  displayEndDate:Date //表示終了日
  remarks:string //備考
  seminarScheduleDisplay:string //セミナー日程本文
  displayFlag:boolean //表示フラグ
}
interface MCAMS020SeminarImageResultDto{
  seminarId:number	//セミナーID
  coordinationFormerSeminarId:number	//連携元セミナーID
  seminarName:string	//セミナー名
  publicFlag:number	//公開フラグ
  contractMediaName:string	//契約応募経路名
  displayStartDate:Date//表示開始日
  displayEndDate:Date //表示終了日
  displayFlag:boolean //表示フラグ
}
interface MCAMS020SignResultDto{
  signSettingId:number	//署名設定ID
  registrationName:string //登録名
  signature:string	//署名 
}

export interface templateAttachmentInfo{
  templateAttachmentName :string[],
  templateAttachmentSize :number[]
}
export interface SelectTemplateResultDto{
  messageTemplateSettingId:number //メッセージテンプレート設定ID
  templateName:string //テンプレート名
  templateType:number //テンプレート種別
  invisibleFlag:number //表示／非表示
  updateTime:Date //更新日時
  fullName:string //更新者
  sysVersionNumber:number //バージョン番号
  sequence: number // 連番
 }

export interface MCAMS020ReturnDisplayDto {
  recruitmentManagementDivision:string//採用管理区分
  forbiddenWordsList:string[]//禁止語リスト
  messageTemplateSettingId:string	//メッセージテンプレートID
  templateName:string	//テンプレート名
  // 年度対応 start
  targetYearDataList:string[] //対象年度データリスト
  targetYear:string //対象年度
  // 年度対応 end
  templateType:number	//テンプレート種別
  destinationDivision:number	//送信先区分
  // [phase2] start
  sendToNonMember:number	//マイナビ退会者を送信対象者に含む
  // [phase2] end
  senderCompanyName:string	//送信元会社名
  senderMailAddress:string	//送信元メールアドレス
  replyPermitFlag:number	//返信許可フラグ
  repliesNecessary: number // 返信希望アイコンの指定
  repliesLimit: string // 応募者からの返信期限の指定
  subject:string	//件名
  body:string	//本文
  barcodeDisplayFlag:number	//バーコード表示フラグ
  invisibleFlag:string	//非表示フラグ
  sysVersionNumber:string	//sysバージョン番号
  homePageList:MCAMS020SearchHomepageResultDto[]//ホームページ情報
  attachmentFileList:MCAMS020SearchAttachmentFileResultDto[]//添付ファイル情報
  selectedEntryBoxList:MCAMS020SearchEntryBoxResultDto[]//マイナビ添付オプション（エントリーボックス）情報
  selectedSeminarList:MCAMS020SearchSeminarResultDto[]//マイナビ添付オプション（セミナー）情報
  entryBoxList:MCAMS020MynaviAttachedOptionEntryBoxResultDto[]//マイナビ添付オプション（エントリー）情報
  seminarList:MCAMS020MynaviAttachedOptionSeminarResultDto[]//マイナビ添付オプション（セミナー）情報
  recruitmentManagementFlagList:RecruitmentManagementFlag[]//管理項目リスト
  seminarScheduleList:MCAMS020SeminarScheduleResultDto[]//セミナー日程リスト
  seminarImageList:MCAMS020SeminarImageResultDto[]//セミナー画像リスト
  signList:MCAMS020SignResultDto[]//署名リスト
  exampleLink:string//文例リンク
  homepageTitle1:string	//ホームページタイトル1
  homepageTitle2:string//ホームページタイトル2
  homepageTitle3:string//ホームページタイトル3
  homepageUrl1:string	//ホームページURL1
  homepageUrl2:string//ホームページURL2
  homepageUrl3:string	//ホームページURL3
  attachmentFileRegistrationMax: number // 添付ファイル登録数上限
  mynaviAttachedOptionRegistrationMax: number // マイナビ添付オプション登録数上限
}

interface modalMessageArgs{
  messageId: string,
  args: string[]
}
export interface openModalDataArgs{
  openCheckModal:boolean,
  modalMessageArgs:modalMessageArgs
}

const resetModal:openModalData={
  openCheckModal:false,
  modalMessage:""
}
const resetModalArgs:openModalDataArgs={
  openCheckModal:false,
  modalMessageArgs:{
    messageId: "",
    args: []
  }
}

export interface addSubmitParams{
  templateId:string	//メッセージテンプレート設定ID
  templateName:string	//テンプレート名
  // 年度対応 start
  targetYear:string //対象年度
  // 年度対応 end
  templateType:number	//テンプレート種別
  destination	:number	//送信先
  // [phase2] start
  sendToNonMember:number	//マイナビ退会者を送信対象者に含む
  // [phase2] end
  senderCompanyName:string	//送信元会社名
  senderMailAddress:string	//送信元メールアドレス
  allowReply:number	//返信許可の指定
  repliesNecessary: number // 返信希望アイコンの指定
  repliesLimit: string // 応募者からの返信期限の指定
  subject:string	//件名
  text:string	//本文
  insertTextSecruitmentManageFlag	:number	//管理フラグ対象フラグ
  attachment:templateAttachmentInfo	//添付ファイル[]
  templateattachmentList:MCAMS020ConfirmData[]  //テンプレート添付ファイル情報[]
  homepageTitle1:string	//ホームページタイトル1
  homepageTitle2:string	//ホームページタイトル2
  homepageTitle3:string	//ホームページタイトル3
  homepageUrl1:string	//ホームページURL1
  homepageUrl2:string	//ホームページURL2
  homepageUrl3:string	//ホームページURL3
  barcodeDisplay:number	//バーコードを表示する
  mynaviAttachedOptionEntryID:string[]	//選択したエントリーボックスID[]
  mynaviAttachedOptionSeminarID:string[]	//選択したセミナーID[]
  sysVersionNumber:string	//sysバージョン番号
  forbiddenWordsList:string[]	//禁止語リスト
  seminarScheduleList:MCAMS020SeminarScheduleResultDto[]//セミナー日程リスト
  seminarImageList:MCAMS020SeminarImageResultDto[]//セミナー画像リスト
  recruitmentManagementFlagList:RecruitmentManagementFlag[]//管理項目リスト
}

export interface MCAMS010MessageTemplateList {
  messageTemplateSearchCondition:MCAMS010InitDisplayRequest //入力する検索条件
  searchTemplateName: string;// テンプレート名
  searchTemplateTypeGeneral: string;// 汎用
  searchTemplateTypeOffer: string;// 合格通知
  searchTemplateTypeFailure: string;// 不採用通知
  searchTemplateWord: string;// 件名／本文
  searchTemplateHidden: string;// 非表示のテンプレートを含む
  recruitmentManagementDivision:string;//採用管理区分
  maxMessageTemplate:string;//メッセージテンプレート数上限
  templateId:number[],
  sysVersionNumber:number[],
  showHide:number,
  messageTemplateResult:SelectTemplateResultDto[] //検索結果
}

export interface openModalData {
  openCheckModal:boolean,
  modalMessage:string,
}

export interface MCAMS020ConfirmData {
  fileManagementId: number // ファイル管理ID
  attachmentName: string // メッセージテンプレート名
  fileSize: string // ファイルサイズ
}

// MCAMS040 Start
export interface MCAMS040MessageTemplateList {
  entryBoxList: entryBox[] // マイナビ添付オプション_エントリー情報
  seminarList: seminar[] // マイナビ添付オプション_セミナー情報
  attachmentFileRegistrationMax: number // 添付ファイル登録数上限
  mynaviAttachedOptionRegistrationMax: number // マイナビ添付オプション登録数上限
  recruitmentManagementDivision: string;//採用管理区分
  // 次期開発12月 #71768 start
  replyMailAddress: string // 送信元メールアドレス
  // 次期開発12月 #71768 end
}

export interface entryBox {
  entryBoxId: number // エントリーボックスID
  mynaviAttachedOptionName: string // エントリーボックス名
  contractMediaName: string // 契約応募経路名
  displayStartDate: Date // 表示開始日
  displayEndDate: Date // 表示終了日
}

export interface seminar {
  seminarId: number // セミナーID
  mynaviAttachedOptionName: string // セミナー名
  contractMediaName: string // 契約応募経路名
  displayStartDate: Date // 表示開始日
  displayEndDate: Date // 表示終了日
}

// MCAMS040 End

interface MessageTemplateList {
    mCAMS010MessageTemplateList:MCAMS010MessageTemplateList,//メッセージテンプレート一覧
    returnDisplayDto:MCAMS020ReturnDisplayDto,
    mCAMS040MessageTemplateList: MCAMS040MessageTemplateList,// メッセージテンプレート登録 内容確認
    pageDialogOpen020: boolean,//画面openフラグ(MCAMS020)
    addCopyflag020:number,//登録コピー画面の区分(MCAMS020)
    pageDialogOpen040: boolean,//画面openフラグ(MCAMS040)
    confirmData : MCAMS020ConfirmData[] //(MCAMS020 内容を確認)
    // mCAMS030ReturnDisplayDto: MCAMS020ReturnDisplayDto, // MCAMS030 内容
    pageDialogOpen030: boolean,//画面openフラグ(MCAMS030)
    messageTemplatePage: number,//メッセージテンプレート一覧に選択されるページ数
    selectedTemplates:number[],//メッセージテンプレート一覧に選択されるデータ
    option:string,//表示非表示option
    addSubmit:addSubmitParams,
    openModalData:openModalData
    openModalDataArgs:openModalDataArgs
    // MCBリプレース MCAMS020 START
    replaceBody:string
    // MCBリプレース MCAMS020 END
  }

const messageTemplateList: MessageTemplateList = {
    mCAMS010MessageTemplateList:initialMCAMS010MessageTemplateList,//メッセージテンプレート一覧
    returnDisplayDto:initialMCAMS020ReturnDisplayDto,
    mCAMS040MessageTemplateList: initMCAMS040MessageTemplateList,// メッセージテンプレート登録 内容確認
    pageDialogOpen020: false,//画面openフラグ(MCAMS020)
    addCopyflag020:0,//登録コピー画面の区分(MCAMS020)
    pageDialogOpen040: false,//画面openフラグ(MCAMS040)
    confirmData :[],
    pageDialogOpen030: false,//画面openフラグ(MCAMS030)
    messageTemplatePage:0,//メッセージテンプレート一覧に選択されるページ数
    selectedTemplates:[],//メッセージテンプレート一覧に選択されるデータ
    option:'',//表示非表示option
    addSubmit:initialSubmitValues,
    openModalData:initialopenModal,
    openModalDataArgs:initialopenModalArgs,
    // MCBリプレース MCAMS020 START
    replaceBody:''
    // MCBリプレース MCAMS020 END
}

const messageTemplateSlice = createSlice({
  name: 'messageTemplate',
  initialState : messageTemplateList,
  reducers: {
    // MCAMS020 Start
    messageTemplateAddInit(state, action: PayloadAction<MCAMS020InitDisplayRequest>) {
      return state
    },
    setMessageTemplateAddInit(state, action: PayloadAction<MCAMS020ReturnDisplayDto>) {
        state.returnDisplayDto = action.payload
        //テンプレート名値がない場合、初期値
        if(action.payload.templateName==null){
          state.returnDisplayDto.templateName=initialMCAMS020ReturnDisplayDto.templateName
        }
        // 年度対応 start
        // 対象年度
        if(action.payload.targetYear==null){
          state.returnDisplayDto.targetYear=initialMCAMS020ReturnDisplayDto.targetYear
        }
        // 年度対応 end
        // MCBリプレース MCAMS020 START
        //テンプレート種別値がない場合、汎用を設定する
        if(Number(action.payload.templateType)==0||Number(action.payload.templateType)==1||Number(action.payload.templateType)==2||Number(action.payload.templateType)==3){
          state.returnDisplayDto.templateType=Number(action.payload.templateType)
        }else{
          state.returnDisplayDto.templateType=0
        }
        //送信先区分値がない場合、マイナビのみを設定する
        if(Number(action.payload.destinationDivision)==0||(Number(action.payload.destinationDivision)==1&&action.payload.recruitmentManagementDivision=='1')||Number(action.payload.destinationDivision)==2||Number(action.payload.destinationDivision)==3){
          state.returnDisplayDto.destinationDivision=Number(action.payload.destinationDivision)
        }else{
          state.returnDisplayDto.destinationDivision=0
        }
        // MCBリプレース MCAMS020 END
        // [phase2] start
        //マイナビ退会者を送信対象者に含むがない場合、初期値
        if(action.payload.sendToNonMember==null){
          state.returnDisplayDto.sendToNonMember=initialMCAMS020ReturnDisplayDto.sendToNonMember
        }
        // [phase2] end
        //送信元会社名値がない場合、初期値
        if(action.payload.senderCompanyName==null){
          state.returnDisplayDto.senderCompanyName=initialMCAMS020ReturnDisplayDto.senderCompanyName
        }
        //送信元メールアドレス値がない場合、初期値
        if(action.payload.senderMailAddress==null){
          state.returnDisplayDto.senderMailAddress=initialMCAMS020ReturnDisplayDto.senderMailAddress
        }
        //返信許可フラグ値がない場合、許可しないを設定する
        if(Number(action.payload.replyPermitFlag)==0||Number(action.payload.replyPermitFlag)==1){
          state.returnDisplayDto.replyPermitFlag=Number(action.payload.replyPermitFlag)
        }else{
          state.returnDisplayDto.replyPermitFlag=0
        }
        // 返信希望アイコンの指定値がない場合、初期値
        if (action.payload.repliesNecessary == null) {
          state.returnDisplayDto.repliesNecessary = initialMCAMS020ReturnDisplayDto.repliesNecessary
        }
        // 応募者からの返信期限の指定がない場合、初期値
        if (action.payload.repliesLimit == null) {
          state.returnDisplayDto.repliesLimit = initialMCAMS020ReturnDisplayDto.repliesLimit
        }
        //件名値がない場合、初期値
        if(action.payload.subject==null){
          state.returnDisplayDto.subject=initialMCAMS020ReturnDisplayDto.subject
        }
        //本文値がない場合、初期値
        if(action.payload.body==null){
          state.returnDisplayDto.body=initialMCAMS020ReturnDisplayDto.body
        }
        //バーコード表示フラグ値がない場合、表示しないを設定する
        if(Number(action.payload.barcodeDisplayFlag)==0||Number(action.payload.barcodeDisplayFlag)==1){
          state.returnDisplayDto.barcodeDisplayFlag=Number(action.payload.barcodeDisplayFlag)
        }else{
          state.returnDisplayDto.barcodeDisplayFlag=0
        }
        //ホームページタイトル,ホームページURL値がない場合、初期値
        for(var i=0;i<action.payload.homePageList.length;i++){
          if(action.payload.homePageList[i].homePageTitle==null){
            state.returnDisplayDto.homePageList[i].homePageTitle=''
          }
          if(action.payload.homePageList[i].homePageUrl==null){
            state.returnDisplayDto.homePageList[i].homePageUrl=''
          }
        }
        state.returnDisplayDto.homepageTitle1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageTitle:''	//ホームページタイトル1
        state.returnDisplayDto.homepageTitle2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageTitle:''	//ホームページタイトル2
        state.returnDisplayDto.homepageTitle3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageTitle:''	//ホームページタイトル3
        state.returnDisplayDto.homepageUrl1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageUrl:''	//ホームページURL1
        state.returnDisplayDto.homepageUrl2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageUrl:''	//ホームページURL2
        state.returnDisplayDto.homepageUrl3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageUrl:''	//ホームページURL3
        return state
    },
    setAddPageDialogOpen(state, action: PayloadAction<boolean>) {
        state.pageDialogOpen020 = action.payload
        return state
    },
    setPageOpen(state, action: PayloadAction<boolean>) {
        state.pageDialogOpen020 = action.payload
        state.returnDisplayDto = initialMCAMS020ReturnDisplayDto
        return state
    },
    // 次期開発9月 #62201 start
    messageTemplateConfirm(state, action: PayloadAction<
      {submitData:MCAMS020messageTemplateInputRequest,data:addSubmitParams,attachmentFileRegistrationMax:number,mynaviAttachedOptionRegistrationMax:number,files: File[],checkFlag: boolean}>) {
      return state
    },
    // 次期開発9月 #62201 end
    setMessageTemplateConfirm(state, action: PayloadAction<MCAMS020ConfirmData[]>) {
      state.confirmData =action.payload
      return state
    },
    // MCAMS020 End
    changeDestinationVaule(state, action:PayloadAction<{name:string,newValue:any}>){
      switch(action.payload.name){
        case 'templateName':
          state.returnDisplayDto.templateName=action.payload.newValue
          break
        case 'templateType':
          state.returnDisplayDto.templateType=action.payload.newValue
          break
        case 'destinationDivision':
          state.returnDisplayDto.destinationDivision=action.payload.newValue
          break
        case 'senderCompanyName':
          state.returnDisplayDto.senderCompanyName=action.payload.newValue
          break
        case 'senderMailAddress':
          state.returnDisplayDto.senderMailAddress=action.payload.newValue
            break
        case 'allowReply':
          state.returnDisplayDto.replyPermitFlag=action.payload.newValue
          break
        case 'messageSubject':   //件名
          state.returnDisplayDto.subject=action.payload.newValue
          break
        case 'messageText':   //本文
          state.returnDisplayDto.body=action.payload.newValue
          break
        case 'barcodeDisplay':
          state.returnDisplayDto.barcodeDisplayFlag=action.payload.newValue
          break
        default:
          break
      }
      return state
    },
    changeHomePageValue(state, action:PayloadAction<{value:any,name:string}>){
      switch(action.payload.name){
        case 'homepageTitle1':
          state.returnDisplayDto.homePageList[0].homePageTitle = action.payload.value
          state.returnDisplayDto.homepageTitle1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageTitle:''	//ホームページタイトル1
          break
        case 'homepageTitle2':
          state.returnDisplayDto.homePageList[1].homePageTitle = action.payload.value
          state.returnDisplayDto.homepageTitle2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageTitle:''	//ホームページタイトル2
          break
        case 'homepageTitle3':
          state.returnDisplayDto.homePageList[2].homePageTitle = action.payload.value
          state.returnDisplayDto.homepageTitle3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageTitle:''	//ホームページタイトル3
          break
        case 'homepageUrl1':
          state.returnDisplayDto.homePageList[0].homePageUrl = action.payload.value
          state.returnDisplayDto.homepageUrl1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageUrl:''	//ホームページURL1
          break
        case 'homepageUrl2':
          state.returnDisplayDto.homePageList[1].homePageUrl = action.payload.value
          state.returnDisplayDto.homepageUrl2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageUrl:''	//ホームページURL2
          break
        case 'homepageUrl3':
          state.returnDisplayDto.homePageList[2].homePageUrl = action.payload.value
          state.returnDisplayDto.homepageUrl3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageUrl:''	//ホームページURL3
          break                    
        default:
          break
      }
      return state
    },
    changeHomePageTitleValue(state, action:PayloadAction<{value:any,id:any}>){
      return state
    },
    changeHomePageUrlValue(state, action:PayloadAction<{value:any,id:any}>){
      return state
    },
    deleteHomePageValue(state, action:PayloadAction<any>){
      state.returnDisplayDto.homePageList.splice(action.payload,1)
      state.returnDisplayDto.homepageTitle1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageTitle:''	//ホームページタイトル1
      state.returnDisplayDto.homepageTitle2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageTitle:''	//ホームページタイトル2
      state.returnDisplayDto.homepageTitle3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageTitle:''	//ホームページタイトル3
      state.returnDisplayDto.homepageUrl1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageUrl:''	//ホームページURL1
      state.returnDisplayDto.homepageUrl2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageUrl:''	//ホームページURL2
      state.returnDisplayDto.homepageUrl3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageUrl:''	//ホームページURL3
      return state
    },
    addHomePageValue(state, action:PayloadAction<any>){
      state.returnDisplayDto.homePageList =[...state.returnDisplayDto.homePageList,{messageTemplateSettingId:0,sequence:0,homePageTitle:'',homePageUrl:''}]
      state.returnDisplayDto.homepageTitle1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageTitle:''	//ホームページタイトル1
      state.returnDisplayDto.homepageTitle2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageTitle:''	//ホームページタイトル2
      state.returnDisplayDto.homepageTitle3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageTitle:''	//ホームページタイトル3
      state.returnDisplayDto.homepageUrl1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageUrl:''	//ホームページURL1
      state.returnDisplayDto.homepageUrl2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageUrl:''	//ホームページURL2
      state.returnDisplayDto.homepageUrl3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageUrl:''	//ホームページURL3

      return state
    },
    changeFileValue(state, action:PayloadAction<{value:any,id:any}>){
      state.returnDisplayDto.attachmentFileList[action.payload.id].attachmentName = action.payload.value
    },
    deleteFileValue(state, action:PayloadAction<any>){
      state.returnDisplayDto.attachmentFileList.splice(action.payload,1)
      return state
    },
    addFileValue(state, action:PayloadAction<any>){
      state.returnDisplayDto.attachmentFileList =[...state.returnDisplayDto.attachmentFileList,{messageTemplateSettingId:0,sequence:0,attachmentName:'ファイルを選択',fileManagementId:'',fileSize:'',}]
      return state
    },

    openModalMessage(state,action:PayloadAction<string>){
      state.openModalData.modalMessage=action.payload;
      state.openModalData.openCheckModal=true
    },
    openModalWithArgMessage(state, action: PayloadAction<string>){
      state.openModalDataArgs.modalMessageArgs.messageId=action.payload
      state.openModalDataArgs.openCheckModal=true
    },
    closeModel(state){
        state.openModalData=resetModal
        return state;
    },
    closeModelArgs(state){
      state.openModalDataArgs=resetModalArgs
      return state;
    },

    // MCAMS010 Start
    selectMeassageTemplateList(state, action: PayloadAction<MCAMS010InitDisplayRequest>) {
      return state
    },
    setSelectMeassageTemplateList(state, action: PayloadAction<MCAMS010MessageTemplateList>) {
      state.mCAMS010MessageTemplateList = action.payload
      return state
    },
    addMeassageTemplateList(state, action: PayloadAction<string>) {
      return state
    },
    setAddMeassageTemplateList(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      state.addCopyflag020 = 0
      return state
    },
    executeMeassageTemplateList(state, action: PayloadAction<MCAMS010UpdateDisplayStatusRequest>) {
      return state
    },
    setExecuteMeassageTemplateList(state, action: PayloadAction<MCAMS010MessageTemplateList>) {
      state.mCAMS010MessageTemplateList = action.payload
      return state
    },
    copyMeassageTemplateList(state, action: PayloadAction<MCAMS010CopyMessageTemplateRequest>) {
      return state
    },
    setCopyMeassageTemplateList(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      state.addCopyflag020 = 1
      return state
    },
    setMeassageTemplateListPage(state, action: PayloadAction<number>) {
      state.messageTemplatePage = action.payload
      return state
    },
    setMeassageTemplateListData(state, action: PayloadAction<number[]>) {
      state.selectedTemplates = action.payload
      return state
    },
    setMeassageTemplateListOption(state, action: PayloadAction<string>) {
      state.option = action.payload
      return state
    },
    setOpenModalMeassageData(state, action: PayloadAction<openModalData>) {
      state.openModalData = action.payload
      return state
    },
    updateMessageTemplateSort(state, action: PayloadAction<{updateOrderList: SelectTemplateResultDto[], selectParam: MCAMS010InitDisplayRequest}>) {
      state.mCAMS010MessageTemplateList.messageTemplateResult = action.payload.updateOrderList
      return state
    },
    // MCAMS010 End
    
    // MCAMS040 Start
    //登録内容確認初期表示時
    fetchMessageTemplateConfirm(state, action: PayloadAction<MCAMS040InitDisplayRequest>) {
      return state
    },
    setMessageTemplateContentConfirm(state, action: PayloadAction<MCAMS040MessageTemplateList>) {
      state.mCAMS040MessageTemplateList = action.payload
      return state
    },
    setOpenConfirmContentPage(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen040 = action.payload
      return state
    },
	  setCloseConfirmContentPage(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen040 = action.payload
      return state
    },
    insertOrUpdateMessageTemplate(state, action: PayloadAction<{registeredFileName:File[],submitParams:MCAMS040generateMessageTemplateRequest,mCAMS010InitDisplayRequest:MCAMS010InitDisplayRequest}>) {
      return state
    },
    setAddSubmit(state, action: PayloadAction<addSubmitParams>){
      state.addSubmit = action.payload
      return state
    },
    // MCAMS040 End

    // MCAMS030 Start
    // 初期表示
    messageTemplateDetailInit(state, action: PayloadAction<MCAMS030InitDisplayRequest>) {
      return state
    },
    // 初期表示内容
    setMessageTemplateDetailInit(state, action: PayloadAction<MCAMS020ReturnDisplayDto>) {
      state.returnDisplayDto = action.payload
      //テンプレート名値がない場合、初期値
      if(action.payload.templateName==null){
        state.returnDisplayDto.templateName=initialMCAMS020ReturnDisplayDto.templateName
      }
      //テンプレート種別値がない場合、汎用を設定する
      // MCBリプレース MCAMS030 START
      if(Number(action.payload.templateType)==0||Number(action.payload.templateType)==1||Number(action.payload.templateType)==2||Number(action.payload.templateType)==3){
        state.returnDisplayDto.templateType=Number(action.payload.templateType)
      }else{
        state.returnDisplayDto.templateType=0
      }
      //送信先区分値がない場合、マイナビのみを設定する
      if(Number(action.payload.destinationDivision)==0||(Number(action.payload.destinationDivision)==1&&action.payload.recruitmentManagementDivision=='1')||Number(action.payload.destinationDivision)==2||Number(action.payload.destinationDivision)==3){
        state.returnDisplayDto.destinationDivision=Number(action.payload.destinationDivision)
      }else{
        state.returnDisplayDto.destinationDivision=0
      }
      // MCBリプレース MCAMS030 END
      // [phase2] start
      //マイナビ退会者を送信対象者に含むがない場合、初期値
      if(action.payload.sendToNonMember==null){
        state.returnDisplayDto.sendToNonMember=initialMCAMS020ReturnDisplayDto.sendToNonMember
      }
      // [phase2] end
      //送信元会社名値がない場合、初期値
      if(action.payload.senderCompanyName==null){
        state.returnDisplayDto.senderCompanyName=initialMCAMS020ReturnDisplayDto.senderCompanyName
      }
      //送信元メールアドレス値がない場合、初期値
      if(action.payload.senderMailAddress==null){
        state.returnDisplayDto.senderMailAddress=initialMCAMS020ReturnDisplayDto.senderMailAddress
      }
      //返信許可フラグ値がない場合、許可しないを設定する
      if(Number(action.payload.replyPermitFlag)==0||Number(action.payload.replyPermitFlag)==1){
        state.returnDisplayDto.replyPermitFlag=Number(action.payload.replyPermitFlag)
      }else{
        state.returnDisplayDto.replyPermitFlag=0
      }
      // 返信希望アイコンの指定値がない場合、初期値
      if (action.payload.repliesNecessary == null) {
        state.returnDisplayDto.repliesNecessary = initialMCAMS020ReturnDisplayDto.repliesNecessary
      }
      // 応募者からの返信期限の指定がない場合、初期値
      if (action.payload.repliesLimit == null) {
        state.returnDisplayDto.repliesLimit = initialMCAMS020ReturnDisplayDto.repliesLimit
      }
      //件名値がない場合、初期値
      if(action.payload.subject==null){
        state.returnDisplayDto.subject=initialMCAMS020ReturnDisplayDto.subject
      }
      //本文値がない場合、初期値
      if(action.payload.subject==null){
        state.returnDisplayDto.body=initialMCAMS020ReturnDisplayDto.body
      }
      //バーコード表示フラグ値がない場合、表示しないを設定する
      if(Number(action.payload.barcodeDisplayFlag)==0||Number(action.payload.barcodeDisplayFlag)==1){
        state.returnDisplayDto.barcodeDisplayFlag=Number(action.payload.barcodeDisplayFlag)
      }else{
        state.returnDisplayDto.barcodeDisplayFlag=0
      }
      //ホームページタイトル,ホームページURL値がない場合、初期値
      for(var i=0;i<action.payload.homePageList.length;i++){
        if(action.payload.homePageList[i].homePageTitle==null){
          state.returnDisplayDto.homePageList[i].homePageTitle=''
        }
        if(action.payload.homePageList[i].homePageUrl==null){
          state.returnDisplayDto.homePageList[i].homePageUrl=''
        }
      }
      state.returnDisplayDto.homepageTitle1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageTitle:''	//ホームページタイトル1
      state.returnDisplayDto.homepageTitle2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageTitle:''	//ホームページタイトル2
      state.returnDisplayDto.homepageTitle3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageTitle:''	//ホームページタイトル3
      state.returnDisplayDto.homepageUrl1=state.returnDisplayDto.homePageList[0]!=undefined?state.returnDisplayDto.homePageList[0].homePageUrl:''	//ホームページURL1
      state.returnDisplayDto.homepageUrl2=state.returnDisplayDto.homePageList[1]!=undefined?state.returnDisplayDto.homePageList[1].homePageUrl:''	//ホームページURL2
      state.returnDisplayDto.homepageUrl3=state.returnDisplayDto.homePageList[2]!=undefined?state.returnDisplayDto.homePageList[2].homePageUrl:''	//ホームページURL3
      return state
    },
    // 次期開発9月 #62201 start
    // 「内容を確認する」ボタン押下
    messageTemplateDetailConfirm(state, action: PayloadAction<{submitData:MCAMS030messageTemplateInputRequest,data:addSubmitParams,attachmentFileRegistrationMax:number,mynaviAttachedOptionRegistrationMax:number,files: File[],checkFlag:boolean}>) {
      return state
    },
    // 次期開発9月 #62201 end
    setDetailPageDialogOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen030 = action.payload
      return state
    },
    setMessageTemplateSettingId030(state,action: PayloadAction<MCAMS030InitDisplayRequest>){
      return state
    },
    messageTemplateDelete(state,action: PayloadAction<MCAMS030deleteMessageTemplateRequest>) {
      return state
    },
    // MCAMS030 End
    // 年度対応 start
    messageTemplateTargetYearChangeInit(state, action: PayloadAction<MCAMS020messageTemplateInputRequest>) {
      return state
    },

    setMessageTemplateTargetYearChangeInit(state, action: PayloadAction<MCAMS020ReturnDisplayDto>) {
      // マイナビ添付オプション（エントリー）情報
      state.returnDisplayDto.entryBoxList=action.payload.entryBoxList
      // マイナビ添付オプション（セミナー）情報
      state.returnDisplayDto.seminarList=action.payload.seminarList
      // 管理項目リスト
      state.returnDisplayDto.recruitmentManagementFlagList=action.payload.recruitmentManagementFlagList
      // セミナー日程リスト
      state.returnDisplayDto.seminarScheduleList=action.payload.seminarScheduleList
      // セミナー画像リスト
      state.returnDisplayDto.seminarImageList=action.payload.seminarImageList

      return state
    },
    // 年度対応 end
    // MCBリプレース MCAMS020 START
    getReplaceBody(state, action: PayloadAction<McbMessageReplaceBodyRequest>) {
      return state
    },
    setReplaceBody(state, action: PayloadAction<string>) {
      state.replaceBody = action.payload
      return state
    },
    // MCBリプレース MCAMS020 END
  },
})

export const {
  messageTemplateAddInit,
  setMessageTemplateAddInit,
  setAddPageDialogOpen,
  setPageOpen,
  messageTemplateConfirm,
  setMessageTemplateConfirm,
  changeDestinationVaule,
  changeHomePageValue,
  changeHomePageTitleValue,
  changeHomePageUrlValue,
  deleteHomePageValue,
  addHomePageValue,
  changeFileValue,
  deleteFileValue,
  addFileValue,
  selectMeassageTemplateList,
  setSelectMeassageTemplateList,
  addMeassageTemplateList,
  setAddMeassageTemplateList,
  executeMeassageTemplateList,
  setExecuteMeassageTemplateList,
  copyMeassageTemplateList,
  setCopyMeassageTemplateList,
  setMeassageTemplateListPage,
  setMeassageTemplateListData,
  setMeassageTemplateListOption,
  setOpenModalMeassageData,
  fetchMessageTemplateConfirm,
  setMessageTemplateContentConfirm,
  insertOrUpdateMessageTemplate,
  setOpenConfirmContentPage,
  setCloseConfirmContentPage,
  setMessageTemplateDetailInit,
  messageTemplateDetailConfirm,
  // changeDestinationVauleDetail,
  setDetailPageDialogOpen,
  setMessageTemplateSettingId030,
  messageTemplateDetailInit,
  messageTemplateDelete,
  // 年度対応 start
  messageTemplateTargetYearChangeInit,
  setMessageTemplateTargetYearChangeInit,
  // 年度対応 end
  setAddSubmit,
  openModalMessage,
  openModalWithArgMessage,
  closeModel,
  closeModelArgs,
  updateMessageTemplateSort,
  // MCBリプレース MCAMS020 START
  getReplaceBody,
  setReplaceBody,
  // MCBリプレース MCAMS020 END
} = messageTemplateSlice.actions
export default messageTemplateSlice.reducer