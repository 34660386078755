import React from 'react'

interface Props {
  index: number
  value: number
}

const TabPanel: React.FC<Props> = ({ index, value, children }) => {
  return value === index ? <div>{children}</div> : null
}

export default TabPanel
