import request from 'utils/request'
import { MCASS010CreateRequest } from 'types/MCASS010/MCASS010CreateRequest'
import { MCASS010Request } from 'types/MCASS010/MCASS010Request'

export const initRequest = (requestInfo: MCASS010Request) =>
    request({
        url: `MCASS010/init`,
        method: 'post',
        data: requestInfo,
    })

export const checkRequest = (spamActReport: MCASS010CreateRequest) =>
    request({
        url: `MCASS010/check`,
        method: 'post',
        data: spamActReport,
    })