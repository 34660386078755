import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stringToDateTime } from 'common/generalUtil'
import { MCAXS110UpdateProgressRequest } from 'types/MCAXS110/MCAXS110UpdateProgressRequest'

export interface ProgressStatusChangeInParm {
    jobSeekerId: number[] //求職者ID
    entryId: number[] //エントリーID
    selectionManagementId: number[] //選考管理
    versionNumberSelectionManagement: SysVersionNumberList[] //sysバージョン情報（選考管理）
    screenId: string //呼出し側機能ID
}

let initParm: ProgressStatusChangeInParm = {
    jobSeekerId: [],
    entryId: [],
    selectionManagementId: [],
    versionNumberSelectionManagement: [],
    screenId:'',
}

export interface SelectionFlowList {
    [key: number]: number
    progressStatusSettingId: number//選考ステップ設定ID
    systemProgressStatusId: number//システム進捗ステータスID
    progressType: string//進捗種別
    displayOrder: number//表示順
    progressName: string//進捗名
    selectionName: string//選考名
    decisionDivisionType: string//判定区分種別
}

export interface ManagerFlowStartCommentQueryResult {
    selectionManagementId: number//選考管理ID
    progressStatusSettingId: number//選考ステップ設定ID
    initialSelectionManagementId: number//初期選考管理ID
    decisionDivision: string//判定区分
    failureReasonId: string//不合格理由ID
    declineReasonId: string//辞退理由ID
    interviewTime: string//面接日時
    inputContent: string//入力内容
    sysVersionNumberPsma: number//sysバージョン番号（進捗ステータス管理）
    sysVersionNumberPsmd: number//sysバージョン番号（進捗ステータス管理詳細）
}

export interface InputValues {
    selectionStep: string //選考ステップ設定ID
    interviewDay: Date | any //面接日時
    fairueReason: string//不合格理由ID
    declineReason: string//辞退理由ID
    other: string//入力内容
    message: string
}

let initialValues: InputValues = {
    selectionStep: '',
    interviewDay: '',
    fairueReason: '',
    declineReason: '',
    other: '',
    message: '',
}

export interface CodeList {
    itemCode: string //コード値
    itemName: string//名称
}

export interface DecisionProcessChangeQueryResult {
    selectMagSelectFlowInfoOutDtoList: SelectionFlowList[]//選考フロー情報
    selectMagSelectSelectionInfoOutDtoList: ManagerFlowStartCommentQueryResult[]//選考情報
    failureReasonIdCodeList: CodeList[]
    declineReasonIdCodeList: CodeList[]
    sysVersionNumberPsmaList: SysVersionNumberList[]
    sysVersionNumberPsmdList: SysVersionNumberList[]
}

const initialDecisionProcessChangeQueryResult: DecisionProcessChangeQueryResult = {
    selectMagSelectFlowInfoOutDtoList: [],
    selectMagSelectSelectionInfoOutDtoList: [],
    failureReasonIdCodeList: [],
    declineReasonIdCodeList: [],
    sysVersionNumberPsmaList: [],
    sysVersionNumberPsmdList: [],
}

const updateProgressData: MCAXS110UpdateProgressRequest = {
    selectionManagementId: [], // 選考管理ID
    progressStatusSettingId: '', // 進捗ステータス設定ID
    interviewDayInput: '', // 面接日時入力
    fairueReasonInput: 0, // 不合格理由入力
    declineReasonInput: 0, // 辞退理由入力
    otherInput: '', // その他入力
    reccomendUpdateFlag: '', // 推薦更新フラグ
    versionNumberSelectionManagement: [], // sysバージョン情報（選考管理）
    versionNumberProgressStatusManagement: [], // sysバージョン情報（進捗ステータス管理）
    versionNumberProgressStatusManagementDetail: [], // sysバージョン情報（進捗ステータス管理詳細）
}

export interface SysVersionNumberList {
    selectionManagementId: string //選考管理ID
    progressStatusSettingId: string //選考ステップ設定ID
    sysVersionNumber: string //sysバージョン番号
}

export interface RecommendCheckRequest {
    selectionManagementId: number[] //選考管理ID
    entryId: number[] //エントリーID
    progressStatusSettingId: number //進捗ステータス設定ID
}

export interface progressStatusChangeResult {
    initParm: ProgressStatusChangeInParm // 検索条件初期化
    decisionProcessChangeQueryResult: DecisionProcessChangeQueryResult,
    openDialogFlag: boolean
    recommendCheckDialogOpeFlag: boolean // 推薦チェック
    progressChangeBeforeCheckDialogOpeFlag: boolean // 進捗変更前チェック
    cancelCheckDialogOpeFlag: boolean // キャンセルチェック
    updateProgressFlag: boolean //処理継続フラグ
    progressNameList: { value: string; label: string; }[]
    fairueReasonList: { value: string; label: string; }[]
    declineReasonList: { value: string; label: string; }[]
    initialValues: InputValues
    // 表示するかどうかを判断するフラグ
    interviewDayShowList: string[]
    fairueReasonShow: string
    declineReasonShow: string
    // 推薦チェックの進捗ステータスIDリスト
    recommendCheckProgressStatusSettingIdList: string[]
    // MCAXS110UpdateProgressRequest
    updateProgressData: MCAXS110UpdateProgressRequest
}

const initialState: progressStatusChangeResult = {
    initParm: initParm, // 検索条件初期化
    decisionProcessChangeQueryResult: initialDecisionProcessChangeQueryResult,
    progressNameList: [],
    fairueReasonList: [],
    declineReasonList: [],
    openDialogFlag: false,
    recommendCheckDialogOpeFlag: false, // 推薦チェック
    progressChangeBeforeCheckDialogOpeFlag: false, // 進捗変更前チェック
    cancelCheckDialogOpeFlag: false, // キャンセルチェック
    updateProgressFlag: false, //処理継続フラグ
    initialValues: initialValues,
    // 表示するかどうかを判断するフラグ
    interviewDayShowList: [],
    fairueReasonShow: '',
    declineReasonShow: '',
    // 推薦チェックの進捗ステータスIDリスト
    recommendCheckProgressStatusSettingIdList: [],
    // MCAXS110UpdateProgressRequest
    updateProgressData: updateProgressData,
}

const progressStatusChangeSlice = createSlice({
    name: 'progressStatusChange',
    initialState,
    reducers: {
        // 画面初期表示時用
        progressStatusChangeInit(state, action: PayloadAction<ProgressStatusChangeInParm>) {
            state.initParm = action.payload
            return state
        },
        setProgressStatusChangeInit(state, action: PayloadAction<DecisionProcessChangeQueryResult>) {
            state.decisionProcessChangeQueryResult = action.payload
            // 推薦チェックのシステム進捗ステータスIDリスト
            let recommendcheckSystemProgressStatusIdList: number[] = [1001, 1002]
            // 選考ステップ名リストを設定
            let progressNameList: { value: string; label: string; }[] = []
            for (var i = 0; i < state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList.length; i++) {
                if (state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressType != '9') {
                    let value = state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString()
                    let label = state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressName
                    progressNameList.push({ value: value, label: label })
                }
                // 選考ステップが「スクリーニング」「書類回収中」
                if (recommendcheckSystemProgressStatusIdList.includes(state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].systemProgressStatusId)) {
                    state.recommendCheckProgressStatusSettingIdList.push(state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString())
                }
                // 表示するかどうかを判断するフラグ
                if (state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].decisionDivisionType == '0') {
                    state.interviewDayShowList.push(state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString())
                    continue
                }
                // 選考ステップが「不採用」
                if (state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressType == '7') {
                    state.fairueReasonShow = state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString()
                    state.recommendCheckProgressStatusSettingIdList.push(state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString())
                    continue
                }
                // 選考ステップが「辞退」
                if (state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressType == '8') {
                    state.declineReasonShow = state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString()
                    state.recommendCheckProgressStatusSettingIdList.push(state.decisionProcessChangeQueryResult.selectMagSelectFlowInfoOutDtoList[i].progressStatusSettingId.toString())
                    continue
                }
            }
            state.progressNameList = progressNameList

            // 不合格理由リストを設定
            let failureReasonIdCodeList: { value: string; label: string; }[] = []
            for (var i = 0; i < state.decisionProcessChangeQueryResult.failureReasonIdCodeList.length; i++) {
                let value = state.decisionProcessChangeQueryResult.failureReasonIdCodeList[i].itemCode
                let label = ''
                if (i != state.decisionProcessChangeQueryResult.failureReasonIdCodeList.length - 1) {
                    label = value + ':' + state.decisionProcessChangeQueryResult.failureReasonIdCodeList[i].itemName
                } else {
                    label = state.decisionProcessChangeQueryResult.failureReasonIdCodeList.length + ':' + state.decisionProcessChangeQueryResult.failureReasonIdCodeList[i].itemName
                }
                failureReasonIdCodeList.push({ value: value, label: label })
            }
            state.fairueReasonList = failureReasonIdCodeList
            // 辞退理由リストを設定
            let declineReasonIdCodeList: { value: string; label: string; }[] = []
            for (var i = 0; i < state.decisionProcessChangeQueryResult.declineReasonIdCodeList.length; i++) {
                let value = state.decisionProcessChangeQueryResult.declineReasonIdCodeList[i].itemCode
                let label = ''
                if (i != state.decisionProcessChangeQueryResult.declineReasonIdCodeList.length - 1) {
                    label = value + ':' + state.decisionProcessChangeQueryResult.declineReasonIdCodeList[i].itemName
                } else {
                    label = state.decisionProcessChangeQueryResult.declineReasonIdCodeList.length + ':' + state.decisionProcessChangeQueryResult.declineReasonIdCodeList[i].itemName
                }
                declineReasonIdCodeList.push({ value: value, label: label })
            }
            state.declineReasonList = declineReasonIdCodeList

            // value初期値を設定
            state.initialValues.selectionStep = action.payload.selectMagSelectSelectionInfoOutDtoList[0].progressStatusSettingId.toString()
            // 変更項目が複数件数の場合は不合格理由、辞退理由、は「（以下から選択）」、面接日時入力は空欄とする。
            if (action.payload.selectMagSelectSelectionInfoOutDtoList.length == 1) {
                let interviewTime: Date | null = stringToDateTime(action.payload.selectMagSelectSelectionInfoOutDtoList[0].interviewTime)
                state.initialValues.interviewDay = interviewTime ? interviewTime : ''
                state.initialValues.fairueReason = action.payload.selectMagSelectSelectionInfoOutDtoList[0].failureReasonId
                state.initialValues.declineReason = action.payload.selectMagSelectSelectionInfoOutDtoList[0].declineReasonId
                state.initialValues.other = action.payload.selectMagSelectSelectionInfoOutDtoList[0].inputContent
            } else {
                state.initialValues.interviewDay = ''
                state.initialValues.fairueReason = ''
                state.initialValues.declineReason = ''
                state.initialValues.other = ''
            }
            return state
        },
        // 転職march #9605 start
        setDialogOpen(state, action: PayloadAction<boolean>) {
            state.openDialogFlag = action.payload
            return state
        },
        initial(state) {
            state.initialValues = initialValues
            return state
        },
        // 転職march #9605 end
        // 推薦チェック
        recommendCheck(state, action: PayloadAction<RecommendCheckRequest>) {
            return state
        },
        // 推薦チェックDialogOpen
        setRecommendCheckDialogOpen(state, action: PayloadAction<number>) {
            let selectionStepBefore: string = state.initialValues.selectionStep
            let selectionStepAfter: string = state.updateProgressData.progressStatusSettingId
            // 1.5.2.1.1 変更後の選考ステップが「スクリーニング」「書類回収中」「不採用」「辞退」以外の場合
            // 1.5.2.1.1.2 処理「1.5.2.1.1.1.1」で取得した件数が0件の場合、ユーザに確認メッセージを表示する。
            if (action.payload == 0 && !state.recommendCheckProgressStatusSettingIdList.includes(selectionStepAfter)) {
                state.recommendCheckDialogOpeFlag = true
            }
            // 1.5.2.2 進捗変更前チェックを実施する 
            else {
                // 進捗が変更しない場合
                if (selectionStepAfter == selectionStepBefore) {
                    // 処理を継続する
                    state.updateProgressFlag = true
                }
                // 進捗が変更する場合
                else {
                    // 1.5.2.2 進捗変更前チェックを実施する
                    state.progressChangeBeforeCheckDialogOpeFlag = true
                }
            }
            return state
        },
        // 推薦チェックDialogOpen
        setRecommendCheckDialogClose(state, action: PayloadAction<boolean>) {
            state.recommendCheckDialogOpeFlag = action.payload
            return state
        },
        // 進捗変更前チェックDialogOpen
        setProgressChangeBeforeCheckDialogOpeFlag(state, action: PayloadAction<boolean>) {
            state.progressChangeBeforeCheckDialogOpeFlag = action.payload
            return state
        },
        // 
        setUpdateProgressData(state, action: PayloadAction<MCAXS110UpdateProgressRequest>) {
            state.updateProgressData = action.payload
            // sysバージョン情報（進捗ステータス管理）
            state.updateProgressData.versionNumberProgressStatusManagement = state.decisionProcessChangeQueryResult.sysVersionNumberPsmaList
            // sysバージョン情報（進捗ステータス管理詳細）
            state.updateProgressData.versionNumberProgressStatusManagementDetail = state.decisionProcessChangeQueryResult.sysVersionNumberPsmdList
            return state
        },
        // 
        updateProgress(state, action: PayloadAction<MCAXS110UpdateProgressRequest>) {
            state.updateProgressFlag = false
            return state
        },
        // キャンセルチェックDialogOpen
        setCancelCheckDialogOpeFlag(state, action: PayloadAction<boolean>) {
            state.cancelCheckDialogOpeFlag = action.payload
            return state
        },
    },
})

export const {
    progressStatusChangeInit,
    setProgressStatusChangeInit,
    setDialogOpen,
    recommendCheck,
    setRecommendCheckDialogOpen,
    setRecommendCheckDialogClose,
    setProgressChangeBeforeCheckDialogOpeFlag,
    setUpdateProgressData,
    updateProgress,
    setCancelCheckDialogOpeFlag,
    // 転職march #9605 start
    initial,
    // 転職march #9605 end
} = progressStatusChangeSlice.actions

export default progressStatusChangeSlice.reducer