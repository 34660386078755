/** 初期表示リクエスト */
export interface ApplicantDetailsRequest {
  [key: string]: any
  applicantsListInfo: ApplicantsInfo[]
  directionId: string
  displayTargetId: string
  listIndex: number
  mediaFlag: string | null
  coordinationCompanyKey: string | null
  coordinationEntryKey: string | null
  sourceFunctionId: string | null
}
export interface ApplicantsInfo {
  [key: string]: any
  jobSeekerId: number //求職者ID
  entryId: number //エントリーID
  selectionManagementId: number //選考管理ID
}

export interface CurrentApplicantsListInfo {
  [key: string]: any
  applicantsListInfo: ApplicantsInfo[]
  listIndex: number
}

export interface ApplicantDetailsRequestInfo {
  [key: string]: any
  applicantDetailsRequest: ApplicantDetailsRequest
  currentApplicantsListInfo: CurrentApplicantsListInfo
}

/** サーバレスポンスからもらう情報 */
/** 初期表示 (初期表示リクエストに対するレスポンス内容) */
export interface InitResult {
  [key: string]: any
  commonHeaderInfo: CommonHeaderInfo
  applicantDataHeaderInfo: ApplicantDataHeaderInfo
  applicantDetailsTargetInfo: ApplicantDetailsTargetInfo
  displayTargetListInfoList: DisplayTargetListInfoList[]
  duplicationInfoList: DuplicationInfoList[]
  profileInfo: ProfileInfo
  entryInfo: EntryInfo
  questionInfoList: QuestionInfoList[]
  aptitudeTestIMAGESInfo: AptitudeTestIMAGESInfo
  aptitudeTestGABInfo: AptitudeTestGABInfo
  aptitudeTestOPQInfo: AptitudeTestOPQInfo
  aptitudeTestCABInfo: AptitudeTestCABInfo
  jobCareerInfoList: JobCareerInfoList[]
  nuisanceHistory: string[]
  messageInfoList: MessageInfoList[]
  selectionFlow: SelectionFlow
  managementItem: ManagementItem
}

/** 共通ヘッダ情報 */
export interface CommonHeaderInfo {
  [key: string]: any
  companyName: string
  fullName: string
}

/** 応募者データヘッダ情報 */
export interface ApplicantDataHeaderInfo {
  [key: string]: any
  familyName: string
  name: string
  familyNameKana: string
  nameKana: string
  jobSeekerIdForDisplay: string
  birthdate: string
  sex: string
  unsubscribeTime: string
}

/** 応募者詳細表示対象情報 */
export interface ApplicantDetailsTargetInfo {
  [key: string]: any
  displayTargetId: string
  applicantsListInfo: ApplicantsInfo[]
  listIndex: number
  directionId: string
}

/** 表示権限情報 */
export interface DisplayTargetListInfoList {
  [key: string]: any
  jobSeekerId: number
  entryId: number
  selectionManagementId: number
  sysVersionNumber: number
}

/** 重複情報 */
export interface DuplicationInfoList {
  [key: string]: any
  countId: string
  targetCount: number
}

/** プロフィール情報 */
export interface ProfileInfo {
  [key: string]: any
  facePhotoManagementId: string
  scanVirusFlag: boolean
  familyName: string
  name: string
  familyNameKana: string
  nameKana: string
  postalCode: string
  prefecturesName: string
  addressAfterCity: string
  birthdate: string
  sex: string
  spouse: string
  currentAnnualIncome: string
  companiesExperience: string
  employmentStatus: string
  phoneNumber3: string
  phoneNumber1: string
  mailAddress2: string
  mailAddress1: string
  educationalBackgroundSummary: string
}

/** エントリー時情報 */
export interface EntryInfo {
  [key: string]: any
  familyName: string
  name: string
  familyNameKana: string
  nameKana: string
  postalCode: string
  prefecturesName: string
  addressAfterCity: string
  birthdate: string
  sex: string
  spouse: string
  currentAnnualIncome: string
  companiesExperience: string
  employmentStatus: string
  phoneNumber3: string
  phoneNumber1: string
  mailAddress2: string
  mailAddress1: string
  educationalBackgroundSummary: string
  suggestedWorkingPlace: string[]
  suggestedAnnualIncome: string
  suggestedJobChangeTime: string
  experienceJobCategorySummary: string
  wishIncentive: string
  selfPr: string
  notice: string
  tag4: string
  tag3: string
  entryType: string
  iconName0: string
  iconName1: string
  iconName2: string
  iconName3: string
  iconName4: string
}

/** 質問回答情報 */
export interface QuestionInfoList {
  [key: string]: any
  question: string
  answer: string
}

/** 職務経歴情報 */
export interface JobCareerInfoList {
  [key: string]: any
  companyName: string
  enrollmentPeriod: string
  dutyCareerSummary: string
}

/** 適性テスト（イメジス）情報 */
export interface AptitudeTestIMAGESInfo {
  [key: string]: any
  imagesDataId: string
  comprehensiveDeviationValue: number
  countingDeviationValue: number
  languageDeviationValue: number
  englishDeviationValue: number
  countingStandardPoint: number
  languageStandardPoint: number
  englishStandardPoint: number
  irealityImagination: number
  mflexibleMethodical: number
  aselfPacedEffortIntention: number
  gintrovertedOpened: number
  erelaxWorrier: number
  scalmSympathy: number
  evigor: number
  hantipersonnel: number
  mfixation: number
  birthdate: string
  updateRoute: number
  updateTime: string
  // Phase2 Start
  dstaticDynamic: number 
  wthinkingKindness: number
  shlCompanyId: number  // SHL企業ID
  mwMemberId: string  // 受験番号
  // Phase2 End
}

/** 適性テスト（GAB）情報 */
export interface AptitudeTestGABInfo {
  [key: string]: any
  webGabDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  personalId: string
  kanjiFamilyName: string
  kanjiName: string
  kanaFamilyName: string
  kanaName: string
  eMail: string
  examLanguage: string
  languageExamDate: string
  countingExamDate: string
  opqExamDate: string
  languageStandardPoint: number
  countingStandardPoint: number
  comprehensiveStandardPoint: number
  languageDeviationValue: number
  countingDeviationValue: number
  comprehensiveDeviationValue: number
  languageRawScore: number
  countingRawScore: number
  languageCorrectAnswerRate: number
  countingCorrectAnswerRate: number
  languageDlTimes: number
  countingDlTimes: number
  managementCapacity: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  specialistGeneralistCompetence: number
  salesI: number
  salesIi: number
  staff: number
  generalOffice: number
  engineer: number
  researchDevelopment: number
  serviceOverTheCounterSalesCallCenter: number
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
}

/** 適性テスト（OPQ）情報 */
export interface AptitudeTestOPQInfo {
  [key: string]: any
  webOpqDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  test: string
  version: string
  examDate: string
  examNumber: string
  eMail: string
  kanaFullName: string
  kanjiFullName: string
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
  managementCapacity: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  salesI: number
  salesIi: number
  staff: number
  generalOffice: number
  engineer: number
  researchDevelopment: number
  serviceOverTheCounterSalesCallCenter: number
}

/** 適性テスト（CAB）情報 */
export interface AptitudeTestCABInfo {
  [key: string]: any
  webCabDataId: string
  updateRoute: number
  importHistorySerialNumber: number
  shlCompanyId: number
  updateTime: string
  personalId: string
  kanjiFamilyName: string
  kanjiName: string
  kanaFamilyName: string
  kanaName: string
  mailAddress: string
  birthdate: string
  examLanguage: string
  cab1ExamDate: string
  cab2ExamDate: string
  cab3ExamDate: string
  cab4ExamDate: string
  opqExamDate: string
  cab1StandardPoint: number
  cab2StandardPoint: number
  cab3StandardPoint: number
  cab4StandardPoint: number
  comprehensiveStandardPoint: number
  cab1DeviationValue: number
  cab2DeviationValue: number
  cab3DeviationValue: number
  cab4DeviationValue: number
  comprehensiveDeviationValue: number
  cab1RawScore: number
  cab2RawScore: number
  cab3RawScore: number
  cab4RawScore: number
  cab1CorrectAnswerRate: number
  cab2CorrectAnswerRate: number
  cab3CorrectAnswerRate: number
  cab4CorrectAnswerRate: number
  cab1dlTimes: number
  cab2dlTimes: number
  cab3dlTimes: number
  cab4dlTimes: number
  pgCompetence: number
  seCompetence: number
  ceCompetence: number
  pmCompetence: number
  vitality: number
  perPerson: number
  teamwork: number
  creativeThinkingPower: number
  problemSolvingPower: number
  statusAdaptationPower: number
  proofStressToPressure: number
  organizeAbility: number
  commandPower: number
  persuasionPower: number
  coachingPower: number
  uniqueness: number
  extroversion: number
  friendship: number
  sociality: number
  humility: number
  consultation: number
  toCare: number
  concreteThing: number
  data: number
  aestheticValue: number
  human: number
  orthodox: number
  changeIntention: number
  conceptuality: number
  creative: number
  planning: number
  accurate: number
  methodical: number
  allowance: number
  worrier: number
  tough: number
  suppression: number
  optimistic: number
  criticism: number
  activityPower: number
  competitiveness: number
  upwardIntention: number
  decisionPower: number
  consistency: number
}

/** 迷惑行為報告情報 */
export interface NuisanceHistory {
  [key: string]: any
  nuisance: string
}

/** メッセージ情報 */
export interface MessageInfoList {
  [key: string]: any
  messageId: string
  messageSendId: string
  selectionName: string
  sendAndReceiveDivision: string
  status: string
  // phase2 start
  sendTimeFlag:String
  // phase2 end
  messageType: string
  entryType: string
  readFlag: string
  sendPlanTime: string
  sendTime: string
  receivingTime: string
  subject: string
  maxSequence: string
  messageSendType: string
  repliedFlag: string
  senderName: string
  convertingBatchCompleteFlag: string
}

/** 選考フローエリア */
export interface SelectionFlow {
  [key: string]: any
  selectionFlowColumnList: SelectionFlowColumnList[]
  applicationDivisionBadgeList: string[]
  scoutBenefitList: string[]
  otherBadgeList: string[]
  recruitmentBadge: string
  applicationRequirements: string
  selectionFlowTagsDisplay: string
}

/** 選考フロー情報 */
export interface SelectionFlowColumnList {
  [key: string]: any
  selectionManagementId: number
  selectionFlowSettingId: number
  selectionFlowInterviewSchedule: string
  selectionFlowMediumBadgeList: string[]
  applicationRouteInfoList: ApplicationRouteInfo[]
  selectionFlowNameBadge: string
  entryJobCategoryName: string
  entryJobCategoryNameBadgeList: string[]
  selectionFlowGPBadge: boolean
  selectionFlowSelectionStepBadge: string
  selectionFlowApplicationDateTime: string
  recommendDate: string
  recommendFlag: string
  selectionFlowExclude: boolean
  selectionFlowUnExclude: boolean
  marchLabel: MarchLabel
  selectionFlowProgressBarList: SelectionFlowProgressBarList[]
  selectionFlowJudgeBadge: boolean
  selectionFlowCurrentProgress: string
  selectionFlowProgressBar: string
  selectionFlowProgressName: string
  selectionFlowProgressUpdateDateTime: string
  selectionFlowEvaluation: string
  selectionFlowComment: string
  sysVersionNumber: number
  entryId: number
  defaultChecked: boolean
  entryCode:string
  progressManagementSysVersionNumber: number
}
export interface MarchLabel {
  [key: string]: any
  marchProgressStatus: string
  interviewDay: string
  resultJudgment: string
  failureReason: string 
  failureReasonOther: string
  declineReason: string 
  declineReasonOther: string
  lastUpdated: string 
}

/** 応募経路情報 */
export interface ApplicationRouteInfo {
  selectionManagementId: number
  mediaId: string
  routeName: string
  routeId: number
  sysVersionNumber: number
}
/** 選考フロー進捗バー */
export interface SelectionFlowProgressBarList {
  [key: string]: any
  selectionFlowJudgeBadge: null
  selectionFlowCurrentProgress: false
  selectionFlowProgressBarColor: null
  selectionFlowProgressName: string
  selectionFlowProgressUpdateDateTime: string
}

/** 管理項目エリア */
export interface ManagementItem {
  managementItemSelectionFlowName: string
  managementItemColumnList: ManagementItemColumn[]
}

export interface ManagementItemColumn {
  managementItemNo: number
  managementItemName: string
  managementItemValue: string
  managementItemUpdateDateTime: string
}

/** 初期表示リクエスト */
export const applicantDetailsRequest: ApplicantDetailsRequest = {
  applicantsListInfo: [],
  directionId: '',
  displayTargetId: '',
  listIndex: 0,
  mediaFlag: null,
  coordinationCompanyKey: null,
  coordinationEntryKey: null,
  sourceFunctionId: null,
}

/** 共通ヘッダ情報 */
export const commonHeaderInfo: CommonHeaderInfo = {
  companyName: '',
  fullName: '',
}

/** 応募者データヘッダ情報 */
export const applicantDataHeaderInfo: ApplicantDataHeaderInfo = {
  familyName: '',
  name: '',
  familyNameKana: '',
  nameKana: '',
  jobSeekerIdForDisplay: '',
  birthdate: '',
  sex: '',
  unsubscribeTime: '',
}

/** 応募者詳細表示対象情報 */
export const applicantDetailsTargetInfo: ApplicantDetailsTargetInfo = {
  displayTargetId: '',
  applicantsListInfo: [],
  listIndex: 0,
  directionId: '',
}

/** プロフィール情報 */
export const profileInfo: ProfileInfo = {
  facePhotoManagementId: '',
  scanVirusFlag: false,
  familyName: '',
  name: '',
  familyNameKana: '',
  nameKana: '',
  postalCode: '',
  prefecturesName: '',
  addressAfterCity: '',
  birthdate: '',
  sex: '',
  spouse: '',
  currentAnnualIncome: '',
  companiesExperience: '',
  employmentStatus: '',
  phoneNumber3: '',
  phoneNumber1: '',
  mailAddress2: '',
  mailAddress1: '',
  educationalBackgroundSummary: '',
}

/** エントリー時情報 */
export const entryInfo: EntryInfo = {
  familyName: '',
  name: '',
  familyNameKana: '',
  nameKana: '',
  postalCode: '',
  prefecturesName: '',
  addressAfterCity: '',
  birthdate: '',
  sex: '',
  spouse: '',
  currentAnnualIncome: '',
  companiesExperience: '',
  employmentStatus: '',
  phoneNumber3: '',
  phoneNumber1: '',
  mailAddress2: '',
  mailAddress1: '',
  educationalBackgroundSummary: '',
  suggestedWorkingPlace: [],
  suggestedAnnualIncome: '',
  suggestedJobChangeTime: '',
  experienceJobCategorySummary: '',
  wishIncentive: '',
  selfPr: '',
  notice: '',
  tag4: '',
  tag3: '',
  entryType: '',
  iconName0: '',
  iconName1: '',
  iconName2: '',
  iconName3: '',
  iconName4: '',
}

/** 選考フロー情報MarchModeのラベル情報 */
export const marchLabel: MarchLabel = {
  marchProgressStatus: "",
  interviewDay: "",
  resultJudgment: "",
  failureReason: "",
  failureReasonOther: "",
  declineReason: "",
  declineReasonOther: "",
  lastUpdated: "",
}

/** 選考フロー情報 */
export const selectionFlowColumn: SelectionFlowColumnList = {
  selectionManagementId: 0,
  selectionFlowSettingId: 0,
  selectionFlowInterviewSchedule: '',
  selectionFlowMediumBadgeList: [],
  applicationRouteInfoList: [],
  selectionFlowNameBadge: '',
  entryJobCategoryName: '',
  entryJobCategoryNameBadgeList: [],
  selectionFlowGPBadge: false,
  selectionFlowSelectionStepBadge: '',
  selectionFlowApplicationDateTime: '',
  recommendDate: '',
  recommendFlag: '',
  selectionFlowExclude: false,
  selectionFlowUnExclude: false,
  marchLabel: marchLabel,
  selectionFlowProgressBarList: [],
  selectionFlowJudgeBadge: false,
  selectionFlowCurrentProgress: '',
  selectionFlowProgressBar: '',
  selectionFlowProgressName: '',
  selectionFlowProgressUpdateDateTime: '',
  selectionFlowEvaluation: '',
  selectionFlowComment: '',
  sysVersionNumber: 0,
  entryId: 0,
  defaultChecked: false,
  entryCode:'',
  progressManagementSysVersionNumber: 0,
}
/** 選考フローエリア */
export const selectionFlow: SelectionFlow = {
  selectionFlowColumnList: [],
  applicationDivisionBadgeList: [],
  scoutBenefitList: [],
  otherBadgeList: [],
  recruitmentBadge: '',
  applicationRequirements: '',
  selectionFlowTagsDisplay: '',
}
/** 応募経路情報 */
export const applicationRouteInfo: ApplicationRouteInfo = {
  selectionManagementId: 0,
  mediaId: '',
  routeName: '',
  routeId: 0,
  sysVersionNumber: 0,
}

/** IMAGES情報 */
export const aptitudeTestIMAGESInfo: AptitudeTestIMAGESInfo = {
  imagesDataId: '',
  comprehensiveDeviationValue: 0,
  countingDeviationValue: 0,
  languageDeviationValue: 0,
  englishDeviationValue: 0,
  countingStandardPoint: 0,
  languageStandardPoint: 0,
  englishStandardPoint: 0,
  irealityImagination: 0,
  mflexibleMethodical: 0,
  aselfPacedEffortIntention: 0,
  gintrovertedOpened: 0,
  erelaxWorrier: 0,
  scalmSympathy: 0,
  evigor: 0,
  hantipersonnel: 0,
  mfixation: 0,
  birthdate: '',
  updateRoute: 0,
  updateTime: '',
  // Phase2 Start
  dstaticDynamic: 0, 
  wthinkingKindness: 0,
  shlCompanyId: 0,
  mwMemberId: "",
  // Phase2 End

}

/** GAB情報 */
export const aptitudeTestGABInfo: AptitudeTestGABInfo = {
  webGabDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  personalId: '',
  kanjiFamilyName: '',
  kanjiName: '',
  kanaFamilyName: '',
  kanaName: '',
  eMail: '',
  examLanguage: '',
  languageExamDate: '',
  countingExamDate: '',
  opqExamDate: '',
  languageStandardPoint: 0,
  countingStandardPoint: 0,
  comprehensiveStandardPoint: 0,
  languageDeviationValue: 0,
  countingDeviationValue: 0,
  comprehensiveDeviationValue: 0,
  languageRawScore: 0,
  countingRawScore: 0,
  languageCorrectAnswerRate: 0,
  countingCorrectAnswerRate: 0,
  languageDlTimes: 0,
  countingDlTimes: 0,
  managementCapacity: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  specialistGeneralistCompetence: 0,
  salesI: 0,
  salesIi: 0,
  staff: 0,
  generalOffice: 0,
  engineer: 0,
  researchDevelopment: 0,
  serviceOverTheCounterSalesCallCenter: 0,
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
}

/** OPQ情報 */
export const aptitudeTestOPQInfo: AptitudeTestOPQInfo = {
  webOpqDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  test: '',
  version: '',
  examDate: '',
  examNumber: '',
  eMail: '',
  kanaFullName: '',
  kanjiFullName: '',
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
  managementCapacity: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  salesI: 0,
  salesIi: 0,
  staff: 0,
  generalOffice: 0,
  engineer: 0,
  researchDevelopment: 0,
  serviceOverTheCounterSalesCallCenter: 0,
}

/** CAB情報 */
export const aptitudeTestCABInfo: AptitudeTestCABInfo = {
  webCabDataId: '',
  updateRoute: 0,
  importHistorySerialNumber: 0,
  shlCompanyId: 0,
  updateTime: '',
  personalId: '',
  kanjiFamilyName: '',
  kanjiName: '',
  kanaFamilyName: '',
  kanaName: '',
  mailAddress: '',
  birthdate: '',
  examLanguage: '',
  cab1ExamDate: '',
  cab2ExamDate: '',
  cab3ExamDate: '',
  cab4ExamDate: '',
  opqExamDate: '',
  cab1StandardPoint: 0,
  cab2StandardPoint: 0,
  cab3StandardPoint: 0,
  cab4StandardPoint: 0,
  comprehensiveStandardPoint: 0,
  cab1DeviationValue: 0,
  cab2DeviationValue: 0,
  cab3DeviationValue: 0,
  cab4DeviationValue: 0,
  comprehensiveDeviationValue: 0,
  cab1RawScore: 0,
  cab2RawScore: 0,
  cab3RawScore: 0,
  cab4RawScore: 0,
  cab1CorrectAnswerRate: 0,
  cab2CorrectAnswerRate: 0,
  cab3CorrectAnswerRate: 0,
  cab4CorrectAnswerRate: 0,
  cab1dlTimes: 0,
  cab2dlTimes: 0,
  cab3dlTimes: 0,
  cab4dlTimes: 0,
  pgCompetence: 0,
  seCompetence: 0,
  ceCompetence: 0,
  pmCompetence: 0,
  vitality: 0,
  perPerson: 0,
  teamwork: 0,
  creativeThinkingPower: 0,
  problemSolvingPower: 0,
  statusAdaptationPower: 0,
  proofStressToPressure: 0,
  organizeAbility: 0,
  commandPower: 0,
  persuasionPower: 0,
  coachingPower: 0,
  uniqueness: 0,
  extroversion: 0,
  friendship: 0,
  sociality: 0,
  humility: 0,
  consultation: 0,
  toCare: 0,
  concreteThing: 0,
  data: 0,
  aestheticValue: 0,
  human: 0,
  orthodox: 0,
  changeIntention: 0,
  conceptuality: 0,
  creative: 0,
  planning: 0,
  accurate: 0,
  methodical: 0,
  allowance: 0,
  worrier: 0,
  tough: 0,
  suppression: 0,
  optimistic: 0,
  criticism: 0,
  activityPower: 0,
  competitiveness: 0,
  upwardIntention: 0,
  decisionPower: 0,
  consistency: 0,
}

/** 管理項目エリア */
export const managementItem: ManagementItem = {
  managementItemSelectionFlowName: '',
  managementItemColumnList: [],
}

/** 画面遷移リクエスト */
export interface ScreenTransitionRequest {
  screenId: string
  jobSeekerId: string
  jobSeekerIdForDisplay: string
  entryData: EntryData[]
}

/** 画面遷移リクエスト(PDF) */
export interface PDFScreenTransitionRequest {
  jobSeekerId: string[]
  entryId: string[]
  pdfType: string
  prevScreenId: string
}

export interface EntryData {
  entryId: string
  selectionManagementId: string
}

export interface SelectionInfo {
  managementId: number[]
  jobSeekerId: number[]
  entryId: number[]
  screenRepresentationID: number[]
}

export interface SelectionFlowChange {
  targetSelectionManagementId: string[]
  targetEntryId: string[]
  filterdSelectionFlowSettingId: string
}

export interface MCAXS170InitRequest {
  screenId: string
  jobSeekerId: string[]
  selectionManagementId: string[]
  entryId: string[]
}

export interface NotAdoptedNotificationRequest {
  entryId: number
  jobSeekerId: number
  selectionManagementId: number
  notAdoptedNotificationType: string
  selectionManagementSysVersionNumber: number
  progressManagementSysVersionNumber: number
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>
}

export interface MCAXS050ServiceModeUpdateRequest {
  selectionManagementId: number | null
  searchLinkFlg?: boolean
  sourceFunctionId: string | null
}