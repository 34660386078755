import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface attachmentList {
 
    // エントリーID
    entryId:number

    // 連番
    sequence:number

    // 添付ファイル名
    attachmentName:string

    // ファイル管理ID
    fileManagementId:string

    // 登録日時
    registrationTime:string

    // 氏名
    fullName:string

    //sysバージョン番号
    sysVersionNumber:number;
}

export interface TemData {
    [key: string]: any
    uploadfiletemp: File
}

export interface initInfo{
  downLoadFlag:string,
  fileList:attachmentList[],
}


const initialState:initInfo={
  downLoadFlag:"0",
  fileList:[]
}



const  AttachmentListSlice = createSlice({
  name: 'attachmentList',
  initialState,
  reducers: {
    fetchAttachmentList(state,action:PayloadAction<{entryId:string}>) {
      return state
    },
    setAttachmentList(state,action: PayloadAction<initInfo>){
        return action.payload;
    },
    deleteAttachment(state,action:PayloadAction<{entryAttachedFileId:string,entryId:string,fileName:string,sequence:number,sysVersionNumber:number}>){
      return state
    }
  }
})

export const {
    fetchAttachmentList,
    setAttachmentList,
    deleteAttachment
} = AttachmentListSlice.actions
export default AttachmentListSlice.reducer
