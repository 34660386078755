import request from 'utils/request'
import { MCAZS210InitRequest } from 'types/MCAZS210/MCAZS210InitRequest'
import { MCAZS210DownloadFileRequest } from 'types/MCAZS210/MCAZS210DownloadFileRequest'
import { MCAZS210DownloadCsvRequest } from 'types/MCAZS210/MCAZS210DownloadCsvRequest'

export const searchMessageDetailRequest = (apiData: MCAZS210InitRequest) => {
  // const params = new URLSearchParams(apiData as {}).toString()

  return request({
    url: '/MCAZS210/init',
    method: 'post',
    data: apiData,
  })
}

export const attachmentFileDownloadRequest = (apiData: MCAZS210DownloadFileRequest) =>
  request({
    url: '/MCAZS210/AttachmentFileLink',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })

export const destinationDownloadRequest = (apiData: MCAZS210DownloadCsvRequest) =>
  request({
    url: '/MCAZS210/destinationDownloadExecute',
    method: 'post',
    responseType:"blob",
    data: apiData,
  })