import { MCAMS040InitDisplayRequest } from 'types/MCAMS040/MCAMS040InitDisplayRequest'
import { MCAMS040generateMessageTemplateRequest } from 'types/MCAMS040/MCAMS040generateMessageTemplateRequest'
import request from 'utils/request'

export const initRequest = (params: MCAMS040InitDisplayRequest) =>
request({
    url: `MCAMS040/init`,
    method: 'post',
    data: params,
})

export const insertOrUpdateRequest=(requestBody:{registeredFileName:File[],submitParams:MCAMS040generateMessageTemplateRequest}) =>{
    const data = new FormData()
    requestBody.registeredFileName.forEach((file) => {
        data.append('files', file);
    });
    data.append('params', encodeURIComponent(JSON.stringify(requestBody.submitParams)))
    
    return request({
      url: `MCAMS040/resister`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data:data,
    })
  }