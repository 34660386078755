import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import history from 'utils/history'

interface Props {
  to: string
  className?: string
  size?: {
    height: string
    width: string
  }
  title?: string
  data: any
  storageName?: string
}

const NavigateButton: React.FC<Props> = ({
  to,
  className,
  children,
  size,
  title,
  data,
  storageName,
}) => {
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)
  const handleClick = () => {
    if (storageName) {
      localStorage.setItem(storageName, JSON.stringify(data))
    }
    localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
    size
      ? window.open(
          to,
          'newWindow',
          `width=${size.width}, height=${size.height}, resizable=yes`
        )
      : history.push(to)
  }

  return title ? (
    <button
      type='button'
      onClick={handleClick}
      className={className}
      title={title}>
      {children}
    </button>
  ) : (
    <button type='button' onClick={handleClick} className={className}>
      {children}
    </button>
  )
}

export default NavigateButton
