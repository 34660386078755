import { RootState } from 'reducers'

export const getAccountList = (state: RootState) => state.account
export const getAccountByLoginId = (loginId: string) => (state: RootState) =>
  state.account.find(i => i.loginId === loginId)

// アカウント一覧条件
export const getExtraAccountListSearchCondition = (state: RootState) =>
  state.extraAccount.extraAccountListSearchCondition
// token
export const token = (state: RootState) => state.user.token
//
export const getCompanyAccountId = (state: RootState) =>
  state.user.companyAccountId
// token
export const seltoken = (state: RootState) =>
state.user.selectToken
export const getUrl = (state: RootState) =>
state.user.url

// 利用者IDと対象者名を取得する
export const getJobSeeker = (state: RootState) =>
  state.spamActReport.jobSeekerResults
// 迷惑行為レポート情報取得
export const spamActReportInfo = (state: RootState) =>
  state.spamActReport.spamActReportInfoResult
// 迷惑行為レポート確認情報取得
export const spamActReportValidationInfo = (state: RootState) =>
  state.spamActReport.SpamActReportValidationInfoResult
// 設定初期情報取得
export const alertPreferenceSetting = (state: RootState) =>
  state.alertPreferenceSetting.alertPreferenceSettingInfoResults
// タグ一覧検索条件取得
export const getTagManagementSearchCondition = (state: RootState) =>
  state.tagManagement.TagManagementListSearchCondition
// メッセージ一覧（下書き）条件
export const getMessageDraftListSearchCondition = (state: RootState) =>
  state.messageList.messageDraftListSearchCondition
// タグ削除検索条件取得
export const getTagManagementDeleteCondition = (state: RootState) =>
  state.tagManagement.TagManagementDeleteCondition

// メッセージ一覧（受信トレー）条件
export const getMessageReceiveListSearchCondition = (state: RootState) =>
  state.messageList.messageReceiveListSearchCondition

// メッセージ一覧（送信トレー）条件
export const getMessageOutBoxListSearchCondition = (state: RootState) =>
state.messageList.messageOutBoxListSearchCondition

//MCAZS170 Start
//MCBメッセージ一覧（送信トレー） MCAZS170
export const getMcbMessageOutBoxListSearchCondition = (state: RootState) =>
state.mcbMessageList.messageOutBoxListSearchCondition
//MCAZS170 End
//MCAZS190 Start
//MCBメッセージ一覧（下書き）条件
export const getMcbMessageDraftListSearchCondition = (state: RootState) =>
// MCB リプレース対応 B票#522対応 start
state.mcbMessageList.mcbMessageDraftListSearchCondition
// MCB リプレース対応 B票#522対応 end
//MCAZS190 End
//MCAZS200 Start
//MCBメッセージ一覧（ゴミ箱）条件 MCAZS200
export const getMcbMessageDustboxListSearchCondition = (state: RootState) =>
state.mcbMessageList.mcbMessageDustboxListSearchCondition
//MCAZS200 End
// メッセージ一覧（ゴミ箱）条件
export const getMessageDustboxListSearchCondition = (state: RootState) =>
  state.messageList.messageDustboxListSearchCondition

// メッセージ一覧（送信予約）条件
export const getMessageReservationListSearchCondition = (state: RootState) =>
  state.messageList.messageReservationListSearchCondition

// カスタマイズ進捗追加/更新スイッチ
export const getSwitchForProgressAddOrChange = (state: RootState) =>
 state.selectionFlow.switchForProgressAddOrChange
 
// 選考フロー更新用　カスタマイズ進捗追加/更新スイッチ
export const getSwitchForProgressAddOrChangeOnUpdate = (state: RootState) =>
state.selectionFlow.switchForProgressAddOrChangeOnUpdate

// メッセージテンプレート一覧検索条件取得
export const getSearchCondition = (state: RootState) =>
  state.messageTemplate.mCAMS010MessageTemplateList

// 名寄せ画面初期フラグ
export const getDelectFlagForDuplicate = (state: RootState) =>
state.duplicateIdentification.delectFlag
// phase2 start
// login out 
export const loginUrl = (state: RootState) => state.url.LOGIN_URL
// phase2 end

// MCBリプレース MCBJS030 START
// 提出リクエストテンプレート一覧検索条件取得
export const getSubSearchCondition = (state: RootState) =>
  state.submissionReqTemplate.mCBJS010SubmissionReqTemplateList
// MCBリプレース MCBJS030 END

// MCBリプレース MCBLS010 START
// PDF一覧検索条件取得
export const getPdfManagementSearchCondition = (state: RootState) =>
  state.mcbPdfDownload.pdfManagementListSearchCondition
// タグ削除検索条件取得
export const getPdfManagementDeleteCondition = (state: RootState) =>
  state.mcbPdfDownload.pdfManagementDeleteCondition
// MCBリプレース MCBLS010 END

// MCB ロット３　start
// メッセージ一覧 MCB送信予約件数取得
export const getMcbReserveCount = ((state: RootState) => state.messageList.mcbReserveCount)

// メッセージ一覧 MCB下書件数取得
export const getMcbDraftCount = ((state: RootState) => state.messageList.mcbDraftCount)
// MCB ロット３　end