import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

// #72427 次期開発2022年12月 start
const initialValues = {
  recruitmentManagementFlag: '',
  recruitmentManagementFlagId: '',
  targetYear: '',
  targetYearList: [],
  display: 0,
  type: 0,
  optionName: [],
  optionNameId: [] as string[],
  recruitmentManagementDivision: '',
}

export interface InitialValues {
  recruitmentManagementFlag: string
  recruitmentManagementFlagId: string
  targetYear: string
  targetYearList: string[]
  display: number
  type: number
  optionName: optionListInfo[]
  optionNameId: string[]
  recruitmentManagementDivision: string
}

export interface optionListInfo {
  optionName: string;
  optionSettingId: string;
}
const initRequest = {
  //管理項目ID
  recruitmentManagementFlagId: ''
}
// #72427 次期開発2022年12月 end
const displayRadioList = [
  { id: 'displayShow', label: '表示', value: 0 },
  { id: 'displayHide', label: '非表示', value: 1 },
]

const typeRadioList = [
  { id: 'typeList', label: '選択', value: 30 },
  { id: 'typeString', label: '文字列', value: 10 },
  { id: 'typeInt', label: '数値', value: 20 },
]

const textMap = {
  create: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS020_003),
  },
  cancel: {
    submit: 'OK',
    cancel: 'キャンセル',
    message: getMessage(magiContants.MESSAGECODE_MCAHS020_006),
  },
}
export {
  textMap,
  displayRadioList,
  typeRadioList,
  initialValues,
// #72427 次期開発2022年12月 start
  initRequest,
// #72427 次期開発2022年12月 end
}
