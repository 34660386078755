import request from 'utils/request'
import { MCBHS120OrderRequest } from 'types/MCBHS120/MCBHS120OrderRequest'


export const initSetDateSagaRequest = () =>
  request({
    url: '/MCBHS120/init',
    method: 'post',
  })

export const settingRequest = (apiData: MCBHS120OrderRequest) =>
  request({
    url: '/MCBHS120/setting',
    method: 'post',
    data: apiData
  })