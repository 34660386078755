/**
 * MCAZS200MessageQueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 検索対象
  // -
  searchTargetList: yup
    .string(),

  // 検索文字
  // -
  searchWordText: yup
    .string()
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

  // 削除日（FROM）
  // -
    deleteFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 削除日（TO）
  // -
    deleteToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  classification: yup.object().shape({
  // メッセージ分類_送信済み
  // -
  classificationSent: yup
    .string()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

  // メッセージ分類_下書き
  // -
  classificationDraft: yup
    .string()
    .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  }),

  // ソート順
  // -
  sort: yup
    .string(),

  // ページ数
  countPage: yup
  .number()
  .min(1, validationMessageSet.minValue),

  // 初回表示フラグ
  // -
  firstDispFlag: yup
    .string(),
});