import request from 'utils/request'
import { ApplicantDetailsRequest } from 'pages/MCAXS040/apiConfig'
import { MCAXS040ScreenTransitionRequest } from 'types/MCAXS040/MCAXS040ScreenTransitionRequest'
import { MCAXS040RouteScreenTransitionRequest } from 'types/MCAXS040/MCAXS040RouteScreenTransitionRequest'
import { MCAXS040UpdateExcludeRequest } from 'types/MCAXS040/MCAXS040UpdateExcludeRequest'
import { MCAXS040UpdateUnExcludeRequest } from 'types/MCAXS040/MCAXS040UpdateUnExcludeRequest'
import { MCAXS040URLParamRequest } from 'types/MCAXS040/MCAXS040URLParamRequest'
import { MCAXS040CreateMcbMessageRequest } from 'types/MCAXS040/MCAXS040CreateMcbMessageRequest'
import { MCAXS040McbMessageScreenTransitionRequest } from 'types/MCAXS040/MCAXS040McbMessageScreenTransitionRequest'
import { MCAXS040PDFDownloadTransitionRequest } from 'types/MCAXS040/MCAXS040PDFDownloadTransitionRequest'
import { MCAXS040McbCheckDetailsRequest } from 'types/MCAXS040/MCAXS040McbCheckDetailsRequest'
import { MCAXS040PdfDownloadAuthCheckRequest } from 'types/MCAXS040/MCAXS040PdfDownloadAuthCheckRequest'

export const initRequest = (interview: ApplicantDetailsRequest & { searchLinkFlg?: boolean }) =>
  request({
    url: '/MCAXS040/init',
    method: 'post',
    data: interview,
  })

export const attachedFileRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/attachedFile',
    method: 'post',
    data: interview,
  })

export const pdfOutputRequest = (interview: MCAXS040ScreenTransitionRequest) =>
  request({
    url: '/MCAXS040/pdfOutput',
    method: 'post',
    data: interview,
  })

export const createNewMessageRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/createNewMessage',
    method: 'post',
    data: interview,
  })

export const selectionFlowAddRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowAdd',
    method: 'post',
    data: interview,
  })

export const selectionFlowChangeRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowChange',
    method: 'post',
    data: interview,
  })

export const selectionFlowRouteAddRequest = (
  interview: MCAXS040RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowRouteAdd',
    method: 'post',
    data: interview,
  })

export const selectionFlowRouteChangeRequest = (
  interview: MCAXS040RouteScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowRouteChange',
    method: 'post',
    data: interview,
  })

export const selectionFlowExcludeRequest = (
  interview: MCAXS040UpdateExcludeRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowExclude',
    method: 'post',
    data: interview,
  })

export const selectionFlowUnExcludeRequest = (
  interview: MCAXS040UpdateUnExcludeRequest
) =>
  request({
    url: '/MCAXS040/selectionFlowUnExclude',
    method: 'post',
    data: interview,
  })

export const profileFacePhotoUploadRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/profileFacePhotoUpload',
    method: 'post',
    data: interview,
  })

export const profileEditRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/profileEdit',
    method: 'post',
    data: interview,
  })

export const entryHistoryNewReservationRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/entryHistoryNewReservation',
    method: 'post',
    data: interview,
  })

export const entryHistoryCancelRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/entryHistoryCancel',
    method: 'post',
    data: interview,
  })

export const managementItemUpdateRequest = (
  interview: MCAXS040ScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/managementItemUpdate',
    method: 'post',
    data: interview,
  })

  export const sendUrlParmRequest = (interview: MCAXS040URLParamRequest) =>
    request({
      url: '/MCAXS040/sendUrlParm',
      method: 'post',
      data: interview,
      responseType: 'blob',
    })

export const mcbMessageScreenTransitionRequest = (
  interview: MCAXS040McbMessageScreenTransitionRequest
) =>
  request({
    url: '/MCAXS040/mcbMessageScreenTransition',
    method: 'post',
    data: interview,
  })

export const createMcbMessageRequest = (
    interview: MCAXS040CreateMcbMessageRequest
  ) =>
    request({
      url: '/MCAXS040/createMcbMessage',
      method: 'post',
      data: interview,
    })
export const PDFDownloadTransitionRequest = (
    interview: MCAXS040PDFDownloadTransitionRequest
  ) =>
    request({
      url: '/MCAXS040/PDFDownloadTransition',
      method: 'post',
      data: interview,
    })
export const pdfDownloadAuthCheckRequest = (
  apiData: MCAXS040PdfDownloadAuthCheckRequest
) =>
  request({
    url: '/MCAXS040/pdfDownloadAuthCheck',
    method: 'post',
    data: apiData,
  })
export const pushMcbCheckDetailsRequest = (apiData: MCAXS040McbCheckDetailsRequest) =>
  request({
    url: '/MCAXS040/pushMcbCheckDetails',
    method: 'post',
    data: apiData,
  })
export const checkMcbServiceStatus = () =>
  request({
    url: '/MCAXS040/checkMcbServiceStatus',
    method: 'post',
  })
