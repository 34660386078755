

const attachmentTypeList = [
  { name: 'attachmentExist', label: 'あり', id: '1' },
  { name: 'attachmentNot', label: 'なし', id: '2' },
];

const templateOptionList = [
  { value: '0', label: '件名' },
  { value: '1', label: '本文' },
  { value: '2', label: '氏名' },
];

const initialValues = {
  searchTargetList:'0',
  searchWordText:'',
  updateFromDate:'',
  updateToDate:'',
  attachment: {
    attachmentExist:'0',
    attachmentNot:'0',
  },
  onlyBulkSend:'0',
};

const submitValue = {
  searchTargetList:'0',
  searchWordText:'',
  updateFromDate:'',
  updateToDate:'',
  attachmentExist:'0',
  attachmentNot:'0',
  onlyBulkSend:'0',
  sort:'0',
  countPage: 1,
  selectedFlag:'0',
  firstDispFlag: ''
} ;

const selectedDraftListInit = {
  mcbMessageInfoListDto: [],
  searchTargetList: '',
  searchWordText: '',
  updateFromDate: '',
  updateToDate: '',
  attachmentExist: '', 
  attachmentNot: '',
  onlyBulkSend: '', 
  sort: '',
  countPage: 0,
  selectedFlag: '',
}



export { initialValues, attachmentTypeList, submitValue, templateOptionList, selectedDraftListInit };
