import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import {
  FieldGroup,
  SubTitle,
  FormField,
  Title,
  DatePicker,
} from 'componentsHsc'
import { FastField } from 'formik'
import { FormControl } from '..'
import { RootState } from 'reducers'
import { useSelector, useDispatch } from 'react-redux'
import { getMcaxs531Init } from 'reducers/advancedSearchModalReducer'
import { validationMessageSet,magiContants as contants } from 'utils/contants'
import {
  EntryDateObj,
  EntryDateConditionObj,
} from '../Config/searchConditionConfig'
import { dateTimeToString,stringToDateTime } from 'common/generalUtil'
const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible !important',
  },
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span': {
      color: colors.grey[600],
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      margin: theme.spacing(0, 1),
    },
  },
  subtitle: {
    color: 'mediumaquamarine',
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: 'lightgrey',
  },
  errorBorder: {
    '& input': {
      border: '1px solid red'
    }
  },
  helperText: {
    color: '#e53935',
    whiteSpace: 'pre-wrap',
  }
}))

const searchOptionList = [
  { label: 'AND', value: '0' },
  { label: 'OR', value: '1' },
]

interface Errors {
  errorFlag: boolean
  errorInfo: string
  [key: string]: any
}

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
}

const EntryDateModal = ({ open, onClose, form }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [confirm, setConfirm] = useState(false)
  const [searchType, setSearchType] = useState('0')
  const { entryBoxInfoList, externalEntryInfoList } = useSelector(
    (state: RootState) => state.advancedSearchModal.entryDateModalInfo
  )
  const [errors, setErrors] = useState([] as Errors[])
  const [errors2, setErrors2] = useState([] as Errors[])

 const initializeErrors = (data:any) => {
  if (data.entryBoxInfoList && data.entryBoxInfoList.length > 0) {
    const errorS1: Errors[] = []
    for (let i = 0; i < data.entryBoxInfoList.length; i++) {
      errorS1.push({
        errorFlag: false,
        errorInfo: '',
        errorType: '',
      })
    }
    setErrors(errorS1)
  }
  if (data.externalEntryInfoList && data.externalEntryInfoList.length > 0) {
    const errorS2: Errors[] = []
    for (let i = 0; i < data.externalEntryInfoList.length; i++) {
      errorS2.push({
        errorFlag: false,
        errorInfo: '',
        errorType: '',
      })
    }
    setErrors2(errorS2)
  }
 }

 useEffect(() => {
    if (open) {
      dispatch(getMcaxs531Init({initializeErrors: initializeErrors}))     
    }
  },[open])
  
  useEffect(() => {
    if (open && entryBoxInfoList) {
      if (form.values.entryDateObj) {
        if (form.values.entryDateObj.entryDateConditionJudgmentFlag) {
          setSearchType(form.values.entryDateObj.entryDateConditionJudgmentFlag.toString())
        } else {
          setSearchType('0')
        }
        if (form.values.entryDateObj.entryDateConditionObj) {
          for (var i = 0; i < entryBoxInfoList.length; i++) {
            for (
              var j = 0;
              j < form.values.entryDateObj.entryDateConditionObj.length;
              j++
            ) {
              const fromObj = form.values.entryDateObj.entryDateConditionObj[j]
            if(fromObj.entryDateClassificationFlag === '0'){
              if (
                entryBoxInfoList[i].contractMediaId ===
                  fromObj.entryDateApplicationRouteId &&
                entryBoxInfoList[i].contractMediaName ===
                  fromObj.entryDateApplicationRouteName &&
                entryBoxInfoList[i].entryBoxId === fromObj.entryDateEntryBoxId
              ) {
                form.setFieldValue(
                  `entryDateObj.entryBoxInfoList[${i}].entryDateFrom`,
                  stringToDateTime(fromObj.entryDateFrom)
                )
                form.setFieldValue(
                  `entryDateObj.entryBoxInfoList[${i}].entryDateTo`,
                  stringToDateTime(fromObj.entryDateTo)
                )
              }
            }
           }
          }
        }
        if (form.values.entryDateObj.entryDateConditionObj) {
          for (var i = 0; i < externalEntryInfoList.length; i++) {
            for (
              var j = 0;
              j < form.values.entryDateObj.entryDateConditionObj.length;
              j++
            ) {
              const fromObj = form.values.entryDateObj.entryDateConditionObj[j]
              if(fromObj.entryDateClassificationFlag === '1'){
                if (
                  externalEntryInfoList[i].mediaSettingId ===
                  fromObj.entryDateApplicationRouteId &&
                  externalEntryInfoList[i].mediaRouteName ===
                  fromObj.entryDateApplicationRouteName
                  ) {
                  form.setFieldValue(
                  `entryDateObj.externalEntryInfo[${i}].exEntryDateFrom`,
                  stringToDateTime(fromObj.entryDateFrom)
                  )
                  form.setFieldValue(
                  `entryDateObj.externalEntryInfo[${i}].exEntryDateTo`,
                  stringToDateTime(fromObj.entryDateTo)
                )
              }
            }
           }
          }
        }
      }
    }
  }, [entryBoxInfoList])

  const handleClick = (open = true) => () => {
    setConfirm(open)
  }
  // phase2 start
  const handleClose = () => {
    setConfirm(false)
    onClose()
  }
  // phase2 end
  const handleChangeSearchType = (e: any) => {
    setSearchType(e.target.value)
  }

  const chkDate = (datestr: string) => {
    var iYear = Number(datestr.substr(0, 4)) - 0
    var iMonth = Number(datestr.substr(5, 2)) - 0
    var iDay = Number(datestr.substr(8, 2)) - 0
    if (iYear > 9999) {
      return false;
    }
    if ((iMonth >= 1) && (iMonth <= 12)) {
      var iMaxDay = 31;
      switch (iMonth) {
        case 4:
        case 6:
        case 9:
        case 11:
          iMaxDay = 30;
          break;
        case 2:
          if ((iYear % 4) == 0) {
            if ((iYear % 100) == 0) {
              if ((iYear % 400) == 0) {
                iMaxDay = 29;
              } else {
                iMaxDay = 28;
              }
            } else {
              iMaxDay = 29;
            }
          } else {
            iMaxDay = 28;
          }
          break;
        default:
          break;
      }
      if ((iDay >= 1) && (iDay <= iMaxDay)) {
        return true;
      }
    }
    return false;
  }
  const chkTime = (timeStr: string) => {
    var iHour = Number(timeStr.substr(0, 2)) - 0
    var iMinute = Number(timeStr.substr(3, 2)) - 0
    if (iHour > 24) {
      return false
    } else if (iHour == 24 && iMinute > 0) {
      return false
    } else if (iHour < 24 && iMinute >= 60) {
      return false
    }
  }
  const dateFormatCheck = (time: string) => {
    let errFlg: boolean = false
    const regDate = /^(\d{4}\/\d{1,2}\/\d{1,2}){0,1}$/u
    const regTime = /^(\d{2}:\d{2}){0,1}$/u
    var dateTime = new Date(time)
    var newDateTime = dateTime.getFullYear() + '/'
      + (dateTime.getMonth() + 1) + '/'
      + dateTime.getDate() + ' '
      + dateTime.getHours() + ':'
      + dateTime.getMinutes();
    var currentDateTime = dateTimeToString(stringToDateTime(newDateTime))
    var newDate = ''
    var newTime = ''
    if (null != currentDateTime) {
      newDate = currentDateTime.substring(0, 10)
      newTime = currentDateTime.substring(11, 16)
    }
    if (!newDate.match(regDate) || chkDate(newDate) == false) {
      errFlg = true
      
    } else {
      if (!newTime.match(regTime) || chkTime(newTime) == false) {
        errFlg = true
      }
    }
    return errFlg;
  }
  const handleSubmit = () => {
    let errFlg: boolean = true
    let entryDateObj: EntryDateObj = {
      entryDateConditionJudgmentFlag: 1,
      entryDateConditionJudgmentName: '',
      entryDateConditionObj: [],
    }
    var searchValue = searchType
    if (searchValue) {
      if (searchValue === '0') {
        entryDateObj.entryDateConditionJudgmentFlag = 0
        entryDateObj.entryDateConditionJudgmentName = 'AND'
      } else if (searchValue === '1') {
        entryDateObj.entryDateConditionJudgmentFlag = 1
        entryDateObj.entryDateConditionJudgmentName = 'OR'
      }
    }
    if (entryBoxInfoList) {
      if (entryBoxInfoList.length > 0) {
        const errorS: Errors[] = []
        for (var i = 0; i < entryBoxInfoList.length; i++) {
          let entryDateConditionObj: EntryDateConditionObj = {
            entryDateClassificationFlag: entryBoxInfoList[i].classificationFlag,
            entryDateClassificationName: 'エントリーボックス',
            entryDateApplicationRouteId: entryBoxInfoList[i].contractMediaId,
            entryDateApplicationRouteName:
              entryBoxInfoList[i].contractMediaName,
            entryDatecoordinationFormerEntryBoxId:
              entryBoxInfoList[i].coordinationFormerEntryBoxId,
            entryDateEntryBoxId: entryBoxInfoList[i].entryBoxId,
            entryDateEntryBoxName: entryBoxInfoList[i].entryBoxName,
            entryDateFrom: '',
            entryDateTo: '',
          }
          var fromDate: any = document.getElementById(`entryDateFrom[${i}]`)
          var toDate: any = document.getElementById(`entryDateTo[${i}]`)

          if(
            fromDate && fromDate.value != '' && toDate && toDate.value != '' &&
            dateFormatCheck(fromDate.value) && dateFormatCheck(toDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueFromTo',
            })
          }
          else if(
            fromDate && fromDate.value != '' && dateFormatCheck(fromDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueFrom',
            })
          }
          else if(
            toDate && toDate.value != '' && dateFormatCheck(toDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueTo',
            })
          }
          else{
            if (
              fromDate &&
              toDate &&
              fromDate.value != '' &&
              toDate.value != '' 
            ) {
              if (fromDate.value > toDate.value) {
                errFlg = false
                errorS.push({
                  errorFlag: true,
                  errorInfo: '終了日が開始日よりも前です',
                  errorType: 'valueRange',
                })
              } else {
                entryDateConditionObj.entryDateFrom = fromDate.value
                entryDateConditionObj.entryDateTo = toDate.value
                entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
                errorS.push({
                  errorFlag: false,
                  errorInfo: '',
                  errorType: '',
                })
              }
            } else if (
              fromDate &&
              toDate &&
              fromDate.value != '' &&
              toDate.value == ''
            ) {
              entryDateConditionObj.entryDateFrom = fromDate.value
              entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            } else if (
              fromDate &&
              toDate &&
              fromDate.value == '' &&
              toDate.value != ''
            ) {
              entryDateConditionObj.entryDateTo = toDate.value
              entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            } else {
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            }
          }
        }
        setErrors(errorS)
      }
    }
    if (externalEntryInfoList) {
      if (externalEntryInfoList.length > 0) {
        const errorS: Errors[] = []
        for (var i = 0; i < externalEntryInfoList.length; i++) {
          let entryDateConditionObj: EntryDateConditionObj = {
            entryDateClassificationFlag:
              externalEntryInfoList[i].classificationFlag,
            entryDateClassificationName: '外部エントリー',
            entryDateApplicationRouteId:
              externalEntryInfoList[i].mediaSettingId,
            entryDateApplicationRouteName:
              externalEntryInfoList[i].mediaRouteName,
            entryDatecoordinationFormerEntryBoxId: '',
            entryDateEntryBoxId: '',
            entryDateEntryBoxName: '',
            entryDateFrom: '',
            entryDateTo: '',
          }
          var fromDate: any = document.getElementById(`exEntryDateFrom[${i}]`)
          var toDate: any = document.getElementById(`exEntryDateTo[${i}]`)
          if(
            fromDate && fromDate.value != '' && toDate && toDate.value != '' &&
            dateFormatCheck(fromDate.value) && dateFormatCheck(toDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueFromTo',
            })
          }
          else if(
            fromDate && fromDate.value != '' && dateFormatCheck(fromDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueFrom',
            })
          }
          else if(
            toDate && toDate.value != '' && dateFormatCheck(toDate.value)
          ){
            errFlg = false
            errorS.push({
              errorFlag: true,
              errorInfo: validationMessageSet.date(),
              errorType: 'valueTo',
            })
          }
          else{
            if (
              fromDate &&
              toDate &&
              fromDate.value != '' &&
              toDate.value != ''
            ) {
              if (fromDate.value > toDate.value) {
                errFlg = false
                errorS.push({
                  errorFlag: true,
                  errorInfo: '終了日が開始日よりも前です',
                  errorType: 'valueRange',
                })
              } else {
                entryDateConditionObj.entryDateFrom = fromDate.value
                entryDateConditionObj.entryDateTo = toDate.value
                entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
                errorS.push({
                  errorFlag: false,
                  errorInfo: '',
                  errorType: '',
                })
              }
            } else if (
              fromDate &&
              toDate &&
              fromDate.value != '' &&
              toDate.value == ''
            ) {
              entryDateConditionObj.entryDateFrom = fromDate.value
              entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            } else if (
              fromDate &&
              toDate &&
              fromDate.value == '' &&
              toDate.value != ''
            ) {
              entryDateConditionObj.entryDateTo = toDate.value
              entryDateObj.entryDateConditionObj.push(entryDateConditionObj)
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            } else {
              errorS.push({
                errorFlag: false,
                errorInfo: '',
                errorType: '',
              })
            }
          }
        setErrors2(errorS)
        }
      }
    }
  // phase2 start
    if (errFlg) {
      if(entryDateObj.entryDateConditionObj.length > 0){
        form.setFieldValue('entryDateObj', entryDateObj)
      } else {
        form.setFieldValue('entryDateObj', null)
      }
      handleClose()
      // TODO: formik
    }
  }

  const handleCancel = () => {
    handleClose()
    if (form.values.entryDateObj && form.values.entryDateObj.entryDateConditionObj) {
      form.setFieldValue('entryDateObj.entryBoxInfoList', null)
      form.setFieldValue('entryDateObj.externalEntryInfo', null)
    }
    else {
      form.setFieldValue('entryDateObj', null)
    }
  }
  // phase2 end

  const dateErrorsCheck = (errorS: Errors[],fromDate: any,toDate: any) => {
    if(
      fromDate && fromDate.value != '' && toDate && toDate.value != '' &&
      dateFormatCheck(fromDate.value) && dateFormatCheck(toDate.value)
    ){
      errorS.push({
        errorFlag: true,
        errorInfo: validationMessageSet.date(),
        errorType: 'valueFromTo',
      })
    }
    else if(
      fromDate && fromDate.value != '' && dateFormatCheck(fromDate.value)
    ){
      errorS.push({
        errorFlag: true,
        errorInfo: validationMessageSet.date(),
        errorType: 'valueFrom',
      })
    }
    else if(
      toDate && toDate.value != '' && dateFormatCheck(toDate.value)
    ){
      errorS.push({
        errorFlag: true,
        errorInfo: validationMessageSet.date(),
        errorType: 'valueTo',
      })
    }
    else{
      if (
        fromDate && fromDate.value != '' &&
        toDate && toDate.value != ''
      ) {
        if (fromDate.value > toDate.value) {
          errorS.push({
            errorFlag: true,
            errorInfo: '終了日が開始日よりも前です',
            errorType: 'valueRange',
            })
        } else {
          errorS.push({
            errorFlag: false,
            errorInfo: '',
            errorType: '',
          })
        }
      } else if (
        fromDate && fromDate.value != '' &&
        toDate && toDate.value == ''
      ) {
        errorS.push({
          errorFlag: false,
          errorInfo: '',
          errorType: '',
        })
      } else if (
        fromDate && fromDate.value == '' &&
        toDate && toDate.value != ''
      ) {
        errorS.push({
          errorFlag: false,
          errorInfo: '',
          errorType: '',
        })
      } else {
        errorS.push({
          errorFlag: false,
          errorInfo: '',
          errorType: '',
        })
      }
    }
  }
  const entryDateErrorsCheck = () => {
    if (entryBoxInfoList) {
      if (entryBoxInfoList.length > 0) {
        const errorS: Errors[] = []
        for (var i = 0; i < entryBoxInfoList.length; i++) {
          var fromDate: any = document.getElementById(`entryDateFrom[${i}]`)
          var toDate: any = document.getElementById(`entryDateTo[${i}]`)
          console.log(fromDate.value,toDate.value);
          
          dateErrorsCheck(errorS,fromDate,toDate)
        }
        setErrors(errorS)
      }
    }
  }
  const exEntryDateErrorsCheck = () => {
    if (externalEntryInfoList) {
      if (externalEntryInfoList.length > 0) {
        const errorS: Errors[] = []
        for (var i = 0; i < externalEntryInfoList.length; i++) {
          var fromDate: any = document.getElementById(`exEntryDateFrom[${i}]`)
          var toDate: any = document.getElementById(`exEntryDateTo[${i}]`)
          dateErrorsCheck(errorS,fromDate,toDate)
        }
        setErrors2(errorS)
      }
    }
  }

  return (
    <div>
      <Dialog fullWidth open={open} maxWidth='lg' className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>エントリー日時を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className={`ps-container ps ${classes.root}`}>
            <RadioGroup onChange={handleChangeSearchType} row defaultValue={'0'}>
              {searchOptionList
                ? searchOptionList.map((item, index1) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={searchType === item.value}
                            value={item.value}
                          />
                        }
                        label={item.label}
                      />
                    </>
                  ))
                : null}
            </RadioGroup>
            {entryBoxInfoList && entryBoxInfoList.length > 0 ? (
              <FieldGroup>
                <SubTitle variant='h5' className={classes.subtitle} noUnderline>
                  エントリーボックス
                </SubTitle>
                {entryBoxInfoList.map((entryBoxInfo, index) => (
                  <>
                    <FormField>
                      <Title
                        title={`${entryBoxInfo.contractMediaName} ${entryBoxInfo.coordinationFormerEntryBoxId} ${entryBoxInfo.entryBoxName}`}
                      />
                      <div className={classes.dateRange}> 
                            <div className={errors && errors.length > 0 && errors[index].errorFlag 
                               && (errors[index].errorType == 'valueFromTo' ||errors[index].errorType == 'valueFrom' || errors[index].errorType == 'valueRange')
                                 ? classes.errorBorder : ''}>
                                <FastField
                                  name={`entryDateObj.entryBoxInfoList[${index}].entryDateFrom`}
                                  selectsStart
                                  component={DatePicker}
                                  id={`entryDateFrom[${index}]`}
                                  onBlur={entryDateErrorsCheck}
                                />
                                {errors && errors.length > 0 && errors[index].errorFlag  && (errors[index].errorType == 'valueFrom' ||errors[index].errorType == 'valueFromTo') 
                                  ? ( <p className={classes.helperText}>{errors[index].errorInfo}</p>) : 
                                  (errors && errors.length > 0 && errors[index].errorFlag  && (errors[index].errorType == 'valueTo' ||errors[index].errorType == 'valueRange' )
                                    ?(<p style={{ height: '20px' }}></p>):
                                    null)
                                }
                            </div>
                          
                        <div>～
                          {errors && errors.length > 0 && errors[index].errorFlag ? (
                            <p style={{ height: '20px' }}></p>
                          ) : null}
                        </div>
                        <div className={errors && errors.length > 0 && errors[index].errorFlag
                          && (errors[index].errorType == 'valueFromTo' || errors[index].errorType == 'valueTo' || errors[index].errorType == 'valueRange')
                          ? classes.errorBorder : ''}>
                          <FastField
                            name={`entryDateObj.entryBoxInfoList[${index}].entryDateTo`}
                            selectsEnd
                            component={DatePicker}
                            id={`entryDateTo[${index}]`}
                            onBlur={entryDateErrorsCheck}
                          />
                          {errors && errors.length > 0 && errors[index].errorFlag 
                          && (errors[index].errorType == 'valueFromTo' || errors[index].errorType == 'valueTo' || errors[index].errorType == 'valueRange')
                          ? (
                            <p className={classes.helperText}>{errors[index].errorInfo}</p>
                          ) : (errors && errors.length > 0 && errors[index].errorFlag  &&errors[index].errorType == 'valueFrom' 
                          ? (
                            <p style={{ height: '20px' }}></p>):null
                          )}
                        </div>
                      </div>
                    </FormField>
                    <Divider className={classes.divider} />
                  </>
                ))}
              </FieldGroup>
            ) : null}

            {externalEntryInfoList && externalEntryInfoList.length > 0 ? (
              <FieldGroup>
                <SubTitle variant='h5' className={classes.subtitle} noUnderline>
                  外部取込
                </SubTitle>
                {externalEntryInfoList.map((externalEntryInfo, index) => (
                  <>
                    <FormField>
                      <Title title={externalEntryInfo.mediaRouteName} />
                      <div className={classes.dateRange}>
                        <div className={errors2 && errors2.length > 0 && errors2[index].errorFlag 
                        && (errors2[index].errorType == 'valueFromTo' ||errors2[index].errorType == 'valueFrom' || errors2[index].errorType == 'valueRange')
                        ? classes.errorBorder : ''}>
                          <FastField
                            name={`entryDateObj.externalEntryInfo[${index}].exEntryDateFrom`}
                            selectsStart
                            component={DatePicker}
                            id={`exEntryDateFrom[${index}]`}  
                            onBlur={exEntryDateErrorsCheck}
                          />
                          {errors2 && errors2.length > 0 && errors2[index].errorFlag  && (errors2[index].errorType == 'valueFrom' ||errors2[index].errorType == 'valueFromTo') 
                            ? ( <p className={classes.helperText}>{errors2[index].errorInfo}</p>) : 
                            (errors2 && errors2.length > 0 && errors2[index].errorFlag  && (errors2[index].errorType == 'valueTo' ||errors2[index].errorType == 'valueRange' )
                              ?(<p style={{ height: '20px' }}></p>):
                              null)
                          }
                        </div>
                        <div>～
                          {errors2 && errors2.length > 0 && errors2[index].errorFlag ? (
                            <p style={{ height: '20px' }}></p>
                          ) : null}
                        </div>
                        <div className={errors2 && errors2.length > 0 && errors2[index].errorFlag 
                          && (errors2[index].errorType == 'valueFromTo' || errors2[index].errorType == 'valueTo' || errors2[index].errorType == 'valueRange')
                          ? classes.errorBorder : ''}>
                          <FastField
                            name={`entryDateObj.externalEntryInfo[${index}].exEntryDateTo`}
                            selectsEnd
                            component={DatePicker}
                            id={`exEntryDateTo[${index}]`}
                            onBlur={exEntryDateErrorsCheck}
                          />
                          {errors2 && errors2.length > 0 && errors2[index].errorFlag 
                          && (errors2[index].errorType == 'valueFromTo' || errors2[index].errorType == 'valueTo' || errors2[index].errorType == 'valueRange')
                          ? (
                            <p className={classes.helperText}>{errors2[index].errorInfo}</p>
                          ) : (errors2 && errors2.length > 0 && errors2[index].errorFlag  &&errors2[index].errorType == 'valueFrom' 
                          ? (
                            <p style={{ height: '20px' }}></p>):null
                          )}
                        </div>
                      </div>
                    </FormField>
                    <Divider className={classes.divider} />
                  </>
                ))}
              </FieldGroup>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClick()}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirm} onClose={handleClick(false)}>
        <DialogTitle>{contants.MESSAGECODE_MCAXS531_001}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClick(false)}>キャンセル</Button>
          <Button onClick={handleCancel} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EntryDateModal
