import { call, put, all, takeEvery, select, takeLatest } from 'redux-saga/effects'
import {
  search,
  setSearchResult,
  setInitDisplay,
  updateOnNewData,
  updateDecision,
  updateJudgementResult,
  updateSelectionSelect,
  updateTagFilter,
  updateProgressFilter,
  getMySearch,
  setMySearchResult,
  updateExclude,
  updateUnExclude,
  updateFilterCondition,
  preUpdateFilterCondition,
  revertFilterCondition,
  updateScreenTransitionInput,
  updateSearchRequest,
  updateSortCondition,
  preUpdateSortCondition,
  updatePage,
  updatePageMaxCard,
  updateListDisplayType,
  updateListDisplayTypeRequest,
  setJudgementStatusList,
  setSearchCondition,
  setSelectedSearch,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  updateFilterDisplay,
  clearFilter,
  setNeedUpdate,
  setSpecificTargetFirstDisplayInfo,
  setCircleLoadingFlag,
  setDisplaySearchCriteriaTitleOpenFlag,
  updateMaxCardInfo,
  preUpdateOnSelectAll,
  updateOnSelectAll,
  checkNotAdoptedNotificationPreference,
  checkSelectionFlowandStep,
} from 'reducers/entryListReducer'
import {
  serviceModeUpdateRequest,
  searchRequest,
  updateJudgmentRequest,
  getMySearchRequest,
  updateExcludeRequest,
  updateUnExcludeRequest,
  changeListDisplayTypeRequest,
  PagerMaxCardRequest,
  checkNotAdoptedNotificationPreferenceRequest,
  checkSelectionFlowandStepRequest,
} from 'apis/MCAXS020Api'
import { openModal } from 'reducers/messageReducer'
import {
  SearchCondition,
  SelectionFlowObj,
  SelectionStepObj,
} from 'pages/MCAXS020/searchConditionConfig'
import { 
  initFilterCondition, 
  initSearchResult, 
  FilterCondition, 
  Data, 
  MaxCardInfoResult, 
  MaxCardInfo, 
  LIST_DISPLAY_TYPE_MAX_CARD,
  LIST_DISPLAY_TYPE_SIMPLE_LIST, 
  SearchResult,
  ServiceModeUpdateResult
} from 'pages/MCAXS020/formConfig'
import {
  setOpen as setAdvancedSearchOpen,
} from 'reducers/advancedSearchJobChangeReducer'
import { initeQueryRequest } from 'componentsHsc/AdvancedSearchJobChange/Config/formConfig'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
import { initAreaContactPassRequest } from 'apis/MCAWS010Api'
import { setInitAreaContactPass } from 'reducers/progressStagentAlertReducer'
import { RootState } from 'reducers'
import { deepEqual } from 'utils/misc'
import {
  setMcaxs401Init,
} from 'reducers/advancedSearchJobChangeReducer'
import { getMCAXS411InitRequest } from 'apis/MCAXS411Api'
import { getMCAXS421InitRequest } from 'apis/MCAXS421Api'
import { getMCAXS431InitRequest } from 'apis/MCAXS431Api'
import { ApplicantInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/applicantConfig'
import { BasicInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/basicConfig'
import { RecruitmentManagementInfo } from 'componentsHsc/AdvancedSearchJobChange/Config/recruitmentManagementConfig'
import { fromEntryList, toEntryList } from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { PagerMaxCard } from 'pages/MCAXS020/apiConfig'
import { loginSuccess } from 'reducers/companyLoginReducer'
import { setMarchModeCheckExecuteFlg, setServiceMode, setServiceModeForDisplay } from 'reducers/globalMenuReducer'
import { routeList } from 'routes/routes'

const updateSearchCondition = (
  searchCondition: string,
  mCAXS411Result: ApplicantInfo,
  mCAXS431Result: RecruitmentManagementInfo
) => {
  const sc: SearchCondition = JSON.parse(searchCondition)
  const newSearchCondition = {
    ...sc,
  }
  if (sc.applicationRouteObj) {
    //応募経路情報
    newSearchCondition.applicationRouteObj = sc.applicationRouteObj.map(
      scApplicationRoute => {
        const applicationRoute = mCAXS411Result.applicationRouteList.find(
          resultApplicationRoute =>
            resultApplicationRoute.applicationRouteId ===
            Number(scApplicationRoute.applicationRouteId)
        )
        if (applicationRoute) {
          return {
            applicationRouteFlag: scApplicationRoute.applicationRouteFlag,
            applicationRouteId: scApplicationRoute.applicationRouteId,
            applicationRouteName: applicationRoute.applicationRouteName,
          }
        } else {
          return { ...scApplicationRoute }
        }
      }
    )
  }

  if (sc.selectionFlowObj) {
    //選考フロー
    const selectionFlowObj: SelectionFlowObj[] = []
    sc.selectionFlowObj.forEach(scSelectionFlow => {
      const selectionFlow = mCAXS431Result.selectionFlowList.find(
        resultSelectionFlow =>
          resultSelectionFlow.selectionFlowSettingId ===
          Number(scSelectionFlow.selectionFlowId)
      )
      if (selectionFlow) {
        selectionFlowObj.push({
          selectionFlowId: scSelectionFlow.selectionFlowId,
          selectionFlowName: selectionFlow.selectionName,
        })
      }
    })
    newSearchCondition.selectionFlowObj = selectionFlowObj
  }

  if (sc.selectionStepObj) {
    //選考ステップ
    const selectionStepObj: SelectionStepObj[] = []
    sc.selectionStepObj.forEach(scSelectionStep => {
      const selectionStep = mCAXS431Result.selectionStepList.find(
        resultSelectionStep =>
          resultSelectionStep.progressStatusSettingId ===
          Number(scSelectionStep.selectionStepId)
      )
      if (selectionStep) {
        selectionStepObj.push({
          selectionStepId: scSelectionStep.selectionStepId,
          selectionStepName: selectionStep.progressName,
        })
      }
    })
    newSearchCondition.selectionStepObj = selectionStepObj
  }
  
  if (sc.applicationTypeObj && (sc.applicationTypeObj.applicationTypeName === '')) {
    //応募職種情報
    const scApplicationTypeObj = sc.applicationTypeObj
    const entryJobCategory = mCAXS411Result.entryJobCategoryList.find(
      entryJobCategory =>
        entryJobCategory.coordinationFormerJobCategoryIdentificationKey ===
        scApplicationTypeObj.applicationTypeId
    )
    if (entryJobCategory) {
      newSearchCondition.applicationTypeObj = {
        jobId: entryJobCategory.jobId,
        applicationTypeId: scApplicationTypeObj.applicationTypeId,
        applicationTypeName: entryJobCategory.entryJobCategoryName,
      }
    }
  }

  return newSearchCondition
}

export function* searchSaga(action: ReturnType<typeof search>) {
  try {
    let data = initSearchResult
    if (action.payload.request.searchCount === 1) {
      yield put(setCircleLoadingFlag(true))

      if (
        action.payload.request.sourceFunctionId === 'MCACS010' &&
        action.payload.serviceModeUpdateRequest
      ) {
        const serviceModeUpdateResponse: ServiceModeUpdateResult = yield call(
          serviceModeUpdateRequest,
          action.payload.serviceModeUpdateRequest
        )
        if (serviceModeUpdateResponse.token) {
          const token =
            serviceModeUpdateResponse.tokenHead +
            ' ' +
            serviceModeUpdateResponse.token
          // token再設定
          yield put(loginSuccess({ token: token }))
          // 転職March #9626 START
          yield put(setMarchModeCheckExecuteFlg(false))
          window.location.href = routeList.entryList
          return
          // 転職March #9626 END
        }
      }

      const recruitmentManagementDivision: string = yield select(
        getRecruitmentManagementDivision
      )
      const globalMenu = yield select((state: RootState) => state.globalMenu)

      const result = yield all([
        call(initAreaContactPassRequest, {
          recruitmentManagementDivision: recruitmentManagementDivision,
          lastLogoutTime: globalMenu.lastLogoutTime,
          previousLoginTime: globalMenu.previousLoginTime,
        }),
        call(getMCAXS411InitRequest),
        call(getMCAXS421InitRequest),
        call(getMCAXS431InitRequest, initeQueryRequest),
        call(searchRequest, action.payload.request),
      ])

      data = result[4]
      yield put(setInitAreaContactPass(result[0]))

      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:開始 *
       *---------------------------------------*/
      const mCAXS411Response = result[1]
      const mCAXS421Response = result[2]
      const mCAXS431Result = result[3]

      const mCAXS411Result: ApplicantInfo = {
        memberTypeList: mCAXS411Response.memberTypeList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),

        applicationRouteList: mCAXS411Response.applicationRouteList.map(
          (option: any) => ({
            applicationRouteFlag: option.applicationRouteFlag,
            applicationRouteId: option.applicationRouteId,
            applicationRouteName: option.applicationRouteName,
          })
        ),

        entryJobCategoryList: mCAXS411Response.entryJobCategoryList.map(
          (option: any) => ({
            coordinationFormerJobCategoryIdentificationKey:
              option.coordinationFormerJobCategoryIdentificationKey,
            entryJobCategoryName: option.entryJobCategoryName,
            entryJobCategoryNameRaw: option.entryJobCategoryNameRaw,
            umuFlag: option.umuFlag,
            jobId: option.jobId,
            postingStartTime: option.postingStartTime,
            postingEndTime: option.postingEndTime,
          })
        ),

        entryTypeList: mCAXS411Response.entryTypeList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        entryClassificationList: mCAXS411Response.entryClassificationList.map(
          (option: any) => ({
            value: option.sequence,
            label: option.iconName,
          })
        ),
        scoutBenefitsList: mCAXS411Response.scoutBenefitsList.map(
          (option: any) => ({
            value: option.sequence,
            label: option.iconName,
          })
        ),
      }

      const mCAXS421Result: BasicInfo = {
        sexList: mCAXS421Response.sexList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        spouseList: mCAXS421Response.spouseList.map((option: any) => ({
          value: option.itemCode,
          label: option.itemName,
        })),
        finalEducationList: mCAXS421Response.finalEducationList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        educationBackgroundList: mCAXS421Response.educationBackgroundList,
        languageSkillsList: mCAXS421Response.languageSkillsList,
        eligibilityList: mCAXS421Response.eligibilityList,
        experienceCountList: mCAXS421Response.experienceCountList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        employmentSituationList: mCAXS421Response.employmentSituationList.map(
          (option: any) => ({
            value: option.itemCode,
            label: option.itemName,
          })
        ),
        jobCareerList: mCAXS421Response.jobCareerList,
        experiencedJobList: mCAXS421Response.experiencedJobList,
      }
      //ReduxStoreに保持
      yield put(
        setMcaxs401Init({
          mCAXS411Result,
          mCAXS421Result,
          mCAXS431Result,
        })
      )

      //検索条件詳細情報の更新
      if (data.searchCondition) {
        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          mCAXS411Result,
          mCAXS431Result,
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
        yield put(setSearchCondition(newSearchCondition))
        const { orderObjArray } = yield select(
          (state: RootState) => state.advancedSearchJobChange
        )
        const newSortSearchCondition = toEntryList(fromEntryList(newSearchCondition), orderObjArray)
        yield put(
          setSelectedSearch({
            searchCondition: newSortSearchCondition,
            searchConditionDisplay: data.searchConditionDisplay,
          })
        )

        const payloadMySearch = action.payload.mySearch
        if (payloadMySearch) {
          const mySearch = {
            entrySearchCriteriaSettingId:
              payloadMySearch.entrySearchCriteriaSettingId,
            searchCriteriaName: payloadMySearch.searchCriteriaName,
            searchCriteria: JSON.stringify(newSearchCondition),
            searchCriteriaDisplay: data.searchConditionDisplay,
          }
          yield put(setMySearchResult(mySearch))
        }
      }
      /*---------------------------------------*
       * 検索条件詳細画面の表示情報を設定:終了 *
       *---------------------------------------*/
    } else {
      yield put(setCircleLoadingFlag(false))
      data = yield call(searchRequest, action.payload.request)
      if (data.searchCondition) {
        const { applicantInfo, recruitmentManagementInfo } = yield select(
          (state: RootState) => state.advancedSearchJobChange
        )

        const newSearchCondition = updateSearchCondition(
          data.searchCondition,
          applicantInfo,
          recruitmentManagementInfo
        )
        data.searchCondition = JSON.stringify(newSearchCondition)
      }
    }
    
    const totalCount: number = yield select((state: RootState) =>
      state.entryList.searchResult.totalCount
    )
    data.flowMasterInfo = data.flowMasterInfo ? data.flowMasterInfo : []
    data.tagMasterInfo = data.tagMasterInfo ? data.tagMasterInfo : []
    data.mediaMasterInfo = data.mediaMasterInfo ? data.mediaMasterInfo : []
    data.applicantsListInfo = data.applicantsListInfo
      ? data.applicantsListInfo
      : []
    data.applicantListMediaInfo = data.applicantListMediaInfo
      ? data.applicantListMediaInfo
      : []
    data.applicantListTagsInfo = data.applicantListTagsInfo
      ? data.applicantListTagsInfo
      : []
    data.duplicationInfo = data.duplicationInfo ? data.duplicationInfo : []
    data.entryIcon1Info = data.entryIcon1Info ? data.entryIcon1Info : []
    data.applicationJobCategorySuggestInfo = data.applicationJobCategorySuggestInfo
      ? data.applicationJobCategorySuggestInfo
      : []
    const totalCountFixed = data.totalCount ? data.totalCount : 0
    const { selectionManagementIdList } = action.payload.request
    if (action.payload.request.searchCount === 1) {
      if (
        selectionManagementIdList &&
        selectionManagementIdList.length
      ) {
        data.totalCount = selectionManagementIdList.length
        yield put(setSpecificTargetFirstDisplayInfo(data.displayInfo))
      } else {
        data.totalCount = totalCountFixed
      }
      yield put(setTotalCount(totalCountFixed))
    } else {
      if (selectionManagementIdList && selectionManagementIdList.length) {
        yield put(setTotalCount(totalCountFixed))
      }
      data.totalCount = totalCount
    }

    if (action.payload.request.searchCount === 1) {
      yield put(setNeedUpdate(false))
      yield put(updateFilterDisplay(data))
      
      if(action.payload.isInitFilter){
        const serviceMode: string = yield select((state: RootState) => state.globalMenu.serviceMode)
        yield put(clearFilter({serviceMode: serviceMode}))
      }
      const filterCondition: FilterCondition = yield select(
        (state: RootState) => state.entryList.filterCondition
      )

      // 1回目レスポンス到着、選考フィルターなし、レスポンスを画面表示に反映
      if (
        deepEqual(initFilterCondition, filterCondition) ||
        totalCountFixed === data.applicantsListInfo.length
      ) {
        yield put(setSearchResult(data))
        yield put(setInitDisplay(
          { isInitFilter: action.payload.isInitFilter, searchResult: data }
        ))
        if(data.listDisplayType == LIST_DISPLAY_TYPE_MAX_CARD){
          const applicantsListInfo: Data[] = yield select(
            (state: RootState) => state.entryList.data
          )
          const endIndex = 100 > applicantsListInfo.length ? applicantsListInfo.length : 100
          const selectionManagementInfo: PagerMaxCard[] =
          applicantsListInfo
              .slice(0, endIndex)
              .filter(i => i.specificTargetDataGetFlag === '0')
              .map(i =>{
                return  {
                  selectionManagementId:i.selectionManagementId,
                  sysVersionNumber1:i.sysVersionNumber1,
                }
              })
          yield put(updatePageMaxCard({ 
            selectionManagementInfo: selectionManagementInfo, 
            page: 0,
            searchResult: null,
          }))
        }
      }
    } else {
      const filterCondition: FilterCondition = yield select(
        (state: RootState) => state.entryList.filterCondition
      )

      yield put(setSearchResult(data))
      const needUpdate: boolean = yield select(
        (state: RootState) => state.entryList.needUpdate
      )

      yield put(setHasNewData(true))
      yield put(setSearchCount(1))

      // 2回目レスポンス到着、選考フィルターあり、レスポンスを画面表示に反映
      if (needUpdate || !deepEqual(initFilterCondition, filterCondition)) {
        yield put(updateOnNewData(data))

        const { 
          selectionFlowSelect, 
          progressStatusSelect , 
          tagSelect
        } = filterCondition

        if (selectionFlowSelect.value) {
          yield put(updateSelectionSelect(selectionFlowSelect))
        }

        if (progressStatusSelect.value) {
          yield put(updateProgressFilter(progressStatusSelect))
        }

        if (tagSelect.length) {
          yield put(updateTagFilter(tagSelect))
        }

        if(data.listDisplayType == LIST_DISPLAY_TYPE_MAX_CARD){
          const applicantsListInfo: Data[] = yield select(
            (state: RootState) => state.entryList.data
          )
          const endIndex = 100 > applicantsListInfo.length ? applicantsListInfo.length : 100
          const selectionManagementInfo: PagerMaxCard[] =
          applicantsListInfo
              .slice(0, endIndex)
              .filter(i => i.specificTargetDataGetFlag === '0')
              .map(i =>{
                return  {
                  selectionManagementId:i.selectionManagementId,
                  sysVersionNumber1:i.sysVersionNumber1,
                }
              })
          yield put(updatePageMaxCard({ 
            selectionManagementInfo: selectionManagementInfo, 
            page: 0,
            searchResult: null,
          }))
        }
      }
    }

    if (action.payload.onSearch) {
      action.payload.onSearch()
    }

    /**
     * レスポンスの totalCount とレスポンスの applicantsListInfo の
     * リストの長さが異なる場合
     *
     * ２回目の検索を実施する
     */
    if (
      action.payload.request.searchCount === 1 &&
      totalCountFixed !== data.applicantsListInfo.length
    ) {
      const filterCondition: FilterCondition = yield select((state: RootState) => 
        state.entryList.filterCondition
      )

      // フィルターあり、再検索(501件以上)の場合は2回目のレスポンスが来るまで全体(選考フィルター、件数、一覧表示)を"読み込み中"表示
      if (deepEqual(initFilterCondition, filterCondition)) {
        yield put(setLoadingFlag(false))
      } else {
        yield put(setLoadingFlag(true))
      }

      yield put(setSearchCount(2))
      yield put(
        search({
          request: {
            ...action.payload.request,
            searchCount: 2,
          },
          isInitFilter: action.payload.isInitFilter
        })
      )
    } else {
      yield put(setLoadingFlag(false))
    }
    yield put(setCircleLoadingFlag(false))
  } catch (error) {
    yield put(openModal(error.message))
    yield put(setCircleLoadingFlag(false))
  }
}

export function* updateDecisionSaga(action: ReturnType<typeof updateDecision>) {
  try {
    const data = yield call(updateJudgmentRequest, action.payload)
    yield call(updateJudgementResult, data)

    yield put(updateJudgementResult(data))
    yield put(
      setJudgementStatusList({
        selectionManagementId: action.payload.selectionManagementId,
        judgementStatus: action.payload.decisionDivision,
        show: true,
      })
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* getMySearchSaga(action: ReturnType<typeof getMySearch>) {
  try {
    const data = yield call(getMySearchRequest, action.payload.mySearchRequest)

    const searchCriteria: SearchCondition = JSON.parse(data.searchCriteria)
    yield put(setSearchCondition(searchCriteria))

    //再検索
    yield put(setAdvancedSearchOpen(false))
    yield put(setDisplaySearchCriteriaTitleOpenFlag(false))
    yield put(
      search({
        request: {
          ...action.payload.searchRequest,
          searchCondition: data.searchCriteria,
        },
        isInitFilter: true,
        mySearch: data,
      })
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* updateExcludeSaga(action: ReturnType<typeof updateExclude>) {
  try {
    yield call(updateExcludeRequest, action.payload.excludeRequest)
    //再検索
    yield put(search({ request: action.payload.searchRequest, isInitFilter: false }))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* updateUnExcludeSaga(
  action: ReturnType<typeof updateUnExclude>
) {
  try {
    yield call(updateUnExcludeRequest, action.payload.unExcludeRequest)
    //再検索
    yield put(search({ request: action.payload.searchRequest, isInitFilter: false }))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

/**最大カード形式　追加情報取得 */
export function* updateListDisplayTypeSaga(action: ReturnType<typeof updateListDisplayTypeRequest>) {
  try {
      const data: MaxCardInfo[] = yield call(changeListDisplayTypeRequest, action.payload)
      /**reducer実行用にパラメータ詰める */
      const payload: MaxCardInfoResult = {
        maxCardObj: data,
      }
      yield put(updateListDisplayType({listDisplayType: action.payload.listDisplayType}))
      yield put(updateMaxCardInfo(payload))
      if(action.payload.listDisplayType === LIST_DISPLAY_TYPE_SIMPLE_LIST){
        const searchResult: SearchResult = yield select((state: RootState) => state.entryList.searchResult)
        const serviceMode: string = yield select(
          (state: RootState) => state.globalMenu.serviceMode
        )
        yield put(preUpdateSortCondition({
          order: serviceMode !== '1' ? LIST_DISPLAY_TYPE_SIMPLE_LIST : '4',
          searchResult: searchResult
        }))
      }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

/**ページャ押下　最大カード形式　追加情報取得 */
export function* updatePageMaxCardSaga(action: ReturnType<typeof updatePageMaxCard>) {
  try {
      const data: MaxCardInfo[] = yield call(PagerMaxCardRequest, {selectionManagementInfo: action.payload.selectionManagementInfo})
      /**reducer実行用にパラメータ詰める */
      const payload: MaxCardInfoResult = {
        maxCardObj: data,
      }
 
      if(action.payload.searchResult != null)
      {
        yield put(updatePage({page: action.payload.page, searchResult: action.payload.searchResult}))
      }
      yield put(updateMaxCardInfo(payload))
    
  } catch (error) {
    yield put(openModal(error.message))
  }
}

/**全件選択　最大カード形式　追加情報取得 */
export function* updateOnSelectAllSaga(action: ReturnType<typeof preUpdateOnSelectAll>) {
  const listDisplayType: string = yield select((state: RootState) => state.entryList.listDisplayType)
  const hasNewData: boolean = yield select((state: RootState) => state.entryList.hasNewData)
  const selectionManagementInfo: PagerMaxCard[] = yield select((state: RootState) => state.entryList.inPageData)
  if(listDisplayType == "2" && hasNewData){
    try {
        /**SVに送信して追加情報取得 */
        const data: MaxCardInfo[] = yield call(PagerMaxCardRequest, {selectionManagementInfo: selectionManagementInfo})
        yield put(updateOnSelectAll(action.payload.searchResult))
        /**reducer実行用にパラメータ詰める */
        const payload: MaxCardInfoResult = {
          maxCardObj: data,
        }
        yield put(updateMaxCardInfo(payload))
    } catch (error) {
      action.payload.revertOnSelectAll(false)
      yield put(openModal(error.message))
    }
  }else{
    try {
      yield put(updateOnSelectAll(action.payload.searchResult))
    } catch (error) {
      yield put(openModal(error.message))
    }
  }
}

/**フィルター　最大カード情報処理分岐*/
export function* updateFilterConditionSaga(action: ReturnType<typeof preUpdateFilterCondition>) {
  const listDisplayType: string = yield select((state: RootState) => state.entryList.listDisplayType)
  const selectionManagementInfo: PagerMaxCard[] = yield select((state: RootState) => state.entryList.inPageData)
  if(listDisplayType == "2"){
    try {
        const data: MaxCardInfo[] = yield call(PagerMaxCardRequest, {selectionManagementInfo: selectionManagementInfo})
        const payload: MaxCardInfoResult = {
          maxCardObj: data,
        }
        //フィルターの事前処理
        if(action.payload.pram.newScreenTransitionInput != undefined && action.payload.pram.newSearchRequest != undefined){
          yield put(updateScreenTransitionInput(action.payload.pram.newScreenTransitionInput))
          yield put(updateSearchRequest(action.payload.pram.newSearchRequest))
        }
        //フィルター条件更新
        yield put(updateFilterCondition({filterCondition: action.payload.pram.filterCondition, searchResult: action.payload.pram.searchResult}))
        //フィルターの追加処理
        if(action.payload.pram.tagSelect != undefined)
          yield put(updateTagFilter(action.payload.pram.tagSelect))
        if(action.payload.pram.selectionFlowSelect != undefined)
          yield put(updateSelectionSelect(action.payload.pram.selectionFlowSelect))
        if(action.payload.pram.progressStatusSelect != undefined)
          yield put(updateProgressFilter(action.payload.pram.progressStatusSelect))
        //最大カード設定  
        yield put(updateMaxCardInfo(payload))
      
    } catch (error) {
      yield put(revertFilterCondition())
      yield put(openModal(error.message))
    }
  }else{
    try {
        //フィルターの事前処理
        if(action.payload.pram.newScreenTransitionInput != undefined && action.payload.pram.newSearchRequest != undefined){
          yield put(updateScreenTransitionInput(action.payload.pram.newScreenTransitionInput))
          yield put(updateSearchRequest(action.payload.pram.newSearchRequest))
        }
        //フィルター条件更新
        yield put(updateFilterCondition({filterCondition: action.payload.pram.filterCondition, searchResult: action.payload.pram.searchResult}))
        //フィルターの追加処理
        if(action.payload.pram.tagSelect != undefined)
          yield put(updateTagFilter(action.payload.pram.tagSelect))
        if(action.payload.pram.selectionFlowSelect != undefined)
          yield put(updateSelectionSelect(action.payload.pram.selectionFlowSelect))
        if(action.payload.pram.progressStatusSelect != undefined)
          yield put(updateProgressFilter(action.payload.pram.progressStatusSelect))
    } catch (error) {
      yield put(openModal(error.message))
    }
  }
}

/**ソート　最大カード情報処理分岐 */
export function* updateSortConditionSaga(action: ReturnType<typeof preUpdateSortCondition>) {
  const listDisplayType: string = yield select((state: RootState) => state.entryList.listDisplayType)
  const selectionManagementInfo: PagerMaxCard[] = yield select((state: RootState) => state.entryList.inPageData)
  if(listDisplayType == "2"){
    try {

        /**SVに送信して追加情報取得 */
        const data: MaxCardInfo[] = yield call(PagerMaxCardRequest, {selectionManagementInfo: selectionManagementInfo})
        /**reducer実行用にパラメータ詰める */
        const payload: MaxCardInfoResult = {
          maxCardObj: data,
        }
        yield put(updateSortCondition({order: action.payload.order, searchResult: action.payload.searchResult}))
        yield put(updateMaxCardInfo(payload))
      
    } catch (error) {
      yield put(openModal(error.message))
    }
  }else{
    try {
      yield put(updateSortCondition({order: action.payload.order, searchResult: action.payload.searchResult}))
    } catch (error) {
      yield put(openModal(error.message))
    }
  }
}

//不合格ボタン押下時
function* checkNotAdoptedNotificationPreferenceSaga(action: ReturnType<typeof checkNotAdoptedNotificationPreference>) {
  try {
    const serviceMode: string = yield select(
      (state: RootState) => state.globalMenu.serviceMode
    )
    const marchResponsibleCompanyFlag: string = yield select(
      (state: RootState) => state.globalMenu.marchResponsibleCompanyFlag
    )
    if (serviceMode !== '1' && marchResponsibleCompanyFlag !== '1') {
      // March担当企業フラグが有効でないかつMarchモードフラグが有効でない場合
      const data: string = yield call(
        checkNotAdoptedNotificationPreferenceRequest
      )

      //不採用ステータス変更有効の場合
      if (data) {
        action.payload.setModalMessage(data)
        action.payload.setOpenMessageModal(true)
      } else {
        //不採用ステータス変更無効の場合
        action.payload.handleChangeStatus(
          '5',
          action.payload.selectionManagementId
        )
      }
    } else {
      // March担当企業フラグとMarchモードフラグのいずれかが有効の場合
      action.payload.handleChangeStatus(
        '5',
        action.payload.selectionManagementId
      )
    }
  } catch (e) {
    const error = e as any
    yield put(openModal(error.message))
  }
}

/**合否・選考ステップを一括変更ボタン押下 */
export function* checkSelectionFlowandStepSaga(
  action: ReturnType<typeof checkSelectionFlowandStep>
) {
  try {
    const data: boolean = yield call(checkSelectionFlowandStepRequest, {
      selectionManagementIdArray: action.payload.selectionManagementIdArray,
    })
      action.payload.checkSelection(
      action.payload.targetData,
      action.payload.info,
      data
    )
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* entryListSaga() {
  yield all([
    takeLatest(search, searchSaga),
    takeEvery(updateDecision, updateDecisionSaga),
    takeEvery(getMySearch, getMySearchSaga),
    takeEvery(updateExclude, updateExcludeSaga),
    takeEvery(updateUnExclude, updateUnExcludeSaga),
    takeEvery(updateListDisplayTypeRequest, updateListDisplayTypeSaga),
    takeEvery(updatePageMaxCard, updatePageMaxCardSaga),
    takeEvery(preUpdateFilterCondition, updateFilterConditionSaga),
    takeEvery(preUpdateSortCondition, updateSortConditionSaga),
    takeEvery(preUpdateOnSelectAll, updateOnSelectAllSaga),
    takeLatest(checkNotAdoptedNotificationPreference, checkNotAdoptedNotificationPreferenceSaga),
    takeLatest(checkSelectionFlowandStep, checkSelectionFlowandStepSaga),
  ])
}
