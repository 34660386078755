import request from 'utils/request'
import { MCAXS170InsertNameRequest } from 'types/MCAXS170/MCAXS170InsertNameRequest'
import { MCAXS170AddListJobchangeRequest } from 'types/MCAXS170/MCAXS170AddListJobchangeRequest'
import { MCAXS170InitRequest } from 'types/MCAXS170/MCAXS170InitRequest'

export const initRequest = (params: MCAXS170InitRequest) =>{
  return request({
    url: '/MCAXS170/init',
    method: 'post',
    data: params,
  })
}
export const insertRequest = (params: MCAXS170InsertNameRequest) =>
  request({
    url: '/MCAXS170/set',
    method: 'post',
    data: params,
  })

  export const insertJobRequest = (params: MCAXS170AddListJobchangeRequest) =>
  request({
    url: '/MCAXS170/set',
    method: 'post',
    data: params,
  })
