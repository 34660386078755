import request from 'utils/request'

export const mcabs010InitRequest = () =>
  request({
    url: '/MCABS010/init',
    method: 'post',
  })
// MCBリプレース MCABS010 START
export const checkMcbServiceStatusRequest = (title:String) =>
  request({
    url: `/MCABS010/checkMcbServiceStatus/${title}`,
    method: 'post',
  })
// MCBリプレース MCABS010 END 