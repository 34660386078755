import React, {useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { magiContants } from 'utils/contants'
import customSelectOptions from 'utils/customSelectOptions'
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import Select, {components as componentsML, MenuListComponentProps } from 'react-select'


export interface OptionType {
  label: string
  value: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlSize: {
      width: '95%',
      marginTop:'0px',
    },
  })
)

function MenuList (props: MenuListComponentProps<any>) {
  return (
    <componentsML.MenuList {...props}>
      <div id='menuListScrollDiv' style={{display:'inline-block',minWidth:'100%'}}>
        {props.children}
      </div>
    </componentsML.MenuList>
  )
}

const components = {
  MenuList,
}

interface Props extends FieldProps {
  optionList: OptionType[];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  defaultValue?: boolean;
  menuPosition?: any;
}

export default ({ className, optionList, placeholder, field, form, disabled, onChange, defaultValue, menuPosition }: Props) => {
  const classes = useStyles()
  const { name, value } = field
  const optionZero = (placeholder ? { label: placeholder, value: ''} : { label: '（以下から選択）', value: ''}) as OptionType
  const [menuValue, setMenuValue] = useState(optionZero)
  const selectStyles = {
    control: (provided: any) => ({ 
      ...provided, 
      minHeight: '30px',
      cursor:'pointer',
    }),
    input: (provided: any) => ({ 
      ...provided, 
      margin: '0px',
      paddingBottom: '0px',
      paddingTop: '0px',
      '& input': {
        height: '20px',
        lineHeight: '18px',
      },
    }),
    indicatorsContainer: (provided: any) => ({ 
      ...provided, 
      height: '30px',
    }),
    option: (provided: any) => ({ 
      ...provided, 
      whiteSpace: 'nowrap',
      lineHeight: '10px', 
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1400 })
  }

  useEffect(() => {
    if(defaultValue !== undefined && defaultValue) {
      form.setFieldValue(name, "");
    }
  }, [defaultValue])

  useEffect(() => {
    const menuValueList = optionList.filter(i => i.value === value)
    if (menuValueList.length > 0) {
      setMenuValue(menuValueList[0])
    } else {
      setMenuValue(optionZero)
    }
  }, [value])

  const handleChange = (e: any) => {
    if (e != null) {
      if(e.value !== value) {
        if (onChange !== undefined) {
          onChange({target: {value: e.value}})
          }
          form.setFieldValue(name, e.value);
      }
    } else {
      if (onChange !== undefined) {
        onChange({target: {value: '-1'}})
        }
        form.setFieldValue(name, '-1');
    }
  };

  const handleCloseMenuOnScroll = (e: any) => {
    // if(e.target.className.indexOf('MenuList') != -1) {
    if(e.target.childElementCount !== undefined 
        && e.target.childElementCount > 0 
        && e.target.firstElementChild.id === 'menuListScrollDiv') {
        return false
    } else {
        return true
    }
}

  return (
    <div className={className}>
        <Select 
          classes={classes}
          styles={selectStyles}
          placeholder={placeholder}
          options={optionList}
          components={components}
          isClearable={false}
          value={menuValue}
          onChange={handleChange}
          isSearchable={true}
          filterOption={customSelectOptions.filterOption}
          noOptionsMessage={({ inputValue }) => {
            return magiContants.SUGGEST_NO_OPTIONS_MESSAGE
          }}
          menuPlacement={'auto'}
          isDisabled={disabled}
          menuPortalTarget={menuPosition ? document.body : null}
          menuPosition={menuPosition ? menuPosition : 'absolute'}
          closeMenuOnScroll={menuPosition ? handleCloseMenuOnScroll : false}
        />
      </div>
  );
};
