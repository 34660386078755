
import request from 'utils/request'

import { MCBIS020InitRequest } from 'types/MCBIS020/MCBIS020InitRequest'


export const selectCriteriaRequest = (apiData: MCBIS020InitRequest) =>
  request({
    url: '/MCBIS020/init',
    method: 'post',
    data: apiData,
})
