import { all, call, put, takeLatest,select } from 'redux-saga/effects'

import {
  login,
  loginSuccess,
  signout,
  signoutSuccess,
  getUserInfo,
  updatePassword,
  temUpdatePassword,
  initDate,
  setInitHiddenInfo,
  agreeInit,
  setSysVersionNumber,
  agreeUpdate,
  setUserInfo,
} from 'reducers/userReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  loginRequest,
  logoutRequest,
  getUserInfoRequest,
} from 'apis/userApi'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import {
  updatePasswordRequest,
  temUpdatePasswordRequest,
  getInit,
} from 'apis/MCACS020Api'
import {
  agreeInitRequset,
  agreeUpdateRequest,
} from 'apis/MCACS030Api'

import { magiContants } from 'utils/contants'
import {getCompanyAccountId, getUrl} from 'selectors'
import {replaceToOriginUrl} from "../utils/misc";

function* loginSaga(action: ReturnType<typeof login>) {
  const { loginId, password } = action.payload

  try {
    const data = yield call(loginRequest, loginId, password)
    const token = data.tokenHead + ' ' + data.token
    yield put(loginSuccess({ token }))
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}
function* signoutSaga() {
  try {
    yield call(logoutRequest)
    yield put(signoutSuccess())
  } catch (error) {
    yield put(openSnackbar(error.message))
  }
}
function* infoSaga() {
  try {
    const data = yield call(getUserInfoRequest)
    yield put(setUserInfo(data))
  } catch (error) {
    yield put(openSnackbar(error))
  }
}
function* initSaga(action: ReturnType<typeof initDate>) {
  try {
    const data = yield call(getInit,action.payload.companyAccountId)
    yield put(setInitHiddenInfo(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* updatePasswordSaga(action: ReturnType<typeof updatePassword>) {
  try {
    yield call(updatePasswordRequest, action.payload)
    history.push(routeList.commonSettingsMenu)
    yield put(openSnackbar(magiContants.MESSAGECODE_MCACS020_UPDATE_SUCCESS))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* temUpdatePasswordSaga(action: ReturnType<typeof temUpdatePassword>) {
  try {
    const data = yield call(temUpdatePasswordRequest, action.payload)
    const url: ReturnType<typeof getUrl> = yield select(getUrl)
    const jumpFlag  = data.jumpFlag;
    // 利用者同意日時NULLの場合
    if(jumpFlag===0){
      const companyAccountId: ReturnType<typeof getCompanyAccountId> = yield select(getCompanyAccountId)
      const params = { companyAccountId: companyAccountId,url:url};
      const path = {
        pathname: routeList.checkStatute,
        state: params,
      }
      history.push(path)
      yield put(openSnackbar(magiContants.MESSAGECODE_MCACS020_UPDATE_SUCCESS))
    } else if(jumpFlag===1){
      // 準備中
      history.push(routeList.readying)
      yield put(openSnackbar(magiContants.MESSAGECODE_MCACS020_UPDATE_SUCCESS))
    } else{
      const token = data.tokenHead + ' ' + data.token
      yield put(loginSuccess({ token }))
      replaceToOriginUrl(url);
      yield put(openSnackbar(magiContants.MESSAGECODE_MCACS020_UPDATE_SUCCESS))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* agreeInitSaga(action: ReturnType<typeof agreeInit>) {
  try {
    const data = yield call(agreeInitRequset,action.payload.companyAccountId)
    yield put(setInitHiddenInfo(data));
    yield put(setSysVersionNumber(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* agreeUpdateSaga(action: ReturnType<typeof agreeUpdate>) {
  try {
    const data = yield call(agreeUpdateRequest,action.payload)

    const jumpFlag  = data.jumpFlag;
    if(jumpFlag===1){
      // 準備中
      history.push(routeList.readying)
    } else{
      const url: ReturnType<typeof getUrl> = yield select(getUrl)

      const token = data.tokenHead + ' ' + data.token
      yield put(loginSuccess({ token }))
      replaceToOriginUrl(url);
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
export default function* userSaga() {
  yield all([
    takeLatest(login, loginSaga),
    takeLatest(signout, signoutSaga),
    takeLatest(getUserInfo, infoSaga),
    takeLatest(updatePassword, updatePasswordSaga),
    takeLatest(temUpdatePassword, temUpdatePasswordSaga),
    takeLatest(initDate, initSaga),
    takeLatest(agreeInit, agreeInitSaga),
    takeLatest(agreeUpdate, agreeUpdateSaga),
  ])
}
