import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from 'components'
import { FieldGroup, FormField, Title, SubTitle } from 'componentsHsc'
import { FastField, Field } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { FormControl } from '.'
import Button from './Button'
import GroupOptions from './GroupOptions'
import { SelectOptions } from './Select'
import { QuestionnaireCondition, QuestionnaireObj, retainAll } from './Config/searchConditionConfig'
import { initeRequest } from './Config/formConfig'
import {
  getMcaxs561InitJobChange,
  setEntryReplyModalStateJobChange,
} from 'reducers/itemSpecificationReducer'
import { setEntryReplyModalOpen } from 'reducers/advancedSearchJobChangeReducer'
import { setSearchCondition } from 'reducers/entryListReducer'
import { toEntryList } from './Config/searchConditionConfig'
import { stringToDate, dateToString } from 'common/generalUtil'
import { getMessage } from 'common/messageUtil'
import moment from 'moment'
import { magiContants } from 'utils/contants'

const useStyles = makeStyles(theme => ({
  dateRange: {
    '& input': {
      padding: '0 8px',
    },
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  notitle: {
    paddingLeft: 10,
    marginRight: 80,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    maxWidth: '295px',
  },
  datePickerDiv: {
    width: '130px',
    float: 'left',
    '& .MuiIconButton-label': {
      width: '23px',
    },
    '& .btn.only-icon.icon-input_calender': {
      margin: '0 10px 0 0',
    },
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: '13px 12px 0px 20px',
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '130px',
    top: '6px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '130px',
    marginTop: '6px',
  },
  cursor: {
    '& div' : {
      cursor: 'pointer!important',
    }
  },
}))

interface Props {
  form: FormControl
}

export const unreadOptionList = [
  { value: '0', label: '履歴書が未読のエントリーのみ対象とする' },
]

/**
 * アンケート回答内容条件の編集
 */
const generateLabel = (item: QuestionnaireCondition) => {
  const question = item.questionTitle
  let answer: string = ''
  if (item.answerConditionFlag === '1') {
    //回答有無FLGが[1]回答なしの場合
    answer = '回答なし'
  } else {
    //回答有無FLGが[0]回答ありの場合
    if (item.questionTypeCode === '1') {
      //質問種別コードが[1]自由記述の場合
      if (item.answerText === '') {
        //回答テキストがない場合
        answer = '回答があるすべて'
      } else {
        //回答テキストがある場合
        answer = `回答に「${item.answerText}」が含まれる`
      }
    } else {
      //質問種別コードが[1]自由記述　以外の場合
      if (item.answerCheckBoxObj.length === 0) {
        //回答テキストがない場合
        answer = '回答があるすべて'
      } else {
        //回答テキストがある場合
        const answerLabelList = item.answerCheckBoxObj.map(i => i.label)
        answer = answerLabelList.join('、')
      }
    }
  }
  return `Q.${question} A.${answer}`
}

const exemptionOptionList = [
  { value: '0', label: '書類選考免除のエントリーのみ対象とする' },
]
const duplicationOptionList = [
  { value: '0', label: '重複したエントリーのみ対象とする' },
]
const multipleOptionList = [
  { value: '0', label: '複数選考中のエントリーのみ対象とする' },
]
const setQuestionTypeName = (questionTypeCode: string) => {
  let questionTypeName: string = ''
  switch (questionTypeCode) {
    case '0':
      questionTypeName = 'なし'
      break
    case '1':
      questionTypeName = '自由記述'
      break
    case '2':
      questionTypeName = '単一選択'
      break
    case '3':
      questionTypeName = '複数選択'
      break
    default:
      break
  }
  return questionTypeName
}

// 応募情報
const Applicant = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { conditionList = [], typeName = '' } =
    form.values.questionnaireObj || {}

  // 初期表示データ設定
  const {
    memberTypeList,
    applicationRouteList,
    entryClassificationList,
    entryTypeList,
    entryJobCategoryList,
    scoutBenefitsList,
  } = useSelector(
    (state: RootState) => state.advancedSearchJobChange.applicantInfo
  )

  // 転職March start
  const {
    serviceMode,
    marchServiceFlag,
  } = useSelector((state: RootState) => state.globalMenu)
  // 転職March end

  const applicationRouteOptionList = applicationRouteList.map(i => {
    return {
      applicationRouteFlag: i.applicationRouteFlag,
      value: i.applicationRouteId,
      label: i.applicationRouteName,
    }
  })

  const postingStartTime = form.values.postingStartTime ? form.values.postingStartTime : null
  const postingEndTime = form.values.postingEndTime ? form.values.postingEndTime : null
  const filterEntryJobCategoryList = () => {
    let entryJobCategoryOptionList = entryJobCategoryList
    if (null != stringToDate(postingStartTime)) {
      const startMilliseconds = moment(postingStartTime).toDate().getTime()
      entryJobCategoryOptionList = entryJobCategoryOptionList.filter(i =>
        !(moment(dateToString(i.postingStartTime)).toDate().getTime() < startMilliseconds
          && moment(dateToString(i.postingEndTime)).toDate().getTime() < startMilliseconds)
      )
    }
    if (null != stringToDate(postingEndTime)) {
      const endMilliseconds = moment(postingEndTime).toDate().getTime()
      entryJobCategoryOptionList = entryJobCategoryOptionList.filter(i =>
        !(moment(dateToString(i.postingStartTime)).toDate().getTime() > endMilliseconds
          && endMilliseconds < moment(dateToString(i.postingEndTime)).toDate().getTime())
      )
    }
    return entryJobCategoryOptionList.map(i => {
      return {
      	value: i.jobId,
      	value2: i.coordinationFormerJobCategoryIdentificationKey,
        label: i.entryJobCategoryName,
      }
    })
  }


  const disabledCheck = (form: FormControl) => {
    if (form.values.applicationTypeObj === null) {
      return true
    } else {
      const applicationTypeObj = form.values.applicationTypeObj

      let selectedApplicationTypeInfo = null
      if (applicationTypeObj.value) {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i => i.jobId === applicationTypeObj.value
        )
      } else {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i =>
            i.coordinationFormerJobCategoryIdentificationKey ===
              applicationTypeObj.value2 &&
            i.entryJobCategoryNameRaw === applicationTypeObj.label
        )
      }

      if (selectedApplicationTypeInfo) {
        return selectedApplicationTypeInfo.umuFlag !== 1
      } else {
        return true
      }
    }
  }

  useEffect(() => {
    dispatch(
      getMcaxs561InitJobChange({
        request: {
          ...initeRequest,
          coordinationFormerJobCategoryIdentificationKey:
            form.values.applicationTypeObj !== null
              ? form.values.applicationTypeObj.value2
              : null,
        },
        isInitSelectedQuestionnaireObj: true,
        toggle: () => {},
      })
    )
  }, [])

  const entryReplyModalState = useSelector(
    (state: RootState) => state.itemSpecification.entryReplyModalStateJobChange
  )

  // #49324 start
  const questionaire = useSelector((state: RootState) => state.itemSpecification.questionaire)
  const questionaireInit = (questionnaireObj: QuestionnaireObj) => {
    if (questionnaireObj && questionaire && questionaire.length > 0) {
      const newCondList: QuestionnaireCondition[] = []
      // 「エントリー・企業質問情報」と「呼び出すのMY検索データ」をループする、判定条件に基づいて「設問」に「選択肢」と「テキスト」の表示内容を決定します。
      questionaire.forEach(item => {
        const filterResult = questionnaireObj.conditionList.filter(obj =>
          // 「選択肢」と「テキスト」を入力しないの場合、同じ「設問」のデータを抽出する
          obj.answerCheckBoxObj.length === 0 && '' === obj.answerText ? obj.questionTitle === item.title
            && (null !== obj.questionOption ?
              (obj.questionOption === item.questionOption || ('' === obj.questionOption && null === item.questionOption)) && obj.questionTypeCode === item.type
              : true) :
            (
              // 設問形式が「自由記述」の場合、同じ「設問形式」のデータを抽出する。それ以外の場合、「同じ選択肢を存在する」のデータを抽出する
              ('1' === item.type ? obj.questionTypeCode === item.type : retainAll(item.optionList, obj.answerCheckBoxObj).length > 0)
              // 「設問」が同じのデータを抽出する
              && obj.questionTitle === item.title
              // 保存したのMY検索条件で「選択肢の文字列（questionOption）」存在の場合、同じ「選択肢の文字列」のデータを抽出する
              && (obj.questionOption && item.questionOption ? obj.questionOption === item.questionOption && obj.questionTypeCode === item.type : true)
            )
        )
        const equalsQuestionTitle = questionnaireObj.conditionList.filter(obj => obj.questionTitle === item.title)
        const findResult = filterResult.find(filter => equalsQuestionTitle.length > 1 ? filter.questionTypeCode === item.type : true)
        if (findResult) {
          if (findResult.answerCheckBoxObj && item.optionList) {
            // 「選択肢」存在するの場合
            const filterCheckBoxResult = retainAll(findResult.answerCheckBoxObj, item.optionList)
            // filterCheckBoxResult：「MY検索条件で保存の選択肢の数」と「エントリーデータの選択肢の数」が異なる場合、「選択肢」の交集を取得し、エントリーデータにある選択肢のみがチェックする
            if (filterCheckBoxResult && filterCheckBoxResult.length > 0) {
              // 「選択肢」の交集存在する
              newCondList.push({
                ...findResult,
                answerCheckBoxObj: filterCheckBoxResult,　//　「選択肢」の交集を「回答選択肢」に設定する
                questionNumber: item.id ? item.id : findResult.questionNumber,　//　「設問番号」を設定する
                questionOption: item.questionOption ? item.questionOption : '',　//　「選択肢の文字列」を設定する
                questionTypeCode: item.type ? item.type : findResult.questionTypeCode,　//　「質問種別コード」のを設定する
                questionTypeName: item.type ? setQuestionTypeName(item.type) : setQuestionTypeName(findResult.questionTypeCode),　//　「質問種別名」を設定する
              })
            } else {
              // 「選択肢」の交集存在しない
              newCondList.push({
                ...findResult,
                questionNumber: item.id ? item.id : findResult.questionNumber,
                questionOption: item.questionOption ? item.questionOption : '',
                questionTypeCode: item.type ? item.type : findResult.questionTypeCode,
                questionTypeName: item.type ? setQuestionTypeName(item.type) : setQuestionTypeName(findResult.questionTypeCode),
              })
            }
          } else {
            // 設問形式が「自由記述」の場合
            newCondList.push({
              ...findResult,
              questionNumber: item.id ? item.id : findResult.questionNumber,
              questionOption: item.questionOption ? item.questionOption : '',
              questionTypeCode: item.type ? item.type : findResult.questionTypeCode,
              questionTypeName: item.type ? setQuestionTypeName(item.type) : setQuestionTypeName(findResult.questionTypeCode),
            })
          }
        }
      })
      const newQuestionnaireObj: QuestionnaireObj = {
        ...questionnaireObj,
        conditionList: newCondList
      }
      return newQuestionnaireObj
    } else {
      return questionnaireObj
    }
  }
  // #49324 end

  const handleEntryReplyClick = () => {
    const request = { ...initeRequest }

    if (form.values.applicationTypeObj) {
      const applicationTypeObj = form.values.applicationTypeObj
      let selectedApplicationTypeInfo = null
      if (applicationTypeObj.value) {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i => i.jobId === applicationTypeObj.value
        )
      } else {
        selectedApplicationTypeInfo = entryJobCategoryList.find(
          i =>
            i.coordinationFormerJobCategoryIdentificationKey ===
              applicationTypeObj.value2 &&
            i.entryJobCategoryNameRaw === applicationTypeObj.label
        )
      }
      if (selectedApplicationTypeInfo) {
        request.coordinationFormerJobCategoryIdentificationKey =
          selectedApplicationTypeInfo.coordinationFormerJobCategoryIdentificationKey
      }
    }

    let isInitSelectedQuestionnaireObj = true

    if (
      entryReplyModalState.conditionList &&
      entryReplyModalState.conditionList.length > 0
    ) {
      if (form.values.questionnaireObj) {
        isInitSelectedQuestionnaireObj = false
        const newEntryReplyModalState = { ...entryReplyModalState }
        const questionnaireObj = questionaireInit(form.values.questionnaireObj)
        newEntryReplyModalState.typeCode = questionnaireObj.typeCode
        newEntryReplyModalState.typeName = questionnaireObj.typeName
        const newCond: QuestionnaireCondition[] = []
        if (newEntryReplyModalState.conditionList) {
          newEntryReplyModalState.conditionList.forEach(i => {
            const findResult = questionnaireObj.conditionList.find(
              itemObjCondition =>
                itemObjCondition.questionNumber === i.questionNumber
                && itemObjCondition.questionOption === i.questionOption
                && itemObjCondition.questionTitle === i.questionTitle
                && itemObjCondition.questionTypeCode === i.questionTypeCode
            )
            if (findResult) {
              newCond.push(findResult)
            } else {
              newCond.push({
                ...i,
                answerConditionFlag: '',
                answerText: '',
                answerCheckBoxObj: [],
              })
            }
          })
        }

        newEntryReplyModalState.conditionList = newCond
        dispatch(setEntryReplyModalStateJobChange(newEntryReplyModalState))
      }
    }
    dispatch(
      getMcaxs561InitJobChange({
        request: request,
        isInitSelectedQuestionnaireObj: isInitSelectedQuestionnaireObj,
        toggle: () => {
          dispatch(setSearchCondition(toEntryList(form.values)))
          dispatch(setEntryReplyModalOpen(true))
        },
      })
    )
  }

  const handleChipDelete = (
    conditionList: QuestionnaireCondition[],
    index: number
  ) => {
    const newConditionList = conditionList.slice()
    newConditionList.splice(index, 1)
    if (newConditionList.length > 0) {
      form.setFieldValue('questionnaireObj.conditionList', newConditionList)
    } else {
      form.setFieldValue('questionnaireObj', null)
    }
  }

  return (
    <>
      <FieldGroup>
        <SubTitle variant='h3'>応募情報</SubTitle>
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='応募日' className={classes.title} />
          <div className={classes.dateRange}>
            <div className={classes.datePickerDiv}>
              <FastField
                name='applicationFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <FastField
                name='applicationTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
              />
            </div>
          </div>
        </FormField>
        }
        {/* 転職March end */}
        <FormField>
          <Title title='会員種別' className={classes.title} />
          <FastField
            name='memberTypeObj'
            row
            component={GroupOptions}
            optionList={memberTypeList}
          />
        </FormField>
        <FormField>
          <Title title='応募経路' className={classes.title} />
          <FastField
            name='applicationRouteObj'
            row
            component={GroupOptions}
            optionList={applicationRouteOptionList}
          />
        </FormField>
      </FieldGroup>
      {/* 転職March #90253 START */}
      {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
      ? ''
      :
      <FieldGroup>
        <SubTitle variant='h3'>応募職種</SubTitle>
        <FormField helperText={getMessage('MCAXS411_001')}>
          <Title title='応募職種' className={classes.title} />
          <div>
            <div style={{ height: '44px' }}>
              <span style={{ float: 'left', margin: '12px 10px 0 0px' }}>掲載期間を指定する</span>
              <div className={classes.dateRange} style={{ float: 'left' }}>
                <div className={classes.datePickerDiv}>
                  <FastField
                    name='postingStartTime'
                    selectsStart
                    component={DatePicker}
                    placeholder='YYYY/MM/DD'
                  />
                </div>
                <div className={classes.wavyLineStyle}>~</div>
                <div className={classes.datePickerDiv}>
                  <FastField
                    name='postingEndTime'
                    selectsEnd
                    component={DatePicker}
                    placeholder='YYYY/MM/DD'
                  />
                </div>
              </div>
            </div>
            <div style={{ float: 'left', width: '100%' }}>
              <Field
                name='applicationTypeObj'
                optionList={filterEntryJobCategoryList()}
                defaultValue={form.values.applicationTypeObj}
                placeholder='応募職種を選択'
                component={SelectOptions}
                className={classes.cursor}
                isClearable
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <Title title='企業から応募者への質問' className={classes.title} />
          <div className={classes.modal} style={{ position: 'relative' }}>
            <Button
              onClick={handleEntryReplyClick}
              disabled={disabledCheck(form)}
            />
            <div
              className={
                window.navigator.userAgent
                  .toLocaleLowerCase()
                  .match(/(trident)\/([\d.]+)/)
                  ? classes.andOrStyleIE
                  : classes.andOrStyleOther
              }>
              {typeName}
            </div>
            {conditionList.length > 0 && (
              <span>
                {conditionList.map((item, index) => (
                  <Chip
                    className={classes.chip}
                    key={index}
                    label={generateLabel(item)}
                    title={generateLabel(item)}
                    onDelete={() => handleChipDelete(conditionList, index)}
                  />
                ))}
              </span>
            )}
          </div>
        </FormField>
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='応募種別' className={classes.title} />
          <FastField
            name='entryTypeObj'
            row
            component={GroupOptions}
            optionList={entryTypeList}
          />
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='応募区分' className={classes.title} />
          <FastField
            name='entryClassificationObj'
            row
            component={GroupOptions}
            optionList={entryClassificationList}
          />
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='スカウト特典' className={classes.title} />
          <FastField
            name='scoutBenefitsObj'
            row
            component={GroupOptions}
            optionList={scoutBenefitsList}
          />
        </FormField>
        }
        {/* 転職March end */}
        {/* 転職March start */}
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='書類選考免除' className={classes.title} />
          <FastField
            name='exemptionObj'
            row
            component={GroupOptions}
            optionList={exemptionOptionList}
          />
        </FormField>
        }
        {/* 転職March end */}
      </FieldGroup>
      }
      {/* 転職March #90253 END */}
      <FieldGroup>
        <SubTitle variant='h3'>その他</SubTitle>
        <FormField>
          <Title title='履歴書未読' className={classes.title} />
          <FastField
            name='unreadObj'
            row
            component={GroupOptions}
            optionList={unreadOptionList}
          />
        </FormField>
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='重複' className={classes.title} />
          <FastField
            name='duplicationObj'
            row
            component={GroupOptions}
            optionList={duplicationOptionList}
          />
        </FormField>
        }
        {serviceMode === magiContants.STRING_1 && marchServiceFlag === magiContants.STRING_1
        ? ''
        :
        <FormField>
          <Title title='複数選考' className={classes.title} />
          <FastField
            name='multipleObj'
            row
            component={GroupOptions}
            optionList={multipleOptionList}
          />
        </FormField>
        }
      </FieldGroup>
    </>
  )
}

export default Applicant
