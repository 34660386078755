import React, { useState } from 'react'
import CustomScrollbar from 'react-custom-scrollbars'

interface Props {
  show?: boolean
  onClose?: () => void
}

const OtherOperation = ({ show = false, onClose }: Props) => {
  const [state, setState] = useState('change-progress')

  const handleClick = (id: string) => () => {
    setState(id)
  }

  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <aside
        className={`status-history ${
          state === 'status-history' ? 'show' : ''
        }`}>
        <header>
          <h1>選考ステップの履歴</h1>
          <p>過去の選考ステップの変更履歴が確認できます。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>履歴</h2>
            <div>
              <div>
                <time>2019/03/11 13:43</time>
                <b>人事課長　大森</b>
                <span>営業選考</span>
              </div>
              <div className='progress-status'>
                <input type='radio' name='status-history-02' />
                <label>不採用</label>
                <input type='radio' name='statuss-history-02' />
                <label>辞退</label>
                <hr />
                <input type='radio' name='progress-history-02' />
                <label className='current'>応募</label>
                <input type='radio' name='progress-history-02' />
                <label>
                  書類
                  <br />
                  選考
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  一次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-02' />
                <label>内定</label>
                <input type='radio' name='progress-history-02' />
                <label>確約</label>
                <input type='radio' name='progress-history-02' />
                <label>入社</label>
              </div>
              <b className='changed-here'>対象外</b>
            </div>
            <div>
              <div>
                <time>2019/03/10 10:10</time>
                <b>人事課長　大森</b>
                <span className='changed-here'>営業選考</span>
              </div>
              <div className='progress-status'>
                <input type='radio' name='status-history-03' />
                <label>不採用</label>
                <input type='radio' name='statuss-history-03' />
                <label>辞退</label>
                <hr />
                <input type='radio' name='progress-history-03' />
                <label className='current'>応募</label>
                <input type='radio' name='progress-history-03' />
                <label>
                  書類
                  <br />
                  選考
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  一次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-03' />
                <label>内定</label>
                <input type='radio' name='progress-history-03' />
                <label>確約</label>
                <input type='radio' name='progress-history-03' />
                <label>入社</label>
              </div>
            </div>
            <div>
              <div>
                <time>2019/03/05 12:40</time>
                <b>人事課長　大森</b>
                <span>標準選考</span>
              </div>
              <div className='progress-status'>
                <input type='radio' name='status-history-04' />
                <label>不採用</label>
                <input type='radio' name='statuss-history-04' />
                <label>辞退</label>
                <hr />
                <input type='radio' name='progress-history-04' />
                <label>応募</label>
                <input type='radio' name='progress-history-04' />
                <label className='current changed-here'>
                  書類
                  <br />
                  選考
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  一次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-04' />
                <label>内定</label>
                <input type='radio' name='progress-history-04' />
                <label>確約</label>
                <input type='radio' name='progress-history-04' />
                <label>入社</label>
              </div>
            </div>
            <div>
              <div>
                <time>2019/03/03 10:10</time>
                <b>人事課長　大森</b>
                <span>標準選考</span>
              </div>
              <div className='progress-status'>
                <input type='radio' name='status-history-01' />
                <label>不採用</label>
                <input type='radio' name='statuss-history-01' />
                <label>辞退</label>
                <hr />
                <input type='radio' name='progress-history-01' />
                <label className='current'>
                  応募<b className='changed-here'>合格</b>
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  書類
                  <br />
                  選考
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  一次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>
                  〇次
                  <br />
                  面接
                </label>
                <input type='radio' name='progress-history-01' />
                <label>内定</label>
                <input type='radio' name='progress-history-01' />
                <label>確約</label>
                <input type='radio' name='progress-history-01' />
                <label>入社</label>
              </div>
            </div>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            閉じる
          </button>
        </footer>
      </aside>
      <aside
        className={`recruitment-management-flag ${
          state === 'recruitment-management-flag' ? 'show' : ''
        }`}>
        <header>
          <h1>管理項目変更</h1>
          <p>応募者に対する管理項目を入力します。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>管理項目一覧</h2>
            <div className='group-vertical'>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark style={{ width: '400px' }}>
                  xxxxxxxxxxxxxxxx：
                  <input
                    type='number'
                    defaultValue='78'
                    style={{ textAlign: 'right' }}
                  />
                  <dd>&nbsp;(数値)</dd>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <select>
                    <option>（以下から選択）</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <textarea rows={1} defaultValue='会社説明会で知った' />
                  <span>
                    入力<span>9</span>/最大100
                  </span>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <textarea rows={1} defaultValue='自宅から一時間' />
                  <span>
                    入力<span>7</span>/最大100
                  </span>
                </mark>
              </label>
              <label>
                <input type='radio' name='recruitment-management-flag' />
                <mark>
                  xxxxxxxxxxxxxxxx：
                  <textarea rows={1}></textarea>
                  <span>
                    入力<span>0</span>/最大100
                  </span>
                </mark>
              </label>
            </div>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            キャンセルする
          </button>
          <button type='button' className='btn' data-action='modal-close'>
            OK
          </button>
        </footer>
      </aside>
      <aside
        className={`search-criteria ${
          state === 'search-criteria' ? 'show' : ''
        }`}>
        <header>
          <h1>検索条件の呼び出し</h1>
          <p>保存した検索条件の呼び出しをします。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>保存済み検索条件</h2>
            <div className='group-vertical'>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>東京本社選考</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
              <label>
                <input type='radio' name='search-criteria' />
                <mark>xxxxxxxxxxxxxxxxx</mark>
              </label>
            </div>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            キャンセル
          </button>
          <button type='button' className='btn' data-action='modal-close'>
            OK
          </button>
        </footer>
      </aside>
      <aside
        className={`comment-history ${
          state === 'comment-history' ? 'show' : ''
        }`}>
        <header>
          <h1>コメントの履歴</h1>
          <p>過去に入力したコメントの変更履歴が確認できます。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>履歴</h2>
            <dl className='box-comment-list'>
              <dt>登録日時</dt>
              <dd>2019/02/20 17:00</dd>
              <dt>登録者</dt>
              <dd>人事課長　大森</dd>
              <dt>コメント</dt>
              <dd>
                サンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメント
              </dd>
            </dl>
            <dl className='box-comment-list'>
              <dt>登録日時</dt>
              <dd>2019/02/20 17:00</dd>
              <dt>登録者</dt>
              <dd>人事課長　大森</dd>
              <dt>コメント</dt>
              <dd>
                サンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメント
              </dd>
            </dl>
            <dl className='box-comment-list'>
              <dt>登録日時</dt>
              <dd>2019/02/20 17:00</dd>
              <dt>登録者</dt>
              <dd>人事課長　大森</dd>
              <dt>コメント</dt>
              <dd>
                サンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメント
              </dd>
            </dl>
            <dl className='box-comment-list'>
              <dt>登録日時</dt>
              <dd>2019/02/20 17:00</dd>
              <dt>登録者</dt>
              <dd>人事課長　大森</dd>
              <dt>コメント</dt>
              <dd>
                サンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメントサンプルコメント
              </dd>
            </dl>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            閉じる
          </button>
        </footer>
      </aside>
      <aside
        className={`write-comments ${
          state === 'write-comments' ? 'show' : ''
        }`}>
        <header>
          <h1>コメントを入力</h1>
          <p>応募者に対するコメントを入力します。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>コメント</h2>
            <textarea rows={1}></textarea>
            <div>
              入力 <span>0</span>/最大2000
            </div>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            キャンセルする
          </button>
          <button type='button' className='btn' data-action='modal-close'>
            OK
          </button>
        </footer>
      </aside>
      <aside
        className={`change-progress ${
          state === 'change-progress' ? 'show' : ''
        }`}>
        <header>
          <h1>進捗・判定を変更</h1>
          <p>選択した応募者のデータを更新します。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <h2>判定</h2>
            <p className='group-horizontal'>
              <label>
                <input type='radio' name='change-progress-radio-01' />
                <mark>判定中</mark>
              </label>
              <label>
                <input type='radio' name='change-progress-radio-01' />
                <mark>合格</mark>
              </label>
              <label>
                <input type='radio' name='change-progress-radio-01' />
                <mark>不合格</mark>
              </label>
            </p>
            <h2>
              <span>選考ステップ</span>
            </h2>
            <div className='progress-status'>
              <input type='radio' name='status' id='progress-status-01' />
              <label htmlFor='progress-status-01'>不採用</label>
              <input type='radio' name='status' id='progress-status-02' />
              <label htmlFor='progress-status-02'>辞退</label>
              <hr />
              <input type='radio' name='progress' id='progress-status-03' />
              <label htmlFor='progress-status-03'>応募</label>
              <input type='radio' name='progress' id='progress-status-04' />
              <label htmlFor='progress-status-04'>
                書類
                <br />
                選考
              </label>
              <input type='radio' name='progress' id='progress-status-05' />
              <label htmlFor='progress-status-05' className='current'>
                一次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-06' />
              <label htmlFor='progress-status-06'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-07' />
              <label htmlFor='progress-status-07'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-08' />
              <label htmlFor='progress-status-08'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-09' />
              <label htmlFor='progress-status-09'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-10' />
              <label htmlFor='progress-status-10'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-11' />
              <label htmlFor='progress-status-11'>
                〇次
                <br />
                面接
              </label>
              <input type='radio' name='progress' id='progress-status-12' />
              <label htmlFor='progress-status-12'>内定</label>
              <input type='radio' name='progress' id='progress-status-13' />
              <label htmlFor='progress-status-13'>確約</label>
              <input type='radio' name='progress' id='progress-status-14' />
              <label htmlFor='progress-status-14'>入社</label>
            </div>
            <button type='button' className='btn on-icon icon-btn_message'>
              メッセージ送信
            </button>
            <button
              type='button'
              className='btn on-icon icon-btn_history'
              data-action='open-status-history'>
              履歴を見る
            </button>
            <h2>優先度とコメント</h2>
            <div className='box-evaluation-comment'>
              <div>
                <h3>管理項目</h3>
                <button
                  type='button'
                  className='btn'
                  data-action='open-recruitment-management-flag'>
                  管理項目一覧を見る
                </button>
              </div>
              <div>
                <h3>優先度</h3>
                <input
                  type='number'
                  defaultValue='4'
                  className='evaluation-meter'
                />
                <h3>コメント</h3>
                <q>
                  <span>
                    ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○
                    ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○ ○○○○○○
                    ○○○○○○ ○○○○○○ ○○○○○○。
                  </span>
                  <time>2019年02月17日</time>
                  <b>人事課長 大森</b>
                </q>
                <button
                  type='button'
                  className='btn on-icon icon-btn_history'
                  data-action='open-comment-history'>
                  履歴を見る
                </button>
                <button
                  type='button'
                  className='btn on-icon icon-btn_edit'
                  data-action='open-write-comments'>
                  コメントを書く
                </button>
              </div>
            </div>
            <h2>その他</h2>
            <button
              type='button'
              className='btn on-icon icon-btn_share'
              title='この応募者の情報を関係者にメールでシェアします'>
              関係者にシェア
            </button>
          </section>
        </CustomScrollbar>
        <footer>
          <button
            type='button'
            className='btn'
            data-action='modal-close'
            onClick={onClose}>
            閉じる
          </button>
        </footer>
      </aside>
      <aside className={`change-progress bundle`}>
        <header>
          <h1>進捗・判定を変更</h1>
          <p>以下の対象に対して一括でデータを更新します。</p>
        </header>
        <CustomScrollbar className='ps-container'>
          <section>
            <header>
              <h1>
                操作対象：<em>55</em>名
              </h1>
              <dl>
                <dt>未判定</dt>
                <dd>50人</dd>
                <dt>判定中</dt>
                <dd>3人</dd>
                <dt>合格</dt>
                <dd>1人</dd>
                <dt>不合格</dt>
                <dd>1人</dd>
              </dl>
            </header>
            <h2>判定</h2>
            <p className='group-horizontal'>
              <label>
                <input type='radio' name='change-progress-bundle-radio-01' />
                <mark>判定中</mark>
              </label>
              <label>
                <input type='radio' name='change-progress-bundle-radio-01' />
                <mark>合格</mark>
              </label>
              <label>
                <input type='radio' name='change-progress-bundle-radio-01' />
                <mark>不合格</mark>
              </label>
            </p>
            <h2>
              <span>選考ステップ</span>
            </h2>
            <div className='progress-status'>
              <input
                type='radio'
                name='status-bundle'
                id='progress-status-bundle-01'
              />
              <label htmlFor='progress-status-bundle-01'>不採用</label>
              <input
                type='radio'
                name='status-bundle'
                id='progress-status-bundle-02'
              />
              <label htmlFor='progress-status-bundle-02'>辞退</label>
              <hr />
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-03'
              />
              <label htmlFor='progress-status-bundle-03'>応募</label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-04'
              />
              <label htmlFor='progress-status-bundle-04'>
                書類
                <br />
                選考
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-05'
              />
              <label htmlFor='progress-status-bundle-05' className='current'>
                一次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-06'
              />
              <label htmlFor='progress-status-bundle-06'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-07'
              />
              <label htmlFor='progress-status-bundle-07'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-08'
              />
              <label htmlFor='progress-status-bundle-08'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-09'
              />
              <label htmlFor='progress-status-bundle-09'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-10'
              />
              <label htmlFor='progress-status-bundle-10'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-11'
              />
              <label htmlFor='progress-status-bundle-11'>
                〇次
                <br />
                面接
              </label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-12'
              />
              <label htmlFor='progress-status-bundle-12'>内定</label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-13'
              />
              <label htmlFor='progress-status-bundle-13'>確約</label>
              <input
                type='radio'
                name='progress-bundle'
                id='progress-status-bundle-14'
              />
              <label htmlFor='progress-status-bundle-14'>入社</label>
            </div>
            <button type='button' className='btn on-icon icon-btn_message'>
              メッセージ送信
            </button>
            <h2>優先度とコメント</h2>
            <div className='box-evaluation-comment'>
              <div>
                <h3>管理項目</h3>
                <button
                  type='button'
                  className='btn'
                  data-action='open-recruitment-management-flag'>
                  管理項目一覧を見る
                </button>
              </div>
              <div>
                <h3>優先度</h3>
                <input
                  type='number'
                  defaultValue='0'
                  className='evaluation-meter'
                />
                <h3>コメント</h3>
                <button
                  type='button'
                  className='btn on-icon icon-btn_edit'
                  data-action='open-write-comments'>
                  コメントを書く
                </button>
              </div>
            </div>
            <h2>その他</h2>
            <button
              type='button'
              className='btn on-icon icon-btn_share'
              title='この応募者の情報を関係者にメールでシェアします'>
              関係者にシェア
            </button>
          </section>
        </CustomScrollbar>
        <footer>
          <button type='button' className='btn' data-action='modal-close'>
            閉じる
          </button>
        </footer>
      </aside>
    </div>
  )
}

export default OtherOperation
