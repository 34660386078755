import { call, put, all, takeEvery } from 'redux-saga/effects'
import {
  initEntryUplodaHistory, setEntryUploadHistory, selectEntryUplodaHistory,
  setSelectEntryUplodaHistory, uploadCount,
  fetchEntryUploadSheet, setEntryUploadSheet,
  // ,setTemplatesInfo,
  register,
  updateEntryUploadSheet,
  duplicateCount, filterAutoCount, errorInfoCount, cleanEntryUploadHistory, setErrorComfrim, initEntryUploadErrorComfrim, reuploading, autoMergeCount, initEntryUploadAutoMergeComfrim, setAutoMergeComfrim,
} from 'reducers/entryUploadReducer';
import {
  initEntryUplodaHistoryApi, selectEntryUplodaHistoryApi,
  uploadCountApi, duplicateCountApi, applicantInfoCountApi, errorInfoCountApi, autoMergeCountApi
} from 'apis/MCAPS020Api';
import { openModal, openSnackbar, openModalWithArg } from 'reducers/messageReducer';
import { getNdaysLater } from 'common/generalUtil';
import moment from 'moment';
import { routeList } from 'routes/routes';
import history from 'utils/history';
import { magiContants } from 'utils/contants';
import { initApi, registerApi } from 'apis/MCAPS010Api'
import { initEntryUploadErrorComfirmApi, reuploadApi } from 'apis/MCAPS030Api';
import { Console } from 'console';
import { initUploadErrorInfoResult } from 'pages/MCAPS030/formConfig';
import { magiContants as contants } from 'utils/contants'
import { initEntryUploadAutoMergeComfrimApi } from 'apis/MCAPS040Api';


// MCAPS010 START
function* fetchEntryUploadSheetSaga(action: ReturnType<typeof fetchEntryUploadSheet>) {
  try {
    const data = yield call(initApi, action.payload);
    data.templateInfo.unshift({ entryDataIoTemplateSettingId: "", templateName: "（以下から選択）", uploadType: -1 });
    data.selectionInfo.unshift({ selectionFlowSettingId: "", selectionName: "（以下から選択）" })
    data.mediaInfo.unshift({ mediaSettingId: "", mediaRouteName: "（以下から選択）" })
    yield put(setEntryUploadSheet(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* registerSaga(action: ReturnType<typeof register>) {
  try {
    const data = yield call(registerApi, action.payload);
    const messageId = data.data.messageId
    if(messageId === contants.SUCCESED){
      history.push(routeList.entryUploadHistory,magiContants.MESSAGECODE_MCAPS010_011)
    }else {
      const messages = data.data.messages
      const [...args] = messages
      yield put(openModalWithArg({messageId,args}))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAPS010 END

// MCAPS020 START
// 初期表示
function* initEntryUplodaHistorySaga(action: ReturnType<typeof initEntryUplodaHistory>) {
  try {
    const data = yield call(initEntryUplodaHistoryApi, action.payload.recruitmentManagementDivision);
    yield put(setEntryUploadHistory(data));
    if (action.payload.messageId != null && action.payload.messageId != '') {
      yield put(openSnackbar(action.payload.messageId))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「検索する」ボタン押下時
function* selectEntryUplodaHistorySaga(action: ReturnType<typeof selectEntryUplodaHistory>) {
  try {
    yield put(cleanEntryUploadHistory([]))
    const data = yield call(selectEntryUplodaHistoryApi, action.payload);
    yield put(setSelectEntryUplodaHistory(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「アップロード件数」リンク押下
function* uploadCountSaga(action: ReturnType<typeof uploadCount>) {
  try {
    const data = yield call(uploadCountApi, action.payload);
    const param = {
      request: {
        ...data,
        screenId: magiContants.MCAPS020_SCREENID
      },
    }
    const path = {
      // 「応募者一覧」画面に遷移する
      pathname: routeList.applicantList,
      state: param,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「重複件数」リンク押下
function* duplicateCountSaga(action: ReturnType<typeof duplicateCount>) {
  try {
    const data = yield call(duplicateCountApi, action.payload);
    const param = {
      ...data,
      screenId: magiContants.MCAPS020_SCREENID
    }
    const path = {
      pathname: routeList.nameList,
      state: param,
    }
    history.push(path)
  } catch (error) {
    const date = moment(getNdaysLater(action.payload.executionTime, magiContants.MCAQS020_DUPLICATE_IDENTIFICATION_CHECK_SAVE_DAY)).format('YYYY/MM/DD');
    const errorMessage = {
      messageId: error.message,
      args: [date],
    }
    yield put(openModalWithArg(errorMessage))
  }
}

// 「自動適用件数」リンク押下
function* applicantInfoCountSaga(action: ReturnType<typeof filterAutoCount>) {
  try {
    const data = yield call(applicantInfoCountApi, action.payload);
    const param = {
      request: {
        ...data,
        screenId: magiContants.MCAPS020_SCREENID
      },
    }
    const path = {
      // 「応募者情報一覧」画面に遷移する
      pathname: routeList.applicantList,
      state: param,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「エラー確認」リンク押下
function* errorInfoCountSaga(action: ReturnType<typeof errorInfoCount>) {
  try {
    const data = yield call(errorInfoCountApi, action.payload);
    const path = {
      // 「応募者情報アップロード エラー内容確認」画面に遷移する
      pathname: routeList.entryUploadErrorInfoComfirm,
      state: data,
    }
    history.push(path)
  } catch (error) {
    const date = moment(getNdaysLater(action.payload.executionTime, magiContants.MCAPS020_ENTRY_UPLOAD_ERROR_MANAGEMENT_SAVE_DAY)).format('YYYY/MM/DD');
    const errorMessage = {
      messageId: error.message,
      args: [date],
    }
    yield put(openModalWithArg(errorMessage))
  }
}
// 次期開発12月 #73504 start
// 「自動名寄せ件数」リンク押下
function* autoMergeCountSaga(action: ReturnType<typeof autoMergeCount>) {
  try {
    const data = yield call(autoMergeCountApi, action.payload);
    const path = {
      // 「応募者情報アップロード 自動名寄せ確認」画面に遷移する
      pathname: routeList.entryUploadAutoMergeComfirm,
      state: action.payload,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 次期開発12月 #73504 end
// MCAPS020 END

// MCAPS030 START
// 初期表示
function* initEntryUploadErrorComfrimSaga(action: ReturnType<typeof initEntryUploadErrorComfrim>) {
  try {
    const data = yield call(initEntryUploadErrorComfirmApi, action.payload);
    yield put(setErrorComfrim(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 再アップロードする
function* reuploadingSaga(action: ReturnType<typeof reuploading>) {
  try {
    const data = yield call(reuploadApi, action.payload);
    const path = {
      pathname: routeList.applicationUpload,
      state: data,
    }
    history.push(path)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAPS030 END

// 次期開発12月 #73504 start
// MCAPS040 START
// 初期表示
function* initEntryUploadAutoMergeComfrimSaga(action: ReturnType<typeof initEntryUploadAutoMergeComfrim>) {
  try {
    const data = yield call(initEntryUploadAutoMergeComfrimApi, action.payload);
    yield put(setAutoMergeComfrim(data));
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// MCAPS040 END
// 次期開発12月 #73504 end

export default function* entryUpLoadSaga() {
  yield all([
    // MCAPS020 START
    takeEvery(initEntryUplodaHistory, initEntryUplodaHistorySaga),
    takeEvery(selectEntryUplodaHistory, selectEntryUplodaHistorySaga),
    takeEvery(uploadCount, uploadCountSaga),
    takeEvery(duplicateCount, duplicateCountSaga),
    takeEvery(filterAutoCount, applicantInfoCountSaga),
    takeEvery(errorInfoCount, errorInfoCountSaga),
    // MCAPS020 END
    // MCAPS010 START
    takeEvery(fetchEntryUploadSheet, fetchEntryUploadSheetSaga),
    takeEvery(register, registerSaga),
    // MCAPS010 end
    // MCAPS030 START
    takeEvery(initEntryUploadErrorComfrim, initEntryUploadErrorComfrimSaga),
    takeEvery(reuploading, reuploadingSaga),
    // MCAPS030 END
    // 次期開発12月 #73504 start
    takeEvery(autoMergeCount, autoMergeCountSaga),
    takeEvery(initEntryUploadAutoMergeComfrim, initEntryUploadAutoMergeComfrimSaga),
    // 次期開発12月 #73504 end
  ])
}