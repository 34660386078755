import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApplicantBasicInfo } from 'pages/MCBHS040/DetailedSearch/Config/ApplicantBasicConfig'
import { EntrySheetInfo } from 'pages/MCBHS040/DetailedSearch/Config/EntrySheetConfig'
import { SubmissionRequestInfo } from 'pages/MCBHS040/DetailedSearch/Config/SubmissionRequestConfig'
import {
  SearchCondition,
  initSearchCondition,
  initOrderObjArray,
} from 'pages/MCBHS040/searchConditionConfig'

/**
 * 応募者基本情報_初期表示用データ格納情報
 */
const applicantBasicInfo: ApplicantBasicInfo = {
  schoolNameList: [], // 学校名
  facultyGraduateNameList: [], // 学部名・研究科名
  mcbUsageOptionList: [], // My CareerBox利用状況
}

/**
 * 提出リクエスト情報_初期表示用データ格納情報
 */
const submissionRequestInfo: SubmissionRequestInfo = {
  submissionContentList: [],//提出物内容サジェスト情報
  submissionDeadlineOptionList: [], //提出締切日(条件)
  submissionStatusOptionList: [],//提出状況(条件)
  confirmationStatusList: [],//提出リクエスト確認状況(条件)
  errorCode: '',//エラーコード
}

/**
 * エントリーシート／履歴書情報_初期表示用データ格納情報
 */
const entrySheetInfoInfo: EntrySheetInfo = {
  sexList: [], // 性別情報
  languageSkillsList: [], // 語学スキルサジェスト情報
  eligibilityList: [], // 保有資格サジェスト情報
  academicList: [],  //力を入れた学業サジェスト情報
  researchContentList: [],//所属ゼミ／研究室の研究内容サジェスト情報
}

const initialState = {
  submissionRequestInfo,
  entrySheetInfoInfo,
  applicantBasicInfo,
  searchCondition: initSearchCondition,
  open: false,
  MCBIS030SCriteriaParam: initSearchCondition, //My検索保存パラメータ
  orderObjArray: initOrderObjArray
}

const submissionRequestDetailedSearchSlice = createSlice({
  name: 'submissionRequestDetailedSearch',
  initialState,
  reducers: {
    // 検索条件詳細初期表示情報を設定
    setSearchConditionSelectInit(
      state,
      action: PayloadAction<{
        mCBHS131Result: ApplicantBasicInfo
        mCBHS141Result: SubmissionRequestInfo
        mCBHS151Result: EntrySheetInfo
      }>
    ) {
      state.applicantBasicInfo = action.payload.mCBHS131Result
      state.submissionRequestInfo = action.payload.mCBHS141Result
      state.entrySheetInfoInfo = action.payload.mCBHS151Result

      const { submissionContentList, submissionDeadlineOptionList, submissionStatusOptionList, confirmationStatusList, errorCode} = action.payload.mCBHS141Result
      const { schoolNameList, facultyGraduateNameList, mcbUsageOptionList } = action.payload.mCBHS131Result
      const { languageSkillsList, eligibilityList, academicList, researchContentList, sexList } = action.payload.mCBHS151Result

      state.orderObjArray = {
        //画面の並び順にソートする処理用のオブジェクト
        sexCodeList: sexList.map(i => ({
          sexCode: Number(i.value)
        })),
      }
      return state
    },
    // 検索条件設定
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      state.searchCondition = action.payload
      return state
    },
    // 検索条件エリア開閉フラグを設定
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
      return state
    },
    // My検索保存パラメータを設定
    setMCBIS030SCriteriaParam(state, action: PayloadAction<SearchCondition>) {
      state.MCBIS030SCriteriaParam = action.payload
      return state
    },
  },
  
})

export const {
  setSearchConditionSelectInit,
  setSearchCondition,
  setOpen,
  setMCBIS030SCriteriaParam,
} = submissionRequestDetailedSearchSlice.actions
export default submissionRequestDetailedSearchSlice.reducer
