import { TableUpdateSexRequest, TableUpdateSexUpdateRequest } from 'pages/MCBKS010/formConfig'
import request from 'utils/request'

export const tableUpdateSexInitRequest = (
  interview: TableUpdateSexRequest
) =>
  request({
    url: '/MCBKS010/init',
    method: 'post',
    data: interview,
  })

export const tableUpdateSexUpdateRequest = (
  interview: TableUpdateSexUpdateRequest
) => 
  request({
    url: '/MCBKS010/update',
    method: 'post',
    data: interview,
  })