import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {initialStateValue} from 'pages/MCBHS050/formConfig'
import { MCBHS050DownloadCsvRequest } from 'types/MCBHS050/MCBHS050DownloadCsvRequest'
import { MCBHS050DownloadFileRequest } from 'types/MCBHS050/MCBHS050DownloadFileRequest'
import { MCBHS050InitRequest } from 'types/MCBHS050/MCBHS050InitRequest'

export interface SubmissionRequestMessageDetail {
    selectMcbMessageDetail: McbMessageDetail
    jobSeekerInfoList: JobSeekersInfo[]
    pageList: McbAttachedOption[]
    attachedList: McbMessageAttachment[]
    mcbJobSeekerInfoList: McbJobSeekersInfo[]
    searchCriteriaInfo: SearchCriteriaInfoResult
    downloadAuthority: boolean // ログインユーザのアカウント権限
    jobSeekersInfoCountFlag: string // 送受信者情報件数フラグ
    // #MCB1.5次開発 #72395 START
    senderAddressFix: string //  送信元メールアドレス固定値
    // #MCB1.5次開発 #72395 END
}

export interface McbMessageDetail {
    submissionRequestId: number // 提出リクエストID
    sendAndReceiveDivision: string // 送受信区分
    status: string // ステータス
    convertingBatchCompleteFlag: string // 変換バッチ完了フラグ
    sendTimeShow: string // 送信日時
    submissionContentId: number // 提出物内容ID
    submissionContentName: string // 提出物内容名
    submissionContentNameStudent: string // 提出物内容名（学生画面表示用）
    submissionEntrySheetFlag: string // 提出物エントリシートフラグ
    submissionResumeFlag: string // 提出物履歴書フラグ
    submissionResearchSummaryFlag: string // 提出物研究概要書フラグ
    submissionOriginalQuestionFlag: string // 提出物オリジナル設問フラグ
    submissionFileSubmissionFlag: string // 提出物ファイル提出フラグ
    submissionDeadlineKind: string // 提出締切_種別
    submissionDeadlineDay: string //  提出締切_日
    submissionDeadlineHour: number // 提出締切_時
    resubmitReceptionFlag: string // 再提出受付フラグ
    senderCompanyName: string // 送信元会社名
    senderMailAddress: string // 送信元メールアドレス
    subject: string // 件名
    body: string // 本文
    thanksMailSettingFlag: string // サンクスメール設定フラグ
    thanksSubject: string // サンクスメール件名
    thanksBody: string // サンクスメール本文
    contactId: number // お問い合わせ先ID
    contactName: string // お問い合わせ先名
    contactCompanyName: string // お問い合わせ先内容企業名
    contactDepartmentNameAndManagerName: string // お問い合わせ先内容部署名担当者氏名
    contactTelephoneNumber: string // お問い合わせ先内容電話番号
    contactMailAddress: string // お問い合わせ先内容メールアドレス
    privacyPolicyId: number // 個人情報の取り扱いID
    privacyPolicyName: string // 個人情報の取り扱い名
    bulkSendFlag: string // 一括送信フラグ
    dateTimeDetermine: string // 提出締切
    submissionRequestUrl: string // 提出リクエストURL
}

export interface JobSeekersInfo {
    readableFlag: string // 閲覧権限無し表示
    sendingResult: string // 送信結果
    fullName: string // 氏名
    jobSeekerIdForDisplay: string // 応募者管理ID
    entryId: number // エントリーID
    readFlag: string // 提出リクエスト確認状況
    mcbMemberId: number // MCB会員ID
    mcbUseStatus: string // My CareerBox利用状況
    memberType: string // 会員種別
    unsubscribedJobSeekerId: number // 求職者退会情報ID
    schoolName: string // 大学
    facultyName: string // 学部
    subjectName: string // 学科 
    sysDeletingFlag: number // sys削除フラグ
    applicantsListInfo: ApplicantsListInfo[] // 選考情報リスト
}

export interface ApplicantsListInfo {
    jobSeekerId: string // 求職者ID
    entryId: string // エントリーID
    selectionManagementId: string // 選考管理ID

}

export interface McbAttachedOption {
    mcbMessageSendId: number // MCBメッセージ送信ID
    attachedOptionType: string // 添付オプション種別
    sequence: number // 連番
    homePageTitle: string // ホームページタイトル
    homePageUrl: string // ホームページURL
}

export interface McbMessageAttachment {
    mcbMessageSendId: number // MCBメッセージ送信ID
    sequence: number // 連番
    attachmentName: string // 添付ファイル名
    fileManagementId: string // ファイル管理ID 
}

export interface McbJobSeekersInfo {
    entryId: string // エントリーID
    jobSeekerIdForDisplay: string // 表示用求職者ID
    familyName: string // 氏名（姓）
    name: string // 氏名（名）
    submissionRequestUrl: string // 提出リクエストURL
}

export interface SearchCriteriaInfoResult {
    searchCriteriaName: string // 検索条件名
    searchCriteria: string // 検索条件
}

export interface DetailRequest {
    flag: string
    request: MCBHS050InitRequest
}

const initialState: SubmissionRequestMessageDetail = initialStateValue

const submissionRequestMessageDetailSlice = createSlice({
    name: 'submissionRequestMessageDetail',
    initialState,
    reducers: {
        searchSubmissionRequestMessageDetail(state, action: PayloadAction<DetailRequest>) {
            return state;
          },
        setSubmissionRequestMessageDetail(state, action: PayloadAction<SubmissionRequestMessageDetail>) {
            state = action.payload;
            return state;
        },
        attachmentFileDownload(state, action: PayloadAction<MCBHS050DownloadFileRequest>) {
            return state;
        },
        csvFileDownload(state, action: PayloadAction<MCBHS050DownloadCsvRequest>) {
            return state;
        },
    }
})

export const {
    searchSubmissionRequestMessageDetail,
    setSubmissionRequestMessageDetail,
    attachmentFileDownload,
    csvFileDownload,
} = submissionRequestMessageDetailSlice.actions
export default submissionRequestMessageDetailSlice.reducer