import request from 'utils/request'
import { MCAZS020MessageQueryRequest } from 'types/MCAZS020/MCAZS020MessageQueryRequest'
import { MCAZS020MessageAllSelectedRequest } from 'types/MCAZS020/MCAZS020MessageAllSelectedRequest'

export const outBoxInitRequest = (apiData: MCAZS020MessageQueryRequest) =>
request({
  url: '/MCAZS020/init',
  method: 'post',
  data: apiData,
})  

export const selectMessageOutBoxRequest = (apiData: MCAZS020MessageQueryRequest) =>
  request({
    url: '/MCAZS020/find',
    method: 'post',
    data: apiData,
  })

export const moveTrashMessageRequest = (apiData: MCAZS020MessageAllSelectedRequest) =>
request({
  url: '/MCAZS020/moveTrashMessage',
  method: 'post',
  data: apiData,
})

export const getFailSelectionManagementApi = (messageSendId: number) =>
request({
  url:`/MCAZS020/getFailSelectionManagementIdBulkList/${messageSendId}`,
  method: 'post',
})

export const sendMessageCheckAPI = (messageSendId: string[]) => 
  request({
    url:`/MCAZS020/sendMessageCheck/${messageSendId}`,
    method: 'post',
})

// #5654 #59450 start
export const getSelectionManagementApiMCAZS020 = (apiData: any) =>
request({
  url:`/MCAZS020/getSelectionManagementIdBulkList`,
  method: 'post',
  data: apiData,
})
// #5654 #59450 end
