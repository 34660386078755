import {
  Button,
  colors,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Option } from 'componentsHsc/Toggle'
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useModal } from 'utils'
import { magiContants as contants } from 'utils/contants'
import { FormControl } from '..'
import EnhancedTableHead from './EnhancedTableHead'
import { useDispatch } from 'react-redux'
import { getMcaxs521Init } from 'reducers/advancedSearchModalReducer'
import { getMessage } from 'common/messageUtil'

const regionSet = {
  hokkaidotohoku: [
    { label: '北海道', value: '01' },
    { label: '青森県', value: '02' },
    { label: '岩手県', value: '03' },
    { label: '宮城県', value: '04' },
    { label: '秋田県', value: '05' },
    { label: '山形県', value: '06' },
    { label: '福島県', value: '07' },
  ],
  kanto: [
    { label: '茨城県', value: '08' },
    { label: '栃木県', value: '09' },
    { label: '群馬県', value: '10' },
    { label: '埼玉県', value: '11' },
    { label: '千葉県', value: '12' },
    { label: '東京都', value: '13' },
    { label: '神奈川県', value: '14' },
  ],
  kousinnetuhokuriku: [
    { label: '新潟県', value: '15' },
    { label: '富山県', value: '16' },
    { label: '石川県', value: '17' },
    { label: '福井県', value: '18' },
    { label: '山梨県', value: '19' },
    { label: '長野県', value: '20' },
  ],
  tokaicyubu: [
    { label: '岐阜県', value: '21' },
    { label: '静岡県', value: '22' },
    { label: '愛知県', value: '23' },
    { label: '三重県', value: '24' },
  ],
  kinnki: [
    { label: '滋賀県', value: '25' },
    { label: '京都府', value: '26' },
    { label: '大阪府', value: '27' },
    { label: '兵庫県', value: '28' },
    { label: '奈良県', value: '29' },
    { label: '和歌山県', value: '30' },
  ],
  cyugokusikoku: [
    { label: '鳥取県', value: '31' },
    { label: '島根県', value: '32' },
    { label: '岡山県', value: '33' },
    { label: '広島県', value: '34' },
    { label: '山口県', value: '35' },
    { label: '徳島県', value: '36' },
    { label: '香川県', value: '37' },
    { label: '愛媛県', value: '38' },
    { label: '高知県', value: '39' },
  ],
  kyusyuokinawa: [
    { label: '福岡県', value: '40' },
    { label: '佐賀県', value: '41' },
    { label: '長崎県', value: '42' },
    { label: '熊本県', value: '43' },
    { label: '大分県', value: '44' },
    { label: '宮崎県', value: '45' },
    { label: '鹿児島県', value: '46' },
    { label: '沖縄県', value: '47' },
  ],
  kaigai: [{ label: '海外', value: '99' }],
}

export type Area = keyof typeof regionSet

const areaList: { label: string; value: Area }[] = [
  { label: '北海道・東北', value: 'hokkaidotohoku' },
  { label: '関東', value: 'kanto' },
  { label: '甲信越・北陸', value: 'kousinnetuhokuriku' },
  { label: '東海・中部', value: 'tokaicyubu' },
  { label: '近畿', value: 'kinnki' },
  { label: '中国・四国', value: 'cyugokusikoku' },
  { label: '九州・沖縄', value: 'kyusyuokinawa' },
  { label: '海外', value: 'kaigai' },
]

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    color: (theme.palette as any).white,
    backgroundColor: '#133e80',
    '&:hover': {
      backgroundColor: '#133e80',
    },
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 900,
    fontSize: 18,
    cursor:'auto',
  },
  select: {
    display: 'flex',
    '& > :first-child': {
      flex: 2,
    },
    '& > :last-child': {
      flex: 3,
    },
    '& span.MuiFormControlLabel-label': {
      color: colors.grey[600],
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: '#1950a6',
      textDecoration: 'underline',
      '& span': {
        color: '#1950a6',
      },
    },
  },
  content: {
    height: 'inherit !important',
  },
  cancel: {
    color: (theme.palette as any).black,
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#c1d1eb',
    },
    width: 130,
    marginRight: '20px',
  },
  confirmButton: {
    color: (theme.palette as any).white,
    backgroundColor: '#3380cc',
    '&:hover': {
      backgroundColor: '#23598e',
    },
    width: 130,
    marginLeft: '20px !important',
  },
  dividerColor: {
    backgroundColor: colors.grey[600],
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '6px',
    '& input:checked + mark': {
      backgroundColor: '#3f71bd',
      backgroundPosition: 'center center',
      borderColor: '#3f71bd',
    },
  },
  mark: {
    height: '16px !important',
    width: '16px !important',
    marginRight: '8px !important',
  },
  selected :{
    '&:hover': {
      opacity:1,
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
  }
}))

export type Address = 'current' | 'vacation'

interface Props {
  open: boolean
  onClose: () => void
  form: FormControl
  tabValueState: [Address, Dispatch<SetStateAction<Address>>]
  selectedAreaState: [
    { current: Area; vacation: Area },
    Dispatch<SetStateAction<{ current: Area; vacation: Area }>>
  ]
}

const RegionModal = ({
  open,
  onClose,
  form,
  tabValueState: [tabValue, setTabValue],
  selectedAreaState: [selectedArea, setSelectedArea],
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      dispatch(getMcaxs521Init())
    }
  }, [open])

  const [selectedRegions, setSelectedRegions] = useState(
    form.values.residenceObj || {
      current: [],
      vacation: [],
      residenceCondition: '',
    }
  )

  useEffect(() => {
    setSelectedRegions(
      form.values.residenceObj || {
        current: [],
        vacation: [],
        residenceCondition: '',
      }
    )
  }, [form.values.residenceObj])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: Address) => {
    setTabValue(newValue)
  }

  const handleClick = (area: Area) => () => {
    setSelectedArea({
      ...selectedArea,
      [tabValue]: area,
    })
  }

  const handleSelect = (region: Option, checked: boolean) => () => {
    setSelectedRegions({
      ...selectedRegions,
      [tabValue]: checked
        ? selectedRegions[tabValue].filter(i => i.value !== region.value)
        : [...selectedRegions[tabValue], region],
    })
  }

  const handleSelectAll = (checked: boolean) => () => {
    setSelectedRegions({
      ...selectedRegions,
      [tabValue]: checked
        ? selectedRegions[tabValue].filter(
            i => regionSet[selectedArea[tabValue]].indexOf(i) === -1
          )
        : [
            ...new Map(
              [
                ...selectedRegions[tabValue],
                ...regionSet[selectedArea[tabValue]],
              ].map(v => [v.value, v])
            ).values(),
          ],
    })
  }

  // Confirm Modal
  const [show, toggle] = useModal(false)
  const handleCancelOk = () => {
    setSelectedRegions(
      form.values.residenceObj || {
        current: [],
        vacation: [],
        residenceCondition: '',
      }
    )
    toggle(false)()
    onClose()
  }

  const handleSave = () => {
    const tempcurrent = new Map();
    const selectCurrent: Option[] = selectedRegions.current.filter((i) => !tempcurrent.has(i.value) && tempcurrent.set(i.value, 1));
    const tempvacation = new Map();
    const selectVacation: Option[] = selectedRegions.vacation.filter((i) => !tempvacation.has(i.value) && tempvacation.set(i.value, 1));
    const result = {
      current: [...selectCurrent].sort((a, b) => {
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      }),
      vacation: [...selectVacation].sort((a, b) => {
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      }),
      residenceCondition: selectedRegions.residenceCondition,
    }
    if (
      result.current.length > 0 &&
      result.vacation.length > 0 &&
      result.residenceCondition === ''
    ) {
      //現住所、休暇中住所両方にチェックがついてる
      //かつ、
      //AND,ORの条件を設定していない場合
      result.residenceCondition = '1'
    } else if (result.current.length === 0 || result.current.length === 0) {
      //現住所、休暇中住所どちらにもチェックがついていない場合
      result.residenceCondition = ''
    }

    form.setFieldValue('residenceObj', result)
    onClose()
  }

  return (
    <>
      <Dialog open={open} maxWidth='md' fullWidth className={'mainDialog'}>
        <DialogTitle className={classes.dialogTitle}>
          <label className={classes.title}>居住地（都道府県）を指定</label>
        </DialogTitle>
        <DialogContent>
          <div className='ps-container ps'>
            <section>
              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label='現住所' value='current' className={classes.selected}/>
                <Tab label='休暇中住所' value='vacation' className={classes.selected}/>
              </Tabs>
              <div className={classes.select}>
                <List>
                  {areaList.map(area => (
                    <ListItem
                      button
                      key={area.value}
                      onClick={handleClick(area.value)}
                      selected={area.value === selectedArea[tabValue]}>
                      <ListItemText primary={area.label} />
                    </ListItem>
                  ))}
                </List>
                <Divider orientation='vertical' flexItem />
                <List>
                  <EnhancedTableHead
                    onSelectAllClick={handleSelectAll}
                    areaKye={selectedArea[tabValue]}
                    selected={regionSet[selectedArea[tabValue]].every(
                      (region, i) =>
                        selectedRegions[tabValue]
                          .map((i: any) => i.value)
                          .indexOf(region.value) !== -1
                    )}
                  />
                  {regionSet[selectedArea[tabValue]].map(region => {
                    const checked =
                      selectedRegions[tabValue]
                        .map((i: any) => i.value)
                        .indexOf(region.value) !== -1

                    return (
                      <ListItem
                        button
                        key={region.value}
                        onClick={handleSelect(region, checked)}
                        disableRipple>
                        <ListItemIcon>
                          <label className={classes.label}>
                            <input
                              type='checkbox'
                              checked={checked}
                              onChange={handleSelect(region, !checked)}
                            />
                            <mark className={classes.mark}></mark>
                          </label>
                        </ListItemIcon>
                        <ListItemText primary={region.label} />
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </section>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={toggle(true)}
            color='primary'
            variant='contained'
            className={classes.cancel}>
            キャンセルする
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
            className={classes.confirmButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={show} onClose={toggle(false)}>
        <DialogTitle>{getMessage(contants.MESSAGECODE_MCAXS521_001)}</DialogTitle>
        <DialogActions>
          <Button onClick={toggle(false)}>キャンセル</Button>
          <Button onClick={handleCancelOk} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RegionModal
