import request from 'utils/request'
import { MCARS060InitRequest } from 'types/MCARS060/MCARS060InitRequest'
import { MCARS060SaveRequest } from 'types/MCARS060/MCARS060SaveRequest'

export const actionSettingInitRequest = (params : MCARS060InitRequest) =>
  request({
    url: `MCARS060/init`,
    method: 'post',
    data: params 
  })

export const actionSettingSaveRequest = (params : MCARS060SaveRequest) =>
request({
  url: `MCARS060/save`,
  method: 'post',
  data: params 
  })

