import React from 'react'
import { FieldProps } from 'formik'
import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import { PropTypes, makeStyles } from '@material-ui/core'
import { Option } from './Config/searchConditionConfig'

interface Props extends FieldProps {
  margin?: PropTypes.Margin
  disableFlag?: boolean
  icon?: React.ReactNode
  placeholder?: string
  option: Option
  onBlur?: (event: any) => void
  onChange?: (index: any, name: any, physicalName:any, value: any) => void
  targetIndex: number
  dateYup: any
}

const useStyles = makeStyles({
  input: {
    border: '1px solid #8593a6',
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    padding: '0 8px',
    cursor: 'text',
    fontSize: '14px',
    '&:hover': {
      borderColor: '#c1d1eb',
    },
    '&:focus': {
      borderColor: '#3f71bd',
    },
    '&:disabled': {
      cursor: 'auto',
      border: '1px solid lightgrey',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: '1px solid lightgrey',
      },
    },
  },
  datePicker: {
    minWidth: '24px !important',
  },
  inputForError: {
    border: '1px solid red',
    background: 'none #fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '30px',
    padding: '0 8px',
    cursor: 'text',
    fontSize: '14px',
    '&:focus': {
      border: '2px solid red',
    },
  },
  disabled: {
    backgroundImage:
      'url(/css/img/common/input_calender_disable.png) !important',
  },
})

const DatePickerDialog = ({
  margin = 'dense',
  disableFlag = false,
  icon,
  placeholder = '',
  field,
  form,
  option,
  onBlur,
  onChange,
  targetIndex,
  dateYup,
}: Props) => {
  const classes = useStyles()
  const { errors, touched } = form
  const { value, name } = field

  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <MuiDatePicker
      InputProps={{ disableUnderline: true }}
      inputProps={{
        className:
          errors[name] && touched[name] ? classes.inputForError : classes.input,
      }}
      keyboardIcon={
        icon ? (
          icon
        ) : (
          <span
            className={`${'btn only-icon icon-input_calender'} ${
              classes.datePicker
            } ${disableFlag ? classes.disabled : ''}`}
          />
        )
      }
      placeholder={placeholder}
      KeyboardButtonProps={{ edge: 'start' }}
      disabled={disableFlag}
      type={'datepicker'}
      inputVariant='standard'
      format='YYYY/MM/DD'
      fullWidth
      margin={margin}
      name={name}
      value={isNaN(Date.parse(value)) ? null : new Date(value)}
      inputValue={value}
      helperText={errors[name] && touched[name] ? errors[name] : null}
      error={Boolean(errors[name] && touched[name])}
      onChange={(date, value) => {
        form.setFieldValue(name, value)
        form.setFieldValue(`${name.split('.')[0]}.physicalName`, option.value)
        form.setFieldValue(`${name.split('.')[0]}.logicalName`, option.label)
        let isValidError = false
        try{
          dateYup.validateSync({
            date: value,
          })
        } catch (err) {
          isValidError = true
        }
        if (!isValidError && onChange) {
          onChange(targetIndex, name.split('.')[1], option.value, value)
        }
      }}
      onBlur={handleBlur}
      autoOk={true}
      cancelLabel='キャンセル'
    />
  )
}

export default DatePickerDialog
