import request from 'utils/request'
import { MCAXS230InitRequest } from 'types/MCAXS230/MCAXS230InitRequest'
import { MCAXS230AddressRequest } from 'types/MCAXS230/MCAXS230AddressRequest'
import { MCAXS230ApplicantInfoRequest } from 'types/MCAXS230/MCAXS230ApplicantInfoRequest'
import { MCAXS230SchoolDetailedDivisionInfoRequest } from 'types/MCAXS230/MCAXS230SchoolDetailedDivisionInfoRequest'
import { MCAXS230SchoolInfoRequest } from 'types/MCAXS230/MCAXS230SchoolInfoRequest'

export const initRequest = (params: MCAXS230InitRequest) =>
    request({
        url: 'MCAXS230/init',
        method: 'post',
        data: params
    })

export const mcaxs230AutoInputRequest = (params: MCAXS230AddressRequest) =>
    request({
        url: 'MCAXS230/autoInput',
        method: 'post',
        data: params
    })

export const mcaxs230VocationalSchoolMajorSelectRequest = (params: MCAXS230SchoolDetailedDivisionInfoRequest) =>
    request({
        url: 'MCAXS230/vocationalSchoolMajorSelect',
        method: 'post',
        data: params
    })

export const mcaxs230CheckRequest = (params: MCAXS230ApplicantInfoRequest) =>
    request({
        url: 'MCAXS230/check',
        method: 'post',
        data: params
    })

export const mcaxs230GraduateSchoolDepartmentRequest = (params: MCAXS230SchoolDetailedDivisionInfoRequest) =>
    request({
        url: 'MCAXS230/graduateSchoolDepartment',
        method: 'post',
        data: params
    })

export const mcaxs230GraduateSchoolDepartmentSeelectRequest = (params: MCAXS230SchoolDetailedDivisionInfoRequest) =>
    request({
        url: 'MCAXS230/graduateSchoolDepartmentSeelect',
        method: 'post',
        data: params
    })

export const mcaxs230JuniorCollegeSubjectSelectRequest = (params: MCAXS230SchoolDetailedDivisionInfoRequest) =>
    request({
        url: 'MCAXS230/juniorCollegeSubjectSelect',
        method: 'post',
        data: params
    })

export const mcaxs230SchoolInitialOkRequest = (params: MCAXS230SchoolInfoRequest) =>
    request({
        url: 'MCAXS230/schoolInitialOk',
        method: 'post',
        data: params
    })