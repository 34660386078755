import { CheckSysVersionRequest } from 'pages/MCBKS020/formConfig'
import request from 'utils/request'

export const MCBKS020InitRequest = () =>
  request({
    url: '/MCBKS020/init',
    method: 'post'
  })

export const MCBKS020CheckRequest = (
  interview: CheckSysVersionRequest
) =>
  request({
    url: '/MCBKS020/check',
    method: 'post',
    data: interview
  })