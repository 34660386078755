/* eslint-disable @typescript-eslint/camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { McbSendTarget, SendTarget } from 'pages/MCBHS090/viewConig'
import { SendReplyImpossibleTime } from 'pages/MCBHS090/apiConfig'
import { MCBHS100InitRequest } from 'types/MCBHS100/MCBHS100InitRequest'
import { MCBHS100InitResult } from 'types/MCBHS100/MCBHS100InitResult'
import { MCBHS100RegisterRequest } from 'types/MCBHS100/MCBHS100RegisterRequest'

const messageRegisterRequest: MCBHS100RegisterRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  // 9174 start
  searchCriteriaJson: '',
  // 9174 end
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  sendTimerFlag: 0,
  draftMessageSendID: '',
  messageUtilMcbMessagesDetailDtoList: [],
  // MCBリプレイス #8508 start
  submissionsNameForStudent:'',
  // MCBリプレイス #8508 end
}

const initRequest: MCBHS100InitRequest = {
  searchCriteriaName: '',
  searchCriteria: '',
  submissionsSelectId: '',
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  sendTimer: 0,
  messageUtilMcbMessagesDetailDtoList: [],
}

const initResult: MCBHS100InitResult = {
  entryId: 0,
  familyName: '',
  name: '',
  jobSeekerIdForDisplay: 0,
  submissionRequestUrl: '',
}

const initialState: {
  messageComfInitRequest: MCBHS100InitRequest
  messageComInitResult: MCBHS100InitResult[]
  draftRequest: MCBHS100RegisterRequest
  businessCheckResult: boolean
  alertMessageIdList: string[]
  subject: string
  body: string
  successfulCountEmail: number
  failedCount: number
  mcbSendTarget: McbSendTarget[]
  destinationId: string[]
} = {
  messageComfInitRequest: initRequest,
  messageComInitResult: [],
  draftRequest: messageRegisterRequest,
  businessCheckResult: false,
  alertMessageIdList: [],
  subject: '',
  body: '',
  successfulCountEmail: 0,
  failedCount: 0,
  mcbSendTarget: [],
  destinationId: [],
}

const submissionRequestForcedStopConfirmSlice = createSlice({
  name: 'submissionRequestForcedStopConfirm',
  initialState,
  reducers: {
    getInit(
      state,
      action: PayloadAction<{
        initVal: MCBHS100InitRequest
        setReplace: (
          serverResponse: MCBHS100InitResult[],
          subject: string,
          // #MCB1.5次開発 #72395 START
          body: string,
          senderMailAddressFix: string
          // #MCB1.5次開発 #72395 END
        ) => { subject: string; body: string }
      }>
    ) {
      return state
    },
    setInit(state, action: PayloadAction<MCBHS100InitResult[]>) {
      state.messageComInitResult = action.payload
      return state
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload
      return state
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = false
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    redistMessage(
      state,
      action: PayloadAction<{
        formData: MCBHS100RegisterRequest
        sendTargetConfirmation: SendTarget[]
        forbiddenWords: string[]
        sendReplyImpossibleTime: SendReplyImpossibleTime[]
      }>
    ) {
      return state
    },
    setBusinessCheckResult(state, action: PayloadAction<boolean>) {
      state.businessCheckResult = action.payload
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setSubject,
  setBody,
  setCode,
  setBusinessCheckResult,
  redistMessage,
} = submissionRequestForcedStopConfirmSlice.actions
export default submissionRequestForcedStopConfirmSlice.reducer
