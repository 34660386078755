import {
  call,
  put,
  select,
  all,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects'
import {
  messageTemplateAddInit, 
  setAddPageDialogOpen, 
  setMessageTemplateAddInit,
  selectMeassageTemplateList,
  setSelectMeassageTemplateList,
  messageTemplateConfirm,
  addMeassageTemplateList,
  setAddMeassageTemplateList,
  executeMeassageTemplateList,
  setExecuteMeassageTemplateList,
  copyMeassageTemplateList,
  setCopyMeassageTemplateList,
  setMeassageTemplateListPage,
  setMeassageTemplateListData,
  setMeassageTemplateListOption,
  openModalData,
  setOpenModalMeassageData,
  setMessageTemplateContentConfirm,
  insertOrUpdateMessageTemplate,
  setMessageTemplateSettingId030,
  setMessageTemplateDetailInit,
  setDetailPageDialogOpen,
  setOpenConfirmContentPage,
  messageTemplateDelete,
  messageTemplateDetailConfirm,
  setAddSubmit,
  openModalMessage,
  updateMessageTemplateSort,
  MCAMS010MessageTemplateList,
  messageTemplateTargetYearChangeInit,
  setMessageTemplateTargetYearChangeInit,
  setReplaceBody,
  getReplaceBody,
} from 'reducers/messageTemplateReducer'
import { openSnackbar,openModal } from 'reducers/messageReducer'
import {selectMeassageTemplateListRequest , addMeassageTemplateListRequest,
  executeMeassageTemplateListRequest,copyMeassageTemplateListRequest, updateMessageTemplateSortRequest} from 'apis/MCAMS010Api'
import { insertOrUpdateRequest } from 'apis/MCAMS040Api'
import { mcbMessageTemplatePreviewRequest } from 'apis/McbMessageReplaceBodyApi'
import { MCAMS040generateMessageTemplateRequest } from 'types/MCAMS040/MCAMS040generateMessageTemplateRequest'
import {messageTemplateAddRequest , messageTemplateConfirmRequest, messageTemplateTargetYearChange} from 'apis/MCAMS020Api'
import { getRecruitmentManagementDivision } from '../selectors/authSelectors'
import { initRequest } from 'apis/MCAMS040Api'
import { messageTemplateDetailRequest, deleteMessageTemplateRequest, messageTemplateInputRequest } from 'apis/MCAMS030Api'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import { MCAMS020InitDisplayRequest } from 'types/MCAMS020/MCAMS020InitDisplayRequest'
import { MCAMS040InitDisplayRequest } from 'types/MCAMS040/MCAMS040InitDisplayRequest'
import { getSearchCondition } from 'selectors'
import { isFileExist } from 'common/businessUtil'
import { initialMCAMS020ReturnDisplayDto } from 'pages/MCAMS020/formConfig'

  // MCAMS020 Start
  function* addSearchSaga(action: ReturnType<typeof messageTemplateAddInit>) {
    try {
      const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
      action.payload.recruitmentManagementDivision = recruitmentManagementDivision;
      const data = yield call(messageTemplateAddRequest, action.payload)
      yield put(setMessageTemplateAddInit(data));
      yield put(setAddPageDialogOpen(true));
    } catch (error) {
      yield put(openModal(error.message));
    }
  }
  function* messageTemplateConfirmSaga(action: ReturnType<typeof messageTemplateConfirm>) {
    try {
      const files: File[] = action.payload.files
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if(!isFile){
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_025)))
            return
          }
        }
      }
      const data1 = yield call(messageTemplateConfirmRequest, 
        {registeredFileName: files,
          submitParams: action.payload.submitData})
      action.payload.data.templateattachmentList=data1;
      const data = action.payload.data
      const attachmentFileRegistrationMax =action.payload.attachmentFileRegistrationMax
      const mynaviAttachedOptionRegistrationMax =action.payload.mynaviAttachedOptionRegistrationMax
      const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)

      // 業務チェックを実施する
        // 不正文字チェック
        if (null != data.subject  || null != data.text) {
          if (data.subject.includes('●●') || data.text.includes('●●')) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_011)))
            return
          }
        }
        // 「セミナー画面」置換文字チェック
        if (recruitmentManagementDivision === '1') {
          if(data.text.search("{@seminar")!= -1) {
            const idSet:string[]=[];
            const seminarArray = data.text.split("{@seminar");
            for (var i = 1; i < seminarArray.length; i++) {
              // 次期開発9月 #63456 start
              if(seminarArray[i].indexOf('_schedule',0)==-1 && seminarArray[i].indexOf('Id',0)==-1){
              // 次期開発9月 #63456 end
                const itemArray = seminarArray[i].split("}");
                if (itemArray.length > 1 || seminarArray[i].indexOf("}") == seminarArray[i].length - 1) {
                    idSet.push(itemArray[0]);
                }
              }  
            }
            // 次期開発9月 #63456 start
            const idSetTwo:string[]=[];
            if (data.text.search("{@seminarId")!= -1) {
              const seminarIdArray = data.text.split("{@seminarId");
              for (var i = 1; i < seminarIdArray.length; i++) {
                  const itemArray = seminarIdArray[i].split("}");
                  if (itemArray.length > 1 || seminarIdArray[i].indexOf("}") == seminarIdArray[i].length - 1) {
                    idSetTwo.push(itemArray[0]);
                }  
              }
            }
            var countResult: number = 0
            // 次期開発9月 #63456 end
            if(idSet.length > 0){
              const flagNameList = Array.from(idSet);
              //差込できないのを除く
              let count: number = flagNameList.length
              let seminarFlag: string[] = action.payload.data.seminarImageList ? action.payload.data.seminarImageList.map(i => i.seminarId.toString()) : []
              let tempArray: string[] = flagNameList.filter(i => seminarFlag.indexOf(i) != -1)
              count = count - flagNameList.filter(i => tempArray.indexOf(i) == -1).length
              // 次期開発9月 #63456 start
              countResult = countResult + count
              // 次期開発9月 #63456 end
            }
            // 次期開発9月 #63456 start
            if (idSetTwo.length > 0) {
              const flagNameListTwo = Array.from(idSetTwo);
              //差込できないのを除く
              let countTwo: number = flagNameListTwo.length
              let seminarIdFlag: string[] = action.payload.data.seminarImageList ? action.payload.data.seminarImageList.map(i => i.coordinationFormerSeminarId.toString()) : []
              let tempArrayTwo: string[] = flagNameListTwo.filter(i => seminarIdFlag.indexOf(i) != -1)
              countTwo = countTwo - flagNameListTwo.filter(i => tempArrayTwo.indexOf(i) == -1).length
              countResult = countResult + countTwo
            }
            if(countResult>1){
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_026)))
              return
            }
            // 次期開発9月 #63456 end
          }
        }
        // 次期開発9月 #62201 start
        if (action.payload.checkFlag) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_033)))
          return
        }
        // 次期開発9月 #62201 end
        // ファイルの拡張子チェック
        const suffixCheck = [
          'ai','art','doc','fla','gif','jpg','jpeg','lzh','mpeg','mpg','pdf','png','pps','ppt','psd','txt','xls','zip','docx','xlsx'
        ]
          for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
            var lastIndexOf = data.attachment.templateAttachmentName[i].lastIndexOf('.')
            var suffix = data.attachment.templateAttachmentName[i].substring(lastIndexOf + 1)
            if(!suffixCheck.includes(suffix.toLowerCase())){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_012)))
                return
              }
          }
       // ファイルの添付数チェック
        if ((data.attachment.templateAttachmentName.length + action.payload.submitData.templateAttachmentId.length) > attachmentFileRegistrationMax) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_013)))
          return
        }

       // ファイルのサイズチェック
        for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
          if (Number(data.attachment.templateAttachmentSize[i]) > 10485760) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_014)))
            return
          } else if (Number(data.attachment.templateAttachmentSize[i]) == magiContants.NUMBER_0) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_025)))
            return
          }
        }

        // 同一添付ファイルチェック
        for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
          for(var j = 0;j < data.templateattachmentList.length; j++){
            if(data.attachment.templateAttachmentName[j] == data.templateattachmentList[j].attachmentName){
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_015)))
              return
            }
          }
          for(var j = i+1;j < data.attachment.templateAttachmentName.length; j++){
            if(data.attachment.templateAttachmentName[j] == data.attachment.templateAttachmentName[i]) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_015)))
              return
            }
          }
        }

        // バーコード表示送信経路チェック
        if (data.destination != 0 && data.barcodeDisplay == 1) {
          yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_016)))
          return
        }

        //禁止語チェック
        for (var i = 0; i < data.forbiddenWordsList.length; i++) {
           // 禁止語チェック_件名
          if (data.subject != null && data.subject.includes(data.forbiddenWordsList[i])) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_017)))
            return
          }
         // 禁止語チェック_本文
          if (data.text != null && data.text.includes(data.forbiddenWordsList[i])) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_018)))
            return
          }
        }

        // ホームページURLの入力チェック
        if (data.homepageTitle1 != magiContants.NULL &&
          data.homepageTitle1 != null) {
            if (data.homepageUrl1 == magiContants.NULL ||
              data.homepageUrl1 == null) {
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_022)))
                return
            }
        }
        if (data.homepageTitle2 != magiContants.NULL &&
          data.homepageTitle2 != null) {
            if (data.homepageUrl2 == magiContants.NULL ||
              data.homepageUrl2 == null) {
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_022)))
                return
            }
        }
        if (data.homepageTitle3 != magiContants.NULL &&
          data.homepageTitle3 != null) {
            if (data.homepageUrl3 == magiContants.NULL ||
              data.homepageUrl3 == null) {
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_022)))
                return
            }
        }
        // phase2 Start
        if (recruitmentManagementDivision === '1') {
           //マイナビ添付オプション上限チェック
           if(data.mynaviAttachedOptionEntryID.length + data.mynaviAttachedOptionSeminarID.length >mynaviAttachedOptionRegistrationMax){
             yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS020_023)))
             return
           }
        }
        // phase2 End
        // #80743 start
        // 返信元件名本文差し込み文字チェック
        if(data.subject.includes("{@reply_title}") || data.text.includes("{@reply_body}")){
          yield put(openModal(getMessage("MCAMS020-035")))
          return
        }
        // #80743 end
        const data2: MCAMS040InitDisplayRequest = {
          templateId: data.templateId,
          mynaviAttachedOptionSeminarID:data.mynaviAttachedOptionSeminarID ,
          mynaviAttachedOptionEntryID:data.mynaviAttachedOptionEntryID,
          recruitmentManagementDivision:recruitmentManagementDivision
          // 年度対応 start
          ,targetYear:data.targetYear
          // 年度対応 end
        }
        const data3 = yield call(initRequest,data2)
        yield put(setMessageTemplateContentConfirm(data3))
        yield put(setAddSubmit(data))
        yield put(setOpenConfirmContentPage(true))

    } catch (error) {
      yield put(openModal(error.message))
    }
  }
  // MCAMS020 End

  // MCAMS010 Start
  function* selectMeassageTemplateListSaga(action: ReturnType<typeof selectMeassageTemplateList>) {
    try {
      const data = yield call(selectMeassageTemplateListRequest, action.payload)
      yield put(setSelectMeassageTemplateList(data));
      yield put(setMeassageTemplateListPage(0));
      yield put(setMeassageTemplateListData([]));
      yield put(setMeassageTemplateListOption(''));
    } catch (error) {
      yield put(openModal(error.message));
    }
  }

  function* addMeassageTemplateListSaga(action: ReturnType<typeof addMeassageTemplateList>) {
    try {
      yield call(addMeassageTemplateListRequest, action.payload)
      const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
      const templateIdInfo:MCAMS020InitDisplayRequest = {
        templateId: null,
        recruitmentManagementDivision:recruitmentManagementDivision
      }
      const data = yield call(messageTemplateAddRequest, templateIdInfo)
      yield put(setMessageTemplateAddInit(data));
      yield put(setAddMeassageTemplateList(true));
    } catch (error) {
      yield put(openModal(error.message));
    }
  }

  function* executeMeassageTemplateListSaga(action: ReturnType<typeof executeMeassageTemplateList>) {
    try {
      const data = yield call(executeMeassageTemplateListRequest, action.payload)
      yield put(setExecuteMeassageTemplateList(data));
      yield put(setMeassageTemplateListPage(0));
      yield put(setMeassageTemplateListData([]));
      yield put(setMeassageTemplateListOption(''));
      yield put(openSnackbar(data.message));
    } catch (error) {
      if(error.message == magiContants.MESSAGECODE_MCAMS010_004){
        const openModalData:openModalData = {
          openCheckModal:true,
          modalMessage:getMessage(magiContants.MESSAGECODE_MCAMS010_004),
        }
        yield put(setOpenModalMeassageData(openModalData));
      }else{
        yield put(openModal(error.message));
      }
    }
  }

  function* copyMeassageTemplateListSaga(action: ReturnType<typeof copyMeassageTemplateList>) {
    try {
      yield call(copyMeassageTemplateListRequest, action.payload)
      const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
      const templateIdInfo: MCAMS020InitDisplayRequest = {
        templateId: Number(action.payload.templateId),
        recruitmentManagementDivision: recruitmentManagementDivision,
      }
      const data = yield call(messageTemplateAddRequest, templateIdInfo)
      yield put(setMessageTemplateAddInit(data));
      yield put(setCopyMeassageTemplateList(true));
    } catch (error) {
      yield put(openModal(error.message));
    }
  }

    function* updateMessageTemplateSortSaga(action: ReturnType<typeof updateMessageTemplateSort>) {
    try {
      yield call(updateMessageTemplateSortRequest, action.payload.updateOrderList)
      const data : MCAMS010MessageTemplateList = yield call(selectMeassageTemplateListRequest, action.payload.selectParam)
      yield put(setSelectMeassageTemplateList(data));
    } catch (error) {
      yield put(openModal(error.message));
    }
  }
  // MCAMS010 End

  // MCAMS040 Start
  function* insertOrUpdateSaga(action: ReturnType<typeof insertOrUpdateMessageTemplate>) {
    try {
      
      const sendData: File[] = action.payload.registeredFileName
      // ファイル存在チェック
      for (var i = 0; i < sendData.length; i++) {
        const file = sendData[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if(!isFile){
            yield put(setOpenConfirmContentPage(false))
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS040_024)))
            return
          }
        }
      }
      const submitParams: MCAMS040generateMessageTemplateRequest = action.payload.submitParams
      // #80743 start
      // 返信元件名本文差し込み文字チェック
      if (submitParams.subject.includes("{@reply_title}") || submitParams.text.includes("{@reply_body}")) {
        yield put(openModal(getMessage("MCAMS040-032")))
        return
      }
      // #80743 end
      const data = yield call(insertOrUpdateRequest, {
        registeredFileName: sendData,
        submitParams: submitParams
      })

      yield put(setOpenConfirmContentPage(false))
      yield put(setAddPageDialogOpen(false))
      yield put(setDetailPageDialogOpen(false))
      yield put(selectMeassageTemplateList(action.payload.mCAMS010InitDisplayRequest))
      yield put(setMeassageTemplateListPage(0));
      yield put(setMeassageTemplateListData([]));
      yield put(setMeassageTemplateListOption(''));
      yield put(openSnackbar(data));
    } catch (error) {
      yield put(setOpenConfirmContentPage(false))
      yield put(openModal(error.message))
    }
  }
  // MCAMS040 End

  // MCAMS030 Start
  function* setMessageTemplateSettingId030Saga(action: ReturnType<typeof setMessageTemplateSettingId030>) {
      try {
        const data = yield call(messageTemplateDetailRequest,action.payload)
        yield put(setMessageTemplateDetailInit(data))
        yield put(setDetailPageDialogOpen(true))
      } catch (error) {
        yield put(openModal(error.message))
      }
  }

  function* messageTemplateDeleteSaga(action: ReturnType<typeof messageTemplateDelete>) {
    try {
      yield call(deleteMessageTemplateRequest,action.payload)
      yield put(setDetailPageDialogOpen(false))

      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_DELETE_SUCCESS)))
      const searchCondition: ReturnType<typeof getSearchCondition> = yield select(getSearchCondition)
      const result = yield call(selectMeassageTemplateListRequest, searchCondition)
      yield put(setSelectMeassageTemplateList(result));
      yield put(setMeassageTemplateListPage(0));
      yield put(setMeassageTemplateListData([]));
      yield put(setMeassageTemplateListOption(''));
    } catch (error) {
      yield put(openModal(error.message))
    }
  }

    function* messageTemplateDetailConfirmSaga(action: ReturnType<typeof messageTemplateDetailConfirm>) {
      try {
        const files: File[] = action.payload.files
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const isFile = yield call(isFileExist, file)
            if (!isFile) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_028)))
              return
            }
          }
        }
        const data1 = yield call(messageTemplateInputRequest, 
          {registeredFileName: files,
            submitParams: action.payload.submitData});
        action.payload.data.templateattachmentList=data1;
        const data = action.payload.data
        const attachmentFileRegistrationMax =action.payload.attachmentFileRegistrationMax
        const mynaviAttachedOptionRegistrationMax =action.payload.mynaviAttachedOptionRegistrationMax
        const recruitmentManagementDivision:ReturnType<typeof getRecruitmentManagementDivision>=yield select(getRecruitmentManagementDivision)
          
        // 業務チェックを実施する
          // 不正文字チェック
          if (data.subject !== null && data.subject !== "") {
            if (data.subject.includes('●●')) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_012)))
              return
            }
          }
          if (data.text !== null && data.text !== "") {
            if (data.text.includes('●●')) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_012)))
              return
            }
          }

          // 「セミナー画面」置換文字チェック
          if (recruitmentManagementDivision === '1') {
            if(data.text.search("{@seminar")!= -1) {
              const idSet:string[]=[];
              const seminarArray = data.text.split("{@seminar");
              for (var i = 1; i < seminarArray.length; i++) {
                // 次期開発9月 #63456 start
                if(seminarArray[i].indexOf('_schedule',0)==-1 && seminarArray[i].indexOf('Id',0)==-1){
                // 次期開発9月 #63456 end
                  const itemArray = seminarArray[i].split("}");
                  if (itemArray.length > 1 || seminarArray[i].indexOf("}") == seminarArray[i].length - 1) {
                      idSet.push(itemArray[0]);
                  }
                }  
              }
              // 次期開発9月 #63456 start
              const idSetTwo:string[]=[];
              if (data.text.search("{@seminarId")!= -1) {
                const seminarIdArray = data.text.split("{@seminarId");
                for (var i = 1; i < seminarIdArray.length; i++) {
                    const itemArray = seminarIdArray[i].split("}");
                    if (itemArray.length > 1 || seminarIdArray[i].indexOf("}") == seminarIdArray[i].length - 1) {
                      idSetTwo.push(itemArray[0]);
                  }  
                }
              }
              var countResult: number = 0
              // 次期開発9月 #63456 end
              if(idSet.length > 0){
                const flagNameList = Array.from(idSet);
                //差込できないのを除く
                let count: number = flagNameList.length
                let seminarFlag: string[] = action.payload.data.seminarImageList ? action.payload.data.seminarImageList.map(i => i.seminarId.toString()) : []
                let tempArray: string[] = flagNameList.filter(i => seminarFlag.indexOf(i) != -1)
                count = count - flagNameList.filter(i => tempArray.indexOf(i) == -1).length
                // 次期開発9月 #63456 start
                countResult = countResult + count
                // 次期開発9月 #63456 end
              }
              // 次期開発9月 #63456 start
              if (idSetTwo.length > 0) {
                const flagNameListTwo = Array.from(idSetTwo);
                //差込できないのを除く
                let countTwo: number = flagNameListTwo.length
                let seminarIdFlag: string[] = action.payload.data.seminarImageList ? action.payload.data.seminarImageList.map(i => i.coordinationFormerSeminarId.toString()) : []
                let tempArrayTwo: string[] = flagNameListTwo.filter(i => seminarIdFlag.indexOf(i) != -1)
                countTwo = countTwo - flagNameListTwo.filter(i => tempArrayTwo.indexOf(i) == -1).length
                countResult = countResult + countTwo
              }
              if(countResult>1){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_029)))
                return
              }
              // 次期開発9月 #63456 end
            }
          }
          // 次期開発9月 #62201 start
          if (action.payload.checkFlag) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_036)))
            return
          }
          // 次期開発9月 #62201 end
          // ファイルの拡張子チェック
          const suffixCheck = [
            'ai','art','doc','fla','gif','jpg','jpeg','lzh','mpeg','mpg','pdf','png','pps','ppt','psd','txt','xls','zip','docx','xlsx'
          ]
            for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
              var lastIndexOf = data.attachment.templateAttachmentName[i].lastIndexOf('.')
              var suffix = data.attachment.templateAttachmentName[i].substring(lastIndexOf + 1)
              if(!suffixCheck.includes(suffix.toLowerCase())){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_013)))
                return
              }
            }

        // ファイルの添付数チェック
          if ((data.attachment.templateAttachmentName.length + action.payload.submitData.templateAttachmentId.length) > attachmentFileRegistrationMax) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_014)))
            return
          }

        // ファイルのサイズチェック
          for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
            if (Number(data.attachment.templateAttachmentSize[i]) > 10485760) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_015)))
              return
            } else if (Number(data.attachment.templateAttachmentSize[i]) == magiContants.NUMBER_0) {
              yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_028)))
              return
            }
          }

          // 同一添付ファイルチェック
          for (var i = 0; i < data.attachment.templateAttachmentName.length; i++) {
            for(var j = 0;j < data.templateattachmentList.length; j++){
              if(data.attachment.templateAttachmentName[j] == data.templateattachmentList[j].attachmentName){
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_016)))
                return
              }
            }
            for(var j = i+1;j < data.attachment.templateAttachmentName.length; j++){
              if(data.attachment.templateAttachmentName[j] == data.attachment.templateAttachmentName[i]) {
                yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_016)))
                return
              }
            }
          }


          // バーコード表示送信経路チェック
          if (data.destination != 0 && data.barcodeDisplay == 1) {
            yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_017)))
            return
          }
          //禁止語チェック
          if(data.forbiddenWordsList != null){
            for (var i = 0; i < data.forbiddenWordsList.length; i++) {
              // 禁止語チェック_件名
              if (data.subject != null && data.subject.includes(data.forbiddenWordsList[i])) {
                yield put(openModalMessage(getMessage(magiContants.MESSAGECODE_MCAMS030_018)))
                return
              }
            // 禁止語チェック_本文
              if (data.text != null && data.text.includes(data.forbiddenWordsList[i])) {
                yield put(openModalMessage(getMessage(magiContants.MESSAGECODE_MCAMS030_019)))
                return
              }
            }
          }

          // ホームページURLの入力チェック
          if (data.homepageTitle1 != magiContants.NULL &&
            data.homepageTitle1 != null) {
              if (data.homepageUrl1 == magiContants.NULL ||
                data.homepageUrl1 == null) {
                  yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_023)))
                  return
              }
          }
          if (data.homepageTitle2 != magiContants.NULL &&
            data.homepageTitle2 != null) {
              if (data.homepageUrl2 == magiContants.NULL ||
                data.homepageUrl2 == null) {
                  yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_023)))
                  return
              }
          }
          if (data.homepageTitle3 != magiContants.NULL &&
            data.homepageTitle3 != null) {
              if (data.homepageUrl3 == magiContants.NULL ||
                data.homepageUrl3 == null) {
                  yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_023)))
                  return
              }
          }
          // phase2 Start
          if (recruitmentManagementDivision === '1') {
              //マイナビ添付オプション上限チェック
              if(data.mynaviAttachedOptionEntryID.length + data.mynaviAttachedOptionSeminarID.length >mynaviAttachedOptionRegistrationMax){
                 yield put(openModal(getMessage(magiContants.MESSAGECODE_MCAMS030_024)))
                 return
              }
          }
          // phase2 End
          // #80743 start
          // 返信元件名本文差し込み文字チェック
          if (data.subject.includes("{@reply_title}") || data.text.includes("{@reply_body}")) {
            yield put(openModal(getMessage("MCAMS030-039")))
            return
          }
          // #80743 end
          const data2: MCAMS040InitDisplayRequest = {
            templateId: data.templateId,
            mynaviAttachedOptionSeminarID:data.mynaviAttachedOptionSeminarID ,
            mynaviAttachedOptionEntryID:data.mynaviAttachedOptionEntryID,
            recruitmentManagementDivision:recruitmentManagementDivision,
            // 年度対応 start
            targetYear:data.targetYear
            // 年度対応 end
          }
          const data3 = yield call(initRequest,data2)
          yield put(setMessageTemplateContentConfirm(data3))
          yield put(setAddSubmit(data))
          yield put(setOpenConfirmContentPage(true))

      } catch (error) {
        yield put(openModal(error.message))
      }
    }

  // MCAMS030 End
  // 年度対応 start
  function* messageTemplateTargetYearChangeSaga(action: ReturnType<typeof messageTemplateTargetYearChangeInit>) {
    try {
      const data = yield call(messageTemplateTargetYearChange, action.payload)

      let initialObject = Object.keys(initialMCAMS020ReturnDisplayDto)
      Object.keys(action.payload.formValue).forEach(i=>{
        if (!initialObject.includes(i)) {
          delete action.payload.formValue[i]
        }
      })
      yield put(setMessageTemplateTargetYearChangeInit(data));
      yield action.payload.method({...action.payload.formValue,
        targetYear:action.payload.targetYear,
        entryBoxList:data.entryBoxList,
        seminarList:data.seminarList,
        recruitmentManagementFlagList:data.recruitmentManagementFlagList,
        seminarScheduleList:data.seminarScheduleList,
        seminarImageList:data.seminarImageList
      })
    } catch (error) {
      yield put(openModal(error.message));
    }
  }
  // 年度対応 end

  // MCBリプレース MCAMS020 START
  export function* getReplaceBodySaga(action: ReturnType<typeof getReplaceBody>) {
    try {
      const data = yield call(mcbMessageTemplatePreviewRequest, action.payload)
      yield put(setReplaceBody(data))
    } catch (error) {
        yield put(openModal(error.message))
    }
  }
  // MCBリプレース MCAMS020 END

  export default function* messageTemplateSaga() {
    yield all([
    takeLeading(messageTemplateAddInit, addSearchSaga),
    takeLeading(messageTemplateConfirm, messageTemplateConfirmSaga),
    takeLeading(addMeassageTemplateList, addMeassageTemplateListSaga),
    takeLeading(selectMeassageTemplateList, selectMeassageTemplateListSaga),
    takeLeading(executeMeassageTemplateList, executeMeassageTemplateListSaga),
    takeLeading(copyMeassageTemplateList, copyMeassageTemplateListSaga),
    takeLeading(insertOrUpdateMessageTemplate, insertOrUpdateSaga),
    takeLeading(setMessageTemplateSettingId030,setMessageTemplateSettingId030Saga),
    takeLeading(messageTemplateDelete,messageTemplateDeleteSaga),
    takeLeading(messageTemplateDetailConfirm,messageTemplateDetailConfirmSaga),
    takeLeading(updateMessageTemplateSort,updateMessageTemplateSortSaga),
    // 年度対応 start
    takeLeading(messageTemplateTargetYearChangeInit,messageTemplateTargetYearChangeSaga),
    // 年度対応 end
    // MCBリプレース MCAMS020 START
    takeLatest(getReplaceBody, getReplaceBodySaga),
    // MCBリプレース MCAMS020 END
    ])
  }
  