import { getMessage } from "common/messageUtil"
import { magiContants } from "utils/contants"

export const initialValues = {
  selectionManagementId:0,//選考管理ID
  progressStatusSettingId:0,//選考ステップ設定ID
  nonTargetFlag:'',//対象外フラグ
  initialSelectionManagementId:0,//初期選考管理ID
  decisionDivision:'',//判定区分
  starEvaluation:'',//星評価
  comment:'',//コメント
  smSysVersionNumber:0,//選考管理sys削除フラグ
  psmSysVersionNumber:0,//選考ステップ管理sys削除フラグ
  seSysVersionNumber:0,//星評価sys削除フラグ
  progressType:'',//進捗種別
  displayOrder:0,//表示順
  progressName:'',//進捗名
  decisionDivisionType:'',//判定区分種別
}

export const initialNoAdoptionData = {
  nascp:'',//不採用ステータス変更設定
  asrlsrp:'',//書類選考不採用通知送信予約設定
  irlsrp:''//面接不採用通知送信予約設定
}

export const initialManagerFlowStartCommentData = {
  selectionManagementId:0,//選考管理ID
  progressStatusSettingId:0,//選考ステップ設定ID
  nonTargetFlag:'',//対象外フラグ
  initialSelectionManagementId:0,//初期選考管理ID
  decisionDivision:'',//判定区分
  starEvaluation:'',//星評価
  comment:'',//コメント
  updateTime: '',//更新日時(コメント)
  updater:'',//更新者(コメント)
  smSysVersionNumber:0,//sysバージョン番号（選考管理）
  psmSysVersionNumber:0,//sysバージョン番号（選考ステップ管理）
  seSysVersionNumber:0,//sysバージョン番号（星評価）
}

export const initialPriority = {
  selectionManagementIdArray:[],//選考管理ID
  evaluation:'00',//星評価
  sysVersionNumberSeva:0,//sysバージョン番号（星評価）
}

export const initialSelectionFlowInitData = {
  flag : 0,// 旗の位置
  leftSelect : 0,// 左進捗の選択位置
  rightSelect : 0,// 右進捗の選択位置
  leftGrey : false,// 左進捗のグレー表示の標識
  rightGrey : false,// 右進捗のグレー表示の標識
  target : true,// 対象の標識
  leftProgressList : [],// 左進捗の選考ステップ
  rightProgressList : [],// 右進捗の選考ステップ
}
export const initialdecisionProcessChangeQueryResult = {
  selectionManagementIdArray:[],
  selectMagSelectFlowInfoOutDtoList : [],
  noadotedOutDto:initialNoAdoptionData,
  selectMagSelectSelectionInfoOutDto:initialManagerFlowStartCommentData,
  progressStatusSettingId:0,//選考ステップ設定ID(直前)
  passingStatusDivisionId:'',//合否区分ID
  historyInsertDivisionId:'',//履歴登録区分
  progressTypeBefore:'',//進捗種別(変更元)
  displayOrderBefore:0,//表示順(変更元)
  decisionDivisionTypeBefore:'',//判定区分種別(変更元)
  progressStatusSettingIdAfter:0,//選考ステップ設定ID(変更先)
  progressTypeAfter:'',//進捗種別(変更先)
  displayOrderAfter:0,//表示順(変更先)
  progressNameAfter:'',//進捗名(変更先)
  selectionName:'',      //選考名
  decisionDivisionTypeAfter:'',//判定区分種別(変更先)
  sysVersionNumberList:[],//バージョン番号と判定区分情報
  selectionFlowInitData:initialSelectionFlowInitData,//選考フロー初期化情報
  decisionDivisionType:'',//判定区分種別(初期表示)
  inValidCheckCount: [],//メッセージ送信エラー情報
};

export interface MCAYS030BulkOperationMaxProcessInfoDto {
  // 処理上限可否
  maxProcessType:number;

  // 処理上限値
  maxProcessCount:number;
}

export interface initResultDto{

  // 処理上限情報_管理項目
  maxProcessInfoManagementItem:MCAYS030BulkOperationMaxProcessInfoDto;

  // 処理上限情報_コメント
   maxProcessInfoComment:MCAYS030BulkOperationMaxProcessInfoDto;

  // 処理上限情報_優先度
  maxProcessInfoPriority:MCAYS030BulkOperationMaxProcessInfoDto;

  // 処理上限情報_メッセージ送信
  maxProcessInfoSendMessage:MCAYS030BulkOperationMaxProcessInfoDto;

  // 処理上限情報_合否
  maxProcessInfoResult:MCAYS030BulkOperationMaxProcessInfoDto;

  // 処理上限情報_選考ステップ
  maxProcessInfoSelectionStep:MCAYS030BulkOperationMaxProcessInfoDto;

}

export const MCAYS030InitValue ={
  // 処理上限可否
  maxProcessType:0,

  // 処理上限値
  maxProcessCount:0,
}

export const initResultDto = {

  // 処理上限情報_管理項目
  maxProcessInfoManagementItem:MCAYS030InitValue,

  // 処理上限情報_コメント
   maxProcessInfoComment:MCAYS030InitValue,

  // 処理上限情報_優先度
  maxProcessInfoPriority:MCAYS030InitValue,

  // 処理上限情報_メッセージ送信
  maxProcessInfoSendMessage:MCAYS030InitValue,

  // 処理上限情報_合否
  maxProcessInfoResult:MCAYS030InitValue,

  // 処理上限情報_選考ステップ
  maxProcessInfoSelectionStep:MCAYS030InitValue,

}

export const initialdecisionProgressChangeQueryResultList = {
  selectionManagementIdArray:[],
  selectMagSelectFlowInfoOutDtoList : [],
  selectMagSelectSelectionInfoOutDto:[],
  sysVersionNumberList:[],//バージョン番号と判定区分情報
  progressStatusSettingIdBefore:0,//選考ステップ設定ID(変更元)
  passingStatusDivisionId:'',//合否区分ID
  historyInsertDivisionId:'',//履歴登録区分
  progressTypeBefore:'',//進捗種別(変更元)
  displayOrderBefore:0,//表示順(変更元)
  decisionDivisionTypeBefore:'',//判定区分種別(変更元)
  progressStatusSettingIdAfter:0,//選考ステップ設定ID(変更先)
  progressTypeAfter:'',//進捗種別(変更先)
  displayOrderAfter:0,//表示順(変更先)
  progressNameAfter:'',//進捗名(変更先)
  selectionName:'',      //選考名
  decisionDivisionTypeAfter:'',//判定区分種別(変更先)
  selectionFlowInitData:initialSelectionFlowInitData,//選考フロー初期化情報
  decisionDivisionType:'',//判定区分種別(初期表示)
  inValidCheckCount: [],//メッセージ送信エラー情報
  initResultDto:initResultDto,//処理上限情報
};

export const staticSendMessageMap = {
  staticMessageOne: 'ご注意ください！',
  staticMessageTwo: '意図せず「書類選考不合格」メッセージを配信してしまう事象が多発しています。',
  staticMessageThree: 'メッセージの内容をよくご確認のうえ、配信設定を行ってください。',
}

export const sendMessageMap = {
  MCAYS030_005: getMessage(magiContants.MESSAGECODE_MCAYS030_005),
  MCAYS030_006: getMessage(magiContants.MESSAGECODE_MCAYS030_006),
  MCAYS030_007: getMessage(magiContants.MESSAGECODE_MCAYS030_007),
  MCAYS030_008: getMessage(magiContants.MESSAGECODE_MCAYS030_008),
}

export const staticTextMap = {
  staticMessageOne: '選考ステップを',
  staticMessageTwo: 'へ変更しますか？変更後は',
  staticMessageThree: 'に移動されます。',
  buttonTip:'この応募者の情報を関係者にメールでシェアします',
  staticMessageFour: 'へ変更しますか？',
}
// #58807 次期開発2022年9月 start
export const textMap = {
  selecting: {
    messageOne: '',
    messageTwo: '選考中一覧',
    messageThree: '全件一覧'
  },
  confrimAfter: {
    messageOne: '',
    messageTwo: '内定者一覧',
    messageThree: '内定者一覧'
  },
  noPass: {
    messageOne: '不合格',
    messageTwo: '選考対象外一覧',
    messageThree: '選考対象外一覧'
  },
  noAdopt: {
    messageOne: '不採用',
    messageTwo: '選考対象外一覧',
    messageThree: '全件一覧'
  },
  dismiss: {
    messageOne: '辞退',
    messageTwo: '選考対象外一覧',
    messageThree: '全件一覧'
  },
  internship: {
    messageOne: 'インターンシップ参加',
    messageTwo: 'インターンシップ参加一覧',
    messageThree: ''
  },
}
// #58807 次期開発2022年9月 end

